import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Image,
  CardGroup,
  Badge,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { LinkContainer } from "react-router-bootstrap";
import AppMetaDataContext from "../../context/AppMetaDataContext";

import linkchimpImage from "../../language-learning-immersion-extension.png";
import meelisImage from "../../meelis.jpg";
import socialImage from "../../duolingo-ninja-social-sharing.png";
import duolingoMobileAppImage from "../../duolingo-mobile-app-vocabulary-list.png";
import duolingoNinjaVocabularyList from "../../duolingo-ninja-vocabulary-list.png";

import LearnDuolingoVocabularyMainCTA from "../../Components/LearnDuolingoVocabularyMainCTA";
import HowItWorks from "../../Components/HowItWorks";
import Benefits from "../../Components/Benefits";
import Customize from "../../Components/Customize";
import FounderQuote from "../../Components/FounderQuote";
import DownloadExtension from "../../Components/DownloadExtension";
import WhyDuolingoIsNotEnough from "../../Components/WhyDuolingoIsNotEnough";

import DownloadVocabularyWithExtension from "../../Components/DownloadVocabularyWithExtension";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>
          How to see and download your Duolingo vocabulary List -{" "}
          {metaData.appName}
        </title>
        <meta
          name="description"
          key="description"
          content="There are two ways to see and download your Duolingo vocabulary list. One is using the Duolingo mobile app. The other is using the Duolingo Ninja browser extension."
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <Card className="border-0 mb-5">
          <Card.Body>
            <Card.Text>
              <h1
                className="text-center"
                style={{ marginBottom: "4%", marginTop: "5%" }}
              >
                How to see your Duolingo Vocabulary List
              </h1>
              <p>
                Historically Duolingo used to offer the{" "}
                <a
                  href="https://duolingo.fandom.com/wiki/Words"
                  target="_blank"
                >
                  word list
                </a>{" "}
                on their website but this feature was removed years ago.
                However, there are still two alternative ways to see and
                download your Duolingo vocabulary. Each has its own drawbacks.
              </p>

              <Row
                style={{ marginBottom: "4%", marginTop: "5%" }}
                className="mb-3"
              >
                <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                  <h2>Use The Duolingo Mobile App To See The Words</h2>
                  <p>
                    You can’t see the vocabulary on the Duolingo website
                    anymore. But Duolingo mobile apps do let you see the words
                    you’ve learned.
                  </p>
                  <ol>
                    <li>Open the mobile app</li>
                    <li>
                      Go under Practice (the blue dumbbells icon at the bottom
                      of the screen)
                    </li>
                    <li>Select “Words”</li>
                  </ol>
                  <p>
                    You can now see the list of words and their possible
                    translations. You can even listen to the pronunciation and
                    practice the words. But you can’t download the vocabulary
                    with this method.
                  </p>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                  <center>
                    <img
                      src={duolingoMobileAppImage}
                      alt="Duolingo vocabulary list from the mobile app"
                      className="img-fluid"
                      // set maxwidth
                      style={{ maxHeight: "300px" }}
                    />
                  </center>
                </Col>
              </Row>
              <DownloadVocabularyWithExtension />

              {/* <Row
                style={{ marginBottom: "4%", marginTop: "5%" }}
                className="mb-3"
              >
                <Col xs={{ span: 12 }} lg={{ span: 4 }}></Col>
                <Col xs={{ span: 12 }} lg={{ span: 8 }}></Col>
              </Row> */}
            </Card.Text>
          </Card.Body>
        </Card>

        <hr className="mb-5" />
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <WhyDuolingoIsNotEnough />
        <Benefits />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
