import React, { useRef, useContext, useState, useEffect } from "react";
import { Form, Button, Card, Container, Alert } from "react-bootstrap";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

export default function ForgotPassword() {
  const emailRef = useRef();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);

  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);

  // const [searchParams] = useSearchParams();
  // const signupMessage = searchParams.get("signup");

  // // if the user signed up, sent an event to Google Analytics
  // useEffect(() => {
  //   if (!window.GA_INITIALIZED) {
  //     ReactGA.initialize("G-V2T4J6Y9YR");
  //     window.GA_INITIALIZED = true;
  //   }
  //   if (signupMessage) {
  //     ReactGA.event({
  //       category: "setup",
  //       action: "sign_up",
  //       // label: "lifecycle", // optional
  //       // value: 99, // optional, must be a number
  //       // nonInteraction: false, // optional, true/false
  //       // transport: "xhr", // optional, beacon/xhr/image
  //     });
  //   }
  // }, [signupMessage]);

  const handleSubmit = async (data) => {
    data.preventDefault();
    console.log("Password Forgot form submitted with email: ", emailRef.current.value);

    const body = {
      email: emailRef.current.value,
    };
    console.log("API query with email: ", body.email);
    axios
      .post("/api/user/forgot-password", body)
      .then((res) => {
        console.log("API response: ", res.data);
        // needs to be checked, in order to have a re-render based on context
        // context.getAuthInfo();
        setSuccessMessage(res.data.message);
        setErrorMessage(undefined);
      })
      .catch((err) => {
        console.log("Failed to find the user: ", err.response.data);
        setErrorMessage(err.response.data);
        setSuccessMessage(undefined);
      });
  };

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <Helmet>
        <title>Forgot Password - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Forgot your password?</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  ref={emailRef}
                  className="w-20"
                />
              </Form.Group>
              <Button type="submit" className="w-100 mt-4">
                Send password reset email
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          {context.user_id ? (
            <Navigate replace to="/dashboard" />
          ) : (
            <div>
              <div>
                Not registered yet? <Link to="/register">Sign up</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
