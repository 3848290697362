import * as React from 'react';
import AuthContext from '../context/AuthContext';

function StripePricingPage() {
  const context = React.useContext(AuthContext);
  
  // make sure test environment has test keys, and production env has live keys
  let publicKey;
  let pricingTableId;
  if (process.env.NODE_ENV === 'production') {
    publicKey =
      "pk_live_51MLh7VDMBCo9CSVZB7STMPJIjovEC7bPlIvlr518FPSq1RPMUtMWMka3LLtBX2V6dS46vRC9NK9e9N1sZ5z1BZeW00vKnLJ7O1";
    pricingTableId = "prctbl_1NNJF7DMBCo9CSVZv39xFv2a";
  } else {
    publicKey = "pk_test_51MLh7VDMBCo9CSVZfVZGELnFoquZQVGo4l88syGlAgvdELelzn4bpk9uSuEVnoVZNfWyz3UuoliWHXecOLRFTyRs00TEjUe0e2";
    pricingTableId = "prctbl_1NSZeZDMBCo9CSVZO9JYNLtK";
  }
  // Paste the stripe-pricing-table snippet in your React component
  
  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publicKey}
      customer-email={context.email}
      client-reference-id={context.user_id}
    >
    </stripe-pricing-table>
  );
}

export default StripePricingPage;