import React from "react";
import Container from "react-bootstrap/Container";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";
import meelisImage from "../meelis.jpg";
import Logo from "../champion-logo-128.png";

export default function ReleaseNotes() {
  const metaData = React.useContext(AppMetaDataContext);

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "70vh" }}>
      <Helmet>
        <title>Review - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div>
        <div style={{ maxWidth: "700px" }}>
          <Container className="mt-5" style={{ maxWidth: "600px" }}>
            <Card>
              <Card.Header>One-time notification from the founder</Card.Header>
              <Card.Body>
                <Card.Text>
                  <img
                    src={meelisImage}
                    alt="Duolingo Ninja"
                    className="img-fluid me-3 mt-2"
                    style={{ float: "left", maxHeight: "200px" }}
                  />
                  <h5 className="mb-3">Enjoying Duolingo Ninja?</h5>
                  <p>
                    Please{" "}
                    <a
                      href="https://chrome.google.com/webstore/detail/duolingo-ninja/cdiecbgkdbkloiniekbfblbdbjgehpgb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      review the extension
                    </a>{" "}
                    in Chrome Web Store. It will help others find the extension
                    and keep the development going.
                    <br />
                    <br />
                    Thanks,
                    <br />
                    Meelis
                    <br />
                    Founder of Duolingo Ninja
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Container>

          <br />
          <br />
        </div>
      </div>
    </Container>
  );
}
