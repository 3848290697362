import React from "react";
import Container from "react-bootstrap/Container";
import Statistics from "../Components/Statistics";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";

export default function Dashboard() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <Container className="d-flex flex-column align-items-center justify-content-top" style={{ minHeight: "70vh" }}>
      <Helmet>
        <title>Dashboard - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          <h1 className="display-4 mb-5">Dashboard</h1>
          <br />
        </div>
        <Statistics />
      </div>
    </Container>
  );
}
