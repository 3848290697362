const wordList = [
  {
    word: "अँगूठा",
    translation: "thumb",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अँगूठे",
    translation: "thumbs, thumb",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अँग्रेज़ी",
    translation: "English",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अंदर",
    translation: "inside, in",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अक्टूबर",
    translation: "october",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अख़बार",
    translation: "newspaper, newspapers, paper",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अगर",
    translation: "if",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अगस्त",
    translation: "august",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अच्छा",
    translation: "well, good, fine, nice",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अच्छी",
    translation: "well, good, fine, nice",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अच्छे",
    translation: "well, good, fine, nice",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अपना",
    translation:
      "his, her, herself, my, your, their, himself, our, yourself, its, ourselves",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अपनी",
    translation: "his, her, herself, its, their, own, our, my, your",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अपने",
    translation:
      "myself, own, your, oneself, her, herself, ourselves, himself, their, his, yourselves, our, themselves, yourself, my, its",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अप्रैल",
    translation: "april",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अब",
    translation: "now",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "अमेरिका",
    translation: "America",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आ",
    translation: "come to, come",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आ रहा",
    translation: "coming to, coming",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आ रही",
    translation: "coming to, coming",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आ रहे",
    translation: "coming to, coming",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आओ",
    translation: "come to, come",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आग",
    translation: "fire",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आगे",
    translation: "in front of, front, ahead of, ahead, further",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आज",
    translation: "today",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आठ",
    translation: "eight",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आदमी",
    translation: "the men, men, the man, man",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आना",
    translation: "come",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आप",
    translation: "you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आपको",
    translation: "(to) you, you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आपसे",
    translation: "of you, than you, you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आम",
    translation: "mangoes, normal, mango, common, ordinary, a mango",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आया",
    translation: "arrived (m.), come, came (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आयी",
    translation: "came (f.), arrived (f.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आये",
    translation: "came (plural), came (m.) (polite)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आयेंगी",
    translation: "(f. pl.) will come, (f. formal) will come",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आयेंगे",
    translation: "come to, will come",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आयेगा",
    translation: "will come",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आयेगी",
    translation: "come, will come",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आराम",
    translation: "rest",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आलू",
    translation: "potatoes, potato",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आसमान",
    translation: "the sky, a sky, sky",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "आसान",
    translation: "simple, convenient, easy, free, an easy",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "इस",
    translation: "this, the, it",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "इसलिये",
    translation: "so",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उँगलियाँ",
    translation: "toes, fingers",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उँगली",
    translation: "finger",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उड़",
    translation: "fly",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उनका",
    translation: "hers, their, his, her, theirs",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उनकी",
    translation: "them, his, her, theirs, their, hers, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उनके",
    translation: "his, her, theirs, their, she, they, hers",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उनसे",
    translation:
      "them, than them, with them, from them, him (respect), her (respect)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उन्हें",
    translation: "them, to them, her, she, they, him, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उन्होंने",
    translation: "she, they, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उस",
    translation: "it, the, that, she, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उसका",
    translation: "of that, his, her, she, hers, its",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उसकी",
    translation: "of that, his, her, him, she, hers, its, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उसके",
    translation: "of that, his, her, she, hers, its, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उसने",
    translation: "he, she, him, it",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उससे",
    translation:
      "with her, her, to him, with him, to her, than him, from him, to it, him",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "उसे",
    translation: "her, that, it, she, him, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ऊपर",
    translation: "on, upstairs, over, up, on top of, above",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "एक",
    translation: "a, an, one",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "और",
    translation: "and, has, else, other, more",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "औरत",
    translation: "woman",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "औरतें",
    translation: "the women, women",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कई",
    translation: "many, several",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कपड़े",
    translation: "the clothes, clothes",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कब",
    translation: "when",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कबूतर",
    translation: "the pigeon, pigeons (m.), pigeon (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कभी",
    translation: "sometime, ever",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कभी कभी",
    translation: "sometimes",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कम",
    translation: "small, few, little, fewer, less",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कमरा",
    translation: "the room, room",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कमरे",
    translation: "the room, room, rooms",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "करना",
    translation: "do, want, to do it, have to do, to do",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "करो",
    translation: "do, make",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कल",
    translation: "yesterday, tomorrow",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कविता",
    translation: "a poem, poem, poetry",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कहाँ",
    translation: "where",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कहानी",
    translation: "story, a story",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "क़मीज़",
    translation: "shirt, shirts",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "का",
    translation: "to, s, 's, of",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कान",
    translation: "ear, ears",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "काम",
    translation: "work, job, task",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "काला",
    translation: "black",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "काली",
    translation: "black",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "काले",
    translation: "black",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कि",
    translation: "ki, (as in",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कितना",
    translation: "how, so, how much",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कितनी",
    translation: "such, how much, so, how many",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कितने",
    translation: "such, how much, so, how many",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "किताब",
    translation: "book, the book",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "किताबें",
    translation: "books",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "किस",
    translation: "whom, what, who, where, which",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "किसी",
    translation: "at any price, someone, somebody",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "की",
    translation: "to, about, of, 's, in",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कुछ",
    translation: "anything, a few, some, few, something, a little",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कुत्ता",
    translation: "the dog, dog",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कुत्ते",
    translation: "dogs, the dogs, dog",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कुर्सी",
    translation: "chair, the chair",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "के",
    translation: "s', of, 's, in",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "केला",
    translation: "a banana, banana",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "केले",
    translation: "the bananas, banana, the banana, bananas",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कैसा",
    translation: "how, what type of, what kind of",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कैसी",
    translation: "how, what type of, what kind of",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कैसे",
    translation: "how, what type of, what kind of",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "को",
    translation: "on, in, at, to",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कोई",
    translation: "someone, nobody, anybody, any",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कौन",
    translation: "who",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कौनसा",
    translation: "what, which",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "कौनसी",
    translation: "what, which",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "क्या",
    translation: "do, what, would, did, is, does, are, have, has",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "क्यों",
    translation: "why",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "क्योंकि",
    translation: "as, because, since",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खड़ा",
    translation: "standing (adj.) (m.), standing (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खड़ी",
    translation: "standing (f.), standing (f.) (adj.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खड़े",
    translation: "standing",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ख़ुश",
    translation: "happy",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ख़ुशबू",
    translation: "fragrance",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खाओ",
    translation: "eat, have",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खाता",
    translation: "account, has, eats, have, eat",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खाती",
    translation: "eat",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खाते",
    translation: "has, eats, have, eat",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खाना",
    translation:
      "eating, food, eat, lunch, dinner, to eat, to have, the food, meal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खाने",
    translation: "eating, eat, food, the food, lunch, dinner, to eat, meal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खाया",
    translation: "ate (m.), ate, eat",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खाये",
    translation: "ate, ate (pl.), ate (m.) (polite)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खिड़कियाँ",
    translation: "windows, the windows",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खिड़की",
    translation: "the window, window",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खेल",
    translation: "play, sport, games, game, sports",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खेलना",
    translation: "play, to play, playing",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खेलने",
    translation: "to play",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खेला",
    translation: "did play, played",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खेले",
    translation: "play, played",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "खेलो",
    translation: "play, play!",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गया",
    translation: "gone (m.), went (m.), went to (m.), left (m.), left",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गयी",
    translation: "go, did go (f.), went (f.), gone (f.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गये",
    translation:
      "go, went (m.) (polite), went (pl.), gone (pl.), gone (m.) (polite)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गरम",
    translation: "hot, warm",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गर्मी",
    translation: "summer, heat, hot",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गली",
    translation: "lane, street",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ग़लत",
    translation: "incorrect, wrong",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गा",
    translation: "sing",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गाँव",
    translation: "native place, villages, a village, the village, village",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गाजर",
    translation: "carrot (m.), carrots (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गाड़ियाँ",
    translation: "cars",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गाड़ी",
    translation: "train, automobile, a car, vehicle, car",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गाय",
    translation: "cow",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गायें",
    translation: "cows",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गिलास",
    translation: "glass, glasses",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "गुरुवार",
    translation: "thursdays, thursday",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "घंटा",
    translation: "hours, hour, bell",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "घंटे",
    translation: "hours, hour",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "घर",
    translation: "house, houses, the house, home",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "घास",
    translation: "grass (f.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चल रहा",
    translation: "(m.) walking, having, is going",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चल रही",
    translation: "moving (f.), walking, (f.) walking",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चल रहे",
    translation: "(pl.) walking",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाँद",
    translation: "the moon, moon",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाकू",
    translation: "the knife, knives, knife",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाचा",
    translation: "uncle, uncles",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाची",
    translation: "aunt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाय",
    translation: "the tea, tea",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चार",
    translation: "four",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चावल",
    translation: "rice",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाहता",
    translation: "likes, want",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाहती",
    translation: "want",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाहते",
    translation: "likes, want, like",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चाहिए",
    translation: "wants, needs, should, want, need, must",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चिट्ठी",
    translation: "postcard, the letter, a letter, letters, letter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चीनी",
    translation: "the sugar, Chinese, sugar",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चूहा",
    translation: "the mouse, mouse, rat (m.), mouse (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "चूहे",
    translation: "mouse (obl. case), mice (m.), rats (m.), rat (obl. case)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "छत",
    translation: "terrace, roof",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "छह",
    translation: "six",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "छाता",
    translation: "an umbrella, umbrella, the umbrella",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "छोटा",
    translation: "younger, short, young, shorter, smaller, small, little",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "छोटी",
    translation: "younger, short, young, shorter, smaller, small, little",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "छोटे",
    translation: "little, short, younger, young, smaller, small",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जगह",
    translation: "room, area, space, spot, site, place",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जनवरी",
    translation: "january",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जवान",
    translation: "young",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जवाब",
    translation: "answer, the answer, an answer, answers",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ज़मीन",
    translation: "ground, the floor, land, the ground, floor",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ज़्यादा",
    translation: "much, more",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जा",
    translation: "go, leave, go to",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जा रहा",
    translation: "going to, going",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जा रही",
    translation: "going to, going",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जा रहे",
    translation: "going to, going",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जाओ",
    translation: "go, leave, go to, to",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जाता",
    translation: "leave, go, does, goes, would go",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जाती",
    translation: "go, goes to, goes",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जाते",
    translation: "go to, went",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जानवर",
    translation: "the animals, animals, animal, an animal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जाना",
    translation: "go, leave, known, to go",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जायेंगी",
    translation: "will go",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जायेंगे",
    translation: "will go",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जायेगा",
    translation: "will go, will go to",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जायेगी",
    translation: "will go, will go to",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जुराब",
    translation: "sock, socks",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जुलाई",
    translation: "july",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "जून",
    translation: "june",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "टमाटर",
    translation: "tomato, the tomatoes, tomatoes, the tomato",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ठंड",
    translation: "cold, winter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ठीक",
    translation: "OK, all right, right, fine, well",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "डेढ़",
    translation: "half past one, one and a half, at half-past one",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ढाई",
    translation: "two and a half, half past two",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तारा",
    translation: "star",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तारे",
    translation: "stars",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तालाब",
    translation: "pond, lake",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तीन",
    translation: "three",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तुझसे",
    translation: "than you, you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तुझे",
    translation: "(to) you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तुम",
    translation: "you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तुमसे",
    translation: "of you, with you, you, than you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तुम्हारा",
    translation: "you, your, yours",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तुम्हारी",
    translation: "your, yours",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तुम्हारे",
    translation: "you, your, yours",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तुम्हें",
    translation: "you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तू",
    translation: "you (informal & intimate)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तूने",
    translation: "you",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तेज़",
    translation: "sharp, loud, fast, faster than",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तेरा",
    translation: "yours (m.), your (m.), your",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तेरी",
    translation: "your (f.), your, yours (f.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तेरे",
    translation: "you, your, your (pl. obj.), yours",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तेल",
    translation: "the oil, oil",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तैर",
    translation: "swim",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तैर रहा",
    translation: "(m.) swimming, (m.) floating",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तैर रही",
    translation: "(f.) swimming",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तैर रहे",
    translation: "(pl.) swimming, (pl.) floating",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "तो",
    translation: "then, so, if",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "था",
    translation: "was, there was, had",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "थी",
    translation: "had, (f. singular) used to, there was, (f.) was, it was",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "थीं",
    translation: "(f.) was (formal), (f. plural) used to, had, (f.) were",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "थे",
    translation: "had, used to, there were, was, were",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दरवाज़ा",
    translation: "door, the door",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दरवाज़े",
    translation: "door, doors, the doors",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दर्द",
    translation: "ache, pain, pains, aches",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दर्द हो रहा",
    translation: "",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दवाई",
    translation: "medicine, medication, tablet",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दस",
    translation: "ten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दाँत",
    translation: "teeth, tooth",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दाँतों",
    translation: "teeth., teeth",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दादा",
    translation: "grandfathers, grandfather",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दादी",
    translation: "grandmother",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दिन",
    translation: "day, days",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दिल",
    translation: "heart",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दिल्ली",
    translation: "Delhi",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दिसंबर",
    translation: "december",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दीवार",
    translation: "wall, the wall",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दीवारें",
    translation: "walls, the walls",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दुकान",
    translation: "shop, the store, store, the shop",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दुखी",
    translation: "sad",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दूध",
    translation: "the milk, milk",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दूर",
    translation: "far, away, far away",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "देखा",
    translation: "look, looked at, watch, looked, see, watched, saw",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "देखी",
    translation: "watch, watched, saw, seen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "देखे",
    translation: "sees, saw",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "देखो",
    translation: "see, look at, watch, look",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दो",
    translation: "let, two, give",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दोनों",
    translation: "the two, both, both of them, both of",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दोपहर",
    translation: "the afternoon, afternoon",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दोस्त",
    translation: "a friend, friends, friend",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दौड़ रहा",
    translation: "(m.) running",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दौड़ रही",
    translation: "running",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "दौड़ रहे",
    translation: "(pl.) running",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "धन्यवाद",
    translation: "thank you, thanks",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "धीरे",
    translation: "slowly, slow, slower",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नदी",
    translation: "river, the river",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नमक",
    translation: "salt, the salt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नमकीन",
    translation: "salty",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नमस्ते",
    translation: "hi, bye, hello",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नवंबर",
    translation: "november",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नहीं",
    translation: "not, do not, no",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नाक",
    translation: "his nose, a nose, nose",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नाना",
    translation: "grandfather",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नानी",
    translation: "grandmother",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नाम",
    translation: "name, the name, names",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नाश्ता",
    translation: "breakfast, snack, snacks",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नींबू",
    translation: "lemons (m.), lemon (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "नौ",
    translation: "nine",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पक्षी",
    translation: "birds (m.), bird (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पड़ोसी",
    translation: "neighbors, neighbour, neighbor",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़",
    translation: "read, studying, study",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़ता",
    translation: "read, study, reads",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़ती",
    translation: "read, study, studies, reads",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़ते",
    translation: "read, study",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़ना",
    translation: "read, study, do read, to study, to read",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़ने",
    translation: "read, to read, to study, study",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़ा",
    translation: "read, studied",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़ी",
    translation: "read, studied",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पढ़ो",
    translation: "read, study",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पतलून",
    translation: "trousers, pants",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पति",
    translation: "husband",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पत्ते",
    translation: "cards, leaves",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पत्नी",
    translation: "wife",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पर",
    translation: "on, over, after, but, to, at",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "परसों",
    translation: "the day before yesterday, the day after tomorrow",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "परिवार",
    translation: "families, the family, family",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पसंद",
    translation: "likes, like",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पहनो",
    translation: "wear",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पहले",
    translation: "previously, ago, before, earlier, first",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पाँच",
    translation: "five",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पानी",
    translation: "water, waters, the water",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पास",
    translation: "close, near, has, have, nearby",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पिता",
    translation: "fathers, father",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पिया",
    translation: "drank, did drink, drink",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पियो",
    translation: "drink",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पी",
    translation: "drink, drank, did drink, have",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पीछे",
    translation: "behind, after, back",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पीता",
    translation: "drink",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पीती",
    translation: "drink, have",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पीते",
    translation: "drink, has, have, drinks",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पीना",
    translation: "to have, drinking, drink, to drink, have",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पेड़",
    translation: "The tree, tree, a tree, trees",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पैर",
    translation: "foot, feet, legs, leg",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पैरों",
    translation: "feet, legs",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पौधा",
    translation: "plant",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पौधे",
    translation: "plants, plant",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "पौने",
    translation: "quarter to",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "प्यार",
    translation: "love, loves",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "फल",
    translation: "fruit, fruits",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "फ़रवरी",
    translation: "February",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "फूफा",
    translation: "uncle",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "फूल",
    translation: "the flowers, flowers, flower",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बंद",
    translation: "close, closed, turn off, switch off",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बच्चे",
    translation: "kids, the children, child, the kids, children, kid",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बड़ा",
    translation: "huge, great, old, older, big, large, bigger",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बड़ी",
    translation: "older (f.), big (f.), old, old (f.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बड़े",
    translation: "huge, great, old, big, larger, large, bigger",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बताओ",
    translation: "tell",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बहन",
    translation: "sister",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बहनें",
    translation: "sisters",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बहुत",
    translation: "very much, very, many, a lot, much, so, too, a lot of",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बात",
    translation: "thing, speaking with, talk, matter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बाद",
    translation: "after, later",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बादल",
    translation: "clouds, cloud, the cloud",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बाल",
    translation: "hair",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बाहर",
    translation: "outside, abroad, out",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बिल्लियाँ",
    translation: "the cats, cats",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बिल्ली",
    translation: "the cat, cat",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बिस्तर",
    translation: "beds, bed, the bed",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बीमार",
    translation: "ill, sick, unwell",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बुआ",
    translation: "aunt (father's sister)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बुखार",
    translation: "fever",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बुधवार",
    translation: "Wednesdays, Wednesday",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बुरा",
    translation: "bad, evil",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बुरी",
    translation: "bad, evil",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बुरे",
    translation: "bad, bad (pl.), evil (pl.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बेटा",
    translation: "boy, son",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बेटियाँ",
    translation: "daughters",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बेटी",
    translation: "girl, daughter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बेटे",
    translation: "sons, son",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बेहतर",
    translation: "better, better than",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बैठा",
    translation: "sitting (adj.) (m.), sitting (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बैठी",
    translation: "sitting (f.) (adj.), sitting (f.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बैठे",
    translation: "(pl.) sitting",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बैठो",
    translation: "sit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बोतल",
    translation: "bottle",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बोल",
    translation: "talk, speak",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "बोलो",
    translation: "say, tell, speak",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "भाई",
    translation: "bro, brother, the brothers, brothers",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "भाई-बहन",
    translation: "siblings, the siblings",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "भारत",
    translation: "India",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "भी",
    translation: "even, also, too",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मंगलवार",
    translation: "tuesday, tuesdays",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मई",
    translation: "May",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मकान",
    translation: "house, houses, the house, home",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मछली",
    translation: "fish",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मत",
    translation: "not, opinions, do not, voice, vote",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "माँ",
    translation: "mom, mother",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "माँसाहारी",
    translation: "non-vegetarian",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "माता-पिता",
    translation: "parents",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मामा",
    translation: "uncle (mother's brother), uncle",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मामी",
    translation: "aunt, (mother's brother's wife)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मार्च",
    translation: "March",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मिनट",
    translation: "minutes, minute",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मिलना",
    translation: "to meet",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मिलने",
    translation: "meet, to meet",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मुँह",
    translation: "mouth, face",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मुझसे",
    translation: "me, to me, from me, than me, with me",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मुझे",
    translation: "me, I am, I",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मुश्किल",
    translation: "difficulty, hard, difficult",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "में",
    translation: "to, lies in, in, during, has, in them, at",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मेज़",
    translation: "the table, tables, table",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मेरा",
    translation: "my, mine, of mine",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मेरी",
    translation: "me, I, my, mine, of mine",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मेरे",
    translation: "me, I, my, mine, of mine",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मेहनत",
    translation: "hardwork",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मैं",
    translation: "I, me, I'd",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मैंने",
    translation: "I",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मोर",
    translation: "peacocks (m.), peacock (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मौसम",
    translation: "season, climate, weather, the weather, seasons",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मौसा",
    translation: "(mother's sister's husb.), uncle",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "मौसी",
    translation: "aunt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "यह",
    translation: "this, the, it, she, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "यहाँ",
    translation: "here",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "या",
    translation: "or",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ये",
    translation: "this, these, she, they, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "रंग",
    translation: "color, paints, colors, the colors, paint",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "रविवार",
    translation: "Sundays, Sunday",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "रहता",
    translation: "live, stay",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "रहती",
    translation: "live, lives",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "रहते",
    translation: "live",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "रात",
    translation: "the night, night",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "रिश्ता",
    translation: "relation, the relation, relation between, relationship",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "रिश्तेदार",
    translation: "relative, relatives",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लंबा",
    translation: "tall, lengthy, taller, long",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लंबी",
    translation: "tall, lengthy, taller, long",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लंबे",
    translation: "tall, taller, tall (pl.), lengthy, long (pl.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लग रही",
    translation: "",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लगती",
    translation: "feel, feels",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लड़का",
    translation: "boy, the boy",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लड़कियाँ",
    translation: "girls, the girls",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लड़की",
    translation: "girl, the girl",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लड़के",
    translation: "boy, the boys, the boy, boys",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लाल",
    translation: "red",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लिखा",
    translation: "written, wrote",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लिखी",
    translation: "wrote",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लेकिन",
    translation: "However, but",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लेखक",
    translation: "writer",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लो",
    translation: "take",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "लोग",
    translation: "persons, public, people",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "वह",
    translation: "it, the, he, she, that",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "वहाँ",
    translation: "there",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "वे",
    translation: "her, that, she, they, the, those, he",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "शनिवार",
    translation: "Saturdays, Saturday",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "शराब",
    translation: "liquor, alcohol (f.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "शहर",
    translation: "town, city, the cities, towns, cities, the city",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "शाकाहारी",
    translation: "vegetarian, a vegetarian",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "शाम",
    translation: "the evening, evening",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "शायद",
    translation: "probably, might, maybe",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "शिक्षक",
    translation: "the teacher, teacher, the teachers, teachers",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "शुक्रवार",
    translation: "Friday, Fridays",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सकता",
    translation: "could, can",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सकती",
    translation: "may, can",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सकते",
    translation: "could, can",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सड़क",
    translation: "road (f.), the road",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सफ़ेद",
    translation: "white",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सब",
    translation: "everybody, everything, all, everyone, all of",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सबसे",
    translation: "most, the most",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सब्ज़ियाँ",
    translation: "the vegetables, vegetables",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सब्ज़ी",
    translation: "vegetables, vegetable (f.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "समय",
    translation: "the time, a time, period, times, while, time",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सर्दी",
    translation: "winters, winter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सलाद",
    translation: "salad (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सवा",
    translation: "quarter, quarter past, one fourth",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सवाल",
    translation: "question, questions",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सही",
    translation: "right, fine, correct",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "साढ़े",
    translation: "half past",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सात",
    translation: "seven",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "साथ",
    translation: "with, together",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "साल",
    translation: "the year, year, years old, years",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सितंबर",
    translation: "september",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सिर",
    translation: "head, heads, his head",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सुंदर",
    translation: "beautiful",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सुनो",
    translation: "listen to, hear, listen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सुबह",
    translation: "in the morning, morning",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सूरज",
    translation: "the sun, sun",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "से",
    translation:
      "from, since, than, better, to, through, have, out of, with, by",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सेब",
    translation: "the apple, apple, apples",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सो",
    translation: "slept, sleep",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सोना",
    translation: "to sleep, gold, sleeping, sleep, have to sleep, the gold",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सोने",
    translation: "to sleep, sleep, gold",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सोमवार",
    translation: "Mondays, Monday",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "सौ",
    translation: "hundred, one hundred, a hundred",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "स्कूल",
    translation: "schools, school, the school",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "स्वस्थ",
    translation: "healthy, fit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हज़ार",
    translation: "thousand",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हम",
    translation: "we, us",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हमने",
    translation: "we",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हमें",
    translation: "we, us",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हमेशा",
    translation: "always, forever, ever",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हर",
    translation: "every, each",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हवा",
    translation: "breeze, wind, the air, air",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हाँ",
    translation: "yes",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हाथ",
    translation: "hands, hand, arms, arm",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हाथी",
    translation: "elephant (m.), elephants (m.)",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हाथों",
    translation: "his hands, hands",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हिंदी",
    translation: "Hindi",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हूँ",
    translation: "am",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "है",
    translation: "it is, is, has, you are, there is",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हैं",
    translation: "do, be, is, are, have, there are",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "हो",
    translation: "do you, be, are",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "होता",
    translation: "would have been, becomes, would have",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "होती",
    translation: "would have been, would, is, are, were, would have",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "होते",
    translation: "would have been, are, would, were",
    partOfSpeech: "",
    gender: "",
  },
];

export default wordList;