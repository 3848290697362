const wordList = 
[
    {
        "word": "a",
        "translation": "to, at, in, for, into, on",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "abbassa",
        "translation": "(he/she/it) lowers, (he/she/it) reduces, (you) lower, lower",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abbassano",
        "translation": "(they) dim, (they) lower, (they) lower oneself, (they) abase, (they) abase oneself, (they) bring down, (they) cast down, (they) decline, (they) decrease, (they) depress, (they) dip, (they) droop, (they) drop, (they) fail, (they) fall, (they) fold down, (they) go down, (they) humble, (they) press down, (they) pull down, (they) recede, (they) reduce, (they) sink, (they) soften, (they) subside, (they) turn down, (they) weaken, (they) wind down, lower",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abbassare",
        "translation": "(to) lower, (to) to lower, (to) to reduce, (to) abase, (to) bring down, (to) decrease, (to) dim, (to) dip, (to) droop, (to) drop, (to) fail, (to) fold down, (to) go down, (to) lower oneself, (to) press down, (to) pull down, (to) reduce, (to) sink, (to) subside, (to) to abase oneself, (to) to bring down, (to) to cast down, (to) to decline, (to) to depress, (to) to dim, (to) to drop, (to) to fall, (to) to go down, (to) to humble, (to) to pull down, (to) to recede, (to) to sink, (to) to soften, (to) turn down, (to) weaken, (to) wind down, abase oneself, cast down, decline, depress, fall, humble, let, lower, recede, soften, write, wrote",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abbassiamo",
        "translation": "(we) lower, (we) lower oneself, (we) recede, (we) abase, (we) abase oneself, (we) bring down, (we) cast down, (we) decline, (we) decrease, (we) depress, (we) dim, (we) dip, (we) droop, (we) drop, (we) fail, (we) fall, (we) fold down, (we) go down, (we) humble, (we) press down, (we) pull down, (we) reduce, (we) sink, (we) soften, (we) subside, (we) turn down, (we) weaken, (we) wind down, lower",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abbastanza",
        "translation": "enough, pretty, rather, sufficient",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "abbia",
        "translation": "has, have",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abbiamo",
        "translation": "(we) have, (we) have got, do we have, keep, own",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abbiano",
        "translation": "have, (they) have",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abbigliamento",
        "translation": "clothing, clothes, dress",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abiti",
        "translation": "clothes, (you singular) live, do you live, dresses, stay, suits, wear",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abito",
        "translation": "(I) live, suit, dress, item of clothing, stay, three-piece (suit), two-piece (suit)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abituale",
        "translation": "regular, habitual, frequent, normal, usual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "abituali",
        "translation": "usual, habitual, normal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "abitudine",
        "translation": "habit, custom, practice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "accende",
        "translation": "(you) turn on, (he/she/it) turns on, (you) put on, (he/she/it) becomes aroused, (he/she/it) catches (fire), (he/she/it) comes on, (he/she/it) fires, (he/she/it) flames, (he/she/it) flares up, (he/she/it) gets heated, (he/she/it) grows heated, (he/she/it) inflames, (he/she/it) kindles, (he/she/it) lights, (he/she/it) lights up, (he/she/it) opens, (he/she/it) puts on, (he/she/it) raises, (he/she/it) sparks off, (he/she/it) strikes, (he/she/it) switches on, (he/she/it) takes out, (you) become aroused, (you) catch (fire), (you) come on, (you) fire, (you) flame, (you) flare up, (you) get heated, (you) grow heated, (you) inflame, (you) kindle, (you) light, (you) light up, (you) open, (you) raise, (you) spark off, (you) strike, (you) switch on, (you) take out, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accendere",
        "translation": "light, (to) light, (to) turn on, (to) flare up, (to) grow heated, (to) inflame, (to) light up, (to) open, (to) put on, (to) spark off, (to) switch on, (to) to catch (fire), catch (fire), come on, get heated, kindle, put, puts, started, strike, take out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accendiamo",
        "translation": "(we) turn on, (we) strike, (we) light, light, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accendo",
        "translation": "(I) turn on, (I) light, (I) light up, (I) strike, light, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accettano",
        "translation": "(they) accept, (they) admit, (they) take (on), wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accettate",
        "translation": "(you) accept, (you) admit, (you) take (on), wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accetti",
        "translation": "(you) accept, (you) admit, (you) take (on)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accordo",
        "translation": "agreement, deal, accord, arrangement, chord, give, harmony, settlement, understanding",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "acido",
        "translation": "sour, acidic, acid, harsh, sharp, tart",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "acqua",
        "translation": "water",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ad",
        "translation": "to, a, in, about, at, each, every, favor, favour, for, per",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "addirittura",
        "translation": "even",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "adesso",
        "translation": "now",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "adolescente",
        "translation": "teenager, adolescent, teenage, teenagers",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "adolescenti",
        "translation": "teenagers, adolescent, teenager",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "adulti",
        "translation": "adults, adult, full-grown, grown-up, mature",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "adulto",
        "translation": "adult, grown-up, mature",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aereo",
        "translation": "airplane, aerial, plane",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aeroplani",
        "translation": "airplanes, aircraft, airplane",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aeroplano",
        "translation": "airplane, aircraft",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aeroporto",
        "translation": "airport",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "affare",
        "translation": "deal, business, affair, affairs, bargain, contraption, gadget, matter, show, situation, thing, whatsit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "affari",
        "translation": "business, deals, affairs, bargain",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "affittare",
        "translation": "(to) rent, rent out, (to) to rent, (to) hire out, (to) let (out), (to) to rent out, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "africa",
        "translation": "Africa",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "aggiunge",
        "translation": "(he/she/it) adds, (you) add",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aggiungere",
        "translation": "(to) add, join, put, puts, throws",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aggiungi",
        "translation": "(you) add",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aggiungo",
        "translation": "(I) add",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aggiungono",
        "translation": "(they) add",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agli",
        "translation": "to, to the, for, a, at, each, every, garlic, per",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "aglio",
        "translation": "garlic",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "agosto",
        "translation": "August",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "agricoltura",
        "translation": "agriculture, farming",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ai",
        "translation": "to the, at, on, in, to",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "aiuta",
        "translation": "(you) help, (he/she/it) helps, (he/she/it) aids",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aiutare",
        "translation": "(to) help, (to) to help, (to) help each other, (to) aid, (to) one another, (to) to assist, (to) to facilitate, assist, facilitate",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aiuto",
        "translation": "(I) help, (I) help each other, (I) aid, (I) assist, (I) facilitate, (I) one another, assistance, assistant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "al",
        "translation": "to the, to, at the, (in)to the, a, at, for, into the, of the, on the, the, with, with the",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "alba",
        "translation": "dawn, dawning, daybreak",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "albergo",
        "translation": "hotel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alberi",
        "translation": "trees",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "albero",
        "translation": "tree",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alcol",
        "translation": "alcohol, drank, spirits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alcuna",
        "translation": "any, a few, anybody, no, nobody, none, some",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "alcune",
        "translation": "some, a few, no",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "alcuni",
        "translation": "some, a few, certain, any, anybody, no, nobody, none",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "alcuno",
        "translation": "any, a few, anybody, no, nobody, none, some",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "all'",
        "translation": "to, at, at the",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "alla",
        "translation": "to, to the, at, a, at the, in the, the",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "alle",
        "translation": "at, to, for, as, to the",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "allegria",
        "translation": "mirth, cheerfulness, jolliness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "allena",
        "translation": "(he/she/it) trains, (he/she/it) exercises, (he/she/it) spars, (he/she/it) coaches, (he/she/it) practices, (he/she/it) practises, (he/she/it/you) tub, (you) coach, (you) exercise, (you) practice, (you) practise, (you) spar, (you) train, train, trains",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allenatore",
        "translation": "coach, trainer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "allo",
        "translation": "at the, at, to, a, each, every, favor, favour, for, in, per",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "allora",
        "translation": "then, since then, from then on, at that moment, at that time, at the time, ever since, since, so, well",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "almeno",
        "translation": "at least, all, little",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "alta",
        "translation": "tall, high, loud, deep, from above, from on high, from the top, high-pitched, long, noble, thick, top, upper",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "alte",
        "translation": "tall, high, from on high, long",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "altezza",
        "translation": "height, altitude, depth, elevation, greatness, highness, loftiness, nobility, pitch, stature, width",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "alti",
        "translation": "tall, high, from the top, deep, from above, from on high, high-pitched, long, loud, noble, thick, top, upper",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "alto",
        "translation": "tall, high, keep, larger",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "altra",
        "translation": "other",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "altre",
        "translation": "others, more, other",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "altri",
        "translation": "other",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "altrimenti",
        "translation": "otherwise, or else, differently",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "altro",
        "translation": "else, far, other",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "alunni",
        "translation": "students, pupil, student",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ama",
        "translation": "(he/she/it) loves, (he/she/it) enjoys, (he/she/it) is fond of",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amare",
        "translation": "love, (to) love, (to) love oneself, (to) make love, (to) to be fond of, (to) to care for, (to) to cherish, (to) to enjoy, be fond of, care for, cherish, enjoy, go, gone, loving, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ambasciatore",
        "translation": "ambassador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ambiente",
        "translation": "environment, background, room",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ambulanza",
        "translation": "ambulance, field ambulance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "america",
        "translation": "America, United States",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "americana",
        "translation": "American (English), american",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "americane",
        "translation": "American, Americans",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "americani",
        "translation": "American, Americans",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "americano",
        "translation": "American (English), in the American style, american",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ami",
        "translation": "love, (you) love, (you) make love, (you) are fond of, (you) care for, (you) cherish, (you) enjoy, (you) love oneself, be fond of",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amica",
        "translation": "friend, friends",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amichevole",
        "translation": "friendly (match), convivial, friendly, good-natured, good-tempered, informal, privately negotiated",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "amichevoli",
        "translation": "friendly (match), user-friendly, convivial",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "amici",
        "translation": "friends",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "amicizia",
        "translation": "friendship",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amico",
        "translation": "friend, friends",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "amministrazione",
        "translation": "administration, government, management",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amo",
        "translation": "(I) love, (I) am fond of, (I) enjoy, (fish) hook",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amore",
        "translation": "love, love affairs, darling, Cupid, Eros, devotion, duck(y), duckie, heartthrob, honey, lovemaking, mating, sweetie",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anatra",
        "translation": "duck",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "anche",
        "translation": "even, also, too, as well, hips, so",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ancora",
        "translation": "still, yet, even, again, anchor escapement, more",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "andai",
        "translation": "went, (I) went, (I) went away, (I) went back, (I) went off, (I) went on, (I) went out, (I) went too far, (I) went up, go, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andammo",
        "translation": "went, (we) went, (we) went away, (we) went back, (we) went off, (we) went on, (we) went out, (we) went too far, (we) went up, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andando",
        "translation": "going, going on, going back, being bound, going away, going down, going off, going out, going too far, going up, leading",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andarci",
        "translation": "go there, (to) go, (to) to go along, (to) be bound, (to) be slow, (to) fall, (to) finish up, (to) get ahead, (to) go away, (to) go back, (to) go badly, (to) go down, (to) go on, (to) go out, (to) go too far, (to) go up, (to) increase, (to) rise, (to) run fast, (to) to come down, (to) to decrease, (to) to get away, (to) to get back, (to) to get down, (to) to go forward(s), (to) to go off, (to) to increase, (to) to keep going, (to) to lead, (to) to leave, (to) to pass away, (to) to rise, (to) to run slow, (to) to wind up, (to) to work, pace, speed, step, stride",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "andare",
        "translation": "go, fell, going, leave, to go",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andarono",
        "translation": "went, (they) went, (they) went off, (they) went away, (they) went back, (they) went on, (they) went out, (they) went too far, (they) went up, go, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andasse",
        "translation": "(he/she/it/you) went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andassi",
        "translation": "(I/you) went, (I/you) led, (I/you) was/were bound",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andate",
        "translation": "(you) go, went, gone",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andava",
        "translation": "",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andavamo",
        "translation": "(we) were going, (we) went, went, (we) came down, (we) decreased, (we) fell, (we) finished up, (we) got ahead, (we) got away, (we) got back, (we) got down, (we) increased, (we) kept going, (we) led, (we) left, (we) passed away, (we) ran fast, (we) ran slow, (we) rose, (we) used to be bound, (we) used to be slow, (we) used to come down, (we) used to decrease, (we) used to fall, (we) used to finish up, (we) used to get ahead, (we) used to get away, (we) used to get back, (we) used to get down, (we) used to go, (we) used to go along, (we) used to go away, (we) used to go back, (we) used to go badly, (we) used to go down, (we) used to go forward(s), (we) used to go off, (we) used to go on, (we) used to go out, (we) used to go too far, (we) used to go up, (we) used to increase, (we) used to keep going, (we) used to lead, (we) used to leave, (we) used to pass away, (we) used to rise, (we) used to run fast, (we) used to run slow, (we) used to wind up, (we) used to work, (we) was/were bound, (we) was/were slow, (we) went along, (we) went away, (we) went back, (we) went badly, (we) went down, (we) went forward(s), (we) went off, (we) went on, (we) went out, (we) went too far, (we) went up, (we) were being bound, (we) were being slow, (we) were coming down, (we) were decreasing, (we) were falling, (we) were finishing up, (we) were getting ahead, (we) were getting away, (we) were getting back, (we) were getting down, (we) were going along, (we) were going away, (we) were going back, (we) were going badly, (we) were going down, (we) were going forward(s), (we) were going off, (we) were going on, (we) were going out, (we) were going too far, (we) were going up, (we) were increasing, (we) were keeping going, (we) were leading, (we) were leaving, (we) were passing away, (we) were rising, (we) were running fast, (we) were running slow, (we) were winding up, (we) were working, (we) winded up, (we) worked, (we) wound up, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andiamo",
        "translation": "(we) go, (we) are going, (we) will go, do we go, gone, let's go, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andrai",
        "translation": "go, (you) will go, (you) will go on, (you) will be slow, (you) will come down, (you) will fall, (you) will get away, (you) will get back, (you) will get down, (you) will go away, (you) will go back, (you) will go down, (you) will go off, (you) will go out, (you) will go too far, (you) will go up, (you) will lead, (you) will pass away, (you) will rise, (you) will run fast, (you) will run slow, (you) will wind up, (you) will work, gone, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andrebbe",
        "translation": "go, (he/she/it/you) would go, (he/she/it/you) would go away, (he/she/it/you) would be slow, (he/she/it/you) would come down, (he/she/it/you) would fall, (he/she/it/you) would get away, (he/she/it/you) would get back, (he/she/it/you) would get down, (he/she/it/you) would go back, (he/she/it/you) would go down, (he/she/it/you) would go off, (he/she/it/you) would go on, (he/she/it/you) would go out, (he/she/it/you) would go too far, (he/she/it/you) would go up, (he/she/it/you) would lead, (he/she/it/you) would pass away, (he/she/it/you) would rise, (he/she/it/you) would run fast, (he/she/it/you) would run slow, (he/she/it/you) would wind up, (he/she/it/you) would work, let, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andrebbero",
        "translation": "go, (they) would go, (they) would go away, (they) would be slow, (they) would come down, (they) would fall, (they) would get away, (they) would get back, (they) would get down, (they) would go back, (they) would go down, (they) would go off, (they) would go on, (they) would go out, (they) would go too far, (they) would go up, (they) would lead, (they) would pass away, (they) would rise, (they) would run fast, (they) would run slow, (they) would wind up, (they) would work, gone, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andremo",
        "translation": "(we) will go, (we) will keep going, (we) will lead, go, gone, let, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andresti",
        "translation": "go, (you) would go, (you) would go up, (you) would be slow, (you) would come down, (you) would fall, (you) would get away, (you) would get back, (you) would get down, (you) would go away, (you) would go back, (you) would go down, (you) would go off, (you) would go on, (you) would go out, (you) would go too far, (you) would lead, (you) would pass away, (you) would rise, (you) would run fast, (you) would run slow, (you) would wind up, (you) would work, gone",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "angeli",
        "translation": "angels, angel, darling",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "angelo",
        "translation": "angel, angels, darling",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "angolo",
        "translation": "corner, (I) angle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anima",
        "translation": "soul, (you) bring life to, (you) come to life, (he/she/it) animates, (he/she/it) brightens up, (he/she/it) brings [something] to life, (he/she/it) brings life to, (he/she/it) comes alive, (he/she/it) comes to life, (he/she/it) encourages, (he/she/it) enlivens, (he/she/it) gets heated, (he/she/it) grows heated, (he/she/it) inspires, (he/she/it) lights up, (he/she/it) warms up, (he/she/it/you) jazz up, (he/she/it/you) liven up, (he/she/it/you) pep (up), (you) animate, (you) brighten up, (you) bring [something] to life, (you) come alive, (you) encourage, (you) enliven, (you) get heated, (you) grow heated, (you) inspire, (you) light up, (you) warm up, anima, bore, core, moving force, sound post, spirit",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "animale",
        "translation": "animal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "animali",
        "translation": "animals, animal, brute",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anni",
        "translation": "years",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anno",
        "translation": "year, years",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "annoiata",
        "translation": "bored, get bored, got/gotten bored, bore",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "antica",
        "translation": "ancient, old, ancients, antiquarian, antique, bygone, classical, past",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "antichi",
        "translation": "ancient, antique, old",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "antico",
        "translation": "antique, ancient, classical, old, past",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "anzi",
        "translation": "on the contrary, nor, quite the opposite",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ape",
        "translation": "bee",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aperta",
        "translation": "open (out), open (out, open up, open, open out, opened, opened (out, opened (out), opened out, opened up",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "appartamento",
        "translation": "apartment, flat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "appartenere",
        "translation": "belong, (to) belong",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appena",
        "translation": "just, as soon as, barely, hardly, only, scarcely, the moment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "appetito",
        "translation": "appetite, whetted/whet one’s appetite, longed for, coveted",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "appuntamenti",
        "translation": "date, appointment",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "appuntamento",
        "translation": "appointment, date",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "apra",
        "translation": "open (out), open (out, open up, break, clear (up), crack, draw back, fold back, head, open out, part, put on, spread (out), start up, switch on, turn on, undo, unfold, unwrap, up), widen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendo",
        "translation": "opening (out, opening (out), opening out, opening up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprì",
        "translation": "opened, (he/she/it) opened, fold back, open (out), spread (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apri",
        "translation": "opened, (he/she/it) opened, fold back, open (out), spread (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprii",
        "translation": "opened, (I) opened, fold back, open (out), spread (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprile",
        "translation": "April",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aprirà",
        "translation": "(he/she/it/you) will open (out), (he/she/it/you) will open (out, (he/she/it/you) will open up, (he/she/it/you) will draw back, (he/she/it/you) will fold back, (he/she/it/you) will head, (he/she/it/you) will open out, (he/she/it/you) will part, (he/she/it/you) will put on, (he/she/it/you) will turn on, (he/she/it/you) will undo, (he/she/it/you) will up)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprirai",
        "translation": "(you) will open (out), (you) will spread (out), (you) will fold back, call, called",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprire",
        "translation": "(to) open (out, (to) open (out), (to) open out, (to) fold back, (to) open up, began, broke, broken, call, called, clear, opened, spread (out), started",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprirebbe",
        "translation": "(he/she/it/you) would open (out, (he/she/it/you) would open (out), (he/she/it/you) would open up, (he/she/it/you) would crack, (he/she/it/you) would draw back, (he/she/it/you) would fold back, (he/she/it/you) would head, (he/she/it/you) would open out, (he/she/it/you) would part, (he/she/it/you) would put on, (he/she/it/you) would turn on, (he/she/it/you) would undo, (he/she/it/you) would up), called, opened",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprirei",
        "translation": "(I) would open (out, (I) would open (out), (I) would open out, (I) would crack, (I) would draw back, (I) would fold back, (I) would head, (I) would open up, (I) would part, (I) would put on, (I) would turn on, (I) would undo, (I) would up), opened",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apriremo",
        "translation": "(we) will open (out), (we) will spread (out), (we) will fold back, called",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprireste",
        "translation": "(you) would open (out), (you) would open (out, (you) would open out, (you) would crack, (you) would draw back, (you) would fold back, (you) would head, (you) would open up, (you) would part, (you) would put on, (you) would turn on, (you) would undo, (you) would up), call",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprirò",
        "translation": "(I) will open (out), (I) will spread (out), (I) will fold back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprirono",
        "translation": "turn on, switch on, put on, break, clear (up), crack, draw back, fold back, head, open (out, open (out), open out, open up, opened, part, spread (out), start up, undo, unfold, unwrap, up), widen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprisse",
        "translation": "opened, (he/she/it/you) opened (out, (he/she/it/you) opened (out), (he/she/it/you) folded back, (he/she/it/you) opened out, (he/she/it/you) opened up, (he/she/it/you) spread (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprissi",
        "translation": "opened, (I/you) opened (out, (I/you) opened (out), (I/you) opened out, (I/you) opened up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprite",
        "translation": "(you pl.) open, clear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apriva",
        "translation": "(he/she/it/you) was/were opening (out, (he/she/it/you) was/were opening (out), (he/she/it/you) was/were opening up, (he/she/it/you) opened (out), (he/she/it/you) used to open (out), (he/she/it/you) was/were opening out, began, called, opened, started",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprivano",
        "translation": "(they) were opening (out), (they) were opening (out, (they) were opening up, (they) were opening out, (they) were up), began, called, opened, started",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprivo",
        "translation": "(I) was/were opening (out), (I) was/were opening (out, (I) was/were opening up, (I) was/were opening out, began, called, opened, started",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arancia",
        "translation": "orange",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arancione",
        "translation": "orange",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "arancioni",
        "translation": "orange",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "architetto",
        "translation": "architect, (I) cook up, (I) concoct",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "architettura",
        "translation": "architecture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "argentina",
        "translation": "Argentinian, Argentina",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "argentini",
        "translation": "Argentinian, Argentine, Argentines, Argentinians",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "argentino",
        "translation": "Argentinian, Argentine",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "argento",
        "translation": "silver",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "argomenti",
        "translation": "subjects, (you) argue, topics",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "argomento",
        "translation": "topic, subject, argument, (I) argue, (subject) matter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aria",
        "translation": "air, aria, breeze, expression, look, wind",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arma",
        "translation": "weapon, (he/she/it) arms, (you) arm",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "armi",
        "translation": "weapons, arms, (you) arm, (you) cock",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arrabbiata",
        "translation": "angry",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "arrabbiati",
        "translation": "angry, get angry, catch rabies",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "arrabbiato",
        "translation": "angry, got/gotten angry, caught rabies",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "arresto",
        "translation": "(I) arrest, (I) stop, (I) halt, failures, stop, stopped",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arriva",
        "translation": "(he/she/it) arrives, (he/she/it) comes, (he/she/it) is successful, (he/she/it) is arriving, (you) are successful, (you) arrive, (you) come, arrive, arrives, be successful, carry, finish",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivai",
        "translation": "(I) arrived, (I) came, (I) was/were successful, arrived, came, finished",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivando",
        "translation": "arriving, being successful, coming",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivano",
        "translation": "(they) arrive, finish, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivare",
        "translation": "(to) arrive, (to) come, get, (to) to be successful, arrived, arrives, be successful, came, carried, carry, come, finished, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivarono",
        "translation": "(they) arrived, (they) came, (they) was/were successful, arrived, came, come, finished",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivasse",
        "translation": "came, (he/she/it/you) came, (he/she/it/you) was/were successful, (he/she/it/you) arrived",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivassi",
        "translation": "(I/you) arrived, (I/you) came, (I/you) was/were successful",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivate",
        "translation": "arrived, (you) arrive, (you) come, come, finish",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivava",
        "translation": "used to come, (he/she/it/you) was/were arriving, (he/she/it/you) used to arrive, (he/she/it/you) arrived, (he/she/it/you) would arrive, arrived, came, carried, finished",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivederci",
        "translation": "goodbye, bye, good-bye",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "arriverà",
        "translation": "(he/she/it/you) will arrive, (he/she/it/you) will come, (he/she/it/you) will be successful, arrived, finish",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arriverai",
        "translation": "(you) will arrive, (you) will come, (you) will be successful, come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arriveranno",
        "translation": "(they) will arrive, (they) will come, are going to arrive, arrived",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arriverei",
        "translation": "(I) would arrive, (I) would come, (I) would be successful, arrived, come, finished",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arriveremo",
        "translation": "(we) will come, (we) will arrive, (we) will be successful, arrived, come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arriveresti",
        "translation": "(you) would arrive, (you) would come, (you) would be successful, come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivi",
        "translation": "(you) arrive, (he/she/it) arrives, carry, finish",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arrivino",
        "translation": "arrive, be successful, come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivo",
        "translation": "(I) arrive, arrival, carry, coming, finish, finished",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arrivò",
        "translation": "(I) arrive, arrival, carry, coming, finish, finished",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrostiamo",
        "translation": "(we) roast, (we) toast, (we) broil",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrostisci",
        "translation": "(you) roast, (you) broil, (you) toast",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrostisco",
        "translation": "(I) roast, (I) toast, (I) broil",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arte",
        "translation": "art, arts, craft, guild, skill, trade",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "articoli",
        "translation": "(you) articulate, (you) enunciate, (you) bend",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "articolo",
        "translation": "article, (I) articulate, (I) bend, papers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "artista",
        "translation": "artist, master",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "artisti",
        "translation": "artists, artist, master",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "asciuga",
        "translation": "(he/she/it) dries (off), (he/she/it) dries out, (he/she/it) dries up, (he/she/it) becomes lean, (he/she/it) dries off, (he/she/it) dries oneself (off), (he/she/it) hardens, (he/she/it) mops up, (he/she/it) sets, (he/she/it) wipes, (he/she/it) wipes away, (he/she/it/you) blow-dry, (he/she/it/you) sop up, (you) become lean, (you) dry (off), (you) dry off, (you) dry oneself (off), (you) dry out, (you) dry up, (you) harden, (you) mop up, (you) set, (you) wipe, (you) wipe away, dries",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asciugamani",
        "translation": "towels, towel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "asciugo",
        "translation": "(I) dry (off), (I) dry out, (I) dry off, (I) dry up, dry",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ascolta",
        "translation": "(he/she/it) listens to, (formal you) listen to, listens, look",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ascoltano",
        "translation": "(they) listen to, (they) hear, (they) auscultate, hear, heard, listen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ascoltare",
        "translation": "(to) listen to, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ascoltiamo",
        "translation": "(we) listen to, (we) hear, (we) auscultate, hear, heard, listen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asia",
        "translation": "Asia",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "aspetta",
        "translation": "(you) wait for, (he/she/it) waits for, be waiting for, (he/she/it) awaits, (he/she/it) expects, (he/she/it) is waiting for, (you) are waiting for, (you) await, (you) expect, carry",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettai",
        "translation": "(I) waited for, (I) awaited, (I) was/were waiting for, expected, waited",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettando",
        "translation": "waiting for, being waiting for, expecting, awaiting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettare",
        "translation": "(to) wait for, (to) to await, (to) to be waiting for, await, be waiting for, expect, expected, wait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettarono",
        "translation": "(they) waited for, (they) awaited, (they) was/were waiting for, waited",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettassero",
        "translation": "(they) expected, (they) awaited, (they) waited for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettassi",
        "translation": "(I/you) was/were expecting, (I/you) was/were waiting for, (I/you) awaited, (I/you) waited for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettassimo",
        "translation": "(we) awaited, (we) was/were waiting for, (we) waited for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettate",
        "translation": "(you) wait for, (you) expect, (you) await, (you) are waiting for, be waiting for, carry, expect, meet",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettavo",
        "translation": "(I) was/were expecting, (I) was/were awaiting, (I) was/were waiting for, (I) awaited, (I) expected, (I) used to await, (I) used to be waiting for, (I) used to expect, (I) used to wait for, (I) waited for, (I) was/were being waiting for, expected, waited",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspetterà",
        "translation": "(he/she/it/you) will wait for, (he/she/it/you) will await, (he/she/it/you) will be waiting for, meet, waited",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspetterai",
        "translation": "(you) will wait for, (you) will await, (you) will be waiting for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspetteranno",
        "translation": "(they) will wait for, (they) will be waiting for, (they) will await, waited",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspetterei",
        "translation": "(I) would wait for, (I) would await, (I) would be waiting for, expect, meet, waited",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspetteremo",
        "translation": "(we) will wait for, (we) will be waiting for, (we) will await",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettereste",
        "translation": "(you) would expect, (you) would await, (you) would wait for, expect",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspetti",
        "translation": "(you) expect, (you) wait for, (you) are waiting for, (you) await, carry",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettiamo",
        "translation": "(we) wait for, (we) await, (we) expect, carry, let's wait, wait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspettino",
        "translation": "expect, await, wait for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aspetto",
        "translation": "(I) wait for, (I) await, (I) expect, aspect, carry, look",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "assicurazione",
        "translation": "insurance, assurance, premium",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "associazione",
        "translation": "association, organization",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "assolutamente",
        "translation": "absolutely, dead, at all, by all means, completely, perfectly, vitally, worse",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "atleta",
        "translation": "athlete",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "atleti",
        "translation": "athlete, athletes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "atmosfera",
        "translation": "atmosphere, ambiance, air, ambience, atmospherics, feel, feel(ing), felt, flavour, mood",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "attacchi",
        "translation": "attacks, (you) put up, (you) attach, fits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "attenta",
        "translation": "careful, alert, attentive, caring, close, open-eyed, thorough, watchful",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "attenti",
        "translation": "cautious, careful, attentive, attention, beware",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "attento",
        "translation": "careful, alert, attentive, caring, close, open-eyed, special, thorough, watchful",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "attività",
        "translation": "activity, activities, task, asset, business",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "attore",
        "translation": "actor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "attori",
        "translation": "actors, actor, claimant, demandant, player, players, protagonist, suitor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "attraversano",
        "translation": "(they) cross, (they) go across, (they) travel across",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attraversare",
        "translation": "(to) cross, get, go, go across, gone, got, run, runs, travel across, walk, walks, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attraverso",
        "translation": "through, across, (I) go through, (I) cross, (I) flow through, (I) get through, (I) go across, (I) go under, (I) pierce, (I) run through, (I) run under, (I) straddle, (I) travel across, by, by (means of), crosswise, diagonally",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attrice",
        "translation": "actress, actor, actresses, player",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aula",
        "translation": "classroom, courtroom, class, lecture hall",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "autobus",
        "translation": "bus, buses",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "automobile",
        "translation": "car, automobile",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "automobili",
        "translation": "automobiles, cars, automobile, car",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "autorità",
        "translation": "authority, authority figure, expert",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "autunno",
        "translation": "autumn, fall",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avanti",
        "translation": "ahead, from then on(wards), from here on(wards), forward(s), from now on(wards), from that day, towards",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "avanzata",
        "translation": "progress, advanced, advance",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "avanzato",
        "translation": "advanced, entered, put forward",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "avemmo",
        "translation": "(we) had, had, have, kept, offered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avendo",
        "translation": "having, stocking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avere",
        "translation": "have, to have, (to) have, assets, came, credit, had, having, hit, keep, kept, offered, offers, own, property, wear, wears",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avesse",
        "translation": "(he/she/it/you) had",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avessi",
        "translation": "(I/you) had",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avete",
        "translation": "have, do you have, had, keep, own, you have, you own",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avrà",
        "translation": "will have, (he/she/it/you) will have, had, keep, kept, might have, must have, own",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avranno",
        "translation": "have, (they) will have, had, own, they must have (colloq.)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avrebbe",
        "translation": "have, (he/she/it/you) would have, had, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avremo",
        "translation": "have, (we) will have, had, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avrò",
        "translation": "have, (I) will have, keep, own, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avventura",
        "translation": "adventure, (he/she/it) ventures, (you) venture, adventures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "avventure",
        "translation": "adventures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "avvocati",
        "translation": "lawyers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avvocato",
        "translation": "lawyer, attorney",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "azione",
        "translation": "action, act, deed, effect, play, share",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "azzurra",
        "translation": "(light) blue, azure, blue",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "azzurre",
        "translation": "(light) blue, member of the Forza Italia party, athlete in an Italian national team, azure, blue",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "azzurri",
        "translation": "(light) blue, blue",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "azzurro",
        "translation": "(light) blue, blue",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "baci",
        "translation": "kisses, (you) kiss (each other), perfects",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bacia",
        "translation": "(he/she/it) kisses (each other), (you) kiss (each other)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "baciano",
        "translation": "(they) kiss (each other)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bacio",
        "translation": "(I) kiss (each other), excellent, perfect",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bagnate",
        "translation": "(you) wet, (you) moisten, (you) dampen",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bagnati",
        "translation": "wet, dampen, moisten, soak",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bagnato",
        "translation": "wetted/wet, dampened, moistened",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bagno",
        "translation": "bathroom, (I) soak, (I) water, (I) wet, bath, restroom, swim, toilet, washroom",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "balcone",
        "translation": "balcony",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "balena",
        "translation": "whale",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ballare",
        "translation": "dancing, to dance",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "balli",
        "translation": "(you) wobble, dances, (you) dance, (you) fidget",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ballo",
        "translation": "(I) dance, (I) wobble, (I) fidget, ball",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bambina",
        "translation": "baby (girl), (little) girl, daughter, child, missed",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bambini",
        "translation": "children, boys, kids",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bambino",
        "translation": "child, baby (boy), (little) boy, boy, childish, infant, little boy, son",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "banana",
        "translation": "banana (plug), banana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "banane",
        "translation": "bananas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "banca",
        "translation": "bank",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bandiera",
        "translation": "flag, colours, ensign",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bar",
        "translation": "bar, coffee bar, café, coffee shop",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "barca",
        "translation": "boat, mow, shock, stack",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bassa",
        "translation": "short, low",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bassi",
        "translation": "low, shallow",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "basso",
        "translation": "short, bass (guitar), low, meant, quiet, shallow",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "basta",
        "translation": "enough, be enough, (he/she/it) is enough, (he/she/it) is sufficient, (he/she/it) lasts, (he/she/it/you) as long as, (you) are enough, (you) are sufficient, (you) last, basting, be sufficient, do, it is enough, tacking, tuck",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "battaglia",
        "translation": "battle, (you) battle, battle scene, (he/she/it) battles, (he/she/it) fights",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "batteria",
        "translation": "battery, drum kit, drums",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bella",
        "translation": "beautiful, nice, fair copy, pretty",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bellezza",
        "translation": "beauty, good looks, nobility",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bello",
        "translation": "nice, beautiful, pretty, good, good-looking, handsome, lovely",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ben",
        "translation": "well, good, affection, assets, belongings, better and better, carefully, comfortably, completely, correctly, fine, fondness, goods, possession, possessions, properly, property, right, sake, smartly, thoroughly, welfare",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "bene",
        "translation": "well, fine, good",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "benessere",
        "translation": "well-being, wellness, affluence, comfort, ease, wealth",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "benissimo",
        "translation": "very well, really well",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "bensì",
        "translation": "but, but (rather), indeed",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "benzina",
        "translation": "gas, gasoline, petrol",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bere",
        "translation": "(to) drink (in), (to) to swallow, (to) to booze, (to) buy, (to) eat (up) petrol, (to) swallow, (to) swallow a story (whole), (to) swallow water, (to) to be on the bottle, (to) to hit the bottle, ate, be on the bottle, booze, bought, drank, hit the bottle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bevanda",
        "translation": "beverage, drink, drank",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "beve",
        "translation": "(he/she/it) drinks, (you) drink",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bevendo",
        "translation": "drinking (in), buying, swallowing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bevete",
        "translation": "(you) drink",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bevi",
        "translation": "(you) are drinking, (do you) drink, (you) drink, drink",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beviamo",
        "translation": "(we) drink",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bevo",
        "translation": "(I) drink",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bevono",
        "translation": "(they) drink (in), (they) are drinking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bianca",
        "translation": "white",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bianche",
        "translation": "white",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bianco",
        "translation": "white wine, blank (space), white",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "biblioteca",
        "translation": "library",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bicchiere",
        "translation": "glass",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bicchieri",
        "translation": "(drinking) glasses",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bici",
        "translation": "bicycle, bike",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bicicletta",
        "translation": "bicycle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "biciclette",
        "translation": "bicycles, bicycle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "biglietti",
        "translation": "tickets, notes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "biglietto",
        "translation": "note, (admission) ticket, lottery ticket, card, fare, ticket",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "biologia",
        "translation": "biology",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bionde",
        "translation": "blonde, blond, cigarette, fair, fair-haired, lager",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "biondo",
        "translation": "blond, blonde, fair, fair-haired, fair-haired man",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "birra",
        "translation": "beer",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "biscotti",
        "translation": "cookies, biscuits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "biscotto",
        "translation": "cookie, biscuit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bisogno",
        "translation": "need, necessity",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bistecca",
        "translation": "steak",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bocca",
        "translation": "mouth, lips, throat",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bolle",
        "translation": "(he/she/it) boils, (he/she/it) ferments, (you) boil",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bolletta",
        "translation": "bill, note",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "borsa",
        "translation": "bag, handbag, purse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "borse",
        "translation": "bags, bag, briefcase, handbag, stock exchange",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boschi",
        "translation": "woods, forest, forests",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bosco",
        "translation": "woods, forest",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bottiglia",
        "translation": "bottle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bottiglie",
        "translation": "bottles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "braccio",
        "translation": "arm",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "brasile",
        "translation": "Brazil",
        "partOfSpeech": "Proper noun",
        "gender": "Masculine"
    },
    {
        "word": "brasiliani",
        "translation": "Brazilian, Brazilians",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brasiliano",
        "translation": "Brazilian",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brava",
        "translation": "good, clever, skillful, talented",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bravo",
        "translation": "good, capable, clever, great, honest, kind, skilful, skillful, talented, trustworthy",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "breve",
        "translation": "short, brief, breve, little, news flash, quick",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brillante",
        "translation": "brilliant, shiny, bright, diamond, gleaming, glittering, glossy, glowing, inspirational, lively, luminous, shining, sparky, vivacious",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "brillanti",
        "translation": "brilliant, clever, shiny",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "brucia",
        "translation": "(he/she/it) burns (up), (he/she/it) burns off, (he/she/it) burns out, (he/she/it) burns down, (he/she/it) is burning, (you) burn (up), (you) burn off, (you) burn out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bruciare",
        "translation": "burn out, (to) burn (up), (to) burn off, (to) to burn out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "brutta",
        "translation": "ugly, bad, rough (copy), rough work",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "brutte",
        "translation": "ugly, bad, terrible",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "brutti",
        "translation": "ugly, bad, terrible",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brutto",
        "translation": "bad, ugly, awful, nasty, poor, terrible, worse",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bugia",
        "translation": "lie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bugie",
        "translation": "lies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "buio",
        "translation": "dark, frowning, gloomy, nights",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "buon",
        "translation": "good, happy, merry, right",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "buona",
        "translation": "good, good natured, happy",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "buonanotte",
        "translation": "goodnight, good night",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "buonasera",
        "translation": "good evening",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "buongiorno",
        "translation": "good morning, good day",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "burro",
        "translation": "butter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "busta",
        "translation": "envelope, bag, folder",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "c'",
        "translation": "there",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "cade",
        "translation": "(he/she/it) falls on, (he/she/it) falls (down), (he/she/it) falls off, (he/she/it) collapses, (he/she/it) comes down, (he/she/it) comes off, (he/she/it) comes out, (he/she/it) droops, (he/she/it) drops, (he/she/it) drops off, (he/she/it) drops out, (he/she/it) falls out, (he/she/it) hangs, (he/she/it) sags, (he/she/it) sits, (he/she/it) slopes, (he/she/it) topples, (you) collapse, (you) come down, (you) come off, (you) come out, (you) droop, (you) drop, (you) drop off, (you) drop out, (you) fall (down), (you) fall off, (you) fall on, (you) fall out, (you) hang, (you) sag, (you) sit, (you) slope, (you) topple, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cadere",
        "translation": "(to) fall (down), (to) fall out, (to) fall off, (to) fall on, (to) sag, (to) slope, (to) to collapse, (to) to come down, (to) to come off, (to) to come out, (to) to droop, (to) to drop, (to) to drop off, (to) to drop out, (to) to hang, (to) to sit, (to) to topple, came, collapse, come, come down, come off, come out, droop, drop, drop off, drop out, fell, go, gone, hang, sit, strike, topple, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cadono",
        "translation": "(they) fall (down), (they) fall off, (they) fall out, (they) fall on, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caduta",
        "translation": "fell, (down)fall, spill, tumble",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caffè",
        "translation": "coffee, coffee shop, café, coffee bar, coffee tree, coffees, cup(s) of coffee, espresso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calcio",
        "translation": "soccer, (I) kick, kick, pistol grip",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calda",
        "translation": "hot, hotf, warm",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "caldi",
        "translation": "hot, hotf, warm",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "caldo",
        "translation": "hot, friendly, full-blooded, warm",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calendario",
        "translation": "calendar, agenda, schedule, time-frame, time-table",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calza",
        "translation": "stocking, sock, (you) are apt, (he/she/it) shoes, (he/she/it) wears, (you) wear",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "calze",
        "translation": "socks, stockings",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cambi",
        "translation": "changes, change, (you) change, (you) alter, (you) exchange, (you) replace, (you) shift, derailleur gears, exchange rate, exchanges, gears, in exchange, in return, reliefs",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambia",
        "translation": "(he/she/it) changes, (you) change, (he/she/it) exchanges, (he/she/it) alters, (he/she/it) replaces, (he/she/it) shifts, (you) alter, (you) exchange, (you) replace, (you) shift",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiando",
        "translation": "changing, altering, exchanging, replacing, shifting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiano",
        "translation": "change, (they) change, (they) exchange, (they) alter, (they) replace, (they) shift",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiare",
        "translation": "change, (to) change, (to) to change, (to) alter, (to) to exchange, (to) to replace, (to) to shift, broke, exchange, replace, shift, to cash",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiarono",
        "translation": "(they) changed, (they) exchanged, (they) replaced, turned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiasse",
        "translation": "change, (he/she/it/you) changed, (he/she/it/you) exchanged, (he/she/it/you) replaced",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiassero",
        "translation": "(they) changed, (they) exchanged, (they) replaced",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiava",
        "translation": "(he/she/it/you) was/were changing, (he/she/it/you) used to change, (he/she/it/you) changed, broke, turned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambierà",
        "translation": "change, (he/she/it/you) will change, (he/she/it/you) will replace, (he/she/it/you) will exchange",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambierebbe",
        "translation": "change, (he/she/it/you) would change, (he/she/it/you) would shift, (he/she/it/you) would alter, (he/she/it/you) would exchange, (he/she/it/you) would replace",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambieremo",
        "translation": "change, (we) will change, (we) will replace, (we) will exchange",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambieresti",
        "translation": "change, (you) would change, (you) would replace, (you) would exchange",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambino",
        "translation": "change, exchange, replace",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambio",
        "translation": "change, (I) change, (I) exchange, (I) alter, (I) replace, (I) shift, (car) transmission, derailleur gears, exchange rate, gears, in exchange, in return, relief",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "camera",
        "translation": "room, bedroom, chamber",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camere",
        "translation": "rooms, chamber, room",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cameriere",
        "translation": "waiter, server",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "camicia",
        "translation": "shirt, blouse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camicie",
        "translation": "shirts, blouses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cammina",
        "translation": "(he/she/it) walks, (you) walk, (he/she/it) works, (you) work, walk, walks",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "camminando",
        "translation": "walking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "camminano",
        "translation": "(they) walk, (they) work, walk",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "camminare",
        "translation": "(to) walk, walking, walk, walks",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cammini",
        "translation": "(you) walk, (you) work, walk",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "camminiamo",
        "translation": "(we) walk, (we) work, walk",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cammino",
        "translation": "(I) walk, (I) work, course, march, path, paths, progress, road, walk, way",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "campagna",
        "translation": "campaign, country, countryside, drive",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "campeggi",
        "translation": "campgrounds, (you) camp, (you) dominate, (you) stand out, campsites",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campeggio",
        "translation": "camping, camping ground, (I) camp, (I) dominate, (I) stand out, campground, campsite, logwood",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campi",
        "translation": "fields, (you) bring into relief, (you) live, (you) set off, (you) struggle along",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campionato",
        "translation": "championship, sampled",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campione",
        "translation": "champion, sample, cross-section, pattern, specimen, standard, swatch, tester",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campo",
        "translation": "camp, course, domain, field",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canale",
        "translation": "channel, canal, culvert",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cancelli",
        "translation": "gates, (you) delete, (you) erase, clear",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cancello",
        "translation": "gate, (I) erase, (I) delete, clear, strike",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "candidata",
        "translation": "candidate, applicant, examinee",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "candidati",
        "translation": "candidates, run, stand (as a candidate)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "candidato",
        "translation": "candidate, stood (as a candidate), nominated",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cane",
        "translation": "dog",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cani",
        "translation": "dogs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canta",
        "translation": "(he/she/it) sings, (formal you) sing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantano",
        "translation": "(they) sing, (they) sing of, (they) chirp",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantare",
        "translation": "(to) sing, (to) to sing, (to) sing of, (to) chirp, (to) crow, (to) squeal, epic poem, sing, singing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantiamo",
        "translation": "(we) sing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "canto",
        "translation": "(I) sing, poem, song",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "canzone",
        "translation": "song, canzone, entries, epic poem",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "canzoni",
        "translation": "songs, (you) make fun of, (you) laugh at, (you) tease",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "capace",
        "translation": "capable, capacious, clever, competent, dexterous, good, roomy, skilful, skillful, talented, useful",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "capaci",
        "translation": "capable, competent, good",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "capacità",
        "translation": "ability, capacity, skill",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "capelli",
        "translation": "hair",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "capendo",
        "translation": "understanding, understanding each other, comprehending",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capi",
        "translation": "bosses, afresh, anew, article, boss, cape, chapter, chief, da capo, end, foreman, forewoman, head, headland, item, leader, mind, strand, to depend on, to end up, to refer to",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "capii",
        "translation": "understood, (I) understood, (I) understood each other, (I) comprehended, get, got, saw, see, seen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capimmo",
        "translation": "understood, (we) understood, (we) understood each other, (we) comprehended, get, got, saw, see",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capirai",
        "translation": "(you) will understand, (you) will understand each other, (you) will comprehend, get, see",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capiranno",
        "translation": "(they) will understand, (they) will comprehend, (they) will understand each other, see, understood",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capire",
        "translation": "(to) understand, (to) to comprehend, (to) understand each other, (to) realize, comprehend, get, got, make, makes, saw, see, seen, take, takes, took, understand, understanding, understood, work",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capirebbe",
        "translation": "(he/she/it/you) would understand, (he/she/it/you) would understand each other, (he/she/it/you) would comprehend, get, see, seen, understood",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capirebbero",
        "translation": "(they) would understand, (they) would understand each other, (they) would comprehend, get, seen, understood",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capiremo",
        "translation": "(we) will understand, (we) will understand each other, (we) will comprehend, get, see",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capisca",
        "translation": "understand, understand each other, comprehend, realize",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capisce",
        "translation": "(he/she/it) understands, (you) understand, (he/she/it) comprehends, (he/she/it) realizes, (you) comprehend, (you) realize, saw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capisci",
        "translation": "(you) understand, (you) understand each other, (you) comprehend, get, saw, see, seen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capisco",
        "translation": "(I) understand",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capisse",
        "translation": "understood, (he/she/it/you) understood, (he/she/it/you) understood each other, (he/she/it/you) comprehended, (he/she/it/you) realized",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capissi",
        "translation": "(I/you) understood, (I/you) understood each other, (I/you) comprehended",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capitano",
        "translation": "(I) captain, (they) come, (they) arrive",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "capitoli",
        "translation": "chapters, (you) capitulate, (you) surrender",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "capitolo",
        "translation": "chapter, (I) capitulate, (I) surrender, item, section",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "capiva",
        "translation": "understood, (he/she/it/you) understood, (he/she/it/you) understood each other, (he/she/it/you) comprehended, (he/she/it/you) realized, (he/she/it/you) used to comprehend, (he/she/it/you) used to realize, (he/she/it/you) used to understand, (he/she/it/you) used to understand each other, (he/she/it/you) was/were comprehending, (he/she/it/you) was/were realizing, (he/she/it/you) was/were understanding, (he/she/it/you) was/were understanding each other, got, saw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capivo",
        "translation": "understood, (I) understood, (I) was/were understanding, (I) comprehended, (I) realized, (I) understood each other, (I) used to comprehend, (I) used to realize, (I) used to understand, (I) used to understand each other, (I) was/were comprehending, (I) was/were realizing, (I) was/were understanding each other, got, saw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capo",
        "translation": "boss, leader, head, afresh, anew, article, cape, chapter, chief, da capo, directors, end, foreman, forewoman, headland, item, manager, mind, strand, to depend on, to end up, to refer to",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cappelli",
        "translation": "hats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cappello",
        "translation": "hat, cap, capping, hats, head, introductory paragraph, lampshade, preamble, top",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cappotto",
        "translation": "(over)coat, coats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caramella",
        "translation": "candy, (he/she/it) caramelizes, (you) caramelize, piece of candy, sweet",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caramelle",
        "translation": "candies, candy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carattere",
        "translation": "character, nature, make, makes, personality, types",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carcere",
        "translation": "prison, jail",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "cari",
        "translation": "expensive, (you) cause [something] to decay, (you) decay, costly, dear",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "carina",
        "translation": "cute, pretty, good-looking, lovely, nice",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "carino",
        "translation": "pretty, cute, good-looking, handsome, kind, lovely, nice",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "carne",
        "translation": "meat, flesh",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caro",
        "translation": "expensive, dear, (be)loved, costly, darling, honey, sweetheart",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "carota",
        "translation": "carrot, core (sample)",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carriera",
        "translation": "career",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carta",
        "translation": "paper, card, map",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cartolina",
        "translation": "postcard",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cartoline",
        "translation": "postcards",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "casa",
        "translation": "house, home, place",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cascata",
        "translation": "waterfall, cascade, falls, fell",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "case",
        "translation": "houses, building, apartment, flat, home, house, square",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caso",
        "translation": "case, chance, by accident, by chance, by coincidence, event, events, fate, instances",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "castelli",
        "translation": "castles, castle, forecastle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "castello",
        "translation": "castle, forecastle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "categoria",
        "translation": "category, class, group",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "categorie",
        "translation": "category, class",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cattedrale",
        "translation": "cathedral",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cattiva",
        "translation": "bad, bad person, evil, malevolent, mean, wicked",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cattivi",
        "translation": "bad, malevolent, mean",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cattivo",
        "translation": "bad, mean, evil, foul, malicious, meant, nasty, naughty, negative, poor, rough, terrible, unhealty, unkind, vicious, wicked, worse",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "causa",
        "translation": "cause, case, (you) cause, (he/she/it) causes, (he/she/it) creates",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cause",
        "translation": "causes, (he/she/it) creates, cause",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cavalli",
        "translation": "horses",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cavallo",
        "translation": "horse, horses, they go horseback riding",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caviglia",
        "translation": "ankle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ce",
        "translation": "there, us",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "celebrazione",
        "translation": "celebration, ceremony",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cena",
        "translation": "dinner, (you) have dinner, (he/she/it) has dinner, (he/she/it) dines, (he/she/it) eats dinner, (you) dine, supper",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ceniamo",
        "translation": "(we) have dinner, (we) dine, (we) eat dinner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "centimetri",
        "translation": "centimeters, centimeter, centimetre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centimetro",
        "translation": "centimeter, centimeters, centimetre, tape measure",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cento",
        "translation": "a hundred, one hundred",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "centro",
        "translation": "middle, (I) balance, (I) centre, (I) hit, center of town, downtown, mall, midpoint",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cerca",
        "translation": "(he/she) searches for, (he/she/it) looks for, (you) look for, (he/she/it) pursues, (he/she/it) seeks, (he/she/it) tries to find, (you) pursue, (you) seek, (you) try to find, collection of alms, get, scenting, tracking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cercando",
        "translation": "looking for, pursuing, seeking, trying to find",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerchi",
        "translation": "(you) look for, (you) seek",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerco",
        "translation": "I look for, I try, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "certa",
        "translation": "certain, for certain, certainly, firm, fixed, for sure, reliable, some, such, sure, surely",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "certamente",
        "translation": "certainly, course, definitely, undoubtedly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "certe",
        "translation": "certain, for certain, sure, certainly, firm, fixed, for sure, reliable, some, such, surely",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "certi",
        "translation": "certain, for certain, sure",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "certo",
        "translation": "certainly, sure, course, for certain, for sure, surely",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "cervello",
        "translation": "brain(s), cerebrum, head",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "che",
        "translation": "that, what, than, those, which, who",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "chi",
        "translation": "who, whom",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "chiama",
        "translation": "(he/she) calls, call, is calling",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamai",
        "translation": "(I) called on, (I) called (out), (I) called out, (I) was/were called, called, get, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamando",
        "translation": "calling (out), calling out, calling on, beckoning, being called, bringing in, crying out, declaring, getting, having in, invoking, naming, ordering, phoning, ringing, summoning, terming",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamare",
        "translation": "(to) call, calling, (to) to ring, (to) to phone, called, get, got, had, name, telephones",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamarono",
        "translation": "(they) called (out), (they) called out, (they) called on, (they) was/were called, called, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamasse",
        "translation": "(he/she/it/you) was/were called, (he/she/it/you) called (out), (he/she/it/you) called on, (he/she/it/you) beckoned, (he/she/it/you) brought in, (he/she/it/you) called out, (he/she/it/you) cried out, (he/she/it/you) declared, (he/she/it/you) got, (he/she/it/you) had in, (he/she/it/you) invoked, (he/she/it/you) named, (he/she/it/you) ordered, (he/she/it/you) phoned, (he/she/it/you) rang, (he/she/it/you) ringed, (he/she/it/you) summoned, (he/she/it/you) termed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamassi",
        "translation": "(I/you) invoked, (I/you) rang, (I/you) beckoned, (I/you) brought in, (I/you) called, (I/you) called (out), (I/you) called (subj.), (I/you) called on, (I/you) called out, (I/you) cried out, (I/you) declared, (I/you) got, (I/you) had in, (I/you) named, (I/you) ordered, (I/you) phoned, (I/you) ringed, (I/you) summoned, (I/you) termed, (if I/you) were to call",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamata",
        "translation": "call, called, curtain call, named, summons",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chiamava",
        "translation": "(he/she/it/you) used to call, (he/she/it/you) was/were calling (out), (he/she/it/you) was/were calling out, (he/she/it/you) called (out), (he/she/it/you) called on, (he/she/it/you) called out, (he/she/it/you) used to be called, (he/she/it/you) was/were being called, (he/she/it/you) was/were called, (he/she/it/you) was/were calling on, called, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamerà",
        "translation": "(he/she/it/you) will call (out), (he/she/it/you) will call on, (he/she/it/you) will call out, (he/she/it/you) will cry out, (he/she/it/you) will get, (he/she/it/you) will have in, (he/she/it/you) will name, (he/she/it/you) will ring, (he/she/it/you) will term, call, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamerai",
        "translation": "(you) will call (out), (you) will call on, (you) will call out, (you) will cry out, (you) will get, (you) will have in, (you) will name, (you) will ring, (you) will term, call, called",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiameranno",
        "translation": "(they) will call (out), (they) will call out, (they) will cry out, call, called",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamerei",
        "translation": "(I) would call (out), (I) would call on, (I) would call out, (I) would cry out, (I) would get, (I) would have in, (I) would name, (I) would ring, (I) would term, get, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamereste",
        "translation": "(you) would call (out), (you) would call out, (you) would call on, (you) would cry out, (you) would get, (you) would have in, (you) would name, (you) would ring, (you) would term, call, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamerò",
        "translation": "(I) will call (out), (I) will call out, (I) will call on, (I) will cry out, (I) will get, (I) will have in, (I) will name, (I) will ring, (I) will term, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiami",
        "translation": "call",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamiamoci",
        "translation": "(let us) call ourselves, (let us) call on, (let us) call each other",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "chiamino",
        "translation": "cry out, call out, call (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamò",
        "translation": "(he/she/it/you) called (out), (he/she/it/you) called on, (he/she/it/you) called out, (he/she/it/you) was/were called, called, get, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiamo",
        "translation": "(he/she/it/you) called (out), (he/she/it/you) called on, (he/she/it/you) called out, (he/she/it/you) was/were called, called, get, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiara",
        "translation": "clear, (egg) white, Chiara, bright, fair, lager, light, pale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "chiaramente",
        "translation": "clearly, distinctly, plainly, straight",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "chiave",
        "translation": "key, rhyme which links one stanza to another, in old Italian songs, clef, clue, spanner, wrench",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chiavi",
        "translation": "keys, (you) shag",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chieda",
        "translation": "ask, ask oneself, ask for, beg, beg for, call for, charge, sue for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedano",
        "translation": "ask, ask for, beg",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiede",
        "translation": "(he/she/it) asks, (he/she/it) asks for, (he/she/it) asks oneself, (he/she/it) begs, (he/she/it) begs for, (he/she/it) calls for, (he/she/it) charges, (he/she/it) sues for, (you) ask, (you) ask for, (you) ask oneself, (you) beg, (you) beg for, (you) call for, (you) charge, (you) sue for, ask",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedemmo",
        "translation": "asked, (we) asked, (we) asked for, (we) begged, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedendo",
        "translation": "asking, asking for, asking oneself, begging, begging for, calling for, charging, suing for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiederai",
        "translation": "ask, (you) will ask for, (you) will beg, (you) will beg for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiederanno",
        "translation": "(they) will ask, (they) will ask for, (they) will beg, (they) will beg for, (they) will call for, (they) will sue for, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedere",
        "translation": "ask, (to) ask, (to) ask for, (to) ask oneself, (to) beg, (to) beg for, (to) call for, (to) sue for, (to) to charge, asked, call, charge, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiederei",
        "translation": "ask, (I) would ask, (I) would ask for, (I) would beg, (I) would beg for, (I) would call for, (I) would sue for, asked, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiederemo",
        "translation": "(we) will ask for, (we) will beg, (we) will beg for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiederò",
        "translation": "ask, (I) will ask, (I) will ask for, (I) will beg, (I) will beg for, (I) will call for, (I) will sue for, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedesse",
        "translation": "asked, (he/she/it/you) asked, (he/she/it/you) asked for, (he/she/it/you) begged, (he/she/it/you) begged for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedete",
        "translation": "ask, (you) ask, (you) ask for, (you) beg",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedeva",
        "translation": "used to ask, (he/she/it/you) was/were asking, (he/she/it/you) was/were asking for, (he/she/it/you) asked for, asked, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedi",
        "translation": "ask, (you) ask, (you) ask oneself, (you) ask for, (you) beg, (you) beg for, (you) call for, (you) charge, (you) sue for, ask for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiediamo",
        "translation": "ask, (we) ask, (we) ask for, (we) ask oneself, (we) beg, (we) beg for, (we) call for, (we) charge, (we) sue for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiedo",
        "translation": "ask, (I) ask, (I) ask for, (I) ask oneself, (I) beg, (I) beg for, (I) call for, (I) charge, (I) sue for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiesa",
        "translation": "church",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chiese",
        "translation": "churches, asked, (he/she/it/you) asked for, (he/she/it/you) asked, (he/she/it/you) begged, (he/she/it/you) begged for, ask, tried",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chilogrammi",
        "translation": "kilograms, kilogram(me)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chilogrammo",
        "translation": "kilogram(me), kilograms",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chilometri",
        "translation": "kilometers, (you) measure [something] in kilometres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chilometro",
        "translation": "kilometer, (I) measure [something] in kilometres, kilometers, kilometre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chimica",
        "translation": "chemistry, chemist",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chissà",
        "translation": "who knows",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "chitarra",
        "translation": "guitar",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chiude",
        "translation": "(he/she/it) closes (down), (he/she/it) shuts (down), (you) close (down), (you) shut (down), stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiudere",
        "translation": "(to) to close, (to) to end, (to) to fence, (to) box in, (to) bring to an end, (to) bring up the rear of, (to) close (down), (to) close up, (to) cloud over, (to) complete, (to) cordon off, (to) draw, (to) end, (to) fasten, (to) fold (up), (to) heal over, (to) plug (up), (to) seal (up), (to) secure, (to) shut (up), (to) sign off, (to) surround, (to) switch off, (to) tie up, (to) to bar, (to) to block, (to) to bring [something] to a close, (to) to bring [something] to an end, (to) to conclude, (to) to do up, (to) to enclose, (to) to finish, (to) to get cloudy, (to) to heal up, (to) to lock, (to) to make, (to) to pull, (to) to seal off, (to) to shut (down), (to) to sign, (to) to stop (up), (to) to wind up, (to) turn off, bar, block, bring [something] to a close, bring [something] to an end, close, closed, conclude, do, do up, done, enclose, fence, fill, finish, get cloudy, go, gone, heal up, lock, make, pull, seal off, sign, stop, stop (up), stopped, walls, went, wind up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiudete",
        "translation": "(you, pl.) close, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiudi",
        "translation": "(you) close (down), (you) close up, (you) do up, (you) shut (down), fill, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chiunque",
        "translation": "anybody, anybody who, anyone",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "chiusa",
        "translation": "closed, closing phrase, complimentary close, conclusion, enclosure, ending, lock, penstock, sluice gate, transverse valley",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ci",
        "translation": "there, us, about it",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "ciao",
        "translation": "hello, hi, bye, goodbye",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "cibo",
        "translation": "food, (I) eat, (I) feed",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cielo",
        "translation": "sky, heaven",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cima",
        "translation": "(you) top, peak, (he/she/it) tops, (he/she/it) clips, (he/she/it) pinches out, (he/she/it) polls, (he/she/it) prunes, (you) clip, (you) pinch out, (you) poll, (you) prune, ace, genius, hilltop, rope, summit, treetop",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cina",
        "translation": "China",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "cinema",
        "translation": "cinema, movie theater, cinemas, movie theaters, movie theatre, movie theatres, movies",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cinese",
        "translation": "Chinese",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cinesi",
        "translation": "Chinese",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "cinquant'",
        "translation": "fifty",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cinquanta",
        "translation": "fifty",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cinque",
        "translation": "five",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cintura",
        "translation": "belt, band",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ciò",
        "translation": "what, that, this, it, those",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "cioccolato",
        "translation": "chocolate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ciotola",
        "translation": "bowl",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cipolla",
        "translation": "onion, bulb, bun, bunion, chignon, rose, turnip",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "circa",
        "translation": "about, around, approximately, as to, concerning, near order, regarding, roughly, round, so, some",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "circo",
        "translation": "circus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "città",
        "translation": "city, cities, town",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cittadini",
        "translation": "city, city residents, civic, national, citizens, citizen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cittadino",
        "translation": "citizen, city, civic",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "classe",
        "translation": "class, classroom",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "classica",
        "translation": "classic, classical",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "classico",
        "translation": "classic, classical, conservative, old favourite, straight, traditional, typical",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cliente",
        "translation": "customer, client, customers, diner, drinker, guest, patron",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "clienti",
        "translation": "customers, clients",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "clima",
        "translation": "climate, atmosphere",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "clinica",
        "translation": "clinic, clinical medicine, clinician",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coda",
        "translation": "line, tail, backup, coda, echo, end, queue, repercussion, scut, tailback",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "codice",
        "translation": "code, codex, manuscript, words",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "codici",
        "translation": "codex, code, manuscript",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cognome",
        "translation": "surname, last name, family name, surnames",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cognomi",
        "translation": "surnames, last names",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "col",
        "translation": "with, with the, over there, although, so what?, there, though",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "colazione",
        "translation": "breakfast",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "collega",
        "translation": "colleague, (he/she/it) connects (up), (you) connect (up), partner",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "collegamenti",
        "translation": "connections, connection, link(-up), hook-up",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "collegamento",
        "translation": "connection, hook-up, link(-up)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colleghi",
        "translation": "colleagues, (you) associate, (you) communicate, (you) connect (up), (you) get in touch, (you) hook up, (you) interlink, (you) join, (you) link, (you) plug in, (you) refer, (you) relate, (you) tie, (you) tie together, co-worker, join, workmate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "collezione",
        "translation": "collection, series",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "collina",
        "translation": "hill",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "collo",
        "translation": "neck",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colonnelli",
        "translation": "colonels",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colorata",
        "translation": "colorful, color (up), colored (up), colour (up), coloured (up), crayon, crayoned, dye, dyed, embellish, embellished, flush, flushed, paint, painted",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "colorati",
        "translation": "colorful, colored (up), color (up), colored, colour (up), coloured (up), crayon, crayoned, dye, dyed, embellish, embellished, flush, flushed, paint, painted",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "colore",
        "translation": "color, colour, dye, hair dye, paint",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colori",
        "translation": "colors",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colpa",
        "translation": "fault, blame, blames, sin, wrong",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "colpiamo",
        "translation": "hit, (we) hit, (we) strike, (we) knock, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "colpire",
        "translation": "hit, strike, (to) hit, (to) affect, (to) sting, (to) strike, (to) to affect, (to) to attack, (to) to impress, (to) to knock, (to) to shoot, (to) to strike, attack, impress, knock, shoot",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "colpisci",
        "translation": "hit, (you) hit, (you) strike, (you) knock, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "colpisco",
        "translation": "hit, (I) hit, (I) strike, (I) knock, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coltello",
        "translation": "knife",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coltiva",
        "translation": "(he/she/it) cultivates, (he/she/it) crops, (you) crop, grows, nurses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coltivano",
        "translation": "(they) cultivate, (they) crop, (they) farm",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coltivare",
        "translation": "(to) to farm, (to) to grow, grow, (to) crop, (to) cultivate, farm, grows, nurses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coltivo",
        "translation": "(I) grow, (I) crop, (I) farm",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "com'",
        "translation": "how, as, as if, as per, as soon as, as well as, like, such as, that",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "come",
        "translation": "how, as, like, what",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "cominciare",
        "translation": "(to) start, (to) to start, (to) begin, (to) strike up, began, came, come, enter, started, take, taken, takes, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "commenti",
        "translation": "comments, (you) comment on",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "commerciale",
        "translation": "commercial, trade",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "commerciali",
        "translation": "commercial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "commercio",
        "translation": "commerce, trade, (I) trade, (I) deal, dealing, trading, traffic",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comoda",
        "translation": "comfortable, convenient",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "comodo",
        "translation": "comfortable, comfy, convenient, cosy, easier, easy, soft",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "compagni",
        "translation": "companion, companions, comrade, comrades, mate, mates",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compagnia",
        "translation": "company, band, companionship, fellowship",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "compagno",
        "translation": "partner, companion, buddy, classmate, comrade, fellow, mate, pal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compiti",
        "translation": "homework, assignments, (you) spell (out)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compleanno",
        "translation": "birthday",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "complesso",
        "translation": "band, complex, group, by and large, compound, difficult, elaborate, entirety, hang-up, hard, her, many-sided, messy, on the whole, subtle, sum, whole",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "completamente",
        "translation": "completely, all the way, totally, altogether, cold, entirely, fully, right, thoroughly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "comportamento",
        "translation": "behavior, behaviour, conduct",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compra",
        "translation": "(he/she/it) purchases, (she/he) buys, (you) buy, (you) purchase",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprare",
        "translation": "bought, buy, got, to buy, to get, to purchase",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compri",
        "translation": "(you) buy (off), (you) get, (you) purchase, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compriamo",
        "translation": "(we) purchase, (we) buy, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compro",
        "translation": "(I) purchase, (I) buy",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compromessi",
        "translation": "compromises, impaired, jeopardized",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compromesso",
        "translation": "compromise, impaired, jeopardized",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "computer",
        "translation": "computer, computers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comune",
        "translation": "common, in common, ordinary, city, city hall, city-state, communally, commune, everyday, frequent, municipality, shared, town, town hall, township, usual, village",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "comunicazione",
        "translation": "communication, message, note",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comunicazioni",
        "translation": "communications, communication, messages",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comunità",
        "translation": "(religious) community, commonalty, communion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comunque",
        "translation": "anyway, however, just the same, by the way, even, in any case, though",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "con",
        "translation": "with, to",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "concerti",
        "translation": "(you) orchestrate, concerts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concerto",
        "translation": "(I) concert, (I) orchestrate, concert, gig, performance, show",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concetto",
        "translation": "concept, executive, idea, managerial, notion",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concordare",
        "translation": "(to) to tally, (to) conform, (to) agree (on), (to) compound, (to) concur, tally",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "concorrenza",
        "translation": "competition, concourse, gathering",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conduttore",
        "translation": "presenter, anchor, conductive, conductor, driver",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conduttrice",
        "translation": "anchorwoman",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conferenza",
        "translation": "conference, lecture, prelection, talk",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "confondere",
        "translation": "(to) confuse, (to) to confuse, (to) to become confused, (to) get confused, (to) mix [something] up, become confused, confusing, mistake, mix",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "congressi",
        "translation": "conference, congress, convention",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "congresso",
        "translation": "congress, conference, convention",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conobbe",
        "translation": "(he/she/it/you) met, (he/she/it/you) knew, (he/she/it/you) experienced, (he/she/it/you) knew each other, (he/she/it/you) knew of, (he/she/it/you) knew oneself, knew, know",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conobbi",
        "translation": "(I) met, (I) got to know, knew, offered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conosca",
        "translation": "know of, know each other, experience, know oneself, know, meet",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoscendo",
        "translation": "getting to know, knowing, knowing each other, experiencing, knowing of, knowing oneself, meeting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoscenza",
        "translation": "knowledge, consciousness, acquaintance, cognition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conosceranno",
        "translation": "(they) will meet, (they) will know, (they) will know of, know, known, meet",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoscere",
        "translation": "know, (to) know, (to) meet, knew, offered, offers",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoscerebbe",
        "translation": "know, (he/she/it/you) would know, (he/she/it/you) would know of, (he/she/it/you) would meet, knew, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoscerei",
        "translation": "know, (I) would know, (I) would know of, (I) would meet, knew, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoscerete",
        "translation": "know, (you) will meet, (you) will know, (you) will know of, meet",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoscesse",
        "translation": "(he/she/it/you) knew, (he/she/it/you) knew of, (he/she/it/you) met, knew",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoscessi",
        "translation": "(I/you) experienced, (I/you) knew, (I/you) met",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conosceva",
        "translation": "knew, (he/she/it/you) knew, (he/she/it/you) knew of, (he/she/it/you) experienced, (he/she/it/you) knew each other, (he/she/it/you) knew oneself, (he/she/it/you) met, (he/she/it/you) used to experience, (he/she/it/you) used to know, (he/she/it/you) used to know each other, (he/she/it/you) used to know of, (he/she/it/you) used to know oneself, (he/she/it/you) used to meet, (he/she/it/you) was/were experiencing, (he/she/it/you) was/were knowing, (he/she/it/you) was/were knowing each other, (he/she/it/you) was/were knowing of, (he/she/it/you) was/were knowing oneself, (he/she/it/you) was/were meeting, offered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conosciamo",
        "translation": "(we) know, (we) meet, knew",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conosco",
        "translation": "know, (I) know, (I) meet, knew",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consapevole",
        "translation": "aware, conscious, mindful",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "conseguenza",
        "translation": "consequence, consequences, ripercussion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conseguenze",
        "translation": "consequences",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "consigli",
        "translation": "advice, (you) recommend, (you) advise, (you) counsel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "consiglio",
        "translation": "advice, suggestion, (I) counsel, (I) advise, (I) recommend, (I) suggest, board, council, guideline, meeting",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contadini",
        "translation": "farmers, peasant, peasants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contadino",
        "translation": "farmer, peasant, rural",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contanti",
        "translation": "cash, count, have, matter, tell",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contenta",
        "translation": "happy, (you) content, (he/she/it) contents, glad",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "contenti",
        "translation": "glad, happy",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "continente",
        "translation": "continent, mainland, temperate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conto",
        "translation": "check, (I) count, account, (I) matter, amount, bill, calculation, tab, tale",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contratti",
        "translation": "contracts, (you) bargain, (you) bargain over, (you) negotiate, contract",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contratto",
        "translation": "contract, (I) bargain, (I) negotiate, caught, got",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contro",
        "translation": "against, into",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "controllare",
        "translation": "(to) check, (to) control, controlling, (to) control oneself, (to) inspect, (to) mark, (to) test, (to) to examine, (to) to manage, (to) to master, (to) to restrain, (to) to rule, (to) watch, check, checking, examine, go, gone, keep, kept, manage, master, restrain, rule, watch, watched, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "controllo",
        "translation": "(I) control, (I) control oneself, (I) check, (I) examine, (I) inspect, (I) manage, (I) mark, (I) master, (I) restrain, (I) rule, (I) test, (I) watch, check-up, command, examination, inspection, verification",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversazione",
        "translation": "conversation, talk",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conversazioni",
        "translation": "conversations, conversation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coperta",
        "translation": "blanket, bedspread, blankets, cover",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coppia",
        "translation": "couple, in twos, in pairs, brace, dyad, pair, torque, yoke",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coraggio",
        "translation": "courage, bottles, bravery, gallantry, spirits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corona",
        "translation": "(you) crown, (he/she/it) crowns, (he/she/it) surrounds",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corpi",
        "translation": "bodies, (dead) body",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corpo",
        "translation": "body, corps, corpse, object, point size, points, profession, substance, type size",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corre",
        "translation": "runs, (you, formal) run, races",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "correndo",
        "translation": "running (in), running after, running in, driving (too fast), driving in, flowing, going around, racing, riding, riding in, rushing, speeding (along)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "correre",
        "translation": "run, get, got, to run",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corretta",
        "translation": "correct, correct oneself, accurate, adjust, adjusted, check, checked, clean, corrected, corrected oneself, fair, grade, graded, improve, improve oneself, improved, improved oneself, lace, laced, mark, marked, mend one’s ways, mended one’s ways, polish, polished, proofread, proper, read, rectified, rectify, refine, refined, revised, spike, spiked, sportsmanlike",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "corri",
        "translation": "(you) run",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corriamo",
        "translation": "(we) run",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corro",
        "translation": "run, I run",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrono",
        "translation": "(they) run",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corruzione",
        "translation": "corruption, decomposition, degeneracy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corso",
        "translation": "class, course (book), in the course of, Corsican, avenue, course, current, driven (too fast), driven in, during, flow, flowed, gone around, high street, in progress, main street, on, ongoing, parade, path, paths, present, price, raced, rate, ridden, ridden in, run, run (in), run after, run in, rushed, speeded/sped (along), stake, textbook, underway",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corte",
        "translation": "short, (royal) court, bench, courtship",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cortile",
        "translation": "courtyard, yard, (school) playground, backyard, barnyard, court, farmyard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corto",
        "translation": "brief, short",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cos'",
        "translation": "what, matter, thing, work",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "cosa",
        "translation": "what, thing",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "coscienza",
        "translation": "conscience, awareness, consciousness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cose",
        "translation": "things, matter, thing, what, work",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "così",
        "translation": "so, that, all, as, like that, like this, this way, those",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "costa",
        "translation": "coast, (he/she/it) costs, seabord, seacoast, slope, spine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costante",
        "translation": "constant, consistent, even, persistent, uniform, uniforms",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "costare",
        "translation": "(to) cost, cost",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costi",
        "translation": "costs, (you) cost",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "costo",
        "translation": "(I) cost, cost, fee, price",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "costosa",
        "translation": "expensive, costly, high-priced, pricey",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "costose",
        "translation": "expensive, burdensome, costly, dear, hard, high priced",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "costosi",
        "translation": "expensive, burdensome, costly, dear, hard, high priced",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "costoso",
        "translation": "expensive, costly, dear",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "costruendo",
        "translation": "building, constructing, laying (down)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruì",
        "translation": "(he/she/it/you) built, (he/she/it/you) constructed, (he/she/it/you) laid (down), make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruiamo",
        "translation": "(we) build, (we) construct, (we) lay (down), made, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruiate",
        "translation": "build, construct, lay (down)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruimmo",
        "translation": "(we) built, (we) constructed, (we) laid (down), made",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruirà",
        "translation": "(he/she/it/you) will build, (he/she/it/you) will construct, (he/she/it/you) will lay (down), made, make, makes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruiranno",
        "translation": "(they) will build, (they) will construct, (they) will lay (down), made, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruire",
        "translation": "(to) build, (to) to construct, (to) to assemble, (to) construct, (to) shape, (to) to lay (down), assemble, lay (down), made, make, makes, put, puts, structures, throws",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruirebbe",
        "translation": "(he/she/it/you) would construct, (he/she/it/you) would build, (he/she/it/you) would lay (down), make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruirei",
        "translation": "(I) would build, (I) would construct, (I) would lay (down), made, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruiremo",
        "translation": "(we) will build, (we) will construct, (we) will lay (down), make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruisca",
        "translation": "lay (down), build, construct",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruiscano",
        "translation": "build, construct, lay (down)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruisci",
        "translation": "(you) build, (you) construct, (you) lay (down), made, make, makes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruissero",
        "translation": "(they) were to build, (they) built, (they) constructed, (they) laid (down)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruiva",
        "translation": "(he/she/it/you) was/were building, (he/she/it/you) used to build, (he/she/it/you) built, made",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costruzione",
        "translation": "construction, building, laying, manufacture, structures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "costruzioni",
        "translation": "buildings, building, constructions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "costume",
        "translation": "costume, outfit, custom, masquerade, morality, morals, swimsuit, tradition",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "creda",
        "translation": "believe, suppose, think",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credano",
        "translation": "believe, suppose, think",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credemmo",
        "translation": "believe, (we) thought, (we) supposed, (we) believed, bought, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credendo",
        "translation": "believing, supposing, thinking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crederà",
        "translation": "believe, (he/she/it/you) will believe, (he/she/it/you) will suppose, (he/she/it/you) will think, bought, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crederai",
        "translation": "believe, (you) will believe, (you) will suppose, (you) will think, bought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credere",
        "translation": "believe, (to) believe, (to) to think, (to) to suppose, believed, bought, suppose, think, thinks, thought, thoughts",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crederebbe",
        "translation": "believe, (he/she/it/you) would believe, (he/she/it/you) would think, (he/she/it/you) would suppose, bought, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crederebbero",
        "translation": "believe, (they) would believe, (they) would suppose, (they) would think",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credesse",
        "translation": "believe, (he/she/it/you) thought, (he/she/it/you) supposed, (he/she/it/you) believed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credessimo",
        "translation": "believe, (we) believed, (we) thought, (we) supposed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credettero",
        "translation": "think, believe, suppose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credeva",
        "translation": "believed, (he/she/it/you) believed, (he/she/it/you) was/were thinking, (he/she/it/you) supposed, (he/she/it/you) thought, (he/she/it/you) used to believe, (he/she/it/you) used to suppose, (he/she/it/you) used to think, (he/she/it/you) was/were believing, (he/she/it/you) was/were supposing, bought, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credevamo",
        "translation": "believed, (we) believed, (we) used to believe, (we) were believing, bought, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credevo",
        "translation": "believed, (I) believed, (I) thought, (I) used to believe, (I) was/were believing, bought, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credi",
        "translation": "believe, (you) believe, (you) think, (you) suppose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crediamo",
        "translation": "believe, (we) believe, (we) suppose, (we) think",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credito",
        "translation": "credit, credence, credibility",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "credo",
        "translation": "believe, (I) believe, (I) think, (Apostles’) Creed, (I) suppose, credo, faith, persuasion",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "credono",
        "translation": "believe, (they) believe, (they) suppose, (they) think",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crema",
        "translation": "cream, custard, (he/she/it) cremates, (you) cremate, crème, upper crust",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cresce",
        "translation": "grows, (he/she/it) grows, (you) grow, (he/she/it) brings up, (he/she/it) comes (up), (he/she/it) goes up, (he/she/it) grows up, (he/she/it) increases, (he/she/it) mounts, (he/she/it) raises, (he/she/it) rises, (he/she/it) turns up, (he/she/it) waxes, (you) bring up, (you) come (up), (you) go up, (you) grow up, (you) increase, (you) mount, (you) raise, (you) rise, (you) turn up, (you) wax",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crescente",
        "translation": "growing, crescent, deepening, expanding, increasing, mounting, rising",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "crescere",
        "translation": "(to) grow, (to) grow up, (to) to grow, (to) go up, (to) increase, (to) raise, (to) rise, (to) to bring up, (to) to come (up), (to) to increase, (to) to mount, (to) to rise, (to) to turn up, (to) wax, bring up, came, come (up), grows, mount, raising, turn up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cresciamo",
        "translation": "(we) raise, (we) wax, (we) increase, (we) bring up, (we) come (up), (we) go up, (we) grow, (we) grow up, (we) mount, (we) rise, (we) turn up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crescita",
        "translation": "growth, increase, rise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "crescono",
        "translation": "(they) raise, (they) grow, (they) grow up, (they) bring up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crimine",
        "translation": "crime, criminal act, criminal offense",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "crisi",
        "translation": "crisis, attack, fits, shortage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cucchiaio",
        "translation": "spoon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cucina",
        "translation": "kitchen, (he/she/it) cooks, (you) cook, cuisine, food, stove",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cucinando",
        "translation": "cooking, cooking up, fixing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cucinano",
        "translation": "(they) cook, (they) cook up, (they) fix",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cucinate",
        "translation": "(you) cook, (you) cook up, (you) fix",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cucini",
        "translation": "(you) cook",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuciniamo",
        "translation": "(we) cook, (we) cook up, (we) fix",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cucino",
        "translation": "(I) cook, I cook, cook, do I cook",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cugini",
        "translation": "cousins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cugino",
        "translation": "cousin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cui",
        "translation": "which, whose, whom, inasmuch as, insofar as, of which, so, that, therefore, those, thus, to the extent that, where",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cultura",
        "translation": "culture, civilization, crop, cultivation, education, knowledge, learning",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "culturale",
        "translation": "cultural",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "culturali",
        "translation": "cultural",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "culture",
        "translation": "cultures, civilization, culture, education",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cuochi",
        "translation": "cooks, cooks'",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuoco",
        "translation": "cook",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuoio",
        "translation": "leather, hide, skin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuore",
        "translation": "heart, heart-shaped object, breast, center, centre, core, hearts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cura",
        "translation": "care, treatment, (you) care for, (he/she/it) cures, (he/she/it) looks after, (he/she/it) nurses, (he/she/it) takes care of, (he/she/it) treats, (you) look after, (you) treat, charge, course, cure, cure (of souls), nurses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cuscini",
        "translation": "cushions, pillows, cushion, pillow",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuscino",
        "translation": "pillow, cushion, hassock, padding",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "d'",
        "translation": "of, in",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "da",
        "translation": "to, from, as, at, for, offers, on, return, returns, since, sits, throws",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "dà",
        "translation": "to, from, as, at, for, offers, on, return, returns, since, sits, throws",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dagli",
        "translation": "from, from the, to the, at the, by the, from...to, since, to, with the",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "dai",
        "translation": "from, from the, by the, (you) give, at, at the, come on, for, give, return, since, sit, through the, to, to the, with, with the",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "dal",
        "translation": "from, from the, at, by, of, since, to, to the",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "dall'",
        "translation": "from, from the, to the, (he/she/it) bears, (he/she/it) bumps, (he/she/it) devotes oneself, (he/she/it) gives, (he/she/it) gives oneself, (he/she/it) grants, (he/she/it) hits, (he/she/it) issues, (he/she/it) lays down, (he/she/it) pays, (he/she/it) presents, (he/she/it) puts on, (he/she/it) returns, (he/she/it) sets, (he/she/it) shows, (he/she/it) yields, (you) bear, (you) bump, (you) devote oneself, (you) give, (you) give oneself, (you) grant, (you) hit, (you) issue, (you) lay down, (you) pay, (you) present, (you) put on, (you) return, (you) set, (you) show, (you) yield, as, at, at the, by, by the, for, from...to, on the, out of, since, through, through the, to, with, with the",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "dalla",
        "translation": "from, from the, from...to, as, at, at the, by, by the, for, like, of, since, through, through the, to, to the, with, with the",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "dalle",
        "translation": "from, from the, from...to, at, at the, bies, by the, for, likes, since, through, through the, to, to the, with, with the",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "dallo",
        "translation": "from, to, as, at, by, for, from the, since, through, with",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "dammi",
        "translation": "give, give me, issue",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "dando",
        "translation": "giving, giving oneself, bearing, bumping, devoting oneself, granting, hitting, issuing, laying down, paying, presenting, putting on, returning, setting, showing, yielding",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "danni",
        "translation": "damages, damage, (you) are damned, (you) damn",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "danno",
        "translation": "give, (they) give, damage, return",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "danza",
        "translation": "(you) dance, (he/she/it) dances, dancing",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dappertutto",
        "translation": "everywhere, all over the place",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "darai",
        "translation": "give, (you) will give, (you) will bump, (you) will bear, (you) will hit, (you) will lay down, (you) will pay, (you) will put on, (you) will set, (you) will show",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "darebbe",
        "translation": "give, (he/she/it/you) would give, (he/she/it/you) would show, (he/she/it/you) would bear, (he/she/it/you) would bump, (he/she/it/you) would grant, (he/she/it/you) would hit, (he/she/it/you) would lay down, (he/she/it/you) would pay, (he/she/it/you) would put on, (he/she/it/you) would set, gave, returned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "darei",
        "translation": "give, (I) would give, (I) would bear, (I) would bump, (I) would grant, (I) would hit, (I) would lay down, (I) would pay, (I) would put on, (I) would set, (I) would show, gave, returned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "daresti",
        "translation": "(you) would give, (you) would bear, (you) would pay, (you) would bump, (you) would grant, (you) would hit, (you) would lay down, (you) would put on, (you) would set, (you) would show, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "darò",
        "translation": "give, (I) will give, (I) will hit, (I) will bear, (I) will bump, (I) will lay down, (I) will pay, (I) will put on, (I) will set, (I) will show, present, returned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "data",
        "translation": "(you) date, given, (you) date from, (he/she/it) dates, (he/she/it) dates back, (he/she/it) dates from, (you) date back, time",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "date",
        "translation": "dates, give, (you) give",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dava",
        "translation": "(he/she/it/you) was/were giving, (he/she/it/you) was/were giving oneself, (he/she/it/you) used to give, (he/she/it/you) bore, (he/she/it/you) bumped, (he/she/it/you) devoted oneself, (he/she/it/you) gave, (he/she/it/you) gave oneself, (he/she/it/you) granted, (he/she/it/you) hit, (he/she/it/you) issued, (he/she/it/you) laid down, (he/she/it/you) paid, (he/she/it/you) presented, (he/she/it/you) put on, (he/she/it/you) returned, (he/she/it/you) set, (he/she/it/you) showed, (he/she/it/you) used to bear, (he/she/it/you) used to bump, (he/she/it/you) used to devote oneself, (he/she/it/you) used to give oneself, (he/she/it/you) used to grant, (he/she/it/you) used to hit, (he/she/it/you) used to issue, (he/she/it/you) used to lay down, (he/she/it/you) used to pay, (he/she/it/you) used to present, (he/she/it/you) used to put on, (he/she/it/you) used to return, (he/she/it/you) used to set, (he/she/it/you) used to show, (he/she/it/you) used to yield, (he/she/it/you) was/were bearing, (he/she/it/you) was/were bumping, (he/she/it/you) was/were devoting oneself, (he/she/it/you) was/were granting, (he/she/it/you) was/were hitting, (he/she/it/you) was/were issuing, (he/she/it/you) was/were laying down, (he/she/it/you) was/were paying, (he/she/it/you) was/were presenting, (he/she/it/you) was/were putting on, (he/she/it/you) was/were returning, (he/she/it/you) was/were setting, (he/she/it/you) was/were showing, (he/she/it/you) was/were yielding, (he/she/it/you) yielded, gave, returned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "davamo",
        "translation": "(we) would give, (we) used to give, (we) gave, (we) were giving, gave, returned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "davanti",
        "translation": "in front, towards",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "davvero",
        "translation": "really, truly, indeed, very",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "debole",
        "translation": "weak, small, delicate, dull, feeble, frail, poor, smaller, unsound",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "deboli",
        "translation": "weak, delicate, feeble",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "decenni",
        "translation": "decades, decennium",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "decennio",
        "translation": "decade, decennium",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "decida",
        "translation": "decide, decide on, determine, make up one’s mind",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decide",
        "translation": "(he/she/it) decides, (he/she/it) decides on, (he/she/it) determines, (he/she/it) chooses, (he/she/it) comes to a decision, (he/she/it) elects, (he/she/it) fixes, (he/she/it) makes up one’s mind, (he/she/it) reaches, (he/she/it) settles, (you) choose, (you) come to a decision, (you) decide, (you) decide on, (you) determine, (you) elect, (you) fix, (you) make up one’s mind, (you) reach, (you) settle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidendo",
        "translation": "deciding, deciding on, determining, passing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decideranno",
        "translation": "(they) will decide, (they) will decide on, (they) will choose, (they) will come to a decision, (they) will determine, (they) will elect, (they) will fix, (they) will make up one’s mind, (they) will reach, (they) will settle, decide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidere",
        "translation": "(to) decide, (to) decide on, (to) to settle, (to) choose, (to) determine, (to) elect, (to) fix, (to) reach, (to) settle, (to) to come to a decision, (to) to determine, (to) to make up one’s mind, come to a decision, decide, elect, make up one’s mind, passed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deciderebbe",
        "translation": "(he/she/it/you) would decide, (he/she/it/you) would decide on, (he/she/it/you) would fix, (he/she/it/you) would make up one’s mind, decide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deciderei",
        "translation": "(I) would decide, (I) would decide on, (I) would fix, (I) would make up one’s mind, decide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decideremo",
        "translation": "(we) will decide, (we) will decide on, (we) will fix, decide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidesse",
        "translation": "(he/she/it/you) decided, (he/she/it/you) decided on, (he/she/it/you) fixed, (he/she/it/you) determined",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidessero",
        "translation": "(they) determined, (they) decided on, (they) fixed, decide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decideva",
        "translation": "(he/she/it/you) used to decide, (he/she/it/you) was/were deciding, (he/she/it/you) used to decide on, (he/she/it/you) decided on, (he/she/it/you) was/were deciding on, passed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidi",
        "translation": "(you) decide, (you) decide on, (you) determine, decide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidiamo",
        "translation": "(we) decide, (we) decide on, (we) determine, decide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decima",
        "translation": "tenth, (he/she/it) decimates, (you) decimate, tithe",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "decimo",
        "translation": "tenth, (I) decimate",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "decise",
        "translation": "(he/she/it/you) decided, (he/she/it/you) decided on, (he/she/it/you) determined, passed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decisione",
        "translation": "decision, deliberation, purpose, strong-mindedness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "decisioni",
        "translation": "decisions, decision, deliberation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "definitiva",
        "translation": "final, definitive, permanent",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "definitivi",
        "translation": "final, definitive, permanent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "definizione",
        "translation": "definition, clue, settlement",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "degli",
        "translation": "of the, about the, from the, in, some",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "dei",
        "translation": "of, of the, some, about, about the, gods, than",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "del",
        "translation": "of, of the, some, 's, about, from, in the, than the, with",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "delfino",
        "translation": "dolphin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "delitti",
        "translation": "crimes, sins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dell'",
        "translation": "of, of the, about, 's, by, d, for, from, from... to, in, kind, say, say to oneself, some, sort, tell, tell oneself, than, with",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "della",
        "translation": "of, of the, about the, in the, some, than",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "delle",
        "translation": "of, of the, some, ', about",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "dello",
        "translation": "of the, of, in, 's, about, by, d, for, from, some, than, with",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "democratica",
        "translation": "democrat",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "democratici",
        "translation": "democrats, democratic, egalitarian",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "democratico",
        "translation": "democratic, democrat, egalitarian",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "democrazia",
        "translation": "democracy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "denaro",
        "translation": "money, denarius, denier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dente",
        "translation": "tooth",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "denti",
        "translation": "teeth",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dentifricio",
        "translation": "toothpaste",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dentista",
        "translation": "dentist",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dentro",
        "translation": "inside, in, into",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "depressione",
        "translation": "depression, lower",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "descrive",
        "translation": "(you) describe, (he/she/it) describes, (he/she/it) depicts",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descrivere",
        "translation": "(to) describe, (to) to depict, (to) image, depict",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descrivi",
        "translation": "(you) describe, (you) depict, (you) image",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descrizione",
        "translation": "description",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "desideri",
        "translation": "wishes, (you) wish, (you) desire, (you) want, want",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "desiderio",
        "translation": "desire, wish, lust",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "desse",
        "translation": "(he/she/it/you) gave, (he/she/it/you) issued, (he/she/it/you) laid down, (he/she/it/you) give, gave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "destinazione",
        "translation": "destination, assignment, posting",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "destino",
        "translation": "destiny, arouse, wake",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "destra",
        "translation": "right",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dettagli",
        "translation": "details, (you) detail, (you) itemize",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "deve",
        "translation": "(he/she/it) has to, (he/she/it) owes, (he/she/it/you) must, (you) have (got) to, (you) have to, (you) owe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devi",
        "translation": "(you) have to, (you) must, (you) owe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devo",
        "translation": "(I) have to, (I) must",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devono",
        "translation": "(they) have to, (they) must",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "di",
        "translation": "of, to, in, 's, about, at, for, from, than, with",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "dia",
        "translation": "give, issue, lay down",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diagnosi",
        "translation": "diagnosis, analysis",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dialoghi",
        "translation": "dialogues, (you) dialog [romanzo, (you) dialogue",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dialogo",
        "translation": "(I) dialogue, (I) dialog [romanzo, (I) scena]",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diano",
        "translation": "lay down, issue, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diario",
        "translation": "diary, journal, timetable",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dica",
        "translation": "tell, tell oneself, say",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dice",
        "translation": "(he/she) says, (he/she) tells, says, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dicembre",
        "translation": "December",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dicendo",
        "translation": "saying, telling, telling oneself",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dicessero",
        "translation": "(they) said, (they) told, (they) told oneself",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dicessi",
        "translation": "(I/you) told, (I/you) said, (I/you) told oneself",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dicessimo",
        "translation": "(we) said, (we) told oneself, (we) told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diceva",
        "translation": "(he/she/it/you) was/were saying, (he/she/it/you) was/were saying to oneself, (he/she/it/you) was/were telling, (he/she/it/you) said, (he/she/it/you) said to oneself, (he/she/it/you) told, (he/she/it/you) told oneself, (he/she/it/you) used to say, (he/she/it/you) used to say to oneself, (he/she/it/you) used to tell, (he/she/it/you) used to tell oneself, (he/she/it/you) was/were telling oneself, said, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dicevi",
        "translation": "(you) were saying, (you) said, (you) used to say, said, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dicevo",
        "translation": "(I) was/were telling, (I) was/were telling oneself, (I) was/were saying, (I) said, (I) said to oneself, (I) told, (I) told oneself, (I) used to say, (I) used to say to oneself, (I) used to tell, (I) used to tell oneself, (I) was/were saying to oneself, said, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diciamo",
        "translation": "(we) say, (we) tell, said, says",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diciannove",
        "translation": "nineteen, seven in the evening",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "diciassette",
        "translation": "seventeen",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "diciotto",
        "translation": "eighteen, six in the evening",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "dico",
        "translation": "(I) say, (I) tell, said, says, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dicono",
        "translation": "(they) say, (they) tell, say, says, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dieci",
        "translation": "ten",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "diede",
        "translation": "gave, (he/she/it) gave, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dieta",
        "translation": "diet",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dietro",
        "translation": "behind, back, rear",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "differente",
        "translation": "different",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "differenti",
        "translation": "different, defer, delay",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "differenza",
        "translation": "difference, distinction, gap, remainder",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "differenze",
        "translation": "differences, difference, distinction, gap, remainder",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "difficile",
        "translation": "difficult, choosy, hard, improbable, tense, tight, tough, tricky, unlikely",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "difficili",
        "translation": "difficult, difficult part, hard to please, awkward, choosy, difficulty, fastidious, fussy, hard, improbable, particular, tough, tricky, unlikely",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "difficoltà",
        "translation": "difficulty, trouble, difficulties, problem",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dignità",
        "translation": "dignity, graveness, worthiness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dimentica",
        "translation": "(he/she/it) forgets, (you) forget, (he/she/it) leaves (behind), leave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dimenticare",
        "translation": "(to) forget, (to) to leave (behind), (to) to leave out, (to) neglect, got, leave, leave (behind), leave out, left, put, puts",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dimentichiamo",
        "translation": "(we) forget, (we) leave (behind), (we) leave out, leave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dimmi",
        "translation": "tell, tell me, say",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "dimostra",
        "translation": "(he/she/it) demonstrates, show, (he/she/it) shows (oneself), (he/she/it) displays, (you) demonstrate, (you) show (oneself)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dimostrare",
        "translation": "(to) demonstrate, (to) to prove, (to) to establish, (to) prove (oneself), (to) to display, (to) to show (oneself), (to) to turn out, display, establish, proving, show, show (oneself), turn out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dimostrazione",
        "translation": "demonstration, proof, show",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dimostro",
        "translation": "(I) demonstrate, (I) display, (I) show (oneself), show",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dio",
        "translation": "god, ace, wizard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dipartimenti",
        "translation": "departments, bureau, department, district, service",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dipartimento",
        "translation": "department, bureau, departments, district",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dipingere",
        "translation": "(to) paint, painting, (to) to depict, (to) to portray",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diploma",
        "translation": "degree, diploma",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diplomazia",
        "translation": "diplomacy, diplomatics, tact",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dirà",
        "translation": "tell, (he/she/it/you) will say, (he/she/it/you) will tell, (he/she/it/you) will tell oneself, said, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dirai",
        "translation": "(you) will tell, (you) will say, (you) will tell oneself, (you) will say to oneself, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dire",
        "translation": "tell, said, say, saying, says, telling, to say, to tell",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "direbbe",
        "translation": "(he/she/it/you) would say, (he/she/it/you) would tell, (he/she/it/you) would tell oneself, said, tell, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diremmo",
        "translation": "(we) would tell, (we) would tell oneself, (we) would say, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "direttamente",
        "translation": "directly, straight, direct(ly), right",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "direttore",
        "translation": "director, head manager, directors, manager",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "direttori",
        "translation": "directors, Directory, executive board",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "direttrice",
        "translation": "director, (f.) manager, manager, (f.) executive",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "direzione",
        "translation": "direction, way, (senior) management, editing, editorship, headship, running",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dirgli",
        "translation": "tell, tell him, tell oneself, say, say to oneself",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "dirigenza",
        "translation": "management, leadership, managership",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "diritta",
        "translation": "straight, erect, lank",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "diritte",
        "translation": "straight, erect, lank",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "diritti",
        "translation": "rights, lank, right, straight",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diritto",
        "translation": "right, straight, directly, drive, dues, eligibility, erect, forehand, lank, law, obverse, rights",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dirò",
        "translation": "tell, (I) will tell, (I) will say, (I) will tell oneself, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "discorsi",
        "translation": "conversations, (I) spoke, discussions",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "discorso",
        "translation": "speech, discussion, lecture, talk",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "discussione",
        "translation": "discussion, argument, contention, conversation, debate, defence, dispute, session",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "disegna",
        "translation": "(he/she/it) designs, (he/she/it) draws, (you) draw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disegnano",
        "translation": "(they) design, (they) plan, (they) draw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disegnare",
        "translation": "drawing, to draw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disegni",
        "translation": "(you) draw, (you) design, (you) plan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disegniamo",
        "translation": "(we) draw, (we) design, (we) plan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disegno",
        "translation": "drawing, (I) design, (I) style, (I) am drawing, (I) draw, (I) outline, (I) plan, pattern, picture, scheme",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dispiace",
        "translation": "(you) are sorry, (he/she/it) is sorry",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disponibile",
        "translation": "available, helpful",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "disponibili",
        "translation": "available, accessible, helpful",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "disse",
        "translation": "said, (he/she/it/you) told, (he/she/it/you) said, (he/she/it/you) told oneself",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dissi",
        "translation": "told, (I) told, (I) told oneself, (I) said, said, tell",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "distanza",
        "translation": "distance, difference, gap",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dita",
        "translation": "fingers",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dite",
        "translation": "(you) say, (you) tell, (you) tell oneself, said, says, tell, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dito",
        "translation": "finger, toe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ditta",
        "translation": "company, firm, business, houses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dittatura",
        "translation": "dictatorship",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "divani",
        "translation": "couches, couch, divan, settee, sofa, sofas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "divano",
        "translation": "couch, sofa",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diventa",
        "translation": "(he/she/it) becomes, (he/she/it) is elected, (he/she/it) gets, (he/she/it) goes, (he/she/it) grows, (he/she/it) turns, (you) are elected, (you) are made, (you) become, (you) get, (you) go, (you) grow, (you) turn, be elected, be made, become, get, grows",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventammo",
        "translation": "became, (we) became, (we) grew, (we) got, get, got, turned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventando",
        "translation": "getting, becoming, being elected, being made, going, growing, turning",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventare",
        "translation": "become, (to) become, (to) to go, (to) be made, (to) grow, (to) to get, (to) turn, be elected, became, get, go, got, grows",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventasse",
        "translation": "(he/she/it/you) got, (he/she/it/you) grew, (he/she/it/you) became",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventassero",
        "translation": "(they) got, (they) became, (they) grew, (they) turned, (they) went, (they) was/were elected, (they) was/were made",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventassi",
        "translation": "(I/you) became, become, (I/you) got, (I/you) grew",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventava",
        "translation": "became, (he/she/it/you) became, (he/she/it) was becoming, (he/she/it) was getting, (he/she/it) was turning, (he/she/it/you) got, (he/she/it/you) grew, (he/she/it/you) turned, (he/she/it/you) used to be made, (he/she/it/you) used to become, (he/she/it/you) used to get, (he/she/it/you) used to go, (he/she/it/you) used to grow, (he/she/it/you) used to turn, (he/she/it/you) went, (you) were becoming, (you) were getting, (you) were turning, got, turned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventerà",
        "translation": "become, (he/she/it/you) will become, (he/she/it/you) will get, (he/she/it/you) will be made, (he/she/it/you) will go, (he/she/it/you) will grow, (he/she/it/you) will turn, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventerai",
        "translation": "become, (you) will become, (you) will grow, (you) will be made, (you) will get, (you) will go, (you) will turn, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventerebbe",
        "translation": "become, (he/she/it/you) would become, (he/she/it/you) would get, (he/she/it/you) would be made, (he/she/it/you) would go, (he/she/it/you) would grow, (he/she/it/you) would turn",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventeremo",
        "translation": "become, (we) will become, (we) will get, (we) will be made, (we) will go, (we) will grow, (we) will turn",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventi",
        "translation": "become, (you) become, (you) turn, (you) are elected, (you) are made, (you) get, (you) go, (you) grow, be elected, be made",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventino",
        "translation": "become, be elected, be made, get, go, grow, turn",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diventò",
        "translation": "became, (he/she/it/you) became, (he/she/it/you) got, (he/she/it/you) grew, get, got, turned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "divento",
        "translation": "became, (he/she/it/you) became, (he/she/it/you) got, (he/she/it/you) grew, get, got, turned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diversa",
        "translation": "different, diverse, dropout, many (people), outsider, several (people), various",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "diverse",
        "translation": "different, various, (he/she/it/you) differed, (he/she/it/you) diverged, (he/she/it/you) used to differ, (he/she/it/you) used to diverge, (he/she/it/you) was/were differing, (he/she/it/you) was/were diverging, diverse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "diversi",
        "translation": "different, various, (I) was/were differing, (I) differed, (I) used to differ, diverse",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "divertente",
        "translation": "fun, amusing, delicious, entertaining",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "divertimenti",
        "translation": "entertainment, amusement, fun",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "divertimento",
        "translation": "fun, amusement, entertainment",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "divisa",
        "translation": "uniform",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "divisione",
        "translation": "division, fits, partition, separation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dizionari",
        "translation": "dictionaries",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dizionario",
        "translation": "dictionary",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dobbiamo",
        "translation": "(we) have to, (we) must, (we) owe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "docce",
        "translation": "showers",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "doccia",
        "translation": "shower",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "documenti",
        "translation": "documents, papers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "documento",
        "translation": "(I) document, (I) prove, (I) provide [somebody] with information, documents, papers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dodici",
        "translation": "twelve",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "dolce",
        "translation": "sweet, cake, dessert, gradual, mild, soft, sweetness",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dolci",
        "translation": "desserts, sweets, candy, cake, dessert, gentle, gradual, kind, mild, pleasant, pudding, soft, sweet (taste), sweetness",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dollari",
        "translation": "dollars",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dollaro",
        "translation": "dollar, buck, clam",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dolore",
        "translation": "pain, ache, grief, sorrow",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dolori",
        "translation": "(you) ache, pains, (you) suffer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "domanda",
        "translation": "question, ask, (you) ask, (he/she/it) asks, (he/she/it) asks for, (you) ask for, application, demand",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "domande",
        "translation": "questions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "domani",
        "translation": "tomorrow",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "domenica",
        "translation": "Sunday, sundays",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "donna",
        "translation": "woman, skirts",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "donne",
        "translation": "women",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dopo",
        "translation": "after, afterwards, later, next, then",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "doppio",
        "translation": "(I) double, (I) dub, (I) round, (I) circumnavigate, (I) lap, copy, double, doubles, round, two-faced",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "dorme",
        "translation": "(he/she/it) sleeps, (you) sleep, (he/she/it) is asleep, (you) are asleep, sleeps",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormendo",
        "translation": "sleeping, going to bed, being asleep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormiamo",
        "translation": "(we) sleep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormire",
        "translation": "sleep, to sleep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormo",
        "translation": "(I) sleep, (I) am asleep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormono",
        "translation": "(they) sleep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dottore",
        "translation": "doctor, graduate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dottoressa",
        "translation": "(f.) doctor, (f.) graduate, doctor",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dottoresse",
        "translation": "(female) doctors, doctor, doctors, graduate",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dottori",
        "translation": "doctors, doctor, graduate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dov'",
        "translation": "where, lest, whereas",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "dove",
        "translation": "where",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "dover",
        "translation": "(to) need to, (to) have to, (to) ought to, (to) have (got) to, (to) must, (to) owe, (to) properly, (to) shall, (to) should",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "doveva",
        "translation": "(he/she/it/you) used to have to, (he/she/it/you) used to have (got) to, (he/she/it/you) used to ought to, (he/she/it) was supposed, (he/she/it/you) had (got) to, (he/she/it/you) had to, (he/she/it/you) ought (past) to, (he/she/it/you) owed, (he/she/it/you) properly (past), (he/she/it/you) used to owe, (he/she/it/you) used to properly, (he/she/it/you) was/were having (got) to, (he/she/it/you) was/were having to, (he/she/it/you) was/were ought to, (he/she/it/you) was/were owing, (he/she/it/you) was/were properly",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dovevo",
        "translation": "(I) had to, (I) had (got) to, (I) used to have to, (I) was supposed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dovrà",
        "translation": "(he/she/it/you) will have to, (he/she/it/you) will have (got) to, (he/she/it/you) will owe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dovrai",
        "translation": "(you) will have to, (you) will have (got) to, (you) will ought to, (you) will owe, (you) will properly",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dovranno",
        "translation": "(they) will have to, (they) will have (got) to, (they) will owe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dovrebbe",
        "translation": "should, (he/she/it) should, (he/she/it/you) would have to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dovrei",
        "translation": "should, (I) would ought to, (I) would have to, (I) would have (got) to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dovremo",
        "translation": "(we) will have to, (we) will have (got) to, (we) will owe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dovrò",
        "translation": "(I) will have to, (I) will have (got) to, (I) will owe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "due",
        "translation": "two",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "dura",
        "translation": "last, hard, (you) last, (he/she/it) goes on for long, (he/she/it) lasts, (he/she/it) lasts out, (you) go on for long, (you) last out, difficult, tough",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "durante",
        "translation": "during, for",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "durata",
        "translation": "duration, length, lengths, term",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "duro",
        "translation": "hard, hard-hitting, hard person, (I) go on for long, (I) keep, (I) last, (I) last out, (I) wear, difficult, firm, harsh, heavy, rough, severe, sharp, stiff, strict, stubborn, tough",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "e",
        "translation": "is, are, be, he is, is it, it is, it's, she is, you are",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "è",
        "translation": "is, are, be, he is, is it, it is, it's, she is, you are",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ebbe",
        "translation": "(he/she/it/you) had, had, have, kept",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ebbi",
        "translation": "have, (I) had, had, kept, offered, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eccellente",
        "translation": "excellent, finished, first-rate, healthy, wonderful",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "eccellenti",
        "translation": "excellent, excel, first-rate",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ecco",
        "translation": "here is, there is",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "economia",
        "translation": "economy, economics",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "economici",
        "translation": "cheap, economical, economics",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "economico",
        "translation": "cheap, economic, economical, financial, inexpensive, trade",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ed",
        "translation": "and",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "edifici",
        "translation": "buildings, edifices, structures",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "edificio",
        "translation": "building, edifice, structure, structures",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "educazione",
        "translation": "education, (good) breeding, (good) manners, formation, politeness, upbringing",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "effetti",
        "translation": "effects, as a matter of fact, in fact, belongings, bill, effect, impression, spin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "effettivamente",
        "translation": "actually, effectively, in effect, indeed, really",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "effetto",
        "translation": "effect, effects, as a matter of fact, belongings, bill, impression, in fact, spin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "efficace",
        "translation": "effective, efficacious, efficient, strong, stronger, telling",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "efficaci",
        "translation": "good, effective, efficacious, efficient, forceful, neat, hard-hitting",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "eguaglianza",
        "translation": "equality, (to) equal, (to) equalize, (to) level out",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "elefante",
        "translation": "elephant, elephants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elefanti",
        "translation": "elephants, elephant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elementi",
        "translation": "elements, cell, component, element, fact, ingredient, part, section",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elemento",
        "translation": "element, cell, component, elements, fact, ingredient, part, section",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elenco",
        "translation": "(I) list, (I) count, (I) enumerate, lists",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elettrica",
        "translation": "electric, electrical, electricity",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "elettriche",
        "translation": "electric, electrical, electrics",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "elettrici",
        "translation": "electrical, electric, electrics",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "elettricità",
        "translation": "electricity, tension",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "elezioni",
        "translation": "elections, election, choice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "emergenza",
        "translation": "emergency, emergence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "emozionante",
        "translation": "exciting, moving, touching",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "emozione",
        "translation": "emotion, excitement, thrill",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "emozioni",
        "translation": "emotions, (you) touch, (you) move, (you) are moved, (you) excite, (you) get excited, (you) get worked up, be moved",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "energia",
        "translation": "energy, drive, power, strength",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "enorme",
        "translation": "enormous, huge, vast",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "entra",
        "translation": "(he/she/it) enters, (he/she/it) goes in, (he/she/it) comes in, (he/she/it) has to do with, (you) come in, (you) enter, (you) go in, (you) have to do with, enter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrambe",
        "translation": "both",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "entrambi",
        "translation": "both, both of",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "entrando",
        "translation": "entering, coming in, going in, having to do with",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrare",
        "translation": "enter, (to) to enter, (to) come in, (to) go in, (to) have to do with, came, come, entering, go, gone, got, walks, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrarono",
        "translation": "(they) entered, (they) came in, (they) went in, entered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrasse",
        "translation": "(he/she/it/you) came in, (he/she/it/you) entered, (he/she/it/you) went in, enter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrassero",
        "translation": "(they) entered, (they) went in, (they) came in",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrassi",
        "translation": "(I/you) came in, (I/you) went in, (I/you) entered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrata",
        "translation": "entrance, entered, entry, admission, doors, entrances, entries, hall, tackle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "entrate",
        "translation": "(you) enter, (you) come in, (you) go in, enter, entrances",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrava",
        "translation": "(he/she/it/you) was/were coming in, (he/she/it/you) was/were entering, (he/she/it/you) went in, (he/she/it/you) used to enter, (he/she/it/you) used to go in, entered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entravano",
        "translation": "(they) were entering, (they) used to go in, (they) went in, entered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrerà",
        "translation": "(he/she/it/you) will enter, (he/she/it/you) will come in, (he/she/it/you) will go in, (he/she/it/you) will have to do with, enter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entreranno",
        "translation": "(they) will enter, (they) will come in, (they) will go in, (they) will have to do with, enter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrerebbe",
        "translation": "(he/she/it/you) would enter, (he/she/it/you) would come in, (he/she/it/you) would go in, (he/she/it/you) would have to do with, enter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entri",
        "translation": "(you) enter, (you) come in, (you) go in, enter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrino",
        "translation": "enter, have to do with, go in",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrò",
        "translation": "(he/she/it/you) entered, (he/she/it/you) came in, (he/she/it/you) went in, (he/she/it/you) had to do with, entered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entro",
        "translation": "(he/she/it/you) entered, (he/she/it/you) came in, (he/she/it/you) went in, (he/she/it/you) had to do with, entered",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "epoca",
        "translation": "era, age, time, epoch",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "eppure",
        "translation": "(and) yet, but, still",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "era",
        "translation": "was, (he/she/it/you) was/were, (he/she/it/you) was/were being, (he/she/it/you) used to be, age, era, felt, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "erano",
        "translation": "were, (they) were, (they) were being, (they) used to be, felt, was",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "erba",
        "translation": "grass, herb, weed",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ero",
        "translation": "(I) was, (I) used to be, (I) was being, felt",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "errore",
        "translation": "mistake, error",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "errori",
        "translation": "errors, mistakes, error, mistake",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esame",
        "translation": "exam, check, check-up, examination, inspection, test",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esami",
        "translation": "examinations, tests, exams",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esattamente",
        "translation": "exactly, accurately, actually, correctly, just, precisely",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "esecuzione",
        "translation": "execution, carrying out, exercise, implementation, playing",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esempi",
        "translation": "examples, specimen, warning",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esempio",
        "translation": "example, for example, warning",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "eserciti",
        "translation": "apply, (you) exert, (you) wield, exercise, (you) exercise",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esercito",
        "translation": "army, (I) exercise, (I) exert, (I) wield, apply, exercise, follow",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esercizi",
        "translation": "exercises, business, businesses, company, discharges, dispatch, firm, practice, practices, shops",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esercizio",
        "translation": "exercise, practice, business, company, discharge, dispatch, firm, shop",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esibizione",
        "translation": "exhibit, display, show",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esistenza",
        "translation": "existence, life",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esperienza",
        "translation": "experience, affair, experiment, practice, relationship",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esperienze",
        "translation": "experiences, experience, practice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esperta",
        "translation": "expert, adept, specialist",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esperti",
        "translation": "experts, experienced, expert",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esperto",
        "translation": "expert, adept, capable, connoisseur, experienced, skilful, skilled, skillful, specialist",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esplorate",
        "translation": "(you) explore",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esploriamo",
        "translation": "(we) explore, (we) analyze, (we) analyse, (we) investigate, (we) probe, (we) probe into, (we) search",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "essendo",
        "translation": "being",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "essenza",
        "translation": "essence, gist, spirits, substance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "essenzialmente",
        "translation": "essentially, basically",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "essere",
        "translation": "(to) be, have, being, be, came, creature, existence, feel, felt, had, person, was, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "est",
        "translation": "east, eastern, eastern Europe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estate",
        "translation": "summer",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estero",
        "translation": "abroad, foreign, overseas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estremamente",
        "translation": "extremely, in the extreme",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "età",
        "translation": "age, era, period",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "etica",
        "translation": "ethic, ethics, consumptive",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "etico",
        "translation": "ethical",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "euro",
        "translation": "euros, euro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "europa",
        "translation": "Europe",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "europea",
        "translation": "European",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "europeo",
        "translation": "European",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "evoluzione",
        "translation": "evolution, development",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fa",
        "translation": "ago, does, cut, equals, follow, goes, is, it is, it's, leave, made, makes, mix, plays, takes, throws",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fabbrica",
        "translation": "factory, building, (you) build, (he/she/it) manufactures, (you) manufacture, produce",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fabbriche",
        "translation": "factories, buildings, constructions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "facce",
        "translation": "faces, expression, looks",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "faccia",
        "translation": "do, face, do drugs, (he/she) makes, (he/she) takes, act as, aspect, expression, form, function / act / serve as, get a fix, get oneself, get stoned, go round, have, jack around, last, look, make, make oneself, make out with, play, run around, shoot up, side, spend, study",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "facciamo",
        "translation": "(we) make, make, (we) do, (we) function / act / serve as, (we) hold, (we) study, (we) take, follow, keep, leave, let us hold, made",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "facendo",
        "translation": "doing, making, acting as, building, following, forming, functioning / act / serve as, having (a meal), leaving, playing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "facesse",
        "translation": "(he/she/it/you) did, (he/she/it/you) did drugs, (he/she/it/you) made, (he/she/it/you) acted as, (he/she/it/you) formed, (he/she/it/you) functioned / act / serve as, (he/she/it/you) got a fix, (he/she/it/you) got oneself, (he/she/it/you) got stoned, (he/she/it/you) had, (he/she/it/you) jacked around, (he/she/it/you) lasted, (he/she/it/you) made oneself, (he/she/it/you) made out with, (he/she/it/you) played, (he/she/it/you) ran around, (he/she/it/you) shot up, (he/she/it/you) spent, (he/she/it/you) studied, (he/she/it/you) went round",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "facessi",
        "translation": "(I/you) did, (I/you) studied, (I/you) made",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faceva",
        "translation": "",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "facevano",
        "translation": "(they) used to do, (they) used to last, (they) used to form, (they) used to act as, (they) used to get a fix, (they) used to have, allowed, baked, kept, left, made",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "facevi",
        "translation": "(you) were doing, (you) were forming, (you) acted as, (you) did, (you) formed, (you) functioned / act / serve as, (you) got oneself, (you) had, (you) lasted, (you) made, (you) made oneself, (you) made out with, (you) played, (you) ran around, (you) spent, (you) studied, (you) used to act as, (you) used to do, (you) used to form, (you) used to function / act / serve as, (you) used to get oneself, (you) used to go round, (you) used to have, (you) used to last, (you) used to make, (you) used to make oneself, (you) used to play, (you) used to run around, (you) used to spend, (you) used to study, (you) went round, (you) were acting as, (you) were functioning / act / serve as, (you) were getting oneself, (you) were going round, (you) were having, (you) were lasting, (you) were making, (you) were making oneself, (you) were making out with, (you) were playing, (you) were running around, (you) were spending, (you) were studying, allowed, kept, left, made",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "facile",
        "translation": "easy, cheap, convenient, likely",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "facilmente",
        "translation": "easily",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "fagiolo",
        "translation": "bean",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fai",
        "translation": "(you) do, (you) make, (you) have, (you) study, have, keep, leave, made, make, makes, mix, you are doing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fallimenti",
        "translation": "failures, bankruptcy, crash, failure",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fallimento",
        "translation": "failure, bankruptcy, collapse, crash, failures",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fallire",
        "translation": "(to) fail, (to) be unsuccessful, (to) miss, abort, broke, broken, fell, go, gone, strike, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fallisce",
        "translation": "(he/she/it) fails, (he/she/it) misses, (you) miss",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fallisci",
        "translation": "(you) fail, (you) miss, (you) are unsuccessful",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fallisco",
        "translation": "(I) fail, (I) miss, (I) abort, (I) am unsuccessful, (I) go astray, (I) go bankrupt, (I) go wrong",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "falliscono",
        "translation": "(they) fail, (they) miss, (they) abort, (they) are unsuccessful, (they) go astray, (they) go bankrupt, (they) go wrong",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fama",
        "translation": "fame, reputation, rumour",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fame",
        "translation": "hunger, famine, starvation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "famiglia",
        "translation": "family",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "familiare",
        "translation": "familiar, member of a family, confidential, domestic help, estate car, families, family, family member, friendly, informal, station wagon, well-known",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "famosa",
        "translation": "famous, well-known",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "famoso",
        "translation": "famous, renowned, well-known",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fantasma",
        "translation": "ghost, ghosts, phantom, spectre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fantasmi",
        "translation": "ghosts, ghost, phantoms",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "farà",
        "translation": "make, (he/she/it/you) will make, (he/she/it/you) will do, (he/she/it/you) will act as, (he/she/it/you) will form, (he/she/it/you) will get a fix, (he/she/it/you) will have, (he/she/it/you) will last, (he/she/it/you) will make out with, (he/she/it/you) will play, (he/she/it/you) will study, do, follow, followed, keep, leave, left, made, makes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "farai",
        "translation": "make, (you) will make, (you) will do, (you) will act as, (you) will do drugs, (you) will form, (you) will function / act / serve as, (you) will get a fix, (you) will get oneself, (you) will get stoned, (you) will go round, (you) will have, (you) will jack around, (you) will last, (you) will make oneself, (you) will make out with, (you) will play, (you) will run around, (you) will shoot up, (you) will spend, (you) will study, do, keep, leave, left",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faranno",
        "translation": "do, (they) will do, (they) will make, (they) will act as, (they) will form, (they) will get a fix, (they) will have, (they) will last, (they) will make out with, (they) will play, (they) will study, leave, left, made, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fare",
        "translation": "baked, come, do, doing, done, get, got, keep, leave, left, make, makes, making, mix, put, throws, to do, to give, to have, to make, to study, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "farebbe",
        "translation": "(he/she/it/you) would do, (he/she/it/you) would make, (he/she/it/you) would do drugs, (he/she/it/you) would act as, (he/she/it/you) would form, (he/she/it/you) would function / act / serve as, (he/she/it/you) would get a fix, (he/she/it/you) would get oneself, (he/she/it/you) would get stoned, (he/she/it/you) would go round, (he/she/it/you) would have, (he/she/it/you) would jack around, (he/she/it/you) would last, (he/she/it/you) would make oneself, (he/she/it/you) would make out with, (he/she/it/you) would play, (he/she/it/you) would run around, (he/she/it/you) would shoot up, (he/she/it/you) would spend, (he/she/it/you) would study, keep, leave, left, made, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "farebbero",
        "translation": "(they) would do, (they) would have, (they) would make, (they) would act as, (they) would form, (they) would get a fix, (they) would last, (they) would make out with, (they) would play, allowed, left",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "farei",
        "translation": "(I) would do, (I) would last, (I) would make, (I) would act as, (I) would form, (I) would get a fix, (I) would have, (I) would make out with, (I) would play, allowed, keep, leave, left, make, mind",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faremmo",
        "translation": "do, (we) would do, (we) would play, (we) would act as, (we) would form, (we) would get a fix, (we) would have, (we) would last, (we) would make, (we) would make out with, keep, left, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faremo",
        "translation": "do, (we) will do, (we) will have, (we) will act as, (we) will form, (we) will get a fix, (we) will last, (we) will make, (we) will make out with, (we) will play, (we) will study, follow, keep, leave, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faresti",
        "translation": "do, (you) would do, (you) would last, (you) would act as, (you) would form, (you) would get a fix, (you) would have, (you) would make, (you) would make out with, (you) would play, follow, leave, left, made, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "farete",
        "translation": "do, (you) will do, (you) will form, (you) will act as, (you) will get a fix, (you) will have, (you) will last, (you) will make, (you) will make out with, (you) will play, hold, keep, leave, left, make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "farfalla",
        "translation": "butterfly, butterfly valve, bow pasta, butterflies, throttle (valve)",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "farfalle",
        "translation": "butterflies, butterfly valve, bow pasta, throttle (valve)",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "farlo",
        "translation": "do it, make it",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "farmacia",
        "translation": "pharmacy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "farò",
        "translation": "(I) will do, (I) will have, (I) will make, (I) will act as, (I) will form, (I) will get a fix, (I) will last, (I) will make out with, (I) will play, (I) will study, keep, leave, left, made, make, mind",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fase",
        "translation": "phase, stage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fasi",
        "translation": "phase, stage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fate",
        "translation": "make, (you) make, (you) act as, (you) do, (you) function / act / serve as, (you) give, (you) study, follow, have, keep, leave, made, makes, mix",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fattoria",
        "translation": "farm, farmhouse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "favore",
        "translation": "favor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "febbraio",
        "translation": "February",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "febbre",
        "translation": "fever, cold sore, temperature",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fece",
        "translation": "(he/she/it/you) made, (he/she/it/you) made out with, (he/she/it/you) did, allowed, baked, cut, do, follow, followed, had, held, kept, left, made, make, mind",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "feci",
        "translation": "(I) did, (I) made, (I) studied, (I) took, did, leave, left, make, mind",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fede",
        "translation": "faith, belief, creed, trust",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "felice",
        "translation": "happy, glad, good",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "felici",
        "translation": "happy, good, successful",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "felicità",
        "translation": "happiness, bliss, delight, felicity, gladness, joy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fenomeni",
        "translation": "phenomenon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fenomeno",
        "translation": "phenomenon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "feriamo",
        "translation": "(we) hurt, (we) injure, (we) wound, hurt",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ferire",
        "translation": "(to) hurt, cut, hurt, injure, wound",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ferisce",
        "translation": "(he/she/it) hurts, (he/she/it) hurts oneself, (he/she/it) injures, (he/she/it) injures oneself, (he/she/it) makes [something] sore, (he/she/it) offends, (he/she/it) wounds, (you) hurt, (you) hurt oneself, (you) injure, (you) injure oneself, (you) make [something] sore, (you) offend, (you) wound, hurt",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ferma",
        "translation": "(you) stop, (he/she/it) stops, (you) come to a stop, (he/she/it) books, (he/she/it) calls, (he/she/it) casts off, (he/she/it) comes to a stop, (he/she/it) detains, (he/she/it) discontinues, (he/she/it) fails, (he/she/it) fixes, (he/she/it) halts, (he/she/it) pulls [somebody] over, (he/she/it) pulls in, (he/she/it) reserves, (he/she/it) runs down, (he/she/it) sets down, (he/she/it) shuts down, (he/she/it) stalls, (he/she/it) switches off, (he/she/it) ups, (he/she/it/you) staunch, (period of military) service, (you) book, (you) call, (you) cast off, (you) detain, (you) discontinue, (you) fail, (you) fix, (you) halt, (you) pull [somebody] over, (you) pull in, (you) reserve, (you) run down, (you) set down, (you) shut down, (you) stall, (you) switch off, (you) up, set, still, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fermano",
        "translation": "(they) stop, (they) come to a stop, (they) halt, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fermare",
        "translation": "(to) stop, (to) to stop, (to) to come to a stop, (to) book, (to) call, (to) cast off, (to) discontinue, (to) fail, (to) fix, (to) pull [somebody] over, (to) pull in, (to) set down, (to) shut down, (to) staunch, (to) to detain, (to) to halt, (to) to pull in, (to) to reserve, (to) to run down, (to) to stall, (to) to switch off, (to) up, come to a stop, detain, halt, hold, reserve, run down, stall, stop, stopped, switch off",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fermata",
        "translation": "stop (over), halt, stop, stopped",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fermo",
        "translation": "(I) stop, still, stop payment, (I) book, (I) call, (I) cast off, (I) come to a stop, (I) detain, (I) discontinue, (I) fail, (I) fix, (I) halt, (I) pull [somebody] over, (I) pull in, (I) reserve, (I) run down, (I) set down, (I) shut down, (I) stall, (I) staunch, (I) switch off, (I) up, at a standstill, catch, caught, clear, custody, detention, dull, fastener, firm, fixed, freeze, holdback, idle, latch, lock, not running, not working, provisional arrest, resolute, solid, stagnant, stalled, standing, stationary, steadfast, steady, stop, strong, stronger, sure, unfaltering, unsold, unwavering",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ferroviaria",
        "translation": "train, railway, rail, railroad",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ferroviario",
        "translation": "train, rail, railway, trains",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "festa",
        "translation": "party, holiday, done, festivity",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "feste",
        "translation": "parties, holidays",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fidanzata",
        "translation": "girlfriend, fiancée, girlfriends",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fidanzate",
        "translation": "fiancées, (you) affiance, (you) betroth, (you) get engaged, (you) promise, girlfriends",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fidanzato",
        "translation": "boyfriend, fiancé, affiance, betrothed, engaged, got/gotten engaged, intended, promised",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "figli",
        "translation": "children, sons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "figlia",
        "translation": "daughter, child, girl",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "figlie",
        "translation": "daughters, child, children, girls, tear-off part",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "figlio",
        "translation": "son, child, boy",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fila",
        "translation": "line, row, in a row, (he/she/it) belts (off), (he/she/it) bowls along, (he/she/it) buzzes off, (he/she/it) casts off, (he/she/it) clears off, (he/she/it) clears out, (he/she/it) dashes off, (he/she/it) draws, (he/she/it) gets away, (he/she/it) goes stringy, (he/she/it) hangs together, (he/she/it) holds, (he/she/it) makes sense, (he/she/it) mills, (he/she/it) pays out, (he/she/it) runs away, (he/she/it) scuttles off, (he/she/it) slinks off, (he/she/it) slips, (he/she/it) speeds along, (he/she/it) speeds away, (he/she/it) speeds off, (he/she/it) spins, (he/she/it) spins away, (he/she/it) takes off, (he/she/it/you) unlash, (you) belt (off), (you) bowl along, (you) buzz off, (you) cast off, (you) clear off, (you) clear out, (you) dash off, (you) draw, (you) get away, (you) go stringy, (you) hang together, (you) hold, (you) make sense, (you) mill, (you) pay out, (you) run away, (you) scuttle off, (you) slink off, (you) slip, (you) speed along, (you) speed away, (you) speed off, (you) spin, (you) spin away, (you) take off, blade, cutting edge, drizzle, edge, file, grain, hip, lane, queue, rank, rope, running, strand, straw, string, thread, tightrope, trickle, wire, yarn",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "film",
        "translation": "movie, film, movies, films",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "filmare",
        "translation": "(to) film, (to) video, (to) tape, film",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "filosofia",
        "translation": "philosophy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "filosofie",
        "translation": "philosophy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "finale",
        "translation": "final, final clause, last, closing, crowning, definitive, denouement, end, ending, finale, finish, finished, finishing, trace, ultimate",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "finalmente",
        "translation": "finally, in the end, at last, eventually, last",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "finanza",
        "translation": "finance, finances",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "finanziamenti",
        "translation": "financing, financial backing, funding",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "finanziamento",
        "translation": "funding, financial backing, financing",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "finché",
        "translation": "until, while, as long as, so long as, till",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "fine",
        "translation": "end, aim, bottom, conclusion, delicate, elegant, ending, exquisite, fine, finely, finish, finished, keen, objectives, passing, purpose, quality, refined, sharp, slim, subtle, superfine, thin, uses",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "finendo",
        "translation": "ending, finishing, finishing off, doing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finestra",
        "translation": "window",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "finestre",
        "translation": "windows",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "finì",
        "translation": "(he/she/it/you) ended, (he/she/it/you) finished, (he/she/it/you) finished off, (he/she/it) ended up, (he/she/it/you) did, closed, do, finished, stop, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finiamo",
        "translation": "(we) finish, (we) finish off, (we) do, do, spend, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finirà",
        "translation": "(he/she/it/you) will end, (he/she/it/you) will finish, (he/she/it/you) will terminate, (he/she/it/you) will cease, (he/she/it/you) will close, (he/she/it/you) will come to, (he/she/it/you) will come to an end, (he/she/it/you) will complete, (he/she/it/you) will conclude, (he/she/it/you) will do, (he/she/it/you) will expire, (he/she/it/you) will finish off, (he/she/it/you) will get through, (he/she/it/you) will go, (he/she/it/you) will go out, (he/she/it/you) will pan out, (he/she/it/you) will run out, (he/she/it/you) will run out of, (he/she/it/you) will spend, (he/she/it/you) will stop, (he/she/it/you) will use up, finish, stop, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finiranno",
        "translation": "(they) will finish, (they) will end, (they) will finish off, (they) will come to, (they) will come to an end, (they) will do, (they) will go, (they) will go out, (they) will pan out, (they) will run out, (they) will run out of, (they) will stop, (they) will use up, finish",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finire",
        "translation": "(to) finish, (to) end, (to) to do, (to) cease, (to) come to, (to) complete, (to) conclude, (to) expire, (to) finish off, (to) get through, (to) go, (to) go out, (to) run out, (to) run out of, (to) spend, (to) terminate, (to) to close, (to) to come to an end, (to) to complete, (to) to pan out, (to) to stop, (to) to use up, (to) use up, ate, close, come to an end, do, done, finish, finished, get, go, gone, got, let, pan out, round, run, runs, serve, spend, stop, stopped, used, uses, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finirebbe",
        "translation": "(he/she/it/you) would end, (he/she/it/you) would stop, (he/she/it/you) would go, (he/she/it/you) would come to, (he/she/it/you) would come to an end, (he/she/it/you) would do, (he/she/it/you) would go out, (he/she/it/you) would pan out, (he/she/it/you) would run out, (he/she/it/you) would run out of, (he/she/it/you) would use up, finished, stop, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finiremo",
        "translation": "(we) will finish, (we) will come to, (we) will finish off, (we) will come to an end, (we) will do, (we) will end, (we) will go, (we) will go out, (we) will pan out, (we) will run out, (we) will run out of, (we) will stop, (we) will use up, do, stop, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finisca",
        "translation": "finish, do, finish off, complete",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finisce",
        "translation": "(he/she/it) ends, (he/she/it) finishes, (he/she/it) ceases, (he/she/it) closes, (he/she/it) comes to an end, (he/she/it) completes, (he/she/it) concludes, (he/she/it) runs out, (he/she/it) runs out of, (he/she/it) stops, (he/she/it) terminates, (you) cease, (you) close, (you) come to an end, (you) complete, (you) conclude, (you) end, (you) expire, (you) finish, (you) finish off, (you) run out, (you) run out of, (you) stop, (you) terminate, (you) use up, does (he/she/it) finish, finish, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finisci",
        "translation": "(you) finish, do, (you) do, (you) finish off, finish, spend, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finisse",
        "translation": "(he/she/it/you) completed, (he/she/it/you) finished, end, (he/she/it/you) did",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finissero",
        "translation": "(they) finished, (they) finished off, (they) did",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finiva",
        "translation": "",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finivamo",
        "translation": "(we) finished, (we) finished off, (we) used to finish, finished, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fino",
        "translation": "as far as, even, fine, fine-grained, till, until, up, up until",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "fiore",
        "translation": "flower",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fiori",
        "translation": "flowers, flower",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fisica",
        "translation": "physics, physical, physicist",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fisici",
        "translation": "physical, figures, physicists",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fiume",
        "translation": "river, stream",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fiumi",
        "translation": "river, stream",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "flauti",
        "translation": "flutes, flute",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "flauto",
        "translation": "flute",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fogli",
        "translation": "sheets, sheets of paper, (you) foliate, (you) leaf",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "foglio",
        "translation": "piece of paper, sheet of paper",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "folla",
        "translation": "crowd, (he/she/it/you) full, battalion, cram, host, huddle, mass, mob, press, rabble, shoal, throng",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fondamentale",
        "translation": "fundamental, essential, basic, critical, crucial, ultimate, vital",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fondo",
        "translation": "bottom (end), (I) found (establish), fund (deposit)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "forchetta",
        "translation": "fork",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "forchette",
        "translation": "forks",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "foresta",
        "translation": "forest",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "forma",
        "translation": "form, (he/she/it) is trained, (he/she/it) arises, (he/she/it) builds, (he/she/it) builds up, (he/she/it) composes, (he/she/it) develops, (he/she/it) dials, (he/she/it) educates oneself, (he/she/it) establishes, (he/she/it) flowers, (he/she/it) forges, (he/she/it) forms, (he/she/it) grows (up), (he/she/it) makes, (he/she/it) molds, (he/she/it) picks, (he/she/it) presses, (he/she/it) puts together, (he/she/it) raises, (he/she/it) sets up, (he/she/it) shapes, (he/she/it) starts, (he/she/it) trains (up), (he/she/it) turns out, (he/she/it/you) mould, (physical) fitness, (you) are trained, (you) arise, (you) build, (you) build up, (you) compose, (you) develop, (you) dial, (you) educate oneself, (you) establish, (you) flower, (you) forge, (you) form, (you) grow (up), (you) make, (you) mold, (you) pick, (you) press, (you) put together, (you) raise, (you) set up, (you) start, (you) train (up), (you) turn out, be trained, cast, condition, figure, last, lines, loaf, round, shape, shoe tree, stretcher, tin, train, trains",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "formaggio",
        "translation": "cheese",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "formale",
        "translation": "formal, ceremonious, cerimonial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "formali",
        "translation": "formal, ceremonious, cerimonial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "formica",
        "translation": "ant",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "formula",
        "translation": "formula, (he/she/it) formulates, (you) formulate, form, phrases",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "forno",
        "translation": "oven, bakery, baker’s (shop)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "forse",
        "translation": "maybe, possibly, some, about, by chance, perhaps, probably",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "forte",
        "translation": "strong, strong-arm, stronger, big, biggest, bright, brilliant, broad, brutal, deep, forceful, fort, forte, good, great, hard, harsh, healthy, hearty, heavily, heavy, high, hot, intense, keen, large, larger, long, loud, loudly, mighty person, potent, powerful, pungent, robust, rugged, sharp, smouldering, sound, steadfast, stiff, strongly, strongman, sturdy, thick, tough, upstanding",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fortemente",
        "translation": "strongly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "forti",
        "translation": "strong, good, big, bright, broad, deep, forceful, fort, forte, great, hard, harsh, healthy, hearty, heavily, heavy, high, intense, keen, large, long, loud, loudly, mighty person, potent, powerful, pungent, robust, rugged, sharp, sound, steadfast, stiff, strongly, strongman, sturdy, thick, upstanding",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fortuna",
        "translation": "luck, good luck, fortune, blessedness, chance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fortunata",
        "translation": "lucky, happy, successful",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fortunatamente",
        "translation": "fortunately, blessedly, happily, thankfully",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "fortunati",
        "translation": "lucky, fortunate, happy",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "forza",
        "translation": "strength, (you) force, (you) force oneself, (he/she/it) breaks through, (he/she/it) contrives, (he/she/it) forces, (he/she/it) forces oneself, (he/she/it) picks, (he/she/it) speeds up, (he/she/it) stretches, (he/she/it/you) prise, (you) break through, (you) contrive, (you) pick, (you) speed up, (you) stretch, intensity, may, violence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "forze",
        "translation": "strength, (he/she/it) forces, (he/she/it) forces oneself, (he/she/it) breaks through, (he/she/it) contrives, (he/she/it) picks, (he/she/it) speeds up, (he/she/it) stretches, (he/she/it/you) prise, (you) break through, (you) contrive, (you) force, (you) force oneself, (you) pick, (you) speed up, (you) stretch, intensity, violence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fosse",
        "translation": "was, (he/she/it) was, were, (you) were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fossi",
        "translation": "(you) were, (I) was",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "foste",
        "translation": "were, (you) were, feel, felt, was",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "foto",
        "translation": "photo, photographs, photos, pic, picture, shot",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fotografare",
        "translation": "(to) photograph, (to) to take a photo(graph) of, (to) to make a mental picture of, (to) draw an accurate picture of, (to) fix [something] in one’s mind, make a mental picture of, take a photo(graph) of",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fotografia",
        "translation": "photograph, photography, picture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fotografica",
        "translation": "photographic",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fotografiche",
        "translation": "photographic",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fotografie",
        "translation": "photographs, pictures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fra",
        "translation": "in, between, among",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "fragola",
        "translation": "strawberry, strawberries",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "francese",
        "translation": "French",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "francesi",
        "translation": "French, French people, French-speaking",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "francia",
        "translation": "France",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "francobolli",
        "translation": "stamps, (postage) stamp",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "francobollo",
        "translation": "(postage) stamp",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "frase",
        "translation": "sentence, phrase, expression, phrases",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fratelli",
        "translation": "brothers, siblings",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fratello",
        "translation": "brother",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fredda",
        "translation": "cold, (you) get cold, (he/she/it) gets cold, (he/she/it) cools down, (he/she/it) guns down, (you) cool down, (you) gun down",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "freddo",
        "translation": "cold, chilly, coldest, dry, ice",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "frequente",
        "translation": "frequent, common, frequently",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "frequentemente",
        "translation": "frequently, often",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "frequenti",
        "translation": "frequent, (you) frequent, (you) associate with, (you) attend, (you) go out together, (you) go out with, (you) go with, (you) hang around with, (you) haunt, (you) knock about together, (you) mix (up) with, (you) see, (you) see one another",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "frequenza",
        "translation": "frequency, attendance, prevalence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fresca",
        "translation": "fresh, cool, coolness, crisp, fresco, hot, red-hot",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fresco",
        "translation": "fresh, cool",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "frigge",
        "translation": "(he/she/it) fries, (he/she/it) sizzles, (you) fry, fries",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "friggi",
        "translation": "(you) fry, (you) sizzle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "friggiamo",
        "translation": "(we) fry, (we) sizzle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "frigoriferi",
        "translation": "refrigerators, fridge, refrigerated",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "frigorifero",
        "translation": "refrigerator, fridge, refrigerators",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fritto",
        "translation": "fried, deep-fried, fries, sizzled",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fronte",
        "translation": "front, opposite, brow, façade, forehead, frontal system",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "frontiera",
        "translation": "border, frontier",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "frutta",
        "translation": "fruit, (he/she/it) bears fruit",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fu",
        "translation": "was, (he/she/it) was, (you) were, be, feel, felt, late, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fuma",
        "translation": "(you) smoke, (he/she/it) smokes, (he/she/it) steams",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fumare",
        "translation": "(to) smoke, smoking, (to) to fume, (to) fume, (to) give off fumes, (to) steam",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fumi",
        "translation": "(you) smoke, (you) steam, (you) give off fumes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fummo",
        "translation": "were, (we) were, felt, was",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fumo",
        "translation": "(I) smoke, (I) fume, (I) give off fumes, (I) steam, pot, smoking",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "funghi",
        "translation": "mushrooms",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fuoco",
        "translation": "fire, heat, burner, focus, gas ring",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fuori",
        "translation": "out, outside, outdoors",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "furba",
        "translation": "cunning, crafty, sly, smart aleck, sneaky",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "furbe",
        "translation": "crafty, cunning, shrewd, sly, sneaky",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "furbi",
        "translation": "cunning, crafty, shrewd, sly",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "furbo",
        "translation": "sly, crafty, cunning, shrewd",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "futura",
        "translation": "future",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "galleria",
        "translation": "gallery, tunnel, arcade, balcony, circle, galleries",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gallerie",
        "translation": "galleries, gallery, tunnel",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gamba",
        "translation": "leg, stem",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gambe",
        "translation": "legs",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gara",
        "translation": "race, competition, contest, event, events",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gas",
        "translation": "gas, gases",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gatte",
        "translation": "cats (f.), cats",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gatti",
        "translation": "cats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gatto",
        "translation": "cat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gelato",
        "translation": "ice cream, ice-cold, been killed by frost, chilled, frosted, frozen, frozen over, frozen up, ice-cream, icy",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "generale",
        "translation": "general, in general, broad-based, full-scale, public",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "generalmente",
        "translation": "generally, usually, widely",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "generazione",
        "translation": "generation, generations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "generazioni",
        "translation": "generations, generation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "genitori",
        "translation": "parents",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gennaio",
        "translation": "January",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gente",
        "translation": "people",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gentile",
        "translation": "kind, good, nice, polite",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "geografia",
        "translation": "geography",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "germania",
        "translation": "Germany",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "ghiaccio",
        "translation": "ice, ice-cold, as cold as ice, icy, icy-cold",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "già",
        "translation": "already, before, by then, right, yet",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "giacca",
        "translation": "blazer, jacket",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gialla",
        "translation": "yellow",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gialle",
        "translation": "yellow (pl.), (egg) yolk, amber",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gialli",
        "translation": "yellow, (egg) yolk, amber, crime, detective, detective film, detective novel, detective story, mystery, sallow",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "giardino",
        "translation": "garden, yard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ginocchio",
        "translation": "knee",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gioca",
        "translation": "(he/she/it) plays, (you) play, (he/she/it) stakes, (he/she/it) bets, (he/she/it) gambles, (he/she/it) risks, (you) bet, (you) gamble, (you) risk, (you) stake, plays",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocammo",
        "translation": "played, (we) played, (we) risked, (we) betted/bet, (we) gambled, (we) staked, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocando",
        "translation": "playing, gambling, staking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocano",
        "translation": "(they) play, (they) bet, (they) gamble",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocare",
        "translation": "(to) to bet, (to) gamble, (to) risk, (to) stake, play, playing, to play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocarono",
        "translation": "played, (they) played, (they) gambled, (they) staked, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocasse",
        "translation": "(he/she/it/you) played, (he/she/it/you) gambled, (he/she/it/you) staked, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocassero",
        "translation": "played, (they) played, (they) gambled, (they) staked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocatore",
        "translation": "player, gambler",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giocatori",
        "translation": "players",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giocattoli",
        "translation": "toys",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giocattolo",
        "translation": "toy, plaything",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giocava",
        "translation": "(he/she/it/you) was/were playing, played, (he/she/it/you) was/were gambling, (he/she/it/you) betted/bet, (he/she/it/you) gambled, (he/she/it/you) played, (he/she/it/you) risked, (he/she/it/you) staked, (he/she/it/you) used to bet, (he/she/it/you) used to gamble, (he/she/it/you) used to play, (he/she/it/you) used to risk, (he/she/it/you) used to stake, (he/she/it/you) was/were betting, (he/she/it/you) was/were risking, (he/she/it/you) was/were staking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocavamo",
        "translation": "played, (we) played, (we) were playing, (we) used to play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocavano",
        "translation": "(they) were playing, (they) played, (they) used to play, played",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocherà",
        "translation": "(he/she/it/you) will play, (he/she/it/you) will bet, (he/she/it/you) will risk, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocheranno",
        "translation": "(they) will play, (they) will risk, (they) will bet, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocherebbe",
        "translation": "(he/she/it/you) would play, (he/she/it/you) would risk, (he/she/it/you) would bet, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocherei",
        "translation": "(I) would play, (I) would risk, (I) would bet, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocheresti",
        "translation": "(you) would play, (you) would bet, (you) would risk, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocherò",
        "translation": "(I) will play, (I) will risk, (I) will bet, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giochi",
        "translation": "games, plays, (you) play, (you) bet, (you) gamble, (you) risk, (you) stake, fun, toys",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giochiamo",
        "translation": "(we) play, (we) gamble, (we) stake",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gioco",
        "translation": "played, (he/she/it/you) played, (he/she/it/you) gambled, (he/she/it/you) staked, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giocò",
        "translation": "played, (he/she/it/you) played, (he/she/it/you) gambled, (he/she/it/you) staked, play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gioia",
        "translation": "joy, bijou, jewel",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gioielleria",
        "translation": "jewelry store",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "giornale",
        "translation": "newspaper",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giornali",
        "translation": "newspapers, periodicals",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giornalista",
        "translation": "journalist",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "giornalisti",
        "translation": "journalists, journalist, newsman",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giorni",
        "translation": "days",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giorno",
        "translation": "day",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giovane",
        "translation": "young, new",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "giovani",
        "translation": "young, youngsters, new, young people",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "giovedì",
        "translation": "Thursday",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gira",
        "translation": "(you) turn over, (he/she/it) turns (over), (he/she/it) flips over, (he/she/it) goes around, (he/she/it) goes round, (he/she/it) rephrases, (he/she/it) rounds, (he/she/it) runs, (he/she/it) shoots, (he/she/it) spins, (he/she/it) stirs, (he/she/it) tours, (he/she/it) transfers, (he/she/it) turns over, (he/she/it) wanders (around), (you) flip over, (you) go around, (you) go round, (you) rephrase, (you) round, (you) run, (you) shoot, (you) spin, (you) stir, (you) tour, (you) transfer, (you) turn, (you) wander (around), forks",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "girare",
        "translation": "(to) to turn, go, (to) turn (over), (to) flip over, (to) go around, (to) go round, (to) rephrase, (to) run, (to) shoot, (to) stir, (to) to flip over, (to) to go round, (to) to round, (to) to spin, (to) tour, (to) transfer, (to) turn over, (to) wander (around), broke, broken, forks, gone, got, round, spin, turned, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "girate",
        "translation": "film, (you) turn over, (you) turn (over), (you) flip over, (you) shoot",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giù",
        "translation": "down, to and fro, about, approximately, around, below, downstairs, downwards, lower, more or less, thereabouts",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "giudica",
        "translation": "judges, (you) judge, (he/she/it) judges, (he/she/it) considers, (you) think, put, puts",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giudicare",
        "translation": "judging, (to) judge, (to) to think, (to) consider, (to) to adjudicate, (to) try, adjudicate, judges, put, puts, think, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giudice",
        "translation": "judge, judges",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "giudichiamo",
        "translation": "judge",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giudici",
        "translation": "judges, judge",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "giudizi",
        "translation": "opinions, judgments, opinion, wisdom",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giudizio",
        "translation": "opinion, (common) sense, judgment",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giugno",
        "translation": "June",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "giusta",
        "translation": "right, correct, fair, just, just person, righteous person",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "giustizia",
        "translation": "justice, (he/she/it) burns, (he/she/it) electrocutes, (he/she/it) executes, (you) burn, (you) electrocute, (you) execute, fairness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "giusto",
        "translation": "just, right, fair, Justus, correct, even, just person, justifiable, legitimate, righteous person, true, uses",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gli",
        "translation": "the, him, it, them, to him",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "glielo",
        "translation": "it, it to him, it to her",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "globale",
        "translation": "global, blankets, overall, total",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "gol",
        "translation": "goal, goals",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gola",
        "translation": "throat, gluttony, gorge",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gonna",
        "translation": "skirt, skirts",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "governatore",
        "translation": "governor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "governatrice",
        "translation": "(female) governor",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "governi",
        "translation": "governments, (you) govern, (you) rule, (you) manage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "governo",
        "translation": "government, (I) drive, (I) govern, (I) look after, (I) manage, (I) navigate, (I) rule, (I) run, (I) steer, (I) take care of, (I) tend, cabinet, housekeeping, managing, steerage, steering",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grammi",
        "translation": "grams, gram",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grammo",
        "translation": "gram, grams",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grande",
        "translation": "big, great, large, tall, wide",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "grandiosa",
        "translation": "grand, great, noble",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grandiosi",
        "translation": "grand, grandiose, magnificent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grandioso",
        "translation": "grand, great, noble",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grassa",
        "translation": "fat, blubber, coarse, coarsely, crude, fatty, fertile, grease, greasy, handsome, oily, phlegmy, plentiful, productive, rich",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grasso",
        "translation": "fat, blubber, coarse, coarsely, crude, fatty, fertile, grease, greasy, handsome, oily, phlegmy, plentiful, productive, rich",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gratuita",
        "translation": "free, rent-free, free of charge",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gratuito",
        "translation": "free, free of charge, gratuitous, selfless, senseless, spurious, unfounded, uninvited, unselfish",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grave",
        "translation": "serious, grave, acute, bass, body, deep, grievous, gross, heavy, low, major, momentous, severe, slow, solemn, weighty, worse",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "gravemente",
        "translation": "seriously, gravely, grievously",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "gravità",
        "translation": "gravity, depth, graveness, grossness, heaviness, lowness, momentousness, seriousness, severity, solemnity, weightiness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "grazie",
        "translation": "thank you, thanks",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "grida",
        "translation": "(he/she/it) scolds, (he/she/it) shouts, (he/she/it) cries out, (he/she/it) gives a cry, (he/she/it) screams, (he/she/it) yells, (you) cry out, (you) give a cry, (you) shout, scream",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gridano",
        "translation": "call, (they) yell, (they) tell off, (they) scold, (they) shout",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grido",
        "translation": "scream, (I) cry out, (I) shout, (I) scold, (I) tell off, (I) yell",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grigia",
        "translation": "gray, grey",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grigie",
        "translation": "gray, grey",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grigio",
        "translation": "gray, grey",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "griglia",
        "translation": "grill, (you) grill, (he/she/it) barbecues, (he/she/it) grills",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "grossi",
        "translation": "large, big, bulky, coarse, fat, great, major, pregnant, rough, roughly, stormy, thick",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grosso",
        "translation": "big, great, large, thick",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gruppo",
        "translation": "group, band, cluster, party",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "guadagna",
        "translation": "(he/she/it) earns, make, (you) earn, (he/she/it) gains, made, makes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guadagnare",
        "translation": "(to) gain, (to) earn, (to) to get to, (to) reach, (to) save, get to, made, make, makes, to make",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guai",
        "translation": "trouble, problems, troubles, woe betide",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "guanti",
        "translation": "gloves",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "guanto",
        "translation": "glove",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "guarda",
        "translation": "(you) look at, (he/she/it) looks at, (you) look, (he/she/it) keeps watch on, (he/she/it) looks, (he/she/it) looks after, (he/she/it) looks at each other, (he/she/it) looks at oneself, (he/she/it) looks on, (he/she/it) looks to, (he/she/it) looks up, (he/she/it) minds, (he/she/it) takes a (close) look at, (he/she/it) takes care, (he/she/it) takes care of, (he/she/it) watches, (he/she/it/you) one another, (you) keep watch on, (you) look after, (you) look at each other, (you) look at oneself, (you) look on, (you) look to, (you) look up, (you) mind, (you) take a (close) look at, (you) take care, (you) take care of, (you) watch, look, view, views, watches",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardai",
        "translation": "(I) watched, (I) looked after, (I) looked at, look, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardammo",
        "translation": "(we) watched, (we) one (past) another, (we) looked at, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardando",
        "translation": "watching, looking, looking at, keeping watch on, looking after, looking at each other, looking at oneself, looking on, looking to, looking up, minding, one another, taking a (close) look at, taking care, taking care of",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardare",
        "translation": "look to, (to) to look to, (to) look, (to) keep watch on, (to) look after, (to) look at, (to) look at each other, (to) look at oneself, (to) look on, (to) look up, (to) mind, (to) one another, (to) take a (close) look at, (to) take care, (to) to look at, (to) to take care of, (to) watch, look, take care of, view, views, watched, watches",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardarono",
        "translation": "(they) watched, (they) looked at, (they) looked after, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardasse",
        "translation": "(he/she/it/you) looked at, (he/she/it/you) looked after, (he/she/it/you) watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardassi",
        "translation": "(I/you) looked at, (I/you) watched, (I/you) looked after",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardate",
        "translation": "(you) look at, (you) watch, (you) look on, (you) look to, (you) look up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardava",
        "translation": "(he/she/it/you) was/were watching, (he/she/it/you) was/were looking, (he/she/it/you) was/were looking at, (he/she/it/you) kept watch on, (he/she/it/you) looked, (he/she/it/you) looked after, (he/she/it/you) looked at, (he/she/it/you) looked at each other, (he/she/it/you) looked at oneself, (he/she/it/you) looked on, (he/she/it/you) looked to, (he/she/it/you) looked up, (he/she/it/you) minded, (he/she/it/you) one (past) another, (he/she/it/you) took a (close) look at, (he/she/it/you) took care, (he/she/it/you) took care of, (he/she/it/you) used to keep watch on, (he/she/it/you) used to look, (he/she/it/you) used to look after, (he/she/it/you) used to look at, (he/she/it/you) used to look at each other, (he/she/it/you) used to look at oneself, (he/she/it/you) used to look on, (he/she/it/you) used to look to, (he/she/it/you) used to look up, (he/she/it/you) used to mind, (he/she/it/you) used to one another, (he/she/it/you) used to take a (close) look at, (he/she/it/you) used to take care, (he/she/it/you) used to take care of, (he/she/it/you) used to watch, (he/she/it/you) was/were keeping watch on, (he/she/it/you) was/were looking after, (he/she/it/you) was/were looking at each other, (he/she/it/you) was/were looking at oneself, (he/she/it/you) was/were looking on, (he/she/it/you) was/were looking to, (he/she/it/you) was/were looking up, (he/she/it/you) was/were minding, (he/she/it/you) was/were one another, (he/she/it/you) was/were taking a (close) look at, (he/she/it/you) was/were taking care, (he/she/it/you) was/were taking care of, (he/she/it/you) watched, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guarderai",
        "translation": "(you) will look, (you) will look at, (you) will look to, (you) will look after, (you) will look on, (you) will look up, (you) will mind, (you) will take a (close) look at, (you) will take care, (you) will take care of, (you) will watch, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guarderanno",
        "translation": "(they) will look at, (they) will look, (they) will watch, (they) will keep watch on, (they) will look on, (they) will look to, (they) will look up, (they) will mind, (they) will take a (close) look at, (they) will take care, (they) will take care of, look",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guarderebbe",
        "translation": "(he/she/it/you) would watch, (he/she/it/you) would look, (he/she/it/you) would look at, (he/she/it/you) would keep watch on, (he/she/it/you) would look on, (he/she/it/you) would look to, (he/she/it/you) would look up, (he/she/it/you) would mind, (he/she/it/you) would take a (close) look at, (he/she/it/you) would take care, (he/she/it/you) would take care of, look, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardi",
        "translation": "(you) look, (you) look at, (you) look to, (you) keep watch on, (you) look after, (you) look at each other, (you) look at oneself, (you) look on, (you) look up, (you) mind, (you) one another, (you) take a (close) look at, (you) take care, (you) take care of, (you) watch, look, view",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardia",
        "translation": "guard, constable, endpaper, flood mark, flyleaf, guards, high-water mark, hilt, watch",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "guardiamo",
        "translation": "(we) look, (we) watch, (we) look at, (we) keep watch on, (we) look at each other, (we) look on, (we) look to, (we) look up, look",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardie",
        "translation": "guards, guard, watch",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "guardino",
        "translation": "look at each other, look, one another, look at oneself, watch, look on, look at, mind, take care of, take care, look up, look after, keep watch on, take a (close) look at, look to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardò",
        "translation": "(he/she/it/you) looked, (he/she/it/you) looked after, (he/she/it/you) looked on, (he/she/it/you) looked at, (he/she/it/you) looked at each other, (he/she/it/you) looked to, (he/she/it/you) looked up, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guerra",
        "translation": "war, battle, warfare",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "guida",
        "translation": "guide, (you) drive, (he/she/it) drives, (he/she/it) guides, drive, driving",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guidare",
        "translation": "(to) to take, (to) to lead, (to) behave, (to) carry out, (to) conduct, (to) guide, (to) head, (to) lead, (to) manage, (to) ride, (to) to conduct oneself, (to) to run, conduct oneself, driving, run, take, to drive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guidiamo",
        "translation": "(we) drive, (we) behave, (we) take, (we) are driving, (we) carry out, (we) conduct, (we) conduct oneself, (we) guide, (we) head, (we) lead, (we) manage, (we) ride, (we) run, drive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guido",
        "translation": "(I) drive, (I) take, (I) guide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gusto",
        "translation": "(I) taste, flavor, (I) try, (I) appreciate, (I) enjoy, (I) like, (I) relish, (I) savor, (I) savour, flavour, with gusto, with relish",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ha",
        "translation": "has, (he/she/it) has, have, does he have, had, has got, keep, offers, wear, wears, you have [formal]",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hai",
        "translation": "have, (you) have, do you have, had, keep, own",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hanno",
        "translation": "have, (they) have, own, had, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ho",
        "translation": "have, (I) have, own, had, have got, keep, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "i",
        "translation": "the",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "idea",
        "translation": "idea, mind, (he/she/it) imagines, (he/she/it) thinks up, (you) think up, thought, thoughts, visions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ideale",
        "translation": "ideal, cause, perfect",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "idraulici",
        "translation": "plumbers, hydraulics, plumber",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "idraulico",
        "translation": "plumber, hydraulic",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ieri",
        "translation": "yesterday",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "il",
        "translation": "the",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "imbarazzata",
        "translation": "embarrassed, be embarrassed, been embarrassed",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "immaginare",
        "translation": "(to) imagine, figure, imagine, picture, suppose, view, views",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "immaginazione",
        "translation": "imagination, fancy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "immagine",
        "translation": "image, picture, corporate image, brand image, illustration, imago, public image, visions",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "immagini",
        "translation": "pictures, images, (you) imagine, (you) picture, view",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "immediatamente",
        "translation": "immediately, at once",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "imparare",
        "translation": "(to) learn, learned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "impari",
        "translation": "(you) learn, azygous, unbalanced, unequal, uneven, unfit, unpaired",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "impariamo",
        "translation": "(we) learn",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "impegnata",
        "translation": "busy, involved, involved oneself, applied oneself, apply oneself, bind, book, booked, bound, commit, commit oneself, committed, committed oneself, concentrate, concentrated, devote oneself, devoted, devoted oneself, engage, engaged, hock, hocked, involve oneself, lock up, locked up, pawn, pawned, pledge, pledge oneself, pledged, pledged oneself, reserve, reserved, start, started, take up, taken up, tie up, tied up, tied-up",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "impegnato",
        "translation": "busy, applied oneself, booked, bound, committed, committed oneself, concentrated, devoted, devoted oneself, engaged, hocked, involved, involved oneself, locked up, pawned, pledged, pledged oneself, reserved, started, taken up, tied up, tied-up",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "importante",
        "translation": "important, big, biggest, elegant, formal, great, heavy, major, prominent",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "importanza",
        "translation": "importance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "impossibile",
        "translation": "impossible, outrageous, unbearable, unearthly",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "imprenditore",
        "translation": "entrepreneur, businessman",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "impresa",
        "translation": "endeavor, business (unit), company, enterprise, firm",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "in",
        "translation": "in, to, on, at, by, for, into, to the",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "incidente",
        "translation": "accident, crash, incident",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "includendo",
        "translation": "including, comprising, covering",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incontra",
        "translation": "(he/she/it) meets up, (he/she/it) meets with, (he/she/it) meets (each other), experiences, meet",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incontrare",
        "translation": "meet with, (to) to meet with, (to) to meet (each other), (to) encounter, (to) experience, (to) join up, (to) meet up, (to) to encounter, (to) to run into, experiences, meet (each other), run, run into, runs",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incontri",
        "translation": "(you) meet up, (you) meet with, (you) meet (each other), (you) encounter, (you) run into, meet",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incontriamo",
        "translation": "(we) meet (each other), (we) meet with, (we) meet up, meet",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incontro",
        "translation": "meeting, (I) meet with, (I) meet up, (I) encounter, (I) experience, (I) join up, (I) meet (each other), (I) run into, bout, contest, fight, match, meet, reunion, toward(s), towards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "indica",
        "translation": "(he/she/it) indicates, (you) indicate, call, mark, points, show, states",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicai",
        "translation": "indicated, (I) indicated, (I) showed, (I) pointed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicando",
        "translation": "pointing, indicating, showing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicare",
        "translation": "show, (to) point, (to) to point, (to) indicate, (to) to show, points, states",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicasse",
        "translation": "indicated, (he/she/it/you) indicated, (he/she/it/you) pointed, (he/she/it/you) showed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicassi",
        "translation": "(I/you) indicated, (I/you) showed, (I/you) pointed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicava",
        "translation": "(he/she/it/you) was/were indicating, (he/she/it/you) was/were pointing, (he/she/it/you) was/were telling, (he/she/it/you) indicated, (he/she/it/you) used to indicate, indicated",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicherà",
        "translation": "(he/she/it/you) will indicate, (he/she/it/you) will say, (he/she/it/you) will mark, (he/she/it/you) will show, (he/she/it/you) will tell, show",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicherebbe",
        "translation": "(he/she/it/you) would indicate, (he/she/it/you) would show, (he/she/it/you) would tell, (he/she/it/you) would denote, (he/she/it/you) would imply, (he/she/it/you) would mark, (he/she/it/you) would point, (he/she/it/you) would record, (he/she/it/you) would register, (he/she/it/you) would say, (he/she/it/you) would signal, (he/she/it/you) would signpost, (he/she/it/you) would state, (he/she/it/you) would suggest, indicated, show",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicherei",
        "translation": "(I) would indicate, (I) would point, (I) would point out, (I) would mark, (I) would say, (I) would show, (I) would tell, show",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicherò",
        "translation": "(I) will point, (I) will indicate, (I) will show, (I) will mark, (I) will say, (I) will tell, show",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indichi",
        "translation": "(you) indicate, (you) point, (you) show, (you) denote, (you) imply, (you) mark, (you) record, (you) register, (you) say, (you) signal, (you) signpost, (you) state, (you) suggest, (you) tell, show",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indicò",
        "translation": "indicated, (he/she/it/you) indicated, (he/she/it/you) pointed, (he/she/it/you) showed, show",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indipendente",
        "translation": "independent, freelance, self-employed",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "indipendenti",
        "translation": "independent, freelance, self-employed",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "indipendenza",
        "translation": "independence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "indirizzi",
        "translation": "addresses, (you) direct, (you) orient, (you) turn",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "indirizzo",
        "translation": "address",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "individui",
        "translation": "individuals, (you) identify, (you) spot, (you) detect, (you) locate, (you) pick out, (you) pinpoint, people, peoples",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "individuo",
        "translation": "individual, (I) identify, (I) pick out, people, peoples",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "indossa",
        "translation": "wears, (he/she/it) wears, (she/she/it) is wearing, (you) wear, models, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indossare",
        "translation": "wear, (to) to wear, wearing, (to) buckle on, (to) have on, (to) put on, got, had, models, put, puts, wears",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indossiamo",
        "translation": "(we) wear, (we) buckle on, (we) put on, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "indosso",
        "translation": "(I) wear, (I) have on, (I) put on",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "industria",
        "translation": "industry, (he/she/it) does one’s best, (you) do one’s best",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "industriale",
        "translation": "industrial, manufactoring",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "infantile",
        "translation": "childish, babyish, infant, infantile",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "infanzia",
        "translation": "childhood",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "infatti",
        "translation": "as a matter of fact, indeed, sure enough",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "infermiera",
        "translation": "(hospital) nurse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "infermiere",
        "translation": "nurse, nurses",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "influenza",
        "translation": "(you) influence, flu, (you) influence each other, (he/she/it) affects, (he/she/it) catches influenza, (he/she/it) influences, (he/she/it) influences each other, (you) affect, (you) catch influenza, clout, grippe, influenza, power",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "informazione",
        "translation": "information, info, intelligence",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "informazioni",
        "translation": "information",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "ingegnere",
        "translation": "engineer",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "ingegneri",
        "translation": "engineers",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "inghilterra",
        "translation": "England",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "inglese",
        "translation": "English, British",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "inglesi",
        "translation": "English",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ingrediente",
        "translation": "ingredient, element",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ingresso",
        "translation": "entry, admission, entrance, entries",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inizi",
        "translation": "beginnings, (you) start, (you) begin, (you) strike up",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "iniziare",
        "translation": "start, (to) to start, (to) begin, (to) initiate, (to) kick off, (to) strike up, (to) to break off, (to) to launch, began, break off, enter, launch, opened, started",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inizio",
        "translation": "beginning, (I) start, (I) begin, (I) break off, (I) initiate, (I) kick off, (I) launch, (I) strike up, enter, first",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "insalata",
        "translation": "salad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "insegna",
        "translation": "(he/she/it) teaches, (formal you) teach, emblem",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "insegnante",
        "translation": "teacher, schoolteacher, schoolmaster, schoolmistress",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "insegnanti",
        "translation": "teachers",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "insegnare",
        "translation": "(to) teach",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "insegni",
        "translation": "(you) teach",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "insegno",
        "translation": "(I) teach, (I) am teaching",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "insetti",
        "translation": "insects, bugs, insect",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "insetto",
        "translation": "insect, bug",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "insieme",
        "translation": "together, in conjunction, along with, ensemble, entirety, huddle, mixture, series, set, welter, whole, with",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "insomma",
        "translation": "well, in short, in a word, to sum up",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "intellettuale",
        "translation": "intellectual, highbrow, mental",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "intellettuali",
        "translation": "intellectual, highbrow, mental",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "intelligente",
        "translation": "intelligent, bright, clever, sharp, smart",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "intelligenza",
        "translation": "intelligence, cleverness, intellect, mind",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "intenzione",
        "translation": "intention, aim, intent, purpose",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "interessante",
        "translation": "interesting, fascinating, intriguing",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "interessanti",
        "translation": "interesting, intriguing, juicy",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "interessata",
        "translation": "interested, applicant, person concerned",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "interessato",
        "translation": "interested, self-interested, affected, applicant, asked after, concerned, person concerned",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "interesse",
        "translation": "interest",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "interessi",
        "translation": "(you) affect, (you) interest, (you) concern",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "interiore",
        "translation": "interior, inner, inside",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "interiori",
        "translation": "inner",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "internazionale",
        "translation": "international, Internationale, extraterritorial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "internazionali",
        "translation": "international, Internationale, extraterritorial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "internet",
        "translation": "Internet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "intero",
        "translation": "whole, clear, complete, entire, full, in full, integer, wholly",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "interpretazione",
        "translation": "interpretation, acting, reading",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "interventi",
        "translation": "operation, surgery, intervention",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "intervento",
        "translation": "operation, surgery, intervention",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "intervista",
        "translation": "(you) interview, (he/she/it) interviews",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "intorno",
        "translation": "around, about, neighborhood, neighbourhood, round",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "invece",
        "translation": "instead, instead of, on the contrary, on the other hand, rather, rather than",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "invenzione",
        "translation": "invention, fabrication, idea",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "invenzioni",
        "translation": "invention, idea, fabrication",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "inverno",
        "translation": "winter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inviare",
        "translation": "(to) send, (to) mail, post",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invita",
        "translation": "(he/she/it) invites, (he/she/it) asks, (you) invite, ask",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitare",
        "translation": "(to) invite, (to) to ask, (to) to request, (to) induce, ask, asked, had, request",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inviti",
        "translation": "(you) invite, (you) request, (you) ask, invitations",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitiamo",
        "translation": "(we) invite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invito",
        "translation": "invitation, (I) ask, (I) request, (I) invite",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "io",
        "translation": "I, me",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "isola",
        "translation": "island, (he/she/it) isolates, (you) isolate",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "isole",
        "translation": "island, islands, isle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "istituti",
        "translation": "institute, school, institution",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "istituto",
        "translation": "institute, institution, school",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "istituzione",
        "translation": "institution, authorities, establishment, foundation, institutes, institutions, setting up",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "istituzioni",
        "translation": "institutions, authorities, establishment, foundation, institutes, institution, setting up",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "istruzioni",
        "translation": "instructions, education, schooling",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "italia",
        "translation": "Italy",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "italiana",
        "translation": "Italian",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "italiane",
        "translation": "Italian, Italian-style, Italian women, italians",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "italiani",
        "translation": "Italians, Italian",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "italiano",
        "translation": "Italian",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "l'",
        "translation": "it, her, him, the, you",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "là",
        "translation": "it, her, for, the, you",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "la",
        "translation": "it, her, for, the, you",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "labbra",
        "translation": "lips",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "laboratorio",
        "translation": "laboratory, studio, workshop",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lacrime",
        "translation": "tears, (he/she/it) waters, (you) water",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "laghi",
        "translation": "lakes, pools",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lago",
        "translation": "lake, pool",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lampada",
        "translation": "lamp",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lana",
        "translation": "wool",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lascerà",
        "translation": "(he/she/it/you) will leave (behind), (he/she/it/you) will drop, (he/she/it/you) will part, (he/she/it/you) will give up, (he/she/it/you) will let go of, (he/she/it/you) will move from, (he/she/it/you) will move out of, leave, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lascerai",
        "translation": "(you) will leave (behind), (you) will part, (you) will drop, (you) will give up, (you) will let go of, (you) will move from, (you) will move out of, leave, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lascerebbe",
        "translation": "(he/she/it/you) would leave (behind), (he/she/it/you) would part, (he/she/it/you) would drop, (he/she/it/you) would give up, (he/she/it/you) would let go of, (he/she/it/you) would move from, (he/she/it/you) would move out of, leave, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lascerebbero",
        "translation": "(they) would leave (behind), (they) would drop, (they) would part, (they) would give up, (they) would let go of, (they) would move from, (they) would move out of, leave, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasceresti",
        "translation": "(you) would let go of, (you) would drop, (you) would part, (you) would give up, (you) would move from, (you) would move out of, leave, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lascerò",
        "translation": "(I) will leave (behind), (I) will part, (I) will drop, (I) will give up, (I) will let go of, (I) will move from, (I) will move out of, leave, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasci",
        "translation": "(you) leave (behind), (you) let go of, (you) drop, leave, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lascia",
        "translation": "(you) let, (he/she) lets, (he/she/it) leaves, (he/she/it) lets go of, (he/she/it) moves from, (he/she/it) moves out of, (you) allow, (you) give up, (you) leave (behind), (you) let go of, (you) move from, (you) move out of, allows, leave, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciai",
        "translation": "(I) left (behind), (I) dropped, (I) let go of, allowed, leave, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciamo",
        "translation": "(we) let, (we) break up, (we) leave, (we) let go of, (we) move from, (we) move out of, (we) split up, leave, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciando",
        "translation": "leaving, leaving (behind), dropping, letting go of",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciare",
        "translation": "(to) leave (behind), leaving, (to) to forget, (to) drop, (to) let go of, (to) move from, (to) move out of, (to) part, (to) split up, (to) to break up, (to) to give up, allowed, allows, break up, forget, gave, get, give, give up, given, got, hand, hands, leave, left, let, put, puts, run, runs, throws",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciarvi",
        "translation": "leave you, leave (behind), let go of, break up, drop, forget, give up, move from, move out of, part, split up",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lasciasse",
        "translation": "(he/she/it/you) let go of, (he/she/it/you) dropped, (he/she/it/you) left (behind), leave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciate",
        "translation": "(you) leave (behind), (you) drop, (you) let go of, leave, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciava",
        "translation": "let, (he/she/it/you) was/were letting go of, (he/she/it/you) used to let go of, (he/she/it/you) let go of, left",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciavano",
        "translation": "left, (they) left (behind), (they) were leaving (behind), (they) let go of, (they) used to leave (behind), (they) used to let go of, allowed, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciavo",
        "translation": "(I) was/were leaving (behind), (I) let go of, (I) used to let go of, allowed, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lasciò",
        "translation": "(he/she/it/you) let go of, (he/she/it/you) left (behind), (he/she/it/you) dropped, allowed, leave, left, let",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lati",
        "translation": "aspects, aspect, side",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lato",
        "translation": "side, on(to) one’s side, point of view, broad, sideways, wide",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "latte",
        "translation": "milk",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "laurea",
        "translation": "degree, graduation, university degree",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lava",
        "translation": "(he/she/it) washes, (he/she/it) cleans, (he/she/it) cleans up, (he/she/it) cleanses, (you) clean, (you) clean up, (you) cleanse, (you) wash",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavare",
        "translation": "(to) wash, (to) wash away, (to) clean, (to) clean up, to mop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavo",
        "translation": "(I) wash, (I) clean, (I) brush, (I) clean up, (I) cleanse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavora",
        "translation": "(he/she/it) works, work, works",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorammo",
        "translation": "(we) worked, (we) cultivated, (we) kneaded",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorando",
        "translation": "working, cultivating, doing business, kneading, knitting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorano",
        "translation": "(they) work, (they) knit, (they) knead, (they) cultivate, (they) do business",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorare",
        "translation": "(to) work, (to) to cultivate, (to) knead, (to) do business, (to) knit, cultivate, work",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorasse",
        "translation": "(he/she/it/you) cultivated, (he/she/it/you) kneaded, (he/she/it/you) worked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorate",
        "translation": "(you) work, (you) cultivate, (you) knead, work",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorava",
        "translation": "(he/she/it/you) was/were working, (he/she/it/you) used to work, (he/she/it/you) worked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavoravano",
        "translation": "(they) used to work, (they) used to knit, (they) were working, (they) worked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavoreranno",
        "translation": "(they) will work, (they) will knit, (they) will knead, work",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorerebbe",
        "translation": "(he/she/it/you) would work, (he/she/it/you) would knead, (he/she/it/you) would knit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorerebbero",
        "translation": "(they) would work, (they) would knit, (they) would knead",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavoreremo",
        "translation": "(we) will work, (we) will knit, (we) will knead",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavorerò",
        "translation": "(I) will work, (I) will knead, (I) will knit, work",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavori",
        "translation": "(you) work, jobs, works",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavoro",
        "translation": "job, (I) work, employment, jobs, labor, labour, occupation, position, task, work",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lavorò",
        "translation": "job, (I) work, employment, jobs, labor, labour, occupation, position, task, work",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "le",
        "translation": "the, her, for her, it, them, to her, you",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "legale",
        "translation": "legal, legal consultant, advisor, attorney, judicial, lawful, lawyer, lawyers, prescribed by law, solicitor, statutory",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "legali",
        "translation": "legal, judicial, prescribed by law",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "legga",
        "translation": "read, interpret, see into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "legge",
        "translation": "(he/she/it) reads, (you) read, law",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggendo",
        "translation": "reading, interpreting, seeing into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggerà",
        "translation": "read, (he/she/it/you) will read, (he/she/it/you) will interpret, (he/she/it/you) will see into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggera",
        "translation": "read, (he/she/it/you) will read, (he/she/it/you) will interpret, (he/she/it/you) will see into",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "leggere",
        "translation": "(to) read, light, lightweight",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "leggerebbe",
        "translation": "read, (he/she/it/you) would read, (he/she/it/you) would interpret, (he/she/it/you) would see into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggerei",
        "translation": "read, (I) would read, (I) would interpret, (I) would see into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggeremo",
        "translation": "read, (we) will read, (we) will interpret, (we) will see into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggeresti",
        "translation": "read, (you) would read, (you) would interpret, (you) would see into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggerò",
        "translation": "light(weight), thin, mild, delicate, faint, fickle, filmy, fine, flimsy, flippant, flirtatious, gentle, light, light-minded, lightly, lightweight, minor, moderate, nimble, popular, slight, soft, weak, without thinking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggero",
        "translation": "light(weight), thin, mild, delicate, faint, fickle, filmy, fine, flimsy, flippant, flirtatious, gentle, light, light-minded, lightly, lightweight, minor, moderate, nimble, popular, slight, soft, weak, without thinking",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "leggesse",
        "translation": "(he/she/it/you) interpreted, (he/she/it/you) read, (he/she/it/you) saw into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggessero",
        "translation": "read, (they) read, (they) interpreted, (they) saw into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggessi",
        "translation": "(I/you) read, (I/you) interpreted, (I/you) saw into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggesti",
        "translation": "read, (you) read, (you) interpreted, (you) saw into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggete",
        "translation": "(you pl.) read",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggeva",
        "translation": "read, (he/she/it/you) read, (he/she/it/you) used to read, (he/she/it/you) was/were reading",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggevano",
        "translation": "(they) were reading, (they) were interpreting, (they) were seeing into, (they) interpreted, (they) read, (they) saw into, (they) used to interpret, (they) used to read, (they) used to see into, read",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggevo",
        "translation": "(I) was/were reading, (I) was/were interpreting, (I) interpreted, (I) read, (I) saw into, (I) used to interpret, (I) used to read, (I) used to see into, (I) was/were seeing into, read",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggi",
        "translation": "(you) read, (do you) read",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggiamo",
        "translation": "read, (we) read, (we) see into, (we) are reading",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggiate",
        "translation": "read, interpret, see into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggo",
        "translation": "(I) read",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leggono",
        "translation": "(they) read",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "legno",
        "translation": "wood, (I) tan, (I) thrash",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lei",
        "translation": "she, her, you",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "lenta",
        "translation": "slow, loose",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "lentamente",
        "translation": "slowly, at a slow pace, slow",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "lento",
        "translation": "slow, slow dance, leisurely, lento, lingering, loose, slack, slowly, sluggish, smooch",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "lenzuola",
        "translation": "bedsheets, bed sheets, beddings",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "leone",
        "translation": "lion, the Lion, Leo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lesse",
        "translation": "read, (he/she/it/you) read, (he/she/it/you) interpreted, (he/she/it/you) saw into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lessi",
        "translation": "read, (I) read, (I) saw into, (you) boil",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lettera",
        "translation": "letter, humanities",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "letteratura",
        "translation": "literature",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lettere",
        "translation": "letters, humanities",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "letto",
        "translation": "bed, read",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lettore",
        "translation": "reader, (foreign language) assistant, language teaching assistant, player",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lezione",
        "translation": "lesson, class, lecture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lezioni",
        "translation": "lessons, classes, lectures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lì",
        "translation": "them",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "li",
        "translation": "them",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "libera",
        "translation": "free, (you) free, (he/she/it) frees, (he/she/it) liberates, available, clear",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "liberazione",
        "translation": "liberation, freeing, release",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "liberi",
        "translation": "free, (you) free, (you) free oneself, (you) break free, (you) clear, (you) come out, (you) deliver, (you) disengage, (you) disentangle, (you) escape, (you) free itself, (you) give free rein to, (you) give off, (you) leak, (you) let out, (you) liberate, (you) out, (you) pour out, (you) purge, (you) release, (you) relieve oneself, (you) set [sb., (you) set [somebody] free, (you) something] free, (you) unblock, (you) unclog, (you) vacate, clear",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "libero",
        "translation": "free, (I) free, (I) free itself, (I) break free, (I) clear, (I) come out, (I) deliver, (I) disengage, (I) disentangle, (I) escape, (I) free oneself, (I) give free rein to, (I) give off, (I) leak, (I) let out, (I) liberate, (I) out, (I) pour out, (I) purge, (I) release, (I) relieve oneself, (I) set [sb., (I) set [somebody] free, (I) something] free, (I) unblock, (I) unclog, (I) vacate, available, clear, empty, for hire, free and easy, liberal, libero player, loose, open, opened, sweeper, vacant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "libertà",
        "translation": "freedom, liberty",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "libreria",
        "translation": "bookstore, bookcase, bookshelves, bookshop",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "libri",
        "translation": "books",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "libro",
        "translation": "book",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "liceo",
        "translation": "high school",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "limonata",
        "translation": "lemonade, lemon crush, necking, snogging",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "limone",
        "translation": "lemon (yellow), lemon tree, lemon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "limpida",
        "translation": "clear, bright, limpid",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "limpido",
        "translation": "clear, bright, limpid",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "lingua",
        "translation": "language, tongue, modern languages",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "linguaggio",
        "translation": "language, speech",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lingue",
        "translation": "languages, tongues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "litri",
        "translation": "liters",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "litro",
        "translation": "liter, litre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "livelli",
        "translation": "levels, (you) level off, (you) level out",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "livello",
        "translation": "(I) level, (I) level off, (I) level out, degree",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lo",
        "translation": "it, the, him, that",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "locale",
        "translation": "local (anaesthetic), business, club, haunt, joint, place, premises, restaurant, room, shop, stopping train, store",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "logica",
        "translation": "logician, logic",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "logico",
        "translation": "logical, logician",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "lontana",
        "translation": "far, far away, away, far from, far off, far-off",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "lontano",
        "translation": "far, far from, far away, away, distant, far off, far-off, faraway, from a distance",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "loro",
        "translation": "they, their, them, theirs",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "luce",
        "translation": "light",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "luci",
        "translation": "(head)lights, light, opening, span",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "luglio",
        "translation": "July",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lui",
        "translation": "he, him",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "luna",
        "translation": "moon, month",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lunedì",
        "translation": "Monday",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lunga",
        "translation": "long, long-standing, (for) a long time, along, beanpole, by, far and near, far and wide, in the long run, lengthwise, lengthy, tall person, thin, watery, weak",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "lunghe",
        "translation": "long",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "lungo",
        "translation": "long, along",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "luoghi",
        "translation": "places, in place of, instead of",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "luogo",
        "translation": "place, in place of, instead of",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lupo",
        "translation": "wolf, willow, willowing-machine",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ma",
        "translation": "but, only, though",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "macchina",
        "translation": "car, (he/she/it) plots, automobile",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "macchine",
        "translation": "cars, machines, car, (he/she/it) brews, (he/she/it) hatches up, (he/she/it) plots, (you) brew, (you) hatch up, (you) plot, engine, machine, typewriter",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "madre",
        "translation": "mother",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "magari",
        "translation": "perhaps, I wish, maybe",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "maggio",
        "translation": "May",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maggioranza",
        "translation": "majority",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maglia",
        "translation": "jersey",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maglione",
        "translation": "sweater, jumper, pullover",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maglioni",
        "translation": "sweaters, jumpers, pullovers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "magra",
        "translation": "thin, lean, slender person, slim",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "magre",
        "translation": "thin, thin person, lean, low water, low-fat, meager, meagre, poor, slender, slender person, slim",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mai",
        "translation": "never, ever",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "maiale",
        "translation": "pork, pig, swine",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mal",
        "translation": "ache, badly, poorly, wrongly",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "malata",
        "translation": "sick person, sick",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "malati",
        "translation": "ill, sick, sick people",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "malato",
        "translation": "sick, ill, sick person, ailing, dirty, diseased, injured",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "malattia",
        "translation": "disease, sickness, illness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "male",
        "translation": "not bad, badly, ache, evil, ill, illness, pain, poorly, sickness, worse, wrong, wrongly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "mamma",
        "translation": "mom, mommy, mum",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "manca",
        "translation": "is missing, (you) miss, (he/she/it) misses, (he/she/it) dies, (he/she/it) fails, (he/she/it) omits, (he/she/it) passes away, (he/she/it/you) left, (you) die, (you) fail, (you) omit, (you) pass away, left hand",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mancano",
        "translation": "are missing, are left, are lacking, (they) die, (they) fail, (they) left, (they) miss, (they) omit, (they) pass away, miss",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mancare",
        "translation": "(to) miss, (to) to fail, (to) to die, (to) left, (to) omit, (to) pass away, die, fail, miss, missed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manco",
        "translation": "(I) pass away, I am missed (by someone), I miss (a shot), miss",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manda",
        "translation": "(he/she/it) sends, (he/she/it) sends away, (he/she/it) sends up, (he/she/it) sends back, (he/she/it) sends down, (he/she/it) sends each other, (he/she/it) sends out, (he/she/it) winds on, (you) send, throws",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mandare",
        "translation": "(to) send, (to) to return, (to) to rewind, (to) chase away, (to) clean off, (to) exhale, (to) force down, (to) forward, (to) go ahead with, (to) keep, (to) let in, (to) put out, (to) run, (to) run back, (to) send away, (to) send back, (to) send down, (to) send each other, (to) send out, (to) send up, (to) support, (to) to advance, (to) to drive out, (to) wind on, advance, drive out, got, return, rewind, throws",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangerebbe",
        "translation": "(he/she/it/you) would eat, (he/she/it/you) would eat away, (he/she/it/you) would take, (he/she/it/you) would use up, (he/she/it/you) would win",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangerebbero",
        "translation": "(they) would eat, (they) would eat away, (they) would take, (they) would use up, (they) would win",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangerei",
        "translation": "(I) would eat, (I) would eat away, (I) would win, (I) would take, (I) would use up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangeresti",
        "translation": "(you) would eat, (you) would eat away, (you) would take, (you) would use up, (you) would win",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangi",
        "translation": "(you) eat, (do you) eat, (he/she/it) eats",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangia",
        "translation": "(he/she/it) eats, (formal you) eat, eats",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangiamo",
        "translation": "(we) eat, (we) are eating",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangiando",
        "translation": "eating, eating away, having lunch, going through, having dinner, taking, using up, winning",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangiano",
        "translation": "(they) eat, (they) eat away, (they) are eating, do (they) eat",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangiare",
        "translation": "(to) eat, eating, eat, food",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangiate",
        "translation": "(you) eat",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangio",
        "translation": "(I) eat, (I) am eating, I eat",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mani",
        "translation": "hands",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maniera",
        "translation": "way, manner, custom",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mano",
        "translation": "hand, coat, coats, layer",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "manzo",
        "translation": "beef, bullock, steer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mappa",
        "translation": "map, picture, plan",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mare",
        "translation": "sea, main, ocean, seaside",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marito",
        "translation": "husband, husbands",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marmellata",
        "translation": "jam, jelly, marmalade",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "marrone",
        "translation": "brown",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "martedì",
        "translation": "Tuesday, on Tuesday",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marzo",
        "translation": "March",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "matematica",
        "translation": "math, mathematics, mathematician, maths",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "matita",
        "translation": "pencil",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "matrimonio",
        "translation": "marriage, wedding",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mattina",
        "translation": "morning",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "me",
        "translation": "me",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "meccanici",
        "translation": "mechanics, clockwork, mechanical",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meccanico",
        "translation": "mechanic, mechanical",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meccanismi",
        "translation": "mechanisms, device, mechanism, workings",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meccanismo",
        "translation": "mechanism, device, mechanics, workings",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "medici",
        "translation": "doctors, medical",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "medicina",
        "translation": "medicine, medication, medical school, medical science",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "medicine",
        "translation": "medication, drugs, medicine, medicines",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "medico",
        "translation": "doctor, (I) medicate",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "meditazione",
        "translation": "meditation, meditations, musing, reflection",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "meditazioni",
        "translation": "meditations, meditation, musing",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "meglio",
        "translation": "better, best, good",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "mela",
        "translation": "apple",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mele",
        "translation": "apples, apple",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "memoria",
        "translation": "by heart, memory, cold, memoir, memoirs, mind, recall, recollection, remembrance, storage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "meno",
        "translation": "less, about, certainly, little, minus [math], roughly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "mente",
        "translation": "mind, (you) lie, (he/she/it) lies, (he/she/it) tells lies, (you) tell lies, memory",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "menti",
        "translation": "minds, (you) lie, (you) tell lies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mentire",
        "translation": "(to) lie, lying, (to) to tell lies, lies, tell lies",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mento",
        "translation": "(I) lie, (I) tell lies, chin",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mentono",
        "translation": "(they) lie, (they) tell lies",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mentre",
        "translation": "while, as, at that very moment, during, just then, whereas, whilst",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "menu",
        "translation": "menu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meraviglioso",
        "translation": "marvelous, wonderful",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mercati",
        "translation": "markets, (street) market, cheap, cheaply, fair, inexpensive, inexpensively, low, marketplace",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mercato",
        "translation": "(street) market, fair, marketplace",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mercoledì",
        "translation": "Wednesday, on Wednesday",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mese",
        "translation": "month",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mesi",
        "translation": "months, month, monthly pay",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "messaggi",
        "translation": "messages, addresses, text (message)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "messaggio",
        "translation": "message, text (message), address",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metà",
        "translation": "half, middle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "metalli",
        "translation": "metals, metal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metallo",
        "translation": "metal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metodi",
        "translation": "methods, method, system",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metodo",
        "translation": "method, system, way",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metri",
        "translation": "metro, tube, (metre ) rule, metre, meter, criterion, subway, measure, meters, yardstick",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metro",
        "translation": "meter, subway, (metre ) rule, criterion, measure, metre, metro, tube, underground, yardstick",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metta",
        "translation": "put (in), put in, put on, put up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettano",
        "translation": "put in, put (in), put on, put up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettendo",
        "translation": "putting (in), putting in, putting on, adding, advertising, assuming, causing, comparing, fitting, imposing, inspiring, laying on, placing, playing, posting up, putting forth, putting oneself, putting up, ranking, setting, sinking, supposing, turning, wearing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "metterà",
        "translation": "put, (he/she/it/you) will put in, (he/she/it/you) will put (in), (he/she/it/you) will add, (he/she/it/you) will fit, (he/she/it/you) will lay on, (he/she/it/you) will play, (he/she/it/you) will post up, (he/she/it/you) will put on, (he/she/it/you) will put up, (he/she/it/you) will rank, (he/she/it/you) will set, (he/she/it/you) will sink, (he/she/it/you) will turn, (he/she/it/you) will wear, keep, place",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "metteranno",
        "translation": "(they) are going to put, (they) will put (in), (they) will set, (they) will place",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettere",
        "translation": "(to) put in, place, (to) put (in), (to) put on, (to) put up, (to) to place, (to) to put, (to) to put on, (to) to wear, (to) wear, fits, get, got, keep, kept, put, wear, wears",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "metterebbe",
        "translation": "put, (he/she/it/you) would put in, (he/she/it/you) would put (in), (he/she/it/you) would add, (he/she/it/you) would fit, (he/she/it/you) would lay on, (he/she/it/you) would play, (he/she/it/you) would post up, (he/she/it/you) would put on, (he/she/it/you) would put up, (he/she/it/you) would rank, (he/she/it/you) would set, (he/she/it/you) would sink, (he/she/it/you) would turn, (he/she/it/you) would wear, keep, place",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "metterei",
        "translation": "put, (I) would put in, (I) would put (in), (I) would add, (I) would assume, (I) would cause, (I) would compare, (I) would fit, (I) would impose, (I) would inspire, (I) would lay on, (I) would place, (I) would play, (I) would post up, (I) would put forth, (I) would put on, (I) would put oneself, (I) would put up, (I) would rank, (I) would set, (I) would sink, (I) would suppose, (I) would turn, (I) would wear, keep, place",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "metterla",
        "translation": "put in, put (in), put on, place, put up",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "mettesse",
        "translation": "(he/she/it/you) put (in), (he/she/it/you) put in, (he/she/it/you) put up, (he/she/it/you) put on, put",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettessi",
        "translation": "(I/you) put on, (I/you) put (in), (I/you) put up, (I/you) placed, (I/you) put in, (I/you) set",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettete",
        "translation": "put, (you) put (in), (you) put in, (you) put on, (you) put up, keep, place",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "metteva",
        "translation": "(he/she/it/you) was/were putting in, (he/she/it/you) was/were putting (in), (he/she/it/you) was/were putting oneself, (he/she/it/you) was/were putting forth, (he/she/it/you) was/were putting on, (he/she/it/you) was/were putting up, kept, put",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettevo",
        "translation": "(I) was/were putting on, (I) was/were putting (in), (I) was/were putting oneself, (I) put (in), (I) used to put (in), (I) was/were putting forth, (I) was/were putting in, (I) was/were putting up, kept, put",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "metti",
        "translation": "put, (you) put on, do you put, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "metto",
        "translation": "(I) add, (I) place, (I) put, (I) wear, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mezzanotte",
        "translation": "midnight, 12 o’clock midnight, north",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mezzi",
        "translation": "means, by means of, half, halves, middle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mezzo",
        "translation": "half, middle, in the middle of, ability, among, between, by means of, center, centre, funds, mean, means, means of transport, meant, medium, resources, through, wherewithal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mezzogiorno",
        "translation": "noon, midday, south",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mi",
        "translation": "me, myself, my",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "mia",
        "translation": "my, mine",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "mie",
        "translation": "my, mine",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "miei",
        "translation": "my, mine",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "miglio",
        "translation": "mile, millet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "migliora",
        "translation": "(he/she/it) improves, (he/she/it) improves oneself, (he/she/it) gets better, (he/she/it) betters, (you) better, (you) get better, (you) improve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "migliorare",
        "translation": "(to) improve, (to) improve oneself, (to) to progress, (to) make progress, (to) to better, (to) to clear up, (to) to get better, (to) to mend one’s ways, (to) to pick up, better, clear up, get better, look, made, make, makes, mend one’s ways, pick up, progress",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "migliore",
        "translation": "best, better",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "migliori",
        "translation": "(you) better, (you) get better, (you) mend one’s ways, (you) clear up, (you) improve, (you) improve oneself, (you) make progress, (you) pick up, (you) progress, the best ones",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "milione",
        "translation": "million",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "milioni",
        "translation": "million, millions",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mille",
        "translation": "a thousand, one thousand",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "minuti",
        "translation": "minutes, delicate, detailed, diminutive, fine, minute, moment, petite, petty, slight, small, tick, tiny, trifling, trivial",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "minuto",
        "translation": "minute, delicate, detailed, diminutive, fine, moment, petite, petty, slight, small, tick, tiny, trifling, trivial",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mio",
        "translation": "my, mine",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "mise",
        "translation": "put, (he/she/it/you) put in, (he/she/it/you) put (in), (he/she/it/you) put on, (he/she/it/you) put up, kept",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "misura",
        "translation": "measurement, size, (you) measure, (he/she/it) checks, (he/she/it) gauges, (he/she/it) measures, (he/she/it) meters, (he/she/it) takes, (he/she/it) tries on, (you) check, (you) gauge, (you) meter, (you) take, (you) try on, beat, close, fitting, hairline, moderation, narrow, step",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "misure",
        "translation": "measurements, (you) take, (you) measure, (he/she/it) checks, (he/she/it) gauges, (he/she/it) measures, (he/she/it) meters, (he/she/it) takes, (he/she/it) tries on, (you) check, (you) gauge, (you) meter, (you) try on, beat, close, fitting, hairline, measurement, moderation, narrow, size, step",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mobile",
        "translation": "piece of furniture, inconstant, mobile, movable, moving",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mobili",
        "translation": "furniture",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moda",
        "translation": "fashion (industry), fashions, trend, vogue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "moderna",
        "translation": "modern, up-to-date",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "moderni",
        "translation": "modern, up-to-date",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "moderno",
        "translation": "modern, contermporary, up-to-date",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "modo",
        "translation": "way, in a way, in such a way, chance, idiom, manner, method, mode, mood, so that",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mogli",
        "translation": "wives",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "moglie",
        "translation": "wife",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "molta",
        "translation": "a lot of, very, many, at the utmost, much",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "molte",
        "translation": "many, a lot of, very",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "molti",
        "translation": "many, a lot of",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "molto",
        "translation": "very, a lot of, a lot, far, much, really",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "momento",
        "translation": "moment, time",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mondiale",
        "translation": "world, global, worldwide",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mondo",
        "translation": "world, (I) peel, (I) hull, (I) gill, (I) purify oneself, (I) shell, hulled barley",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moneta",
        "translation": "coin, (he/she/it/you) monetize, change, money",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "monete",
        "translation": "coins, (he/she/it/you) monetize, coin",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "montagna",
        "translation": "mountain, mountains, pile",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "montagne",
        "translation": "mountains, mount, mountain",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "morendo",
        "translation": "dying (away), fading (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morì",
        "translation": "died, (he/she/it/you) died (away), (he/she/it/you) faded (out), die",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "moriamo",
        "translation": "(we) die (away), (we) fade (out), die",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "moriate",
        "translation": "die (away), die, fade (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morirà",
        "translation": "die (away), fade (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morirai",
        "translation": "die (away), fade (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morire",
        "translation": "(to) die (away), (to) to fade (out), die, died, fade (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morirebbe",
        "translation": "die (away), fade (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morirei",
        "translation": "die (away), fade (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morirono",
        "translation": "died, (they) died (away), (they) faded (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morisse",
        "translation": "(he/she/it/you) died (away), (he/she/it/you) faded (out), died",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morissi",
        "translation": "(I/you) faded (out), (I/you) died (away)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "moriva",
        "translation": "(he/she/it/you) was/were dying (away), (he/she/it/you) was/were fading (out), (he/she/it/you) died (away), (he/she/it/you) faded (out), (he/she/it/you) used to die (away), (he/she/it/you) used to fade (out), died",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morivano",
        "translation": "(they) used to die (away), (they) were dying (away), (they) died (away), died",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "morta",
        "translation": "dead",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "morte",
        "translation": "death, dead",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "morti",
        "translation": "dead, died (away), deaths, faded (out)",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mosca",
        "translation": "fly, Moscow",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "moschea",
        "translation": "mosque",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mostra",
        "translation": "(he/she/it) shows, (he/she/it) shows oneself, (he/she/it) shows oneself to be, (he/she/it) exhibits, (he/she/it) points to, (he/she/it) pretends, (you) exhibit, (you) point to, (you) pretend, (you) show, (you) show oneself, (you) show oneself to be, display, exhibition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "motivi",
        "translation": "reasons, motives, (you) account for oneself, (you) lead to, (you) motivate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "motivo",
        "translation": "reason, (I) motivate, motive, (I) motivate oneself, points, purpose",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "motocicletta",
        "translation": "motorcycle, motorbike",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "motore",
        "translation": "engine, motor, driving, driving force",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "movimenti",
        "translation": "movements, (you) liven up, motions",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mucca",
        "translation": "cow",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mucche",
        "translation": "cows",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "muoia",
        "translation": "die (away), I die, die, fade (out)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "muoiono",
        "translation": "(they) die (away), (they) fade (out), die",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "muore",
        "translation": "(he/she/it) dies (away), (you) die (away), (he/she/it) fades (out), (you) fade (out), die",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "muri",
        "translation": "walls, (you) wall up, (you) brick up",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muro",
        "translation": "wall, (I) wall up, (I) block, (I) brick up, (I) immure oneself, (I) shut oneself up, rampart, walls",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muscoli",
        "translation": "muscles, lean meat, muscle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muscolo",
        "translation": "muscle, brawn, lean meat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "museo",
        "translation": "museum",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "musica",
        "translation": "music",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "musicista",
        "translation": "musician",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "musicisti",
        "translation": "musicians",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nascita",
        "translation": "birth, first appearance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "naso",
        "translation": "nose",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "natura",
        "translation": "nature, character, kind",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "naturale",
        "translation": "natural, in brine, legal, natives",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "naturali",
        "translation": "natural",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "naturalmente",
        "translation": "naturally, of course, course, sure enough",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nave",
        "translation": "ship, ships",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "navi",
        "translation": "ships, ship",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nazionale",
        "translation": "national, national championship, national team, domestic, international (player), local, states",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "nazionali",
        "translation": "national, national team, domestic",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "nazione",
        "translation": "nation, country",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nazioni",
        "translation": "countries, nations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "né",
        "translation": "nor, either... or, not",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "ne",
        "translation": "nor, either... or, not",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "neanch'",
        "translation": "either, even, neither, nor",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "neanche",
        "translation": "even, neither, nor, either, not even, so",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nebbia",
        "translation": "fog, haze, mist",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "necessari",
        "translation": "necessary, essentials, what is needed",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "necessariamente",
        "translation": "necessarily, of necessity",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "necessario",
        "translation": "necessary, (what is) necessary, what is needed, essentials",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "necessità",
        "translation": "necessity, need, needs, poverty",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "negativa",
        "translation": "negative",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "negative",
        "translation": "negative, adverse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "negativo",
        "translation": "negative, adverse",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "negli",
        "translation": "in the, to, bies, ins, inside, on",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "negoziazione",
        "translation": "negotiation, deal, negotiations, transaction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "negoziazioni",
        "translation": "negotiations, deal, negotiation, transaction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "negozio",
        "translation": "store, shop",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nei",
        "translation": "in the, to, on, bies, in, ins, inside, on the",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "nel",
        "translation": "in, in the, on the whole, at, band, by, by and large, complex, compound, difficult, elaborate, entirety, group, hang-up, inside, into the, many-sided, messy, on, on the, subtle, sum, to, whole",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "nell'",
        "translation": "in, in the, on the whole, band, by, by and large, complex, compound, difficult, elaborate, entirety, group, hang-up, inside, into the, many-sided, messy, on, subtle, sum, to, whole",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "nella",
        "translation": "in, in the, into the, by, inside, inside the, to, whole",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "nelle",
        "translation": "in the, into the, to, inside, into, on",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "nello",
        "translation": "in, in the, by, inside, on, to",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "nemica",
        "translation": "enemy, enemies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nemici",
        "translation": "enemies, adverse, hostile",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nemico",
        "translation": "enemy, adverse, hostile",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nemmeno",
        "translation": "even, not even, either, either...or, neither, neither...nor, nor",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "neppure",
        "translation": "not even, neither, either, nor",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nera",
        "translation": "black",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nere",
        "translation": "black",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "neri",
        "translation": "black",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nero",
        "translation": "black",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nervosa",
        "translation": "nervous, edgy, tense",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nervose",
        "translation": "nervous, edgy, tense",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nervosi",
        "translation": "nervous, edgy, tense",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nervoso",
        "translation": "nervous, edgy, tense",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nessun",
        "translation": "no, no one, any, nobody",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "nessuna",
        "translation": "any, no, no one, anybody, anyone, either, neither, no-one, nobody, none, not a single",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "nessuno",
        "translation": "nobody, anybody, none, any, anyone, either, neither, no, no-one",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "neve",
        "translation": "snow",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nevicare",
        "translation": "(to) snow",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "niente",
        "translation": "anything, nothing, no, any, nothingness",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nipote",
        "translation": "nephew, granddaughter, niece, grandson",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "no",
        "translation": "no, not",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "noi",
        "translation": "we, us",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "noiosa",
        "translation": "boring",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "noioso",
        "translation": "boring, annoying, dries, dry, troublesome",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "noleggiare",
        "translation": "(to) rent, (to) rent out, book, hire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nome",
        "translation": "name, last name, surname, noun",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nomi",
        "translation": "names, first names, nouns",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "non",
        "translation": "not, do not, does not, don't, no, won't",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nona",
        "translation": "ninth, none, nones",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nonna",
        "translation": "grandmother, grandma",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nonni",
        "translation": "grandparents, grandfathers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nonno",
        "translation": "grandfather, grandpa",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nono",
        "translation": "ninth",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nonostante",
        "translation": "despite, in spite of, all, notwithstanding, though",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "nord",
        "translation": "north, the north of Italy, northern, northern Italy",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "normale",
        "translation": "normal, circular (letter), clear, natural, straight, usual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "normalmente",
        "translation": "normally, usually",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nostra",
        "translation": "our, ours",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "nostre",
        "translation": "our, ours",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "nostri",
        "translation": "our, ours",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "nostro",
        "translation": "our, ours",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "nota",
        "translation": "note, (you) note, (you) make a note of, (you) note down",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "note",
        "translation": "(he/she/it) notes, (he/she/it) notes down, (he/she/it) notices",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "notizia",
        "translation": "news, information, note, piece of news, words",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "notizie",
        "translation": "news, information, note, words",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "notte",
        "translation": "night, evenings, nights",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "notti",
        "translation": "nights, night, dark",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "novanta",
        "translation": "ninety",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "nove",
        "translation": "nine",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "novembre",
        "translation": "November",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nulla",
        "translation": "anything, nothing, nothingness",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "numeri",
        "translation": "(you) number, numbers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "numero",
        "translation": "number, shoe size",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "numerosa",
        "translation": "large",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "numerose",
        "translation": "numerous, large",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nuota",
        "translation": "(he/she/it) swims, (you) swim",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nuotando",
        "translation": "swimming",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nuotano",
        "translation": "(they) swim",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nuotare",
        "translation": "(to) swim, swimming",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nuotiamo",
        "translation": "(we) swim, swim",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nuoto",
        "translation": "(I) swim, swim, swimming",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nuova",
        "translation": "new",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nuove",
        "translation": "new, afresh, again, anew, further",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nuovi",
        "translation": "new, afresh, again, anew, further",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nuovo",
        "translation": "new, fresh",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nuvola",
        "translation": "cloud, clouds, haze",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nuvole",
        "translation": "clouds, cloud, cloudy, haze",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nuvoloso",
        "translation": "cloudy, overcast",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "o",
        "translation": "or",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "obiettivi",
        "translation": "objectives, goals, aim, detached, goal, impartial, lens, object-glass, objective, target, unbias(s)ed, unprejudiced",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "obiettivo",
        "translation": "objective, goal, purpose, aim, detached, impartial, lens, object-glass, objectives, target, unbias(s)ed, unprejudiced",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "occasione",
        "translation": "occasion, opportunity, bargain, broke, broken, cause, chance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "occhi",
        "translation": "eyes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "occhiali",
        "translation": "glasses, specs, spectacles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oceano",
        "translation": "ocean, main",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "odiare",
        "translation": "hate, (to) hate, detest, loathe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odio",
        "translation": "(I) hate, hatred, (I) detest",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "odore",
        "translation": "smell, herbs, odor, odour, scent",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "offerta",
        "translation": "offer, bid, offered, offers, proposal",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "offra",
        "translation": "offer, give, proffer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offre",
        "translation": "(he/she/it) offers, (he/she/it) offers itself, (he/she/it) gives, (he/she/it) bids, (he/she/it) presents, (he/she/it) proffers, (he/she/it) provides, (he/she/it) tenders, (you) bid, (you) give, (you) offer, (you) offer itself, (you) present, (you) proffer, (you) provide, (you) tender, give, offers, present, serve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrendo",
        "translation": "offering, offering itself, giving",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offri",
        "translation": "offer, give, proffer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrì",
        "translation": "offer, give, proffer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offriamo",
        "translation": "(we) offer, (we) give, (we) proffer, give, present",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrii",
        "translation": "offer, give, proffer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrirai",
        "translation": "(you) will offer, (you) will proffer, (you) will bid, (you) will give, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offriranno",
        "translation": "(they) will offer, (they) will bid, (they) will give, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrire",
        "translation": "(to) offer, (to) to offer, (to) offer itself, (to) bid, (to) present, (to) proffer, (to) provide, (to) to give, (to) to provide, (to) to tender, gave, give, given, hand, hands, offered, offers, put, puts, serve, tender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrirebbe",
        "translation": "(he/she/it/you) would offer, (he/she/it/you) would bid, (he/she/it/you) would give, gave, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrirebbero",
        "translation": "(they) would offer, (they) would bid, (they) would give, gave, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrirei",
        "translation": "(I) would offer, (I) would bid, (I) would give, gave, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrirete",
        "translation": "(you) will offer, (you) will bid, (you) will give, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrirò",
        "translation": "(I) will offer, (I) will bid, (I) will give, (I) will buy (someone), (I) will proffer, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrisse",
        "translation": "(he/she/it/you) proffered, (he/she/it/you) offered, (he/she/it/you) gave, would offer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrissi",
        "translation": "(I/you) gave, (I/you) proffered, (I/you) offered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offriva",
        "translation": "(he/she/it/you) was/were offering, (he/she/it/you) was/were offering itself, (he/she/it/you) was/were presenting, (he/she/it/you) bade/bid, (he/she/it/you) bid, (he/she/it/you) gave, (he/she/it/you) offered, (he/she/it/you) offered itself, (he/she/it/you) presented, (he/she/it/you) proffered, (he/she/it/you) provided, (he/she/it/you) tendered, (he/she/it/you) used to bid, (he/she/it/you) used to give, (he/she/it/you) used to offer, (he/she/it/you) used to offer itself, (he/she/it/you) used to present, (he/she/it/you) used to proffer, (he/she/it/you) used to provide, (he/she/it/you) used to tender, (he/she/it/you) was/were bidding, (he/she/it/you) was/were giving, (he/she/it/you) was/were proffering, (he/she/it/you) was/were providing, (he/she/it/you) was/were tendering, gave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrivano",
        "translation": "(they) used to offer, (they) offered, (they) were offering, gave, offered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offro",
        "translation": "(I) offer, (I) give, (I) proffer, give",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oggetti",
        "translation": "objects, items, things",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oggetto",
        "translation": "object, item, jobs, thing",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oggi",
        "translation": "today",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ogni",
        "translation": "every, each",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "ognuna",
        "translation": "each, each one, everybody, everyone",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "ognuno",
        "translation": "each, everybody, everyone, each one",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "olio",
        "translation": "oil, (I) oil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oltre",
        "translation": "over, beyond, besides, in addition to, on top of",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "oltretutto",
        "translation": "besides, moreover, on top of that",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ombra",
        "translation": "shadow",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ombrelli",
        "translation": "umbrellas, gamp, shelter, umbrella",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ombrello",
        "translation": "umbrella, gamp, shelter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "onore",
        "translation": "honor, credit, honour",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "operai",
        "translation": "workers, of the workers, (I) worked, (I) acted, (I) came about, (I) carried out, (I) had an operation, (I) had surgery, (I) made, (I) occurr (past), (I) operated, (I) took place, (I) underwent surgery, blue-collar, hand, labor, laborer, labour, labourer, slave, trade, working class",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "opinione",
        "translation": "opinion, idea, mind, opinions, points, thought, thoughts, view, views",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "opinioni",
        "translation": "opinions, idea, opinion, views",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "opportunità",
        "translation": "opportunity, advisability, broke, broken, expediency, opportuneness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oppure",
        "translation": "or, or rather, or else, otherwise",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "opzione",
        "translation": "option",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "opzioni",
        "translation": "option",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ora",
        "translation": "time, hour, now, times",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ore",
        "translation": "hours",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "orecchi",
        "translation": "ears, (you) eavesdrop, (you) have a nodding acquaintance of",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "orecchio",
        "translation": "ear, (I) eavesdrop, (I) have a nodding acquaintance of",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "organi",
        "translation": "organs, (pipe) organ, mouthpiece",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "organizzazione",
        "translation": "organization, arrangement, organizing, ring",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "organo",
        "translation": "(pipe) organ, mouthpiece, organism, organs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "originale",
        "translation": "original, authentic, different, genuine",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "originali",
        "translation": "original, authentic, genuine",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "origine",
        "translation": "origin, root",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "orologi",
        "translation": "watches, clocks",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "orologio",
        "translation": "(wall) clock, (wrist) watch",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "orso",
        "translation": "bear",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ospedale",
        "translation": "hospital",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ospite",
        "translation": "(house)guest, guests, tourists",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "ospiti",
        "translation": "guests, (you) take in, (you) play host to, (you) accommodate, (you) host, (you) house, (you) lodge, (you) put [somebody] up",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "osso",
        "translation": "bone, pit, stone",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ottanta",
        "translation": "eighty",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "ottavo",
        "translation": "eighth",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "otto",
        "translation": "eight",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "ottobre",
        "translation": "October",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ovest",
        "translation": "west, western",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ovunque",
        "translation": "everywhere, anywhere, wherever",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ovviamente",
        "translation": "obviously, evidently, plainly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "pace",
        "translation": "peace",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "padre",
        "translation": "father",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paesaggio",
        "translation": "landscape (painting), scenery, view",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paese",
        "translation": "country, town, village",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paesi",
        "translation": "countries, towns",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paga",
        "translation": "(he/she/it) pays, (he/she/it) pays for, (he/she/it) pays off, (he/she/it) settles, (he/she/it) settles up with, (he/she/it) treats oneself to, (you) pay, (you) pay for, (you) pay off, (you) settle, (you) settle up with, (you) treat oneself to, pays, salary, wage",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagamenti",
        "translation": "payments, payment",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pagamento",
        "translation": "payment, payments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pagando",
        "translation": "paying, paying for, paying off",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagano",
        "translation": "(they) pay, (they) pay for, (they) pay off, (they) settle, (they) settle up with, (they) treat oneself to, heathen, pagan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagare",
        "translation": "(to) pay, (to) pay for, (to) pay off, (to) to settle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagella",
        "translation": "report card, (school) report",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pagelle",
        "translation": "report cards",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "paghiamo",
        "translation": "(we) pay, (we) pay for, (we) pay off, (we) settle, (we) settle up with, (we) treat oneself to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagina",
        "translation": "page, face",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pagine",
        "translation": "pages, face, page",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pagliacci",
        "translation": "clowns, buffoon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pagliaccio",
        "translation": "clown, buffoon, clowns",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paio",
        "translation": "pair, couple, (I) look, (I) appear, (I) look like, (I) seem, (I) sound, (I) think",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "palazzi",
        "translation": "palaces, buildings",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "palazzo",
        "translation": "palace, building, block of flats, apartment block, apartment house, palaces",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "palchi",
        "translation": "stages, platforms, stand",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "palco",
        "translation": "stage, platform, stand",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "palcoscenico",
        "translation": "stage, boards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "palestra",
        "translation": "gym, gymnasium",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "palla",
        "translation": "ball, pain in the ass, bummer, drag, pain in the arse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pallacanestro",
        "translation": "basketball",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pane",
        "translation": "bread, cake, loaf, pat, thread",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "panini",
        "translation": "sandwiches, roll, sandwich",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "panino",
        "translation": "sandwich, sandwiches",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pantaloni",
        "translation": "pants, trousers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "papà",
        "translation": "dad, daddy",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paragrafi",
        "translation": "(you) paragraph",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paragrafo",
        "translation": "paragraph, section, section mark",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parcheggi",
        "translation": "(you) park, car park, parking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parcheggiamo",
        "translation": "(we) park",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parcheggiano",
        "translation": "(they) park",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parco",
        "translation": "park, economical, fleet, sparing",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parecchi",
        "translation": "several, many, quite a lot of, a good deal, a good few, plenty of, quite a few, quite a lot",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "parecchia",
        "translation": "many, quite a lot of, a good deal of, plenty of, quite a lot",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "parecchie",
        "translation": "many, quite, quite a few, a good deal, a good deal of, a good few, quite a lot, quite a lot of",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "parecchio",
        "translation": "quite, quite a lot, quite a lot of, a good deal, a good deal of, a good few, many, plenty of, quite a few, very",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "parete",
        "translation": "wall, climbing wall, walls",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pareti",
        "translation": "walls, (you) appear, (you) seem",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "parla",
        "translation": "talk, is speaking, is talking, speak, speaks, talks",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlai",
        "translation": "(I) spoke, (I) told, (I) addressed, (I) mentioned, (I) talked, talk, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlamenti",
        "translation": "parliaments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parlamento",
        "translation": "Parliament",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parlammo",
        "translation": "(we) spoke, (we) told, (we) talked, talk, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlando",
        "translation": "speaking, talking, addressing, mentioning, telling",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlano",
        "translation": "(they) speak, (they) talk, (they) tell, (they) address, (they) mention, talk",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlare",
        "translation": "(to) speak, (to) to speak, (to) to talk, (to) address, (to) talk, (to) tell, (to) to mention, mention, speak, speech, talk, talked, way of speaking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlasse",
        "translation": "(he/she/it/you) spoke, (he/she/it/you) talked, (he/she/it/you) told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlassi",
        "translation": "(I/you) spoke, (I/you) told, (I/you) talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlate",
        "translation": "(you) speak, (you) talk, (you) address, (you) mention, (you) tell, talk",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlava",
        "translation": "(he/she/it/you) was/were talking, (he/she/it) used to talk, (he/she/it/you) used to speak, (he/she/it/you) spoke, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlavano",
        "translation": "(they) were speaking, (they) spoke, (they) used to speak, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlerà",
        "translation": "(he/she/it/you) will talk, (he/she/it/you) will tell, (he/she/it/you) will speak, talk, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlerebbe",
        "translation": "talk, (he/she/it/you) would talk, (he/she/it/you) would speak, (he/she/it/you) would tell, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlerei",
        "translation": "(I) would speak, (I) would talk, (I) would tell, talk, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlerò",
        "translation": "talk, (I) will speak, (I) will talk, (I) will tell, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parli",
        "translation": "(you) speak, (you) talk, do you speak, speak, talk",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parliamo",
        "translation": "(we) speak, (we) talk",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlò",
        "translation": "(he/she/it/you) spoke, (he/she/it/you) talked, (he/she/it/you) told, talk, talked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parola",
        "translation": "word, said, says, speech, words",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "parole",
        "translation": "words, word",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "parte",
        "translation": "side, goes, leaves, part, role, share",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "partenza",
        "translation": "departure, leaving, start",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "parti",
        "translation": "parts, you leave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partiamo",
        "translation": "(let us) depart, (let us) be off, (let us) go, (let us) leave, we leave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "particolare",
        "translation": "particular, in particular, special, keen, peculiar",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "particolari",
        "translation": "peculiar, details, particular",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "particolarmente",
        "translation": "particularly, especially, notably",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "partirà",
        "translation": "depart, get off, leave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partirai",
        "translation": "depart, get off, leave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partiremo",
        "translation": "leave, depart, get off",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partirò",
        "translation": "leave, (I) am going to leave, depart, get off",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partita",
        "translation": "match, game, batch, consignment, lot",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "partiti",
        "translation": "parties, left",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "partito",
        "translation": "party, crazy, gone, lost, match, option, slashed, smashed, smitten, solution",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "passa",
        "translation": "",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passai",
        "translation": "(I) spent, (I) went by, (I) went past, called, came, recognized",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passammo",
        "translation": "(we) spent, (we) went by, (we) went past, called, came, passed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passando",
        "translation": "passing, passing off, passing on, being over, being promoted, blowing itself out, blowing over, carrying, changing, checking, coming, connecting, crossing, drawing, drawing on, dropping in, dropping round, dying, dying out, flowing, forgetting, forgiving, getting across, getting over, getting through, going (by), going across, going by, going for, going on, going past, going through, handing, impersonating, living out, mashing (up), moving, moving on, passing between, passing down, paying, popping in, posing, pureeing, putting on, putting through, rubbing, running, running through, showing, spending, spending itself, subsiding, wearing off, whizzing up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passano",
        "translation": "(they) pass, (they) pass off, (they) pass between, (they) are over, (they) are promoted, (they) blow itself out, (they) blow over, (they) carry, (they) change, (they) check, (they) connect, (they) cross, (they) die, (they) die out, (they) draw, (they) draw on, (they) drop in, (they) drop round, (they) flow, (they) forget, (they) forgive, (they) get across, (they) get over, (they) get through, (they) go (by), (they) go across, (they) go by, (they) go for, (they) go on, (they) go past, (they) go through, (they) hand, (they) impersonate, (they) live out, (they) mash (up), (they) move, (they) move on, (they) pass down, (they) pass on, (they) pay, (they) pop in, (they) pose, (they) puree, (they) put on, (they) put through, (they) rub, (they) run, (they) run through, (they) show, (they) spend, (they) spend itself, (they) subside, (they) wear off, (they) whizz up, call, come, spend",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passaporto",
        "translation": "passport",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "passare",
        "translation": "spend, (to) spend, go, (to) be over, (to) be promoted, (to) blow itself out, (to) change, (to) check, (to) connect, (to) die, (to) draw on, (to) drop in, (to) flow, (to) forget, (to) forgive, (to) get through, (to) go by, (to) go for, (to) go on, (to) go past, (to) go through, (to) hand, (to) live out, (to) mash (up), (to) move, (to) pass, (to) pass between, (to) pass down, (to) pass off, (to) pass on, (to) pay, (to) pose, (to) rub, (to) run, (to) run through, (to) show, (to) to blow over, (to) to carry, (to) to cross, (to) to die out, (to) to draw, (to) to drop round, (to) to get across, (to) to get over, (to) to get through, (to) to go (by), (to) to go across, (to) to impersonate, (to) to move, (to) to move on, (to) to pass, (to) to pass on, (to) to pop in, (to) to puree, (to) to put on, (to) to put through, (to) to spend itself, (to) to subside, (to) wear off, (to) whizz up, blow over, call, called, came, carry, come, cross, die out, draw, drop round, get, get across, get over, go (by), go across, gone, got, impersonate, live, move on, passage, passed, passing, pop in, puree, put, put on, put through, recognized, spend itself, subside, wears, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passarono",
        "translation": "(they) passed, (they) passed down, (they) passed on, (they) passed off, called, came, come, passed, recognized",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passarti",
        "translation": "pass, pass you, pass off, be over, be promoted, blow itself out, blow over, carry, change, check, connect, cross, die, die out, draw, draw on, drop in, drop round, flow, forget, forgive, get across, get over, get through, go (by), go across, go by, go for, go on, go past, go through, hand, impersonate, live out, mash (up), move, move on, pass between, pass down, pass on, pay, pop in, pose, puree, put on, put through, rub, run, run through, show, spend, spend itself, subside, wear off, whizz up",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "passasse",
        "translation": "(he/she/it/you) put through, (he/she/it/you) ran through, (he/she/it/you) went through, (he/she/it/you) got through, (he/she/it/you) passed, (he/she/it/you) went across, (he/she/it/you) went by, (he/she/it/you) went past",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passassero",
        "translation": "(they) went by, (they) went past, (they) went across",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passava",
        "translation": "passing, (he/she/it/you) was/were passing, (he/she/it/you) was/were passing between, (he/she/it/you) was/were moving, (he/she/it/you) was/were moving on, (he/she/it/you) was/were passing down, (he/she/it/you) was/were passing off, (he/she/it/you) was/were passing on, (he/she/it/you) went by, called, came, passed by, used to spend",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passeggeri",
        "translation": "passengers, fleeting, momentary, passenger, short-lived, traveler, traveller",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "passeggiata",
        "translation": "walk, stroll, walks",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "passerai",
        "translation": "spend, (you) will spend, (you) will pass, (you) will be over, (you) will blow over, (you) will die, (you) will die out, (you) will draw, (you) will draw on, (you) will drop in, (you) will flow, (you) will get over, (you) will go (by), (you) will go by, (you) will go for, (you) will go on, (you) will go past, (you) will hand, (you) will live out, (you) will mash (up), (you) will move, (you) will move on, (you) will pass down, (you) will pass off, (you) will pass on, (you) will pay, (you) will pop in, (you) will pose, (you) will put on, (you) will rub, (you) will run, (you) will show, (you) will wear off, call, called",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passerebbe",
        "translation": "(he/she/it/you) would go through, (he/she/it/you) would get through, (he/she/it/you) would run through, (he/she/it/you) would be over, (he/she/it/you) would blow over, (he/she/it/you) would die, (he/she/it/you) would die out, (he/she/it/you) would draw, (he/she/it/you) would draw on, (he/she/it/you) would drop in, (he/she/it/you) would flow, (he/she/it/you) would get over, (he/she/it/you) would go (by), (he/she/it/you) would go by, (he/she/it/you) would go for, (he/she/it/you) would go on, (he/she/it/you) would go past, (he/she/it/you) would hand, (he/she/it/you) would live out, (he/she/it/you) would mash (up), (he/she/it/you) would move, (he/she/it/you) would move on, (he/she/it/you) would pass, (he/she/it/you) would pass down, (he/she/it/you) would pass off, (he/she/it/you) would pass on, (he/she/it/you) would pay, (he/she/it/you) would pop in, (he/she/it/you) would pose, (he/she/it/you) would put on, (he/she/it/you) would put through, (he/she/it/you) would rub, (he/she/it/you) would run, (he/she/it/you) would show, (he/she/it/you) would wear off, (he/she/it/you) would whizz up, call, called, recognized",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passeremmo",
        "translation": "spend, (we) would spend, (we) would draw, (we) would be over, (we) would blow over, (we) would die, (we) would die out, (we) would draw on, (we) would drop in, (we) would flow, (we) would get over, (we) would go (by), (we) would go by, (we) would go for, (we) would go on, (we) would go past, (we) would hand, (we) would live out, (we) would mash (up), (we) would move, (we) would move on, (we) would pass, (we) would pass down, (we) would pass off, (we) would pass on, (we) would pay, (we) would pop in, (we) would pose, (we) would put on, (we) would rub, (we) would run, (we) would show, (we) would wear off, (we) would whizz up, recognized",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passeresti",
        "translation": "(you) would pass, (you) would pass off, (you) would pass on, (you) would be over, (you) would blow over, (you) would die, (you) would die out, (you) would draw, (you) would draw on, (you) would drop in, (you) would flow, (you) would get over, (you) would go (by), (you) would go by, (you) would go for, (you) would go on, (you) would go past, (you) would hand, (you) would live out, (you) would mash (up), (you) would move, (you) would move on, (you) would pass down, (you) would pay, (you) would pop in, (you) would pose, (you) would put on, (you) would rub, (you) would run, (you) would show, (you) would wear off, (you) would whizz up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passi",
        "translation": "(you) pass, steps, (you) pass off, (you) are over, (you) are passing, (you) are promoted, (you) blow itself out, (you) blow over, (you) carry, (you) change, (you) check, (you) connect, (you) cross, (you) die, (you) die out, (you) draw, (you) draw on, (you) drop in, (you) drop round, (you) flow, (you) forget, (you) forgive, (you) get across, (you) get over, (you) get through, (you) go (by), (you) go across, (you) go by, (you) go for, (you) go on, (you) go past, (you) go through, (you) hand, (you) impersonate, (you) live out, (you) mash (up), (you) move, (you) move on, (you) pass between, (you) pass down, (you) pass on, (you) pay, (you) pop in, (you) pose, (you) puree, (you) put on, (you) put through, (you) rub, (you) run, (you) run through, (you) show, (you) spend, (you) spend itself, (you) subside, (you) wear off, (you) whizz up, be over, be promoted, call, spend",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "passiamo",
        "translation": "(we) pass, (we) spend, (we) are over, (we) are promoted, (we) blow over, (we) carry, (we) change, (we) check, (we) connect, (we) cross, (we) die, (we) die out, (we) draw, (we) draw on, (we) drop in, (we) drop round, (we) flow, (we) forget, (we) forgive, (we) get across, (we) get over, (we) get through, (we) go (by), (we) go across, (we) go by, (we) go for, (we) go on, (we) go past, (we) go through, (we) hand, (we) impersonate, (we) live out, (we) mash (up), (we) move, (we) move on, (we) pass between, (we) pass down, (we) pass off, (we) pass on, (we) pay, (we) pop in, (we) pose, (we) puree, (we) put through, (we) rub, (we) run, (we) run through, (we) show, (we) spend itself, (we) subside, (we) wear off, (we) whizz up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passiamola",
        "translation": "pass, let's pass it, let's pass her, be over, be promoted, blow itself out, blow over, carry, change, check, connect, cross, die, die out, draw, draw on, drop in, drop round, flow, forget, forgive, get across, get over, get through, go (by), go across, go by, go for, go on, go past, go through, hand, impersonate, live out, mash (up), move, move on, pass between, pass down, pass off, pass on, pay, pop in, pose, puree, put on, put through, rub, run, run through, show, spend, spend itself, subside, wear off, whizz up",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "passino",
        "translation": "pass, pass on, pass off",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passione",
        "translation": "passion, intense feeling, keenness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "passo",
        "translation": "step, (I) pass, (I) spend, (I) am promoted, (I) blow itself out, (I) blow over, (I) carry, (I) connect, (I) cross, (I) draw on, (I) drop in, (I) drop round, (I) forget, (I) get across, (I) get over, (I) get through, (I) go across, (I) go by, (I) go on, (I) go past, (I) go through, (I) hand, (I) mash (up), (I) move, (I) move on, (I) pass between, (I) pass down, (I) pass off, (I) pass on, (I) pop in, (I) pose, (I) puree, (I) put through, (I) rub, (I) run, (I) run through, (I) show, (I) spend itself, (I) stop by, (I) wear off, (I) whizz up, bit, dried, footfall, footstep, gauge, pace, pas, section, snatch, stop, walk, walks, way, withered",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pasta",
        "translation": "pasta, paste, dough, pastry",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pasticceria",
        "translation": "bakery, confectioner’s (shop), pastry shop, patisserie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pasto",
        "translation": "meal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patata",
        "translation": "potato",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "patente",
        "translation": "driver’s license, driving license",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "patria",
        "translation": "homeland, fatherland, (native) country, native country",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "paura",
        "translation": "fear",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pavimento",
        "translation": "floor, (I) floor, ground",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paziente",
        "translation": "patient",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "pazienti",
        "translation": "patients, patient",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "pazienza",
        "translation": "patience",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "peggiore",
        "translation": "worst, worse",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "peggiori",
        "translation": "(you) make worse, (you) get worse, (you) worsen, worse",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "pelle",
        "translation": "skin, leather, casing, fell, hide, peel, pelt, rind",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "penna",
        "translation": "pen, feather",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "penne",
        "translation": "pens",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pensa",
        "translation": "thinks, (you) think, (he/she/it) thinks, (he/she/it) believes, (you) believe, believe, imagine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensammo",
        "translation": "(we) thought, (we) believed, believe, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensando",
        "translation": "thinking, believing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensano",
        "translation": "(they) think",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensarci",
        "translation": "(to) think (of it)",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "pensare",
        "translation": "(to) think, (to) to believe, believe, believed, imagine, thinks, thought, thoughts",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensarono",
        "translation": "thought, believe, (they) believed, (they) thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensasse",
        "translation": "(he/she/it/you) thought, (he/she/it/you) believed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensassi",
        "translation": "(I/you) thought, (I/you) believed, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensava",
        "translation": "(he/she/it/you) was/were thinking, (he/she/it/you) was/were believing, (he/she/it/you) thought, (he/she/it/you) believed, (he/she/it/you) used to believe, (he/she/it/you) used to think, believed, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensavi",
        "translation": "(you) were thinking, (you) thought, (you) used to think, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensavo",
        "translation": "(I) thought, (I) believed, (I) was/were thinking, (I) used to believe, (I) used to think, (I) was/were believing, believed, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penserà",
        "translation": "(he/she/it/you) will think, (he/she/it/you) will believe, believe, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penserai",
        "translation": "(you) will think, (you) will believe, believe, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penseranno",
        "translation": "(they) will think, (they) will believe, believe, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penserebbero",
        "translation": "(they) would think, (they) would believe, believe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penserei",
        "translation": "(I) would think, (I) would believe, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penseremo",
        "translation": "(we) will think, (we) will believe, believe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penserò",
        "translation": "(I) will think, (I) will believe, believe, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensi",
        "translation": "(you) think, thinks, (he/she) thinks [subj]",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensiamoci",
        "translation": "(let us) think about it, (let us) think about each other",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "pensiero",
        "translation": "thought, gift, mind, thoughts, way of thinking",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pensò",
        "translation": "(he/she/it/you) thought, (he/she/it/you) believed, believe, believed, thought",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pepe",
        "translation": "pepper",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "per",
        "translation": "for, to, by, gone, on, out of, times, went",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "perché",
        "translation": "why, because, for, reason, so that",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "perda",
        "translation": "lose, lose one’s way, be deprived of, forfeit, loses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perde",
        "translation": "(he/she/it) loses, (he/she/it) loses one’s way, (he/she/it) is lost, (you) are lost, (you) get lost, (you) lose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdendo",
        "translation": "losing, missing, losing one’s way, forfeiting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perderà",
        "translation": "(he/she/it/you) will lose, (he/she/it/you) will lose one’s way, (he/she/it/you) will miss, (he/she/it/you) will be lost, (he/she/it/you) will get lost, (he/she/it/you) will leak, (he/she/it/you) will shed, lose, lost, missed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perderai",
        "translation": "lose, (you) will lose, (you) will miss, (you) will be deprived of, (you) will be lost, (you) will forfeit, (you) will get lost, (you) will go astray, (you) will leak, (you) will lose one’s way, (you) will shed, (you) will waste, lost",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdere",
        "translation": "(to) lose, go, (to) be lost, (to) forfeit, (to) get lost, (to) leak, (to) miss, (to) shed, (to) waste, be deprived of, gave, get, give, go astray, gone, got, lose, lose one’s way, lost, missed, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perderebbe",
        "translation": "(he/she/it/you) would be lost, (he/she/it/you) would lose, (he/she/it/you) would get lost, (he/she/it/you) would leak, (he/she/it/you) would lose one’s way, (he/she/it/you) would miss, (he/she/it/you) would shed, lose, lost, missed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perderemmo",
        "translation": "(we) would lose, (we) would leak, (we) would miss, (we) would be deprived of, (we) would be lost, (we) would forfeit, (we) would get lost, (we) would go astray, (we) would lose one’s way, (we) would shed, (we) would waste, lose, lost, missed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perderò",
        "translation": "(I) will lose, (I) will shed, (I) will forfeit, (I) will be deprived of, (I) will be lost, (I) will get lost, (I) will leak, (I) will lose one’s way, (I) will miss, lose, missed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdessero",
        "translation": "(they) lost, (they) got lost, (they) was/were lost, (they) forfeited, (they) lost one’s way, (they) was/were deprived of",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdessi",
        "translation": "(I/you) lost, lost",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdessimo",
        "translation": "(we) forfeited, (we) lost, (we) was/were deprived of",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdevamo",
        "translation": "(we) were losing, (we) were losing one’s way, (we) lost, lost, missed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdevano",
        "translation": "(they) were losing, (they) lost, (they) were losing one’s way, (they) used to lose, lost, missed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdita",
        "translation": "loss, waste, leak, deprivation, fell",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "perdo",
        "translation": "lose, (I) lose, (I) lose one’s way, (I) forfeit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perfetta",
        "translation": "perfect, perfect (tense), complete, faultless, utter",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "perfettamente",
        "translation": "perfectly, absolutely",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "perfetti",
        "translation": "perfect",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "perfetto",
        "translation": "perfect, perfect (tense), clear, complete, faultless, finished, utter",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pericoli",
        "translation": "danger, peril, risk",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pericolo",
        "translation": "danger, peril, risk",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pericolosa",
        "translation": "dangerous, hazardous, perilous, unsafe",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pericolosi",
        "translation": "dangerous, hazardous, unsafe",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pericoloso",
        "translation": "dangerous, hazardous, perilous, ugly, unsafe",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "periodo",
        "translation": "period, time, moment, run, runs, span",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "però",
        "translation": "but, however, though, nevertheless, well",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "perse",
        "translation": "lost, missed, be lost, be deprived of, forfeit, get lost, lose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "persi",
        "translation": "lost, be lost, get lost, (I) lost, be deprived of, forfeit, lose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "persona",
        "translation": "person",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "personaggi",
        "translation": "characters, figures, personage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "personaggio",
        "translation": "character, figure, personage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "personale",
        "translation": "personal, staff, private, individual, special",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "personali",
        "translation": "personal, individual, private",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "personalità",
        "translation": "personality, character, personage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pesante",
        "translation": "heavy, bad, dull, hard, her, leaden, oppressive, ponderous, rich, slow, sluggish, stodgy, tasteless, thick, ungainly, unwieldy, vulgar, wearisome, weighty",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pesanti",
        "translation": "hard, heavy, bad, carry weight, dull, leaden, lie (heavily), oppressive, ponderous, slow, sluggish, stodgy, tasteless, thick, ungainly, unwieldy, vulgar, wearisome, weigh, weigh oneself, weighty",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pesca",
        "translation": "(you) fish, (he/she/it) fishes, (you) fish for, (he/she/it) catches, (he/she/it) dives for, (he/she/it) draws, (he/she/it) finds, (he/she/it) fishes for, (he/she/it) gets, (he/she/it) picks (up), (you) catch, (you) dive for, (you) draw, (you) find, (you) get, (you) pick (up), fishing, haul, lottery, peach, raffle, take, taken, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pescano",
        "translation": "(they) fish, (they) fish for, (they) find, (they) catch, (they) dive for, (they) draw, (they) get, (they) pick (up), fish",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pescare",
        "translation": "(to) fish, (to) to fish, (to) fish for, (to) catch, (to) dive for, fish",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pescatore",
        "translation": "fisherman, fisher",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pescatrice",
        "translation": "fisherwoman",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pesce",
        "translation": "fish",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pesci",
        "translation": "fish, the Fishes, Pisces, fishes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "petizione",
        "translation": "petition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pettine",
        "translation": "comb, decoration on Venetian gondolas, pecten",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "petto",
        "translation": "chest, breast, bosom, breasts, bust",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pezzi",
        "translation": "pieces, parts, piece",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pezzo",
        "translation": "piece, part, cut, bit, entries, lengths",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piaccia",
        "translation": "like, like oneself",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piacciono",
        "translation": "like, likes, are liked, they like",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piace",
        "translation": "like, likes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piacendo",
        "translation": "liking, enjoying",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piacerà",
        "translation": "like, will like",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piacere",
        "translation": "pleasure, pleased to meet you, favor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piacerebbe",
        "translation": "would like",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piacesse",
        "translation": "liked, (he/she/it/you) liked oneself",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piaceva",
        "translation": "liked, like, (he/she/it/you) liked oneself, (he/she/it/you) used to like oneself, (he/she/it/you) was/were liking oneself",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piacque",
        "translation": "liked, like, (he/she/it/you) liked oneself",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piacquero",
        "translation": "liked, (they) liked oneself, like",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pianeta",
        "translation": "planet, chasuble, universe, world",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piange",
        "translation": "(he/she/it) cries, (he/she/it) mourns (for), (you) mourn (for)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piangere",
        "translation": "(to) cry, (to) to weep, (to) to bemoan, (to) bleed, (to) mourn (for), (to) to grieve for, (to) to grieve over, (to) to lament, (to) water, bemoan, grieve for, grieve over, lament, weep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piangi",
        "translation": "(you) cry, (you) mourn (for), (you) grieve for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piani",
        "translation": "plans, stories, flat, floors, plain, storeys",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pianifica",
        "translation": "(you) plan, (he/she/it) plans",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pianifichi",
        "translation": "(you) plan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pianifichiamo",
        "translation": "(we) plan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piano",
        "translation": "plan, floor, slowly, even, flat, gently, level, piano, plain, program, programme, project, quietly, scheme, slow, softly",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pianoforte",
        "translation": "piano, pianoforte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pianta",
        "translation": "(you) plant, (he/she/it) plants, (he/she/it) stops, (you) stop, layout, map, plant",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piante",
        "translation": "plants",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piatti",
        "translation": "plates, dishes, cymbal, dull, flat, smooth, unexciting, unimaginative",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piatto",
        "translation": "plate, dish, course, cymbal, dull, even, flat, slim(line), smooth, unexciting",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piazza",
        "translation": "square",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piccola",
        "translation": "small, little",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "piccoli",
        "translation": "small, little, petty, short, undersized, young",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "piccolo",
        "translation": "small, little, short, smaller",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "piede",
        "translation": "foot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piedi",
        "translation": "feet, foot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piena",
        "translation": "full, flood, heat",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pieno",
        "translation": "full, busy, complete, heavy, plump, rich, round, solid",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pietra",
        "translation": "stone, rock",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pilota",
        "translation": "pilot, (you) pilot, driver, (he/she/it) flies, (he/she/it) pilots, drive",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pinguino",
        "translation": "penguin, choc-ice",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pioggia",
        "translation": "rain, rains, shower, volley",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piovendo",
        "translation": "raining, rain, rain down",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piovere",
        "translation": "(to) rain, raining, (to) rain down, (to) to shower, rained, rains",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piscina",
        "translation": "pool, swimming pool",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pista",
        "translation": "track, trail, ski run, racecourse, ring, runs",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pittore",
        "translation": "painter, portrayer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "più",
        "translation": "more, plus",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "piuttosto",
        "translation": "pretty, rather, somewhat, before, first, instead, prettier, quite, rather than, than",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "plastica",
        "translation": "plastic, plastic surgery, plastic art",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "po'",
        "translation": "some, a little",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "poca",
        "translation": "few, little, a few, a little of, not many",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "pochi",
        "translation": "few, little, not many",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "pochino",
        "translation": "a little bit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poco",
        "translation": "little, a little of, a bit of, a fair amount of, a few, few, not many, not much, not very",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "poesia",
        "translation": "poetry, poem, piece of poetry, verse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poesie",
        "translation": "poems",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poeta",
        "translation": "poet, (he/she/it) writes poetry, (you) write poetry, (day)dreamer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poi",
        "translation": "then, later, in the end, after, afterwards, at last, besides, secondly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "politica",
        "translation": "politics, policy, political, (female) politician",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "politici",
        "translation": "political, calculating, politicians",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "politico",
        "translation": "political, politician, calculating",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "polizia",
        "translation": "police",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poliziotta",
        "translation": "policewoman, police officer",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poliziotti",
        "translation": "policemen, police, police officers, policeman",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poliziotto",
        "translation": "policeman, police officer, policemen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "polli",
        "translation": "chickens",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pollo",
        "translation": "chicken",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pomeriggio",
        "translation": "afternoon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pomodoro",
        "translation": "tomato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ponte",
        "translation": "bridge",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ponti",
        "translation": "bridges, bridge(work), deck",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "popolare",
        "translation": "popular, people, (to) to people, (to) become populated, (to) fill, (to) fill (up) with people, (to) populate, (to) spook, (to) to haunt, (to) to inhabit, (to) to pack, downmarket, folk, haunt, inhabit, lower class, pack, peoples, people’s, working class",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "popolari",
        "translation": "popular, (to) populate, common",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "popolazione",
        "translation": "population",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "popoli",
        "translation": "nations, peoples",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "popolo",
        "translation": "people, nation, peoples",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "porta",
        "translation": "door, wears, (he/she/it) wears, (he/she/it) brings, (he/she/it) carries, carry, goal, ports, take, takes, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portafogli",
        "translation": "wallets, wallet, briefcases",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "portafoglio",
        "translation": "wallet, billfold, briefcase, briefcases, portfolio, portfolios, wallets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "portai",
        "translation": "(I) took, (I) brought, (I) carried, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portando",
        "translation": "taking, bringing, carrying, wearing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portano",
        "translation": "(they) wear, (they) take, (they) bring, (they) carry, carry, lead, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portare",
        "translation": "(to) bring, (to) take, (to) wear, (to) carry, had, put, puts, take, takes, took, wear, wears",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portasse",
        "translation": "(he/she/it/you) carried, (he/she/it/you) brought, (he/she/it/you) took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portassimo",
        "translation": "(we) brought, (we) carried, (we) took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portava",
        "translation": "would take, (he/she/it/you) was/were taking, (he/she/it/you) was/were bringing, (he/she/it/you) brought, (he/she/it/you) used to bring, (he/she/it/you) used to carry, (he/she/it/you) was/were wearing, carried, took, would bring",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portavano",
        "translation": "(they) used to take, (they) were wearing, (they) used to wear, (they) brought, (they) used to bring, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porterà",
        "translation": "(he/she/it/you) will take, (he/she/it/you) will bring, (he/she/it/you) will wear, take",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porterebbe",
        "translation": "(he/she/it/you) would bring, (he/she/it/you) would wear, (he/she/it/you) would take, carry, take",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porterei",
        "translation": "take, (I) would bring, (I) would take, (I) would wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porteresti",
        "translation": "(you) would take, (you) would wear, (you) would bring, take, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porterò",
        "translation": "(I) will bring, (I) will take, (I) will wear, take, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porti",
        "translation": "(you) bring, (you) take, ports, (you) carry, (you) wear, carry, take, wear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portò",
        "translation": "port, harbor, (I) wear, (I) bring, (I) carry, (I) take, carry, harbour, ports",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porto",
        "translation": "port, harbor, (I) wear, (I) bring, (I) carry, (I) take, carry, harbour, ports",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "portoghese",
        "translation": "Portuguese, fare dodger, gatecrasher, ligger",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "portoghesi",
        "translation": "Portuguese, gatecrasher, ligger",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "positiva",
        "translation": "positive",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "positive",
        "translation": "positive, constructive, empirical, favorable, favourable, good, matter-of-fact, plus, practical",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "positivo",
        "translation": "positive, constructive, empirical, favorable, favourable, good, matter-of-fact, plus, practical",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "posizione",
        "translation": "position, (bank) balance, attitude, lies, line, location, place, placing, posture, standing, status, view",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "possa",
        "translation": "may, be likely, be allowed to, be able to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "possiamo",
        "translation": "(we) can, (we) are able to, (we) are allowed to, (we) may",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "possibile",
        "translation": "possible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "possibilità",
        "translation": "possibility, chance, income, likelihood, means, opportunity, possibilities",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "possibilmente",
        "translation": "if possible",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "posso",
        "translation": "(I) can, (I) may, (I) am able to, (I) am allowed to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "possono",
        "translation": "(they) can, (they) may, (they) are able to, (they) are allowed to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "posta",
        "translation": "mail, (you) post, (he/she/it) posts",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "posti",
        "translation": "places, (you) post, (you) station, spots",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "postini",
        "translation": "mailmen, mail carriers, post, station",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "postino",
        "translation": "mailman, postman, mail carrier, mailmen, post, station",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "posto",
        "translation": "place, parking place, in place of, (I) post, (I) station, arisen, assumed, assuming that, at the scene, erected, given that, instead of, job, laid down, on the scene, placed, points, posed, position, provided that, put, put down, put oneself, room, seat, set, set up, since, site, situated, space, spot, supposed, tidy, to the scene",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "potei",
        "translation": "could, (I) could, (I) was/were likely, (I) was/were able to, (I) was/were allowed to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potemmo",
        "translation": "could, (we) flatted out, (we) was/were likely, (we) as (past) fast as one can, (we) as (past) hard as one can, (we) as (past) much as one can, (we) was/were able to, (we) was/were allowed to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potendo",
        "translation": "being able to, being allowed to, being likely, as fast as one can, as hard as one can, as much as one can, can, could, flatting out, may, might, would",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potenziale",
        "translation": "potential (mood), tension",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "potenziali",
        "translation": "potential (mood), tension",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "poter",
        "translation": "(to) be able to, (to) can, (to) may",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potere",
        "translation": "power, be able to, be likely, can, can't, cannot, could, may",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poteri",
        "translation": "powers, be able to, be likely",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "potesse",
        "translation": "(he/she/it/you) was/were likely, (he/she/it/you) as (past) much as one can, (he/she/it/you) as (past) fast as one can, (he/she/it/you) as (past) hard as one can, (he/she/it/you) could, (he/she/it/you) was/were able to, (he/she/it/you) was/were allowed to, could",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potete",
        "translation": "(you) can, (you) are able to, (you) are allowed to, (you) as fast as one can",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poteva",
        "translation": "could, (he/she/it) could, (he/she/it/you) was/were able to, (he/she/it/you) as (past) fast as one can, (he/she/it/you) as (past) hard as one can, (he/she/it/you) as (past) much as one can, (he/she/it/you) flatted out, (he/she/it/you) used to as fast as one can, (he/she/it/you) used to as hard as one can, (he/she/it/you) used to as much as one can, (he/she/it/you) used to be able to, (he/she/it/you) used to be allowed to, (he/she/it/you) used to be likely, (he/she/it/you) used to flat out, (he/she/it/you) was/were allowed to, (he/she/it/you) was/were as fast as one can, (he/she/it/you) was/were as hard as one can, (he/she/it/you) was/were as much as one can, (he/she/it/you) was/were being able to, (he/she/it/you) was/were being allowed to, (he/she/it/you) was/were being likely, (he/she/it/you) was/were flatting out, (he/she/it/you) was/were likely",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potevano",
        "translation": "(they) were being able to, (they) was/were able to, (they) used to be able to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potevo",
        "translation": "could, (I) was/were able to, (I) was/were being able to, (I) as (past) much as one can, (I) used to be able to, (I) was/were as much as one can",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potrebbe",
        "translation": "could, (he/she/it/you) would be likely, (he/she/it/you) would be able to, (he/she/it/you) would be allowed to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potremmo",
        "translation": "could, (we) could, (we) would be likely, (we) would be able to, (we) would be allowed to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "potrò",
        "translation": "(I) will be able to, (I) will be allowed to, (I) will be likely, (I) will as fast as one can, (I) will as hard as one can, (I) will as much as one can, (I) will flat out, can",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "povera",
        "translation": "poor",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "poveri",
        "translation": "poor, poor people, poor man, barren, late, pauper, plain, scanty, wretched",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "povertà",
        "translation": "poverty, want, lack, shortage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pranza",
        "translation": "(you) have lunch, (he/she/it) has lunch, (he/she/it) eats lunch, (you) eat lunch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pranzano",
        "translation": "(they) have lunch, (they) lunch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pranzo",
        "translation": "lunch, (I) lunch, (I) have lunch, luncheon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pratica",
        "translation": "(you) practice, (he/she/it) makes, (he/she/it) practises, (he/she/it) associates with, (he/she/it) carries out, (he/she/it) charges, (he/she/it) follows, (he/she/it) frequents, (he/she/it) gives, (he/she/it) keeps company with, (he/she/it) plays, (he/she/it) practices, (he/she/it) pursues, (he/she/it) travels, (you) associate with, (you) carry out, (you) charge, (you) follow, (you) frequent, (you) give, (you) keep company with, (you) make, (you) play, (you) practise, (you) pursue, (you) travel, affair, allows, custom, dossier, experience, file, follow, knowledge, matter, plays, practical experience, practical person, pratique",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "praticamente",
        "translation": "practically, you know, in a practical way, all, for all practical purposes, in practice, virtually, well",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "preferire",
        "translation": "(to) prefer, (to) like better, (to) like best, go, gone, prefer, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferisce",
        "translation": "(you) prefer, (he/she/it) prefers, (he/she/it) likes better, (you) like better",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferisci",
        "translation": "(you) prefer, (you) like better, (you) like best",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferita",
        "translation": "favorite, favourite",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "preferito",
        "translation": "favorite, preferred, favourite",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "preghiamo",
        "translation": "(we) ask, (we) beg, (we) request",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preghiera",
        "translation": "prayer, entreaty, request",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prego",
        "translation": "please, you are welcome, do not mention it, not at all, you're welcome",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "premi",
        "translation": "(you) award a prize to, (you) give a prize, (you) reward, stop",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "premio",
        "translation": "prize, award, (I) give a prize, (I) reward, bonus, premium",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prenda",
        "translation": "take, take root, take up, (he/she/it) takes, assume, bring, capture, catch, charge, come over, cop it, get, get a beating, get worked up, go into, grab, grasp, handle, have, hit, involve, pick up, put on, run into, seize, set, steal, strike, take [somebody] on, take down, take on, take over, take something amiss",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prende",
        "translation": "(he/she/it) takes, (he/she/it) gets, (he/she/it) grasps, (you) get, (you) grasp, (you) take, had, take, takes, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendendo",
        "translation": "taking, having, taking on, assuming, bringing, capturing, catching, charging, coming over, copping it, getting, getting a beating, getting worked up, going into, grabbing, grasping, handling, hitting, involving, picking up, putting on, running into, seizing, setting, stealing, striking, taking [somebody] on, taking down, taking over, taking root, taking something amiss, taking up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenderà",
        "translation": "(he/she/it/you) will pick up, (he/she/it/you) will get, (he/she/it/you) will take up, (he/she/it/you) will come over, (he/she/it/you) will cop it, (he/she/it/you) will go into, (he/she/it/you) will grab, (he/she/it/you) will have, (he/she/it/you) will hit, (he/she/it/you) will put on, (he/she/it/you) will run into, (he/she/it/you) will set, (he/she/it/you) will take, (he/she/it/you) will take down, (he/she/it/you) will take on, (he/she/it/you) will take over, (he/she/it/you) will take root, caught, get, had, received, take, taken",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenderai",
        "translation": "take, (you) will take, (you) will take down, (you) will come over, (you) will cop it, (you) will get, (you) will go into, (you) will grab, (you) will have, (you) will hit, (you) will pick up, (you) will put on, (you) will run into, (you) will set, (you) will take on, (you) will take over, (you) will take root, (you) will take up, get, taken",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendere",
        "translation": "take, (to) get, (to) take, (to) assume, (to) bring, (to) catch, (to) charge, (to) come over, (to) get a beating, (to) get worked up, (to) grasp, (to) handle, (to) have, (to) hit, (to) involve, (to) pick up, (to) put on, (to) set, (to) take [somebody] on, (to) take down, (to) take on, (to) take over, (to) take root, (to) take something amiss, (to) take up, (to) to capture, (to) to catch, (to) to cop it, (to) to go into, (to) to grab, (to) to run into, (to) to seize, (to) to steal, (to) to strike, capture, cop it, get, go, go into, gone, got, grab, had, have, light, put, puts, received, run into, seize, steal, strike, takes, took, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenderebbe",
        "translation": "take, (he/she/it/you) would take, (he/she/it/you) would take up, (he/she/it/you) would come over, (he/she/it/you) would cop it, (he/she/it/you) would get, (he/she/it/you) would go into, (he/she/it/you) would grab, (he/she/it/you) would have, (he/she/it/you) would hit, (he/she/it/you) would pick up, (he/she/it/you) would put on, (he/she/it/you) would run into, (he/she/it/you) would seize, (he/she/it/you) would set, (he/she/it/you) would take down, (he/she/it/you) would take on, (he/she/it/you) would take over, (he/she/it/you) would take root, caught, get, had, taken, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenderebbero",
        "translation": "(they) would get, (they) would seize, (they) would have, (they) would come over, (they) would cop it, (they) would go into, (they) would grab, (they) would hit, (they) would pick up, (they) would put on, (they) would run into, (they) would set, (they) would take, (they) would take down, (they) would take on, (they) would take over, (they) would take root, (they) would take up, get, had",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendereste",
        "translation": "(you) would take, (you) would take down, (you) would take root, (you) would come over, (you) would cop it, (you) would get, (you) would go into, (you) would grab, (you) would have, (you) would hit, (you) would pick up, (you) would put on, (you) would run into, (you) would seize, (you) would set, (you) would take on, (you) would take over, (you) would take up, get, had, take, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenderò",
        "translation": "take, (I) will take, (I) will get, (I) will come over, (I) will cop it, (I) will go into, (I) will grab, (I) will have, (I) will hit, (I) will pick up, (I) will put on, (I) will run into, (I) will set, (I) will take down, (I) will take on, (I) will take over, (I) will take root, (I) will take up, received, taken",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendesse",
        "translation": "(he/she/it/you) took, (he/she/it/you) took up, (he/she/it/you) took on, (he/she/it/you) grabbed, (he/she/it/you) grasped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendessi",
        "translation": "(I/you) grasped, (I/you) took, (I/you) grabbed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendeva",
        "translation": "(he/she/you) used to charge, (he/she/it/you) used to take, (he/she/it/you) was/were taking, (he/she/it/you) took, (he/she/it/you) was/were taking down, (he/she/it/you) was/were taking on, (he/she/it/you) was/were taking over, (he/she/it/you) was/were taking root, (he/she/it/you) was/were taking up, caught, got, had, received, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendi",
        "translation": "(you) take, (you) capture, (you) catch, (you) get, (you) grab, (you) grasp, (you) have, (you) hit, (you) pick up, (you) seize, (you) set, do you take, had, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendiamo",
        "translation": "(we) take, (we) get, (we) grab, (we) grasp, had, take, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendono",
        "translation": "take, (they) take, get, (they) catch, (they) get, (they) grab, (they) grasp, (they) have, (they) hit, (they) pick up, (they) seize, (they) set, had, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenota",
        "translation": "(he/she/it) reserves, (he/she/it) makes a reservation, (he/she/it) books, (he/she/it) puts one’s name down, (you) book, (you) make a reservation, (you) put one’s name down, (you) reserve, book",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenotano",
        "translation": "(they) reserve, (they) book, (they) put one’s name down, book",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenotare",
        "translation": "reserve, (to) to reserve, make a reservation, (to) book, (to) put one’s name down, (to) to make a reservation, book",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prenotazione",
        "translation": "reservation, booking",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prenotiamo",
        "translation": "(we) reserve, (we) book, (we) put one’s name down, book",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preoccupare",
        "translation": "(to) worry, (to) to concern, (to) to trouble, (to) take care, (to) to be worried, (to) to take the trouble, be worried, concern, exercise, take the trouble, trouble",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preparando",
        "translation": "preparing (oneself), preparing for, getting ready, arranging, being brewing, coaching, drafting, drawing up, dressing, getting [something] ready, making, making out, mounting, planning, studying for, training",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prese",
        "translation": "(he/she/it/you) took, (he/she/it/you) took up, (he/she/it/you) took on, get, got, had, received, take, took",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presentazione",
        "translation": "presentation, introduction, lodgement, lodging, presentment, production, show, submission, view, viewing, views",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presenza",
        "translation": "presence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presidente",
        "translation": "president, chair, chairman, chairperson, chairs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "presidenza",
        "translation": "presidency, chairmanship, presidentship",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pressione",
        "translation": "pressure, press, strain",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presta",
        "translation": "(he/she/it) lends, (he/she/it) gives, (he/she/it) offers oneself, (you) give, (you) lend, (you) offer oneself, exercise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prestano",
        "translation": "(they) lend, (they) loan, exercise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prestare",
        "translation": "(to) lend, (to) to lend, (to) give, exercise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presto",
        "translation": "soon, early, exercise",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "prezzi",
        "translation": "prices",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prezzo",
        "translation": "(I) price, price label, price tag, (I) put price tags on, cost",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prigione",
        "translation": "prison, jail",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prima",
        "translation": "before, first (night), back, beforehand, bottom gear, channel one, earlier, first, first ascent, first course, first form, first year, former, front, in advance, minute, once, opening (night), première, previous, previous to, prime, sooner, unison, until",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "primavera",
        "translation": "spring, youth team",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "primi",
        "translation": "first, at first, first course, at face value, channel one, early, first courses, firstly, foremost, former, in the first instance / place, in the foreground, initially, leading, minute, next, on the face of it, pasta dishes, prime, to begin with",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "primo",
        "translation": "first, first course, prime",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "principale",
        "translation": "main, basic, boss, central, chief, head, primary, prime, principal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "principiante",
        "translation": "beginner, inexperienced, inexpert",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "probabile",
        "translation": "likely, probable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "probabilmente",
        "translation": "probably, possibly, likely",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "problema",
        "translation": "problem, issue, matter, question, trouble, worry",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "problemi",
        "translation": "problems, issue, matter, problem, question, trouble, worry",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "processi",
        "translation": "trials, (you) process, (you) try",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "processo",
        "translation": "trial, (I) process, (I) try",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prodotti",
        "translation": "products, manufactured, produced, turned out",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prodotto",
        "translation": "produced, product, born, borne, brought forward, come along, developed, generated, happened, made, manufactured, returned, turned out, yielded",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "produzione",
        "translation": "production, generations, make, makes, output, run, runs",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "professionale",
        "translation": "professional, occupational, professionals, vocational",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "professionali",
        "translation": "professional, occupational, professionals, vocational",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "professore",
        "translation": "professor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "professoressa",
        "translation": "professor",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "profonda",
        "translation": "deep, lavish",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "profondo",
        "translation": "deep, deep down, (I) lavish, deeply, earnest, heavy, low, marked, meaningful, penetrating, profound, rich, sheer, sound, strong, stronger",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "progetti",
        "translation": "plans, (you) style, (you) plan, (you) design, (you) work out",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "progetto",
        "translation": "project, (I) plan, (I) work out, jobs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "programma",
        "translation": "program, (computer) program, plan, (you) budget, (you) organize, (you) preset, (you) schedule, (you) set, (you) time, cards, curriculum, syllabus, times",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "programmi",
        "translation": "plans, programs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pronta",
        "translation": "ready, ready-made, agile, clever, nimble, prompt, quick, quick-witted",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pronto",
        "translation": "ready, ready-made, agile, clever, hello, nimble, prompt, quick, quick-witted, right",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "proposta",
        "translation": "proposal, offer, proposition, suggestion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "proposte",
        "translation": "proposals, proposed, suggested, suggestions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propri",
        "translation": "his (own), her (own), their (own) (plural), exact, its (own), just, literal, own, proper, quite, really, truly, very",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "propria",
        "translation": "own, her own, their own, his own, its own, strictly",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "proprietà",
        "translation": "property, properties, characteristic, correctness, estate, ownership, possessions, propriety",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "proprietario",
        "translation": "owner, proprietor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "proprio",
        "translation": "really, right, truly, exact, his or her own, his/her/its own, own, precisely, proper, quite, real, their own, very",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "prossima",
        "translation": "next, close, direct, forthcoming, immediate, neighbour, proximo, upcoming",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "prossimo",
        "translation": "next, close, coming, forthcoming, immediate, upcoming",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "protezione",
        "translation": "protection, protection money, protective device",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prova",
        "translation": "(you) test, (he/she/it) tries on, (he/she/it) tries (out), (he/she/it) demonstrates, (he/she/it) distresses, (he/she/it) documents, (he/she/it) enjoys, (he/she/it) establishes, (he/she/it) experiences, (he/she/it) feels, (he/she/it) goes through, (he/she/it) has a taste of, (he/she/it) hits, (he/she/it) knows, (he/she/it) practices, (he/she/it) practises, (he/she/it) proves, (he/she/it) rehearses, (he/she/it) runs through, (he/she/it) runs trials on, (he/she/it) samples, (he/she/it) tests, (you) demonstrate, (you) distress, (you) document, (you) enjoy, (you) establish, (you) experience, (you) feel, (you) go through, (you) have a taste of, (you) hit, (you) know, (you) practice, (you) practise, (you) prove, (you) rehearse, (you) run through, (you) run trials on, (you) sample, (you) try (out), (you) try on, demonstration, documents, event, events, evidence, exam, exercise, exhibit, experiences, feel, fitting, hardship, ordeal, papers, proof, rehearsal, show, trial, trial run, tried, venture",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provai",
        "translation": "(I) tried (out), (I) tried on, (I) ran trials on, feel, felt, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provando",
        "translation": "trying (out), trying on, feeling, demonstrating, distressing, documenting, enjoying, establishing, experiencing, going through, having a taste of, hitting, knowing, practicing, practising, proving, rehearsing, running through, running trials on, sampling, testing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provano",
        "translation": "(they) try (out), (they) try on, (they) practice, (they) demonstrate, (they) distress, (they) document, (they) enjoy, (they) establish, (they) experience, (they) feel, (they) go through, (they) have a taste of, (they) hit, (they) know, (they) practise, (they) prove, (they) rehearse, (they) run through, (they) run trials on, (they) sample, (they) test, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provasse",
        "translation": "to feel, (he/she/it/you) had a taste of, (he/she/it/you) demonstrated, (he/she/it/you) distressed, (he/she/it/you) documented, (he/she/it/you) enjoyed, (he/she/it/you) established, (he/she/it/you) experienced, (he/she/it/you) felt, (he/she/it/you) hit, (he/she/it/you) knew, (he/she/it/you) practiced, (he/she/it/you) practised, (he/she/it/you) proved, (he/she/it/you) ran through, (he/she/it/you) ran trials on, (he/she/it/you) rehearsed, (he/she/it/you) sampled, (he/she/it/you) tested, (he/she/it/you) tried (out), (he/she/it/you) tried on, (he/she/it/you) went through, felt",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provassi",
        "translation": "(I/you) tested, (I/you) tried (out), (I/you) ran trials on, try",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provassimo",
        "translation": "(we) tested, (we) tried (out), (we) ran trials on",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provava",
        "translation": "",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provavamo",
        "translation": "(we) used to try, (we) were trying (out), (we) ran trials on, (we) were trying on, felt, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provavo",
        "translation": "(I) was/were trying (out), (I) was/were trying on, (I) used to feel, (I) ran trials on, (I) used to run trials on, felt, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proverebbe",
        "translation": "(he/she/it/you) would prove, (he/she/it/you) would try (out), (he/she/it/you) would try on, (he/she/it/you) would feel, (he/she/it/you) would hit, (he/she/it/you) would know, (he/she/it/you) would test, feel, felt, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proveremmo",
        "translation": "(we) would try (out), (we) would try on, (we) would feel, (we) would hit, (we) would know, (we) would prove, (we) would test",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proveremo",
        "translation": "(we) will try (out), (we) will try on, (we) will know, (we) will feel, (we) will hit, (we) will test",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proverete",
        "translation": "(you) will try (out), (you) will try on, (you) will test, (you) will feel, (you) will hit, (you) will know, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proverò",
        "translation": "(I) will try (out), (I) will try on, (I) will document, (I) will demonstrate, (I) will distress, (I) will enjoy, (I) will establish, (I) will experience, (I) will feel, (I) will go through, (I) will have a taste of, (I) will hit, (I) will know, (I) will practice, (I) will practise, (I) will prove, (I) will rehearse, (I) will run through, (I) will run trials on, (I) will sample, (I) will test, feel, tried",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provi",
        "translation": "try, (you) try (out), (you) try on, (you) know, (you) run trials on, (you) test, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proviamo",
        "translation": "(we) try (out), (we) try on, (we) run trials on, feel, let's try",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provincia",
        "translation": "province, provincial administration",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "provinciale",
        "translation": "provincial, insular, small-town",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "provo",
        "translation": "(I) try (out), (I) try on, (I) feel, (I) demonstrate, (I) distress, (I) document, (I) enjoy, (I) establish, (I) experience, (I) go through, (I) have a taste of, (I) hit, (I) know, (I) practice, (I) practise, (I) prove, (I) rehearse, (I) run through, (I) run trials on, (I) sample, (I) test, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "provò",
        "translation": "(I) try (out), (I) try on, (I) feel, (I) demonstrate, (I) distress, (I) document, (I) enjoy, (I) establish, (I) experience, (I) go through, (I) have a taste of, (I) hit, (I) know, (I) practice, (I) practise, (I) prove, (I) rehearse, (I) run through, (I) run trials on, (I) sample, (I) test, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "psicologia",
        "translation": "psychology",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pubblica",
        "translation": "public, (he/she/it) produces, (he/she/it) prints, (he/she/it) advertises, (he/she/it) issues, (he/she/it) posts, (he/she/it) publishes, (he/she/it) releases, (you) advertise, (you) issue, (you) post, (you) print, (you) produce, (you) publish, (you) release, produce",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pubblicità",
        "translation": "advertising, advertisement, advertisements, ads, broke, broken, publicity",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pubblico",
        "translation": "public, audience, in public, (I) advertise, (I) issue, (I) post, (I) print, (I) produce, (I) publish, (I) release, advertising, following, houses, listeners, opened, produce, spectators, state, state-owned, states",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "puliamo",
        "translation": "(we) clean, (we) clean up, (we) clean out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pulire",
        "translation": "(to) clean, (to) clean up, (to) clean out, clean, clear, done",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pulisci",
        "translation": "(you) clean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pulisco",
        "translation": "(I) clean, (I) am cleaning",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pulita",
        "translation": "clean, clean up, clean out, cleaned, cleaned itself, cleaned out, cleaned up, clear, clear out, clear up, cleared out, cleared up, do the housework, gut, gutted, honest, sanitary, wash, washed, wholesome",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pulite",
        "translation": "(you) clean, (you) clean up, (you) clean out, (you) clean itself, (you) clear out, (you) clear up, (you) do the housework, (you) gill, (you) gut, (you) wash, clean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "puliti",
        "translation": "clean, clean out, clean up",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pullman",
        "translation": "bus, coach, pullman",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "punti",
        "translation": "points, (you) place a bet, (you) mark [something] with a dot, (you) aim, (you) bet, (you) brace, (you) direct, (you) dot, (you) gamble, (you) head, (you) lean, (you) pin, (you) point, (you) rest, (you) turn",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "punto",
        "translation": "(I) point, at all, period, (I) aim, (I) bet, (I) brace, (I) direct, (I) dot, (I) gamble, (I) head, (I) lean, (I) mark [something] with a dot, (I) pin, (I) place a bet, (I) rest, (I) turn, bit/bitten, degree, dots, extent, fastidiously, from head to foot, full stop, position, pricked, pricked oneself, prickled, run, runs, stitch, stop, stopped, stung, wounded",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "può",
        "translation": "can, (he/she) is able to, he/she/it can, you (formal) can",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "puoi",
        "translation": "(you) can, (you) are able to, (you) are allowed to, may",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pura",
        "translation": "pure",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pure",
        "translation": "also, too, as well, even, yet",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "puro",
        "translation": "pure, absolute, clear, fine, straight",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "qua",
        "translation": "here, from here, through here, Louie, on this side of, quack, this way",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "qual",
        "translation": "what, which, which (one)",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "qualche",
        "translation": "some, a few",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "qualcos'",
        "translation": "something, something else, anything else, anything",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "qualcosa",
        "translation": "something, anything",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "qualcuna",
        "translation": "somebody, somebody else, (in frasi affermative) some, a few, any, anybody, anybody else, anyone, anyone else, someone, someone else, something else",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "qualcuno",
        "translation": "somebody, someone, somebody else, (in frasi affermative) some, a few, any, anybody, anybody else, anyone, anyone else, someone else, something else",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "quale",
        "translation": "which, what, such as",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quali",
        "translation": "which, what, which (ones), as, exactly the same, just like, such as, that, when, where, who, whom, whose",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "qualità",
        "translation": "quality, qualities, as, kind, properties",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "qualsiasi",
        "translation": "any, just any, anything, common, every, ordinary, whatever, whichever",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "qualunque",
        "translation": "any, whichever, whatever",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "quando",
        "translation": "when, whenever",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "quanta",
        "translation": "how, how much",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "quante",
        "translation": "many, how, how many, as for, as many, as much, concerning, how much, quantity, quantum, regarding, what, what a lot of",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "quanti",
        "translation": "how many, as",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "quantità",
        "translation": "amount, quantity",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "quanto",
        "translation": "how, how much, how long, as much as",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "quarant'",
        "translation": "forty",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quaranta",
        "translation": "forty",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quartiere",
        "translation": "neighborhood, neighbourhood, skirts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "quarto",
        "translation": "quarter, fourth, quarto, watch",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "quasi",
        "translation": "almost, all, nearly, about",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "quattordici",
        "translation": "fourteen",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quattro",
        "translation": "four",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quegli",
        "translation": "those, those (ones), he, that (one), that man, what",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "quei",
        "translation": "those, those (ones), that (one), that, what",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "quel",
        "translation": "that, that (one), what, those (ones)",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "quell'",
        "translation": "that, that (one), those (ones), what",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "quella",
        "translation": "that, that (one), what, those, those (ones)",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "quelle",
        "translation": "those, those (ones), that (one), what",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "quelli",
        "translation": "those, those (ones), ones, that (one), what",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "quello",
        "translation": "what, that (one), those (ones)",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "quest'",
        "translation": "this, this (one), this these, these (ones)",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "questa",
        "translation": "this, these",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "queste",
        "translation": "these (ones), this these, this, this (one)",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "questi",
        "translation": "these (ones), this these, this (one), this, this man, those",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "questo",
        "translation": "this",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "qui",
        "translation": "here",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "quindi",
        "translation": "therefore, so, afterwards, then",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "quindici",
        "translation": "fifteen, 3 pm",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quinta",
        "translation": "fifth (gear), fifth year, fifth form, fifth",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "quinto",
        "translation": "fifth, Quintus",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "quotidiano",
        "translation": "daily (newspaper), everyday",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "radicale",
        "translation": "radical, drastic, far-reaching, root, root sign, sweeping",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "radicali",
        "translation": "radical, drastic, root",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "radice",
        "translation": "root, roots",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "radici",
        "translation": "roots",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "radio",
        "translation": "radio, radio set, radio station, radios, radium, radius",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ragazza",
        "translation": "girl, girlfriend, child, girlfriends, skirts",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ragazze",
        "translation": "girls, girlfriends",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ragazzi",
        "translation": "boys, children, guys, kids",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ragazzo",
        "translation": "boy, child, boyfriend, guy, kid",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ragione",
        "translation": "reason, cause, ratio, right",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ragioni",
        "translation": "reasons, motives",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ragno",
        "translation": "spider",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rapporti",
        "translation": "relations, relationships, (you) compare, (you) report, (you) reproduce, (you) scale, (you) tell, reports",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rapporto",
        "translation": "relationship, (I) report, (I) reproduce, (I) compare, (I) scale, (I) tell, comparison, connection, gear, link, ratio, relation, relationships, repeat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rappresentante",
        "translation": "representative, sales rep(resentative), salesperson",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "rappresentanti",
        "translation": "sales rep(resentative), salesperson, representative",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "raramente",
        "translation": "rarely, seldom",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "rasoio",
        "translation": "razor, shaver",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "re",
        "translation": "king, D, tycoon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "realtà",
        "translation": "reality",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "recente",
        "translation": "recent, fresh",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "recentemente",
        "translation": "recently, lately",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "recenti",
        "translation": "recent",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "regali",
        "translation": "presents, gifts, (you) give away",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "regalo",
        "translation": "gift, present, (I) give away",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "regina",
        "translation": "queen",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "regionale",
        "translation": "regional, local",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "regionali",
        "translation": "regional, local",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "regione",
        "translation": "region, command, land, regions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "regole",
        "translation": "rules, (he/she/it) behaves, (he/she/it) limits, (he/she/it) adjusts, (he/she/it) controls, (he/she/it) controls oneself, (he/she/it) governs, (he/she/it) regulates, (he/she/it) settles, (you) adjust, (you) behave, (you) control, (you) control oneself, (you) govern, (you) limit, (you) regulate, (you) settle, as a rule, in order, moderation, regular, rule, with regular contract",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "religione",
        "translation": "religion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "repubblica",
        "translation": "republic",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "resistenza",
        "translation": "resistance, endurance, resilience",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "responsabile",
        "translation": "responsible, person responsible, person in charge, accountable, culprit, directors, reliable, trustworthy",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "responsabili",
        "translation": "responsible, person responsible, guilty",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "responsabilità",
        "translation": "responsibility, blames, guilt, liability, responsibilities",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "resterà",
        "translation": "remain, (he/she/it/you) will remain, (he/she/it/you) will stay, (he/she/it/you) will be left, (he/she/it/you) will be struck dumb, (he/she/it/you) will drop dead, (he/she/it/you) will meet one’s end / Maker, continue, stay",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "resteremo",
        "translation": "(we) will remain, (we) will stay, (we) will be left, (we) will drop dead, continue, remained, stay",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "resterò",
        "translation": "(I) will stay, (I) will remain, (I) will be left, (I) will be struck dumb, (I) will drop dead, (I) will meet one’s end / Maker, continue, remain, remained, stay",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rete",
        "translation": "net, goal, network, grid, main, mesh, meshwork, netting, rete, ring, sprung bed base, system, trap, wire netting",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ricca",
        "translation": "rich",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ricche",
        "translation": "wealthy, riches, rich, well off",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ricchezza",
        "translation": "wealth, affluence, richness",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ricco",
        "translation": "rich, wealthy, well off",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ricerca",
        "translation": "search, research, piece of research, (he/she/it) looks for [sth., (you) look for, (you) look for [sth., look-up, studied",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ricercare",
        "translation": "research, (to) to seek, (to) to hunt, (to) look for, (to) look for [sth., (to) pursue, (to) seek, (to) somebody] again, (to) try to find, hunt",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricercatore",
        "translation": "researcher",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ricercatori",
        "translation": "researchers, detector, research worker, researcher",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ricercatrice",
        "translation": "researcher, detector, research worker",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ricetta",
        "translation": "recipe, method, prescription, remedy",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "riceve",
        "translation": "(he/she/it) receives, (he/she/it) gets, (you) receive, get, saw, seen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricevere",
        "translation": "(to) receive, (to) to receive, (to) to draw, (to) accept, (to) catch, (to) get, (to) retrieve, (to) see, (to) to get, (to) to take, (to) welcome, draw, fits, get, got, received, saw, see, seen, take",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricevo",
        "translation": "(I) receive, (I) draw, (I) get, get, saw, seen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricorda",
        "translation": "(you) remember, (he/she/it) remembers, (he/she/it) reminds, remembers",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordai",
        "translation": "remembered, (I) remembered, (I) mentioned, (I) reminded",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordano",
        "translation": "(they) remember, (they) remind, (they) mention",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordare",
        "translation": "(to) remember, (to) mention, (to) remind, remembers",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordasse",
        "translation": "(he/she/it/you) remembered, (he/she/it/you) reminded, (he/she/it/you) mentioned",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordassi",
        "translation": "(I/you) remembered, (I/you) mentioned, (I/you) reminded",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordate",
        "translation": "(you) remember, (you) remind, (you) mention",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordava",
        "translation": "remembered, (he/she/it/you) remembered, (he/she/it/you) used to remember, (he/she/it/you) was/were remembering, (he/she/it/you) was/were reminding",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordavo",
        "translation": "(I) remembered, (I) reminded, (I) mentioned, (I) used to mention, (I) used to remember, (I) used to remind, (I) was/were mentioning, (I) was/were remembering, (I) was/were reminding, remembered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricorderà",
        "translation": "(he/she/it/you) will remember, (he/she/it/you) will mention, (he/she/it/you) will remind, remembered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricorderanno",
        "translation": "(they) will remember, (they) will remind, (they) will mention",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricorderebbe",
        "translation": "(he/she/it/you) would remember, (he/she/it/you) would mention, (he/she/it/you) would remind, remembered",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricorderò",
        "translation": "(I) will remember, (I) will mention, (I) will remind",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordi",
        "translation": "(you) remember, (you) remind, (you) mention",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordò",
        "translation": "(I) remember, (I) remind, (I) mention, keepsake, memento, memory, souvenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ricordo",
        "translation": "(I) remember, (I) remind, (I) mention, keepsake, memento, memory, souvenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ride",
        "translation": "(he/she/it) laughs, (he/she/it/you) not to give a hoot, (you) laugh",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ridere",
        "translation": "laughing, (to) laugh, laughter, (to) not to give a hoot",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ridete",
        "translation": "(you) laugh",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ridono",
        "translation": "(they) laugh, (they) not to give a hoot",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riempi",
        "translation": "(you) fill (up), (you) fill in, (you) complete, fill",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riempiamo",
        "translation": "(we) fill (up), (we) fill in, (we) complete, (we) make out, fill",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riempie",
        "translation": "(he/she/it) fills (up), (he/she/it) fills in, (he/she/it) completes, (he/she/it) makes out, (you) complete, (you) fill (up), (you) fill in, (you) make out, fill",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riempiono",
        "translation": "(they) fill (up), (they) fill in, (they) complete, fill",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riempire",
        "translation": "(to) fill (up), (to) fill in, fill",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riflessione",
        "translation": "reflection, thought, consideration, meditations, reflexion, remark, thinking",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "riflessioni",
        "translation": "remarks, consideration, observations, reflection, reflexion, remark, thinking, thoughts",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rimane",
        "translation": "(he/she/it) remains, (you) are up the spout, (he/she/it) is up the spout, (he/she/it) gets pregnant, (he/she/it) is flabbergasted, (he/she/it) is left, (he/she/it) lives on, (he/she/it) meets one’s end, (he/she/it) stays, (you) are flabbergasted, (you) are left, (you) get pregnant, (you) live on, (you) meet one’s end, (you) remain, (you) stay, continue, lose, remain, remains, stay, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimanendo",
        "translation": "staying, remaining, being left",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimanere",
        "translation": "remain, (to) remain, (to) to remain, (to) be flabbergasted, (to) be left, (to) get pregnant, (to) live on, (to) meet one’s end, (to) stay, (to) to be up the spout, (to) to stay, be up the spout, continue, lose, lost, remained, remains, stay, stayed, stop, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimanesse",
        "translation": "remained, (he/she/it/you) remained, (he/she/it/you) was/were left, (he/she/it/you) stayed, stay",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimanessero",
        "translation": "(they) stayed, (they) remained, (they) was/were left",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimanete",
        "translation": "remain, (you) remain, (you) are left, (you) stay, continue, lose, stay, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimaneva",
        "translation": "(he/she/it/you) was/were left, (he/she/it/you) was/were remaining, (he/she/it/you) used to stay, (he/she/it/you) stayed, lost, remained, stayed, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimanevano",
        "translation": "(they) were remaining, remained, (they) were staying, (they) got pregnant, (they) lived on, (they) met one’s end, (they) remained, (they) stayed, (they) used to be flabbergasted, (they) used to be left, (they) used to be up the spout, (they) used to get pregnant, (they) used to live on, (they) used to meet one’s end, (they) used to remain, (they) used to stay, (they) was/were flabbergasted, (they) was/were left, (they) was/were up the spout, (they) were being flabbergasted, (they) were being left, (they) were being up the spout, (they) were getting pregnant, (they) were living on, (they) were meeting one’s end, lost, stayed, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimanga",
        "translation": "remain, be left, remains, stay",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimangono",
        "translation": "remain, (they) remain, (they) are up the spout, (they) are flabbergasted, (they) are left, (they) get pregnant, (they) live on, (they) meet one’s end, (they) stay, continue, lose, stay, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimani",
        "translation": "(you) stay, (you) remain, (you) are left, continue, lose, remain, stay, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimarrai",
        "translation": "remain, (you) will remain, (you) will stay, (you) will be left, (you) will live on, (you) will meet one’s end, continue, lose, lost, stay, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimarranno",
        "translation": "remain, (they) will remain, (they) will stay, (they) will be left, (they) will live on, (they) will meet one’s end, continue, stay, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimarrebbe",
        "translation": "remain, (he/she/it/you) would remain, (he/she/it/you) would stay, (he/she/it/you) would be left, (he/she/it/you) would live on, (he/she/it/you) would meet one’s end, lose, lost, remained, stayed, stop, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimase",
        "translation": "remained, (he/she/it/you) remained, (he/she/it/you) was/were up the spout, (he/she/it/you) got pregnant, (he/she/it/you) lived on, (he/she/it/you) met one’s end, (he/she/it/you) stayed, (he/she/it/you) was/were flabbergasted, (he/she/it/you) was/were left, lose, lost, stayed, stop, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rimasero",
        "translation": "remained, (they) remained, (they) stayed, (they) was/were left, lost, stayed, stopped",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ringrazia",
        "translation": "(he/she/it) thanks, (you) thank, (he/she/it) says thank you, (you) say thank you, thanks",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ringraziare",
        "translation": "(to) thank, (to) to say thank you, say thank you, thank",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ringrazio",
        "translation": "(I) thank, (I) say thank you, thank",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ripara",
        "translation": "(he/she/it) repairs, (he/she/it) protects, (you) protect, right",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ripariamo",
        "translation": "(we) repair, (we) protect, (we) screen, right",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riparo",
        "translation": "(I) repair, (I) fix, (I) screen, (I) protect, right",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ripetere",
        "translation": "repeat, to repeat",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riposa",
        "translation": "(he/she/it) rests, (you) rest, rests",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riposare",
        "translation": "(to) rest, (to) to rest up, rest up, (to) have a rest, (to) lie untilled, (to) place oneself again, (to) put down [something] again, (to) stand, (to) to lie fallow, (to) to repose, (to) to take a rest, lie fallow, repose, rest, take a rest",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riposiamo",
        "translation": "(we) rest, (we) rest up, (we) have a rest, rest",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riposo",
        "translation": "(I) rest, (I) rest up, (I) have a rest, rest",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risata",
        "translation": "laugh",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "riscaldamento",
        "translation": "heating, warming, heating up, heat, warm-up",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rischi",
        "translation": "risks, (you) face, (you) risk, (you) hazard, (you) look at, (you) stake, at risk, chance, exposures, peril",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "riso",
        "translation": "rice, laughed, laughter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "risorsa",
        "translation": "resource, means, resort",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "risorse",
        "translation": "resources, (he/she/it/you) resurrected, (he/she/it/you) rose, (he/she/it/you) rose again",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rispetto",
        "translation": "(I) respect, with respect, (I) respect oneself, keep, respect",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "risponda",
        "translation": "answer, answer for, answer back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risponde",
        "translation": "(he/she/it) answers, (he/she/it) answers for, (he/she/it) answers back, (he/she/it) replies, (he/she/it) responds, (he/she/it) returns, (he/she/it) talks back, (you) answer, (you) answer back, (you) reply, (you) respond, (you) talk back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispondendo",
        "translation": "responding, answering, answering for, answering back, replying",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risponderà",
        "translation": "(he/she/it/you) will respond, (he/she/it/you) will answer, (he/she/it/you) will talk back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispondere",
        "translation": "(to) answer, (to) respond, (to) to answer back, (to) answer for, answer, answer back, answers, reply, strike, talk, wrote",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risponderebbe",
        "translation": "(he/she/it/you) would respond, (he/she/it/you) would answer, (he/she/it/you) would talk back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risponderebbero",
        "translation": "(they) would reply, (they) would answer, (they) would talk back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risponderei",
        "translation": "(I) would respond, (I) would answer, (I) would talk back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risponderemo",
        "translation": "(we) will respond, (we) will reply, (we) will answer, (we) will talk back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispondesse",
        "translation": "(he/she/it/you) responded, (he/she/it/you) replied, (he/she/it/you) answered, (he/she/it/you) talked back, answer, respond",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispondessi",
        "translation": "(I/you) answered, (I/you) replied, (I/you) talked back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispondeva",
        "translation": "(he/she/it/you) was/were answering, (he/she/it/you) would answer, (he/she/it/you) was/were answering for, (he/she/it/you) answered, (he/she/it/you) answered back, (he/she/it/you) answered for, (he/she/it/you) mouthed off, (he/she/it/you) replied, (he/she/it/you) responded, (he/she/it/you) returned, (he/she/it/you) talked back, (he/she/it/you) used to answer, (he/she/it/you) used to answer back, (he/she/it/you) used to answer for, (he/she/it/you) used to mouth off, (he/she/it/you) used to reply, (he/she/it/you) used to respond, (he/she/it/you) used to return, (he/she/it/you) used to talk back, (he/she/it/you) was/were answering back, (he/she/it/you) was/were mouthing off, (he/she/it/you) was/were replying, (he/she/it/you) was/were responding, (he/she/it/you) was/were returning, (he/she/it/you) was/were talking back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispondi",
        "translation": "(you) answer, (you) answer back, (you) answer for, (you) mouth off, (you) reply, (you) respond, (you) return, (you) talk back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispondo",
        "translation": "(I) answer, (I) answer for, (I) answer back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispondono",
        "translation": "(they) answer, (they) answer back, (they) answer for, answer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rispose",
        "translation": "(he/she/it/you) answered, (he/she/it/you) answered for, (he/she/it/you) answered back, (he/she/it/you) replied, (he/she/it/you) talked back",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risposta",
        "translation": "answer, response, reply, answers",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ristorante",
        "translation": "restaurant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "risultati",
        "translation": "results, result, resulting, returns",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "risultato",
        "translation": "result, accomplishment, appeared, come out, ensued, issue, outcome, proved/proven, resulted, score, seemed, turned out",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ritardo",
        "translation": "(I) am late, (I) delay, (I) am slow, (I) hold back, (I) put back, (I) put off, (I) set back, (I) slow down, lateness, retardation",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ritmi",
        "translation": "rhythms, (you) pattern",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ritmo",
        "translation": "rhythm, (I) mark (the rhythm) of, (I) pattern, beat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ritorna",
        "translation": "(he/she/it) returns, (you) return, (he/she/it) gives back, return, returns",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ritornare",
        "translation": "(to) return, (to) to return, (to) to give back, (to) become again, (to) come back, (to) get back, (to) go back, (to) recur, came, come, give back, go, gone, got, return, returns, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ritorni",
        "translation": "(you) return, (you) give back, (you) get back",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ritorno",
        "translation": "(I) return, (I) get back, (I) give back, return, returns",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "riunione",
        "translation": "meeting, gathering, meet, reunion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "riunioni",
        "translation": "gathering, meetings, reunions, reunion, gatherings, meeting",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rivista",
        "translation": "magazine, inspection, magazines, review",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "riviste",
        "translation": "magazines, inspection, parade, review",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rivoluzionari",
        "translation": "revolutionaries",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rivoluzionario",
        "translation": "revolutionary",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rivoluzione",
        "translation": "revolution, mess",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rocce",
        "translation": "rocks, rock climbing",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "roccia",
        "translation": "rock, rock climbing",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "romanzi",
        "translation": "novels, (you) fictionalize, (you) novelize, (you) romanticize",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "romanzo",
        "translation": "novel, (I) romanticize, (I) fictionalize, (I) novelize, Romanic, fiction, love affair, romance",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rompe",
        "translation": "(you) break, (you) break off, (he/she/it) breaks, (he/she/it) breaks off, (he/she/it) bursts, (he/she/it) cracks, (he/she/it) ends, (he/she/it) is a pain in the neck, (he/she/it) is fed up, (he/she/it) is sick and tired, (he/she/it) is tired (to death), (he/she/it) rips, (he/she/it) tears, (he/she/it) upsets, (you) are a pain in the neck, (you) are fed up, (you) are sick and tired, (you) are tired (to death), (you) burst, (you) crack, (you) end, (you) rip, (you) tear, (you) upset",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rompere",
        "translation": "(to) break, (to) break off, (to) to tear, (to) be a pain in the neck, (to) be fed up, (to) be sick and tired, (to) be tired (to death), (to) burst, (to) crack, (to) end, (to) rip, (to) to crack, (to) upset, broke, cut, tear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rompono",
        "translation": "(they) break, (they) break off, (they) crack, cut",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rosa",
        "translation": "pink, rose, romantic",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rossa",
        "translation": "red, redhead",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rosse",
        "translation": "red-haired person, red, redhead",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rossi",
        "translation": "red",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rosso",
        "translation": "red, red wine",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ruba",
        "translation": "(he/she/it) steals, (you) steal",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rubare",
        "translation": "(to) steal, (to) to snatch, (to) to burglarize, (to) burgle, (to) snatch, burglarize",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rumore",
        "translation": "noise, sound, din",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rumorosa",
        "translation": "noisy, loud",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rumorose",
        "translation": "noisy, loud",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rumorosi",
        "translation": "noisy, loud",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ruoli",
        "translation": "position, part, role",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ruolo",
        "translation": "role, jobs, part, position",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ruota",
        "translation": "wheel, (he/she/it) revolves, (he/she/it) rolls, (he/she/it) rotates, (he/she/it) spins, (you) revolve, (you) roll, (you) rotate, (you) spin, cartwheel, lottery drum, rack, revolving door",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ruote",
        "translation": "wheels, (he/she/it) rotates, (he/she/it) revolves, (he/she/it) rolls, (he/she/it) spins, (you) revolve, (you) roll, (you) rotate, (you) spin, cartwheel, lottery drum, rack, revolving door, wheel",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sa",
        "translation": "know, (he/she/it) knows, (you) know, (he/she/it) hears, (he/she/it) is able to, (he/she/it) learns, (he/she/it) smells, (he/she/it) tastes, (he/she/it/you) can, (you) are able to, (you) hear, (you) learn, (you) smell, (you) taste, knew, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabato",
        "translation": "Saturday, on Saturday",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sabbia",
        "translation": "sand, urinary sand, (he/she/it) sandblasts, (you) sandblast, grit",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sai",
        "translation": "know, (you) know, (you) can, (you) are able to, (you) hear, (you) learn, (you) savor, (you) savour, (you) smell, (you) taste, frock, habits, knew, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sala",
        "translation": "hall, room, sitting room, living room",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sale",
        "translation": "salt, (you) come in, (he/she/it) climbs, (he/she/it) comes up, (he/she/it) goes up, (he/she/it) goes uphill, (he/she/it) mounts, (he/she/it) moves up, (he/she/it) rises (up), (he/she/it) rises up, (he/she/it) surges, (you) come up, (you) go up, (you) go uphill, (you) mount, (you) move up, (you) rise (up), (you) rise up, (you) surge, mother wit, piquancy, sal, wit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "salsiccia",
        "translation": "(pork) sausage, banger",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "saltare",
        "translation": "(to) jump (over), (to) to clear, (to) to spring, (to) be cancelled, (to) be dismissed, (to) blow, (to) chip (away), (to) come off, (to) come out, (to) come up, (to) come up with, (to) miss (out), (to) pop (off), (to) sauté, (to) skip, (to) to be canceled, (to) to be removed from power, (to) to come off, (to) to crop up, (to) to hop (over), (to) to leap (over), (to) to leave out, (to) to miss, (to) to pop (off), (to) to pop up, (to) to toss, (to) turn up, be canceled, be removed from power, broke, broken, clear, crop up, hop (over), hopes, leap (over), leave out, missed, pop up, spring, toss",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salute",
        "translation": "health, bless you (after sneeze)",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "salva",
        "translation": "(he/she/it) saves, (he/she/it) rescues, (you) save",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salvano",
        "translation": "(they) save, (they) protect, (they) rescue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salvare",
        "translation": "(to) save, (to) save oneself, (to) to protect, (to) defend oneself, (to) to protect oneself, (to) to redeem, (to) to rescue, (to) to salvage, protect, protect oneself, redeem, rescue, salvage",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salve",
        "translation": "hello",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "salviamo",
        "translation": "(we) save, (we) protect, (we) rescue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salvo",
        "translation": "(I) save, (I) protect, (I) rescue, except, safe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sana",
        "translation": "healthy, (he/she/it) is healed, (he/she/it) balances, (he/she/it) cures, (he/she/it) heals (up), (he/she/it) makes up, (he/she/it) reclaims, (you) are healed, (you) balance, (you) cure, (you) heal (up), (you) make up, (you) reclaim, be healed",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sandali",
        "translation": "sandals, sandal, sandalwood",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sandalo",
        "translation": "sandal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sangue",
        "translation": "blood",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sano",
        "translation": "healthy, (I) cure, (I) reclaim, (I) am healed, (I) balance, (I) heal (up), (I) make up, good, health-giving, healthful, intact, right, robust, sound, whole, wholesome",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "santa",
        "translation": "holy, saint, saint’s day, godly person, patron (saint)",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sapendo",
        "translation": "knowing, being able to, can, hearing, learning, savoring, savouring, smelling, tasting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapere",
        "translation": "know, (to) know, (to) to learn, (to) hear, (to) smell, (to) taste, (to) to be able to, (to) to savor, (to) to savour, be able to, can, erudition, hear, knew, knowledge, known, learn, learning, savor, savour",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapesse",
        "translation": "knew, (he/she/it/you) knew, (he/she/it/you) was/were able to, (he/she/it/you) heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapessi",
        "translation": "(I/you) knew, (I/you) savoured, (I/you) tasted, (I/you) heard, (I/you) learned/learnt, (I/you) savored, (I/you) smelt/smelled, (I/you) was/were able to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapessimo",
        "translation": "know",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapeste",
        "translation": "knew, (you) knew, (you) heard, (you) was/were able to, know",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapeva",
        "translation": "knew, know, knew how to, (he/she/it/you) heard, (he/she/it/you) knew, (he/she/it/you) learned/learnt, (he/she/it/you) savored, (he/she/it/you) savoured, (he/she/it/you) smelt/smelled, (he/she/it/you) tasted, (he/she/it/you) used to be able to, (he/she/it/you) used to hear, (he/she/it/you) used to know, (he/she/it/you) used to learn, (he/she/it/you) used to savor, (he/she/it/you) used to savour, (he/she/it/you) used to smell, (he/she/it/you) used to taste, (he/she/it/you) was/were able to, (he/she/it/you) was/were being able to, (he/she/it/you) was/were hearing, (he/she/it/you) was/were knowing, (he/she/it/you) was/were learning, (he/she/it/you) was/were savoring, (he/she/it/you) was/were savouring, (he/she/it/you) was/were smelling, (he/she/it/you) was/were tasting, could, was able to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapevo",
        "translation": "know, (I) knew, knew, (I) heard, (I) learned/learnt, (I) savored, (I) savoured, (I) smelt/smelled, (I) tasted, (I) used to be able to, (I) used to hear, (I) used to know, (I) used to learn, (I) used to savor, (I) used to savour, (I) used to smell, (I) used to taste, (I) was/were able to, (I) was/were being able to, (I) was/were hearing, (I) was/were knowing, (I) was/were learning, (I) was/were savoring, (I) was/were savouring, (I) was/were smelling, (I) was/were tasting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapone",
        "translation": "soap",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sappi",
        "translation": "know, be able to, can",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sappia",
        "translation": "know, be able to, (he/she) knows, can",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sappiamo",
        "translation": "know, (we) know, (we) are able to, (we) can, knew",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saprà",
        "translation": "know, (he/she/it/you) will know, (he/she/it/you) will hear, (he/she/it/you) will be able to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapranno",
        "translation": "know, (they) will know, (they) will be able to, (they) will hear, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saprebbe",
        "translation": "know, (he/she/it/you) would know, (he/she/it/you) would learn, (he/she/it/you) would be able to, (he/she/it/you) would hear, knew, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saprei",
        "translation": "know, (I) would know, (I) would learn, (I) would be able to, (I) would hear, knew, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sapremo",
        "translation": "know, (we) will know, (we) will hear, (we) will be able to, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saprete",
        "translation": "know, (you) will know, (you) will hear, (you) will be able to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saprò",
        "translation": "know, (I) will know, (I) will hear, (I) will be able to, known",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sarà",
        "translation": "(he/she/it/you) will be, (he/she/it) probably is, (he/she/it] must be, be, feel, felt, was",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sarai",
        "translation": "(you) will be, be, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saranno",
        "translation": "(they) will be, felt, be",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sarebbe",
        "translation": "(he/she/it/you) would be, were, was, be, felt",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sarei",
        "translation": "(I) would be, were, be, feel, was",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saremo",
        "translation": "(we) will be, be, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sarò",
        "translation": "(I) will be, (I) am going to be, be, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sbagliata",
        "translation": "wrong, be wrong, been wrong",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sbagliato",
        "translation": "wrong, been wrong, bad, been mistaken, false, made a mistake, missed",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "scala",
        "translation": "ladder, staircase, (you) scale, (he/she/it) changes down, (he/she/it) climbs, (he/she/it) deducts, (he/she/it) layers, (he/she/it) scales, (he/she/it) takes off, (he/she/it/you) downshift, (you) change down, (you) climb, (you) deduct, (you) layer, (you) take off, run, runs, stairs, stairway, straight",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scale",
        "translation": "stairs, (you) take off, (he/she/it) changes down, (he/she/it) climbs, (he/she/it) deducts, (he/she/it) layers, (he/she/it) scales, (he/she/it) takes off, (he/she/it/you) downshift, (you) change down, (you) climb, (you) deduct, (you) layer, (you) scale, ladder, staircase, stairway, straight",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scarpa",
        "translation": "shoe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scarpe",
        "translation": "shoes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scatola",
        "translation": "(cardboard) box, boxful, can, can't, cannot, carton, could, tin",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scatole",
        "translation": "boxes, (cardboard) box, carton",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scegli",
        "translation": "(you) choose, (you) pick, (you) elect, (you) decide on, (you) draft, (you) select, (you) single out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sceglie",
        "translation": "(he/she/it) chooses, (he/she/it) picks, (he/she/it) drafts, (he/she/it) decides on, (he/she/it) elects, (he/she/it) selects, (he/she/it/you) single out, (you) choose, (you) decide on, (you) draft, (you) elect, (you) pick, (you) select",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scegliere",
        "translation": "(to) choose, (to) to elect, (to) pick, (to) draft, (to) select, (to) to decide on, (to) to single out, decide, decide on, elect, go, gone, single out, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scena",
        "translation": "scene, stage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "schermo",
        "translation": "screen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "schiena",
        "translation": "back, croup",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sciampi",
        "translation": "shampoos, shampoo bottles, shampoo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sciampo",
        "translation": "shampoo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "scienza",
        "translation": "science, knowledge",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scienze",
        "translation": "sciences",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scienziata",
        "translation": "scientist",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scienziate",
        "translation": "scientists, scientist",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scienziati",
        "translation": "scientists, scientist",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "scienziato",
        "translation": "scientist",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "scimmia",
        "translation": "monkey, ape",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sciopero",
        "translation": "strike, (I) strike, (I) go out, (I) walk out",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sconti",
        "translation": "discounts, (you) do, (you) deduct, (you) discount, (you) rebate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sconto",
        "translation": "(I) discount, (I) deduct, (I) rebate, discounts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "scoperta",
        "translation": "discovery, detection, find",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scorsa",
        "translation": "last, browse, glance, look",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "scorso",
        "translation": "last, flicked through, gone through, scanned, scrolled",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "scrisse",
        "translation": "wrote, (he/she/it/you) wrote, (he/she/it/you) wrote down, (he/she/it/you) spelled, write",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrissi",
        "translation": "wrote, (I) wrote, (I) wrote down, (I) spelled",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrittore",
        "translation": "writer, author",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "scrittrice",
        "translation": "writer, author, authoress, writers",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scriva",
        "translation": "write, write down, write (to) each other, spell",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrivania",
        "translation": "desk, writing desk",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scrive",
        "translation": "(you. formal) write, he/she writes, writes, wrote",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrivendo",
        "translation": "writing, writing down, writing (to) each other, spelling",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriverà",
        "translation": "write, (he/she/it/you) will write, (he/she/it/you) will write down, (he/she/it/you) will write (to) each other",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriverai",
        "translation": "write, (you) will write, (you) will write down, (you) will write (to) each other",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrivere",
        "translation": "write, (to) write, (to) to write, (to) spell, (to) write (to) each other, (to) write down, fill, got, put, puts, types, words, writing, wrote",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriverebbe",
        "translation": "write, (he/she/it/you) would write, (he/she/it/you) would write down, (he/she/it/you) would write (to) each other",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriverebbero",
        "translation": "write, (they) would write, (they) would write down, (they) would write (to) each other",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriverei",
        "translation": "write, (I) would write, (I) would write down, (I) would spell, (I) would write (to) each other",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriveremo",
        "translation": "write, (we) will write, (we) will write down, (we) will write (to) each other",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriveresti",
        "translation": "(you) would write, (you) would write down, (you) would write (to) each other",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriverò",
        "translation": "(I) will write",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrivesse",
        "translation": "(he/she) was writing, (he/she/it/you) spelled, (he/she/it/you) wrote, (he/she/it/you) wrote down, wrote",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrivete",
        "translation": "(you pl.) write",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriveva",
        "translation": "wrote, (he/she/it/you) was/were writing, (he/she/it/you) wrote, (he/she) would write, (he/she/it/you) used to write, (he/she/it/you) was/were writing (to) each other, (he/she/it/you) was/were writing down, (he/she/it/you) wrote down",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrivi",
        "translation": "write, (you) write, (you) write down, wrote",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scriviamo",
        "translation": "(we) write, (we) write down, (we) write (to) each other, (we) spell, wrote",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrivo",
        "translation": "(I) write",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scrivono",
        "translation": "(they) write, are writing, write",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scuola",
        "translation": "school",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scuole",
        "translation": "schools",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scura",
        "translation": "dark",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "scure",
        "translation": "dark",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "scuri",
        "translation": "dark",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "scusa",
        "translation": "excuse me, sorry",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scusare",
        "translation": "apologize, (to) excuse, (to) to apologize, (to) excuse oneself, excuse, justify",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scusate",
        "translation": "(you) excuse, (you) excuse oneself, (you) justify, (you) apologize, (you) say sorry, excuse me",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scusi",
        "translation": "excuse me, sorry",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "se",
        "translation": "if, whether, (impersonal) you",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "secca",
        "translation": "dry, (he/she/it) gets dry, (he/she/it) annoys, (he/she/it) bakes, (he/she/it) bothers, (he/she/it) cures, (he/she/it) dries, (he/she/it) dries out, (he/she/it) dries up, (he/she/it) gets annoyed, (he/she/it) gets bothered, (he/she/it) hardens, (he/she/it) irks, (he/she/it) scorches, (he/she/it) withers, (he/she/it/you) annoyed, (he/she/it/you) desiccate, (he/she/it/you) wizen, (you) annoy, (you) bake, (you) bother, (you) cure, (you) get annoyed, (you) get bothered, (you) harden, (you) irk, (you) scorch, (you) wither, die, dried, dries, dryness, riffle, shallows, shoal",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "secchi",
        "translation": "(you) dry, (you) get dry, (you) dry up, (you) dry out, dries, dry",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "secoli",
        "translation": "centuries, century",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "secolo",
        "translation": "century, centuries",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "seconda",
        "translation": "second (gear), second form, second year, (he/she/it) seconds, channel two, depending on, depending on whether, economy class, latter, second, second course, second position, seconde",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "secondi",
        "translation": "seconds, (you) second, second",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "secondo",
        "translation": "second course, according to, depending on, in compliance with, latter, second",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sedere",
        "translation": "(to) sit (down), can, cannot, be seated, be sitting, behind, can't, could",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sedia",
        "translation": "chair, seat",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sediamo",
        "translation": "(we) sit (down), (we) are sitting, be sitting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sedici",
        "translation": "sixteen, four in the afternoon",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "sedie",
        "translation": "chairs, chair, seat",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "seggiola",
        "translation": "chair, seat",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "seggiole",
        "translation": "chairs, chair",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "segnale",
        "translation": "signal, (road)sign, warning",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "segno",
        "translation": "sign, as a sign of, mark, (I) mark, (I) mark out, (I) write [something] down, as a mark of, enter, produce, symbols",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "segretaria",
        "translation": "secretary, secretaries",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "segretario",
        "translation": "secretary, committee secretary, leader",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "segreto",
        "translation": "secret, secret mechanism, concealed, secrecy, secretive",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "segua",
        "translation": "follow (up), follow up, accompany",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguano",
        "translation": "follow (up), follow up, keep to, accompany, act on, attend, be on, come after, go after, go on, hug, keep up with, monitor, run alongisde, take, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "segue",
        "translation": "(he/she/it) follows (up), (he/she/it) follows up, (you) follow (up), (he/she/it) accompanies, (he/she/it) acts on, (he/she/it) attends, (he/she/it) comes after, (he/she/it) goes after, (he/she/it) goes on, (he/she/it) hugs, (he/she/it) is on, (he/she/it) keeps to, (he/she/it) keeps up with, (he/she/it) monitors, (he/she/it) runs alongisde, (he/she/it) takes, (he/she/it) watches, (you) accompany, (you) act on, (you) are on, (you) attend, (you) come after, (you) follow up, (you) go after, (you) go on, (you) hug, (you) keep to, (you) keep up with, (you) monitor, (you) run alongisde, (you) take, (you) watch, follow",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguendo",
        "translation": "following (up), following up, accompanying, following, watching",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguente",
        "translation": "following, the following one, the next one, next",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "seguenti",
        "translation": "following, the following one, next",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "segui",
        "translation": "(you) follow (up), (you) follow up, (you) take, (you) accompany, (you) act on, (you) are on, (you) attend, (you) come after, (you) go after, (you) go on, (you) hug, (you) keep to, (you) keep up with, (you) monitor, (you) run alongisde, (you) watch, be on, follow, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguì",
        "translation": "(you) follow (up), (you) follow up, (you) take, (you) accompany, (you) act on, (you) are on, (you) attend, (you) come after, (you) go after, (you) go on, (you) hug, (you) keep to, (you) keep up with, (you) monitor, (you) run alongisde, (you) watch, be on, follow, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguiamo",
        "translation": "(we) follow (up), (we) follow up, (we) accompany",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguiate",
        "translation": "(you) follow, follow",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguirà",
        "translation": "(he/she/it/you) will follow (up), (he/she/it/you) will follow up, (he/she/it/you) will keep to, (he/she/it/you) will act on, (he/she/it/you) will be on, (he/she/it/you) will go on, (he/she/it/you) will hug, (he/she/it/you) will keep up with, (he/she/it/you) will take, follow",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguirai",
        "translation": "(you) will take, (you) will follow (up), (you) will keep to, (you) will accompany, (you) will act on, (you) will be on, (you) will follow up, (you) will go on, (you) will hug, (you) will keep up with, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguiranno",
        "translation": "(they) will follow (up), (they) will follow up, (they) will keep to, (they) will accompany, (they) will act on, (they) will be on, (they) will go on, (they) will hug, (they) will keep up with, (they) will take",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguire",
        "translation": "follow (up), (to) to follow (up), (to) follow up, (to) accompany, (to) act on, (to) be on, (to) come after, (to) go after, (to) go on, (to) keep to, (to) keep up with, (to) run alongisde, (to) to attend, (to) to hug, (to) to monitor, (to) to take, (to) to watch, (to) watch, attend, carried, carry, follow, hug, keep, kept, monitor, take, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguirebbe",
        "translation": "(he/she/it/you) would follow (up), (he/she/it/you) would follow up, (he/she/it/you) would take, (he/she/it/you) would act on, (he/she/it/you) would be on, (he/she/it/you) would go on, (he/she/it/you) would hug, (he/she/it/you) would keep to, (he/she/it/you) would keep up with, follow, watch, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguirebbero",
        "translation": "(they) would follow (up), (they) would follow up, (they) would hug, (they) would act on, (they) would be on, (they) would go on, (they) would keep to, (they) would keep up with, (they) would take",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguirei",
        "translation": "(I) would follow (up), (I) would follow up, (I) would take, (I) would act on, (I) would be on, (I) would go on, (I) would hug, (I) would keep to, (I) would keep up with",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguiremo",
        "translation": "(we) will follow (up), (we) will follow up, (we) will take, (we) will act on, (we) will be on, (we) will go on, (we) will hug, (we) will keep to, (we) will keep up with, follow, followed, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguirò",
        "translation": "(I) will follow (up), (I) will keep to, (I) will follow up, (I) will act on, (I) will be on, (I) will go on, (I) will hug, (I) will keep up with, (I) will take, follow, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguirono",
        "translation": "(they) followed (up), (they) followed up, (they) accompanied, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguisse",
        "translation": "(he/she/it/you) were following, (he/she/it/you) accompanied, (he/she/it/you) followed (up), (he/she/it/you) went after",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguissero",
        "translation": "(they) was/were on, (they) accompanied, (they) followed (up)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguite",
        "translation": "(you) follow (up), (you) follow up, (you) accompany, follow",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguiva",
        "translation": "(he/she/it/you) was/were following (up), (he/she/it/you) was/were following up, (he/she/it/you) followed (up), (he/she/it/you) followed up, (he/she/it/you) used to follow (up), watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguivamo",
        "translation": "(we) were following (up), (we) were following up, (we) was/were on, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguivano",
        "translation": "(they) were following (up), (they) were following up, (they) was/were on, followed, watched",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sei",
        "translation": "six, are, are you, be, feel, you are",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "selvaggi",
        "translation": "wild, fierce, primitive, savages, sprawling, uncivilized, unruly",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "selvaggio",
        "translation": "wild, fierce, primitive, savage, sprawling, uncivilized, unruly",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sembra",
        "translation": "(he/she/it) seems, (he/she/it) looks, (he/she/it) looks like, (he/she/it) feels like, (he/she/it) smells like, (he/she/it) sounds, (you) feel like, (you) look, (you) look like, (you) seem, (you) smell like, (you) sound, feel, seems",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sembrano",
        "translation": "(they) look, (they) seem, (they) look like, (they) feel like, (they) smell like, (they) sound, feel, look",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "semestre",
        "translation": "semester, term, half-year",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "semplice",
        "translation": "simple, simple person, easy, plain, single, straightforward",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "semplicemente",
        "translation": "simply",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "semplici",
        "translation": "easy, simple, single",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sempre",
        "translation": "always, still",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "senatore",
        "translation": "senator",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "senatori",
        "translation": "senatorial",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "senatrice",
        "translation": "(female) senator",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sensazione",
        "translation": "feeling, impression, sensation, sensational",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "senso",
        "translation": "sense, meaning, feeling, sensation",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "senta",
        "translation": "feel, taste, smell",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentano",
        "translation": "feel, feel like, feel up to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sente",
        "translation": "(he/she/it) feels, (he/she/it) hears, feel, (he/she/it) feels like, (he/she/it) feels up to, (he/she/it) knows, (he/she/it) listens to, (he/she/it) smells, (he/she/it) tastes, (you) feel, (you) feel like, (you) feel up to, (you) hear, (you) know, (you) listen to, (you) smell, (you) taste, hear, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentendo",
        "translation": "hearing, feeling, feeling like, feeling up to, smelling",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "senti",
        "translation": "(you) feel, (you) hear, (you) feel like, (you) feel up to, (you) smell, (you) taste, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentiamo",
        "translation": "feel, (we) feel, hear, (we) feel like, (we) feel up to, (we) hear, (we) know, (we) listen to, (we) smell, (we) taste, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentii",
        "translation": "(I) heard, (I) felt, (I) smelt/smelled, feel, felt, hear, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentimenti",
        "translation": "feelings, emotion, emotions, feeling",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sentimento",
        "translation": "feeling, consciousness, emotion",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sentimmo",
        "translation": "(we) heard, (we) felt, (we) smelt/smelled, felt, hear, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentirai",
        "translation": "hear, feel, (you) will hear, (you) will feel, (you) will feel like, (you) will feel up to, (you) will know",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentiranno",
        "translation": "feel, (they) will feel, (they) will feel up to, (they) will feel like, (they) will hear, (they) will know, hear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentire",
        "translation": "hear, (to) hear, (to) feel, (to) feel like, (to) feel up to, (to) know, (to) listen to, (to) smell, (to) taste, feel, feeling, felt, heard, sentiment",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentirebbe",
        "translation": "feel, (he/she/it/you) would feel, (he/she/it/you) would feel like, (he/she/it/you) would feel up to, (he/she/it/you) would hear, (he/she/it/you) would know, caught, felt, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentirei",
        "translation": "feel, (I) would feel, (I) would feel like, (I) would feel up to, (I) would hear, (I) would know, (I) would listen to, (I) would smell, (I) would taste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentireste",
        "translation": "feel, (you) would feel, (you) would feel like, (you) would feel up to, (you) would hear, (you) would know",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentirò",
        "translation": "(I) will feel, (I) will feel up to, (I) will feel like, (I) will hear, (I) will know, (I) will smell, (I) will taste, Am I going to feel, feel, hear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentisse",
        "translation": "(he/she/it/you) felt, (he/she/it/you) felt like, (he/she/it/you) felt up to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentissi",
        "translation": "(I/you) heard, (I/you) felt, (I/you) smelt/smelled",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentiva",
        "translation": "felt, (he/she/it/you) felt, (he/she/it/you) felt like, (he/she/it/you) felt up to, (he/she/it/you) heard, (he/she/it/you) knew, (he/she/it/you) listened to, (he/she/it/you) smelt/smelled, (he/she/it/you) tasted, (he/she/it/you) used to feel, (he/she/it/you) used to feel like, (he/she/it/you) used to feel up to, (he/she/it/you) used to hear, (he/she/it/you) used to know, (he/she/it/you) used to listen to, (he/she/it/you) used to smell, (he/she/it/you) used to taste, (he/she/it/you) was/were feeling, (he/she/it/you) was/were feeling like, (he/she/it/you) was/were feeling up to, (he/she/it/you) was/were hearing, (he/she/it/you) was/were knowing, (he/she/it/you) was/were listening to, (he/she/it/you) was/were smelling, (he/she/it/you) was/were tasting, caught, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentivamo",
        "translation": "felt, (we) felt, (we) felt like, (we) felt up to, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentivi",
        "translation": "(you) used to feel, (you) used to feel like, (you) used to feel up to, feel, felt, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentivo",
        "translation": "(I) was/were feeling, (I) felt, (I) was/were feeling like, (I) felt like, (I) felt up to, (I) heard, (I) knew, (I) listened to, (I) smelt/smelled, (I) tasted, (I) used to feel, (I) used to feel like, (I) used to feel up to, (I) used to hear, (I) used to know, (I) used to listen to, (I) used to smell, (I) used to taste, (I) was/were feeling up to, (I) was/were hearing, (I) was/were knowing, (I) was/were listening to, (I) was/were smelling, (I) was/were tasting, caught, felt, heard",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sento",
        "translation": "(I) feel, hear, (I) feel like, (I) feel up to, (I) hear, (I) listen to, (I) smell, (I) taste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "senz'",
        "translation": "without",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "senza",
        "translation": "without, except, out of",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "seppe",
        "translation": "knew, (he/she/it/you) knew, (he/she/it/you) heard, (he/she/it/you) was/were able to, know",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seppero",
        "translation": "know, (they) heard, (they) knew, (they) was/were able to, knew",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sera",
        "translation": "evening, night, even, evenings, nights",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "seria",
        "translation": "serious",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "serie",
        "translation": "serious, series, division, round, runs",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "serpente",
        "translation": "snake, serpent, snakeskin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "servizi",
        "translation": "services, favour favor, service",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "servizio",
        "translation": "service, service industry, department, favour favor, feature, report, serve",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sessant'",
        "translation": "sixty",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "sessanta",
        "translation": "sixty, sixties",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "sesto",
        "translation": "sixth, curve",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "settant'",
        "translation": "seventy",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "settanta",
        "translation": "seventy, seventies",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "sette",
        "translation": "seven, seventh",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "settembre",
        "translation": "September",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "settima",
        "translation": "seventh",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "settimana",
        "translation": "week, week’s wages",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "settimanalmente",
        "translation": "weekly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "settimane",
        "translation": "weeks, week, week’s wages",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "settimo",
        "translation": "seventh",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sezione",
        "translation": "section, division of classes in groups, over a whole cycle of studies, branch, bureau, department, dissection, distinguished by letters, division, part",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sforzi",
        "translation": "efforts, (you) force, (you) strain",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sforzo",
        "translation": "effort, (I) force, (I) strain",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sì",
        "translation": "yes",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "si",
        "translation": "yes",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "sia",
        "translation": "is, both, and, (he/she/it) is, be, has",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siamo",
        "translation": "(we) are, be, feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siano",
        "translation": "be, (they) are",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sicura",
        "translation": "sure, certain, secure, comfortable, safe",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sicuramente",
        "translation": "definitely, certainly, surely, safely",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "sicurezza",
        "translation": "security, safety, (self-)assurance, (self-)confidence, assertiveness, certainty, certitude",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sicuro",
        "translation": "sure, for sure, safe, assured, certain, for certain, reliable, secure, sound, steady, straight",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "siede",
        "translation": "sit, (he/she/it) sits (down), (you) sit (down), (he/she/it) is sitting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siete",
        "translation": "be, you (pl.) are",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "significa",
        "translation": "it means",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "significano",
        "translation": "mean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "significare",
        "translation": "(to) mean, (to) represent, imply, mean, meant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "significati",
        "translation": "represent, imply, meanings, mean",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "significato",
        "translation": "meaning, implied, meant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "signora",
        "translation": "lady, madam, woman",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "signore",
        "translation": "gentleman, ladies, sir",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "signori",
        "translation": "gentlemen, sir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "silenzio",
        "translation": "silence, quiet, quietness, still",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "silenziosa",
        "translation": "silent, quiet, still",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "silenzioso",
        "translation": "silent, quiet",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "simboli",
        "translation": "symbols, emblem, sign, symbol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "simbolo",
        "translation": "symbol, emblem, sign, symbols",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "simile",
        "translation": "similar, such, alike, kindred, like",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "simili",
        "translation": "similar, alike, fellow creature, kindred, such",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "simpatica",
        "translation": "nice, pleasant",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "simpatici",
        "translation": "nice, autonomic, good, likeable, pleasant, sympathetic, sympathetic nervous system",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "simpatico",
        "translation": "nice, likeable, pleasant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sinceramente",
        "translation": "honestly, frankly, sincerely",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "sindacati",
        "translation": "unions, labor unions",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sindacato",
        "translation": "trade union, labor union, controlled, ring",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sindaco",
        "translation": "mayor, (I) control, (I) inspect",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sinistra",
        "translation": "left, ports",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sistema",
        "translation": "system, system perm, (he/she/it) arranges, form",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sistemi",
        "translation": "systems",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "situazione",
        "translation": "situation, position",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "situazioni",
        "translation": "situations, position, situation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "so",
        "translation": "(I) know, knew",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "società",
        "translation": "(high) society, company, companies, association, club",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "soddisfazione",
        "translation": "satisfaction, joy, pleasure",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "soddisfazioni",
        "translation": "satisfactions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sofà",
        "translation": "couch, sofa, couches, settee",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soffice",
        "translation": "soft, fluffy",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "soffici",
        "translation": "soft, fluffy",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "soggetto",
        "translation": "subject, subject matter, dependent",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sognare",
        "translation": "(to) dream (of), (to) dream of, (to) to imagine, (to) think, (to) to be kidding oneself, (to) to long for, be kidding oneself, imagine, long for",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sogni",
        "translation": "(you) imagine, (you) think, (you) dream of, (you) are kidding oneself, (you) dream (of), (you) long for, be kidding oneself, dreams",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sogno",
        "translation": "(I) dream (of), (I) dream of, (I) think, (I) am kidding oneself, (I) dream, (I) imagine, (I) long for",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sola",
        "translation": "alone, lonely, (you) sole, (he/she/it) soles, only",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "solamente",
        "translation": "only, just",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "soldi",
        "translation": "money, pay, bread, cash, cent, coin, necessary, penny, soldo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sole",
        "translation": "sun, alone, lonely",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soleggiata",
        "translation": "sunny, expose [something] to the sun, put [something] in the sun",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "soleggiate",
        "translation": "(you) put [something] in the sun, (you) expose [something] to the sun",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "soleggiato",
        "translation": "sunny, put [something] in the sun, bright, exposed [something] to the sun, exposed to the sun",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "soli",
        "translation": "alone, (you) sole",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "solo",
        "translation": "only, just, alone, but, by oneself, lone, lonely, mere, merely, on one’s own, single, solo",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "soltanto",
        "translation": "only, just, alone",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "sonno",
        "translation": "sleep",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sono",
        "translation": "(they) are, am, I am, am I, be, feel, have, is, it's",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sopra",
        "translation": "on, on top, on top of, about, above, after, beyond, more than, north of, onto, over, top (side), up, upon, upper, upstairs",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "soprattutto",
        "translation": "above all, most of all, chiefly, especially, in particular, mainly, mostly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "sorella",
        "translation": "sister",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sorelle",
        "translation": "sisters",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sorpresa",
        "translation": "surprise, amazement, astonishment, surprised",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sorprese",
        "translation": "(he/she/it/you) surprised, (he/she/it/you) was/were surprised, (he/she/it/you) astonished, (he/she/it/you) amazed, surprises",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sorpresi",
        "translation": "(I) surprised, (I) was/were surprised, (I) astonished, caught, surprised",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sorride",
        "translation": "(he/she/it) smiles, (he/she/it) appeals, (you) appeal, (you) smile",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sorridere",
        "translation": "(to) smile, (to) appeal",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sorridi",
        "translation": "(you) smile, (you) appeal",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sorriso",
        "translation": "smile, appealed, smiled",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sottile",
        "translation": "thin, fine, nice, sharp, slim",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sotto",
        "translation": "under, at the bottom, at the bottom of, (to) bring, (to) carry, (to) take, (to) wear, below, beneath, bottom, bottom part, bottom side, down, downstairs, during, lower, near, south of, underneath, underside",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "spagna",
        "translation": "Spain",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "spagnoli",
        "translation": "Spanish, Spaniards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "spagnolo",
        "translation": "Spanish, Spaniard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "spalla",
        "translation": "shoulder, back, collar, shoulders",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "spalle",
        "translation": "shoulders",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "spaventare",
        "translation": "scare, (to) to scare, (to) to be scared, (to) be frightened, (to) frighten, be scared",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spazio",
        "translation": "space, (I) space out, room, (I) move freely, (I) sweep, blank, clearance, gap, place, slot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "spazzola",
        "translation": "(you) brush, (he/she/it) brushes, (he/she/it) grooms",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "spazzolini",
        "translation": "toothbrushes, brush, groom",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "spazzolino",
        "translation": "toothbrush, brush, groom, mop up, polish off, small brush, toothbrushes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "specchi",
        "translation": "mirrors, (you) are reflected, (you) are mirrored, (you) look at oneself in the mirror, be mirrored, be reflected, looking-glass, models, sheets, tables",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "specchio",
        "translation": "mirror",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "speciale",
        "translation": "special, special slalom",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "speciali",
        "translation": "special, special slalom",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "specialità",
        "translation": "specialty, speciality, sport",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "specialmente",
        "translation": "especially, in particular, particularly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "speranza",
        "translation": "hope, expectation, hopes, prospect",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "speranze",
        "translation": "hopes, expectation, hope, prospect",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "speriamo",
        "translation": "(we) hope, let's hope, (we) candle, (we) trust, trust",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spero",
        "translation": "(I) hope, (I) trust, (I) candle, trust",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spesso",
        "translation": "often, thick, frequently, heavy",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "spettacolo",
        "translation": "show, performance, play, entertainment, showing, sight",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "spettatore",
        "translation": "spectator, member of the audience, looker-on, onlooker",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "spettatori",
        "translation": "member of the audience, houses, looker-on, onlooker, spectator",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "spiacente",
        "translation": "sorry",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "spiaggia",
        "translation": "beach, (sea)shore, seaside",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "spiega",
        "translation": "(he/she/it) explains, (he/she/it) explains oneself, (he/she/it) folds out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spiegare",
        "translation": "(to) explain, (to) explain oneself, (to) fold out, opened",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spiegazione",
        "translation": "explanation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "spieghiamo",
        "translation": "(we) explain, (we) explain oneself, (we) fold out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spinge",
        "translation": "(he/she/it) pushes, (he/she/it) urges, (he/she/it) incites, (he/she/it) bears down, (he/she/it) carries, (he/she/it) drives, (he/she/it) goes, (he/she/it) jostles, (he/she/it) pokes, (he/she/it) presses, (he/she/it) shoves, (he/she/it) takes, (he/she/it) urges on, (he/she/it) wheels, (you) bear down, (you) carry, (you) drive, (you) go, (you) incite, (you) jostle, (you) poke, (you) press, (you) push, (you) shove, (you) take, (you) urge, (you) urge on, (you) wheel, drive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spingere",
        "translation": "(to) push, (to) to push, (to) to poke, (to) bear down, (to) drive, (to) go, (to) take, (to) to carry, (to) to drive, (to) to incite, (to) to jostle, (to) to press, (to) to shove, (to) to urge, (to) urge on, (to) wheel, bored, carry, drive, incite, jostle, poke, press, shove, urge",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spingiamo",
        "translation": "(we) push, (we) wheel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spingo",
        "translation": "(I) push, (I) drive, (I) wheel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spiriti",
        "translation": "wit, mood, spirit, spirits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "spirito",
        "translation": "spirit, mood, spirits, wit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sporche",
        "translation": "dirty, dirt, dirties, nasties, unsavory, unsavoury",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sporchi",
        "translation": "(you) dirty, (you) make dirty, (you) get dirty, dirty",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sport",
        "translation": "sport",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sposa",
        "translation": "bride, (he/she/it) marries, (he/she/it) embraces, (he/she/it) gets married, (he/she/it) goes well, (he/she/it) marries off, (you) embrace, (you) get married, (you) go well, (you) marry, (you) marry off",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sposi",
        "translation": "newlyweds, (you) marry, (you) marry off",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sposta",
        "translation": "(you) move, (he/she/it) moves, (he/she/it) diverts, (he/she/it) changes, (he/she/it) changes round, (he/she/it) shifts, (you) change, (you) change round, (you) divert, (you) shift",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spostano",
        "translation": "(they) move, (they) change round",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spostare",
        "translation": "(to) move, (to) to move, (to) divert, (to) change, (to) change round, (to) shift, change, put, puts",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spostiamo",
        "translation": "(we) move, (we) change, (we) change round",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "squadra",
        "translation": "team, squad, squadron",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "squalo",
        "translation": "shark",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sta",
        "translation": "is, be, he/she feels, he/she is [feeling], stay, stays, you (form.) are [feeling], you (form.) feel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stadio",
        "translation": "stadium",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stagione",
        "translation": "season",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stagioni",
        "translation": "seasons, (you) mature, (you) age, (you) season",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stai",
        "translation": "(you) are, (you) are [feeling], (you) feel, be, stay",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stamattina",
        "translation": "this morning",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "stampa",
        "translation": "(you) press, (you) print a design on, (he/she/it) prints a design on, (he/she/it) crashes one’s bike, (he/she/it) crashes one’s car, (he/she/it) molds, (he/she/it) presses, (he/she/it) prints, (he/she/it) sticks, (he/she/it/you) mould, (you) crash one’s bike, (you) crash one’s car, (you) mold, (you) print, (you) stick, press, printing",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stampare",
        "translation": "(to) print, (to) print a design on, (to) press, run, runs, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stanca",
        "translation": "(you) grow tired, (you) get tired, (he/she/it) grows tired, (he/she/it) exhausts, (he/she/it) gets tired, (he/she/it) makes [somebody] tired, (he/she/it) makes [something] tired, (he/she/it) strains, (he/she/it) tires [somebody] out, (he/she/it) wears [somebody] out, (you) exhaust, (you) make [somebody] tired, (you) make [something] tired, (you) strain, (you) tire [somebody] out, (you) wear [somebody] out, slack water, stagnation, tired",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "stanche",
        "translation": "tired, slacks, strained, wearies, weary",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "stanco",
        "translation": "tired, (I) get tired, (I) grow tired, (I) make [somebody] tired, (I) make [something] tired, (I) strain",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "stanno",
        "translation": "(they) are, be, fit, stay",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stanotte",
        "translation": "tonight, last right",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "stanza",
        "translation": "room",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stare",
        "translation": "(to) stay, (to) be, (to) fit, be, fits, keep, kept, lies, stay, stayed, was, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "starebbe",
        "translation": "(he/she/it/you) would be, (he/she/it/you) would fit, be, stay, stayed, was, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "staremo",
        "translation": "(we) will be, (we) will fit, be, stay, we (will stay), were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stasera",
        "translation": "tonight, this evening",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "statale",
        "translation": "state, state school, highway, A road, civil servant, government employee, national, public, states",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "statali",
        "translation": "state, state school, government employee, A road, civil servant, highway, national",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "stati",
        "translation": "been, states",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stato",
        "translation": "been, state, felt, fitted, states, stayed",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stava",
        "translation": "was, (he/she/it/you) was/were, (he/she/it/you) was/were being, (he/she/it/you) used to be, stayed, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stavi",
        "translation": "were, (you) were, (you) were (doing), (you) used to be, (you) were being, stayed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stavo",
        "translation": "was, (I) was, (I) was/were fitting, (I) fitted, (I) used to be, (I) used to fit, (I) was/were being, stayed, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stazione",
        "translation": "(radio) station, posture, resort, stop, stopped",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stella",
        "translation": "star, blaze, darling",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stelle",
        "translation": "stars, blaze, darling",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stessa",
        "translation": "same",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "stessero",
        "translation": "(they) were, (they) fitted",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stessi",
        "translation": "same, (I) was, (I/you) fitted, (you) were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stessimo",
        "translation": "(we) were, (we) fitted",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stesso",
        "translation": "same",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "stette",
        "translation": "was, (he/she/it) was, (he/she/it/you) fitted, (you) were, be, stayed, were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stia",
        "translation": "is, be, chicken coop, fit, hen coop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "stile",
        "translation": "style, in style, look, speech form",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stipendi",
        "translation": "salaries, (you) employ, (you) pay (a salary to)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stipendio",
        "translation": "salary, (I) pay (a salary to), (I) employ",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stivale",
        "translation": "boot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stivali",
        "translation": "boots",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stomaco",
        "translation": "stomach, (I) turn one’s stomach, (I) disgust",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "storia",
        "translation": "history, story, affair, tale",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "storica",
        "translation": "historian (professione), historic (aggettivo), historical, historical (aggettivo)",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "storico",
        "translation": "historic, historical, historian",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "storie",
        "translation": "stories, affair, fib, fuss, history, story, tale",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "strada",
        "translation": "street, road, route, path, way",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "strade",
        "translation": "streets, road, roads, street, way",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "strana",
        "translation": "strange, strange thing, odd, weird",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "straniera",
        "translation": "foreigner, foreign, stranger",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stranieri",
        "translation": "foreigners, foreign, foreigner",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "straniero",
        "translation": "foreigner, foreign",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "strano",
        "translation": "strange, strange thing, odd, stranger, weird",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "strategia",
        "translation": "strategy, exercise, strategics",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "strumenti",
        "translation": "instruments, (you) orchestrate, tools, (you) instrument",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "strumento",
        "translation": "(I) instrument, tool, (I) orchestrate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "struttura",
        "translation": "(you) structure, (he/she/it) is structured, (he/she/it) structures, structures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "studente",
        "translation": "student",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "studenti",
        "translation": "students, pupils, scholars",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "studi",
        "translation": "(you) study, (you) examine, (I) study, (he/she) studies, office, practice, research, studied, survey",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "studia",
        "translation": "(he/she/it) studies, (he/she/it) examines, (you) examine, (you) study",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "studiando",
        "translation": "studying, dealing with, examining, thinking up, weighing, working, working out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "studiano",
        "translation": "(they) study, (they) examine, (they) deal with, consider",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "studiare",
        "translation": "look, study, studying, to study",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "studio",
        "translation": "(I) study, (I) examine, studied, study, work",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "su",
        "translation": "on, on to, come on, about, above, after, approximately, around, fits, in, more or less, on top of, onto, out of, over, to, to and fro, up, up and down, upon, upstairs, upwards",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "sua",
        "translation": "his, her, its, hers",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "subito",
        "translation": "right away, immediately, at once, in no time, received",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "succeda",
        "translation": "happen, follow (one another), follow one another, happens, occur, succeed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "succede",
        "translation": "(he/she/it) happens, (you) happen, (he/she/it) occurs, (he/she/it) follows (one another), (he/she/it) follows one another, (he/she/it) succeeds, (you) follow (one another), (you) follow one another, (you) occur, (you) succeed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "succedendo",
        "translation": "happening, occurring, passing, succeeding",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "succederà",
        "translation": "(he/she/it/you) will happen, (he/she/it/you) will occur, (he/she/it/you) will succeed, happened",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "succedere",
        "translation": "(to) happen, happened, (to) to occur, (to) follow (one another), (to) succeed, (to) to follow one another, follow one another, occur",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "succederebbe",
        "translation": "(he/she/it/you) would happen, (he/she/it/you) would succeed, (he/she/it/you) would occur, happened",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "succedesse",
        "translation": "happened, (he/she/it/you) happened, (he/she/it/you) succeeded, (he/she/it/you) followed (one another), (he/she/it/you) followed one another, (he/she/it/you) occurred, (it) would happen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "succedeva",
        "translation": "(he/she/it/you) used to happen, (he/she/it/you) was/were happening, (he/she/it/you) happened, happened",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "successe",
        "translation": "happened, (he/she/it/you) happened, (he/she/it/you) succeeded, (he/she/it/you) occurred",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "successi",
        "translation": "successes, (I) happened, (I) occurred, (I) succeeded, happened, passed",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "successo",
        "translation": "success, happened, hit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "succo",
        "translation": "juice",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sud",
        "translation": "south, the south of Italy, southern, southern Italy",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sue",
        "translation": "his, her, hers, its, your, yours",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "sufficiente",
        "translation": "enough, sufficient, adequate",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sufficienti",
        "translation": "enough, sufficient, adequate, self-important, self-important person",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sui",
        "translation": "on, about, on to, above, afters, approximately, around, come on, in the, more or less, on the, on top of, onto, out of, over, to and fro, up and down, upon, ups, upstairs, upwards",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "sul",
        "translation": "on, on to, come on, about, above, after, around, in, on the, on top of, onto, over, up, upon, upstairs, upwards",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "sull'",
        "translation": "on, on the, on to, above",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "sulla",
        "translation": "on, on the, on to, about, above, after, approximately, around, come on, more or less, on top of, onto, out of, over, to and fro, up, up and down, upon, upstairs, upwards",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "sulle",
        "translation": "on, on to, come on, about, above, afters, approximately, around, more or less, on top of, onto, out of, over, to and fro, up and down, upon, ups, upstairs, upwards",
        "partOfSpeech": "Preposition",
        "gender": "Feminine"
    },
    {
        "word": "sullo",
        "translation": "on, on to, come on, about, above, after, approximately, around, more or less, on the, on top of, onto, out of, over, to and fro, up, up and down, upon, upstairs, upwards",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "suo",
        "translation": "his, her, its, hers, your, your-formal, yours",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "suoi",
        "translation": "his, her, its, hers, yours",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "suona",
        "translation": "(he/she/it) plays, (you formal) play, plays",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonando",
        "translation": "playing, beating, going off, meaning, putting on, ringing, ringing out, sounding, sounding like, striking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonare",
        "translation": "(to) to play, playing, (to) go off, (to) put on, (to) ring, (to) ring out, (to) sound, (to) sound like, (to) strike, (to) to beat, (to) to mean, beat, go, gone, mean, play, played, ring, strike, to play, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonarono",
        "translation": "(they) rang, (they) rang out, (they) played",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonasse",
        "translation": "(he/she/it/you) played, (he/she/it/you) ringed, (he/she/it/you) rang",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonassimo",
        "translation": "(we) played, (we) rang, (we) ringed",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonate",
        "translation": "(you) play, (you) put on, (you) ring",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonava",
        "translation": "(he/she/it/you) was/were playing, (he/she/it/you) was/were striking, (he/she/it/you) was/were sounding, (he/she/it/you) beat, (he/she/it/you) meant, (he/she/it/you) played, (he/she/it/you) put on, (he/she/it/you) rang, (he/she/it/you) rang out, (he/she/it/you) ringed, (he/she/it/you) ringed out, (he/she/it/you) sounded, (he/she/it/you) sounded like, (he/she/it/you) struck, (he/she/it/you) used to beat, (he/she/it/you) used to go off, (he/she/it/you) used to mean, (he/she/it/you) used to play, (he/she/it/you) used to put on, (he/she/it/you) used to ring, (he/she/it/you) used to ring out, (he/she/it/you) used to sound, (he/she/it/you) used to sound like, (he/she/it/you) used to strike, (he/she/it/you) was/were beating, (he/she/it/you) was/were going off, (he/she/it/you) was/were meaning, (he/she/it/you) was/were putting on, (he/she/it/you) was/were ringing, (he/she/it/you) was/were ringing out, (he/she/it/you) was/were sounding like, (he/she/it/you) went off, (he/she/it/you) would ring",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonavano",
        "translation": "(they) played, (they) used to play, (they) were playing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonavi",
        "translation": "(you) used to play, (you) played, (you) were playing",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonerebbe",
        "translation": "(he/she/it/you) would sound, (he/she/it/you) would play, (he/she/it/you) would sound like, (he/she/it/you) would beat, (he/she/it/you) would go off, (he/she/it/you) would mean, (he/she/it/you) would put on, (he/she/it/you) would ring, (he/she/it/you) would ring out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonerei",
        "translation": "(I) would play, (I) would ring, (I) would beat, (I) would go off, (I) would mean, (I) would put on, (I) would ring out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suoneremo",
        "translation": "(we) will play, (we) will beat, (we) will ring, (we) will go off, (we) will mean, (we) will put on, (we) will ring out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suoneresti",
        "translation": "(you) would play, (you) would beat, (you) would mean, (you) would go off, (you) would put on, (you) would ring, (you) would ring out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonerete",
        "translation": "(you) will play, (you) will mean, (you) will ring, (you) will beat, (you) will go off, (you) will put on, (you) will ring out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suoni",
        "translation": "(you) play, (you) ring",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suoniamo",
        "translation": "(we) play",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suoniate",
        "translation": "play, put on, ring",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonino",
        "translation": "play, put on, ring",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suonò",
        "translation": "(he/she/it/you) played, (he/she/it/you) rang, (he/she/it/you) rang out, (he/she/it/you) beat, (he/she/it/you) meant, (he/she/it/you) put on, (he/she/it/you) ringed, (he/she/it/you) ringed out, (he/she/it/you) sounded, (he/she/it/you) sounded like, (he/she/it/you) struck, (he/she/it/you) went off",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suono",
        "translation": "(he/she/it/you) played, (he/she/it/you) rang, (he/she/it/you) rang out, (he/she/it/you) beat, (he/she/it/you) meant, (he/she/it/you) put on, (he/she/it/you) ringed, (he/she/it/you) ringed out, (he/she/it/you) sounded, (he/she/it/you) sounded like, (he/she/it/you) struck, (he/she/it/you) went off",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "superiore",
        "translation": "top, upper, advanced, bigger, course of studies following middle school or junior high school and preceeding university, higher, longer, senior, superior",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "superiori",
        "translation": "superior, bigger, higher, longer, senior, top, upper",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "supermercato",
        "translation": "supermarket",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "suppongo",
        "translation": "(I) suppose, (I) assume, (I) presume, assume",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supponiamo",
        "translation": "assume, (we) assume, (we) suppose, (we) presume",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supporre",
        "translation": "presume, (to) to presume, (to) suppose, (to) imagine, assume, imagine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "svegliare",
        "translation": "(to) wake up, (to) wake (up), (to) to liven up, (to) awaken, (to) to arouse, (to) to awake, (to) to awaken, (to) to open one’s eyes, (to) whet, arouse, awake, called, liven up, open one’s eyes, wake",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sviluppo",
        "translation": "development, (I) develop, (I) expand, (I) build up",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "svuotano",
        "translation": "(they) empty, (they) scoop out, (they) deprive, (they) drain, clear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "svuoti",
        "translation": "(you) empty, (you) drain, (you) scoop out, clear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "svuoto",
        "translation": "(I) empty, (I) drain, (I) deprive, (I) scoop out, clear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tacchino",
        "translation": "turkey",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "taglia",
        "translation": "(he/she/it) cuts, (you) cut!, cut, size, stop",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "taglio",
        "translation": "(I) cut (down), (I) cut out, (I) cut off, cut, stop, style",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "talmente",
        "translation": "so, that, enough, those",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tamburi",
        "translation": "cylinder, drummer, drum, (brake) drum, drums",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tamburo",
        "translation": "(brake) drum, cylinder, drummer, drums",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tanta",
        "translation": "many, much, a lot, a lot of, as much, at most, just as well",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "tante",
        "translation": "many, a lot, a lot of, as much, at most, just as well, much",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "tanto",
        "translation": "much, so, as much, a lot, a lot of, all, as, at most, just as well, many, more, very, very much",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "tappeti",
        "translation": "carpets, rugs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tappeto",
        "translation": "rug, carpet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tardi",
        "translation": "late, latest",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tartaruga",
        "translation": "turtle, (sea) turtle, slowcoach, slowpoke, tortoise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tasca",
        "translation": "pocket, pouch",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tasche",
        "translation": "pockets, compartment, pouch",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tassa",
        "translation": "(you) tax, (he/she/it) contributes, (he/she/it) taxes, (he/she/it) undertakes to pay, (you) contribute, (you) undertake to pay, fee",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tasse",
        "translation": "taxes, (I) undertake to pay, (I) contribute, (I) tax, badger, brock, count, fees, figure, level, percentage, rate, yew (tree)",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tastiera",
        "translation": "(computer) keyboard, fingerboard",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tavola",
        "translation": "(dining) table, painting (on wood), board, illustration, snowboard, tables",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tavolo",
        "translation": "table, tables",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tazza",
        "translation": "cup, mug",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tè",
        "translation": "you, yourself, to your place, in your house, in your place, to your house",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "te",
        "translation": "you, yourself, to your place, in your house, in your place, to your house",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "teatri",
        "translation": "theaters, theatres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "teatro",
        "translation": "theater, theatre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tecnici",
        "translation": "technicians, engineer, technical",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tecnologia",
        "translation": "technology",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tedesca",
        "translation": "German",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "tedesche",
        "translation": "German",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "tedeschi",
        "translation": "Germans, German, German people",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "tedesco",
        "translation": "German",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "telefonare",
        "translation": "call (up), (to) to call (up), (to) (tele)phone, called, ring, ring (up), telephones",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "telefoni",
        "translation": "(you) (tele)phone, (you) ring (up), (you) call (up), phones, telephones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "telefono",
        "translation": "telephone, phone",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "televisione",
        "translation": "television, tv",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "televisore",
        "translation": "television set, TV, television",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "televisori",
        "translation": "televisions, television (set), television receiver, television set",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "temperatura",
        "translation": "temperature, fever",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tempo",
        "translation": "time, weather, tempo, times",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "temporale",
        "translation": "storm, electric storm, earthly, rainstorm, secular, shower, temporal, thunderstorm, time, time clause, wordly",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "temporali",
        "translation": "storms, temporal, thunderstorms",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tenda",
        "translation": "tent, curtain, awning, curtains, stretch, sun blind, sunshade",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tende",
        "translation": "curtains, tents, (he/she/it) stretches",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tenemmo",
        "translation": "(we) held, (we) held out, (we) held on, (we) held back, (we) was/were held, kept",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenendo",
        "translation": "holding, keeping, holding on, holding back, holding out, keeping to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenere",
        "translation": "(to) keep, (to) keep to, (to) to restrain, (to) be held, (to) carry, (to) give, (to) hold, (to) hold back, (to) hold on, (to) hold out, (to) last, (to) take up, (to) to cling on, (to) to control, (to) to hold together, (to) to stick, (to) to take place, carried, carry, cling on, control, gave, give, given, hold together, keep, kept, leave, left, lies, restrain, stick, take place",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenesse",
        "translation": "(he/she/it/you) kept, (he/she/it/you) held, (he/she/it/you) kept to, (he/she/it/you) carried, (he/she/it/you) clung on, (he/she/it/you) controlled, (he/she/it/you) gave, (he/she/it/you) held back, (he/she/it/you) held on, (he/she/it/you) held out, (he/she/it/you) held together, (he/she/it/you) lasted, (he/she/it/you) restrained, (he/she/it/you) stuck, (he/she/it/you) took place, (he/she/it/you) took up, (he/she/it/you) was/were held",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenete",
        "translation": "(you) keep, (you) keep to, (you) hold, (you) hold back, (you) hold on, (you) hold out, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "teneva",
        "translation": "(he/she/it/you) was/were holding, (he/she/it/you) was/were keeping, (he/she/it/you) was/were holding back, (he/she/it/you) held, (he/she/it/you) kept, (he/she/it/you) kept to, (he/she/it/you) was/were holding on, (he/she/it/you) was/were holding out, (he/she/it/you) was/were keeping to, carried, kept",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenevano",
        "translation": "(they) were keeping, (they) were keeping to, (they) held, kept",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenevo",
        "translation": "(I) kept, (I) was/were holding, (I) kept to, (I) held, (I) was/were holding back, (I) was/were holding on, (I) was/were holding out, kept",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenga",
        "translation": "keep to, keep, hold, hold back, hold on, hold out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tengano",
        "translation": "keep, keep to, hold",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tengono",
        "translation": "(they) keep, (they) keep to, (they) hold, carry, hold, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "teniamo",
        "translation": "(we) keep, (we) keep to, (we) hold, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenne",
        "translation": "(he/she/it/you) held, (he/she/it/you) held back, (he/she/it/you) held out, (he/she/it/you) carried, (he/she/it/you) clung on, (he/she/it/you) controlled, (he/she/it/you) gave, (he/she/it/you) held on, (he/she/it/you) held together, (he/she/it/you) kept, (he/she/it/you) kept to, (he/she/it/you) lasted, (he/she/it/you) restrained, (he/she/it/you) stuck, (he/she/it/you) took place, (he/she/it/you) took up, (he/she/it/you) was/were held, carried, kept",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tennis",
        "translation": "tennis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tentando",
        "translation": "trying, attempting, tempting",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "teoria",
        "translation": "theory",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "teorie",
        "translation": "theories",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "terra",
        "translation": "(he/she/it/you) will keep to, (he/she/it/you) will keep, (he/she/it/you) will give, (he/she/it/you) will be held, (he/she/it/you) will carry, (he/she/it/you) will cling on, (he/she/it/you) will control, (he/she/it/you) will hold, (he/she/it/you) will hold back, (he/she/it/you) will hold on, (he/she/it/you) will hold out, (he/she/it/you) will hold together, (he/she/it/you) will last, (he/she/it/you) will restrain, (he/she/it/you) will stick, (he/she/it/you) will take place, (he/she/it/you) will take up, keep",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "terrà",
        "translation": "(he/she/it/you) will keep to, (he/she/it/you) will keep, (he/she/it/you) will give, (he/she/it/you) will be held, (he/she/it/you) will carry, (he/she/it/you) will cling on, (he/she/it/you) will control, (he/she/it/you) will hold, (he/she/it/you) will hold back, (he/she/it/you) will hold on, (he/she/it/you) will hold out, (he/she/it/you) will hold together, (he/she/it/you) will last, (he/she/it/you) will restrain, (he/she/it/you) will stick, (he/she/it/you) will take place, (he/she/it/you) will take up, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terrai",
        "translation": "(you) will keep, (you) will keep to, (you) will hold, (you) will be held, (you) will give, (you) will hold back, (you) will hold on, (you) will hold out, (you) will last, (you) will take up, hold, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terranno",
        "translation": "(they) will be held, (they) will keep, (they) will hold, (they) will give, (they) will hold back, (they) will hold on, (they) will hold out, (they) will keep to, (they) will last, (they) will take up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terrebbe",
        "translation": "(he/she/it/you) would keep, (he/she/it/you) would keep to, (he/she/it/you) would hold on, (he/she/it/you) would be held, (he/she/it/you) would give, (he/she/it/you) would hold, (he/she/it/you) would hold back, (he/she/it/you) would hold out, (he/she/it/you) would last, (he/she/it/you) would take up, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terrei",
        "translation": "(I) would keep, (I) would hold, (I) would last, (I) would be held, (I) would give, (I) would hold back, (I) would hold on, (I) would hold out, (I) would keep to, (I) would take up, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terreni",
        "translation": "soil, land, field, terrain",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "terreno",
        "translation": "terrain, ground, land, earthly, field, site",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "terribile",
        "translation": "terrible, awful, dreadful, fearful, horrendous, nasty, raging, terrific, thundering, tormenting, tremendous",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "territorio",
        "translation": "territory, country, region",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "terrò",
        "translation": "(I) will keep, (I) will keep to, (I) will give, (I) will be held, (I) will hold, (I) will hold back, (I) will hold on, (I) will hold out, (I) will last, (I) will take up, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terza",
        "translation": "third (gear), third class, third year, steerage, third, third form, third party, third position, tierce",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "terzo",
        "translation": "third, third party, thirdly",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "tesi",
        "translation": "thesis, (I) stretched, (you) stretch",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "testa",
        "translation": "head, mind, (he/she/it) tests",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "testo",
        "translation": "text, (I) test, (I) benchmark, (I) make one’s will, (I) trial, baking tray, body type, book, crock, cruse, material, script, wording, words, work",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tetto",
        "translation": "roof, ceiling, rooftop",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ti",
        "translation": "you, to you, yourself, your",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "tieni",
        "translation": "(you) keep, (you) keep to, (you) hold, (you) are held, (you) carry, (you) cling on, (you) control, (you) give, (you) hold back, (you) hold on, (you) hold out, (you) hold together, (you) last, (you) restrain, (you) stick, (you) take place, (you) take up, be held, keep",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tigre",
        "translation": "tiger",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "timore",
        "translation": "fear, awe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tipi",
        "translation": "types, kinds, sorts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tipo",
        "translation": "type, kind, like, (classic) example, average, class, customer, customers, fellow, guy, man, sort, standard, such as, types, typical",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tira",
        "translation": "",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tirare",
        "translation": "",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tiri",
        "translation": "(you) pull, (you) pull up, (you) pull off, (you) pull in, (you) pull out, light",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "titolo",
        "translation": "(I) title, (I) headline, (I) titrate, deed, epithet, fineness, heading, name, qualification, security, share, stock, term of abuse, titre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "toccando",
        "translation": "touching, touching each other, handling",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tonda",
        "translation": "round, Roman, chubby, circle, ring, tondo",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "topo",
        "translation": "mouse, mouse-colour",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "torneo",
        "translation": "tournament, (I) joust, (I) tourney, championship, tilt",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "toro",
        "translation": "bull, the Bull, Taurus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "torta",
        "translation": "cake, pie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "torte",
        "translation": "cakes, pies, tarts, twisted",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "totale",
        "translation": "total, in all, complete, absolute, aggregate, combined, comprehensive, full, out-and-out, outright, overall, rank, thorough, unadulterated, unqualified, utter, whole, wholehearted",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "totalmente",
        "translation": "totally, completely",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tra",
        "translation": "between, in, among",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "tradizionale",
        "translation": "traditional, conservative, conventional, mainstream, usual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tradizione",
        "translation": "tradition, handing over, legend",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "traduzione",
        "translation": "translation, rendering, transfer",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "traffico",
        "translation": "(I) traffic, coming and going, traffic",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tranne",
        "translation": "except, but, except that, bar, besides, except for",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "tranquilla",
        "translation": "calm, peaceable, quiet, tranquil",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "tranquillo",
        "translation": "calm, clear, easier, easy, even, peaceable, private, quiet, still, tranquil",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "trasformazione",
        "translation": "transformation, conversion, processing",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "trasporti",
        "translation": "(you) carry, carry, (you) transport, drive, (you) move",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trattamento",
        "translation": "treatment, medication, course, handling, processing",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tre",
        "translation": "three",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "tredici",
        "translation": "thirteen",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "treni",
        "translation": "trains, carriage, train",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "treno",
        "translation": "train, carriage, quarters, set, trains",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trent'",
        "translation": "thirty",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "trenta",
        "translation": "thirty",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "tribunale",
        "translation": "court, tribunal, courthouse",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "triste",
        "translation": "sad",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "troppa",
        "translation": "too, too much, too many",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "troppe",
        "translation": "too, too many, too long, all too, only too, too much",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "troppi",
        "translation": "too, too many, too long, all too, only too, too much",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "troppo",
        "translation": "too, too much",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "trova",
        "translation": "(he/she/it) finds, (he/she/it) finds oneself, (he/she/it) is, (he/she/it) catches, (he/she/it) comes across, (he/she/it) comes up with, (he/she/it) encounters, (he/she/it) feels, (he/she/it) happens (to be), (he/she/it) meets, (he/she/it) meets with, (he/she/it) runs into, (he/she/it) thinks, (you) are, (you) catch, (you) come across, (you) come up with, (you) encounter, (you) feel, (you) find, (you) find oneself, (you) happen (to be), (you) meet, (you) meet with, (you) run into, (you) think, be, get, hit, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovai",
        "translation": "(I) found, (I) thought, (I) came across, get, got, hit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovammo",
        "translation": "(we) found, (we) thought, (we) came across, got, hit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovando",
        "translation": "finding, finding oneself, raising, thinking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovare",
        "translation": "(to) find, (to) find oneself, (to) to be, (to) be, (to) encounter, (to) feel, (to) happen (to be), (to) meet, (to) think, (to) to catch, (to) to come across, (to) to come up with, (to) to meet with, (to) to run into, came, catch, come across, come up with, figure, get, got, hit, meet with, run, run into, runs, strike, visit, work",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovarono",
        "translation": "(they) found, (they) came across, (they) thought, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovasse",
        "translation": "(he/she/it) was, (he/she/it/you) thought, (he/she/it/you) found",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovassimo",
        "translation": "(we) happened (to be), (we) came across, (we) came up with, (we) caught, (we) encountered, (we) felt, (we) found, (we) found oneself, (we) met, (we) met with, (we) ran into, (we) thought, (we) were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovate",
        "translation": "found, (you) find, (you) find oneself, (you) are, (you) catch, (you) come across, (you) come up with, (you) encounter, (you) feel, (you) happen (to be), (you) meet, (you) meet with, (you) run into, (you) think, be, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovava",
        "translation": "(he/she/it/you) used to find, (he/she/it/you) used to find oneself, (he/she/it/you) used to encounter, (he/she/it/you) came across, (he/she/it/you) came up with, (he/she/it/you) caught, (he/she/it/you) encountered, (he/she/it/you) felt, (he/she/it/you) found, (he/she/it/you) found oneself, (he/she/it/you) happened (to be), (he/she/it/you) met, (he/she/it/you) met with, (he/she/it/you) ran into, (he/she/it/you) thought, (he/she/it/you) used to be, (he/she/it/you) used to catch, (he/she/it/you) used to come across, (he/she/it/you) used to come up with, (he/she/it/you) used to feel, (he/she/it/you) used to happen (to be), (he/she/it/you) used to meet, (he/she/it/you) used to meet with, (he/she/it/you) used to run into, (he/she/it/you) used to think, (he/she/it/you) was/were, (he/she/it/you) was/were being, (he/she/it/you) was/were catching, (he/she/it/you) was/were coming across, (he/she/it/you) was/were coming up with, (he/she/it/you) was/were encountering, (he/she/it/you) was/were feeling, (he/she/it/you) was/were finding, (he/she/it/you) was/were finding oneself, (he/she/it/you) was/were happening (to be), (he/she/it/you) was/were meeting, (he/she/it/you) was/were meeting with, (he/she/it/you) was/were running into, (he/she/it/you) was/were thinking, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovavamo",
        "translation": "(we) found, (we) were finding, (we) used to find, got, hit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovavano",
        "translation": "(they) used to find, (they) found, (they) were finding, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "troverà",
        "translation": "(he/she/it/you) will find, (he/she/it/you) will meet, (he/she/it/you) will be, (he/she/it/you) will come up with, (he/she/it/you) will feel, (he/she/it/you) will meet with, (he/she/it/you) will run into, get, hit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "troverai",
        "translation": "(you) will find, (you) will meet, (you) will think, (you) will be, (you) will come across, (you) will come up with, (you) will feel, (you) will meet with, (you) will run into, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "troverebbe",
        "translation": "(he/she/it/you) would find, (he/she/it/you) would meet, (he/she/it/you) would feel, (he/she/it/you) would be, (he/she/it/you) would come up with, (he/she/it/you) would meet with, (he/she/it/you) would run into, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "troveremo",
        "translation": "(we) will find, (we) will be, (we) will meet, (we) will come up with, (we) will feel, (we) will meet with, (we) will run into",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "troveresti",
        "translation": "(you) would find, (you) would meet, (you) would be, (you) would come up with, (you) would feel, (you) would meet with, (you) would run into, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovi",
        "translation": "(you) find, (you) think, (you) come across, get",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "troviamo",
        "translation": "(we) find, (we) find oneself, (we) feel, (we) are, (we) catch, (we) come across, (we) come up with, (we) encounter, (we) happen (to be), (we) meet, (we) meet with, (we) run into, (we) think, be, get, hit, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trovo",
        "translation": "(I) find, (I) find oneself, (I) am, (I) catch, (I) come across, (I) come up with, (I) encounter, (I) feel, (I) happen (to be), (I) meet, (I) meet with, (I) run into, (I) think, get, hit, strike",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tu",
        "translation": "you",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "tua",
        "translation": "yours, your",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "tue",
        "translation": "Your(s), yours",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "tuo",
        "translation": "yours, your",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "tuoi",
        "translation": "Your(s), yours",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "turismo",
        "translation": "tourism, touring, tourist trade",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "turista",
        "translation": "tourist, sightseer, tourists",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "turisti",
        "translation": "tourists",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tutta",
        "translation": "all, whole, all of, altogether, any, anything, each (one), entire, every, everybody, everyone, everything, perfectly, quite, totally",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "tuttavia",
        "translation": "however, nonetheless, still, but, nevertheless, though",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tutte",
        "translation": "all, every, altogether, any, anything, each (one), everybody, everyone, everything, perfectly, quite, totally, whole",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "tutti",
        "translation": "all, everybody, everyone, any, each, every",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "tutto",
        "translation": "everything, all, whole, altogether, any, anything, each (one), every, everybody, everyone, perfectly, quite, totally",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "uccelli",
        "translation": "birds",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uccello",
        "translation": "bird, birds",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uffici",
        "translation": "offices, departments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ufficio",
        "translation": "office, bureau, department, section, service",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uguale",
        "translation": "same, the same, alike, equal, equal sign, equals, equals sign, even, level",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "uguali",
        "translation": "same, the same, equal, alike, equal sign, equals sign, even, level",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ugualmente",
        "translation": "anyway, equally, anyways, even",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ultima",
        "translation": "last, (you) accomplish, (he/she/it) finishes, (he/she/it) accomplishes, (you) finish, latest, least",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ultimi",
        "translation": "last, last (ones), (you) accomplish, (you) finish",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ultimo",
        "translation": "last, (I) accomplish, (I) finish, bottom, final, latest, least, past, ultimate",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "umana",
        "translation": "human, humane",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "umani",
        "translation": "humans, human, humane",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "umano",
        "translation": "human, humane",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "un",
        "translation": "a, an, one",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "un'",
        "translation": "an, a, you, each, one, somebody, someone",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "una",
        "translation": "a, an, one",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "undici",
        "translation": "eleven",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "unendo",
        "translation": "uniting, joining (up), linking",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unghie",
        "translation": "nails, claw, nail",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "unì",
        "translation": "joined, (he/she/it/you) joined (up), (he/she/it/you) linked, (he/she/it/you) united, united",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unica",
        "translation": "only, the only one, special, unique",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "unico",
        "translation": "only, exclusive, single, sole, special, unique, unparalleled",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "unire",
        "translation": "(to) unite, (to) combine, (to) to link, (to) amalgamate, (to) bind (together), (to) blend, (to) enclose, (to) fold (in), (to) join (up), (to) join together, (to) merge (together), (to) to add, (to) to merge (together), (to) to tie together, add, combine, join, link, merge, put together, teams, tie together, united",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unirebbe",
        "translation": "(he/she/it/you) would unite, (he/she/it/you) would link, (he/she/it/you) would add, (he/she/it/you) would bind (together), (he/she/it/you) would fold (in), (he/she/it/you) would join (up)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unirei",
        "translation": "(I) would unite, (I) would add, (I) would link, (I) would bind (together), (I) would fold (in), (I) would join (up), join",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "uniresti",
        "translation": "(you) would join (up), (you) would add, (you) would link, (you) would bind (together), (you) would fold (in), join",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unirono",
        "translation": "joined, (they) joined (up), (they) united, (they) linked, united",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unisce",
        "translation": "(he/she/it) unites, (he/she/it) joins (up), (he/she/it) joins together, (he/she/it) ties together, (you) join together, (you) tie together, (you) unite, teams",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unisse",
        "translation": "(he/she/it/you) joined (up), (he/she/it/you) joined together, (he/she/it/you) united, (he/she/it/you) added, (he/she/it/you) amalgamated, (he/she/it/you) blended, (he/she/it/you) bound (together), (he/she/it/you) combined, (he/she/it/you) enclosed, (he/she/it/you) folded (in), (he/she/it/you) linked, (he/she/it/you) merged (together), (he/she/it/you) tied together",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unissi",
        "translation": "(I/you) united, (I/you) linked, (I/you) joined (up)",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unisti",
        "translation": "joined, (you) joined (up), (you) linked, (you) united",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "unite",
        "translation": "(you) unite, (you) link, (you) join (up), join, mix",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "università",
        "translation": "university, college, colleges, universities",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "universo",
        "translation": "universe, world",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uno",
        "translation": "one, a, an",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "uomini",
        "translation": "men",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uomo",
        "translation": "man",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uovo",
        "translation": "egg",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "usa",
        "translation": "(he/she/it) uses, (you) use, (he/she/it) is in the habit of, (he/she/it) exercises, (he/she/it) is used to, (he/she/it) runs, (you) are in the habit of, (you) are used to, (you) exercise, (you) run, USA, United States, be in the habit of, be used to, exercise, uses, wears",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usando",
        "translation": "using, being in the habit of, being used to, exercising, running",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usare",
        "translation": "(to) use, (to) to be used to, (to) be in the habit of, (to) exercise, (to) run, be used to, exercise, used, uses, wear, wears",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usarono",
        "translation": "used, (they) used, (they) ran, (they) was/were used to, exercise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usasse",
        "translation": "(he/she/it/you) exercised, (he/she/it/you) used, (he/she/it/you) ran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usassi",
        "translation": "(I/you) exercised, (I/you) ran, (I/you) used",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usate",
        "translation": "(you) use, (you) run, (you) exercise, exercise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usava",
        "translation": "(he/she/it/you) was/were using, used, (he/she/it/you) was/were exercising, (he/she/it/you) exercised, (he/she/it/you) ran, (he/she/it/you) used, (he/she/it/you) used to be in the habit of, (he/she/it/you) used to be used to, (he/she/it/you) used to exercise, (he/she/it/you) used to run, (he/she/it/you) used to use, (he/she/it/you) was/were being in the habit of, (he/she/it/you) was/were being used to, (he/she/it/you) was/were in the habit of, (he/she/it/you) was/were running, (he/she/it/you) was/were used to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usavamo",
        "translation": "(we) were using, (we) used, (we) used to use, used",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "uscita",
        "translation": "exit, way out, (cash) outflow, ending, exits, expenditure, landing, outbound, outing, output, publication, release, remark, sally, turnoff",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "userai",
        "translation": "(you) will use, (you) will run, (you) will be used to, used",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "userebbe",
        "translation": "(he/she/it/you) would use, (he/she/it/you) would run, (he/she/it/you) would be used to, (he/she/it/you) would be in the habit of, used",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "userei",
        "translation": "(I) would use, (I) would run, (I) would be used to, (I) would be in the habit of",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "useremo",
        "translation": "(we) will use, (we) will exercise, (we) will run, (we) will be in the habit of, (we) will be used to",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usi",
        "translation": "(you) use, (you) are in the habit of, (you) are used to, (you) exercise, (you) run, be in the habit of, be used to, exercise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usiamo",
        "translation": "(we) use, (we) exercise, (we) run, exercise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usino",
        "translation": "use, exercise, run",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usò",
        "translation": "(I) use, (I) exercise, (I) run, exercise, used, uses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "uso",
        "translation": "(I) use, (I) exercise, (I) run, exercise, used, uses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utile",
        "translation": "useful, advantage, convenient, earnings, handy, helpful, income, profit",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "utili",
        "translation": "useful, advantage, earnings, handy, helpful, income, profit",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "uva",
        "translation": "grapes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "va",
        "translation": "goes, (he/she/it) goes, has to be, is going, must be",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vacanza",
        "translation": "vacation, holiday",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vacanze",
        "translation": "vacation, broke, broken, holiday, vacations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vada",
        "translation": "go, go up, go on, (he/she/it) goes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vado",
        "translation": "go, (I) go, (I) go on, (I) am going, (I) come down, (I) decrease, (I) fall, (I) finish up, (I) get ahead, (I) get away, (I) get back, (I) get down, (I) go along, (I) go away, (I) go back, (I) go badly, (I) go down, (I) go forward(s), (I) go off, (I) go out, (I) go too far, (I) go up, (I) increase, (I) lead, (I) leave, (I) pass away, (I) rise, (I) run fast, (I) run slow, (I) wind up, (I) work, gone, let, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vai",
        "translation": "(you) go, gone, went",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "valigia",
        "translation": "suitcase, bag",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "valigie",
        "translation": "suitcases, bag, case",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "valore",
        "translation": "(time) value, merit, worth",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vantaggi",
        "translation": "advantages, edge, edges",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vantaggio",
        "translation": "advantage, asset, benefit, edge, head start, lead",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vasca",
        "translation": "bathtub, tub, tank",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vecchi",
        "translation": "old, old men, old people, ancient, former, old man, old person, old vecchi, old-fashioned, outdated, stale",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vecchia",
        "translation": "old",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vecchie",
        "translation": "old, old man, old women",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vecchio",
        "translation": "old, old man, ancient, coldest, former, outdated, stale",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "veda",
        "translation": "see, imagine, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vede",
        "translation": "(he/she/it) sees, (he/she/it) sees oneself, see, (he/she/it) consults, (he/she/it) imagines, (he/she/it) looks over, (he/she/it) looks through, (he/she/it) meets, (he/she/it) shows off, (he/she/it) tries, (he/she/it) visits, (he/she/it) watches, (you) consult, (you) imagine, (you) look over, (you) look through, (you) meet, (you) see, (you) see oneself, (you) show off, (you) try, (you) visit, (you) watch, saw, seen, tell, view, views",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedere",
        "translation": "see, (to) see, (to) see oneself, (to) look over, (to) show off, (to) to consult, (to) to imagine, (to) to look through, (to) to meet, (to) to try, (to) to visit, (to) watch, consult, imagine, look through, meet, saw, seeing, seen, tell, told, try, view, views, visit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedesse",
        "translation": "(he/she/it/you) imagined, (he/she/it/you) watched, (he/she/it/you) saw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedessero",
        "translation": "(they) watched, (they) tried, (they) imagined, (they) consulted, (they) looked over, (they) looked through, (they) met, (they) saw, (they) saw oneself, (they) showed off, (they) visited",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedessi",
        "translation": "(I/you) saw, (I/you) watched, (I/you) imagined, saw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedeva",
        "translation": "(he/she/it/you) used to see, (he/she/it/you) was/were seeing, (he/she/it/you) saw, saw, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedevamo",
        "translation": "(we) used to see, (we) used to try, (we) used to meet, (we) saw, (we) used to show off, (we) were seeing, saw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedevo",
        "translation": "(I) used to see, (I) used to meet, (I) saw, (I) used to show off, (I) was/were seeing, saw, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vediamo",
        "translation": "see, (we) see, saw",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedo",
        "translation": "see, (I) see, (I) meet, saw, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedrà",
        "translation": "see, (he/she/it) will see, imagine, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedrai",
        "translation": "see, (you) will see, imagine, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedrebbe",
        "translation": "see, (he/she/it/you) would see, (he/she/it/you) would meet, (he/she/it/you) would look over, (he/she/it/you) would show off, (he/she/it/you) would try, seen, tell, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedrei",
        "translation": "see, (I) would see, (I) would try, (I) would look over, (I) would meet, (I) would show off, seen, tell, told",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedremo",
        "translation": "see, (we) will see, see oneself, consult, imagine, look over, look through, meet, show off, try, visit, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedrete",
        "translation": "see, imagine, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vedrò",
        "translation": "see, (I) will see, imagine, watch",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vegetariano",
        "translation": "vegetarian",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "veloce",
        "translation": "fast, quick, rapid, swift",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "velocemente",
        "translation": "quickly, fast, rapidly, swiftly",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "velocità",
        "translation": "speed, gear, quickness, rapidity, velocity",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vende",
        "translation": "(he/she/it) sells, (you) sell",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendere",
        "translation": "(to) sell, (to) to shop, (to) betray, carried, carry, sell, shop, sold",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendita",
        "translation": "sale, sales, selling, shop",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vendite",
        "translation": "sales, sale, selling",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "venditore",
        "translation": "shop assistant, vendor, vendors, seller",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "venditori",
        "translation": "vendors, sellers, dealer, dealers, salesperson, seller, vendor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vendo",
        "translation": "(I) sell, (I) sell one’s body, (I) betray, sell",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendono",
        "translation": "(they) sell",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venendo",
        "translation": "coming, coming out, coming up, coming away, coming down, coming in, coming off",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venerdì",
        "translation": "Friday, on Friday",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "venga",
        "translation": "come, come in, come out, arrive, come off, come up, yet to come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vengano",
        "translation": "come, come in, come out, (they) come, come off, come up, yet to come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venire",
        "translation": "come, to come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venisse",
        "translation": "came, (he/she/it/you) came, (he/she/it/you) came out, (he/she/it/you) arrived, (he/she/it/you) came in, (he/she/it/you) came off, (he/she/it/you) came up, (he/she/it/you) yet (past) to come, (you) were",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venissi",
        "translation": "(I/you) yet (past) to come, (I/you) arrived, (I/you) came",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venite",
        "translation": "come, (you) come, (you) come out, (you) are, (you) are owed, (you) arrive, (you) come away, (you) come down, (you) come forward, (you) come in, (you) come off, (you) come up, (you) cost, (you) fade, (you) faint, (you) follow, (you) get, (you) grow up, (you) turn out, (you) work out, (you) yet to come, be, be owed, coming",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veniva",
        "translation": "",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venivano",
        "translation": "(they) used to come, (they) used to yet to come, (they) used to come out, (they) used to be, (they) used to come in, (they) used to come off, (they) used to come up, (they) used to cost, (they) used to fade, (they) used to get, (they) used to grow up, (they) used to turn out, (they) were yet to come, (they) yet (past) to come, came",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venivi",
        "translation": "(you) were, (you) were coming, (you) were coming away, (you) were coming down, (you) were coming in, (you) were coming off, (you) were coming out, (you) were coming up, came",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venne",
        "translation": "came, (he/she/it/you) came out, (he/she/it/you) came, (he/she/it) was, (he/she/it/you) arrived, (he/she/it/you) came in, (he/she/it/you) came off, (he/she/it/you) came up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venni",
        "translation": "came, (I) came, (I) came off, (I) came in, (I) came out, (I) came up",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venti",
        "translation": "twenty, winds",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ventilatore",
        "translation": "fan, fans, ventilator, winnower",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vento",
        "translation": "wind",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ventosa",
        "translation": "windy, cupping glass, sucker, suction cup, suction pad",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ventoso",
        "translation": "windy, breezy, gusty",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "veramente",
        "translation": "really, truly, to tell the truth, actually, as a matter of fact, indeed",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "verde",
        "translation": "green, greener",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "verdura",
        "translation": "vegetables, greens, vegetable",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "verità",
        "translation": "truth",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vero",
        "translation": "true, real, right, correct",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "verrai",
        "translation": "(you) will come, (you) will yet to come, (you) will come off, (you) will be, (you) will be owed, (you) will come away, (you) will come down, (you) will come in, (you) will come out, (you) will come up, (you) will cost, (you) will fade, (you) will get, (you) will grow up, (you) will turn out, (you) will work out",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verranno",
        "translation": "(they) will come, (they) will come out, (they) will come up, (they) will arrive, (they) will be, (they) will be owed, (they) will come away, (they) will come down, (they) will come in, (they) will come off, (they) will cost, (they) will fade, (they) will get, (they) will grow up, (they) will turn out, (they) will work out, (they) will yet to come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verrebbe",
        "translation": "come, (he/she/it/you) would come, (he/she/it/you) would come in, (he/she/it/you) would be, (he/she/it/you) would be owed, (he/she/it/you) would come away, (he/she/it/you) would come down, (he/she/it/you) would come off, (he/she/it/you) would come out, (he/she/it/you) would come up, (he/she/it/you) would cost, (he/she/it/you) would fade, (he/she/it/you) would get, (he/she/it/you) would grow up, (he/she/it/you) would turn out, (he/she/it/you) would work out, (he/she/it/you) would yet to come, came",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verremmo",
        "translation": "(we) would come, (we) would come in, (we) would come down, (we) would be, (we) would be owed, (we) would come away, (we) would come off, (we) would come out, (we) would come up, (we) would cost, (we) would fade, (we) would get, (we) would grow up, (we) would turn out, (we) would work out, (we) would yet to come, come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verrò",
        "translation": "(I) will come, (I) will come out, (I) will come down, (I) will be, (I) will be owed, (I) will come away, (I) will come in, (I) will come off, (I) will come up, (I) will cost, (I) will fade, (I) will get, (I) will grow up, (I) will turn out, (I) will work out, (I) will yet to come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "versione",
        "translation": "version, model, translation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "verso",
        "translation": "to, towards, toward(s), (I) am, (I) bank, (I) flood, (I) flow, (I) pay, (I) pay in, (I) pour, (I) shed, (I) spill, (I) spill out, (I) tip, around, at, back, call, cry, direction, for, line (of verse), reverse, reversed, round, way",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "vestiti",
        "translation": "dresses, clothes, clothing, dressed, suits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vestito",
        "translation": "dress, suit, three-piece (suit), two-piece (suit)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vi",
        "translation": "you, for you, to you, yourselves",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "via",
        "translation": "away, road, off, because of, duct, get, got, highway, owing to, paths, route, start, starting signal, street, way",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "viaggi",
        "translation": "trips, (you) travel, journeys",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "viaggia",
        "translation": "(he/she/it) travels, (you) travel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viaggiamo",
        "translation": "(we) travel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viaggiano",
        "translation": "(they) travel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viaggiare",
        "translation": "(to) travel, get, got",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viaggiate",
        "translation": "(you) travel",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viaggio",
        "translation": "trip, journey, (I) travel, run, runs, tour, voyage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vicina",
        "translation": "neighbor, close, near, nearby, neighbour",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vicini",
        "translation": "neighbors, close, close to, close by, near (at hand), nearby, neighbor, neighboring, neighbour, neighbouring, similar, sympathetic",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vicino",
        "translation": "close, close to, close by, convenient, near, nearby, neighbor, neighbour, neighbouring",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vide",
        "translation": "see, watch, imagine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "video",
        "translation": "video, video clip, music video, video / visual display unit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vidi",
        "translation": "see, watch, imagine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viene",
        "translation": "(he/she/it) comes, (he/she/it) comes up, (he/she/it) comes in, (he/she/it) arrives, (he/she/it) comes away, (he/she/it) comes down, (he/she/it) comes forward, (he/she/it) comes off, (he/she/it) comes out, (he/she/it) costs, (he/she/it) fades, (he/she/it) faints, (he/she/it) follows, (he/she/it) gets, (he/she/it) grows up, (he/she/it) is, (he/she/it) is owed, (he/she/it) turns out, (he/she/it) works out, (he/she/it/you) yet to come, (he/she/it] is coming, (you) are, (you) are owed, (you) arrive, (you) come, (you) come away, (you) come down, (you) come forward, (you) come in, (you) come off, (you) come out, (you) come up, (you) cost, (you) fade, (you) faint, (you) follow, (you) get, (you) grow up, (you) turn out, (you) work out, come, coming, is",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vieni",
        "translation": "(you) come, coming, you are  coming",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "villaggio",
        "translation": "village",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vincere",
        "translation": "(to) win, (to) beat, (to) defeat, (to) break down, (to) conquer, (to) control oneself, (to) overcome, broke, broken, carried, carry, winning, wins, won",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vino",
        "translation": "wine",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "viola",
        "translation": "purple, (you) violate, (he/she/it) breaks, (he/she/it) breaks into, (he/she/it) infringes, (he/she/it) trespasses on, (he/she/it) violates, (you) break into, (you) infringe, (you) trespass on",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "violenza",
        "translation": "violence, act of violence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "violini",
        "translation": "violins, break, infringe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "violino",
        "translation": "violin, trespass on, break, break into, desecrate, fiddle, infringe, rape, violate, violinist, violins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "virtù",
        "translation": "virtue, moral virtue, by virtue of",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "virus",
        "translation": "(computer) virus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "visi",
        "translation": "face, faces",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "visione",
        "translation": "vision, view, eyesight, sight, views, visions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "visioni",
        "translation": "visions, (you) examine, (you) view, view",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "visita",
        "translation": "(you) visit, visits, official visit, (he/she/it) examines, (he/she/it) goes round, (he/she/it) goes sightseeing in, (he/she/it) inspects, (he/she/it) pays [somebody] a visit, (he/she/it) sees, (he/she/it) tours, (he/she/it) views, (he/she/it) visits, (he/she/it/you) a call, (you) examine, (you) go round, (you) go sightseeing in, (you) inspect, (you) pay [somebody] a visit, (you) see, (you) tour, (you) view, call, medical examination, saw, see, seen, state visit, view, views",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "visitare",
        "translation": "(to) visit, (to) to see, (to) to go round, (to) a call, (to) examine, (to) inspect, (to) pay [somebody] a visit, (to) see, (to) to go sightseeing in, (to) tour, (to) view, go round, go sightseeing in, look, saw, see, seen, view, views, visits, walk, walks",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visite",
        "translation": "visits, (he/she/it) visits, (he/she/it) goes round, (you) visit",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "visitiamo",
        "translation": "(we) visit, (we) tour, (we) inspect, (we) a call, (we) examine, (we) go round, (we) go sightseeing in, (we) pay [somebody] a visit, (we) see, (we) view, saw, see, seen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viso",
        "translation": "face",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "visse",
        "translation": "(he/she/it/you) lived, (he/she/it/you) lived through, (he/she/it/you) went through, live",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vista",
        "translation": "seen, view, saw, (you) stamp, aspects, exposed, eyesight, on demand, sight, views, visible, vision, visions, whithout instruments, within sight of",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vita",
        "translation": "life, alive, living, waist, waistline",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vite",
        "translation": "lives, (grape)vine, screw",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vittima",
        "translation": "victim, sacrificial victim, casualty",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vittime",
        "translation": "victims, casualty, victim",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "viva",
        "translation": "alive, go (team)!, living",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vivace",
        "translation": "lively, active, bright, vivid",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "vivano",
        "translation": "(may they) live",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vive",
        "translation": "(he/she/it) lives, (he/she/it) experiences, (you) experience, (you) live, alive, lives",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vivendo",
        "translation": "living, living through, by living, going through",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivere",
        "translation": "living, (to) live, (to) experience, (to) go through",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivesse",
        "translation": "(he/she/it/you) lived, (he/she/it/you) lived through, (he/she/it/you) went through",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivessero",
        "translation": "(they) lived, (they) lived through, (they) went through",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivete",
        "translation": "(you) live, (you) experience",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viveva",
        "translation": "(he/she/it/you) was/were living, (he/she/it/you) lived, (he/she/it/you) used to live",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivevamo",
        "translation": "(we) lived, (we) were living, (we) used to live",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivi",
        "translation": "(you) live, (you) experience, alive, living",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivo",
        "translation": "alive, (I) live, (I) experience, acute, bright, lively, living, sharp, vivid",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vivono",
        "translation": "live, (they) live, (they) have been living",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivrà",
        "translation": "live, (he/she/it/you) will live, (he/she/it/you) will live through, (he/she/it/you) will cope with",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivranno",
        "translation": "live, (they) will live, (they) will live through, (they) will cope with",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivrebbe",
        "translation": "live, (he/she/it/you) would live, (he/she/it/you) would live through, (he/she/it/you) would cope with",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivrei",
        "translation": "(I) would live",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivrò",
        "translation": "live, (I) will live, (I) will live through, (I) will cope with",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voce",
        "translation": "voice, cry, call, entries, entry, heading, headword, hearsay, item, lemma, rumor, rumour, song, story",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "voci",
        "translation": "voices, (you) shout, (you) yell, (you) clamour, entries, talk",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "voglia",
        "translation": "want, (she/he/it) wants, craving, desire, will, won't",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vogliamo",
        "translation": "want, (we) want, (we) take",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vogliano",
        "translation": "want, (they) want",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voglio",
        "translation": "(I) want, (I) take",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vogliono",
        "translation": "want, (they) want, (they) take, (they) need",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voi",
        "translation": "you, you (plural), you all",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "volano",
        "translation": "(they) fly, (they) fly away / off, (they) blow around",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volare",
        "translation": "(to) fly, (to) to fly, (to) fly away / off, (to) blow away / off, (to) fall, (to) rush, (to) to blow around, (to) to zoom, blow around, zoom",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volendo",
        "translation": "would like, requiring, taking, needing, wishing, being needed, meaning, wanting, being required",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voler",
        "translation": "(to) mean, (to) want, (to) take, (to) be needed, (to) be required, (to) need, (to) require, (to) wish, (to) would like",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volesse",
        "translation": "(he/she/it/you) wanted, (he/she/it/you) meant, (he/she/it/you) wished, wanted",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volessi",
        "translation": "(I/you) wanted, (I/you) meant, (I/you) wished",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volessimo",
        "translation": "(we) wanted, (we) meant, (we) wished, wanted",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volete",
        "translation": "want, (you) want, (you) take, (you) are needed, (you) are required, (you) mean, (you) need, (you) require, (you) wish, (you) would like, be needed, be required, do you want, like",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voleva",
        "translation": "wanted, (he/she/it/you) wanted, (he/she/it/you) used to want, (he/she/it/you) meant, (he/she/it/you) needed, (he/she/it/you) required, (he/she/it/you) took, (he/she/it/you) used to be needed, (he/she/it/you) used to be required, (he/she/it/you) used to mean, (he/she/it/you) used to need, (he/she/it/you) used to require, (he/she/it/you) used to take, (he/she/it/you) used to wish, (he/she/it/you) was/were being needed, (he/she/it/you) was/were being required, (he/she/it/you) was/were meaning, (he/she/it/you) was/were needed, (he/she/it/you) was/were needing, (he/she/it/you) was/were required, (he/she/it/you) was/were requiring, (he/she/it/you) was/were taking, (he/she/it/you) was/were wanting, (he/she/it/you) was/were wishing, (he/she/it/you) wished, liked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volevano",
        "translation": "wanted, (they) wanted, (they) used to want, (they) took, (they) were wanting, liked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volevi",
        "translation": "wanted, (you) wanted, (you) used to want, (you) meant, (you) needed, (you) required, (you) took, (you) used to be needed, (you) used to be required, (you) used to mean, (you) used to need, (you) used to require, (you) used to take, (you) used to wish, (you) was/were needed, (you) was/were required, (you) were being needed, (you) were being required, (you) were meaning, (you) were needing, (you) were requiring, (you) were taking, (you) were wanting, (you) were wishing, (you) wished, liked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volevo",
        "translation": "wanted, (I) wanted, (I) used to want, (I) meant, (I) needed, (I) required, (I) took, (I) used to be needed, (I) used to be required, (I) used to mean, (I) used to need, (I) used to require, (I) used to take, (I) used to wish, (I) was/were being needed, (I) was/were being required, (I) was/were meaning, (I) was/were needed, (I) was/were needing, (I) was/were required, (I) was/were requiring, (I) was/were taking, (I) was/were wanting, (I) was/were wishing, (I) wished, liked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voli",
        "translation": "flights, (you) fly, (you) zoom, (you) rush",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volle",
        "translation": "wanted, want, (he/she/it/you) wanted, (he/she/it/you) meant, (he/she/it/you) needed, (he/she/it/you) required, (he/she/it/you) took, (he/she/it/you) was/were needed, (he/she/it/you) was/were required, (he/she/it/you) wished, like, liked",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volo",
        "translation": "flight, (I) fly, (I) zoom, (I) blow around, (I) blow away / off, (I) fall, (I) fly away / off, (I) rush",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volta",
        "translation": "time, arch",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "volte",
        "translation": "times",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vorrà",
        "translation": "want, (he/she/it/you) will want, (he/she/it/you) will take, (he/she/it/you) will mean, (he/she/it/you) will need, (he/she/it/you) will wish, like, liked, wanted",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vorrebbe",
        "translation": "like, want, (he/she/it/you) would want, (he/she/it/you) would mean, (he/she/it/you) would need, (he/she/it/you) would take, (he/she/it/you) would wish, liked, wanted",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vorrei",
        "translation": "(I) would like, (I) would want, (I) would be needed, (I) would be required, (I) would mean, (I) would need, (I) would require, (I) would take, (I) would wish, liked, want, wanted",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vorrò",
        "translation": "want, (I) will want, (I) will mean, (I) will need, (I) will take, (I) will wish, like",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vostre",
        "translation": "your, yours",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "vostri",
        "translation": "your, yours",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "vostro",
        "translation": "your, yours",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "votare",
        "translation": "(to) vote (through), (to) to dedicate, (to) to give one’s vote, (to) devote, (to) devote oneself, (to) pass, dedicate, give one’s vote",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voti",
        "translation": "grades, votes, (you) dedicate, (you) devote, (you) devote oneself, (you) give one’s vote, (you) pass, (you) vote (through)",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vulcano",
        "translation": "volcano, Vulcan",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vuoi",
        "translation": "(you) want, do you want",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuole",
        "translation": "want, (he/she/it) wants, (you) want, (he/she/it) needs, (he/she/it) takes, (you) need, (you) take, wants",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuota",
        "translation": "empty, clear",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vuoto",
        "translation": "(I) empty (out), (I) clear out, (I) drain, bare, blank, clear, free, gap, hollow, in vain, opened, space, to no purpose, unoccupied, used, vacant, vacuous, vacuum, void",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "zero",
        "translation": "zero",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "zia",
        "translation": "aunt",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "zie",
        "translation": "aunts",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "zio",
        "translation": "uncle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "zona",
        "translation": "area, (you) zone, district, (he/she/it) zones, patch, region, regions, territory, zone",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "zone",
        "translation": "zone, area",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "zoo",
        "translation": "zoo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "zucchero",
        "translation": "sugar, (I) sugar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "zuppa",
        "translation": "soup",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    }
]

export default wordList;