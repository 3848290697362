const wordList = [
    {
        "word": "a",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "a continuación",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a cuánto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a dónde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a él",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a él le",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a él le gustan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a ella le",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a ella le gusta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a fin de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a fin de cuentas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a fondo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a la perfección",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a la venta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a lo largo de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a lo largo del",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a lo mejor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a menudo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a mí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a mí me",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a mí me gusta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a nosotros",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a pesar de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a pie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a primera hora",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a primera vista",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a propósito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a punto de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a qué distancia",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a qué hora",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a ti",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a ti te",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a ti te gustan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a tiempo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a través de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a través del",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a usted",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a ustedes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a veces",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "abajo",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "abandonar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abierta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "abiertas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "abierto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "abiertos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "abogada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "abogadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "abogado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abogados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abrazarte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "abrazo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abres la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "abrí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abriendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abrieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abrigo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abrigos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abril",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abrimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abrió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abrirla",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "abrirlo",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "abrirlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "abrís",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abriste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abro",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "absolutamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "abuela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "abuelas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "abuelita",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "abuelito",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abuelitos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "abuelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "abuelos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aburridas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "aburridísima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "aburridísimas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "aburridísimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "aburridísimos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "aburridos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "acá",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "acaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acaba de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "acabamos de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "acaban de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "acabar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acabas de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "acabo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acabo de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "academia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "acampaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acampando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acamparán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acamparé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acamparemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acampas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acceso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "acción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "acciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aceite",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aceites",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "acento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "acepta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aceptar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aceptaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aceptó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acepto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acerca",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "ácido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "acompañaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompañaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompañar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompañará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompañarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompañaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompañaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompañas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompañó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acompaño",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aconseja",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aconsejado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "aconsejan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aconsejas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aconsejo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acordarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "acordarnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "acordarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "acostarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "acostarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "acostarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "actitud",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "actitudes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "activa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "activas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "actividad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "actividades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "activo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "activos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "acto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "actor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "actores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "actos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "actuación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "actual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "actualicé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "actualizando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "actualizar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "actualizará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "actualizaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "actualizó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "actualmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "actuar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acuerdo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "acuerdos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "adecuada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "adecuado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "adelante",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "además",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "además de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "adentro",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "adicional",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "adicionales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "adiós",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "adivinaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "administración",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "adónde",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "adulta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "adultas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "adultos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aeropuerto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aeropuertos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "afecta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "afectan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "afortunadamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "afuera",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "agencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "agente",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "agosto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "agradables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "agradecer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agradecerle",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "agradezco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agrega",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agregar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agregué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agreguemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agricultor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "agricultores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "agricultura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "agua",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aguacate",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aguacates",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ah",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "ahí",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ahora",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ahora mismo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ahora sí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ahorrar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ahorros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aire acondicionado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ajedrez",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ajo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "al",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "al aire libre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al conducir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al cruzar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al entrar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al escuchar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al final",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al fondo de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al fondo del",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al lado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al llegar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al menos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al mismo tiempo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al regresar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al revés",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al salir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "al ver",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "alcalde",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alcaldesa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "alcanza",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "alcanzar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "alcanzó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "alcohol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alcoholes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alegre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "alegres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "alegría",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "alejada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "alejadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "alejado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "alejados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "alemania",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "alfabetos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alfombra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "alfombras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "algo",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "algo de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "algo más",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "alguien",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "algún",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "algún día",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "alguna",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "alguna vez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "algunas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "alguno",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "algunos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "allá",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "allí",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "alma",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "almas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "almohadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "almorcé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorcemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorzábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorzaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorzabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorzamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorzar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorzaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorzaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almorzó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almuerza",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almuerzan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almuerzas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "almuerzo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "alojamiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alquilar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "alquiler",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alrededor",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "alrededor del",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "alta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "altas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "alternativa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "alternativas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "alto",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "altos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "altura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "alturas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aluminio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alumnos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ama de casa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "amaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amabilidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "amar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amarilla",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "amarillas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "amarillo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "amarillos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "amaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amas de casa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "amaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ambas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "ambiente",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ambos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "ambulancia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ambulancias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "américa",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "americana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "americanas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "americano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "americanos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "amiga",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amigo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "amigos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "amistad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "amplia",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "amplio",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "añade",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "añadir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "añadís",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "añado",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "análisis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "análisis de sangre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "analizar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "anchas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "anchos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "anda",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "andén",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "anduve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "anduvieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "anduvimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "anduviste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "anduvo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ángel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ángeles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anillo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anillos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "animada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "animado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "animal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "animales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aniversario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "año",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "año nuevo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "anoche",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "años",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ansiosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ansiosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "anteayer",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "anteriormente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "antes",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "antes de que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "antibiótico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "antibióticos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "antigua",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "antiguas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "antiguo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "antiguos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "antipáticas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "antipático",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "antipáticos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "antivirus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "anuncio",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "anuncios",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "apaga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apagaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apagábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apagaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apagada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "apagadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "apagado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "apagados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "apagar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apagaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apagas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apagaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apagó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aparato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aparece",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aparecen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aparecer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apareció",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aparentemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "apartamento",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "apenas",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "aplicación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aplicaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aplicar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprenda",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprenden",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprenderíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprenderían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendiendo a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aprendieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apretada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "apretadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "apretados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "aprobamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprobar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprobarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprobaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprobaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aprobé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apropiadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "apropiado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "apropiados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "aproximadamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "aprueba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apruebas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apruebo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apuntes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aquel",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "aquella",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "aquellas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "aquellos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "aquí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aquí mismo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "araña",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "árbitro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "árbol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "árboles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "archivo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "archivos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "área",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "áreas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arena",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "argentina",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "argentino",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "argumento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "argumentos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arma",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "armario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "armas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arquitectura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arreglamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arreglar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arreglaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arreglé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arresto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arrestos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arriba",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "arroba",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arrogante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "arroz",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "artes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "artículo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "artículos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "artista",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "artistas",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "asa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "asado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "asará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ascensor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "asesinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "asesino",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "asesinos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "así",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "así que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "asientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "asignatura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "asignaturas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "asistente",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aspecto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aspectos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aspiradora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aspirina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aspirinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "asumir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asunto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "asuntos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "atájaselo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ataque",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "atención",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "atención médica",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "atender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "atendió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "atentamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "atiende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "atienden",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "atleta",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "atletas",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "atletismo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "atmósfera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "atractivo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "atractivos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "atrás",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "atún",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "audiencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aumentar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aumentará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aumentaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aún",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "aún así",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aunque",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "aunque sea",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "auriculares",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ausencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ausencias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "auto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "autobiografía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "autobús",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "autobuses",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "autopista",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "autor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "autora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "autoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "autos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "auxilio",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "avanzada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "avanzado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "avena",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "avenida",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "avenidas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aventura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aventuras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "avión",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aviones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avisamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avisaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avisaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avisaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avisó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aviso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ay",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "ayer",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ayuda",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayudaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayudábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayudaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayudabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayúdalo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ayúdame",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ayudamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayudan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayudándolas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "ayudar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayudas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ayudaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayude",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayúdenos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ayudo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayudo a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "azúcar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "azul",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "azules",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "bádminton",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "baila",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailarín",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bailarina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bailarinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bailaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "baile",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bailé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bailó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "baja",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bajar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bajarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "bajarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "bajas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bajita",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bajitas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bajito",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bajo",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "bajo cero",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bajos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "balcón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ballet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "baloncesto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "balonmano",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "banana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bananas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bananos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "banco",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bancos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bandera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "banderas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "baño",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "baños",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "barata",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "baratas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "barato",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "baratos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "barba",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "barco",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "barcos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bares",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "barre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "barrer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "barría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "barrio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "básica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "básicamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "básicas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "básico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "básicos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bastante",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "bastantes",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "basura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "basureros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "batería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "baterías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "batir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebé",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bébela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bébelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bébelos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bebemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beben",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beber",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beberá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beberán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beberás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beberé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beberemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebería",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beberían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beberías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beberla",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "beberlas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "beberlo",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "beberlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "bebés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bebes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebíais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bebo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "beca",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "becas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "béisbol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "belleza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bellísima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bellísimas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bellísimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bellísimos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "beneficio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "beneficios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "besar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "besaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "biblioteca",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bibliotecas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bici",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bicicleta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bicicletas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bien",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bienestar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bienvenida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bilingüe",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "biografía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "biología",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bistec",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "blanca",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "blancas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "blanco",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "blancos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "blog",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "boca",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bodas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bola",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bolas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "boleto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "boletos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bolígrafo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bolígrafos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bolsa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bolsas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bomba",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bombas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bonita",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bonitas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bonito",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bonitos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "borrador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "borradores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "borramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "borrar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "borraste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "borré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bosque",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bota",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "botas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "botas montañeras",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "botella",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "botellas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "botón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "boxeo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "brazalete",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "brazaletes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "brazo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "brazos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "brevemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "breves",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "brillante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "brisa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "británico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brócoli",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "buen",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "buen provecho",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "buen tiempo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "buena",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "buenas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "buenas noches",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "buenas tardes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "buenísimas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "buenísimos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bueno",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "buenos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "buenos días",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bufanda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bufandas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "búhos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "busca",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buscamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buscan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buscando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buscar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buscaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buscas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buscó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "busco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "busqué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "búsqueda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "búsquedas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "busquemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buzón de voz",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "caballero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caballo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caballos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cabaña",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cabe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caben",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cabeza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cabezas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cable",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cada",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "cadáver",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cadáveres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cadena",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cadenas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cae",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cae granizo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "caen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caer granizo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "caernos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "caerte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "café",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "cafés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cafetera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cafetería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cafeterías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caída",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caigo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caja",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caja registradora",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cajas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cajas registradoras",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cajeras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cajero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cajero automático",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cajeros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cajeros automáticos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cajón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cajones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calcetín",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calcetines",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calculadora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "calculadoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "calefacción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "calendario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calendarios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "calientes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "callados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "calle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "calles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "calor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calurosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "calurosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cama",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cámara",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "cámara web",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cámaras",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "camarera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camareras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camarero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "camareros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "camas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cambiamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambiaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cambio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cambios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "camina",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminabais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "camine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "camino",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "caminó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "camisa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camisas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camiseta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camisetas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "campamento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campamentos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "campaña",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "campanas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "campeón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campeonato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campeonatos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campeones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campesino",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campesinos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cancelación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cancelaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cancha",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cancha de golf",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "canchas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "canchas de golf",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "canción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "canciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "candidata",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "candidato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "candidatos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cangrejo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cangrejos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cansada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cansadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cansadísimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cansado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cansados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "canta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantantes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "cantar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "canté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cantidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "canto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cantó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "capacidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "capaz",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "capital",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "capitales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "capitán",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "capitana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "capítulo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "capítulos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cara",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cara a cara",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "carácter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caracteres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carácteres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cárcel",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cargo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cargos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caribe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carísima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "carísimas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "carísimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "carísimos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "carnaval",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carnavales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "carnes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carnicería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caro",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "caros",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "carpetas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carpintera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carpintero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carrera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carreras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carretera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cartas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cartera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carteras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "casa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "casada",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "casado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "casaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "casas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "casi",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "casi nunca",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "castaño",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "castillo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "categoría",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "categorías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "católica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "católico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "catorce",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "causa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "causaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "causas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "causó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cayó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cayó granizo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cebolla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cebollas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "celebración",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "celebraciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "celebramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "celebremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "celebren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "celular",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "celulares",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "células",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cena",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cenaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cenábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cenaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cenamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cenan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cenar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cenaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cenas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cenó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "centavos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centímetros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centro comercial",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "centroamérica",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "centros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centros comerciales",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cepillabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cepillarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "cepillo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cepillo de dientes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cepillos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cerca",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "cerca de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cercana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cercano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cerdo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cerdos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cereal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cereales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cerebro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cerebros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ceremonia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ceremonias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cereza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cerezas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cerrado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cerráis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerrar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerraron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cerveza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cervezas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "césped",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "champán",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "champanes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "champiñones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chaqueta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "chaquetas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chateamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chatearon",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chateaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chateé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chateó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chef",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "chica",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chicas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chicos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chilena",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "chileno",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "chilenos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "china",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "chino",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "chocarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chocaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chocas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chocó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chocolates",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chorizo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chorizos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ciclismo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cielo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cielos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cien",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "ciencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ciencia ficción",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ciencias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "científica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "científicas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "científico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ciento",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "cierra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cierran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cierras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cierras la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cierre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cierren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cierro",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cierro la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ciertas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cierto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ciertos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cifra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cifras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cigarrillos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cinco",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cincuenta",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cine",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cintura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cinturón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cinturón de seguridad",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cinturones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cinturones de seguridad",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "circo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "circulación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "círculo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "círculos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "circunstancia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "circunstancias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cirujana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cirujano",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cirujanos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cita",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ciudad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ciudad natal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ciudadana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ciudadano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ciudadanos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ciudades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "civil",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "civiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "clara",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "claramente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "claras",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "claro",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "clase",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clases",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clásica",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clásicas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "clásico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "clásicos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "clave",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clic",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "clima",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "clínica",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clínicas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "club",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "clubes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cobrar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cobraron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cobró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coche",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coches",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cocina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cocinaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocinada",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "cocinado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "cocinamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocinan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocinando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocinándolas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "cocinar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocinaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cocinaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocinera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cocineras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cocinero",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cocineros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cocinó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocino",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cocodrilo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cocodrilos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "código",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "código postal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "códigos postales",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "codo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "codos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coge",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cogemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cogen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coges",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cogí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cogieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cogimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cogió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cogiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cojo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cola",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "colección",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "colecciona",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coleccionaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coleccionabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coleccionan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "colegio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colegios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "collar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colocar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "colombiana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "colombiano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "colonia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "colonias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "color",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "columna",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "columnas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coman",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comandante",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "come",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comedia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comedias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comedor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comedores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comencé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comencemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comentario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comentarios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comenzamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comenzar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comenzaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comenzaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comenzó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comer mucho",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comer una",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comerá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comerán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comerás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comercio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comeré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comeremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comería",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comerían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comerías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comerla",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "comerlas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "comerlo",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "comerlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "comes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cometa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cometas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comíais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cómics",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comida",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comiences",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comienza",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comienzas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comienzo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "comieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comisaría",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "como",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cómo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "como",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "cómo está",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómo está el tiempo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómo le va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómo puedo ir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómo quiere pagar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómo quieres pagar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómo se dice",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómo se escribe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "como siempre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómo te va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cómoda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cómodas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "compañera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "compañera de cuarto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compañeras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "compañeras de clase",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compañeras de cuarto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compañero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compañero de clase",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compañero de cuarto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compañeros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compañeros de clase",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compañeros de cuarto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compañía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "compañías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "compartan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compartía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compartían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compartías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compartiré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compartiremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "competencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "competencias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "competidoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "competidores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "competir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "competiría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "competirían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "competirías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "completa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "completamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "completando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "completar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "completaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "completaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "completen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "completó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comportamiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compositor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compositora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "compositores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compraba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compraban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compráis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compran",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comprándoles",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "comprar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprarlas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "comprarlo",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "comprarlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "compraron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compraste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprenden",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compro",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprobar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compromiso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "computadora",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "computadoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "común",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "comunes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "comunicación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comunicaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comunidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "comunidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "con calma",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "con gas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "con llave",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "con nadie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "con qué",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "concejo municipal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "concentración",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "concepto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conceptos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conciencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "concierto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conciertos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conclusión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conclusiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "concurso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "condición",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "condiciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conducir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conducta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conductor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conductora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conductoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conductores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conduje",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "condujimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduzco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conectamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conectar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conectarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "conectaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conectas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conecté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conectó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conejo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conejos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conexión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conferencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conferencias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "confiaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "confiábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "confiaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "confirmada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "confirmadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "confirmado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "confirmar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "confirmen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "confirmó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "confirmo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conflicto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conflictos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "confortable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "confortables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "confusión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "congelador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "congreso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conmigo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "conoce",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocerían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoces",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocíais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocida",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "conocido",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "conocidos",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "conociendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conocimiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conocimientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conocimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conoció",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conociste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conozco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consciente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "consecuencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "consecuencias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conseguir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conseguiríamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consejo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "consejos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conservación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "considera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consideramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consideran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "considerar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consideró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "considero",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consigo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consigues",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consiguió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "constitución",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "construcción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "construimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construirá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construirán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construiré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construiremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construye",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construyen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construyes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construyo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consulta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consultarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consultaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consulté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consulto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consultorio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "consultorios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contabilidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "contactar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contactaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contactaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contacté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contacto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contactó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contactos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contadora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "contaminada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "contamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contemporánea",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "contemporáneo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "contenido",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contenidos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contenta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "contestará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contestarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contesto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contestó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contiene",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contigo",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "continente",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "continentes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "continúa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "continuación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "continuamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "continúan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "continuar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "continúas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contra",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "contratando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contratar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contratarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contraté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contrato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contratos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "control",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "control remoto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "controlar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "convenceré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "convenceremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "convencerlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "convencerte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "conveniente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "conversaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conversaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conversador",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "conversadora",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "conversadores",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "conversamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "converso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cooperación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "copa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "copia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "copiaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "copiamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "copiando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "copias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "copiaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corazón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corazones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corbata",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corbatas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coronel",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "corre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "correctamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "corredor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corredora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corredoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corredores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corregirás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corregirlas",
        "partOfSpeech": "",
        "gender": "Feminine"
    },
    {
        "word": "corremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "correo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "correo electrónico",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "correos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "correos electrónicos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "correr",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corríamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corriendo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "corriente",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corrientes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corrieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrige",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrigen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corriges",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrigiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrijo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corriste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corro",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corrupción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cortáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cortan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cortaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "corte",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cortes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "corto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cosa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cosas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "costa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "costaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "costo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "costó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "costos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "costumbre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "costumbres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "crea",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "creamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creativa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "creativo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "crecí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crecieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crecimiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "crecimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creció",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creciste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crédito",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "créditos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cree",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creéis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creíais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creímos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creíste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crema",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "creó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "creo que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "creo que debería",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "creo que deberías",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "creyeron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "criaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crié",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crimen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "crió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crisis",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "críticas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "crucé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cruce peatonal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "crucero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cruces",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cruda",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "crudos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cruel",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "crueles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "cruz",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cruzamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cruzaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cruzaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cruzó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuaderno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuadernos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuadra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cuadrada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cuadradas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cuadrado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cuadrados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cuadras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cuadro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuadros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuál",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "cuál quiere",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cuál quieres",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cuáles",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "cuáles quiere",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cualquier",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "cualquier lugar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cualquiera",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "cuándo",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "cuando",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "cuánta",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "cuántas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "cuánto",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "cuanto antes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cuánto hace que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cuántos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "cuantos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "cuántos años",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cuarenta",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cuarto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuartos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuatro",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cuatrocientos",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "cuba",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "cubana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cubanas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cubano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cubanos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cubiertas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cubierto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cubiertos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cubre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cubrieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cubrió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cubrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuchara",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cucharas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cuchillo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuchillos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuello",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuellos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuenta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuenta corriente",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cuentan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuentas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cuento",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuentos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuerpo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuerpos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuesta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuestan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuida",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuidaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuidábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuidaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuidabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuidan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuidar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuidas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "culpa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cultura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "culturas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cumbre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cumpleaños",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuna",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cunas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cura",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "curiosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "curiosidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "curiosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "currículum",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "curso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cursos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "da",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "da igual",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "daba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "daba igual",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "daban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dale",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dales",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dame",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dámela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dámelas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dámelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dámelos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "daña",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dañar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dañó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "daño",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "daños",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "danza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "darás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "darle",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "darme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "darte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "dáselo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "datos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "de",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "de acuerdo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de al lado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de alto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de compras",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de hecho",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de ida",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de ida y vuelta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de inmediato",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de largo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de madera",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de más",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de moda",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de nada",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de niña",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de niño",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de nuevo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de pie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de regalo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de repente",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de segunda mano",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de talla única",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de terror",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de tiempo parcial",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de todas formas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de todas maneras",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de vacaciones",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de verdad",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de vez en cuando",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "debajo",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "debe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "debe haber",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "debe haber sido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "debemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deben",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deberá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "debería",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deberíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deberían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deberías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "debes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "debía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "débil",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "débiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "debió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "debo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decida",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deciden",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "decidí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decidiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decirle",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "decirles",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "decirme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "decirnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "decirte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "decís",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "decisión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "decisiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "declaración",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "declaraciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "decoración",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dedicada",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "dedicado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "dedo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dedos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "defender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "defensa",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "déficit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "definición",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "definiciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "definir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "definitivamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "deja",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejaría",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dejaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dejó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "del",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "delante de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "deles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "delgada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "delgados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "deliciosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "delicioso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "demás",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "demasiada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "demasiadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "demasiado",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "demasiados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "democracia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "demócrata",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "demócratas",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "democrática",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "democrático",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "demostrar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dentro",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "departamento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "departamentos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "depende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deporte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "deportes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "depositar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "depositó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "depósito",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "deposito",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "depresión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "depresiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "deprimido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "derecha",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "derecho",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "derechos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "derrota",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "derrotan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "des",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desacuerdo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "desafortunadamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "desarrollar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desayunaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desayunábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desayunaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desayunamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desayunan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desayunar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desayunó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desayuno",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descanse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descanso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descansos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "descárgalo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "descargamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descargando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descargaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descargó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descargué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descárguela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "descarguemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descenso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "desconocida",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "desconocidas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "desconocido",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "descripción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "descripciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "descubrieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descubrimiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "descubrimientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "descubrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descubrirán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descubriremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descuento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "desde",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "desde ahora",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "desde cuándo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "desde hace",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "desde que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "desea",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "desean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deseas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déselo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "desempleada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "desempleadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "desempleado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "desempleo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "deseo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deseos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "desorden",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "desordenada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "desordenado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "desorganizada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "desorganizadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "desorganizado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "desorganizados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "despacio",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "despeja",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "despejado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "despejar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "despertador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "despertar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "despertarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "despertarnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "despertarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "despertarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "despierta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "despiertas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "despierto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "despiertos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "después",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "después de que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "destino",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "destrucción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "destruimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "destruir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "destruirá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "destruirán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "destruye",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "destruyen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "destruyes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "destruyo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "detalle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "detalles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "detenido",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "detenidos",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "detrás",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "deuda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "deudas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "devolvemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devolver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devuelven",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devuelves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devuelvo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "di",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "día",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "día de acción de gracias",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "día de los muertos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "diagnóstico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diálogo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diaria",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "diarias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "diario",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "diarios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "días",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dibujaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dibujos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dibujos animados",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dice",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dicen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dices",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diciembre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diciendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dictadura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dictaduras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "diecinueve",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "dieciocho",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "dieciséis",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "diecisiete",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "diente",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dientes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dieta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dietas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "diez",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "diferencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "diferencias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "diferente",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "diferentes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "difícil",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "difíciles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dificilísimo",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dificultad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dificultades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "diga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dígale",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "dígales",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dígame",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dígamelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "digamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "digas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dígaselo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "digital",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "digitales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "digo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dije",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dijeron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dijimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dijiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dijo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dile",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "diles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dime",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dímelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dimensiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dinero",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dinosaurio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dinosaurios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dio",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diosa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "diosas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dioses",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dirán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dirección",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "direcciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "director",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "directoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "directos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "diría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diríamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dirían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dirías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disciplina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "disciplinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "disco",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "discos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "disculpa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disculpe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disculpe, señor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "discurso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "discusión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "discute",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "discutí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "discutir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "díselo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "diseña",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diseñar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diseñé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diseñó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfraces",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "disfruta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfruté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfruten",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfrutó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disfruto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disminución",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "disponible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "disponibles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "distancia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "distancias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "diste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "distinta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "distintas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "distinto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "distintos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "distribución",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "distribuciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "divertida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "divertidas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "divertidísima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "divertidísimas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "divertidísimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "divertidísimos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "divertido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "divertidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "divertirme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "divertirte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "división",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "divorciada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "divorciado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "divorciados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "divorciarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "divorcio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "doblamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "doblar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "doblas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "doble",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dobles",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "doce",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "doctor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "doctora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "doctoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "doctores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "documental",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "documentales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "documento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "documento de identidad",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "documentos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dólar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dólares",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "doler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dolor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "domingo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "domingos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dónde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "donde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dorada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "doradas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "dorado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "dormí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "dormidas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "dormido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "dormidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "dormimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dormiríamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormirían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormitorio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dormitorios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dos",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "doscientos",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "dosis",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "doy",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dr.",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dra.",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "duda",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dudas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dudo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "duele",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "duelen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dueña",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dueñas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dueño",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dueños",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "duerme",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "duermen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "duermes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "duermo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dulce",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dulces",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dura",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "durante",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "duras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "durazno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "duraznos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "durmiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "durmieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "durmió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "duro",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "duró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "e",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "ecología",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "economía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "economías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "económica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "económico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "económicos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "edad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "edición",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "edificio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "edificios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "editorial",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "educación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "efectivo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "efecto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "eficaz",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "egoísta",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "egoístas",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ejecutiva",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ejecutivas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ejecutivo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ejecutivos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ejemplo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ejemplos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ejercicio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ejército",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "el",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "él",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "el ajedrez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el azul",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el baloncesto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el café",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el ciclismo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el español",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el fútbol",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el inglés",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el más",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el mejor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el mío",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el otro día",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el peor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el rojo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "él se",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "el uno al otro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "elección",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "elecciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "electoral",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "electorales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "eléctrica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "eléctricas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "electricidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "eléctrico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "eléctricos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "electrodoméstico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "electrodomésticos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elefante",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elefantes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elegante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "elegantes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "elegí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "elegimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "elegir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "elegiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "elemento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elementos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "elevado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "elige",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eligen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eliges",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eligiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eligieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "elijo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eliminar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ella",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "ella come",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ellas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "ellas escriben",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ellas se",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ellos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "ellos hablan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ellos leen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ellos se",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "embajada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "embajador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "embajadora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "emergencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "emoción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "emocionada",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "emocionadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "emocionado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "emocionados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "emocionante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "emociones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "empaqué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "emparedado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "emparedados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "empatado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "empatar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empataron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empecé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empecemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empezamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empezamos a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "empezar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empezarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empezarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empezaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empezaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empezó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empieces",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empieza",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empiezan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empiezas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empiezo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empleada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "empleadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "empleado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "empleados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "empresa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "empresaria",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "empresarias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "empresario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "empresarios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "empujando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "empuje",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en alquiler",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en común",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en contacto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en contra de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en cualquier caso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en cualquier momento",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en cuanto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en cuanto a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en efectivo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en el",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en este momento",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en familia",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en forma",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en general",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en línea",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en mi vida",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en ninguna parte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en oferta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en primer lugar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en punto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en realidad",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en secreto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en segundo lugar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en serio",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en su lugar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en todas partes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en total",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en tu lugar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en vez de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en vivo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en voz baja",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "enamorados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "encantaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encantaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encantada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "encantadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "encantado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "encantados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "encantan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encantar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encantaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encantarían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encantaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encantó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encendemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encendí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encendía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encendíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encendían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encendías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encendida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "encendidas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "encendido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "encendidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "encendieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encendiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enchufar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enchufas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encima",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "encontraba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encontrado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "encontramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encontrar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encontrarnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "encontraron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encontrarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "encontraste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encontré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encontremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encontró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encuentra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "encuentran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enemigo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "enemigos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "energía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "energías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "enero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "enfadadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "enfadados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "enferma",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "enfermedad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "enfermera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "enfermeras",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "enfermero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "enfermeros",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "enfermo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "enfermos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "enfrente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "enojada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "enojadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "enojado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "enojados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "enorme",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "enormes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ensalada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ensaladas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "enseguida",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "enseña a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "enséñame",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "enseñamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseñan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseñando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseñándoles",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "enseñar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseñaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseñas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseñaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseñé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseñe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entendemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entendimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entendiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entera",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "enterado",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enteramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enteraron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enteraste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enteré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enteró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entiende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entienden",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entiendes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entonces",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "entra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entraba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entraban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "entradas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "entráis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entre",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "entrega",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entregan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entregar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entregas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrego",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entregó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entretenida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "entretenido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "entretenidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "entrevista",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "entró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enviamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enviándole",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "enviar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envié",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envíe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envíele",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "envíeles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "envíesela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "envíeselas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "envío",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "equilibrio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "equipaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "equipo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "equipo de música",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "equipos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "equivocada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "era",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "era necesario que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "erais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "éramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "error",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "errores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "es",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "es bueno que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es decir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es divertido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es estresante que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es fantástico que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es grande",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es importante que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es mejor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es mejor que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es muy simpático",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es necesario que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es posible que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es preferible que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es probable que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es segura",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es seguro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es tan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es una lástima que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es una pena que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es urgente que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "es verde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "esa",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "esas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "escalar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escalaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escalaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escalaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escalera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "escaleras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "escándalo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "escaparon",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escapen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escapó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escasa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "escaso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "escena",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "escenario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "escenas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "escoger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escogerás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escojo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escriba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escríbelos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "escriben",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribirás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribiremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribiría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribirle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "escribiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escribo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escrito",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "escritorio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "escritorios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "escuchaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escuchábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escuchabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escuchamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escuchando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escuchar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escuche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escuchen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "escuela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "escuela primaria",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "escuela secundaria",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "escuelas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esculturas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ese",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "esfuerzo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esfuerzos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "eso",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "esos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "espacio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espacios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espalda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "espaldas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "españa",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "español",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "española",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "especial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "especial del día",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "especiales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "especialidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "especialista",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "especialistas",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "especialmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "especias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "especie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "especies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "espectacular",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "espectaculares",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "espectáculo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espectáculos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espejo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espejos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esperaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esperabais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esperáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esperamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esperando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esperanza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esperar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esperaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "espere",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "espero",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "espíritu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espíritus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esponja",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esponjas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esposa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esposas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esposo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esposos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esquía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esquiaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esquiábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esquiaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esquiamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esquían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esquiar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esquías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "esquina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esquinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "esquío",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "está",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ésta",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "esta",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "está",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "está bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "está listo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "está rico",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "está segura",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estabais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estabilidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "establecer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estacionamiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estacionamientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estacionar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estacionaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estacionaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estacione",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estadio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estado civil",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estados unidos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estadounidense",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "estáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estampillas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "están",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "están listas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "están listos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "están ricas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "están ricos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "están seguros",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estante",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estantería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estanterías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estantes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estar de acuerdo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estarían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estarías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "éstas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "estás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estás lista",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estás segura",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "este",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "éste",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "estilo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estilos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esto",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "estómago",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estómagos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "éstos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "estos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "estoy",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estoy lista",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estoy listo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estoy seguro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estrategia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estrechos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "estrella",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estrellas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estrés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estresada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "estresado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "estresante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "estuche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estudia",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudian",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiante",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "estudiantes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "estudiar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudiaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudias",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudias en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estudiaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudié",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudies",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudio",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estudios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "estudiosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "estudiosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "estudioso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "estudiosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "estufa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estufas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "estupenda",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "estupendas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "estupendo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "estupendos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "estuve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estuvieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estuvimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estuviste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "estuvo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "etcétera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "euro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "europa",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "europea",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "europeas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "europeo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "europeos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "evidencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "evidentemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "evitar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "evolución",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ex",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "exacta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "exactamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "exactas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "examen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "excelente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "excelentes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "excepción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "excepto",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "excursión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "excursiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "excusa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "excusas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "existir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "éxito",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "éxitos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "exitosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "exitosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "exitoso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "exitosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "experiencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "experiencias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "experto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "explica",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "explicación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "explicaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "explícame",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "explícamela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "explícamelas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "explícamelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "explícamelos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "explicando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "explicar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "explicaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "explicas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "explicó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "expliqué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "explíquele",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "explíqueme",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "exploras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "exporta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "expresa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "expresión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "extensión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "exterior",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "extra",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "extraña",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "extrañamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "extranjero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "extranjeros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "extraño",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "extraordinarios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "extras",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "extrovertida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "extrovertido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "extrovertidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fábrica",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fábricas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fácil",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fáciles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fácilmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "factor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "factores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "facultad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "facultades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "falda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "faldas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "falta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "faltan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fama",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "familia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "familiar",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "familias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "famosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "famosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "famoso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fantástica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fantásticas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fantástico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fantásticos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "farmacias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fatal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "favorita",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "favoritas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "favorito",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "favoritos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fea",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "feas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "febrero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fecha",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fechas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "federación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "federal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "felices",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "felicidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "felicidades",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "felicitaciones",
        "partOfSpeech": "Interjection",
        "gender": "Feminine"
    },
    {
        "word": "felicitaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "felicitarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "felicité",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "feliz",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "felizmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "femenino",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fenómeno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fenómenos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "feo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "feos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "feria",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ferrocarril",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "festival",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "festivales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fibra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ficción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fiebre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fiebres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fiesta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fiestas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "figura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fila",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "filas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "filosofía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "filosofías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fin de semana",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "final",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "finales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "finalmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "fines de semana",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "firma",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "firmamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "firman",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "firmará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "firmarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "firmas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "firme",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "firmen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "firmo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "física",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "físicas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "flauta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "flautas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "flor",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "florero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "floreros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "flores",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "forma",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "formal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "formar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "formas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fórmula",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "formulario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fórmulas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "foro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fortuna",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "foto",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fotocopias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fotógrafa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fotógrafas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fotografía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fotografías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fotógrafo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fotógrafos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fotos",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fracaso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "frágiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "francés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "francesa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "franceses",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "francia",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "frase",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "frases",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "frecuencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "frecuentemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "fregadero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "freí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "freímos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "freír",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "freíste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "frente",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "frentes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "fresa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fresas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fresca",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "frescas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "frescos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "frieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "frió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "frío",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "frita",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "frontera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fronteras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fruta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "frutas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "frutería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fue desarrollado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fue un",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fuego",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fuegos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fuegos artificiales",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fuente",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fuentes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fuera",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "fuéramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fueran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fueras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fueron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fueron invitados",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fueron unos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fuerte",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fuertes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fuerza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fuerzas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fui",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fuimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fuiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fumaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fumar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fumé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fumó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "función",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "funcionan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "funcionar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "funciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fútbol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fútbol americano",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "futbolista",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "futbolistas",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "gabinete",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gabinetes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gafas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gafas de sol",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "galería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gana",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ganaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ganaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ganabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ganador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ganadora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ganáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ganar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ganas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ganaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ganó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "garaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gasolina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gasolinera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gasolineras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gastaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gastáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gastamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gastar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gastaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gasto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gastó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gata",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gatas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gatos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gay",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "gemela",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gemelo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "generalmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "generosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "generosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "generoso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "generosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "genial",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "gente",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gentes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "geografía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gerentes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "gesto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gigante",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gigantes",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gimnasios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giráis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "girar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "giro",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "globo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "globos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gobernador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gobierno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gobiernos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "goles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "golf",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "golfista",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "golfistas",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "golpea",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "golpear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "golpeas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gorra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gorro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gorros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gps",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "graba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grabando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grabo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gracias",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "gracias por",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "graciosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "graciosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gracioso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "graduación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "graduada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "graduado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "graduados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gráficos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gramática",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gramos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gran",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "grande",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "grandes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "grandísima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grandísimas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grandísimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grandísimos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "granjera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "granjero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "granjeros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gratis",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "grave",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "gravemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "graves",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "grifo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grifos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gripe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gris",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "grises",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "gritaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gritamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gritan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gritando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gritar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gritaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gritaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grité",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grito",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gritó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gritos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grupo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "guárdalas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "guárdalo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "guardar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardia",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "guardo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guardó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guau",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "guerra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "guerras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "guía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gusta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gustaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gustaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gustan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gustar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gustaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gustarían",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "gustaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gusto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gustó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "haber",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "había",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "había una vez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "habías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habitación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "habitaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "habitante",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "habla",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablabais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablando de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hablar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablar con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hablar de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hablar español",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hablaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablas con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hablaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hable",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hables",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habló",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hablo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habrá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habrá ido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "habrá sido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "habría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hace",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hace calor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hace frío",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hace mucho tiempo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hace un rato",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hacemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacer la cama",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "haces",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacia",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "hacía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacia atrás",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hacíais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hacienda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "haciendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "haga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hagamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hagan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hagas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hago",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hala",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "halar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hambre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hamburguesa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "harán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "harás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "haré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "haremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "haría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "haríamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "harían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "harías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hasta",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "hasta ahora",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hasta dónde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hasta luego",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hasta mañana",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hasta que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hay",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hay algún",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hay alguna",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hay que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "haya",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "haz",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hecha",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "hechos",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "helicóptero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "herido",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "heridos",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "hermana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hermanas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hermano",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hermanos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hermosísima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "hermosísimas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "hermosísimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "hermosísimos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "heroína",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "heroínas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hice",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hiciera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hicieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hicimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hiciste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hielo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hielos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hija",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hija única",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hijas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hijas únicas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hijo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hijo único",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hijos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hijos únicos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hincha",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hinchas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hip-hop",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hirviendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hispana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "hispanohablante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "hispanohablantes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "hispanos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "historia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "historia del arte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "historias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "histórica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "históricas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "histórico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "hizo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hobbies",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hobby",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hockey",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hogar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hogares",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hoja",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hojas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hola",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "hombre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hombres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hombro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hombros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "honesta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "honestas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "honesto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "hongos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "honor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "horario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "horas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "horno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hornos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "horrible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "hospital",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hospitales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hostal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hostales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hotel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hoteles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hoy",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "hoy en día",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hubiera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hubieran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hubo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hueso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "huesos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "huevo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "huevos revueltos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "humanidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "humedad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "húmedos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "humo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "humor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "huracán",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "huracanes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "iba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "iba a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ibais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "íbamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "íbamos a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "iban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "iban a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ibas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ibas a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "idea",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ideal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ideas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "identidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "identificación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "iglesia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "iglesias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "igual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "igualdad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "iguales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "igualmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ilegal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ilegales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "imagen",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "imágenes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "imaginación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "imaginan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "imaginar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "imaginaria",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "imaginarias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "imaginario",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "imaginas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "impaciente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "impacientes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "impacto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "impedir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "imperio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "imperios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "impermeable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "importa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "importamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "importan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "importancia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "importante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "importantes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "importará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "importaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "importo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "imposible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "impresionante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "impresionantes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "impresora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "impuesto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "impuestos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "incendio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "incluí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "incluye",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluyen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluyendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluyeron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluyes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluyó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incluyo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "incorrecta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "incorrectas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "independencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "independiente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "independientes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "indicaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "individual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "individuales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "individuo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "individuos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "industria",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "industrial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "industrias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "infancia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "infantil",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "inflación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "influencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "información",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "informaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "informal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "informales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "informática",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ingeniera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ingenieras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ingeniero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ingenieros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inglaterra",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "inglés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "inglesa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "inglesas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ingleses",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ingredientes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ingreso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ingresos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "iniciar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inicié",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inició",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "injusta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "injusto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "inmediata",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "inmediatamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "inmediato",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "inmigración",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "insistió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "instalación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "instalaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "instalamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "instalaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "instalaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "instaló",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "institución",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "instituciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "instituto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "instructor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "instructoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "instructores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "instrumento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "instrumentos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "intelectual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "intelectuales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "inteligencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "inteligente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "inteligentes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "intención",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "intensidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "intenta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intentaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intentábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intentaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intentabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intentando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intentar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intentaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intento",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intentó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "interés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "interesa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "interesa jugar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "interesaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "interesadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "interesados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "interesan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "interesante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "interesantes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "intereses",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "internacional",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "internacionales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "internacionalmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "internet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "introducción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "inundaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "inusual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "inventando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inventar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inventaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inventos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inversión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "inversiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "investigación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "investigaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "invierno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inviernos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "invisible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "invisibles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "invita",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "invitada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "invitados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "invitamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitarlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "invitas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inviten",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invito",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "irán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "irás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "irte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "isla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "islas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "italiana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "italianas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "italiano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "italianos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "itinerario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "izquierda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jabón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jabones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jalea",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jamás",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "jamón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jamones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "janucá",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jardín",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jardines",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jazz",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jefa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jefas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "joven",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "jóvenes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "joyas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jubilada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jubiladas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jubilado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jubilados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jueces",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "juega",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "juegan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "juegas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "juego",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "juego de mesa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "juegos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "juegos de mesa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jueves",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "juez",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jueza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jugaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jugadora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jugadoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jugadores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jugáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jugo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jugos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jugué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "juguemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "juicio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "juicios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "julio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "junio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "junta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "juntos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "justicia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "justo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "kilómetro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "kilómetros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "la",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "la amarilla",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la mano",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la más",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la mayoría de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la mitad de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la parte de abajo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la quiero",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la tuya",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la verdad",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "labio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "labios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "laboratorio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "laboratorios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ladrón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ladrones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lagos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lágrima",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lágrimas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lamento que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lámpara",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lámparas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lancé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lanzamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lanzas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lanzaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lanzó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "larga",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "largas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "largo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "largos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "las",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "las afueras",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "las afueras de la ciudad",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "las comen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "las frutas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "las necesita",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "las nuestras",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lástima",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lastimar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "latina",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "latinas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "latinos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "lavaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavadora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lavadoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lavamanos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lavamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavaplatos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lavar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavarla",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "lavarlas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "lavarlo",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "lavarlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "lavarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lavarnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lavaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lavarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lavas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lávate",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lavavajillas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lavé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lávese",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lavó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "le",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "le das",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le doy",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le encanta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le gusta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le gustan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le hago",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le interesa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lea",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "léala",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "léalo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lección",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lecciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "leche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lechuga",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lectura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lee",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "léelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "léelos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "leemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leer este",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "leerá",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "leerán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leerían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leerlo",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "lees",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "legal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "legales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "legislación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "leí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leímos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leíste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lejos",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "lengua",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lenguaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lenguajes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lenguas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lentamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "lentejas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lentes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lento",
        "partOfSpeech": "Adverb",
        "gender": "Masculine"
    },
    {
        "word": "leo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "león",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "leones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "les",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "les encanta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les gusta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les interesa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lesbiana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "lesbianas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "letra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "letras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "levanta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "levantan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "levantarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "levantarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "levantarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "levántate",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "levántese",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "levanto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ley",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "leyendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leyeron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "leyes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "leyó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "libertad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "libra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "libras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "libre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "libres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "libro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "libro de ejercicios",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "libro de texto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "libros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "libros de ejercicios",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "libros de texto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "licencia de conducir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "líder",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "líderes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "liga",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ligeras",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "límite",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "límites",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "limón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "limpia",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "limpiábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpiaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpiabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpiador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "limpiadora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "limpiadoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "limpiadores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "límpiala",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "límpialas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "límpialo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "límpialos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "limpiamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpian",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpiando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpiar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpiaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "limpiaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpié",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "limpio",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "limpios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "línea",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "líneas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "liso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "lista",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "listas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "listo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "listos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "literatura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "llama",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "llamadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "llamamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llaman",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamándola",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "llamándolo",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "llamar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llame",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llamó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llave",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "llaves",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "llega",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "llegadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "llegamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llego",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llegué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lleguemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llena",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llenamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llenas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "llene",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llené",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lleno",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lleva",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lleva puesto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "llevaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llévame",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "llevamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llevan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llevar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llevarme bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "llevarnos bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "llevarse bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "llevarte bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "llevas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llévate",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lleves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llevo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llevó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lloraba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lloraban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llorabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llorar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llover",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llovía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lloviendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llovió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llovizna",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lloviznar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "llueve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lluvia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lluvias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lo",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "lo compro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo contrario",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo curioso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo es",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo está",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo interesante",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo más divertido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo más importante",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo mismo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo quiero",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo raro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lo siento",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "loca",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "local",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "locales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "loco",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "locos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lógica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "lógico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "lograr",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "logró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "los",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "los años",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los comen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los deportes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los gatos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los lunes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los maestros",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los médicos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los necesita",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los padres",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "los perros",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lotería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "luces",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "luego",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lugar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lugares",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lujo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "luminosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "luminoso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "luna",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "luna de miel",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lunas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lunes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "luz",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "madera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "madrastra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "madrastras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "madre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "madrugada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maestra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maestras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maestría",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maestro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maestros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "magia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mágicas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mágicos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mago",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maíz",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mal",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mal tiempo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mala",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "maleta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "maletas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "malísima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "malísimas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "malísimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "malísimos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "malo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "malos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mamá",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mamás",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mañana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mancha",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "manchas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mandamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mandan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mandar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mandas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mandaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mandé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mandó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "maneja",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manejaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manejaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manejamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manejan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manejar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manejas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manejo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maneras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mangos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "manifestación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "manifestaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mano",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mano de obra",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "manos",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mantenemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mantener",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mantenerme informado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mantenerse informados",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mantengo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mantequilla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mantequilla de maní",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mantiene",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mantienen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mantienes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manzana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "manzanas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mapa",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mapas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maquillaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "máquina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "máquinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mar",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "maravillosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "maravillosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "maravilloso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "marcaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "marcaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "marcó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mareada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mareado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mareados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mares",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "margen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marido",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mariscos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marrón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marrones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "martes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marzo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "más",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "más o menos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "más que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "masa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mascota",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mascotas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "matar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "matarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mataré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "matemáticas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "materia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "material",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "materias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mató",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "matrimonio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "máxima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "máximo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mayo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mayonesa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mayor",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mayores",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mayoría",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "me",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "me acordé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me acostaba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me acostaré",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me acosté",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me acuerdo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me acuesto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me ama",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me apuro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me ayudas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me bajo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me cae bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me cae mal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me caí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me caigo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me caso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me cepillé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me cepillo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me compra",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me conecté",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me conoce",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me dedico",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me desperté",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me despierto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me dice",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me divierto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me duché",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me ducho",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me encanta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me encuentro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me enfermo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me enseña",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me equivoco",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me escondí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me escucha",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me fracturé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me fui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me gusta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me iba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me interesa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me iré",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me la llevo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me las",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me las llevo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me lastimé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me lavé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me lavo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me levantaba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me levanté",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me levanto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me llaman",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me llamo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me llevaba bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me llevo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me llevo bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me lo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me lo llevo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me lo quedo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me los",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me los llevo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me maquillaba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me maquillo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me necesita",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me olvido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me perdí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me pierdo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me pongo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me preocupaba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me preocupé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me preocupo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me probé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me puede cambiar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me puede decir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me puede traer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me puedes decir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me puedo probar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me puse",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me quedaré con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me quedé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me quedo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me quedo con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me quemé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me quemo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me quito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me registro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me reí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me reuní",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me reunía",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me río",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me sentí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me sentía",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me separé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me siento",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me subo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me tumbo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me veo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me veré",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me vestí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me voy",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mecánica",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mecánicas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mecánico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mecánicos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "media",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mediana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "medianas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "medianoche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "medianos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "medias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "médica",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "médicas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "medicina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "medicinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "médico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "médicos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "medida",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "medidas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "medio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "medio ambiente",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mediodía",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mejor",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mejora",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mejorar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mejorará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mejorarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mejoraremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mejores",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "melón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "melones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "memoria",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "memorias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "memorizan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "memorizarla",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "menciona",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mencionó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "menor",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "menores",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "menos",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "menos mal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "menos que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mensaje",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "mensaje de voz",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mensajes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "mente",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mentes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "menú",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mercado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mercados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meriendas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mesa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mesa de noche",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mesas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mesas de noche",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mesera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mesero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meses",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mesita",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "metal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "método",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "métodos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "metros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mexicana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mexicanas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mexicano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mexicanos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "méxico",
        "partOfSpeech": "Proper noun",
        "gender": "Masculine"
    },
    {
        "word": "mezcla",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mezclan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mezclar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mezclo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mi",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "mí",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "mi nuevo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mía",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "microondas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "miedo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "miente",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mientras",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "mientras tanto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "miércoles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mil gracias",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mil millones",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "miles",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "millas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "millón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "millones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mínima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mínimo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ministerio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ministra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ministro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mintieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "minuto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "minutos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mío",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "miraba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mirabais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "miraban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mirada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "miramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mirando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mirar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "miraron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "miraste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "miremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "miren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "miro",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "miró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mis",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "misión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "misma",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mismas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mismo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mismos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mitad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mochila",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mochilas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "moda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "modas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "modelo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "modelos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moderna",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "modernas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "moderno",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "modernos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mojada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mojadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mojado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mojados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "molesta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "molestas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "molesto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "molestos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "momento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "momentos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "moneda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "monedas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mono",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monstruo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monstruos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "montaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montaña",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "montañas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "montar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "monte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "monumento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monumentos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "morada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "morenas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "moreno",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "morenos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "morir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mostaza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mostrar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mostró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "motivo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "motivos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moto",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "motocicletas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "motor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "motores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "movamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mover",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "moverme",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "móvil",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "movimiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "movimientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mucha",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "muchacha",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "muchachas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "muchacho",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muchachos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muchas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "muchas gracias",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "muchísimas gracias",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mucho",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "mucho gusto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "muchos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "muebles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muerta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "muertas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "muerto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "muertos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "muestra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "muestran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "muestras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mujer",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mujeres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "múltiples",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "multiplicando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "multiplicar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "multiplicarlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "multipliquemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mundial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mundiales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mundo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mundos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muñeca",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "muñecas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "muriendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "murió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "museo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "museos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "música",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "musical",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "musicales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "músicas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "músicos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muy",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "muy amable",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nacer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nacional",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "nacionales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "nacionalidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nada",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "nada es",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nada más",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nadaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadie",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "nadie más",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nado",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nadó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "naranja",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "naranjas",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "narices",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nariz",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "natación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "natural",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "naturales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "naturaleza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "naturalmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "navegaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "navegador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "navegamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "navegará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "navegarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "navegaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "navegaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "navegué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "navidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "necesaria",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "necesariamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "necesarias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "necesario",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "necesarios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "necesidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "necesidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "necesita",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesita escribir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "necesitaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesitabais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesitaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesitáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesitamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesitamos abrir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "necesitan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesitar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesitaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesitarla",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "necesitarlas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "necesitarlo",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "necesitarlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "necesitas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesito",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "necesito comprar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "negativa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "negativo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "negó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "negociación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "negociaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "negocio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "negocios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "negra",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "negras",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "negro",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "negros",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "negué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nerviosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nerviosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nervioso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nerviosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nevaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nevada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nevando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nevar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nevará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nevera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "neveras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nevó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ni",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "ni idea",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ni siquiera",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "niebla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nietas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nieva",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "niña",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "niñas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ningún",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "ninguna",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "ningunas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "ninguno",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "ninguno de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ningunos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "niño",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "niños",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nivel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "niveles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "no",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "no busques",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no crees",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no creo que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no es cierto que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no es seguro que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no hay de qué",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no hay problema",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no lo sé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no me digas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no me parece que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no puede ser",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no puede ser que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no te creo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "no vayamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "noche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nochebuena",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "noches",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nochevieja",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nombre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nombre de usuario",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nombres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "normal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "normalmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "noroeste",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "norte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "norteamericana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "norteamericano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "nos abrazamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos acordamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos acostamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos acostaremos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos amamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos apuramos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos ayudamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos ayudan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos ayudas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos bajamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos besamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos besaremos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos caemos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos caímos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos casamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos cepillábamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos cepillamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos compra",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos comunicamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos conocemos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos conocen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos dedicamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos despedimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos despertamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos divertimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos duchamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos encanta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos encontramos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos escribimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos escuchan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos gusta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos íbamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos inscribamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos interesa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos iremos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos juntamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos las",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos lavamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos levantábamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos levantamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos llamamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos llamas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos llevamos bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos lo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos mantenemos informados",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos odiamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos olvidamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos perdimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos ponemos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos preocupamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos probamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos pusimos de acuerdo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos quedamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos quedamos con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos quedaremos con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos quitamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos reímos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos relajamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos reuníamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos reunimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos sentamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos sentíamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos sentimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos subimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos tumbamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos vamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos vemos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos veremos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos visita",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nos volveremos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nosotras",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "nosotras vivimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nosotros",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "nosotros comemos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nosotros fuimos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nosotros leemos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nota",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "notas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "noticia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "noticias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "novecientos",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "novela",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "novelas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "noventa",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "novia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "novias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "noviembre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "novio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "novios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nubla",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nublado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nubló",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "núcleo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nuestra",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "nuestras",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "nuestro",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "nuestros",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "nueva",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nuevamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nuevas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nueve",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "nuevo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nuevos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "número",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "números",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "numerosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nunca",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nunca más",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "o",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "objetivo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "objetivos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "objeto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "objetos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "obligación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "obligatoria",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "obligatorio",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "obligatorios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "obra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "obra de teatro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "obras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "obras de arte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "obrera",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "obrero",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "observa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "observación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "observaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "observar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "observo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "obtener",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "obtuvo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "obvia",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "obviamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "obvio",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ocasión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ocasiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ochenta",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "ocho",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "ochocientos",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "octubre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ocupa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ocupación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ocupada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ocupadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ocupado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ocupados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ocurrieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ocurrió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ocurrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odia",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odiaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odiaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odiáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odiar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odiaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odiaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "odio",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oeste",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oestes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oferta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ofertas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oficial",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "oficiales",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "oficina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oficina de correos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "oficinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ofrece",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ofrecemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ofrecen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ofrecer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ofreces",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ofrecieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ofreció",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ofrezco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oído",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oídos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oigan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oigo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oímos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oír",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oíste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oíste que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ojalá",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "ojo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ojos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "olor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "olores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "olvidamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "olvidar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "olvidarnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "olvidaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "olvidarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "olvidaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "olvido",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "once",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "opción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "opciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ópera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "operación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "operaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "operaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "operó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "opinión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "opiniones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oportunidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oportunidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oposición",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "optimista",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "optimistas",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "opuestas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "opuesto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "oraciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "orden",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ordenaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ordenabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ordenar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "órdenes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "organicé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "organismo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "organismos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "organiza",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "organización",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "organizaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "organizar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "organizas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "organizaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "órgano",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "órganos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "orgullosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "orgullosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "orgulloso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "orientación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "origen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "original",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "originales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "oro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "orquesta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "orquestas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ortografía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oscuridad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oscuro",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "oscuros",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "oso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "otoños",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "otra",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "otra vez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "otras",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "otro",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "otros",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "ovejas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oye",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oyen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oyendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oyeron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oyes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oyó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "paciencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pacienta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pacientas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "paciente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "pacientes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "padrastro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "padre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "padres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagada",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "pagado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "págala",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pagamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "página",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "página web",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "páginas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "páginas web",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pago",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pagó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pago electrónico",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pagos electrónicos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pagué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "páguelas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pagues",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "país",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paisaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paisajes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "países",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pájaro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pájaros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "palabra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "palabras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "palacio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pálida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pálido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "palomitas de maíz",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pan",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "panadera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "panadero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "panes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "panorama",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pantalla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pantallas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pantalón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pantalones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pantalones cortos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "papá",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "papa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "papel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "papelería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "papeles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paquete",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paquetes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "par",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "para",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "para él",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "para ella",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "para nada",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "para nosotros",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "para que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "para siempre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parabrisas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paraguas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pararme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "pararnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "pararse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "pararte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "paras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parece",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parece que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parecen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pareces",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parecía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parecía que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parecían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pared",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "paredes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pareja",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "parejas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pares",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parís",
        "partOfSpeech": "Proper noun",
        "gender": "Masculine"
    },
    {
        "word": "parlantes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paro",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parque",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parque de atracciones",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parques",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "párrafos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parrilla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "parte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "parte de atrás",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "partes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "participar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "participaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "participaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "particular",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "particulares",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "partido",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "partidos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "partir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pasado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pasado mañana",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pasados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pásamela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pásamelas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pásamelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pásamelos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pasamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasaporte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pasaportes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pasar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasaré por",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pasaremos por",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pasaríamos por",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pasarla bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pasas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pase",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "paseaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "paseábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pases",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pasiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "paso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pasó por",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pasos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pasta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pastilla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pastillas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pasto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "patadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "patinaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "patinábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "patinaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "patinabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "patinar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "patines",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patio de juego",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "patios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patios de juego",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patria",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pausa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pavo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "payaso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "payasos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paz",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pc",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pecho",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pechos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pedí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pedido",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pedimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pedir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pedir prestado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pediré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pedirla",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "pedirlas",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "pedirlo",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "pedís",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pediste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peine",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "peleamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pelean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peleando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "película",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "películas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "peligro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "peligros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pelo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pena",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pendiente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "pensaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensamiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pensamientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pensamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peor",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "peores",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "pepino",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pepinos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pequeña",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pequeñas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pequeñita",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pequeñito",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pequeñitos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pequeño",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pequeños",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "peras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "perdemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perder",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "perderemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perderme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "perdernos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "perderse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "perderte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "perdí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pérdida",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "perdida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "perdidas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "perdido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "perdidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "perdimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "perdona",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdone",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perezosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "perezosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "perezoso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "perezosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "perfecta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "perfectamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "perfecto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "perfectos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "perfil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "perfumes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "periódico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "periódicos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "periodismo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "periodista",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "período",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "permanente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "permiso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "permite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permiten",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permitieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permitió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permitir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permito",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pero",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "perrito",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "perro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "perros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "persona",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "personaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "personajes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "personal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "personales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "personalidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "personalidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "personalmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "personas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "perspectiva",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pertenece",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pertenecer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perteneces",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pertenezco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pesa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pesadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pesadilla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pesadillas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pesados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pesar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pesca",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pescaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pescaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pescado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pescados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pescamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pescar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pescaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pescó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pesos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pesqué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "petición",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "picando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "picante",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "picantes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "picar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pídasela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pídaselos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pídele",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "piden",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pídeselo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pídeselos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pidiendo",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "pidieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pidió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pie",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piedra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piedras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piel",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piensa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piensan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "piensas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pienso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pienso que deberías",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pierde",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pierden",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pierdes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pierna",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piernas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pies",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pieza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piezas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pijama",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pijamas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pimienta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piña",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pingüino",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pingüinos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pintada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pintadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pintado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pintados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pintamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pintan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pintar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pintaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pintas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pintaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pinté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pinten",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pinto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pintó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pintor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pintora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pintoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pintores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pintura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pinturas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piscina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piscinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pisos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pista",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pistas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pizarra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pizarras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pizza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pizzas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "placer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plan",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "planchaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "planchar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plancharía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "planea",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "planeamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "planean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "planeas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "planeo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "planes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "planeta",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "planta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plantas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plástico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plata",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plátanos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plato principal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "platos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "playa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "playas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plaza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plazas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pluma",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plumas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "población",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pobre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "pobres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "pobreza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poca",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "pocas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "poco",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "poco a poco",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "poco después",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pocos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "podemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poder",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poderes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "podía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podrá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podrán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podrás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podría ser",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "podríamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "podrías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poema",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poesía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poeta",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "poetas",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "policía",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "policías",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "política",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "políticas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "político",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "políticos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pollo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pollos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "polvo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pon",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pondrás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pondré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pondrían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pone",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ponéis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ponemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ponen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ponerme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "ponernos de acuerdo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ponerse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "ponerte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "pones",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ponga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pongamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pongan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pongas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pongo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poniendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poniéndola",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "pop",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "popular",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "populares",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "poquito",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "por",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "por ahora",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por amor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por anticipado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por ciento",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por cierto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por dónde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por el momento",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por eso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por favor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por fin",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por lo general",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por lo menos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por lo que veo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por lo visto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por mi culpa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por nada",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por qué",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por si acaso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por suerte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por supuesto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por todas partes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "por último",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "porcentaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "porcentajes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "porque",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "portátil",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "portátiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "portugués",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "portuguesa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "portuguesas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "portugueses",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "posee",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "posibilidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "posibilidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "posible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "posiblemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "posición",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "posiciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "positiva",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "positivo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "postal",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "postales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "póster",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pósteres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "postre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "postres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "práctica",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prácticamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "practicamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "practicar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "practicaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "practicaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "practicó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "practiqué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "precio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "precios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prefería",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferida",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "preferido",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "preferimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferiría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preferiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prefieren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prefirieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prefirió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pregunta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "preguntáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pregúntale",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "preguntamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preguntan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preguntar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preguntaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preguntas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "preguntaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pregunté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pregúntele",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "pregúnteles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pregúntesela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pregúnteselas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pregúnteselo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "preguntó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pregunto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "premio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "premios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prensa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "preocupación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "preocupaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "preocupadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "preocupados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "preocuparme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "preocuparnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "preocuparse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "preocuparte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "prepara",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preparamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preparar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prepararla",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "prepararlo",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "prepararlos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "prepararon",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preparaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prepare",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preparé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "preparó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presenta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presentación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presentan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presentar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presente",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "presentes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "presento",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presentó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presidencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presidencial",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "presidenta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presidentas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presidente",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "presidentes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "presión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "préstame",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "préstamela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "préstamelas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "préstamelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "préstamelos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "prestar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prestas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prestaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presté",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "presupuesto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "previa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "prima",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "primas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "primavera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "primaveras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "primer",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "primer piso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "primera",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "primeras",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "primero",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "primeros",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "primeros auxilios",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "primo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "primos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "principal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "principales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "principalmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "príncipe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "principiantes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "principio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "principios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prioridad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prioridades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "privada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "privadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "privado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "probable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "probablemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "probador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "probadores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "probáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "probamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "probar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "probaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "probarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "probarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "probaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "probé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "problema",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "problemas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "procedimiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "procedimientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "proceso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "procesos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "producción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "producir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "producto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "productos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "profesión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "profesional",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "profesionales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "profesor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "profesora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "profesoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "profesores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "profunda",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "profundidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "profundidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "profundo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "programa",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "programas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "progreso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "progresos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prohibido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "prohibidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "promedio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prometerías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prometieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prometimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "promoción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "promociones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pronóstico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pronto",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "pronunciación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propia",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "propias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "propiedad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propiedades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propio",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "propios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "proporción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propósito",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "propósitos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "propuesta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propuestas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "protagonistas",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "protección",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "proteína",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "proteínas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "próxima",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "próximas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "próximo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "proyecto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prueba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prueban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pruebas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pruebe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pruebo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pública",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "publicación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "publicaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "publicado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "publicar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "publicaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "publicidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "publicidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "público",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "publicó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "públicos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "publiquemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pude",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pudiera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pudiéramos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pudieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pudimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pudiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pudo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pueblo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pueblos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "puede",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "puede que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "puede ser",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "puede traer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pueden",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "puedes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "puedo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "puente",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "puentes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "puerta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "puertas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "puerto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "puesta",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "puestas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "puesto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "puestos",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "pulsera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "punto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "punto de vista",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "puntos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "puntual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "pura",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "puro",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "puse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pusieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pusimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pusiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "puso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "qué",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué alivio",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué divertido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué elegante",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "que escribir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "que estudiar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué hacemos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "que hacer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué horror",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué interesante",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué interesante que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué le gustaría para empezar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué le parece",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué le pasa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué le traigo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "que leer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué les pasa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué mala suerte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué raro",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué raro que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué tal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué tal si",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué tan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué te parece",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué te pasa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué te traigo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qué tiempo hace",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "que viene",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "queda",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quedarme con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quedarnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quedaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quedarse con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quedarte con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quedas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quedó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "queremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "queremos abrir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "querer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querer decir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quería",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quería decir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "queríais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "queridos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "querrá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querrán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querrás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "querríamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "querrían",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "queso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quesos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "quién",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quiénes",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quiera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quieras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quiere",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quiere aprender",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quiere decir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quiere dibujar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quieren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quieren beber",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quieren decir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quieres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quieres decir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quiero",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quiero aprender",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quiero beber",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "química",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "quince",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quinientos",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quiosco",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "quise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quisiera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quisieran",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quisieras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quisieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quisimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quisiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quiso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quitar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quitaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quitarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quitarnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quitaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quitarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quitarte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "quizá",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "quizás",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "radiante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "radio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "radios",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "raíces",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "raíz",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ramadán",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rap",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rápida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rápidamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "rápidas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rápido",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "rápidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rasuradora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rasuradoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rata",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ratas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ratón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ratones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "razón",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "razonable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "razonables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "razones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reacción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reacciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "real",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "reales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "realidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "realidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "realizar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "realmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "rebanada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rebanadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "recepcionista",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "receta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rechazo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibido",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "recibiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibirán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibirás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibiremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibís",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recibo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "recibos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reciclaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reciclaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reciclar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recién",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "reciente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "recientemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "recientes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "recital",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "recomendable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "recomendación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "recomendaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "recomendada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "recomendado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "recomendados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "reconoce",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reconocer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reconocida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "reconocido",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "recordamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recordar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recordemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recordó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rectas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "recto",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "recuerda",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recuerdo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recuperar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recurso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "recursos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "recursos humanos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "red",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "redes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "redes sociales",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "redonda",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "redondas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "redondo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "redondos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "reducir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reencarnación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reenviaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reenvías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reflexión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reflexiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "refresco",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "refrescos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "refrigerador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "refrigeradora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "refrigeradores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "regalaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regaló",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regalo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "región",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "regiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "registrarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "regístrese",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "regla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reglas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "regresáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regresamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regresan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regresar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regresaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regresaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regrese",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regreso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regular",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "reina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reino unido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "reírme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "reírnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "reírse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "reírte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "relación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "relajación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "relajado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "relajados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "relajarme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "relajarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "relativamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "religión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "religiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "religiosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "religioso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "religiosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "reloj",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "relojes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "remedio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "remedios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "renovaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reparaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reparaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reparar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repare",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reparó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repasan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repasaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repasó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repaso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "repetí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repetimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repetir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repetiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repiten",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repitiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repitieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repitió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repito",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reportajes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reportera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reportero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reporteros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reprobamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reprobaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reprobé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reprobó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reprueban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repruebas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repruebo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "república",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "republicano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "republicanos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "requiere",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "requieren",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "requisitos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reserva",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "reservada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "reservadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "reservado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "reservados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "reserváis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservará",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservaremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reserve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reservo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "resfriadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "resfriado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "resfriados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "resolver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respeta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respetan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respeto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "responde",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "responden",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "responder",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respondo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "responsabilidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "responsable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "responsables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "respuesta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "respuestas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "restaurante",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "restaurantes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "resto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "resultado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "resultados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "resumen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "retirar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retiraste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retiró",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retrasada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "retrasado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "retrasados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "retrasamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retrasaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retrasó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reunión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reuniones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "revisión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "revista",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "revistas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "revolución",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "revolucionarias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "revolucionario",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rey",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reyes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rezaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rezábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rezaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rezabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rezar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ricas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ricos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "riesgo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "riesgos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "río",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ríos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "riqueza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "riquezas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ritmo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rival",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "rivales",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "rock",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rodilla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rodillas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "roja",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rojas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rojo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rojos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "roles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "roma",
        "partOfSpeech": "Proper noun",
        "gender": "Feminine"
    },
    {
        "word": "romance",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "romántica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "romántico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "románticos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rompecabezas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "romper",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rompí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rompieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rompiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ropa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ropa interior",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rosa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rosadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rosas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rota",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "rotas",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "roto",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "rubias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rubio",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rubios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rueda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ruedas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rugby",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ruido",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ruidosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ruidoso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ruinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rumbo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rumbos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ruta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rutas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rutina",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sábado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sábados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sábana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sábanas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sabe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabe usted",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sabemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabemos dónde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "saben",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saber",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabes cuándo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sabes nadar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sabes quién",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sabía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sabores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sabrá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabrán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabrás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabrías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sabrosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sabroso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sabrosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sacan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sacarla",
        "partOfSpeech": "",
        "gender": "Feminine"
    },
    {
        "word": "sacas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sacerdote",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sacerdotes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sacerdotisa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sacó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sal",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sala",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sala de reunión",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sala de reuniones",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "salas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "salas de reuniones",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "salchicha",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "salchichas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "saldo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "saldos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "saldrá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saldremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saldría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saldríamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saldrían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saldrías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sale",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sales",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salgamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salgan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salgas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salgo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salgo con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "salí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salida",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "salida de emergencia",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "salidas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "saliendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salir a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "saliste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salmón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "salón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "salsa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "salta",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saltan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salto",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salud",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "saludable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "saludables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "saludamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saludan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saludaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saludes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "saludo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salvajes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "salvamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salvo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sándwich",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sándwiches",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sangre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "santa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "santo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sartén",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "sartenes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "satisfacción",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "satisfecha",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "satisfechas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "satisfecho",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "satisfechos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "se",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "se abrazan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se abrazaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se aburren",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acordaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acordó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acostaban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acostaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acostó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acuerda",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acuerdan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acuesta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se acuestan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se almuerza",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se amaban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se aman",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se amaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se apura",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se apuran",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ayudan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ayudaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se baila",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se bailan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se bajan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se besan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se besaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cae",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se caen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se casan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se casó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cayeron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cayó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se celebra",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se celebran",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cepilla",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cepillaba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cepillaban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cepillan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cepillaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cepilló",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se come",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se comunicaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se comunicó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se conectaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se conocen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se cree",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sé cuándo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se dedica",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se dedican",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se desayuna",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se despertaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se despertó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se despidieron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se despierta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se despiertan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se dice",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se divierte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se divorciará",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se divorció",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ducha",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se duchan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ducharon",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se duchó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se encuentra",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se encuentran",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se enferma",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se enferman",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se escondía",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se escondían",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se escondieron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se escondió",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se escriben",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se está realizando",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se fracturó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se fue",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se habla",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se hacen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se han vendido",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se iba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se iban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se inscriban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se las",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se lastimó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se lava",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se lavan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se lavaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se lavó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se levanta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se levantaban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se levantan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se levantaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se levantó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llama",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llamaba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llamaban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llaman",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llamará",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llamarán",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llevaban bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llevan bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se llevó bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se lo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se los",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se maquilla",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se movía",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se movían",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se necesitan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se nota",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se odian",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se odiaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ofrece",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ofrecen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se olvida",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se olvidaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se paga",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se pondrían",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se pone",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ponen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se preocupaba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se preocupaban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se preocupan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se preocuparon",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se preocupe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se preocupó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se probaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se probó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se puede",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se puede cenar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se puede ir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se puso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se queda",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se queda con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quedan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quedaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quedaron con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quedó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quedó con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quemaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quemó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quita",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se quitan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se regala",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se regalan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se reunían",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se reunieron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se reunió",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ríe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ríen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se rió",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se sentía",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se sentían",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se sientan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se siente",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se sienten",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se sintieron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se supone que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se trabaja",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se tumba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se tumban",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se usa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se usan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se van",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ve",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ven",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se vende",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se venden",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se verá",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se vistieron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se vistió",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se vive",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se volverá",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se volverán",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sea",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seca",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "secadora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "secadoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "secan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "secar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "secas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "secaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sección",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "secciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "seco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "secó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "secos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "secreta",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "secretaria",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "secretarias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "secretario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "secretas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "secreto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "secretos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sector",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sed",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "seguí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguida",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "seguido",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "seguimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seguiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "según",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "segunda",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "segundo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "segundos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "segura",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "seguramente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "seguras",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "seguridad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "seguro",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "seguro que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "seguro social",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "seguros",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "seis",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "seiscientos",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "selección",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "selecciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "semáforos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "semana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "semana santa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "semanas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "senador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "senadores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "señal",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "señala",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "señalar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "señales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "señales de tránsito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sencillo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "senderismo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "señor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "señora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "señoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "señores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "señorita",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "señoritas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sentada",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "sentado",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "sentarnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "sentarse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "sentía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentido común",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sentido del humor",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sentimiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sentimientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sentimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentirnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "sentirte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "sentiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "septiembre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sequé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "será",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sería",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sería posible",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sería una buena idea",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "serían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "serie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "serpientes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "serví",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "servicio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "servicios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "servimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "servir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "servís",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serviste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sesenta",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "sesión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sesiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "setecientos",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "setenta",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "sí",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "si",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "si mal no recuerdo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "si no",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sí o sí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "siempre",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "siempre que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "siéntate",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "siente",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siéntese",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "siento",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siento mucho que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "siete",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "siga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "significado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "signo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sigo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sigue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siguen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sigues",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siguiendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siguiente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "siguientes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "siguieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "siguió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "silencio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "silenciosa",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "silenciosamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "silenciosas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "silencioso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "silenciosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "silla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sillas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sillón",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sillones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "silvestres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "símbolo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "símbolos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "similar",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "similares",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "simpática",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "simpáticas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "simpático",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "simpáticos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "simple",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "simplemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "sin",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sin duda",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sin embargo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sin parar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sin querer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sin receta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sincera",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sinceramente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "sinceras",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sinceridad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sino",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "sintieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sintió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "síntoma",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "síntomas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "siquiera",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "sirve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sirves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sirvieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sirvo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sitio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sitios",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "situación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "situaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "snowboard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sobre",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "sobre todo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sobrinas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sociales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "socialista",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "socias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sociedad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sociedades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sociología",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sofá",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "software",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sola",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "solamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "solas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "soldado",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "soldados",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "soleada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "soleadas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "soleados",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "soledad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "soles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "solicitudes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "solo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sólo",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "solos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "soltera",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "solteros",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "solución",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "soluciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sombra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sombras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sombrero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sombreros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "somos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "son",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "son seguros",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "son verdes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "soñáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soñamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sonar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soñar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soñaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soñaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soñé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soñemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sonido",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soñó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sonreía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sonreír",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sonríes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sonrisa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sopa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sopas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sorprender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sorprendidas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sorprendieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sorprendimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sorprendiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sorpresa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sortija",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sortijas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sótano",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sótanos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soul",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soy",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "su",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "suavemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "suaves",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "subas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sube",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "subes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "subí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "subieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "subimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "subió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "subir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "subirme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "subirnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "subirse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "subirte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "subiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sucederá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sucederán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sucia",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sucias",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sucio",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sucios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sucursales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sudadera",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sudaderas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sudamérica",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "sudeste",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "suegros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sueldo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sueldos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sueña",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suena",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sueño",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suerte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "suertes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "suéter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "suéters",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "suficiente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "suficientes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sufrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sugerí",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sugirió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suma",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "superficie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "superficies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "superior",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "superiores",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "supermercado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "supermercados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "supieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suponemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supongo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "surfea",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "surfean",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "surfear",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "surfeo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sus",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "suspenso",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sustancia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sustancias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "suyas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "suyo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "tableta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tal como",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tal vez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "talento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "talentoso",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "talentosos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "tales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tallas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tamaño",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tamaños",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "también",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tambores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tampoco",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tan",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tan bonita",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tan bonito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tango",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tanta",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "tanto",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "tanto como",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tantos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "tarde",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tardes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tarea",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tareas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tareas domésticas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tarjeta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tarjeta de crédito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tarjeta de débito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tarjetas de crédito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tarjetas de débito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tasa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tasas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "taxi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "taxis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "taxista",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "taxistas",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "taza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tazas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "te",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "té",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "te acordaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te acostabas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te acostarás",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te acostaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te acuerdas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te acuestas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te amo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te apuras",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te ayudo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te caes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te caíste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te casas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te cepillas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te cepillaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te compra",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te comunicas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te conozco",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te despertaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te despiertas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te dicen",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te diviertes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te duchas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te duchaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te encanta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te encuentras",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te equivocas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te escondías",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te fuiste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te gusta",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te gustan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te ibas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te inscribas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te interesa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te irás",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te las",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te lastimarás",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te lavaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te levantabas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te levantas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te levantaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te llamamos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te llamas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te llevabas bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te llevas bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te lo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te maquillas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te necesito",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te olvidas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te perdiste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te pones",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te portarás",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te preocupabas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te preocupas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te probaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te pusiste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te quedarás con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te quedas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te quedas con",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te quedaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te quemaste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te registraste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te reíste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te reuniste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te ríes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te sentías",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te sentiste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te sientas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te sientes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te subes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te vas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te verás",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te ves",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te vestiste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te visitan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "te volverás",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "teatro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "teatros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "techo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "techos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "técnica",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "técnicas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "técnico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "técnicos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tecnología",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tecnologías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tele",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "teléfono",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "teléfonos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "telenovela",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "telenovelas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "televisión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "televisiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "televisores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tema",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "temas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "temor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "temperatura",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "templada",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "templado",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "temporada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "temporadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "temprano",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ten",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ten cuidado",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tendencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tendrá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tendrán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tendrás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tendré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tendremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tendría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tendrían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tendrías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenemos prisa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tenemos que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tener",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tener razón",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tenga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tengamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tengan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tengas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tengo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tengo prisa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tengo que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tengo razón",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tenía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenía prisa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tenía razón",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "teníais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "teníamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenías prisa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tenido",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "teniendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tenis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tenista",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "tenistas",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "tensión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "teoría",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "teorías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tercer",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "tercera",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "tercero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tercios",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "termina",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "termináis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "término",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "termino",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "términos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "terraza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "terremotos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "terreno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "terrible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "territorio",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "terrorismo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tesis",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "testigo",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "testigos",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "textos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ti",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "tía",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tiburones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tiempo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tiempo completo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tiempo libre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tiempos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tienda",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tiendas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tiene",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tiene prisa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tiene que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tiene razón",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tienen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tienen prisa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tienen que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tienes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tienes prisa",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tienes que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tienes razón",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tierra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tijeras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tímida",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "tímidas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "tío",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tíos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "típica",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "típico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "típicos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "tipo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tírasela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tires",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tiro",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "título",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "títulos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "toallas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "toca",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tocó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "toco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "toda",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "todas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "todavía",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "todo",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "todo el mundo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "todo el tiempo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "todo recto",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "todos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "tolerante",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tolerantes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "toma",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomábamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "toman",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomar",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tomar el",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tomaron",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tomaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomate",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tomé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tome",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomó",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tomo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "toneladas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tontería",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tonterías",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "toqué",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tormenta eléctrica",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tornado",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tornados",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "torneo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "torre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "torres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tortillas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tortuga",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tortugas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tos",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tostada",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tostadas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "total",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "totalmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tour",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trabaja",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajabas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajador",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trabajadora",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "trabajadoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "trabajadores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trabajamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "trabajando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajar en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "trabajar mucho",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "trabajarías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabaje",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trabajos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tradición",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tradicional",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tradicionales",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tradiciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "traduce",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traducen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traducirán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traducirás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traduciré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traducirla",
        "partOfSpeech": "",
        "gender": "Feminine"
    },
    {
        "word": "traductor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "traductora",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "traductores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "traduzco",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tráemela",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tráemelas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tráemelo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tráemelos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "traemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tráfico",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "traigan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traigo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "traje de baño",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "trajeron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trajes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trajes de baño",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "trajimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trajiste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trajo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tranquila",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "tranquilas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "tranquilo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "tranquilos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "transferencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "transformación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "transformaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tránsito",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "transporte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tranvía",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trasera",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "trasero",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "trata",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trataba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tratabais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tratáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tratamiento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tratamientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tratando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tratar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tratarán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tratarás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trataremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trato",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trayendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trece",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "treinta",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "tren",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "trenes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tres",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "trescientos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "triste",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tristes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "trompeta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tronando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tronar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trozo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trozos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "truena",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trueno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "truenos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "tú bebes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú escribes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú fuiste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú hablas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú haces",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú lees",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú pones",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú se",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú vas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tú vuelves",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "turismo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "turismos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "turístico",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "tus",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "tuve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tuviera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tuvieras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tuvieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tuvimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tuviste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tuvo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "u",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "ubicación",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "última",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "últimamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "últimas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "último",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "últimos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "un",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "un grupo de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "un plato",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "un poco",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "una",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "una vez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "una vez que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "unas",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "única",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "únicamente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "único",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "unidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "unidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "uniforme",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uniformes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "unión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "universal",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "universidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "universidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "universitaria",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "universitarios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "universo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "universos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uno",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "unos",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "urgencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "urgente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "urgentemente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "usa",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usáis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "use",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "uso",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "usted",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "usted bebe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted escribe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted habla",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted hace",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted pone",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted prueba",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted trabaja",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted vive",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "usted vuelve",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ustedes",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "usual",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "usualmente",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "útil",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "útiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "utiliza",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utilizan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utilizando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utilizar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "uvas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "uy",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "va",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "va a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vacaciones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vacas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vacíos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vainilla",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vale",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "valen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "valiente",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "valientes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "valor",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "valores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vámonos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "vamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vamos a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "van",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "van a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vaqueros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "varias",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "variedad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "variedades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "varios",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "vas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vas a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vaso",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vasos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vaya",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vayan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vayas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veces",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vecina",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vecinas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vecino",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vecinos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vegetales",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vegetariana",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vegetariano",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vegetarianos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vehículo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vehículos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "veía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veíais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veinte",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "veinticinco",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "veinticuatro",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "veintidós",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "veintinueve",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "veintiocho",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "veintiséis",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "veintisiete",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "veintitrés",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "veintiún",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "veintiuno",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "velero",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "velocidad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "velocidades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ven",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ven acá",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vendedoras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vendedores",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vender",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendidas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vendidos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vendieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendrá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendrían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venga",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vengan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vengas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vengo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veníamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venís",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venta",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ventaja",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ventajas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ventana",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ventanas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ventas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "veo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verá",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verán",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verano",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "veranos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "verás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verdad",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "verdadera",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "verdaderas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "verdadero",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "verdaderos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "verdades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "verde",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "verdes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "veré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veremos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vernos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "verse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "versión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "versiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vestida",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "vestidas",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "vestido",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vestidos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vestir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vestirme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "vestirnos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "vestirse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "vestirte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "vete",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "vez",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vi",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajaríamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajarías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viaje",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "viajé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viajó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "víctima",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "víctimas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vida",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vida nocturna",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vidas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "video",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "videojuego",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "videojuegos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "videos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vieja",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "viejas",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "viejo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "viejos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "viendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viene",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vienen",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vienes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viento",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vientos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "viernes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vinagre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vinieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vinimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viniste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vino",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vinos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vio",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "violencia",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "violín",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "violines",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "virus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "visa",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "visión",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "visiones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "visita",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitaba",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitaban",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitando",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitándolos",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "visitantes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "visitar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitaría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitarías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitaron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitaste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visité",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitó",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visito",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viuda",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "viudo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "viudos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "viva",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viven",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vives",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viví",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivía",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivíamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivían",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivías",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivieron",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivimos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivir en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vivirás",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viviré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viviría",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivís",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viviste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivo",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vivos",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vocabulario",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "voces",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "voláis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volar",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volcán",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volcanes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volumen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "voluntario",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "voluntarios",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "volvamos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volvemos",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volverme",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "volvernos",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "volverse",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "volverte",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "volviendo al tema",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "volvió",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "votaré",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voy",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voy a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voz",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vuela",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuelan",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuelas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuelo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuelos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vuelva",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuelve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuelven",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuelves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vuelvo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "web",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "y",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "y después",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "y medio",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "y si",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ya",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ya mismo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ya que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ya veremos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "yendo",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "yo",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "yo fui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "yo no",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "yo sí",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "yoga",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "yogur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "yogures",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "zanahorias",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "zapato",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "zapatos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "zapatos deportivos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "zona",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "zonas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    }
]

export default wordList;