import React from "react";
import { Container, Card, Image, Carousel } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../../context/AppMetaDataContext";

import socialImage from "../../duolingo-ninja-social-sharing.png";

import LearnDuolingoVocabularyMainCTA from "../../Components/LearnDuolingoVocabularyMainCTA";
import HowItWorks from "../../Components/HowItWorks";
import Benefits from "../../Components/Benefits";
import Customize from "../../Components/Customize";
import FounderQuote from "../../Components/FounderQuote";
import DownloadExtension from "../../Components/DownloadExtension";

import DuolingoNinjaAd from "../../Components/DuolingoNinjaAd";
import DuolingoCharacterBeaImage from "../../duolingo-character-bea.jpg";
import DuolingoCharacterEddyImage from "../../duolingo-character-eddy.jpg";
import DuolingoCharacterFlagstaffImage from "../../duolingo-character-flagstaff.jpg";
import DuolingoCharacterJuniorImage from "../../duolingo-character-junior.jpg";
import DuolingoCharacterLilyImage from "../../duolingo-character-lily.jpg";
import DuolingoCharacterLinImage from "../../duolingo-character-lin.jpg";
import DuolingoCharacterLucyImage from "../../duolingo-character-lucy.jpg";
import DuolingoCharacterOscarImage from "../../duolingo-character-oscar.jpg";
import DuolingoCharacterVikramImage from "../../duolingo-character-vikram.jpg";
import DuolingoCharacterZariImage from "../../duolingo-character-zari.jpg";
import DuolingoCharacterDuoImage from "../../duolingo-character-duo.jpg";

import DuolingoNinjaPromoImage from "../../duolingo-ninja-characters-promo.png";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);

  var duolingoCharacters = [
    {
      name: "Bea",
      image: DuolingoCharacterBeaImage,
    },
    {
      name: "Eddy",
      image: DuolingoCharacterEddyImage,
    },
    {
      name: "Flagstaff",
      image: DuolingoCharacterFlagstaffImage,
    },
    {
      name: "Junior",
      image: DuolingoCharacterJuniorImage,
    },
    {
      name: "Lily",
      image: DuolingoCharacterLilyImage,
    },
    {
      name: "Lin",
      image: DuolingoCharacterLinImage,
    },
    {
      name: "Lucy",
      image: DuolingoCharacterLucyImage,
    },
    {
      name: "Oscar",
      image: DuolingoCharacterOscarImage,
    },
    {
      name: "Vikram",
      image: DuolingoCharacterVikramImage,
    },
    {
      name: "Zari",
      image: DuolingoCharacterZariImage,
    },
    {
      name: "Duo",
      image: DuolingoCharacterDuoImage,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          If Duolingo Characters Were Real People - {metaData.appName}
        </title>
        <meta
          name="description"
          key="description"
          content="AI-generated images based on Duolingo characters like Bea, Lin, Vikram, and Oscar."
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <Card className="border-0 mb-5">
          <Card.Body>
            <Card.Text>
              {/* <DuolingoNinjaAd /> */}

              <h1
                className="text-center"
                style={{ marginBottom: "4%", marginTop: "5%" }}
              >
                If Duolingo Characters Were Real People
              </h1>
              <center>
                <Carousel interval={null} style={{ maxWidth: "600px" }}>
                  {duolingoCharacters.map((character) => (
                    <Carousel.Item>
                      <Image
                        src={character.image}
                        alt={character.name}
                        style={{ maxWidth: "600px" }}
                      />
                      <Carousel.Caption>
                        <h3>{character.name}</h3>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                  <Carousel.Item>
                    <a href="https://duolingoninja.com/">
                      <Image
                        src={DuolingoNinjaPromoImage}
                        alt="Duolingo Ninja"
                        style={{ maxWidth: "600px" }}
                      />
                    </a>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </center>
              <p className="text-center mt-5">
                These are AI-generated images based on the Duolingo characters.
              </p>
              <p className="text-center">
                The characters include Bea, Eddy, Flagstaff, Junior, Lily, Lin,
                Lucy, Oscar, Vikram, and Zari.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>

        <hr className="mb-5" />
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <Benefits />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
