import React, { useContext, useEffect } from "react";
import Navigation from "./Components/Navigation";
import { Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Account from "./pages/Account";
import Success from "./pages/Success";
import Privacy from "./pages/Privacy";
import ReleaseNotes from "./pages/ReleaseNotes";
import IfDuolingoCharactersWereRealPeople from "./pages/SEO/IfDuolingoCharactersWereRealPeople";
import DuolingoVocabularyList from "./pages/SEO/DuolingoVocabularyList";
import DuolingoSpanishVocabularyList from "./pages/vocabulary/Spanish";
import DuolingoFrenchVocabularyList from "./pages/vocabulary/French";
import DuolingoRussianVocabularyList from "./pages/vocabulary/Russian";
import DuolingoGermanVocabularyList from "./pages/vocabulary/German";
import DuolingoJapaneseVocabularyList from "./pages/vocabulary/Japanese";
import DuolingoKoreanVocabularyList from "./pages/vocabulary/Korean";
import DuolingoItalianVocabularyList from "./pages/vocabulary/Italian";
import DuolingoHindiVocabularyList from "./pages/vocabulary/Hindi";
import ComprehensibleInput from "./pages/SEO/ComprehensibleInput";
import CanDuolingoMakeYouFluent from "./pages/SEO/CanDuolingoMakeYouFluent";
import DuolingoFlashcards from "./pages/SEO/DuolingoFlashcards";
import Review from "./pages/Review";
import NotFound from "./pages/NotFound";
import CookieDetection from "./pages/CheckIfCookiesAreEnabled";
import Footer from "./Components/Footer";
// could be rewritten and simplified to use a Hook instead
// https://www.youtube.com/watch?v=ZBZ6BqoUDsU&ab_channel=yoursTRULY
import AuthContext from "./context/AuthContext";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function App() {
  const context = useContext(AuthContext);
  console.log("CONTEXT VARIABLES AND METHODS: ", context);

  // send pageview to Google Analytics only when location (path) changes
  let location = useLocation();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-V2T4J6Y9YR");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    context.getAuthInfo();
  }, []);

  if (context.has_been_verified === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navigation />
      <br />
      <br />
      <Routes>
        <Route path="/" element={<Home />} />
        {context.user_id && <Route path="/dashboard" element={<Dashboard />} />}
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/account/change-password" element={<ChangePassword />} />
        {context.user_id && <Route path="/account" element={<Account />} />}
        <Route path="/success" element={<Success />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/release-notes" element={<ReleaseNotes />} />
        <Route path="/cookie-detector" element={<CookieDetection />} />
        <Route
          path="/if-duolingo-characters-were-real-people"
          element={<IfDuolingoCharactersWereRealPeople />}
        />
        <Route
          path="/duolingo-vocabulary-list"
          element={<DuolingoVocabularyList />}
        />
        {/* TODO: the language could actually be passed as a prop - no need to have separate files */}
        <Route
          path="/duolingo-spanish-vocabulary-list"
          element={<DuolingoSpanishVocabularyList />}
        />
        <Route
          path="/duolingo-french-vocabulary-list"
          element={<DuolingoFrenchVocabularyList />}
        />
        <Route
          path="/duolingo-russian-vocabulary-list"
          element={<DuolingoRussianVocabularyList />}
        />
        <Route
          path="/duolingo-german-vocabulary-list"
          element={<DuolingoGermanVocabularyList />}
        />
        <Route
          path="/duolingo-japanese-vocabulary-list"
          element={<DuolingoJapaneseVocabularyList />}
        />
        <Route
          path="/duolingo-korean-vocabulary-list"
          element={<DuolingoKoreanVocabularyList />}
        />
        <Route
          path="/duolingo-italian-vocabulary-list"
          element={<DuolingoItalianVocabularyList />}
        />
        <Route
          path="/duolingo-hindi-vocabulary-list"
          element={<DuolingoHindiVocabularyList />}
        />
        <Route path="/comprehensible-input" element={<ComprehensibleInput />} />
        <Route path="/can-duolingo-make-you-fluent" element={<CanDuolingoMakeYouFluent />} />
        <Route path="/duolingo-flashcards" element={<DuolingoFlashcards />} />
        <Route path="/review" element={<Review />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
