const wordList = [
    {
        "word": "а",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "август",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "августе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "автобус",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "автобуса",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "автобусная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "автобусной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "автобусные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "автор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "автора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "авторе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ага",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "адвокат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "адвоката",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "адвокаты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "администратор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "администратора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "адреса",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "адресе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "азиатские",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "азиатский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "азиатском",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "азии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "академией",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "академии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "академия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "актёр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "актёры",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "актриса",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "актрисы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "алло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "альбом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "альбома",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "америка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "американец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "американка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "американке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "американский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "американскую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "американцев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "американцу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "америке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "анализ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "анализа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "анализом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ангел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ангела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ангелы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "англии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "английски",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "английский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "англичане",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "англичанин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "англию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "англия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "англо-русский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "англо-русского",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "анна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "анне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "анну",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "апельсинами",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "апельсиновый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "апельсины",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "апрель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "апреля",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "аптека",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "аптеку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "арестовали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "арестован",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "арестованы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "армии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "армия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "артист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "артисты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "архитектор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "архитектура",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "архитектурой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "атеист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "атеистом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "атом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "атома",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "атомов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "аудитории",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "аэропорт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "аэропорту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бабушка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бабушки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бабушку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "багаж",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "балерина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "балерину",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "балет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "балета",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "балетом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "балкон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "банан",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бананы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "банк",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "банка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "банком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "банку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "банок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "баскетбол",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "баскетболист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "баскетболиста",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "баскетболисты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "баскетболом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бегает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бегать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бегают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "беги",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бегу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бегут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бедной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бедный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бежал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бежали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бежать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бежим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бежит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бежите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "без",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "белой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "белоруссии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "белоруссия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "белую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "белые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "белый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "белым",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "белых",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бензин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бензина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "берегу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "берём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "берёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "беру",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бесплатно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бесплатные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бесплатный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "беспокоимся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "беспокойтесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "беспокоюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бесполезная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бесполезные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бесполезный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бесполезных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "библиотека",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "библиотеке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бизнес",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бизнесмен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бизнесмена",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бизнесменам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бизнесом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "билет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "билете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "билеты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "биологии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "биологический",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "биологическом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "биология",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "битва",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "благодарю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "благодаря",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "близко",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "блин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "блог",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "блога",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бог",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бога",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "богатые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "богатый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "боимся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "боится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "более",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "болен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "болеть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "болит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "больна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "больнице",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "больницу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "больницы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "большая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "больше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "большие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "большинство",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "больших",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "большое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "большой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "большом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "большую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "болят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "борщ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ботинки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ботинок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "боюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "боялась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "брат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "брата",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "братом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "братья",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бреетесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бреется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "брился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бросает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бросать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бросаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бросил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бросила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "брюки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "буддистом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "буддисты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "будем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "будет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "будешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "буду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "будут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "будущая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "будущий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "будь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "будьте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бумага",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бумаги",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бутылки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бутылку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бутылок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "бы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "был",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "была",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "были",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "было",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "быстрая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "быстрее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "быстро",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "быстрый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "быть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "в",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "в виду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "в депутаты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "в-третьих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вагон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вагоне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вагонов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "важно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "важный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ваза",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вазе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ванну",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ваня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "варить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вас",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ваш",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ваша",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ваше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вашего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вашей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вашем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ваши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вашу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "введите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ввела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ввели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вверх ногами",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вдвоём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вдруг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ведёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "веду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ведут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ведь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "век",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "века",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "веке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "велика",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "велики",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "великие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "великий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "велико",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "велосипед",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "велосипеда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "велосипеде",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вера",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "верит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "верить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "веришь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "верно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вернуться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "верх",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "верю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "верят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весёлая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весёлым",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весенние",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весенним",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весенних",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "весь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ветер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вечер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вечера",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вечером",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вещества",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "веществами",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вещество",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вещи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вещь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "взрослые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "взрослый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "взрослых",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "взял",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "взяла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "взяли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вид",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видео",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "виды",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "видят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вижу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вилка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вилки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вилкой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вилок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вино",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "виновен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "виновна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "виновны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "висел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "висела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "висеть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "висит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "включи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "включил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "включила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "включите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "включить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вкусная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вкусное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вкусные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вкусный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "власти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "власть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вместе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вместо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "внимателен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "внимательна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "внимательны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "внук",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "внуки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "внучка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "во-вторых",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "во-первых",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вовремя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вода",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "водород",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "водорода",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воды",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "возвращалась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "возвращаться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "возвращаюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "возле",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "возможность",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "возьми",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "возьмите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воину",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "войду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "войне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "войну",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "войны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "войти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вокруг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "волейболе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "волейболом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "волк",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "волосы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "волшебник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "волшебников",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вообще-то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вопрос",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вопросы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воре",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восемнадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восемь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восемьдесят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воскресенье",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воскресенью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "воскресеньям",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восстание",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восстании",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восстановили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восстановить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восточной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восточную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восьмидесяти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "восьмой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вот",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вошел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вошла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вошли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "впереди",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "враг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "врага",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "враги",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "врагов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "врач",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "врача",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "врачом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вращается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вредная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вредную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "времени",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "время",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "все",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всё",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всё равно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всегда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вселенная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вселенной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всему",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всех",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вспоминает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вспоминаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вспоминаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вставать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вставил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вставить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вставь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встаёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встанем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встань",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встаньте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встретил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встретила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встретили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встретились",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встретиться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встречается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встречаться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "встречаются",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "всю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вторник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "второй",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вход",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "входа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вчера",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выборах",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выборы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выбрал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выбрала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выбрать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выиграл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выиграла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выиграли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выйду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выйти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выключи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выключил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выключила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выключили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выключить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выпил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выпила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выпьет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выпью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вырос",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выросла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "высокая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "высокий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "высокое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выставка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выстрелил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выстрелили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выступал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выступать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выступили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выступит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выступят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выход",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выхода",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выходные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выходным",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выходом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "выше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вышедшая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вышедшего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вышедший",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вышедших",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "вышел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "газ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "газета",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "газету",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "газеты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "газы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "галерее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "галерея",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гараж",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гараже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "где",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "где-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "где-то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "германии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "германию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "германия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "герой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гитара",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гитаре",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глава",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "главное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "главный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "главными",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глаз",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глаза",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глубина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глубине",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глубиной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глупая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глупое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глупые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "глупый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говори",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говоришь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорящая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорящего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "говорящую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "год",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "года",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "году",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "голова",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "голос",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "голосовали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "голосовать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "голуби",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "голубь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "голубя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гораздо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "горе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "город",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "города",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "городе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "городу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "горы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "горячая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "горячее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "горячий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гости",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гостиниц",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гостиница",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гостиницы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гость",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "государства",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готова",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовилась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовиться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовлю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовлюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готово",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "готовят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "градуса",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "градусов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "граница",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "границей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "границу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "греции",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "грецию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "греция",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "грибы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "громкая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "громкий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "громкую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "груди",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "группа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "группе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "грустная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "грустно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "грустную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "грустный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "грязно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гуляем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гуляет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гуляешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гуляла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гулять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "гуляя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "да",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "да здравствует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "давай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "давайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "давление",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "давлении",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "давления",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "давно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "даёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "даже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "далеко",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "данные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "данных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "даст",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "два",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двадцатого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двадцатом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двадцатый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "две",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двенадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двери",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дверь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дверью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двести",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двигается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "движется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двойка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двойки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двойку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дворе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дворец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дворца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дворце",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двум",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двух",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двухтысячного",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "двухтысячном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девочек",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девочка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девочкам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девочках",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девочке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девочки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девочкой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девочку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девушка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девушки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девяносто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девятнадцатого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девятнадцатом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девятнадцатый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девятнадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девятое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девятый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "девятьсот",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дедушка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дедушки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дедушку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "действия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "декабрь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "декабря",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "делает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "делаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "делаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "делал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "делать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "делаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дело",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дело в том",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "денег",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "день",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "деньги",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "депутат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дереве",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "деревне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "деревни",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "деревня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дерево",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дереву",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "деревья",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "деревьям",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "деревянная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "деревянный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "держит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "держу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "десерт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "десерта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "десяти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "десятое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "десятом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "десять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "детей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дети",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "детская",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "детский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "детям",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "детях",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дешёвая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дешевле",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дёшево",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дешёвый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дженни",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "диван",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "диване",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "диваны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дима",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "директор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "директора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "директором",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дирижёр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дирижёром",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "диск",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "диске",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дискотеке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дискотеку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "длина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "длинная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "длиннее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "длинную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "длинный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "длинных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "длиной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "длину",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "для",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дней",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "днём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "до",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "до сих пор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "добро пожаловать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доброе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "добрый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "договор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "договорились",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "договорился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "договориться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дождь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дождя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доказана",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доказано",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доказательств",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доказательства",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "документ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "документе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "долгие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "долгий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "долго",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "должен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "должна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "должны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доллара",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "долларов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дома",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "домашнее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "домашние",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "домашних",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доме",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "домов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "домой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дорога",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дороге",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дорогое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дорогой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дорогу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дороже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доска",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "доске",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "достаточно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дочерей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дочери",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дочь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дракон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дракона",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "драконов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дракону",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "древнего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "древней",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "древнем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "древнюю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "древняя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "друг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "друг друга",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "друг с другом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "другая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "другой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "другом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "друзей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "друзья",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "думая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "душ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "душа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "душе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "души",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дяди",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дядю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "дядя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "европа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "европе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "европейские",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "европейский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "европу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "европы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "его",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "еда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "едем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "едет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "едете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "единственную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "единственный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "едите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "еду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "едут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "еды",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "едят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "её",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ежедневная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ежедневно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ежедневные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ездил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ездила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ездим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ездит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ездите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ездить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "езжу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ему",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "если",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ест",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "естественно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "естественный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "есть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ехать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ешьте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ещё",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жалко",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жаль",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жарко",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ждал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ждала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ждали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ждать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ждёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ждите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ждут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "железная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "железное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "железной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "железную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "железный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жёлтая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жёлтые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жёлтый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жена",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "женился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "женится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жениться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "женский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "женскую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жену",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "женщина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "женщиной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "женщины",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "женюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жёсткий диск",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жёстком диске",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живёшь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живопись",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живописью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живот",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живота",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "животе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "животное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "животные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "животных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живущая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живущие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "живущих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жидкости",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жидкость",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жизнь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жило",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "жить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "журнал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "журналистом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "за",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заботился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заботится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заботишься",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "забочусь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заброшенном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заброшенных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "забываю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "забыл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "забыла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "забыли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "забыт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "забыта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "забыто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "завода",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заводе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "завтра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "завтрак",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "завтракаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "завтракал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "завтракать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "завтракаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "загорает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "загорать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "задала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "задам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "задание",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "задать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "задача",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зайду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зайти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закажу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заказал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заказываем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заказывает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заказываете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заканчивает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заключат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заключили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заключить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закончить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "законы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закрой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закройте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закрывается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закрылась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закрылся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закрыт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закрыто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "закрыты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "замка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "замке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "замок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "замуж",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занимает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занимается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занимаешься",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занимался",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занимаюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занята",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занятий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "занятия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заняты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "запад",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "западе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "западная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "западной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "западную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "записал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "записала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "записали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "записываете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "записываешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заплатившего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заплативший",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заплатил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заплатить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "запомнил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "запомнили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "запомнит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "запомню",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "запускать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "запусти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "запустил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зарплата",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зато",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зачем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зашла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "защищает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "защищали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "защищать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "заявил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "звезда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "звезду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "звёзды",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "звонил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "звонила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "звонит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здание",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здании",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зданию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здоров",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здорова",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здоровую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здоровы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здоровые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здоровый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здравствуй",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "здравствуйте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зелёной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зелёные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зелёный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зелёным",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "земле",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "земля",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зеркале",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зеркало",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зима",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зиме",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зимнее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зимние",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зимняя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зимой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зиму",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зимы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "злое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "злой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "змеи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "змею",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знакомую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знакомый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знаменитый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знаменитым",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знаменитых",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "значит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "знаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зовут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "золотое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "золотой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зонт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зонта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зонты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зоопарк",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зоопарка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зоопарке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зрители",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "зрителю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "и",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "и так",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иван",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иванович",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ивановна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "игра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играй",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играйте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играют",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играющая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играющий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играющих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "играющую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "игру",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "игры",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "идём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "идёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "идёте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "идёшь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иди",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "идите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "идти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "идут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "из",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "из-за",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "известная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "известно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "известный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "извини",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "извините",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "измерить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "изучает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "изучаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "изучал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "изучала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "изучать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "изучаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "изучен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "изучено",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "или",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "им",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "имеет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "имеете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "имел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "имена",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "имени",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "имею",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "имя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "инженер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "инженеры",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иногда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иностранец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иностранные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иностранный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иностранных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "иностранцев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "инструмент",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "инструментах",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "инструменте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интересная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интереснее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интересно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интересные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интересный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интересных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интересовалась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интересуетесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интересуется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интернет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "интернете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "искусством",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испанец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испании",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испания",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испанка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испанский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испанского",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испанском",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испанскую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "испанцев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "использовала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "использовались",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "использовать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "используете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "используется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "используй",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "истории",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "историк",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "историки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "историком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "история",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "италией",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "италии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "италия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "итальянец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "итальянка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "итальянские",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "итальянский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "итальянское",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "их",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ищем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ищет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ищу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "июле",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "июль",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "июля",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "июнь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "июня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "к",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "к сожалению",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кабинет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кабинетов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каждое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каждой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каждый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кажется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "казахстан",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "казахстана",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "казахстане",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "как",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "как раз",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "какао",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "какая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "какая-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "какие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "какова",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каково",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каковы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "какого-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "какой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "какой-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "календари",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "календарь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каменное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каменном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каменный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "камень",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "камня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "канада",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "канаде",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "канаду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "канады",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "канал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "канале",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каналов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кандидат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кандидата",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каникулы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "карандаш",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "карандаша",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "карандашей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "карандаши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "карандашом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "карта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "карте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картине",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картинка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картинку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картинок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картошка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картошки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картошкой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "картошку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "карту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кастрюлю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кастрюля",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "катаемся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "катается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кататься",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "катаюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кафе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "качество",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "каша",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кашу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "квартира",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "квартире",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "квартиру",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кем-то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "киев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "киева",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "киеве",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "килограмм",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "килограмма",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "километр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "километра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "километров",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кино",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кинотеатр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кинотеатрах",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "киоск",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "киоске",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кислород",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кислорода",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "китае",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "китаец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "китай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "китайская",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "китайскому",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "китайцев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "китая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клавиатура",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клавиатуре",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клавиатуру",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клавиша",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клавиши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клавишу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кладу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "класс",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "классе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "классическая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "классической",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "классом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клетка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клетки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клеток",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клуб",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клуба",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "клубе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ключ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ключи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "книг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "книга",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "книги",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "книгу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "книжный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кнопку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ковёр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ковра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ковром",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "когда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "когда-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кого-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кого-то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "колец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коллега",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коллеги",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "колледж",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "колледже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кольца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кольцо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "команда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "команде",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "комедии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "комедию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "комедия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "комната",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "комнате",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "комнату",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "комнаты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "компании",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "компания",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "композитор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "компьютере",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "компьютеры",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кому",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "конверт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "конверте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "конец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "конечно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "контрольная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "контрольную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "конфет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "конфеты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "концерт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "концерте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "концерты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коньках",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коньки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "копьё",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "корабля",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кораблях",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коридор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коридора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коробка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коробке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коробку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "коробок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "королеве",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "королевой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "королевы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "король",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "короткая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "короткий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "костюм",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кот",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "которое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "котором",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "который",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кофе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кофточка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кофточке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кошек",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кошка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кошке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кошки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кошку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "красивая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "красивый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "красная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "красного",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "красную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "красные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "красный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "красных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "крепость",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кресла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кресло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кристалл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кристаллов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "критиковал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "критиковали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "критиковать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "критикует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кроватей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кровати",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кровать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кроватью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кровь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кроме",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кружек",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кружка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кружки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "круто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "крыша",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "крыше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "крышей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "крышу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кстати",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кто-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кто-то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "куда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "купи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "купил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "купила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "купили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "купим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "купить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "куплю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курица",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курицу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курсы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "куртка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "куртку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "курю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "куска",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кусков",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кусок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кухне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кухни",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кухня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "куча",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "кучу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лаборатории",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лаборатория",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лампой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лампы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "левая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "левой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лёг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лёгкая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лёгкий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "легко",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "легла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лежал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лежала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лежали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лежит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лежу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лекарств",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лекарство",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лекцию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лекция",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лес",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лестниц",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лестница",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лестнице",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лесу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лета",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летнее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летние",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летний",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лето",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "летят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лечу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лечь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лидер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лимон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лимона",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лимоном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лимоны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "листа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "листов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "листы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "литература",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "литературу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лифт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лифта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лифте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лица",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лице",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лицо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "логин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ложек",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ложится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ложиться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ложка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ложки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ложку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ложусь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лондон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лондоне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лошадей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лошади",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лошадь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лук",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лука",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "луком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "луна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "луны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лучшая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лучше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лучший",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лыжах",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лыжи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "львы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "любим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "любимая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "любимый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "любит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "любите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "любишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "люблю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "любят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "людей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "люди",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ляг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "лягте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "магазин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "май",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "макарон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "макароны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "маленькая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "маленькие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "маленький",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "маленьких",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "маленькое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мало",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мальчик",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мальчика",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мальчикам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мальчиках",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мальчики",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мальчиков",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мальчиком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мальчику",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мама",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "маме",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мамой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "маму",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мамы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "март",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "марта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "масла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "масло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "масса",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "массу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "математика",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "математике",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "математики",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "математику",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "матери",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "матч",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "матча",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "машин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "машина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "машиной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мебели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мебель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "медведи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "медведь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "медик",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "медленная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "медленнее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "медленно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "медленные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "между",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "международная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "международные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "международный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "менеджер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "менеджером",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "менее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "меньше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "меню",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "меня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "меняется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "меняться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "меняются",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мест",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "места",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "месте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "место",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "месяц",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "месяца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "месяцев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "металл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "металлов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "металлы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "метод",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "методов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "методы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "метра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "метро",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "метров",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "меч",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мечетей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мечеть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мечтает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мечтать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "микроскоп",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "микрофон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "микрофона",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "микрофоны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "миллион",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "миллиона",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "миллионов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мимо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "министр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "министра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "минус",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "минут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "минута",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "минуту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "минуты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мир",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мира",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мире",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мировая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мировой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "миска",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "младшая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "младший",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "млекопитающее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "млекопитающих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мнение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "много",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мобильного",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мобильный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мобильных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "могу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моё",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "может",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "может быть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "можете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "можешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "можно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мои",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молекула",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молекулы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молись",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молитесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молиться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молодая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молодец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молодой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молодцы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молока",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "молоко",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "монитор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "монитора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "море",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мороженое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моря",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "москва",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "москве",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "москву",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "москвы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мост",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моста",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мостом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мосту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мотор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мотоцикл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мотоцикле",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мошенник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мошенника",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мою",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моют",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "моя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "муж",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мужа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мужская",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мужской",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мужчина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мужчиной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мужчины",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "музее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "музей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "музыка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "музыкальном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "музыкальный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "музыкальных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "музыкант",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "музыкантом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мультфильм",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мультфильма",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мультфильмы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мусульманин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мусульманка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "муха",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мухи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мыла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мыло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мылся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мыть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мыться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мышей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мыши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мышь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мягкая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мягкие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мягкий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "мясо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "на",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "на свободе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наблюдается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "над",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "надевает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "надеваешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "надеваю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "надела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "надень",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наденьте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "надеть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "надо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нажал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нажала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нажать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нажмите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "назад",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "название",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "называется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "найдём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "найду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "найти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наконец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "налево",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нами",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напечатай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напечатайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напечатала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напечатать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написав",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написавший",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написан",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написана",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написанная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написанное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написанные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написано",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "написать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напишет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напиши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напишите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напишу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "напишут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "направо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарезать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарисовал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарисовала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарисуем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарисует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарисуешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарисуй",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарисуйте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нарисую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "народу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нас",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "население",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "настоящая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "настоящего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "настоящие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "настоящий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "настройки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наука",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "науке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наукой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "научилась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "научились",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "научился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "научится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "научиться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "научишься",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "научусь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наушники",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "находимся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "находитесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "находится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "находятся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "началась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начале",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начало",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начальник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начальника",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начинается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начинаются",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начнём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начнёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начнётся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "начну",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наш",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наша",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нашего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нашей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нашёл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нашем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "наши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нашла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нашу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "не",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "не о чем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "не подумав",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "не с кем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "небе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "небо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "неверно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "неверный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "негде",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "него",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "недель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "неделя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "неё",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "незачем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "неизвестно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ней",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "некогда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "некого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "некому",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "некуда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нельзя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "немец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "немецки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "немецкий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "немка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "немного",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "непонятно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "неправильно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "неправильный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "несколько",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "несмотря на",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нету",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "неудивительно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нечего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ни",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нигде",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "низ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "никогда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "никому",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "никто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ним",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ними",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "них",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ничего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "но",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "новая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нового",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "новой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "новость",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "новую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "новый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ног",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нога",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ноги",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нож",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ножей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ножи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нормальная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нормальные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нормальный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нос",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "носы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ночи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ночь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ночью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ноябрь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ноября",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нравится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нравятся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нужен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нужна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нужно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "нужны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "о",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "об",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оба",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обед",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обедать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обсудили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обсудить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обсуждаем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обсуждаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обсуждать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обсуждают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обуви",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обувь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "общая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "общее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "общественного",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "общественный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "общий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объясни",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объяснит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объясните",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объяснить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объясняет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объясняется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объяснял",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объясняла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объяснять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объясняю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "объясняются",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обычно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обычного",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "обычный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "овощи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "огромная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "огромный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "огурец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "огурца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "огурцы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одеваемся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одевается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одеваешься",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одевалась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одеваюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одевшись",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одежда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одежду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "один",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "один и тот же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одинаковые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одинаковый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одинаковых",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одиннадцатая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одиннадцатого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одиннадцатое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одиннадцатый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одиннадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одно и то же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одного и того же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "одном и том же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "озёра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "озере",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "озеро",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оказалась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оказались",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оказалось",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "окна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "окно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "окном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "около",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "окончил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "окончила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "окончили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "октябре",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "октябрь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "он",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "она",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "они",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опаздывает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опаздываешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опаздывал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опаздывала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опаздываю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опера",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опере",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оперой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оперы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "описана",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "описывает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опоздал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опоздала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оппозиции",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оппозиция",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опыт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опыта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "опять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "организовала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "организовали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оркестр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оркестром",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "оружие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осени",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осенние",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осенних",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осень",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осенью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осматривает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осматривал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осматривали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осматривают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осмотреть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осталась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "осталось",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "остался",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "остановилась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "остановилось",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "остановился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "остановиться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "остановка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "остановки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "от",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ответ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ответа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ответил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ответила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ответили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ответить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ответы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отвечает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отвечаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отдела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отделе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отдохнули",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отдохнуть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отдыхаем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отдыхает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отдыхать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отдыхаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "откройте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открывает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открывается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открываю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открыл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открылась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открыли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открылось",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открылся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открыт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открыта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открыто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "открыть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "откуда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отличается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отличаются",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отличная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отлично",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отличный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отойди",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отойдите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отправил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отправила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отправить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отпуск",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отпуска",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отпуске",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отрежь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отрезала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отце",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "отцы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "офис",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "офисе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "офисы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "официант",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "официанткой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "официантом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "очень",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "очки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "очков",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ошибка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ошибку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ошибок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пакет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пакеты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "палец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пальто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пальцев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "памятник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "памятнику",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "память",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "папа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "папе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "папой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "папу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "папы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "парень",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "парк",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "парке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "парку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "парламент",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "парламента",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пароль",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "партии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "партий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "партия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "партнёр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "партнёром",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пациент",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пациента",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пациентов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пейте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "первый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перевод",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переводим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переводит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переводом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переводчик",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переводчиком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переводы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перевожу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перед",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "передай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "передайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "передача",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "передачу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "передумаем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "передумаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "передумал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "передумала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переедем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перееду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переехала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переехали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перезвонил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перезвоню",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переоделась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переоденусь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переодеться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перестали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перестань",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переходите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "переходишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перехожу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "перца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "песен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "песню",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "песня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "петербург",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "петербурга",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "петербурге",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "печенье",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пешком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пианино",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пианист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пианиста",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пива",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пиво",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "писал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "писала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "писатель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "писателю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "писателя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "писать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "письма",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "письмо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пицца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пишет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пишешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пиши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пишите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пишу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пишущая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пишущий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плаваем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плаваете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плавай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плавайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плавать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "план",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "планета",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "планеты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "планов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плану",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "планы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "платить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "платье",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плеер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плеера",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плееры",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плиту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плохая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плохие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плохо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плохой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плохую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "площади",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "площадь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "площадью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плывёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плыву",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плывут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плыла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "плюс",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пляж",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пляже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "по",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "по мнению",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "по словам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "по-вашему",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "по-моему",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "по-твоему",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "победила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "победит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "победить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "побежал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "побежала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "побежали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поблагодарил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поблагодарить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "побольше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повар",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повара",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повернул",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повернула",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повернули",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повернуть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повесил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повесили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повесить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повтори",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повторите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "повторить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поговори",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поговорим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поговорит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поговорите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "погода",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "погоду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "под",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подарил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подарили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подарки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подарок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подарю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подождать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подожди",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подозреваете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подозревать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подозревают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подойди",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подошёл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "подошли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поезд",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поезда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поезде",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поесть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поехал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поехала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поехали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поёшь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пожалуйста",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позавтракал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позавтракали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позавтракать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позавчера",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позвони",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позвонила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позвонили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позвоните",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "позвонить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поздно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поздравляю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "познакомился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "познакомиться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поиск",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пойдём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пойдёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пойду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поймёшь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пойти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пока",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "покажу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "показал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "показали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "показалось",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "показывает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "показывал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "показывала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "показываю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "показывают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "покупаем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "покупает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "покупать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "покупаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "покупают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пол",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полгода",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полезную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полезный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "политик",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "политика",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "политиков",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полиции",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полиция",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "половина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "половину",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "положи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "положил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "положила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "положите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полотенца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полотенце",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "получил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "получит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "получите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "получу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "польский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "польского",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "польскую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "польша",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "польше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "польшу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полюс",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "полюсе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поменьше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помидоров",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помнил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помнила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помнили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помнит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помню",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помнят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помогает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помогаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помогать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помогают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помогла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поможем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помойте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помочь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помощью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "помыть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понедельник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понедельника",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понедельникам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понедельнику",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понимает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понимаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понимаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понимая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понравилась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понравился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понравится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поняла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поняли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "понятно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пообедал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пообедать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пообедаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "попала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "попросил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "попросила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пораньше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "портрет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "портретом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "портреты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "посадили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "посадят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "после",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "последние",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "последний",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "последняя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "послезавтра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "посмотрев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "посмотрел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "посмотрела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "посмотрим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пост",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поставила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поставили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поставит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поставишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поставлю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поставь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поставьте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "постирать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "построен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "построено",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поступил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поступила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поступить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "посуда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "посуду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "потерял",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "потеряла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "потом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "потому",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "потратить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "похожа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "похоже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "похожи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поцеловались",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поцеловался",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "почему",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "почти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "почту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пошёл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пошла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пошли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поэт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поэта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поэтому",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поэты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "поют",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прав",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "права",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правило",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правильно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правильный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правительства",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правительство",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "право",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "правую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "праздником",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "предложение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "предложений",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "предложения",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "предмет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "президент",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "президента",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "президентом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прекрасное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прекрасные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прекрасный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "премьер-министр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "премьера",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преподавала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преподаватель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преподаёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преподаёте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преподаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преподают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преступление",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преступлением",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преступник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "преступника",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "при",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приближается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "привет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "привидение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "привидений",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "привидения",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "привык",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "привыкла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "привыкли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "привыкнуть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пригласил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пригласила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пригласили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приговорил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приговорили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приготовим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приготовит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приготовить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приготовленный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приготовлю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "придёте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "придумал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "придумать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "придумаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приедет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приеду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приезжать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приехал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приехали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прилетела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прилетели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прилетит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "примут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принёс",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принеси",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принесла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принести",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принесу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принимает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принимаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принимают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принял",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приняли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "принять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "природа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "природу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приходил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приходили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приходит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прихожу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пришёл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пришла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пришли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пришло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приятно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "приятный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "про",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробежала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробежали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробежать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробежит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проблем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проблема",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробовал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробовала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробовали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробуем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пробую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проведём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проведёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "провёл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "провела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "провели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проверить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проверьте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проверю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проводим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проводить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проводят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "программа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "программист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "программистом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "программу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продавали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продавец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продавца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продаёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продаётся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продаст",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продаются",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продолжить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "продукты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проедем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проеду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проектах",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проекте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проектом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проехал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проехала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проехали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проехать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проживёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прожил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прожили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проиграл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проиграли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "производит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "производите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "производят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "произойдёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "произошла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "произошло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "происхождение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "происхождения",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пройдём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пройдёте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пройти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пропуск",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пропуска",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пропустила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пропустили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пропустит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "просит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "просишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проспал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "просплю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прости",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "простите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "просто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "простое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "простой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "против",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "профессии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "профессию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "профессия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прохладно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проходит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "проходите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прохожу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "процесс",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "процессы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прочитай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прочитайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прочитал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прочитала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прошёл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прошла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прошлая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прошли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прошлый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пруд",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пруда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пруду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прыгает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прыгать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прыгают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "прямо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "птиц",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "птица",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "птицы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пустая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пустой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пустыми",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пусть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "путешествовать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "путь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пушек",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пушка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пушки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьеса",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьесу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьесы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьёшь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьют",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьющая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьющего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пьющий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пюре",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятёрка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятёрку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пяти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятнадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятница",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятницам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятницу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятьдесят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "пятьсот",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "работы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рабочего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рабочий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рад",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рада",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "радио",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рады",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "раз",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разбил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разбила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разбили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разбить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разговор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разговоры",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "раздевается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "раздеваешься",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "раздевайся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "раздевайтесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "раздеваюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "раздевшись",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "размер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "размера",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разобрал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разобрали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разобрать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разработал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разработали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "разработать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "район",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "районе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рано",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "раньше",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расписание",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расписания",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рассвет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рассвете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рассказ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рассказывает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рассказываешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рассказывают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расследование",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расследовании",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расстояние",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расстоянии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расстроилась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расстроился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расстроится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "растёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "расту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "растут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ребёнка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ребёнком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ребёнок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "революции",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "революцию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "революция",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "редко",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "режиссёр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "режиссёром",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "результат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "река",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "реке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "реки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рекой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "религией",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "религии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "религия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рептилии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рептилий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ресторан",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "решат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "решение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "решил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "решить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рима",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "риме",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рис",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "риса",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисовать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисуем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисунки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисунков",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисунок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисующая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисующий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисующих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рисующую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "род",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рода",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родилась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родились",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родину",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родины",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родителей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родители",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родителям",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "родной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рождение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рождения",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рождества",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рождество",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "роман",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "романы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "россии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "россия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рост",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рот",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рубашка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рубашку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рублей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рубль",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рубля",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рука",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "руках",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "руки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "русски",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "русский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ручек",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ручка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ручки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ручкой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рыба",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рыбу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рыбы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рыжая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рыжие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рыжий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рыцари",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рыцарь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рюкзак",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ряд",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рядов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "рядом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ряду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "с",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "с молоком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "с новым годом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "с собой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сад",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "садись",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "садится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "саду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "садятся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сажусь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сайт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сайта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "салат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "салата",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сама",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "самая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сами",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "самое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "самолёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "самолёта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "самый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сахаром",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свари",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сварить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свежее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свежие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свежий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свежих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "светло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "светлое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "светлые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "светлый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свидания",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свидетель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свидетелю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свитер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свитере",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свитером",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свободен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свободна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свободная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свободно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свободного",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свободное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свободный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "своего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "своим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "своих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свойств",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свойства",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свойство",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "свою",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сдавала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сдаём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сдача",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сдачи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сдачу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сдают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделав",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделана",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделанная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделанное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделанный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделано",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сделаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "себя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "север",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "северная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "северном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "северный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "севшая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "севшие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "севшую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сегодня",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "седьмое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "седьмой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сейчас",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "секретарь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "села",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "семнадцатого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "семнадцатом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "семнадцатый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "семнадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "семь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "семьдесят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "семью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "семья",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сентябре",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сентябрь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "серая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сергей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сергея",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сердце",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сердцу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "середина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "середине",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "середину",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сериал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сериалы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "серые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "серый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "серьёзный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "серьёзных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сестра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сестрой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сестры",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сёстры",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сесть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сети",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сеть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сибири",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сибирь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сидеть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сидим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сидит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сильная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сильнее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сильней",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сильные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сильный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "синагога",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "синагоге",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "синагогу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "синей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "синий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "синих",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "синюю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "синяя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скажет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скажи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скажите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скажу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сказал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сказала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сказали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сказать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сказке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сказки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сказку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скачать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сквозь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сколько",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скопировала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скопировать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скопируй",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скорее всего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скоро",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скорого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скорости",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скорость",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скрипке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скучная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скучный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "скучных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слабая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слабые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сладкая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сладкий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сладкое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слева",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "следующей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "следующем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "следующие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "следующий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слишком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слова",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "словам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "словарём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "словарь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "словаря",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слово",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сложный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сломал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сломала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сломана",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "служат",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "служим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слушаем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слушаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слушай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слушайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слушать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слушаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слушают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слышал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слышала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "слышали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смеётся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смеешься",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смерти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смерть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смеются",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смеялся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смотрел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смотрела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смотрели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смотреть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смотри",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смотрим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смотрите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "смотрю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сначала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снег",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снега",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снегу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снизил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снизили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снимаем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снимаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снимал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снимала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снимали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снимаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снимите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снова",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "снял",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сняла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "со",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "со временем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собак",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собака",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собаки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собаку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собираемся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собираетесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собирается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собираешься",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собиралась",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собирались",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собирался",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собираюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собираются",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собрала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собрали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собрание",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собрания",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "собрать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "совершенно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "совершил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "совершить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "совет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "советуем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "советует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "советую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "современная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "современные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "современный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "совсем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "согласен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "согласна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "согласны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "содержание",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "содержании",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "создал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "создали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сока",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "солнце",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "солью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сообщение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сообщения",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сорок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сосед",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "соседа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "соседе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "соседи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "состоит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "состояние",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "состоянии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "состоят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "соуса",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "социальная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "социальной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "социальную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спасибо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спектакль",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спине",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спины",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сплю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спокойной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спорт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спортсмен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спортсменка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спортсменов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спортсмены",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "справа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спрашивает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спрашиваете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спрашивают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спросил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спросила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спросили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "спят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сразу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "среда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "средам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "средний",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "средняя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "среду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ссоримся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ссорюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ссорятся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ста",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ставил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ставила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ставить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ставлю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стадион",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стадиона",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стадионе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стакан",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стакана",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стакане",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "становилось",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "становится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "станцию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "станция",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "старая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "старик",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "старика",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "старики",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "старшая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "старший",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "старый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "статьи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "статья",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стена",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стене",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стены",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стёр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стёрла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стихи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стихов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стоим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стоит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стоить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стол",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столица",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столице",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столовая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столовой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столовую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "столы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сторона",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стороне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стою",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стоял",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стояла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стояли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стояло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стоят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стоять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "страдание",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "страна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стране",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "страниц",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "страница",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "страницы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "странное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "странные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "странный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "странных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "страшно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стрел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стрела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стрелы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стрелял",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стрелять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стреляют",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "строил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "строим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "строит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "строитель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "строить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "студент",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "студента",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "студентка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "студентов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стул",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стуле",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стулом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "стыдно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "суббота",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "субботу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "субботы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "суд",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "суда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "судьба",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "судьбу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "судьи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "судья",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "судя по",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сумка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сумках",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сумке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "суп",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "супа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "супермаркет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "супермаркете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "суши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "существует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "существуешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "существуют",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сцена",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сцене",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "считает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "считаете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "считается",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "считать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "считаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "съел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "съели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "съешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сын",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сыновей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сыновья",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сыр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сюда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сюжет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "сюжета",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "та",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "та же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "так",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "так же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "так как",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "так что",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "такая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "такая же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "таких",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "такое же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "такой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "такой же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "такси",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "талантливая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "талантливые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "талантливый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "там",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "там же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "танки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "танков",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "танковая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "танцевать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "танцуем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "танцует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "танцуете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тарелка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тарелке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тарелки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тарелку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тарелок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "твоё",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "твоего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "твоей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "твои",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "твоим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "твой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "твою",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "твоя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "те",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "театр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тебе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тебя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "текст",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "телевизор",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "телевизора",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "телефон",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "телефоне",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "телефону",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тёмная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "темно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тёмные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тёмный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "температура",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "теннис",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "теория",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "теперь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тёплая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "теплее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тепло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тёплую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тёплые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тёплый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "территории",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "территория",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "теряет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "теряешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "терять",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "теряю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тёти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тетради",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тетрадь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тётю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тётя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "техника",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "техники",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тихая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тихий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тихо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "то же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тобой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тогда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тогда же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "того",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тоже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "той же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "толстые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "толстый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "толстым",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "только",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "только что",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "том",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тому",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тонн",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тонна",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тонны",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "торт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "торта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "торте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "торты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тот",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тот же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "точно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трава",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "траве",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трамвае",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трамвай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трамвая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "транспорт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "транспорта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тренируемся",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тренируетесь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тренируется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тренируюсь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "третий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "третье",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "третьем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "третью",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трёх",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "три",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тридцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тринадцатая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тринадцатого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тринадцатое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тринадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "триста",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трудно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трудное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трудный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трудных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "трудом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туалет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туалете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туда",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туда же",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туман",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тумана",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туристы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туфель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туфли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "туча",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тысяча",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тысячи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тысячу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тюрьма",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тюрьме",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тюрьму",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тяжёлое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "тяжёлый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "у",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "убегу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "убежала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "убежишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "убийство",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "убийца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "убийцу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "убил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "убьют",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "увеличение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "увеличении",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уверен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уверена",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уверены",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "увидел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "увидела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "увидеть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "увидит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "увидите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "увидишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уволили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уволят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "углом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "углу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "удали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "удалила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "удалить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "удобная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "удобные",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "удобный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "удобных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "удовольствием",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уедут",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уезжаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уезжают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уехал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужасная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужасно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужасный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужинаем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужинает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужинать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужинаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ужинают",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "узнаем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "узнает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "узнал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "узнала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "узнали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "узнаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уйдём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уйду",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уйти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "украина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "украинец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "украинский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "украл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "украла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "украли",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "улица",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "улице",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "улицы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умеет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умеешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умерла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умерло",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умею",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умеют",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "умных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "университет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "университета",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "университете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "упадёт",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "упадёшь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "упал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "упала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "упало",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "упражнение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "упражнения",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "урок",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "урока",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ускорение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ускорения",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "успешная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "успешный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "успешных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "устал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "устала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "устали",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "утверждение",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "утка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "утки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "утку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "утра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "утро",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "утром",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ухо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уходим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уходит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уходите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уходить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уходя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ухожу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "участвовать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "участвует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "участвуешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "участвую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учебник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учебника",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учебнике",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учебники",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ученик",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ученики",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учеников",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ученица",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ученицы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учёный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учёным",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учились",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учился",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учителей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учитель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учителю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учителя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учится",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учиться",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "учусь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "уши",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ушла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "файл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "файла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "файлы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "факультеты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фамилии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фамилию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фамилия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "феврале",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "февраль",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "февраля",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фейсбук",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "физика",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "физике",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "физический",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фильм",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фильмов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "финансовая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "финансовый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фирма",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фирмы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "флешка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "флешке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "форма",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "форму",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "формы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фотоаппарата",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фотографии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фотографий",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фотографировать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фотографию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "франции",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "францию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "француз",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "французов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "французски",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "французские",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "французский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "французское",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "фрукты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футбол",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футболист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футболисты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футболка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футболки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футболку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футболом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футбольной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "футбольный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "характер",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хватает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хватало",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хватит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хлеб",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хлеба",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходил",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходила",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходили",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходить",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходишь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ходят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хожу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хозяин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хозяином",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хоккей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "холодильник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "холодильнике",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "холодная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "холоднее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "холодно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "холодное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "холодный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хорошая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хорошего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хорошее",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хорошей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хорошие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хороший",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хорошо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хорошую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хотел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хотела",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хотели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хотите",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хотя",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хотят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хочет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хочется",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хочешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хочу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "храм",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "храме",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "христиане",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "христианин",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "христианка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "худая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "художник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "художника",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "художником",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "худой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "худые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "хуже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "цвет",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "цвета",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "цветы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "цел",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "целый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "цена",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "центр",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "центра",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "центральном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "центральный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "центре",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "цену",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "церквей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "церкви",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "цирк",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чай",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чайник",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чайнике",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "час",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "часа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "часов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "части",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "частица",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "частицы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "часто",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "часть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чашек",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чашка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чашки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чашку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чаще",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чё",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чё-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "человек",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "человека",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "человеке",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "человеком",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "человеку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чем",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чём",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чём-то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чем-то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "через",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чёрная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чернов",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чёрный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чёрных",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четверг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четверга",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четвергам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четвёрка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четвёрку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четвёртом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четвёртый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четыре",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "четырнадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "число",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чистые",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чистый",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чистых",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читает",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читаешь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читайте",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читал",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читала",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читатели",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читатель",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читаю",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читающая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читающего",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читающие",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "читая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "что",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "что такое",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "что-нибудь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "что-то",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чтобы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чувствовать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чувствует",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чувствуете",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чувствую",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чуть не",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чуть-чуть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чьё",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чьи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "чья",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шапка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шапку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шёл",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шестая",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шести",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шестнадцать",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шестой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шесть",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шестьдесят",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ширина",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шириной",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ширину",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шкаф",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шкафу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школ",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школа",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школам",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школу",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школы",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школьная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школьном",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "школьный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шла",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шоколад",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шоколада",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шоколадная",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шоколадное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шоссе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "шумно",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "щи",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "щит",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экзамен",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экзамена",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экзамене",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экзамену",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экзамены",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экономика",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экономики",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экономику",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экономист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экономиста",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "экскурсия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "эксперимент",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "эксперименты",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "электричестве",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "электричество",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "элемент",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "элемента",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "энциклопедии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "энциклопедию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "энциклопедия",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "эта",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этаж",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этаже",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этажей",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "эти",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этим",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "это",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этого",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этой",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этому",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "этот",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "эту",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "эффект",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "юбка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "юбку",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "юг",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "юге",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "южную",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "южный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "юридический",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "юридическом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "юрист",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "юристом",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "я",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "яблока",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "яблоки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "яблоко",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "яблочное",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "яблочный",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "явление",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "явления",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "является",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "язык",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "языка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "языки",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "яйца",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "яйцо",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "январе",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "январь",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "января",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "японец",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "японии",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "японию",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "япония",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "японка",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "японский",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "японского",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "японском",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "японцев",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ясно",
        "partOfSpeech": "",
        "gender": ""
    }
]

export default wordList;