const wordList = [
  {
    word: "ab",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "abend",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "abendessen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "abends",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "abenteuer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "aber",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "abfahrt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "abflug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "abgeben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "Abgeordnete",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Abgeordneten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "abgeschrieben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "abgestürzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "abgewaschen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "abhängig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "abheben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "Abiball",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "abitur",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "abnehmen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "abonnieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "abschluss",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "abschnitt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "abteilung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "abteilungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "abtrocknen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "abzugeben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "abzuholen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "acht",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "achte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "achten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "achtest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "achtunddreißig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "achtundzwanzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "achtung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "achtzehn",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "achtzehnten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "achtzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "adler",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "adresse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "adressen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "affe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "affen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "afrika",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "agentur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ägypten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ähnlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ähnlichen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ahnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "akademie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "akademien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "akku",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "akte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "akten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aktentasche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aktien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aktiv",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "aktive",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "aktiviere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aktivieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aktivität",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aktivitäten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aktualisieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aktualisiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aktuelle",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "akzeptiere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "akzeptieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "akzeptiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "album",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "alkohol",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "all",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "alle",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "allein",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "alleine",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "allerdings",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "allergien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "alles",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "alles gute",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "alles klar",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "allgemein",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "alltag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "alpen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "als",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "also",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "alt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "alte",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "alten",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "älter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "alter",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "älteren",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "alternative",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Altersheim",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "altes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "ältesten",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "ältestes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "am",
    partOfSpeech: "Preposition",
    gender: "Masculine",
  },
  {
    word: "am besten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am billigsten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am frischsten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am größten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am höchsten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am kleinsten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am nächsten tag",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am nettesten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am salzigsten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am schlechtesten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am schnellsten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am see",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am strand",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am süßesten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am telefon",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am teuersten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "am wochenende",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "amerika",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "amerikaner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "amerikanerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "amerikanischen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ampel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "amt",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "an",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "an der kasse",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "analyse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "analysen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ananas",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anbieten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ändere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "andere",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "anderen",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "anderes",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "anders",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "änderst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ändert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "änderung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "änderungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anfang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anfangen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "anfänger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anforderung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anforderungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "angabe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "angaben",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "angebot",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "angebote",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "angefangen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "angekommen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "angemeldet",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "angerufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "angeschaut",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "angst",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anhang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anhänge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Ankunft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anlage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anlagen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anleitung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anmachen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "anmelden",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "anmeldung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anmeldungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "annehmen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "anprobieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "anprobiert",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "anrede",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anregung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anregungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anruf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anrufbeantworter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anrufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ansage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ansatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anschluss",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ansicht",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ansichten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ansonsten",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "anspruch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ansprüche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anstrengend",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "anteil",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anteile",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "antrag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anträge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "antwort",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "antworte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "antworten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "antwortest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "antwortet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "anwalt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anwältin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anweisungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anwendung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anwendungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anzeige",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anzeigen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "anziehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "anzug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anzüge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "anzukommen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "anzurufen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "äpfel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "apfel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "apfelkuchen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "apfelsaft",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "apfelschorle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "apfelstrudel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "apotheke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "apotheken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Aprikose",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Aprikosen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "april",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "arabisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "arbeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "arbeite",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "arbeiten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "arbeiter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "arbeitest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "arbeitet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "arbeitete",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "arbeiteten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "arbeitgeber",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "arbeitgeberin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "arbeitgeberinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "arbeitnehmer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "arbeitserlaubnis",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Arbeitserlaubnis",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "arbeitsheft",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "arbeitslos",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Arbeitslose",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "Arbeitsstelle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "arbeitszimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "architekt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "architekten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "architektur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ärger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Ärger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ärgerlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "arm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "armband",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "arme",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "armen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "art",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "arten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "artikel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "artikeln",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "arzt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ärzte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ärztin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ärztinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "arzttermin",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "aß",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aßen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "asien",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "aßt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "atheist",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "atheisten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "atmosphäre",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "auch",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "auf",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "auf dem campus",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "auf dem markt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "auf jeden fall",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "auf wiederhören",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "auf wiedersehen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "aufgabe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aufgaben",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Aufgaben",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aufgemacht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aufgepasst",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "aufgeräumt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "aufgeregt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "aufgestanden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aufgewachsen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aufhören",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "aufladen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "auflage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aufmachen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "aufnahme",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aufnahmen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aufpassen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "aufräumen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "aufregen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "aufregend",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "aufregender",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "aufs",
    partOfSpeech: "Preposition",
    gender: "Neuter",
  },
  {
    word: "aufsatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "aufstehe",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "aufstehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "auftrag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "aufzug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "auge",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "augen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "augenblick",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "august",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "aula",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "aus",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "ausbildung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ausdruck",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "außen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "außer",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "außerdem",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "außerirdische",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ausflug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ausfüllen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ausgabe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ausgaben",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ausgang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ausgeben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ausgefüllt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ausgegangen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ausgehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ausgepackt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ausgestiegen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ausgezeichnet",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ausland",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ausländerbehörde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ausnahme",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ausnahmen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "auspacken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ausrede",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ausreichend",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ausschlafe",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ausstellung",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ausstellungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "austausch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "australien",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "ausverkauft",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "auswahl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Auswahl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ausweis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "auszugehen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "auto",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "autobahn",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "autofahren",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "automat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "automaten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "automatisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "autor",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Autoren",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "autoren",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "autos",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "autowerkstatt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "baby",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "babys",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bach",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bäche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bäcker",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bäckerei",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bäckereien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bäckerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "backwaren",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bad",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "badeanzug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "badehose",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "badetuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "badewanne",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "badezimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bahn",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bahnhof",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bahnhöfe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Bahnsteig",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bald",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "balkon",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "balkone",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ball",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bälle",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Ballett",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "banane",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bananen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "band",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bank",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bänke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "banken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bankkauffrau",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bankkaufmann",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bär",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bar",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bär",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bären",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bargeld",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bars",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bart",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "basieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "basiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "basilikum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "basis",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "basketball",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "basketballspiel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "batterie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "batterien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bauch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bauchschmerzen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bauen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bauer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bäuerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bauern",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bauernhof",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "baum",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bäume",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "baumwolle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "baust",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bayern",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "Bayern München",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "beachten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beamter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "beamtin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "beantworte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beantworten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beantwortet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bearbeite",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bearbeiten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bearbeitest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bearbeitet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "becher",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bedarf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bedeutet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bedeutung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bedienung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bedingung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bedingungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "beenden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beendest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beendet",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "begann",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "begannen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "begannst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "begannt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "begeistert",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "beginne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beginnen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beginnst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beginnt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "begriff",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "begriffe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "begründung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "behalte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "behalten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "behandeln",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "behandelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "behörden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bei",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "bei regen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bei schlechtem wetter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bei schönem wetter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "beide",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "beiden",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "beim",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "bein",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "beine",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "beiße",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beißen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beispiel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "beispiele",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "beißt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beitrag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "beiträge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bekannt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "bekannten",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "bekanntere",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bekomme",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bekommen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bekommst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bekommt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beliebt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "benötige",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "benötigen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "benutze",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "benutzen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "benutzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "benzin",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bequem",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "beraten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beratung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "berechne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "berechnen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "berechnet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "berechnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "berechtigen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "berechtigt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bereich",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bereiche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bereichen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bereit",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "bereits",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "berg",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Berg",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "berge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bergen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bergsteigen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bericht",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "berichte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "berichten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "berichtet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "berlin",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "Berliner",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "berliner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bernstein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "beruf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "berufe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "beruflich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "berühmt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "berühmten",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "bescheid",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "beschreibung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "beschwert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "besetzt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "besichtigt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "besitzen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "besitzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "besonderen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "besonders",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "besser",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "bessere",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "besseren",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "bestanden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bestätige",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bestätigen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bestätigt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bestätigung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "beste",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "beste freundin",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bestehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "besteht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bestellen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bestellt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bestellung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bestellungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "besten",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "bester freund",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bestimmung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bestimmungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "besuch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "besuche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "besuchen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "besucher",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "besuchst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "besucht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bete",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "betest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "betet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "beton",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "betrag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "betreffen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "betreiber",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "betreuung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "betrieb",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "betriebe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "betrifft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bett",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bettdecke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "betten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bettlaken",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bevölkerung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bevor",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "bewegen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bewegt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bewegung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bewegungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bewerbung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bewerten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bewertest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bewertet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bewertung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bewertungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bewölkt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "beworben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bewusstsein",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bezahle",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bezahlen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bezahlst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bezahlt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bezahlung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bezeichnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "beziehung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "beziehungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bezirk",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bezirke",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bezug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bezüge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bibliothek",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bibliotheken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bieg",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "biege",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "biegt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "biene",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "biergarten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bierkrug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "biete",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bieten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bietest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bietet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bild",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bilden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bilder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bildern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bildet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bildschirm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bildung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "billig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "billiger",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "bin",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "biogurken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bioladen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "biologie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "birnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bis",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "bis bald",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bis morgen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bis später",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bis wann",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bis zum",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bis zum hotel",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bis zum nächsten mal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "bisher",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "bisschen",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "bist",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bitte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bitten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bittest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bittet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "blass",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "blatt",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "blau",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "blaue",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "blauen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "blaues",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "bleibe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bleiben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bleibt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bleistift",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "blick",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "blieb",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "blieben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "blitz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "blitze",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "blond",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "blume",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "blumen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "blumenladen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bluse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "blut",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "blute",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bluten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "blutest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "blutet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "böden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "boden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bohnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bonus",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "boot",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "boote",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bootsfahrt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "börse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "böse",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "branche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "branchen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brandenburger tor",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "brauche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "brauchen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "braucht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "braun",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "braune",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "braunen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "brav",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "breite",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brenne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "brennen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "brezel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brezeln",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brief",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "briefe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "briefkasten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "briefmarke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "briefmarken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brieftasche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "briefumschlag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "brille",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bring",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bringe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bringen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bringst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bringt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "brot",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "brötchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "brücke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brücken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brüder",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bruder",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "brüder",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "brüdern",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "brunnen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "brust",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brüste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "brustkorb",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "buch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "buche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "buchen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bücher",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bücherei",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bücherregal",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "buchladen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "buchst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "bucht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "buddhismus",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bühne",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bundesland",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bundesländer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Bundesländern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bundesliga",
    partOfSpeech: "Proper noun",
    gender: "Feminine",
  },
  {
    word: "bundesregierung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bundesrepublik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Bundestag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bunt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "bunte",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "buntes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "Burg",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bürger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "burger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bürgerbüro",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "bürgermeister",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "büro",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "büros",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Bürste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bus",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "bushaltestelle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bushaltestellen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "busse",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "butter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "bwl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "café",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Café central",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "cafés",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "campingplatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "campus",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "cent",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "cents",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "chance",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "chancen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "charakter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "charaktere",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "chefin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "chefinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "chefs",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "chemie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "chemisches",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "china",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "chinesisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "chor",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "christ",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "christen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "circa",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "clown",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "club",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "cola",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "comics",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "computer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Computerraum",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "cool",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "coolen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "cooler",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "coolsten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "couch",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "cousin",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "cousine",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "cousinen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "cousins",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Croissant",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "currywurst",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "da",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "da drüben",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dabei",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dach",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dächer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dachte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dachten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dachtest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dachtet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dadurch",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dafür",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dagegen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "daher",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "damals",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dame",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "damen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "damit",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "danach",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dänemark",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "danke",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "danke schön",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "danken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dankst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dankt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dann",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "daran",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "darauf",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "darf",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "darfst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "darm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "därme",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "darüber",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "darum",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "das",
    partOfSpeech: "Pronoun",
    gender: "Neuter",
  },
  {
    word: "das alte auto",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "das geht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "das macht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "das macht dann",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "das tut",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "dass",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "daten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "datum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dauer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dauert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "daumen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "davon",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dazu",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "decke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "decken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "definiere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "definieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "definierst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "definiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "definition",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "definitionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dein",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "deine",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "deinem",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "deinen",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "deiner",
    partOfSpeech: "Pronoun",
    gender: "Feminine",
  },
  {
    word: "deines",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "delfine",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dem",
    partOfSpeech: "",
    gender: "Masculine",
  },
  {
    word: "demnächst",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "Demokratie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "den",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "den ganzen tag",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "denen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "denke",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "denken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Denkmal",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "denkst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "denkt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "denn",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "dennoch",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "der",
    partOfSpeech: "",
    gender: "Masculine",
  },
  {
    word: "der welt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "deren",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "derzeit",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "des",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "deshalb",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "design",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dessen",
    partOfSpeech: "",
    gender: "Masculine",
  },
  {
    word: "desto",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "deswegen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "detail",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "detaillierte",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "details",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "deutlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "deutsch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "deutsche",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "deutschen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "deutscher",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "deutschland",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "deutschlands",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "Deutschlands",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "dezember",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Dialog",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dialog",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "diät",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dich",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "dick",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "dicke",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "dicken",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "die",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "die ganze nacht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "die ganze zeit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "die vereinigten staaten",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "dienen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dienstag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dienstleistung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dienstleistungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dies",
    partOfSpeech: "Pronoun",
    gender: "Neuter",
  },
  {
    word: "diese",
    partOfSpeech: "Pronoun",
    gender: "Feminine",
  },
  {
    word: "diesem",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "diesen",
    partOfSpeech: "",
    gender: "Masculine",
  },
  {
    word: "dieser",
    partOfSpeech: "Pronoun",
    gender: "Feminine",
  },
  {
    word: "dieses",
    partOfSpeech: "Pronoun",
    gender: "Neuter",
  },
  {
    word: "dieses jahr",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "dimension",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dimensionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ding",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dinge",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dinger",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dir",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "direkt",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dirigent",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dirigenten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dirigiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Diskussion",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "diskussion",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "diskussionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "diskutieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "diskutiert",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "doch",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "dokufilme",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dokument",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dokumentation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dokumente",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dollar",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dollars",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dom",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "döner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "donner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "donnerstag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "doppelzimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dorf",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dörfer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "dort",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dose",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dosen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "drache",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "drachen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dran",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "draußen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "drei",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "dreißig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "dreiundzwanzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "dreizehn",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "drin",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "drinnen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "dritte",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "dritten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "drogeriemarkt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "drüben",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "druck",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "drücke",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "drücken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "drucken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "drucker",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "drückt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "druckt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "du",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "dumm",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "dunkel",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "dunkles",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "dünn",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "durch",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "durchaus",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "durcheinander",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "durchschnitt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "durchstellen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "durchwahlnummer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "dürfen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "durst",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "dusche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "duschen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "duschgel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "duschst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "duscht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "dutzend",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "e-mail",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "e-mail-adresse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "e-mails",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ebene",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ebenfalls",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "ebenso",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "ec-karte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "echt",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "echte",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "echter",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "echtes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "ecke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ecken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "edelstahl",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "egal",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ehefrau",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ehemalige",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "ehemaligen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "ehemann",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eher",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "ehrenamtlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ehrlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ei",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "eichhörnchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Eidechse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "eigene",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "eigenen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "eigener",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "eigenes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "eigenschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eigenschaften",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eigentlich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "eilig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ein",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ein bisschen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "ein hemd",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ein kleid",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ein paar",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "ein sakko",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "einander",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "einchecken",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "eindeutig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "eindruck",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eine",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "eineinhalb",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "einem",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "einen",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "einen augenblick",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "einen schönen tag noch",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "einer",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "eines tages",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "einfach",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "einfache",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "einfachen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "einfacher",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "einfluss",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "einführung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eingang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eingebrochen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "eingekauft",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "eingeladen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "eingeloggt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "eingepackt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "eingezogen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "einhalten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "einheimische",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "einheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "einhorn",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "einhundert",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "einkaufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "einkaufswägen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "einkaufszentrum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "einkaufszettel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "einladung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "einladungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "einlösen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "einmal",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "Eins",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eins",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "einsatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "einschließlich",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "einstellung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "einstellungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eintrag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "einträge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Eintritt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eintritt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eintrittskarte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eintrittskarten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "einundzwanzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "einverstanden",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "einweihungsparty",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "einwohner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "einwohnern",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "einzahlen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "einzelhandel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "einzelheiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "einzelnen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "einzelzimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "einzige",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "Einzimmerwohnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eis",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "eisbären",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eisen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "eiskaffee",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eislaufe",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "eisläufst",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "eisläuft",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "elefant",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "elefanten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Elefanten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "elegant",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "elektroläden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "elektroladen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "element",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "elemente",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "elf",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "elften",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ellbogen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eltern",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "empfang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "empfänger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "empfehlen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "empfehlung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "empfehlungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "empfohlen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ende",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Ende",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "endet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "endlich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "energie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eng",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "engagement",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "engagements",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "englisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "englische",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "englischer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "enkel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "enkelin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "enkelinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "entdecken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "entdeckt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ente",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "enten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "entferne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "entfernen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "entfernt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "entfernung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "enthalten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "entlang",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "entscheidung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "entscheidungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "entschieden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "entschuldigen sie bitte",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "entschuldigung",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "entstehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "entsteht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "enttäuscht",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "entweder",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "entwickeln",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "entwickelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "entwickler",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "entwicklung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "entwurf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "er",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "erdbeere",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erdbeeren",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erdkunde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ereignisse",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ereignissen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "erfahren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erfährt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erfahrung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erfahrungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erfindung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erfindungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erfolg",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "erfunden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ergebnis",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ergebnisse",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ergibt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erhält",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erhalten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erhielt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erhielten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erhöhen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erhöhst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erhöht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erholt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erholung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Erinnerungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erkältung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erkannt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erkennen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erkennst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erkennt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erkläre",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erklären",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erklärt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erklärung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erlässt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erlaube",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erlauben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erlaubt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "erleben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erleichtert",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ermäßigung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ermitteln",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ermittelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ermöglichen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ermöglicht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ernährung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erneut",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ernst",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "eröffne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "eröffnen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "eröffnet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "eröffnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "erreichen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erreicht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erscheinen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erscheint",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ersetze",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ersetzen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ersetzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erste",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "ersten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "erstens",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "erstes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "erwachsene",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "erwähnt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erwarte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erwarten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erwartest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erwartet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erweitern",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erwerben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erwirbt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erzähle",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erzählt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "erziehung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "es",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "es geht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "es gibt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "es tut mir leid",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "es wäre",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "esse",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "essen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "essig",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "esst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "esszimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "etwa",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "etwas",
    partOfSpeech: "Pronoun",
    gender: "Neuter",
  },
  {
    word: "euch",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "euer",
    partOfSpeech: "Pronoun",
    gender: "Neuter",
  },
  {
    word: "eule",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eulen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "eure",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "eurem",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "euren",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "eurer",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "euro",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "europa",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "europäisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "europäische",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "euros",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "existiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "exotische",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "experiment",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "experten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "externe",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "extrem",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "fabrik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fabriken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fach",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fachbereich",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fahr",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fahre",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fähre",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fahren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fähren",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fahrer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fahrkarte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fahrkarten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fahrplan",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fahrrad",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fahrräder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fahrradfahren",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fahrradhelm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fahrschein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fährst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fahrt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fährt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fahrzeug",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fahrzeuge",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fair",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "fall",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fälle",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fallen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fällig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "falls",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "fallschirmspringen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fällt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "falsch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "familie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "familien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "familienstand",
    partOfSpeech: "",
    gender: "Masculine",
  },
  {
    word: "fan",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fand",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fanden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fandest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fangen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fängst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fans",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fantastisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "farbe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "farben",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "farbstifte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fassung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fast",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "faul",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "februar",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "federball",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "federn",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fehlen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fehler",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fehlt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "feierabend",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "feiere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "feiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "feiertag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "feinde",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "feld",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "felder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "feldern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fell",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fenster",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ferien",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "ferienhaus",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ferienhäuser",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ferkel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fern",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "Fernbedienung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fernsehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fernseher",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Fernsehserie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fernsehserien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ferse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fersen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fertig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "fest",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "festplatte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "festplatten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fettarme",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "feuer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "feuerwehr",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "feuerwerk",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "feuerzeug",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "feuerzeuge",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fieber",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "figur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "figuren",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "film",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "filme",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "filmen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "filmstar",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "finanzierung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "finde",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "finden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "findet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "finger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "firma",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fisch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fische",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fit",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "fitnessstudio",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fix und fertig",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "fläche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "flächen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "flamingos",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "flasche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "flaschen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fleck",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "flecken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Flecken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fleisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fliege",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Fliege",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fliegen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fliegst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fliegt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fließend",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "flohmarkt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "flöte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "flug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "flüge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fluggesellschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "flughafen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "flugnummer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "flugzeug",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "flugzeuge",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fluss",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "flüsse",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "folge",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "folgen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "folgst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "folgt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fordern",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fördern",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fordert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "förderung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "form",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "format",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "formular",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "formulare",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "forschung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fortsetzung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "foto",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fotoalbum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Fotoapparat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fotografie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fotos",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "frage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fragen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "frankreich",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "französisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "französische",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "frau",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "frauen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "frechen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "frei",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "freiburg",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "freien",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "freiheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "freitag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "freitags",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "Freiwillige",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "freizeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fressen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "freude",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "freund",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "freunde",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "freunden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "freundin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "freundinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "freundlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "freundlichen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "freut mich",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "frieden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "frikadellen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "frisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "frischem",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "frischen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "frischer",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "friseur",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "friseurin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "frist",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fristen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "froh",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "frosch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "froschkönig",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "früh",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "früher",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "frühling",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "frühstück",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "frühstücke",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "frühstücken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "frühstücksflocken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "frühstückst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "frühstückt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "fuchs",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fuhren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "führerschein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "führt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "führte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "führten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "füllung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "fünf",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "fünftausend",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "fünfte",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "fünfunddreißig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "fünfundvierzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "fünfundzwanzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "fünfzehn",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "fünfzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "funktion",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "funktionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "funktioniert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "für",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "fürs",
    partOfSpeech: "Preposition",
    gender: "Neuter",
  },
  {
    word: "fuß",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fußball",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "fußballspiel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fußballspielen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "fußballtraining",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "füße",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Futter",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gab",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gabel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gabeln",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "galaxie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "galerie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Galerie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gans",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gänse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ganz",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "ganz bestimmt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ganzen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "ganzen familie",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gar",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "gar nicht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "garage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "garantie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "garantieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "garantiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gärten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "garten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gas",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gassi",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gast",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gäste",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gearbeitet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geärgert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Gebäck",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gebäude",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gebaut",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gebe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geblieben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "geboren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gebot",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gebote",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gebracht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gebrauch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gebräuche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gebrochen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gebt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gebühr",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Geburt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "geburtsjahr",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geburtsort",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geburtstag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geburtstage",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geburtstagskarte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gecampt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gedanke",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gedanken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gedauert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gedichte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gefahr",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gefahren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefährlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gefährlicher",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gefallen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefällt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefangen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefasst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefeiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geflogen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefolgt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefreut",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefühl",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gefühle",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gefühlen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gefühlt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefunden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gefüttert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gegangen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gegeben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gegen",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "gegend",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gegensatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gegenüber",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "gegessen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gegner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gegrillt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gegrilltem",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "geh",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gehalten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gehasst",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gehe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geheimzahl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "geheiratet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gehen wir",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gehirn",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gehirnen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gehören",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gehörst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gehört",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gehst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geht's",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "geige",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "geist",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geister",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gejubelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gekauft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geklickt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gekocht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gekommen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gekostet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gekündigt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gelacht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gelassen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gelaufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gelb",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gelbe",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gelben",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gelber",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "geld",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geldautomat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geldautomaten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geldbeutel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geldstrafe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gelebt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gelegenheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gelernt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gelesen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gelogen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gemacht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gemalt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gemein",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gemeinde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gemeinden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gemeinsam",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gemeinsame",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gemocht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gemüse",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gemütlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "genau",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "genauso",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "genehmigung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "genervt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "genf",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "genfer see",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "genie",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "genommen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "genug",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "genügen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "genügt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geöffnet",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gepäck",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geplant",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geputzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gerade",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "geradeaus",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "gerät",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geräte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geräusch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geregnet",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gereist",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gerettet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gericht",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gern",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "gern geschehen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gerne",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "gesagt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gesalzene",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "gesammelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gesamte",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "gesamten",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "geschafft",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "geschäft",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geschäfte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geschäftlich",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "geschäftsfrau",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "geschäftsführer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geschäftsmann",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "geschehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geschenk",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geschenke",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geschichte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "geschichten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "geschickt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geschirr",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geschlafen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geschlecht",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geschlechter",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geschlossen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "geschmeckt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geschnarcht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geschneit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "geschossen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geschrieben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "geschummelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geschwindigkeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "geschwister",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "geschwistern",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "geschwommen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gesegelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gesehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Gesellschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gesessen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gesetz",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gesetze",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gesicht",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gesichter",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gespannt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gespenst",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gespielt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gespräch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gesprochen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gesprungen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gestern",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "gestohlen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gestresst",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gesucht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gesund",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gesundheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gesungen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gesurft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "getanzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "geteilt durch",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "getragen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "getränk",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "getränke",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "getränken",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "getrennt",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "getroffen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "getrunken",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gewalt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gewandert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gewaschen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gewicht",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gewinn",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gewinne",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gewinnt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gewitter",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gewöhnlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gewonnen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "geworfen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gewusst",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gezeigt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gibst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gibt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gibt es",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ging",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gingen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gipsverband",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "giraffe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Giraffen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "girokonto",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gitarre",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "glas",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gläser",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "glaube",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "glauben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "glaubt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gleich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gleiche",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "gleichen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gleichgewicht",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gleis",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "glück",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "glücklich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "glücksbringer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "glühwein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gold",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "goldene hochzeit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "golf",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Gorillas",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gott",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "götter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gottes",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "grad",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "grad celsius",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gramm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "grammatik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gras",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gratis",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "grau",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "grauen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Grenze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "griechisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "griechische",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "grillen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "grillparty",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "grimm",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "grippe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "groß",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "großartig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "großbritannien",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "große",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "größe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "große",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "großeltern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "großen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "größen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "großer",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "größer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "größere",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "größeren",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "größeres",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "großes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "großmutter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "größte",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "größten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "großvater",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "grün",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "grund",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gründe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "grundlage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "grundlagen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "grundschule",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "grundschulen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "grundstück",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "grundstücke",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gründung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "grüne",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Grünen",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "grünen",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "grünes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "gruppe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gruppen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "grüß dich",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "grüße",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "gummibärchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "günstige",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gurke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gurken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "gurkensalat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gürtel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gut",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "gut gemacht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gute",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "gute besserung",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gute nacht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "gute reise",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "guten",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "guten abend",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "guten appetit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "guten morgen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "guten rutsch ins neue Jahr",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "guten tag",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "guter",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "gutes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "gutschein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gutscheine",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "gymnasien",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "gymnasium",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Haar",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "haar",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "haarbürste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "haare",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Haarreif",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "habe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "haben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "habt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hähnchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "haie",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Haifisch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "halb",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "halbbruder",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "halbbrüder",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "halbschwester",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "halbschwestern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Halbzeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hälfte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "halle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hallen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hallo",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "hallo zusammen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "hals",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hälse",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hält",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "haltbar",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "halte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "halten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Haltestelle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "haltestellen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hamburg",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "hamster",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hand",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "handball",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "handbuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hände",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "handel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "handelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "handgelenk",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "handgelenke",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "handle",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "händler",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "handlung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "handschuhe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "handtasche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "handtaschen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "handtuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "handtücher",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "handwerk",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "handy",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "handynummer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "handys",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hängen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hängt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hans",
    partOfSpeech: "Proper noun",
    gender: "Masculine",
  },
  {
    word: "harmlos",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "hart",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "hassen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hässlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "hasst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hast",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hat",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hätte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hatte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hätte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hatten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hätten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hattest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hättest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hättet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hattet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "häufig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Hauptbahnhof",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hauptfach",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hauptgericht",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hauptstadt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hauptstädte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "haus",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hausarbeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hausaufgaben",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "häuser",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hauses",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hausgemachtem",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "haushalt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hausnummer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "haustier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "haustiere",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "haut",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "heft",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "heilig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "heilige",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "heimat",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Heimtrainer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "heimweh",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "heiß",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "heiße",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "heißen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "heißt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "heizung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "helfe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "helfen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "helft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hell",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "hemd",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hemden",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "her",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "heraus",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "herausfinden",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "herausgefunden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "herbst",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "herd",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "herein",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "herkunft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "herr",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "herren",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "herrlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "herrn",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hersteller",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "herum",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "herunter",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "heruntergeladen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "hervorragend",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "herz",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "herzen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "herzlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "herzlich willkommen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "herzlichen glückwunsch",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "heute",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "heute abend",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "heute morgen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "heute nachmittag",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "heute nacht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "heutigen",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "hexe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hexen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hey",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "hier",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "hierher",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "hiermit",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "hierzu",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "hilf",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hilfe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hilfreich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "hilfst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hilft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "himmel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hin",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "hin- und rückfahrt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hinaus",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "hinausgehen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "hinduismus",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hingegen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "hinlegen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "hinten",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "hinter",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "hintergrund",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hinweis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hinweise",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hinzu",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "hip-hop",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hitze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hobby",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hobbys",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hoch",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "hochschule",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hochschulen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "höchste",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "hochzeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hockey",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hof",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "höfe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hoffe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hoffen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hoffentlich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "hoffnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hohe",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "höhe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hohen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "hoher",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "höher",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "höhere",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "höheren",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "hole",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "holländisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "holländischen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "holst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "holz",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "honig",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "höre",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hören",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "horn",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hörner",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "horrorfilm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hörst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Hort",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hört",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "hose",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hosen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hosentasche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hotel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hotels",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "hubschrauber",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hüfte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hüften",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hügel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "huhn",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hühner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "humboldt-universität",
    partOfSpeech: "Proper noun",
    gender: "Feminine",
  },
  {
    word: "humor",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hund",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "hunde",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hunden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hundert",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "hundertdreiundzwanzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "hunger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "hungrig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "hurra",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "hut",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "hüte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ice",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ich",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "ich freue mich",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ich kann nicht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "idee",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ideen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ihm",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "ihn",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "ihnen",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "ihr",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "ihre",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "ihrem",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "ihren",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "ihrer",
    partOfSpeech: "Pronoun",
    gender: "Feminine",
  },
  {
    word: "ihres",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "im",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "im angebot",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "im ausland",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "im bett",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "im jahr",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "im koffer",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "im monat",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "im park",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "im schrank",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "immer",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "immer noch",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in deiner pause",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in dem stadtviertel",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in den ferien",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in der nähe",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in der nähe von",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in der woche",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in der wohnung",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in die bar",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in eile",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in einem team",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in einer stunde",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in einer wg",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in meiner pause",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in ordnung",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "in rente",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "indien",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "indische",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "individuell",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "individuelle",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "industrie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "industrien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "informatik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "information",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "informationen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "inhaber",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "inhalt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "inhalte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "inhalten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "inklusive",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "innen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "innenstadt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "innenstädte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "innere",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ins",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "ins internet",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ins möbelhaus",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "insbesondere",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "insekt",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "insekten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "insel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "inseln",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "installieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "installiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "institut",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "institute",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "instrument",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "instrumente",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "intelligent",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "interessant",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "interessante",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "interesse",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "interessen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "interessiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "international",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "internationale",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "internationalen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "internet",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "internetseiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "internetverbindung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "interview",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Interview",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "interviews",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "inzwischen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "irgendwann",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "irgendwas",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "irgendwer",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "irgendwie",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "irgendwo",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "islam",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "island",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "iss",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "isst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ist",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "italien",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "italienisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "italienische",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "italienischem",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "ja",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "jacke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "jacken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "jahr",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "jahre",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "jahren",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "jahrestag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "jahreszeiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "jahrhundert",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "jahrhunderte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "jährlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "januar",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "japan",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "jazz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "je",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "jeans",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "jede",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "jedem",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "jeden",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "jedenfalls",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "jeder",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "jederzeit",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "jedes",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "jedes mal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "jedoch",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "jemals",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "jemand",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "jemandem",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "jemanden",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "jetzt",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "jetzt geht's los",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "job",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "jobinterview",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "jobs",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "jogge",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "joggen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "joggst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "joggt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "joghurt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "jude",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "juden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "jugendherberge",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "jugendliche",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "jugendlichen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "juli",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "julia",
    partOfSpeech: "Proper noun",
    gender: "Feminine",
  },
  {
    word: "jung",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "junge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "jungen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "jünger",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "jüngeren",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "jüngsten",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "jüngstes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "juni",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kabel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "käfer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kaffee",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Kaffeehaus",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kaffeemaschine",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kaffeetisch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "käfig",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kakao",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kalender",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kalt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kaltem",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "kälter",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kam",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kamel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kamele",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kamelen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kamen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kamera",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kameras",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kampf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kanada",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "kanadier",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kanadierin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Kanal",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kaninchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kann",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kannst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kantine",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kapitel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Kapitel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kaputt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kaputtgegangen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "Karibik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "karl",
    partOfSpeech: "Proper noun",
    gender: "Masculine",
  },
  {
    word: "karneval",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "karnevalsparty",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "karnevalsumzug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "karotte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "karotten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "karriere",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "karte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "karten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kartoffel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kartoffeln",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kartoffelsalat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "karton",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Karton",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Kartons",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "käse",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "käsebrot",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "käsespätzle",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "kasse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kassen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kassenbon",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kassenzettel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "katalog",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kataloge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kategorie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kategorien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "katze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "katzen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kauf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "käufe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kaufe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kaufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "käufer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kaufhaus",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kauft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kaum",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "kein",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "kein problem",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "keine",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "keine ahnung",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "keinen",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "keinen hunger",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "keiner",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "keinerlei",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "kekse",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "keller",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kellner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kellnerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kenne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kennen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kennengelernt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "kennenlernen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kennenzulernen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "kennst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kennt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kenntnis",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kenntnisse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kette",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kilo",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kilogramm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kilometer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kind",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kinder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kinderbuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kindergarten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kindergärten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kindern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kinderstuhl",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kinderzimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kindes",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kinn",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kino",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kinokarten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kiosk",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kirche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kirchen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "klar",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "klasse",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "klassen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "klassenarbeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "klassentreffen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "klassische musik",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "klavier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kleid",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kleider",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kleidung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "klein",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kleinanzeige",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kleinanzeigen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kleine",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "kleinen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "kleiner",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kleines",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "kleingeld",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "klima",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "klimaanlage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "klingt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "klinik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Kloß",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "klug",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "klüger",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "knapp",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Kneipe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kneipe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kneipen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "knie",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "knoblauch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "knöchel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "knochen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "knödel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "knopf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "knöpfe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Koalition",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "koch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kochbuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "köche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "koche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kochen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "köchin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "köchinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kochlöffel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kochst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kocht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "koffer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kofferausgabe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Kofferraum",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kohl",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kollege",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kollegen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kollegin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kombination",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "komisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "komischen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "komischer",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "komm",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "komme",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kommen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kommentar",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kommentare",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kommode",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kommst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kommt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kommunikation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "komödie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kompetenz",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "komplett",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "komplette",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "komponist",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "konditorei",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "könig",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "könige",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "königs",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "können",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "könnt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "konnte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "könnte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "konnten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "könnten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "konntest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "könntest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "konntet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "könntet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "konsole",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kontakt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Kontakt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kontaktieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kontaktiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "konten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kontinent",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "konto",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kontonummer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kontrolle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Kontrollen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "konzept",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "konzepte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "konzert",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "konzerte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "konzerten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kooperation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kopf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "köpfe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kopfhörer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kopfkissen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kopfschmerzen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "körper",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "korrupt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kosmetik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kosten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kostenlos",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kostenlose",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "kostenlosen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "kostet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "kostüme",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kraft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "krank",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kranken",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "krankenhaus",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "krankenhäuser",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "krankenpfleger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "krankenschwester",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "krankenversicherung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "krankenwagen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "krankenwägen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "krankheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "krankheiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "krawatte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kreditkarte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kreditkarten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kreis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kreuzfahrt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kreuzung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "krieg",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "krimis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kriterien",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kritik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "küche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kuchen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Küchen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "küchensachen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "küchenwaage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kugel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kugelschreiber",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kuh",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kühe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kühl",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kühlschrank",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kühlschränke",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kultur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kunde",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kunden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kundenservice",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kundinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "kunst",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "künste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "künstler",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kunstmuseum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kunststoff",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kupfer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "kurs",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kurse",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "kurz",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "kürzeren",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "Kuss",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "küste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "l",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "labor",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lache",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lachen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lachs",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lacht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lade",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ladegerät",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ladegeräte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "läden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "laden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lädt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lag",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lagen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lager",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lampe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lampen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "land",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "länder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "landkarte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "landschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lang",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "lange",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "länge",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lange nicht gesehen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "langen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "länger",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "langer tag",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "langsam",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "langsamer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "langweilig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "laptop",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "las",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lasen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lass",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lass uns",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "lassen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lasst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lässt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lauf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "laufe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "laufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "läufst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "läuft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "laut",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "lauten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "lauter",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "lautsprecher",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lebe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "leben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lebens",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lebenslauf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lebensmittel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "leber",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leberwurst",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lebst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lebt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lecker",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "leckerer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "leder",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lederjacke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "leg",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lege",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "legen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "legt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lehrbuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lehrbücher",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lehre",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lehrer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lehrerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lehrerinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leicht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "leichter",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "leid",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "leider",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "leine",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leise",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "leiser",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "leisten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "leistet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "leistung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leistungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leiter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leitern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leitung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "leitungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Leoparden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lerne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lernen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lernst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lernt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lese",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lesen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "leser",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "letzte",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "letzten",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "letzter",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "letztes",
    partOfSpeech: "",
    gender: "Neuter",
  },
  {
    word: "letztes mal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "leuchtturm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "leute",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "leuten",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "licht",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lichter",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lieb",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "liebe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lieben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lieber",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "liebling",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lieblings",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "lieblingsband",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lieblingsessen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lieblingslied",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lieblingsrestaurant",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "lieblingsteam",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lieblingstier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "liebst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "liebt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lied",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "lieder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "liefern",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lieferservice",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "liefert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lieferung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "liegst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "liegt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ließ",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lies",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ließen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "liest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "liga",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lila",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "limonade",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lineal",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "linguistik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "linie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "linien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "link",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "linken",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "links",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "lippe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lissabon",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "liste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "liter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "literatur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Literatur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "loch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "löffel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "logistik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lokal",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "los",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "lösche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "löschst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "löscht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lösen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "lösung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lösungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "löwe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Löwen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "löwen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "luft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "luftballons",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "lunge",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lust",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "lustig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "lustige",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "lustigen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "lustiger",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "luxemburg",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "mach",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mach's gut",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "mache",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "machen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "machst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "macht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "macht's gut",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "machte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "machten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mädchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mag",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "magen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mägen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Magen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "magie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "magisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "magische",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "magischen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "magst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Mahlzeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mai",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mal",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "male",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "malen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mallorca",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "malst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "malt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mama",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mamas",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "man",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "management",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "manche",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "manchmal",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "mango",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mann",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "männer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mannes",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mannschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mannschaften",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mantel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mäntel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "marathon",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "märchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "marke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "markiere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "markieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "markierst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "markiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "markt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Markt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "märkte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "marktplatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "marmelade",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "märz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Marzipan",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "maß",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "maschine",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "maschinen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "maschinenbau",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "maße",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Maske",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "masken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "material",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "materialien",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Mathe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mathematik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "matratze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mauer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mauern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "maultaschen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "maus",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mäuse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mäusen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mayo",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "medien",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "medikament",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "medikamente",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "meditiere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "meditieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "meditierst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "meditiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "medium",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "medizin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "meer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "meerschweinchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mehl",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mehr",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "mehrbettzimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mehrfamilienhaus",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Mehrheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mehrwertsteuer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "meile",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "meilen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mein",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "meine",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "meinem",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "meinen",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "meiner",
    partOfSpeech: "Pronoun",
    gender: "Feminine",
  },
  {
    word: "meines",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "meinst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "meint",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "meinung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "meinungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "meist",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "meiste",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "meisten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "meistens",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "meister",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "melde",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "melden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "meldest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "meldet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "meldung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "meldungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "melodie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "menge",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mensa",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mensch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "menschen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "merke",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "merken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "merkmale",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "merkt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "messe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "messen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "messer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "metall",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "meter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "methode",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "methoden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "metzger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "metzgerei",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mich",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "miete",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mieten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mietest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mietet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mietwagen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mietwägen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mikrowelle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "milch",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "milchreis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "milliarde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "milliarden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "million",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Millionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "millionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mindestens",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "mineralwasser",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ministerium",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ministeriums",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "minus",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "minute",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "minuten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "minze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mir",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "mir geht's gut",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "misst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mit",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "mitarbeiter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mitarbeiterin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mitarbeiterinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mitbewohner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mitbewohnerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mitbewohnern",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mitgebracht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mitgespielt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mitglied",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mitglieder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mitgliedern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mitgliedschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mitgliedschaften",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mitkommen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mitleid",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mitnehmen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "mitspielen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mittag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Mittag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mittagessen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Mittagsmenü",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mittagszeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mitte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mitteilung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mitteilungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mittel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mittelmeer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mitteln",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mitternacht",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mittlerweile",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "mittwoch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mitzukommen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "mitzunehmen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "möbel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "möbelhaus",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "möchte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "möchten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "möchtest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "möchtet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mode",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Mode",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "modell",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "modelle",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "modern",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "modernen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "mögen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "möglich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "möglichen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "möglichkeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "möglichkeiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mögt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "möhren",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "moment",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "monat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "monate",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "monaten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "monatlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "mönch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mönche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mond",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "monitor",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "monitore",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "montag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "moped",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "morgen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "morgens",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "moschee",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "moscheen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "motiviert",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "motor",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "motorrad",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "motorräder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "motorroller",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "müde",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "müll",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Müllbeutel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "mülleimer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "münchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "mund",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "münder",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "münze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "münzen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "museen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "museum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "musik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "musiker",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "musikinstrumente",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "muskel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "muskeln",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "müsli",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "muslim",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "muslime",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "muss",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "müssen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "musste",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mussten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "musstest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "musstet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "mutter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mütter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "muttersprache",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "mütze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nach",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nach dem signalton",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nach der arbeit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nach der schule",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nach hause",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nachbar",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nachbarin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nachbarn",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nachdem",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "nachher",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "nachmittag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nachmittags",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nachname",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nachricht",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nachrichten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nachsehen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nächste",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "nächsten",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "nächster",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "nächstes",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "nächstes mal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nacht",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nächte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nachtisch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nachts",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "nachweis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nacken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nähe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nahm",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "nahmen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "naja",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "name",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "namen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "namibia",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "nase",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nasen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nass",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "nationalpark",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "natur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "natürlich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "nebel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "neben",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "nebenan",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "nebenfach",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "neblig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "neffe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "neffen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nehme",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "nehmen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "nehmt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "nein",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "nennen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "nennt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "nervös",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "nett",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "nette",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "netten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "netter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nettes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "netz",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "netze",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "neu",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "neue",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "neue kleidung",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "neue wohnung",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "neuen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "neuen film",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "neuen grill",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "neuen job",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "neuen pass",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "neuer",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "neues",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "neuesten",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "neulich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "neun",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "neunundneunzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "neunzehn",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "neunzehnhundertachtzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "neunzehnten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "neunzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "neuschwanstein",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "neuseeland",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "nicht",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nicht einmal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nicht wahr",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nichte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nichten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nichts",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "nickerchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "nie",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "niemals",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "niemand",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "niemandem",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "niemanden",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "nimm",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "nimmt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "nirgends",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "niveau",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "noch",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "noch einmal",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "noch nie",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "nochmals",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "nordamerika",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "norden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nordpol",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "normal",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "normalen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "normalerweise",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "not",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "notaufnahme",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "note",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "noten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "notfall",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "notfälle",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "notiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "notiz",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "notizbuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "notwendig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "notwendigen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "november",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nudelholz",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "nudeln",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "null",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "nummer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nummern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nun",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "nur",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "nuss",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nussallergie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nüsse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "nutzen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nutzer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "nützlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "nutzung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ob",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "Obdachlosen",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "oben",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "oberfläche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "oberschenkel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "obst",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "obstsalat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "obwohl",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "oder",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "ofen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "offen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "offene",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "offenen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "öffentliche",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "öffentlichen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "öffentliches",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "öffentlichkeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "öffne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "öffnen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "öffnet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "öffnungszeiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "oft",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "öfter",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "oh",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "ohne",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "ohr",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ohren",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ohrringe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ohrringen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ok",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "oktober",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "oktoberfest",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "öl",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "olé",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "oma",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "omelett",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "onkel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "online",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "opa",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "oper",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "operation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "opern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "opfer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "opfern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "optimist",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "option",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "optionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "orange",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "orangen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "orangensaft",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "orchester",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ordentlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "ordner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ordnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "organ",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "organe",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "organisation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Organisation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "organisationen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "organisiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "original",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ort",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "orte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "osten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ostern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "österreich",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "ostsee",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "paar",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "paare",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "packung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "packungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "paket",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "pakete",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "panda",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "panik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "papa",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "papagei",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "papageien",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "papas",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "papier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "papiere",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "pappe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "parfüm",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "park",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "parkplatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "parks",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Partei",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "partei",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "parteien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Parteien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "parties",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "partner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "partnerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "partnerschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "partnerschaften",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "party",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "partys",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "pass",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pass auf",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "pässe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "passen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "passende",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "passenden",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "passfoto",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "passieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "passiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "passt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "pasteten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "patient",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "patienten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pause",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "pausen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Pausenbrot",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "pension",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "per",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "perfekt",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "person",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "personal",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "personen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "persönlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "persönliche",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "persönlichen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "pessimist",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "pfanne",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "pfeffer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pferd",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "pferde",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "pflanze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "pflanzen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "pflaster",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Pflaumen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "phase",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "phasen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "philosophie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "physik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "picknick",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "pils",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "pilz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pilze",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pinguin",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pinguine",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pink",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "piraten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pizza",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "pizzas",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "plan",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pläne",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "planen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "planeten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "planst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "planung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "plastik",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "platz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "plätze",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "plötzlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "plus",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "podcasts",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "polen",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "Polen",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "politik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "politiker",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "politikerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "politischen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "polizei",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "polnisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "pommes",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "popcorn",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "popmusik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "position",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "positiv",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "post",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "poster",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "postkarte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "postkarten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "postleitzahl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "postleitzahlen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Prag",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "praktika",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "praktikant",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "praktikanten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "praktikantin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "praktikum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "praktisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Präsentation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "präsentiere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "präsentieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "präsentiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "präsident",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "präsidenten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "praxis",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "preis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "preise",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "presse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "priester",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "prima",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "prinz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "prinzen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "prinzessin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "prinzip",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "prinzipien",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "privat",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "private",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "privaten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "pro",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "probiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "problem",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "probleme",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "produkt",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "produkte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "produkten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "produktion",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "professor",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "professoren",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "professorin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "professorinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "profil",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "profile",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "programm",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "programme",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "projekt",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "projekte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "proseminar",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "prospekt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "prost",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "protestieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "protestiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "prozent",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "prozente",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "prozess",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "prozesse",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "prozesses",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "prüfen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "prüft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "prüfung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "prüfungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "pst",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "psychologie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "publikum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Pudel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pullover",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pullovern",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Punkt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "punkt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "punkte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "pünktlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Puppe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "putzen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "puzzle",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "puzzles",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "pyramide",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "pyramiden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "quadratmeter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "qualifikation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "qualität",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "quartal",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "quartale",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "quatsch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "quelle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "quellen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Quiz",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "radiergummi",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "radio",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "radios",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "radtour",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rahmen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rasen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rasierer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Rastplatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rathaus",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ratschlag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ratte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ratten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "raum",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "räume",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "raumschiff",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "raus",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "realistische",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "rechnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rechnungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "recht",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "rechte",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "rechts",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "rechtsanwälte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rechtschreibung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rechtzeitig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "rede",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "reden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "redest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "redet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "reduziert",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "referat",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "referenzen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "regal",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "regale",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "regel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "regelmäßig",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "regen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "regenbogen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "regenmantel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "regenschirm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "regie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "regierung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Regierung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "regierungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Region",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "region",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "regional",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "regionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "regnet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "reich",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "reiche",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "reicher",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "Reichstagsgebäude",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "reicht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "reifen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "reifenpanne",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "reihe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rein",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "reinigung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "reinkommen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "reis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "reise",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "reiseadapter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "reisebüro",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "reiseführer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "reisen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Reisetasche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Reisetaschen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "reisezentrum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "reitest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "reitet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "relativ",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "religion",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "religionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "renne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rennen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rennst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rennt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "reparatur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "reparaturen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Reportage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "republik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "reserviert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rest",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "restaurant",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "restaurants",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "retten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rettet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rezept",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "rezeption",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rhein",
    partOfSpeech: "Proper noun",
    gender: "Masculine",
  },
  {
    word: "richter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "richterinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "richtig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "richtige",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "richtung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "riesenrad",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "riesig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "riesige",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "riesigen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Rinderbraten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rindfleisch",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ring",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ringe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "risiken",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "risiko",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "roboter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rock",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "röcke",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rockkonzert",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "rolle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rollschuhe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rollstuhl",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rollstühle",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rolltreppe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "roman",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "romantisch",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Röntgenbild",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "rosa",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "rosen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rot",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "rote",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "roten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "roter",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "rotes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "rotwein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "router",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rubrik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rubriken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "rücken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rucksack",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "rucksäcke",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ruf",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rufe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ruft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "rugby",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "rügen",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "ruhe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ruhig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "rund",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "runde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Runde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Runden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "runtergefallen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "russisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "russischen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "russland",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "s",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "s-bahn",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "saal",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sache",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sachen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "saft",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sag",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sag bescheid",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "sage",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sagen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sagst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sagt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sagte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sagten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sagtest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sah",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sahen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sahst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "saht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "saison",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sakko",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "salami",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "salat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "salate",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "salz",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "salzig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sammeln",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sammelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sammle",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sammlung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sammlungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "samstag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "samstagabend",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "samstage",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "samstags",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "sandalen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sandwich",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sandwiches",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sänger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sängerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "satt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "satz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sauber",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sauer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sauerkraut",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "säugetier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "säugetiere",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sauna",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schach",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schade",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "schaden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Schaden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schäden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schaden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schaf",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schafe",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schäferhund",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schaffen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schafft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schal",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Schal",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schalter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "scharf",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "scharfe",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "scharfen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "scharfer",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "schatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schau",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schaue",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schauen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schauspieler",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schauspielerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schaut",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "scheck",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Scheibe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "scheint",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schenken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schenkt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schere",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "scheren",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schick",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schicke",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schicken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schicksal",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schickst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schiff",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schiffe",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schild",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schildkröte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schildkröten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Schildkröten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schinken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schlafanzug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schlafe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schlafen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schläfst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schläft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schlafzimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schlagsahne",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schlagzeug",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schlange",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "schlangen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schlau",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schlecht",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schlechte",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schlechter",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schlief",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schliefen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schließen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schließlich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "schließt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schlimm",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schloss",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Schloss",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schloss schönbrunn",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schlossen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schlösser",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schluss",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schlüssel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schmeckt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schmuck",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schmutzig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schmutzigen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schnecke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schnee",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schneemann",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schneidebrett",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schneider",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schneit",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schnell",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "schnellen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "schneller",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schnelles",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "schnitzel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schokolade",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schön",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schon",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "schon immer",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "schöne",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "schönen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schöner",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schönsten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schrank",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schränke",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schränken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schrecklich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schreibblock",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schreibe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schreiben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schreibst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schreibt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schreibtisch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schrieb",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schrieben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schrift",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schritt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schritte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schublade",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schuh",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schuhe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schuhen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Schulabschluss",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Schuld",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Schulden",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "schuldig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schule",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Schule",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schulen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schüler",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schülerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schülerinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Schulranzen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schulter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schultern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schürze",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schüssel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schüsseln",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schutz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schützen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schützt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwach",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schwager",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schwägerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schwamm",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwammen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwammst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwammt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwanger",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schwarz",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schwarze",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schwarzen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "schwarzes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "schwarzfahren",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "schwarzwald",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schweden",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "schwedisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schwein",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schweine",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Schweinebraten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schweinefleisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schweiz",
    partOfSpeech: "Proper noun",
    gender: "Feminine",
  },
  {
    word: "schweizer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "schweizer franken",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "schwer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schwester",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schwestern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Schwiegereltern",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "schwierig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "schwimmbad",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schwimmbecken",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "schwimme",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwimmen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwimmsachen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "schwimmst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwimmt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "schwindelig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sechs",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "Sechs",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sechste",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "sechsunddreißig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "sechzehn",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "sechzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "see",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "seele",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "seelen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sehe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sehenswürdigkeiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sehr",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "seht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sei",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "seid",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Seide",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "seien",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "seife",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sein",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "seine",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "seinem",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "seinen",
    partOfSpeech: "Pronoun",
    gender: "Masculine",
  },
  {
    word: "seiner",
    partOfSpeech: "Pronoun",
    gender: "Feminine",
  },
  {
    word: "seit",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "seitdem",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "seite",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "seiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sekt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sekunde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sekunden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "selber",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "selbst",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "selbstständig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "selbstverständlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "selten",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "seltsam",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "semester",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "semesterende",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "seminar",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "seminare",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sende",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "senden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sender",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sendest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sendung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sendungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "senf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "senior",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "senioren",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "september",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "serie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "serien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "service",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Serviette",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sessel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "setzen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "setzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "shampoo",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "shorts",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "sich",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "sicher",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sichere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sicherheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sichern",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sichert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sicht",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sichtbar",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sie",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "sieben",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "siebenunddreißig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "siebte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "siebten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "siebzehn",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "siebzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "sieg",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sieger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "siehst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sieht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "silber",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "silvester",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Silvesterparty",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "simkarte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sind",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "singe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "singen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "singst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "singt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sinn",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sinne",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sinnvoll",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "situation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sitz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sitzplatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sitzplätze",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sitzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "skateboard",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "skeptisch",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "ski",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "smartphone",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sms",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "snowboard",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "so",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "so ein Pech",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "sobald",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "socke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "socken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sodass",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "soeben",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "sofa",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sofas",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sofort",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "software",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sogar",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "sohn",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "söhne",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "solange",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "soll",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sollen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sollst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sollte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sollten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "solltest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "solltet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sommer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sommerjob",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sonderangebot",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sonderangebote",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "sondern",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "Song",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sonne",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sonnenbrand",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sonnenbrille",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sonnencreme",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sonnig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sonntag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sonntage",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sonntags",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "sonst",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "sonst noch etwas",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "sorgen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sorry",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "sorte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "soße",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "soßen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "soweit",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "sowohl",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "soziale",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "sozialen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "soziales",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "spa",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Spaghetti",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "spalte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "spanien",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "spanisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "spargel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "spargelzeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "spaß",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "spät",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "später",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "spätestens",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "spazieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spazieren gehen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "spaziergang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "spaziert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spazierte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spazierten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Speck",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "speisekarte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "speisewagen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "spenden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "spezialität",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "spezialitäten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "speziellen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "spiegel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Spiel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "spiel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "spiele",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spielen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spieler",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "spielplatz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "spielst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spielt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spielzeug",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "spielzeuge",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "spinat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "spinne",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "spinnen",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "spiritualität",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "spitze",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sport",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sporthalle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sportlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "sportverein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sprach",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Sprache",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sprache",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sprachen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sprachst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spreche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sprechen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sprecht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sprichst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spricht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "springe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "springen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "springt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "spülbecken",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "spülmaschine",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "staat",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "staatsangehörigkeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stadion",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "stadt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "städte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stadtführung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stadtmuseum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "stadtplan",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stadtpläne",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stadtviertel",
    partOfSpeech: "",
    gender: "Neuter",
  },
  {
    word: "stadtzentrum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "staffel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stahl",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stall",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Stand",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stand",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "standard",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "standards",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ständig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "standort",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "standorte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stark",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "starke",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "stärken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "starken",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "stärker",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "start",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "starte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "starten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "startest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "startet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "station",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "statistik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "statistiken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "statt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "stattdessen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "stau",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "steckdose",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "steckdosen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stehe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stehlt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stehst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "steht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "steif",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "steigen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "steigt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "steine",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stelle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stellen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stellenangebot",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "stellenangebote",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "stellt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stellte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stellten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stern",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sterne",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "steuer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "steuern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "sticht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stiefel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stiefeln",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "stiefmutter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stiefvater",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stiehlt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stift",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stifte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stiften",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stil",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stimme",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stimmen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Stimmen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stimmt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "stimmung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stipendium",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "stirn",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stock",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Stoff",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stollen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stolz",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "stoppschild",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "stornieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "strahlung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "strand",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "strände",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "straße",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "straßen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "straßenbahn",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "strategie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "strecke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "strecken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "streifen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "streit",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "streng",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "stress",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stressig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "strom",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "struktur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "strukturen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Strümpfe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stück",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "student",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "studenten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "studentenausweis",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "studentenwohnheim",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "studentin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "studentinnen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "studie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "studien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "studierst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "studiert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "studio",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "studium",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "stufe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stuhl",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stühle",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "stunde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Stunde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stunden",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "stundenplan",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sturm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "sturms",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "suche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "suchen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "sucht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "südafrika",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "südamerika",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "süden",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "südpol",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "summe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "summen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "super",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "supermarkt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "supermärkte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "suppe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "suppen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "surfbrett",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "surfe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "surfst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "surft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "süß",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "süßer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "süßes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "süßigkeiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "symbol",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "synagogen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "syrien",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "system",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "systeme",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "t-shirt",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "t-shirts",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "tabelle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tabellen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tablette",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Tafel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tag der deutschen einheit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "tage",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tagebuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "tagen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "täglich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "tank",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Tankstelle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tante",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tanten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tanz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tanze",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tanzen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tänzer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tanzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tapfer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "tasche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "taschen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Taschen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "taschengeld",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "taschenrechner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tasse",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tassen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tastatur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tastaturen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "taste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tätigkeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tätigkeiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Tatsache",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tatsächlich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "tauben",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tausend",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "taxi",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "taxifahrt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "taxistand",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Team",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "team",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "teams",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "technik",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "techniken",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "technischen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "technoparty",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tee",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Teekanne",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "teig",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "teil",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "teile",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "teilen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "teilnahme",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "teilnehmen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "teilnehmer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "teilnehmern",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "teilt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "teilzeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "telefon",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "telefone",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "telefonieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "telefonnummer",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "telefonnummern",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "teller",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tempel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "temperatur",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "temperaturen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tennis",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "teppich",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "teppiche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "termin",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "termine",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "test",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "teste",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "testen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "testest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "testet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tests",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "teuer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "teuren",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "teurer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "text",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "texte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "theater",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "thema",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "themen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "theoretische",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "theorie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "therapie",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Tickets",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "tief",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "tiefe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "tierarzt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tierärztin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tiere",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "tieren",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "tiergarten",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tierheim",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "tierwelt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tiger",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Tipp",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Tipps",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tisch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tische",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "titel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "toast",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "toaster",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "töchter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tochter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tod",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Tofu",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "toilette",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "toiletten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "toll",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "tomate",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tomaten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ton",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "topf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tor",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "torte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "total",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "tour",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "touren",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tourismus",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "touristen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tradition",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "traditionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "trage",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tragen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trägst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trägt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tragt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trainer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "training",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "trampolin",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "trank",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tranken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trankst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trankt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trauben",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "traum",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "traumberuf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "träume",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "träumen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "träumt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "traurig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "treffe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "treffen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trefft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "treppe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "treppen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "trete",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "treten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "triffst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trifft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Trikots",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "trink",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trinke",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trinken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trinkgeld",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "trinkst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trinkt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tritt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trittst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "trocken",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "trockne",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "trockner",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "trommel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "trotzdem",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "tschüss",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "Tuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "tun",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tür",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "türen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "türke",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "türkei",
    partOfSpeech: "Proper noun",
    gender: "Feminine",
  },
  {
    word: "türken",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "türkisch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Turm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "turnschuhe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "tut",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "tüte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "tüten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "typ",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "u-bahn",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "u-bahnstation",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "über",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "überall",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "überblick",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "übergeben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überhaupt",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "überlege",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überlegst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "übermorgen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "übernehme",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "übernimmst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "übernimmt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überprüfe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überprüfen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überprüfst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überprüft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überrascht",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "überraschung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "übers",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "überschrift",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "überschwemmt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Überschwemmung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "übersetzung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "übersetzungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "übersicht",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "überweise",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überweist",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "überzeugt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "übrigen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "übrigens",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "übung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "übungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ufo",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "uhr",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "uhren",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "uhrzeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "um",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "um die ecke",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "um zu",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "umfang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "umfrage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "umfragen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "umgang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "umgebung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "umkleidekabinen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ums",
    partOfSpeech: "Preposition",
    gender: "Neuter",
  },
  {
    word: "umsetzung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "umtauschen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "umwelt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "umweltschutz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "umziehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "umzug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "unabhängig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unbedingt",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "unbekannt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "und",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "unentschieden",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unerträglich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unfall",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "unfälle",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "ungarischer",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "ungarn",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "ungefähr",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "ungeheuer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "unglaublich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unglücklich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unheimlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unhöflich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "uni",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "uniform",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "unis",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "universität",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "universität münchen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "universität wien",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "unmöglich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unordentlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "uns",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "unser",
    partOfSpeech: "Pronoun",
    gender: "Neuter",
  },
  {
    word: "unsere",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "unserem",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "unseren",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "unserer",
    partOfSpeech: "Pronoun",
    gender: "Feminine",
  },
  {
    word: "unsichtbar",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unsinn",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "unten",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "unter",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "unterhaltung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "unterkunft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "unternehmen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "unterricht",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "unterschied",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "unterschiede",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "unterschiedliche",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "unterschreiben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "unterschrift",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "unterstütze",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "unterstützen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "unterstützt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "unterstützung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "untersuchung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "untersuchungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "unterwäsche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "unterwegs",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "unvorbereitet",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "urenkel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "urgroßmütter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "urgroßmutter",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "urlaub",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "urteil",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "vase",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vater",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "väter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vatertag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vegan",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "vegetarier",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "venedig",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "ventilator",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verabredet",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verändere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verändern",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "veränderst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verändert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "veranstaltung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "veranstaltungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verantwortlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verantwortung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verärgert",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verband",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verbände",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verbessert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verbesserung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verbindung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verbindungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verboten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verbraucher",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verdiene",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verdienen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verdienst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verdient",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Verein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vereinbarung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vereine",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verfahren",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "verfasser",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verfügbar",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verfügbaren",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verfügen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verfügt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergangenen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "vergangenheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vergebe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergeben",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergesse",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergessen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergibst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergibt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergiss",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergisst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergleich",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vergleichen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergleichst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vergleicht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verhalten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Verhältnis",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "verhältnis",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "verhandele",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verhandeln",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verhandelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verheiratet",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verhindere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verhindern",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verkauf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verkaufe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verkaufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verkäufer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verkaufst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verkauft",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verkehr",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verkehrsmittel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "verkleidet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verlag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verlage",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verlasse",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verlassen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verlässt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verlauf",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verletzt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verliebt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verlor",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verlust",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vermeide",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vermeiden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vermeidet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vermitteln",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vermittelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vermittlung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "veröffentliche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "veröffentlichen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "veröffentlicht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verpasse",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verpassen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verpasst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verpflichtet",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verpflichtungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verreist",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "verrückt",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verrücktes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "verschiedene",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verschiedenen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "verschwinde",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verschwinden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "versichert",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "versicherung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "version",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "versionen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "versorgung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verspätet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verspätung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "versprochen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verstanden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verständnis",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "Verstecken",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "verstehe",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verstehen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verstehst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "versteht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "versuch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "versuche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "versuchen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "versuchst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "versucht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vertrag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Vertrag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verträge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vertreten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vertretet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vertritt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verursacht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verwählt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verwaltung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verwandelt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "verwandte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "verwandten",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "verwendung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "verzeichnis",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "verzeihung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "video",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "videos",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "videospiele",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "viel",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "viel Glück",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "viel spaß",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "viel zu tun",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "viele",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "vielen",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "vielen dank",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "vielfalt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vielleicht",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "vier",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "viereck",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "vierte",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "viertel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "viertelstunde",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vierten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "vierundzwanzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "vierzehn",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "vierzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "virus",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "visa",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "visum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "vogel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vögel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vogel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vögeln",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vokabeln",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "volk",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "voll",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "volleyball",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "völlig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "vollzeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vom",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "vom bahnhof",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "von",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "vor",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "vor allem",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "vor Kurzem",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "voraus",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "vorbei",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "vorbereiten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "vorbereitung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vorbereitungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vorgestellt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "vorgestern",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "vorhang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vorhänge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vorher",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "vorlesung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vorname",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vornamen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vorne",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "vorschlag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vorschläge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vorschlagen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "vorschriften",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vorsichtig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "vorspeise",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vorstellen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "vorstellung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Vorstellungsgespräch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "vorteil",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vorteile",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vortrag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "vorwahl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "vorzeigen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wach",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wahl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Wahl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wählen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wahlen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wählt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wahr",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "während",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "wahrheit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wahrscheinlich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wald",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wälder",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wale",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wand",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wände",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wandere",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wandern",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wanderst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wandert",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wann",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "war",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wäre",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "ware",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "waren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wären",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wäret",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "warm",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wärmer",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "Warschau",
    partOfSpeech: "Proper noun",
    gender: "",
  },
  {
    word: "warst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "warte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "warten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wartest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wartet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wartezimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "warum",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "was",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "was für",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "was gibt's neues",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wäsche",
    partOfSpeech: "Noun",
    gender: "",
  },
  {
    word: "wasche",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "waschen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "waschmaschine",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wäschst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wäscht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wasser",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wasserflasche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wasserkocher",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wassermelone",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "website",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wechsel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wechseln",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wechselt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wechsle",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wecker",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "weder",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "weg",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wegbeschreibung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wege",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wegen",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "weh",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "weich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "weihnachten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "weil",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "wein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "weine",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "weiß",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "weißbier",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "weiße",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "weise",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "weißen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "weißer",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "weißes",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "weißt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "weißwein",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "weit",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "weit weg",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "weiterbildung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "weitere",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "weiterer",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "weiteres",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "weiterhin",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "welche",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "welche farbe",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "welchem",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "welchen",
    partOfSpeech: "Determiner",
    gender: "Masculine",
  },
  {
    word: "welcher",
    partOfSpeech: "Determiner",
    gender: "Feminine",
  },
  {
    word: "welches",
    partOfSpeech: "Determiner",
    gender: "Neuter",
  },
  {
    word: "welle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wellen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "welt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "weltraum",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wem",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "wen",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "wenden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wenig",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wenige",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "wenigen",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "weniger",
    partOfSpeech: "Determiner",
    gender: "",
  },
  {
    word: "weniger als",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wenigstens",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wenn",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "wer",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "werbung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "werde",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "werdet",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "werkstatt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "werkstätten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "werktag",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "werktage",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "werkzeug",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "werkzeuge",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wert",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "werte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wertung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wertvoll",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wespe",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wespen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wessen",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "westen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wettbewerb",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wettbewerbe",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wette",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wetter",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wettervorhersage",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wichtig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wichtige",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wichtigen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wichtiger",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wichtiger tag",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wichtigsten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wie",
    partOfSpeech: "Conjunction",
    gender: "",
  },
  {
    word: "wie bitte",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie geht's",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie ist es",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie lange",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie läuft's bei",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie oft",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie viel",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie viel uhr",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie viele",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wie wäre es mit",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wieder",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wieder da",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wiedergeburt",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wiederholen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wiederzusehen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wien",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "wiese",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wiesn-herz",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wieso",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "will",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "willkommen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wind",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "windig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "winter",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "winterstiefel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wir",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "wird",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wirken",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wirklich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wirklichkeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wirkst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wirkt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wirkung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wirst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wirtschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wissen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wissenschaft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wissenschaftlerin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wisst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "witz",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "witze",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "witzig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "witziger",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "wlan",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wo",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "woche",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wochen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wochenende",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wochenenden",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wochenmarkt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wöchentlich",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wofür",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "woher",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wohin",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wohl",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wohne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wohnen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wohnst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wohnt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wohnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wohnungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "Wohnungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wohnzimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wolke",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wolle",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wollen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wollt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wollte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wolltest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "womit",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "wonach",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "woran",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "woraus",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wort",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "worten",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wörter",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wörterbuch",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wörterbücher",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wörterbüchern",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wortes",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "worüber",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "worum",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wovon",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "wow",
    partOfSpeech: "Interjection",
    gender: "",
  },
  {
    word: "wunder",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "wunderbar",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wunderschön",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "wunderschöne",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "wunsch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Wunsch",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Wünsche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wünsche",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wünschen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wünscht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wünschte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "würde",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wurde",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "würde",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "würden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wurden",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wurm",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "wurst",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "würstchen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "würste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wusste",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wusstest",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "wüste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "wüsten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "yoga",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "yogamatte",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zahl",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zahlen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zahlreiche",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "zählt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zahlt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zahlung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zahlungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zahn",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zahnarzt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zahnärztin",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zahnbürste",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zahnbürsten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zähne",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zahnschmerzen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zahnspange",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zaun",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zäune",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zebra",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zebras",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zeh",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zehen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zehn",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zehnten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "zeichen",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zeichentrickfilme",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zeichne",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zeichnung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zeige",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zeigen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zeigst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zeigt",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zeigte",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zeigten",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zeiten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zeitmaschine",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zeitpunkt",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zeitpunkte",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zeitraum",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zeitreise",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zeitschrift",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zeitschriften",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zeitung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zeitungen",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zelt",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zentrale",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zentren",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zentrum",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zerbrochen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "Zettel",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zeug",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zeugnis",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ziege",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "ziel",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ziele",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "ziemlich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zig",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "zigarette",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zigaretten",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zimmer",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zinsen",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zirkus",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zitieren",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zitierst",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zitrone",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zoll",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zoo",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zoos",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zu",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zu abend",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zu fuß",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zu hause",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zu mittag",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zu weihnachten",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zubehör",
    partOfSpeech: "Noun",
    gender: "Neuter",
  },
  {
    word: "zucker",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zuerst",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zufälliger",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "zufrieden",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "zug",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zugang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "Zugang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "züge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zugehört",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zugemacht",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zugleich",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zugtür",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zukunft",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zuletzt",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zum",
    partOfSpeech: "",
    gender: "Neuter",
  },
  {
    word: "zum abendessen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum arzt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum beispiel",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum flughafen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum friseur",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum frühstück",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum halben preis",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum mittagessen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum oktoberfest",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum schalter",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum strand",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zum zahnarzt",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zumachen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zumindest",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zur",
    partOfSpeech: "Pronoun",
    gender: "",
  },
  {
    word: "zur bank",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zur botschaft",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zur friseurin",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zur party",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zur polizistin",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zürich",
    partOfSpeech: "Proper noun",
    gender: "Neuter",
  },
  {
    word: "zurück",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zurückbekommen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zurückgeben",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zurückgerufen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zurückkommen",
    partOfSpeech: "",
    gender: "",
  },
  {
    word: "zurzeit",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zusammen",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zusammenarbeit",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zusammenfassung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zusammenhang",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zusätzliche",
    partOfSpeech: "Adjective",
    gender: "Feminine",
  },
  {
    word: "zusätzlichen",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "zuschauer",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zustand",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zuständig",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "zuständigen",
    partOfSpeech: "Adjective",
    gender: "Masculine",
  },
  {
    word: "zustimmen",
    partOfSpeech: "Verb",
    gender: "",
  },
  {
    word: "zustimmung",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zuvor",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zwanzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zweck",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zwei",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zweihundert",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zweimal",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zweitausend",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zweite",
    partOfSpeech: "Adjective",
    gender: "Neuter",
  },
  {
    word: "zweiten",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "zweitens",
    partOfSpeech: "Adverb",
    gender: "",
  },
  {
    word: "zweiundachtzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zweiunddreißig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zweiundvierzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zweiundzwanzig",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zwiebel",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zwiebeln",
    partOfSpeech: "Noun",
    gender: "Feminine",
  },
  {
    word: "zwilling",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zwillinge",
    partOfSpeech: "Noun",
    gender: "Masculine",
  },
  {
    word: "zwischen",
    partOfSpeech: "Preposition",
    gender: "",
  },
  {
    word: "zwölf",
    partOfSpeech: "Numeral",
    gender: "",
  },
  {
    word: "zwölften",
    partOfSpeech: "Adjective",
    gender: "",
  },
  {
    word: "zypern",
    partOfSpeech: "Noun",
    gender: "",
  },
];

export default wordList;