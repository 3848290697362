import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from './context/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import CookieConsent from "react-cookie-consent";
import { AppMetaDataProvider } from "./context/AppMetaDataContext";
// import { init as initFullStory } from "@fullstory/browser";

// if (process.env.NODE_ENV === "production") {
//   initFullStory({ orgId: "o-1TWHS6-na1" });
// }


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <AppMetaDataProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </AppMetaDataProvider>
    <CookieConsent
      // debug={true}
      location="bottom"
      buttonText="I understand"
      cookieName="acceptedCookie"
      style={{ background: "black" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  </HelmetProvider>
);