import React from "react";
import { Button } from "react-bootstrap";

function VocabularyHeading(props) {
  const { language } = props;
  return (
    <>
      <h1
        className="text-center my-5"
        style={{ marginBottom: "4%", marginTop: "5%" }}
      >
        Duolingo {language} Vocabulary List
      </h1>

      <p className="text-center mb-4">
        This is a list of all the {language} vocabulary in Duolingo.
      </p>
      {/* <p className="text-center mb-4" style={{ textWrap: "balance" }}>
        Use <a href="/">Duolingo Ninja</a> to get the {language} words that{" "}
        <em>you</em> specifically have learned with Duolingo along with
        translations, word infinitive and other info.
      </p> */}
      {/* create button */}
      {/* <div style={{ display: "flex", justifyContent: "center" }} class="mb-4">
        <Button variant="primary" href="/">
          Get the Duolingo vocabulary <em>you</em> learned
        </Button>
      </div> */}
    </>
  );
}

export default VocabularyHeading;
