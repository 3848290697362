import React from "react";
import { Container, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { LinkContainer } from "react-router-bootstrap";
import AppMetaDataContext from "../context/AppMetaDataContext";

import HowItWorks from "../Components/HowItWorks";
import Customize from "../Components/Customize";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>{metaData.metaTitle}</title>
        <meta
          name="description"
          key="description"
          content={metaData.metaDesc}
        />
        <meta property="og:title" key="og:title" content={metaData.metaDesc} />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDesc}
        />
      </Helmet>

      <Container style={{ padding: "3% 3%" }}>
        <h1 className="text-center" style={{ marginBottom: "7%" }}>
          Thank you for installing {metaData.appName}!
        </h1>

        <HowItWorks />
        <Customize />

        <hr />

        <div className="text-center" style={{ margin: "0% 3%" }}>
          <h1 className="text-center" style={{ paddingTop: "15%" }}>
            Upgrade to paid version
          </h1>
          <h4
            className="text-center"
            style={{ padding: "2% 0%", fontWeight: "normal" }}
          >
            Increase the translation limits and keep learning after the free
            trial runs out.
          </h4>
          <LinkContainer to="/register">
            <Button size="lg" variant="success">
              Sign up
            </Button>
          </LinkContainer>
        </div>

        <br />
        <br />
        <br />
        <br />
      </Container>
    </>
  );
}

export default Home;
