import React from "react";
import {
  Card,
  CardGroup,
} from "react-bootstrap";

import AppMetaDataContext from "../context/AppMetaDataContext";

function Customize() {
  const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
        <h1
          className="text-center"
          style={{ marginBottom: "7%", marginTop: "10%" }}
        >
          Customize your learning experience
        </h1>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>Translate words or sentences</Card.Title>
              <Card.Text>
                Choose the language you want to translate from. It doesn't have
                to be the same as in Duolingo. Translate words or sentences and
                choose how much to translate.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>
                Ignore the domains you don't want to translate
              </Card.Title>
              <Card.Text>
                Some domains just shouldn't be translated. For example, domains
                related to your work. You can add them to the ignore list with
                one click.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>Use tooltips to see the original text</Card.Title>
              <Card.Text>
                Hover the translation to see the tooltips with the original
                meaning of the word.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>
                Don't translate &#60;code blocks&gt;, input fields, or links
              </Card.Title>
              <Card.Text>
                Code blocks and input fields aren't translated. And you can
                optionally also skip translating links.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

      </div>
    );
}

export default Customize;
