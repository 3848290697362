import React, { Component } from "react";

class CookieDetection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cookiesEnabled: navigator.cookieEnabled,
    };
  }

  render() {
    const { cookiesEnabled } = this.state;

    return (
      <div>
        {cookiesEnabled ? (
          <p>Cookies are enabled in the browser.</p>
        ) : (
          <p>
            Cookies are not enabled in the browser. Please enable cookies to use
            this website.
          </p>
        )}
        {/* Your other React component content */}
      </div>
    );
  }
}

export default CookieDetection;
