import React from "react";
import {
  Container
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../../context/AppMetaDataContext";

import socialImage from "../../duolingo-ninja-social-sharing.png";

import LearnDuolingoVocabularyMainCTA from "../../Components/LearnDuolingoVocabularyMainCTA";
import HowItWorks from "../../Components/HowItWorks";
import Benefits from "../../Components/Benefits";
import Customize from "../../Components/Customize";
import FounderQuote from "../../Components/FounderQuote";
import DownloadExtension from "../../Components/DownloadExtension";


function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>
          Comprehensible Input in Language Learning - {metaData.appName}
        </title>
        <meta
          name="description"
          key="description"
          content="Comprehensible input is the most important factor in language learning. Learn where the term came from and how to get more comprehensible input in your target language."
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <h1 className="mt-4">
          Understanding Comprehensible Input in Language Learning
        </h1>

        <div className="mt-4">
          <p>
            Comprehensible input is a powerful approach to language acquisition.
            If you're looking to supercharge your language learning journey,
            you've come to the right place.
          </p>
        </div>

        <div className="mt-4">
          <h2>What is Comprehensible Input?</h2>
          <p>
            Comprehensible input is a groundbreaking concept in language
            acquisition. Coined by linguist{" "}
            <a href="https://www.sdkrashen.com/" target="_blank">
              Stephen Krashen
            </a>
            , it revolves around the idea that language learners make the most
            progress when they are exposed to language that is slightly beyond
            their current proficiency level but still comprehensible. In other
            words, it's about providing learners with content that challenges
            them just enough to encourage growth without overwhelming them.
          </p>
        </div>

        <div className="mt-4">
          <h2>How Comprehensible Input Works</h2>
          <p>
            Here's a closer look at how comprehensible input can transform your
            language learning experience:
          </p>
          <ul>
            <li>
              <strong>Engagement</strong>: Comprehensible input engages learners
              in meaningful communication. Whether through reading, listening,
              or conversation, learners are exposed to language in context,
              making it relevant and relatable.
            </li>
            <li>
              <strong>Reduced Anxiety</strong>: One of the key benefits of this
              approach is that it minimizes anxiety. When learners can
              understand most of the language they encounter, they feel more at
              ease and confident in their ability to learn.
            </li>
            <li>
              <strong>Natural Learning</strong>: Comprehensible input mimics the
              way we acquire our first language as children—through immersion
              and understanding. It encourages learning in a natural, intuitive
              way.
            </li>
            <li>
              <strong>Efficiency</strong>: This approach maximizes language
              acquisition efficiency. Learners focus on understanding rather
              than memorization, resulting in faster progress.
            </li>
          </ul>
        </div>

        <div className="mt-4">
          <h2>Examples of Comprehensible Input</h2>
          <p>Comprehensible input can take various forms, including:</p>
          <ul>
            <li>
              <strong>Storytelling:</strong> Reading or listening to stories in
              the target language, with appropriate levels of complexity, is an
              effective way to engage learners.
            </li>
            <li>
              <strong>Contextual Conversations:</strong> Engaging in
              conversations where the language is comprehensible in a real-life
              context helps reinforce learning.
            </li>
            <li>
              <strong>Media and Audio:</strong> Watching movies, TV shows, or
              listening to podcasts in the target language can provide valuable
              exposure to comprehensible input.
            </li>
          </ul>
        </div>

        <div className="mt-4">
          <h2>Why Choose Comprehensible Input?</h2>
          <p>
            There are compelling reasons to embrace comprehensible input in your
            language learning journey:
          </p>
          <ul>
            <li>
              <strong>Effective Learning:</strong> It's a research-backed method
              that consistently produces results.
            </li>
            <li>
              <strong>Confidence Building:</strong> Learners gain confidence as
              they understand and use language in meaningful ways.
            </li>
            <li>
              <strong>Minimized Frustration:</strong> Reduced frustration leads
              to more enjoyable and sustainable learning.
            </li>
            <li>
              <strong>Natural Progression:</strong> It mirrors the way we
              naturally learn languages.
            </li>
          </ul>
        </div>

        <div className="mt-4">
          <h2 className="mt-4">Language Acquisition vs. Language Learning</h2>
          <p>
            Comprehensible input is described as part of the language
            acquisition method. This method has many differences with the more
            traditional language learning method. While both methods aim to
            bridge the linguistic gap, they differ in fundamental ways.
          </p>
          <p>
            This table provides a concise comparison of these two approaches,
            shedding light on their unique characteristics, advantages, and
            considerations.
          </p>
          <table
            className="table table-bordered mt-4"
            style={{ maxWidth: "80%" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Language Acquisition</th>
                <th>Language Learning</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Definition</td>
                <td>
                  The process of naturally absorbing a language through
                  immersion and exposure, typically from birth or extensive
                  exposure in a foreign environment.
                </td>
                <td>
                  The intentional and conscious effort to acquire a new language
                  through study, instruction, and practice.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Method</td>
                <td>
                  Learning occurs primarily through exposure to language in
                  meaningful contexts, such as conversations and daily life.
                </td>
                <td>
                  Learning is typically guided by structured lessons, textbooks,
                  and language courses.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Processing in the brain</td>
                <td>
                  Mostly subconscious through exposure to language.
                </td>
                <td>
                  Mostly conscious through memorization and explicit learning.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Fluency</td>
                <td>
                  Can lead to native-like fluency and intuitive understanding of
                  the language.
                </td>
                <td>
                  Fluency may vary and may not reach native-like proficiency.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Focus</td>
                <td>
                  Focuses on understanding and communication rather than
                  explicit grammar rules.
                </td>
                <td>
                  Often involves explicit learning of grammar rules and
                  vocabulary.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Errors</td>
                <td>
                  Errors are common but are seen as a natural part of the
                  learning process.
                </td>
                <td>
                  Errors may be corrected and emphasized as part of language
                  instruction.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Anxiety</td>
                <td>
                  Generally lower anxiety levels, as learning is natural and
                  occurs in context.
                </td>
                <td>
                  Higher anxiety levels may be associated with language
                  learning, especially in formal settings.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Time</td>
                <td>
                  Timeframe varies, but it can take several years to become
                  proficient.
                </td>
                <td>
                  Can be more time-efficient for achieving basic proficiency,
                  but mastery may take longer.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Cultural Understanding</td>
                <td>
                  Often includes a deep understanding of the culture associated
                  with the language.
                </td>
                <td>
                  Cultural understanding may be limited to what is taught in
                  language classes.
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Environment</td>
                <td>
                  Can occur in any environment where the language is spoken or
                  written, including immersion environments.
                </td>
                <td>
                  Takes place in settings that provide structure, such as
                  classrooms and language schools.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-4 mb-5">
          <h2>Start Your Language Journey with Comprehensible Input</h2>
          <p>
            Now that you understand the power of comprehensible input, it's time
            to take the next step in your language learning journey. Incorporate
            this approach into your daily routine, and you'll be amazed at the
            progress you can make.
          </p>
          <p>
            Remember, consistency is key. Whether you're reading, listening, or
            engaging in conversations, make sure the language is just
            challenging enough to stimulate growth while remaining
            comprehensible. With comprehensible input, fluency is within reach.
          </p>
        </div>
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <Benefits />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
