const wordList = [
    {
        "word": "가",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ga",
        "translation": "go, ga (sound), is"
    },
    {
        "word": "가게",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gage",
        "translation": "store"
    },
    {
        "word": "가격",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gagyeog",
        "translation": "prices, price"
    },
    {
        "word": "가구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gagu",
        "translation": "furniture"
    },
    {
        "word": "가까이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaggai",
        "translation": "near"
    },
    {
        "word": "가끔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaggeum",
        "translation": "sometimes"
    },
    {
        "word": "가는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ganeun",
        "translation": "who is going, going, along which u2026 goes"
    },
    {
        "word": "가람",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "garam",
        "translation": "Garam (name)"
    },
    {
        "word": "가르치는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gareucineun",
        "translation": "teach"
    },
    {
        "word": "가르칠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gareucil",
        "translation": "teaching"
    },
    {
        "word": "가면",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gamyeon",
        "translation": "go, mask"
    },
    {
        "word": "가뭄",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gamum",
        "translation": "drought"
    },
    {
        "word": "가방",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gabang",
        "translation": "bags, bag"
    },
    {
        "word": "가벼운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gabyeoun",
        "translation": "light"
    },
    {
        "word": "가사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gasa",
        "translation": "lyrics"
    },
    {
        "word": "가수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gasu",
        "translation": "singer, the singer"
    },
    {
        "word": "가스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaseu",
        "translation": "gas"
    },
    {
        "word": "가슴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaseum",
        "translation": "heart, chest"
    },
    {
        "word": "가운데",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaunde",
        "translation": "center"
    },
    {
        "word": "가위",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gawi",
        "translation": "scissors"
    },
    {
        "word": "가을",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaeul",
        "translation": "autumn, fall"
    },
    {
        "word": "가장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gajang",
        "translation": "most, the most, best"
    },
    {
        "word": "가족",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gajog",
        "translation": "family"
    },
    {
        "word": "가치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaci",
        "translation": "value"
    },
    {
        "word": "각각",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaggag",
        "translation": "each"
    },
    {
        "word": "각자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gagja",
        "translation": "each"
    },
    {
        "word": "간",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gan",
        "translation": "who ... went, who has gone, having gone"
    },
    {
        "word": "간장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ganjang",
        "translation": "soy sauce"
    },
    {
        "word": "간호사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ganhosa",
        "translation": "nurse"
    },
    {
        "word": "갈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gal",
        "translation": "go, will go, appear"
    },
    {
        "word": "갈비",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "galbi",
        "translation": "galbi"
    },
    {
        "word": "감각",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gamgag",
        "translation": "Feeling, Sense"
    },
    {
        "word": "감기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gamgi",
        "translation": "cold"
    },
    {
        "word": "감독",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gamdog",
        "translation": "director, manager"
    },
    {
        "word": "감습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gamseubnida",
        "translation": ""
    },
    {
        "word": "감자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gamja",
        "translation": "potatoes, potato"
    },
    {
        "word": "감정",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gamjeong",
        "translation": "emotion, feeling"
    },
    {
        "word": "갑자기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gabjagi",
        "translation": "suddenly"
    },
    {
        "word": "강",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gang",
        "translation": "river, rivers"
    },
    {
        "word": "강당",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gangdang",
        "translation": "auditorium"
    },
    {
        "word": "강아지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gangaji",
        "translation": "puppy, a puppy"
    },
    {
        "word": "같이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gati",
        "translation": "together"
    },
    {
        "word": "개",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gae",
        "translation": "gae (sound), a dog, dog"
    },
    {
        "word": "개구리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaeguri",
        "translation": "frogs, frog"
    },
    {
        "word": "개막식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaemagsig",
        "translation": "opening ceremony"
    },
    {
        "word": "개미",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaemi",
        "translation": "ant"
    },
    {
        "word": "개인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaein",
        "translation": "individuals, individual"
    },
    {
        "word": "개최",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gaecoe",
        "translation": "hosting"
    },
    {
        "word": "거",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geo",
        "translation": ""
    },
    {
        "word": "거기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geogi",
        "translation": "there"
    },
    {
        "word": "거리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geori",
        "translation": "distance, street, road"
    },
    {
        "word": "거미",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geomi",
        "translation": "a spider, spider, spiders"
    },
    {
        "word": "거북이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geobugi",
        "translation": "turtle"
    },
    {
        "word": "거실",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geosil",
        "translation": "living room"
    },
    {
        "word": "거울",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geoul",
        "translation": "mirror"
    },
    {
        "word": "거의",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geoyi",
        "translation": "almost, approximately"
    },
    {
        "word": "거짓말",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geojismal",
        "translation": "lie"
    },
    {
        "word": "건",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geon",
        "translation": "the thing, thing"
    },
    {
        "word": "건강",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geongang",
        "translation": "health"
    },
    {
        "word": "건너편",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geonneopyeon",
        "translation": "across from"
    },
    {
        "word": "건물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geonmul",
        "translation": "building, buildings, the building, structure, property, block"
    },
    {
        "word": "건배",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geonbae",
        "translation": "cheers (when toasting)"
    },
    {
        "word": "건조",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geonjo",
        "translation": ""
    },
    {
        "word": "건축가",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geoncugga",
        "translation": "architect"
    },
    {
        "word": "걷는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geodneun",
        "translation": "walking, who walks"
    },
    {
        "word": "걸",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geol",
        "translation": "thing, to see, one"
    },
    {
        "word": "걸을",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geoleul",
        "translation": "walk"
    },
    {
        "word": "검사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geomsa",
        "translation": "examination, check"
    },
    {
        "word": "검은",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geomeun",
        "translation": "black"
    },
    {
        "word": "검정",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geomjeong",
        "translation": "black"
    },
    {
        "word": "것",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geos",
        "translation": "thing"
    },
    {
        "word": "게",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ge",
        "translation": "crabs, thing, ge (sound), crab, one"
    },
    {
        "word": "게으른",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geeureun",
        "translation": "lazy"
    },
    {
        "word": "겨울",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeoul",
        "translation": "winter"
    },
    {
        "word": "결코",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeolko",
        "translation": "never"
    },
    {
        "word": "경기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeonggi",
        "translation": "game, games, match"
    },
    {
        "word": "경기장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeonggijang",
        "translation": "Stadium"
    },
    {
        "word": "경력",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeongryeog",
        "translation": "career, record"
    },
    {
        "word": "경제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeongje",
        "translation": "economy"
    },
    {
        "word": "경주",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeongju",
        "translation": "race, gyeongju"
    },
    {
        "word": "경찰",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeongcal",
        "translation": "police"
    },
    {
        "word": "경찰서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeongcalseo",
        "translation": "police station"
    },
    {
        "word": "계곡",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyegog",
        "translation": "valley"
    },
    {
        "word": "계란",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyeran",
        "translation": "eggs, egg"
    },
    {
        "word": "계산대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyesandae",
        "translation": "checkout"
    },
    {
        "word": "계산서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyesanseo",
        "translation": "the check"
    },
    {
        "word": "계절",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyejeol",
        "translation": "season"
    },
    {
        "word": "계획",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyehoeg",
        "translation": "ideas, idea, plan"
    },
    {
        "word": "고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "go",
        "translation": "go (sound)"
    },
    {
        "word": "고구마",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goguma",
        "translation": "sweet potato"
    },
    {
        "word": "고기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gogi",
        "translation": "meat"
    },
    {
        "word": "고등학교",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "godeunghaggyo",
        "translation": "high school"
    },
    {
        "word": "고등학생",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "godeunghagsaeng",
        "translation": "high school student"
    },
    {
        "word": "고래",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gorae",
        "translation": "whale"
    },
    {
        "word": "고마워",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gomaweo",
        "translation": "thank you, thanks"
    },
    {
        "word": "고맙습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gomabseubnida",
        "translation": "thank you, thank"
    },
    {
        "word": "고슴도치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goseumdoci",
        "translation": "hedgehog"
    },
    {
        "word": "고양이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goyangi",
        "translation": "cats, a cat, cat"
    },
    {
        "word": "고용인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goyongin",
        "translation": "employee"
    },
    {
        "word": "고용주",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goyongju",
        "translation": "employer"
    },
    {
        "word": "고원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goweon",
        "translation": "plateau"
    },
    {
        "word": "고장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gojang",
        "translation": "broken"
    },
    {
        "word": "고집",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gojib",
        "translation": ""
    },
    {
        "word": "고추",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gocu",
        "translation": "chili pepper, chili"
    },
    {
        "word": "고층",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goceung",
        "translation": "high-rise"
    },
    {
        "word": "고통",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gotong",
        "translation": "agony, pain"
    },
    {
        "word": "고향",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gohyang",
        "translation": "Hometown"
    },
    {
        "word": "곧",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "god",
        "translation": "soon"
    },
    {
        "word": "골",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gol",
        "translation": "goal"
    },
    {
        "word": "골짜기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goljjagi",
        "translation": "valley"
    },
    {
        "word": "곰",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gom",
        "translation": "bear"
    },
    {
        "word": "곳",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gos",
        "translation": "the place, place"
    },
    {
        "word": "공",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gong",
        "translation": "zero, ball, a ball"
    },
    {
        "word": "공격수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gonggyeogsu",
        "translation": "Forward, Attacker, Striker"
    },
    {
        "word": "공부",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gongbu",
        "translation": "study"
    },
    {
        "word": "공원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gongweon",
        "translation": "park"
    },
    {
        "word": "공항",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gonghang",
        "translation": "airport"
    },
    {
        "word": "과",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gwa",
        "translation": "and, section, with"
    },
    {
        "word": "과일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gwail",
        "translation": "fruit"
    },
    {
        "word": "과자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gwaja",
        "translation": "snack, cracker, snacks"
    },
    {
        "word": "과학",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gwahag",
        "translation": "science"
    },
    {
        "word": "관",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gwan",
        "translation": ""
    },
    {
        "word": "관한",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gwanhan",
        "translation": "about"
    },
    {
        "word": "괴물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goemul",
        "translation": "monster"
    },
    {
        "word": "굉장히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "goengjanghi",
        "translation": "exceedingly, extremely, greatly"
    },
    {
        "word": "교수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyosu",
        "translation": "professor"
    },
    {
        "word": "교시",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyosi",
        "translation": "period"
    },
    {
        "word": "교실",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyosil",
        "translation": "classroom"
    },
    {
        "word": "교외",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyooe",
        "translation": "suburbs"
    },
    {
        "word": "교장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyojang",
        "translation": ""
    },
    {
        "word": "교통",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyotong",
        "translation": "transportation"
    },
    {
        "word": "교환",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyohwan",
        "translation": "exchanged, exchange"
    },
    {
        "word": "구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gu",
        "translation": "nine"
    },
    {
        "word": "구급",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gugeub",
        "translation": "rescue, first-aid, relief"
    },
    {
        "word": "구급차",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gugeubca",
        "translation": "ambulance"
    },
    {
        "word": "구두",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gudu",
        "translation": "dress shoes"
    },
    {
        "word": "구름",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gureum",
        "translation": "cloud"
    },
    {
        "word": "국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gug",
        "translation": "soup"
    },
    {
        "word": "국가",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gugga",
        "translation": "country, a country, nation"
    },
    {
        "word": "국경",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "guggyeong",
        "translation": "border"
    },
    {
        "word": "국자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gugja",
        "translation": "ladle"
    },
    {
        "word": "국제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gugje",
        "translation": "international"
    },
    {
        "word": "군인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gunin",
        "translation": "soldier"
    },
    {
        "word": "궁수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gungsu",
        "translation": "archer"
    },
    {
        "word": "권",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gweon",
        "translation": "some, some books"
    },
    {
        "word": "권투",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gweontu",
        "translation": "boxing"
    },
    {
        "word": "귀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gwi",
        "translation": "ear"
    },
    {
        "word": "귀여운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gwiyeoun",
        "translation": "cute"
    },
    {
        "word": "귤",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gyul",
        "translation": "satsumas, tangerines, satsuma"
    },
    {
        "word": "그",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geu",
        "translation": "the, he, him, those, that"
    },
    {
        "word": "그것",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geugeos",
        "translation": "that"
    },
    {
        "word": "그냥",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geunyang",
        "translation": "just, just because, anyway"
    },
    {
        "word": "그녀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geunyeo",
        "translation": "she, her"
    },
    {
        "word": "그래도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geuraedo",
        "translation": "nevertheless"
    },
    {
        "word": "그래서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geuraeseo",
        "translation": "So"
    },
    {
        "word": "그러나",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geureona",
        "translation": "however, but"
    },
    {
        "word": "그런데",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geureonde",
        "translation": "but"
    },
    {
        "word": "그룹",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geurub",
        "translation": "group"
    },
    {
        "word": "그릇",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geureus",
        "translation": "dish, plate, bowl"
    },
    {
        "word": "그리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geuri",
        "translation": "so, that"
    },
    {
        "word": "그리고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geurigo",
        "translation": "and"
    },
    {
        "word": "그리스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geuriseu",
        "translation": "greece"
    },
    {
        "word": "그릴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geuril",
        "translation": "draw"
    },
    {
        "word": "그림",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geurim",
        "translation": "picture, image, painting, pictures"
    },
    {
        "word": "그림자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geurimja",
        "translation": "shadow"
    },
    {
        "word": "그만큼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geumankeum",
        "translation": "that much, that"
    },
    {
        "word": "그저께",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geujeogge",
        "translation": "the day before yesterday"
    },
    {
        "word": "그쪽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geujjog",
        "translation": "that way"
    },
    {
        "word": "그편",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geupyeon",
        "translation": "That side"
    },
    {
        "word": "극장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geugjang",
        "translation": "theater, theatre, cinema"
    },
    {
        "word": "근처",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geunceo",
        "translation": "nearby"
    },
    {
        "word": "금",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geum",
        "translation": "Friday, gold"
    },
    {
        "word": "금붕어",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geumbungeo",
        "translation": "goldfish"
    },
    {
        "word": "금요일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "geumyoil",
        "translation": "friday"
    },
    {
        "word": "기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gi",
        "translation": ""
    },
    {
        "word": "기계",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gigye",
        "translation": "machine"
    },
    {
        "word": "기록",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "girog",
        "translation": "report, records, record"
    },
    {
        "word": "기름",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gireum",
        "translation": "oil"
    },
    {
        "word": "기본",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gibon",
        "translation": ""
    },
    {
        "word": "기쁨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gibbeum",
        "translation": "pleasure, joy"
    },
    {
        "word": "기사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gisa",
        "translation": "article, driver"
    },
    {
        "word": "기억",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gieog",
        "translation": "memory"
    },
    {
        "word": "기자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gija",
        "translation": "journalist"
    },
    {
        "word": "기차",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gica",
        "translation": "train, trains"
    },
    {
        "word": "기후",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gihu",
        "translation": "climate"
    },
    {
        "word": "긴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gin",
        "translation": "long"
    },
    {
        "word": "긴급",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gingeub",
        "translation": "urgent"
    },
    {
        "word": "긴장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ginjang",
        "translation": "nervousness, nervous, anxiousness"
    },
    {
        "word": "길",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gil",
        "translation": "street, way, road"
    },
    {
        "word": "김",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gim",
        "translation": "seaweed, laver"
    },
    {
        "word": "김밥",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gimbab",
        "translation": "gimbap, kimbap"
    },
    {
        "word": "김치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gimci",
        "translation": "kimchi"
    },
    {
        "word": "까지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggaji",
        "translation": "to, until, by"
    },
    {
        "word": "까치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggaci",
        "translation": "magpie, magpies"
    },
    {
        "word": "깎아",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggagga",
        "translation": ""
    },
    {
        "word": "깎았다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggaggassda",
        "translation": "cut"
    },
    {
        "word": "께",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "gge",
        "translation": "to"
    },
    {
        "word": "께서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggeseo",
        "translation": ""
    },
    {
        "word": "꽂이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggoji",
        "translation": ""
    },
    {
        "word": "꽃",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggoc",
        "translation": "kkot (sound), flowers, flower"
    },
    {
        "word": "꽤",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggwae",
        "translation": "quite"
    },
    {
        "word": "꾼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggun",
        "translation": "who dreamed, which ... dreamed"
    },
    {
        "word": "꿀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggul",
        "translation": "honey"
    },
    {
        "word": "꿈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggum",
        "translation": "the dream, dream, dreams"
    },
    {
        "word": "끝",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ggeut",
        "translation": "edge"
    },
    {
        "word": "나는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naneun",
        "translation": "I, flying"
    },
    {
        "word": "나라",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nara",
        "translation": "country, a country"
    },
    {
        "word": "나무",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "namu",
        "translation": "wooden, tree"
    },
    {
        "word": "나비",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nabi",
        "translation": "butterfly"
    },
    {
        "word": "나쁜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nabbeun",
        "translation": "bad"
    },
    {
        "word": "나중",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "najung",
        "translation": ""
    },
    {
        "word": "나흘",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naheul",
        "translation": "four days"
    },
    {
        "word": "낙엽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nagyeob",
        "translation": "fallen leaf, fallen leaves"
    },
    {
        "word": "낚시",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naggsi",
        "translation": ""
    },
    {
        "word": "난로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nanro",
        "translation": "stove"
    },
    {
        "word": "날",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nal",
        "translation": "me, the day, day, to fly"
    },
    {
        "word": "날씨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nalssi",
        "translation": "weather"
    },
    {
        "word": "남",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nam",
        "translation": "south"
    },
    {
        "word": "남동생",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "namdongsaeng",
        "translation": "younger brother"
    },
    {
        "word": "남자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "namja",
        "translation": "a man, the man, man"
    },
    {
        "word": "남자아이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "namjaai",
        "translation": "boy, a boy"
    },
    {
        "word": "남편",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nampyeon",
        "translation": "husbands, husband"
    },
    {
        "word": "낮습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "najseubnida",
        "translation": "is low"
    },
    {
        "word": "내과",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naegwa",
        "translation": "internal medicine"
    },
    {
        "word": "내기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naegi",
        "translation": ""
    },
    {
        "word": "내년",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naenyeon",
        "translation": "next year"
    },
    {
        "word": "내일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naeil",
        "translation": "tomorrow"
    },
    {
        "word": "냄비",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naembi",
        "translation": "pot, pan"
    },
    {
        "word": "냄새",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naemsae",
        "translation": "smell"
    },
    {
        "word": "냉동고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naengdonggo",
        "translation": "freezer"
    },
    {
        "word": "냉장고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "naengjanggo",
        "translation": "refrigerator"
    },
    {
        "word": "냐고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nyago",
        "translation": "whether"
    },
    {
        "word": "너구리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "neoguri",
        "translation": "raccoon dog"
    },
    {
        "word": "너무",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "neomu",
        "translation": "very, too, so, overly, too much"
    },
    {
        "word": "너희",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "neohyi",
        "translation": "you"
    },
    {
        "word": "넓습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "neolbseubnida",
        "translation": "is wide, are wide, spacious"
    },
    {
        "word": "넘어집니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "neomeojibnida",
        "translation": "fall down, falls, falls down, trips, trip"
    },
    {
        "word": "네",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ne",
        "translation": "four, yes, no, your, ne (sound)"
    },
    {
        "word": "네덜란드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nedeolrandeu",
        "translation": "netherlands"
    },
    {
        "word": "네트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "neteu",
        "translation": "Net"
    },
    {
        "word": "넥타이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "negtai",
        "translation": "tie, necktie"
    },
    {
        "word": "년",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nyeon",
        "translation": "the year, years, year"
    },
    {
        "word": "노동자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nodongja",
        "translation": "workers"
    },
    {
        "word": "노란",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "noran",
        "translation": "yellow"
    },
    {
        "word": "노랑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "norang",
        "translation": "yellow"
    },
    {
        "word": "노래",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "norae",
        "translation": "song"
    },
    {
        "word": "노르웨이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "noreuwei",
        "translation": "norwegian, norway"
    },
    {
        "word": "녹",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nog",
        "translation": ""
    },
    {
        "word": "녹음",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nogeum",
        "translation": "records"
    },
    {
        "word": "농구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nonggu",
        "translation": "basketball"
    },
    {
        "word": "농장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nongjang",
        "translation": "farm"
    },
    {
        "word": "높습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nopseubnida",
        "translation": "is tall, is high"
    },
    {
        "word": "뇌",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "noe",
        "translation": "brain, noe (sound), brains"
    },
    {
        "word": "누가",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nuga",
        "translation": "who"
    },
    {
        "word": "누구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nugu",
        "translation": "someone, who"
    },
    {
        "word": "눈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nun",
        "translation": "eyes, eye"
    },
    {
        "word": "눈물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nunmul",
        "translation": "tears"
    },
    {
        "word": "눈보라",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nunbora",
        "translation": "blizzards"
    },
    {
        "word": "눈치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nunci",
        "translation": ""
    },
    {
        "word": "뉴스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nyuseu",
        "translation": "news"
    },
    {
        "word": "뉴욕",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nyuyog",
        "translation": "New York"
    },
    {
        "word": "늑대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "neugdae",
        "translation": "wolves"
    },
    {
        "word": "는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "neun",
        "translation": "is"
    },
    {
        "word": "님",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "nim",
        "translation": "Ms., Mr., Miss, Mrs."
    },
    {
        "word": "다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "da",
        "translation": "everything, all, da (sound)"
    },
    {
        "word": "다고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dago",
        "translation": ""
    },
    {
        "word": "다른",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dareun",
        "translation": "different, the other, another"
    },
    {
        "word": "다리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dari",
        "translation": "bridge, legs, leg"
    },
    {
        "word": "다섯",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daseos",
        "translation": "five"
    },
    {
        "word": "다시",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dasi",
        "translation": "again"
    },
    {
        "word": "다음",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daeum",
        "translation": "the next, next"
    },
    {
        "word": "단",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dan",
        "translation": "sweet, only"
    },
    {
        "word": "닫는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dadneun",
        "translation": "closing"
    },
    {
        "word": "닫혔다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dadhyeossda",
        "translation": "is closed"
    },
    {
        "word": "달",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dal",
        "translation": "month, moon"
    },
    {
        "word": "달걀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dalgyal",
        "translation": "eggs, egg"
    },
    {
        "word": "달러",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dalreo",
        "translation": "dollars, dollar"
    },
    {
        "word": "달력",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dalryeog",
        "translation": "calendar, calendars"
    },
    {
        "word": "달리는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dalrineun",
        "translation": "running, that runs, who runs"
    },
    {
        "word": "닭",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dalg",
        "translation": "chicken"
    },
    {
        "word": "닭고기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dalggogi",
        "translation": "chicken"
    },
    {
        "word": "담요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "damyo",
        "translation": "blanket"
    },
    {
        "word": "담임",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "damim",
        "translation": ""
    },
    {
        "word": "닷새",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dassae",
        "translation": "five days"
    },
    {
        "word": "당신",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dangsin",
        "translation": "you"
    },
    {
        "word": "당했습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "danghaessseubnida",
        "translation": "experienced"
    },
    {
        "word": "대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dae",
        "translation": "zones, counter for machines"
    },
    {
        "word": "대답",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daedab",
        "translation": "answer, answers"
    },
    {
        "word": "대륙",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daeryug",
        "translation": "continent"
    },
    {
        "word": "대박",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daebag",
        "translation": "Awesome"
    },
    {
        "word": "대부분",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daebubun",
        "translation": "usually, almost"
    },
    {
        "word": "대중",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daejung",
        "translation": "mass"
    },
    {
        "word": "대통령",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daetongryeong",
        "translation": "president"
    },
    {
        "word": "대표",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daepyo",
        "translation": "representative"
    },
    {
        "word": "대한",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daehan",
        "translation": "about, for"
    },
    {
        "word": "대화합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daehwahabnida",
        "translation": "converse, talk, have a conversation"
    },
    {
        "word": "대회",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daehoe",
        "translation": "contest"
    },
    {
        "word": "댁",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "daeg",
        "translation": "house"
    },
    {
        "word": "더",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deo",
        "translation": "more"
    },
    {
        "word": "던",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deon",
        "translation": ""
    },
    {
        "word": "덜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deol",
        "translation": "fewer, less"
    },
    {
        "word": "데",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "de",
        "translation": ""
    },
    {
        "word": "덴마크",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "denmakeu",
        "translation": "Danish, denmark"
    },
    {
        "word": "도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "do",
        "translation": "even, do (sound), degrees, too"
    },
    {
        "word": "도난",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "donan",
        "translation": "theft"
    },
    {
        "word": "도넛",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "doneos",
        "translation": "doughnut"
    },
    {
        "word": "도둑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dodug",
        "translation": "thief"
    },
    {
        "word": "도로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "doro",
        "translation": "street, avenue, road"
    },
    {
        "word": "도마",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "doma",
        "translation": "cutting board, chopping board"
    },
    {
        "word": "도마뱀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "domabaem",
        "translation": ""
    },
    {
        "word": "도서관",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "doseogwan",
        "translation": "a library, library"
    },
    {
        "word": "도시",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dosi",
        "translation": "city"
    },
    {
        "word": "도움",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "doum",
        "translation": "help"
    },
    {
        "word": "도착",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "docag",
        "translation": ""
    },
    {
        "word": "도쿄",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dokyo",
        "translation": "Tokyo"
    },
    {
        "word": "독일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dogil",
        "translation": "germany"
    },
    {
        "word": "돈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "don",
        "translation": "money, don (sound)"
    },
    {
        "word": "돌",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dol",
        "translation": "stone, rock, first birthday"
    },
    {
        "word": "동",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dong",
        "translation": "dong (sound), east"
    },
    {
        "word": "동갑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "donggab",
        "translation": ""
    },
    {
        "word": "동계",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "donggye",
        "translation": "wintertime"
    },
    {
        "word": "동굴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "donggul",
        "translation": "cave, the cave"
    },
    {
        "word": "동급생",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "donggeubsaeng",
        "translation": "classmate"
    },
    {
        "word": "동네",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dongne",
        "translation": "town, neighborhood, neighbourhood, village"
    },
    {
        "word": "동료",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dongryo",
        "translation": "colleague"
    },
    {
        "word": "동물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dongmul",
        "translation": "animals, an animal, animal"
    },
    {
        "word": "동반자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dongbanja",
        "translation": "companion"
    },
    {
        "word": "동생",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dongsaeng",
        "translation": "younger sibling"
    },
    {
        "word": "동안",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dongan",
        "translation": "during, while, for"
    },
    {
        "word": "동업자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dongeobja",
        "translation": "business partner"
    },
    {
        "word": "돼지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dwaeji",
        "translation": "pigs, pig"
    },
    {
        "word": "돼지고기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dwaejigogi",
        "translation": "pork"
    },
    {
        "word": "뒤",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dwi",
        "translation": ""
    },
    {
        "word": "듀오링고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dyuoringgo",
        "translation": "Duolingo"
    },
    {
        "word": "드래곤즈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deuraegonjeu",
        "translation": "Dragons"
    },
    {
        "word": "득점",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deugjeom",
        "translation": "scored, point"
    },
    {
        "word": "든지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deunji",
        "translation": ""
    },
    {
        "word": "들",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deul",
        "translation": "field"
    },
    {
        "word": "들을",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deuleul",
        "translation": "hear, will listen"
    },
    {
        "word": "들판",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deulpan",
        "translation": "fields"
    },
    {
        "word": "등",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "deung",
        "translation": "lamp, back"
    },
    {
        "word": "따로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddaro",
        "translation": "separately"
    },
    {
        "word": "딱",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddag",
        "translation": "only, well, just"
    },
    {
        "word": "딸기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddalgi",
        "translation": "strawberry, a strawberry"
    },
    {
        "word": "땀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddam",
        "translation": "sweat"
    },
    {
        "word": "땄습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddassseubnida",
        "translation": "got, won, received"
    },
    {
        "word": "땅",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddang",
        "translation": "ttang (sound), ground"
    },
    {
        "word": "때",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddae",
        "translation": "ttae (sound), occasions, when"
    },
    {
        "word": "때문",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddaemun",
        "translation": ""
    },
    {
        "word": "떡",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddeog",
        "translation": "rice cake"
    },
    {
        "word": "또는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddoneun",
        "translation": "or, either u2026 or"
    },
    {
        "word": "뚜껑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "dduggeong",
        "translation": ""
    },
    {
        "word": "뜨거운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ddeugeoun",
        "translation": "hot"
    },
    {
        "word": "라고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "rago",
        "translation": ""
    },
    {
        "word": "라디오",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "radio",
        "translation": "radio"
    },
    {
        "word": "라면",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ramyeon",
        "translation": "ramen"
    },
    {
        "word": "러시아",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "reosia",
        "translation": "Russia"
    },
    {
        "word": "런던",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "reondeon",
        "translation": "London"
    },
    {
        "word": "레인지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "reinji",
        "translation": ""
    },
    {
        "word": "로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ro",
        "translation": "to, toward, with, by"
    },
    {
        "word": "롤러스케이트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "rolreoseukeiteu",
        "translation": "rollerskate"
    },
    {
        "word": "루마니아",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "rumania",
        "translation": "romania"
    },
    {
        "word": "를",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "reul",
        "translation": ""
    },
    {
        "word": "마다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mada",
        "translation": ""
    },
    {
        "word": "마당",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "madang",
        "translation": "yard"
    },
    {
        "word": "마라톤",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maraton",
        "translation": "marathon"
    },
    {
        "word": "마루",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maru",
        "translation": "floor"
    },
    {
        "word": "마리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mari",
        "translation": "(counter for animals)"
    },
    {
        "word": "마비",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mabi",
        "translation": ""
    },
    {
        "word": "마스코트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maseukoteu",
        "translation": "mascot"
    },
    {
        "word": "마시는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "masineun",
        "translation": "drinking, that u2026 is drinking, that u2026 drinks"
    },
    {
        "word": "마신",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "masin",
        "translation": "that was drunk, who drank, where ... drank, drunk"
    },
    {
        "word": "마실",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "masil",
        "translation": "drink"
    },
    {
        "word": "마십니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "masibnida",
        "translation": "am drinking, are drinking, drink, drinks"
    },
    {
        "word": "마지막",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "majimag",
        "translation": "last, final"
    },
    {
        "word": "마천루",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maceonru",
        "translation": "skyscraper"
    },
    {
        "word": "마흔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maheun",
        "translation": "forty"
    },
    {
        "word": "막차",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "magca",
        "translation": ""
    },
    {
        "word": "만",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "man",
        "translation": "only, ten thousand"
    },
    {
        "word": "만나서 반갑습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mannaseo bangabseubnida",
        "translation": "nice to meet you"
    },
    {
        "word": "만날",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mannal",
        "translation": "meet, see, meet with"
    },
    {
        "word": "만들",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mandeul",
        "translation": "will make, will create"
    },
    {
        "word": "많이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "manhi",
        "translation": "very, a lot, many, much, quite"
    },
    {
        "word": "말",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mal",
        "translation": "talk, horse, word, language, words"
    },
    {
        "word": "말합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "malhabnida",
        "translation": "says, say, speaks, tell, talk, speak"
    },
    {
        "word": "맛",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mas",
        "translation": "flavor"
    },
    {
        "word": "맛없는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maseobsneun",
        "translation": "bad-tasting, tasteless"
    },
    {
        "word": "맛없습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maseobsseubnida",
        "translation": "are not tasty, is tasteless, is not delicious, tastes bad"
    },
    {
        "word": "맛있는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "masissneun",
        "translation": "delicious"
    },
    {
        "word": "맛있습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "masissseubnida",
        "translation": "is tasty, taste good, tastes good, is delicious, tasty, are delicious"
    },
    {
        "word": "매",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mae",
        "translation": "every"
    },
    {
        "word": "매년",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maenyeon",
        "translation": "every year"
    },
    {
        "word": "매력",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maeryeog",
        "translation": ""
    },
    {
        "word": "매우",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maeu",
        "translation": "very, much"
    },
    {
        "word": "매운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maeun",
        "translation": "spicy"
    },
    {
        "word": "매일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maeil",
        "translation": "every day, each day"
    },
    {
        "word": "맥도날드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maegdonaldeu",
        "translation": "McDonald's"
    },
    {
        "word": "맥주",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "maegju",
        "translation": "beer"
    },
    {
        "word": "머리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meori",
        "translation": "head"
    },
    {
        "word": "머리카락",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meorikarag",
        "translation": "hair"
    },
    {
        "word": "먹는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meogneun",
        "translation": "eating, that u2026 eats, who is eating"
    },
    {
        "word": "먹습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meogseubnida",
        "translation": "are eating, drink, eats, is eating, have, eat, drinks"
    },
    {
        "word": "먹은",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meogeun",
        "translation": "which ... ate, having eaten"
    },
    {
        "word": "먹을",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meogeul",
        "translation": "eat, have"
    },
    {
        "word": "먹이지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meogiji",
        "translation": "feed"
    },
    {
        "word": "멀리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meolri",
        "translation": "away, off"
    },
    {
        "word": "멋없습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meoseobsseubnida",
        "translation": "are not stylish, is not cool, are not cool, uncool, is not stylish"
    },
    {
        "word": "멋있습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meosissseubnida",
        "translation": "are cool, is cool, is stylish, cool"
    },
    {
        "word": "메뉴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "menyu",
        "translation": "menu"
    },
    {
        "word": "메달",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "medal",
        "translation": "medal"
    },
    {
        "word": "메스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "meseu",
        "translation": ""
    },
    {
        "word": "메시지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mesiji",
        "translation": "message, messages"
    },
    {
        "word": "멕시코",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "megsiko",
        "translation": "mexico"
    },
    {
        "word": "멤버",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "membeo",
        "translation": "member"
    },
    {
        "word": "며칠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "myeocil",
        "translation": "several days, a few days, how many days"
    },
    {
        "word": "면도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "myeondo",
        "translation": "shave"
    },
    {
        "word": "면세",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "myeonse",
        "translation": ""
    },
    {
        "word": "면접",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "myeonjeob",
        "translation": "interview"
    },
    {
        "word": "면허",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "myeonheo",
        "translation": ""
    },
    {
        "word": "명",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "myeong",
        "translation": "nineteen friends"
    },
    {
        "word": "명부",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "myeongbu",
        "translation": "list"
    },
    {
        "word": "몇",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "myeoc",
        "translation": "how much, what, some, a few, how many"
    },
    {
        "word": "모델",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "model",
        "translation": "models, model"
    },
    {
        "word": "모두",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "modu",
        "translation": "everybody, all, total, every"
    },
    {
        "word": "모든",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "modeun",
        "translation": "all, every"
    },
    {
        "word": "모래",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "morae",
        "translation": "sand"
    },
    {
        "word": "모레",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "more",
        "translation": "day after tomorrow"
    },
    {
        "word": "모자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "moja",
        "translation": "hat, the hat"
    },
    {
        "word": "모터스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "moteoseu",
        "translation": "motors"
    },
    {
        "word": "모험",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "moheom",
        "translation": "An adventure, adventure"
    },
    {
        "word": "목",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mog",
        "translation": "throat, neck"
    },
    {
        "word": "목요일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mogyoil",
        "translation": "thursdays, thursday"
    },
    {
        "word": "목욕",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mogyog",
        "translation": ""
    },
    {
        "word": "몸",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mom",
        "translation": "body"
    },
    {
        "word": "못",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mos",
        "translation": "cannot"
    },
    {
        "word": "무",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mu",
        "translation": "radish, mu (sound)"
    },
    {
        "word": "무거운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mugeoun",
        "translation": "heavy"
    },
    {
        "word": "무대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mudae",
        "translation": "stage"
    },
    {
        "word": "무릎",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mureup",
        "translation": "knees"
    },
    {
        "word": "무슨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "museun",
        "translation": "what"
    },
    {
        "word": "무엇",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mueos",
        "translation": "what, what's"
    },
    {
        "word": "무지개",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mujigae",
        "translation": "rainbow"
    },
    {
        "word": "무침",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mucim",
        "translation": ""
    },
    {
        "word": "문",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mun",
        "translation": "entry, door"
    },
    {
        "word": "문제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "munje",
        "translation": "question, problem, problems, matter"
    },
    {
        "word": "문화",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "munhwa",
        "translation": "culture"
    },
    {
        "word": "물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mul",
        "translation": "water"
    },
    {
        "word": "물건",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mulgeon",
        "translation": "objects"
    },
    {
        "word": "물고기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mulgogi",
        "translation": "fish"
    },
    {
        "word": "미각",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "migag",
        "translation": "(sense of) taste, Gustation, Gustatory perception"
    },
    {
        "word": "미국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "migug",
        "translation": "American, USA"
    },
    {
        "word": "미안합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mianhabnida",
        "translation": "sorry, I am sorry"
    },
    {
        "word": "민지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "minji",
        "translation": "Minji"
    },
    {
        "word": "밑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "mit",
        "translation": ""
    },
    {
        "word": "바나나",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "banana",
        "translation": "bananas, banana"
    },
    {
        "word": "바다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bada",
        "translation": "sea, ocean"
    },
    {
        "word": "바닥",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "badag",
        "translation": "floor"
    },
    {
        "word": "바둑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "badug",
        "translation": "go, baduk"
    },
    {
        "word": "바람",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baram",
        "translation": "breeze, wind"
    },
    {
        "word": "바로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baro",
        "translation": "exactly, (uc2dcuac04uc744 uc774uc57cuae30ud560 ub54c) right away, right, (uc2dcuac04uc744 uc774uc57cuae30 ud560 ub54c) in a minute, directly"
    },
    {
        "word": "바쁜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "babbeun",
        "translation": "busy"
    },
    {
        "word": "바이러스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baireoseu",
        "translation": "virus"
    },
    {
        "word": "바지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baji",
        "translation": "pants"
    },
    {
        "word": "박물관",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bagmulgwan",
        "translation": "museum"
    },
    {
        "word": "밖",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bagg",
        "translation": "outside, bak (sound)"
    },
    {
        "word": "반",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ban",
        "translation": "homeroom, thirty (after the hour), class, half"
    },
    {
        "word": "반지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "banji",
        "translation": "ring"
    },
    {
        "word": "반찬",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bancan",
        "translation": "side dish"
    },
    {
        "word": "받은",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "badeun",
        "translation": "which ... received"
    },
    {
        "word": "발",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bal",
        "translation": "foot, feet"
    },
    {
        "word": "발가락",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "balgarag",
        "translation": "toe"
    },
    {
        "word": "발꿈치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "balggumci",
        "translation": "heel"
    },
    {
        "word": "발생했어요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "balsaenghaesseoyo",
        "translation": "happened"
    },
    {
        "word": "밤",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bam",
        "translation": "chestnut, at night, night"
    },
    {
        "word": "밥",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bab",
        "translation": "something to eat, rice, bap (sound)"
    },
    {
        "word": "밥솥",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "babsot",
        "translation": "rice cooker"
    },
    {
        "word": "방",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bang",
        "translation": "room"
    },
    {
        "word": "방금",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "banggeum",
        "translation": "just now, just"
    },
    {
        "word": "방문",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bangmun",
        "translation": "visit"
    },
    {
        "word": "방송",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bangsong",
        "translation": "broadcast"
    },
    {
        "word": "방송국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bangsonggug",
        "translation": "broadcasting company"
    },
    {
        "word": "배",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bae",
        "translation": "ship, stomach, bae (sound), boat, times"
    },
    {
        "word": "배구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baegu",
        "translation": "volleyball"
    },
    {
        "word": "배꼽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baeggob",
        "translation": "belly button"
    },
    {
        "word": "배낭",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baenang",
        "translation": "backpack"
    },
    {
        "word": "배스킨라빈스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baeseukinrabinseu",
        "translation": "Baskin-Robbins"
    },
    {
        "word": "배우",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baeu",
        "translation": "actor"
    },
    {
        "word": "배우자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baeuja",
        "translation": "spouse"
    },
    {
        "word": "배추",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baecu",
        "translation": "napa cabbage"
    },
    {
        "word": "백",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baeg",
        "translation": "hundred, one hundred"
    },
    {
        "word": "백일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baegil",
        "translation": "one hundredth day"
    },
    {
        "word": "백화점",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baeghwajeom",
        "translation": "department store"
    },
    {
        "word": "밴드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baendeu",
        "translation": "band"
    },
    {
        "word": "뱀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "baem",
        "translation": "snake"
    },
    {
        "word": "버스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beoseu",
        "translation": "bus"
    },
    {
        "word": "번",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beon",
        "translation": "time, times"
    },
    {
        "word": "번개",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beongae",
        "translation": "lightning"
    },
    {
        "word": "번째",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beonjjae",
        "translation": ""
    },
    {
        "word": "번호",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beonho",
        "translation": "number"
    },
    {
        "word": "벌",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beol",
        "translation": "counter for clothes"
    },
    {
        "word": "벌써",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beolsseo",
        "translation": "already"
    },
    {
        "word": "벗는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beosneun",
        "translation": "taking off"
    },
    {
        "word": "벚꽃",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beojggoc",
        "translation": "cherry blossom"
    },
    {
        "word": "베개",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "begae",
        "translation": "pillow"
    },
    {
        "word": "베를린",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bereulrin",
        "translation": "Berlin"
    },
    {
        "word": "벨기에",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "belgie",
        "translation": "belgium, belgian"
    },
    {
        "word": "벨트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "belteu",
        "translation": "belt"
    },
    {
        "word": "벽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "byeog",
        "translation": "wall"
    },
    {
        "word": "벽장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "byeogjang",
        "translation": "closet"
    },
    {
        "word": "변기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "byeongi",
        "translation": "toilet"
    },
    {
        "word": "변호사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "byeonhosa",
        "translation": "the lawyer, lawyer"
    },
    {
        "word": "별로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "byeolro",
        "translation": "not very"
    },
    {
        "word": "병",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "byeong",
        "translation": "illnesses, illness, bottles, bottle, sickness"
    },
    {
        "word": "병원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "byeongweon",
        "translation": "hospital, clinic"
    },
    {
        "word": "보",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bo",
        "translation": "bo (sound), cover, cloth, sheet"
    },
    {
        "word": "보낼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bonael",
        "translation": "send"
    },
    {
        "word": "보다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "boda",
        "translation": "sees, watch, watches, looking, seen, than"
    },
    {
        "word": "보라",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bora",
        "translation": ""
    },
    {
        "word": "보통",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "botong",
        "translation": "usually, generally, normally"
    },
    {
        "word": "보행자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bohaengja",
        "translation": "pedestrian"
    },
    {
        "word": "복숭아",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bogsunga",
        "translation": ""
    },
    {
        "word": "복용",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bogyong",
        "translation": ""
    },
    {
        "word": "본",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bon",
        "translation": "that ... saw, which ... saw, that was seen, who ... saw"
    },
    {
        "word": "볼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bol",
        "translation": "read, to see, look, to watch, to look at"
    },
    {
        "word": "볼펜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bolpen",
        "translation": "ballpoint pen"
    },
    {
        "word": "봄",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bom",
        "translation": "spring, sight"
    },
    {
        "word": "봅슬레이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bobseulrei",
        "translation": "bobsled"
    },
    {
        "word": "부모",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bumo",
        "translation": "parents"
    },
    {
        "word": "부부",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bubu",
        "translation": "couple"
    },
    {
        "word": "부산",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "busan",
        "translation": "Busan"
    },
    {
        "word": "부엉이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bueongi",
        "translation": "Owl"
    },
    {
        "word": "부터",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "buteo",
        "translation": "from"
    },
    {
        "word": "북",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bug",
        "translation": "north"
    },
    {
        "word": "분",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bun",
        "translation": "honorific counter, minutes"
    },
    {
        "word": "분필",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bunpil",
        "translation": "chalk"
    },
    {
        "word": "분홍",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bunhong",
        "translation": "pink"
    },
    {
        "word": "불",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bul",
        "translation": "fire"
    },
    {
        "word": "불고기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bulgogi",
        "translation": "bulgogi"
    },
    {
        "word": "불러요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bulreoyo",
        "translation": "call"
    },
    {
        "word": "브라질",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beurajil",
        "translation": "Brazil, Brazilian"
    },
    {
        "word": "블루윙즈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "beulruwingjeu",
        "translation": "Bluewings"
    },
    {
        "word": "비",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bi",
        "translation": "rain, bi (sound)"
    },
    {
        "word": "비누",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "binu",
        "translation": "soap"
    },
    {
        "word": "비밀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bimil",
        "translation": "secret"
    },
    {
        "word": "비상",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bisang",
        "translation": "emergency"
    },
    {
        "word": "비싼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bissan",
        "translation": "expensive"
    },
    {
        "word": "비자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bija",
        "translation": "visas, visa"
    },
    {
        "word": "비행",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bihaeng",
        "translation": "flights, flight"
    },
    {
        "word": "비행기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bihaenggi",
        "translation": "airplane, plane, an airplane"
    },
    {
        "word": "빗",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bis",
        "translation": "comb"
    },
    {
        "word": "빙하",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bingha",
        "translation": "glacier"
    },
    {
        "word": "빛",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bic",
        "translation": "light"
    },
    {
        "word": "빨간",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bbalgan",
        "translation": "red"
    },
    {
        "word": "빨강",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bbalgang",
        "translation": "red"
    },
    {
        "word": "빨리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bbalri",
        "translation": "hurry and, fast, quickly"
    },
    {
        "word": "빵",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bbang",
        "translation": "bread"
    },
    {
        "word": "빵집",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bbangjib",
        "translation": "bakery"
    },
    {
        "word": "뼈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bbyeo",
        "translation": "bone"
    },
    {
        "word": "뽀뽀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "bbobbo",
        "translation": "kiss"
    },
    {
        "word": "사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sa",
        "translation": "four, sa (sound)"
    },
    {
        "word": "사거리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sageori",
        "translation": "intersection"
    },
    {
        "word": "사과",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sagwa",
        "translation": "an apple"
    },
    {
        "word": "사람",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saram",
        "translation": "person, a person, the person"
    },
    {
        "word": "사막",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "samag",
        "translation": "desert"
    },
    {
        "word": "사망",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "samang",
        "translation": ""
    },
    {
        "word": "사슴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saseum",
        "translation": "deer"
    },
    {
        "word": "사이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sai",
        "translation": ""
    },
    {
        "word": "사이즈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saijeu",
        "translation": "size"
    },
    {
        "word": "사진",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sajin",
        "translation": "picture, photography, photos, photo"
    },
    {
        "word": "사촌",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sacon",
        "translation": "cousin"
    },
    {
        "word": "사태",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "satae",
        "translation": "situation"
    },
    {
        "word": "사회",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sahoe",
        "translation": "society"
    },
    {
        "word": "사흘",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saheul",
        "translation": "three days"
    },
    {
        "word": "산",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "san",
        "translation": "living, bought, mountains, alive, mountain"
    },
    {
        "word": "살",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sal",
        "translation": "purchase, live"
    },
    {
        "word": "삼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sam",
        "translation": "three"
    },
    {
        "word": "삼성",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "samseong",
        "translation": "Samsung"
    },
    {
        "word": "삼촌",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "samcon",
        "translation": "uncle"
    },
    {
        "word": "상",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sang",
        "translation": "awards, prize, award"
    },
    {
        "word": "상당히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sangdanghi",
        "translation": "considerably, greatly"
    },
    {
        "word": "상어",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sangeo",
        "translation": "sharks, shark"
    },
    {
        "word": "상자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sangja",
        "translation": "box"
    },
    {
        "word": "상태",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sangtae",
        "translation": "condition"
    },
    {
        "word": "상표",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sangpyo",
        "translation": "brand"
    },
    {
        "word": "새",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sae",
        "translation": "new, the bird, bird, birds"
    },
    {
        "word": "새끼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saeggi",
        "translation": "baby"
    },
    {
        "word": "새벽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saebyeog",
        "translation": "in the morning, daybreak, the morning, dawn"
    },
    {
        "word": "색",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saeg",
        "translation": "color, colors, colour"
    },
    {
        "word": "색깔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saegggal",
        "translation": "color, colors, colour"
    },
    {
        "word": "색연필",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saegyeonpil",
        "translation": "colored pencil"
    },
    {
        "word": "샌드위치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saendeuwici",
        "translation": "sandwich"
    },
    {
        "word": "생각",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saenggag",
        "translation": "thought, an idea, idea, thoughts, ideas"
    },
    {
        "word": "생각하는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saenggaghaneun",
        "translation": "thinking"
    },
    {
        "word": "생선",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saengseon",
        "translation": "fish"
    },
    {
        "word": "생신",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saengsin",
        "translation": "birthday"
    },
    {
        "word": "생일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "saengil",
        "translation": "birthday"
    },
    {
        "word": "샤워",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "syaweo",
        "translation": "shower"
    },
    {
        "word": "샴푸",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "syampu",
        "translation": "shampoo"
    },
    {
        "word": "서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seo",
        "translation": "west, seo (sound)"
    },
    {
        "word": "서로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seoro",
        "translation": "each other"
    },
    {
        "word": "서른",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seoreun",
        "translation": "thirty"
    },
    {
        "word": "서리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seori",
        "translation": "frost"
    },
    {
        "word": "서연",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seoyeon",
        "translation": "Seoyeon"
    },
    {
        "word": "서울",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seoul",
        "translation": "Seoul"
    },
    {
        "word": "서점",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seojeom",
        "translation": "bookstore"
    },
    {
        "word": "서준",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seojun",
        "translation": "Seojun (name), seojun"
    },
    {
        "word": "서핑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seoping",
        "translation": "surfing"
    },
    {
        "word": "선",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seon",
        "translation": "who stood, standing, line, having stopped"
    },
    {
        "word": "선글라스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seongeulraseu",
        "translation": "sunglasses"
    },
    {
        "word": "선물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seonmul",
        "translation": "the present, present"
    },
    {
        "word": "선생님",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seonsaengnim",
        "translation": "sir, teacher"
    },
    {
        "word": "선수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seonsu",
        "translation": "player"
    },
    {
        "word": "선수단",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seonsudan",
        "translation": "squad"
    },
    {
        "word": "선장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seonjang",
        "translation": "captain"
    },
    {
        "word": "설탕",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seoltang",
        "translation": "sugar"
    },
    {
        "word": "섬",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seom",
        "translation": "islands, island"
    },
    {
        "word": "성공",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seonggong",
        "translation": "success"
    },
    {
        "word": "성적표",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seongjeogpyo",
        "translation": "report card"
    },
    {
        "word": "성함",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seongham",
        "translation": "name"
    },
    {
        "word": "성화",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seonghwa",
        "translation": "Torch, flame"
    },
    {
        "word": "세계",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "segye",
        "translation": "world"
    },
    {
        "word": "세관",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "segwan",
        "translation": "at customs, customs"
    },
    {
        "word": "세척",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seceog",
        "translation": ""
    },
    {
        "word": "세탁",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "setag",
        "translation": ""
    },
    {
        "word": "셔츠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "syeoceu",
        "translation": "shirt"
    },
    {
        "word": "소",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "so",
        "translation": "so (sound), cow"
    },
    {
        "word": "소개",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sogae",
        "translation": "introduction"
    },
    {
        "word": "소고기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sogogi",
        "translation": "beef"
    },
    {
        "word": "소금",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sogeum",
        "translation": "salt"
    },
    {
        "word": "소리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sori",
        "translation": "sound"
    },
    {
        "word": "소방관",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sobanggwan",
        "translation": "firefighter"
    },
    {
        "word": "소방서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sobangseo",
        "translation": "firehouse, fire station"
    },
    {
        "word": "소방차",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sobangca",
        "translation": "fire engine"
    },
    {
        "word": "소스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "soseu",
        "translation": "sauce"
    },
    {
        "word": "소아과",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "soagwa",
        "translation": "pediatrics, paediatrics, pediatric"
    },
    {
        "word": "소파",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sopa",
        "translation": "sofa"
    },
    {
        "word": "소화기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sohwagi",
        "translation": "fire extinguisher"
    },
    {
        "word": "속",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sog",
        "translation": "inside"
    },
    {
        "word": "손",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "son",
        "translation": "hand, hands"
    },
    {
        "word": "손가락",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "songarag",
        "translation": "fingers, finger"
    },
    {
        "word": "손가방",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "songabang",
        "translation": "handbag"
    },
    {
        "word": "손녀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sonnyeo",
        "translation": "granddaughter"
    },
    {
        "word": "손님",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sonnim",
        "translation": "customer, sir, miss, guest, visitor"
    },
    {
        "word": "손목시계",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sonmogsigye",
        "translation": "watch, watches"
    },
    {
        "word": "손자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sonja",
        "translation": "grandson"
    },
    {
        "word": "쇼트트랙",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "syoteuteuraeg",
        "translation": "short track"
    },
    {
        "word": "쇼핑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "syoping",
        "translation": "shopping"
    },
    {
        "word": "수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "su",
        "translation": "Wednesday, number"
    },
    {
        "word": "수건",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sugeon",
        "translation": "towel"
    },
    {
        "word": "수도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sudo",
        "translation": "water way, capital"
    },
    {
        "word": "수비수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "subisu",
        "translation": "defender"
    },
    {
        "word": "수상자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "susangja",
        "translation": "winner"
    },
    {
        "word": "수속",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "susog",
        "translation": "check in procedures"
    },
    {
        "word": "수수께끼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "susuggeggi",
        "translation": "riddle"
    },
    {
        "word": "수술",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "susul",
        "translation": "operation, surgery"
    },
    {
        "word": "수술대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "susuldae",
        "translation": "operating table"
    },
    {
        "word": "수염",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "suyeom",
        "translation": ""
    },
    {
        "word": "수영",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "suyeong",
        "translation": "swimming"
    },
    {
        "word": "수영합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "suyeonghabnida",
        "translation": "swim, swims, is swimming"
    },
    {
        "word": "수요일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "suyoil",
        "translation": "wednesday"
    },
    {
        "word": "수저",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sujeo",
        "translation": "spoon and chopsticks"
    },
    {
        "word": "수프",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "supeu",
        "translation": "soup"
    },
    {
        "word": "수하물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "suhamul",
        "translation": "baggages, luggage"
    },
    {
        "word": "수학여행",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "suhagyeohaeng",
        "translation": "Class trip, School trip, Field trip"
    },
    {
        "word": "수확물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "suhwagmul",
        "translation": ""
    },
    {
        "word": "숙제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sugje",
        "translation": "homework"
    },
    {
        "word": "순항",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sunhang",
        "translation": "cruising"
    },
    {
        "word": "숟가락",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sudgarag",
        "translation": "spoons, spoon"
    },
    {
        "word": "술",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sul",
        "translation": "alcohol"
    },
    {
        "word": "술집",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "suljib",
        "translation": "bar, pub"
    },
    {
        "word": "숲",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sup",
        "translation": "woods, forest"
    },
    {
        "word": "쉬는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "swineun",
        "translation": "resting"
    },
    {
        "word": "쉬어라",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "swieora",
        "translation": "rest"
    },
    {
        "word": "쉬운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "swiun",
        "translation": "easy"
    },
    {
        "word": "쉬지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "swiji",
        "translation": "rest"
    },
    {
        "word": "쉰",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "swin",
        "translation": "fifty"
    },
    {
        "word": "스노보드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seunobodeu",
        "translation": "snowboard"
    },
    {
        "word": "스케이트보드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seukeiteubodeu",
        "translation": "skateboard"
    },
    {
        "word": "스케이팅",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seukeiting",
        "translation": "skating"
    },
    {
        "word": "스키",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seuki",
        "translation": "ski"
    },
    {
        "word": "스타벅스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seutabeogseu",
        "translation": "Starbucks"
    },
    {
        "word": "스페인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seupein",
        "translation": "spain"
    },
    {
        "word": "스포츠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seupoceu",
        "translation": "sport, sports"
    },
    {
        "word": "스피드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seupideu",
        "translation": ""
    },
    {
        "word": "스피커",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seupikeo",
        "translation": "a speaker"
    },
    {
        "word": "승마",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "seungma",
        "translation": "horseback riding"
    },
    {
        "word": "시",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "si",
        "translation": "city, si (sound), o'clock, poetry, si, at, time"
    },
    {
        "word": "시각",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sigag",
        "translation": "[Sense of/] sight, Perspective, sight"
    },
    {
        "word": "시간",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sigan",
        "translation": "hours, hour, time"
    },
    {
        "word": "시내",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinae",
        "translation": "downtown"
    },
    {
        "word": "시상식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sisangsig",
        "translation": "award ceremony"
    },
    {
        "word": "시외",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sioe",
        "translation": ""
    },
    {
        "word": "시작",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sijag",
        "translation": "starts"
    },
    {
        "word": "시장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sijang",
        "translation": "markets, mayor, market"
    },
    {
        "word": "시청",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "siceong",
        "translation": "city hall"
    },
    {
        "word": "시험",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "siheom",
        "translation": "test"
    },
    {
        "word": "식기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "siggi",
        "translation": "dishes"
    },
    {
        "word": "식당",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sigdang",
        "translation": "a restaurant, restaurant"
    },
    {
        "word": "식료",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sigryo",
        "translation": ""
    },
    {
        "word": "식사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sigsa",
        "translation": "a meal, eating, meal"
    },
    {
        "word": "식용",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sigyong",
        "translation": ""
    },
    {
        "word": "식탁",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sigtag",
        "translation": "table"
    },
    {
        "word": "신",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sin",
        "translation": "sour"
    },
    {
        "word": "신고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "singo",
        "translation": "report, put, wears, wear"
    },
    {
        "word": "신는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinneun",
        "translation": "wearing"
    },
    {
        "word": "신문",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinmun",
        "translation": "newspaper, a newspaper"
    },
    {
        "word": "신물",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinmul",
        "translation": ""
    },
    {
        "word": "신발",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinbal",
        "translation": "shoes, shoe"
    },
    {
        "word": "신선한",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinseonhan",
        "translation": "fresh"
    },
    {
        "word": "신용",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinyong",
        "translation": "credit"
    },
    {
        "word": "신청",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinceong",
        "translation": "application"
    },
    {
        "word": "신호",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sinho",
        "translation": "signal"
    },
    {
        "word": "실",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sil",
        "translation": "string"
    },
    {
        "word": "실례합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "silryehabnida",
        "translation": "excuse me"
    },
    {
        "word": "실수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "silsu",
        "translation": "mistakes, mistake"
    },
    {
        "word": "실업자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sileobja",
        "translation": "unemployed"
    },
    {
        "word": "실크",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "silkeu",
        "translation": "silk"
    },
    {
        "word": "심장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "simjang",
        "translation": "heart"
    },
    {
        "word": "싱가포르",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "singgaporeu",
        "translation": "singaporean, Singapore"
    },
    {
        "word": "싱크대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "singkeudae",
        "translation": "sink"
    },
    {
        "word": "싸우는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ssauneun",
        "translation": "fighting"
    },
    {
        "word": "싼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ssan",
        "translation": "cheap"
    },
    {
        "word": "쓰는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sseuneun",
        "translation": "using, use, used"
    },
    {
        "word": "쓰레기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sseuregi",
        "translation": "trash"
    },
    {
        "word": "쓴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sseun",
        "translation": "written, bitter"
    },
    {
        "word": "쓸",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sseul",
        "translation": "written, wear"
    },
    {
        "word": "씁니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sseubnida",
        "translation": "write, writes, is bitter, wears, spend"
    },
    {
        "word": "씁니다.",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "sseubnida.",
        "translation": ""
    },
    {
        "word": "씨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ssi",
        "translation": "Ms., Mr., seed, ssi (sound), (form of address)"
    },
    {
        "word": "아기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "agi",
        "translation": "baby, a baby, babies"
    },
    {
        "word": "아내",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anae",
        "translation": "wife, wives"
    },
    {
        "word": "아니요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aniyo",
        "translation": "no"
    },
    {
        "word": "아니요,",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aniyo,",
        "translation": ""
    },
    {
        "word": "아닙니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anibnida",
        "translation": "is not, are not, am not"
    },
    {
        "word": "아래",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "arae",
        "translation": "under"
    },
    {
        "word": "아르헨티나",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "areuhentina",
        "translation": "Argentina, argentinian"
    },
    {
        "word": "아름다운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "areumdaun",
        "translation": "beautiful"
    },
    {
        "word": "아무",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "amu",
        "translation": "any"
    },
    {
        "word": "아무도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "amudo",
        "translation": "anyone"
    },
    {
        "word": "아빠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "abba",
        "translation": "dad"
    },
    {
        "word": "아시아",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "asia",
        "translation": "asia"
    },
    {
        "word": "아싸",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "assa",
        "translation": "great, yay, hurray, yeah, hooray, alright"
    },
    {
        "word": "아이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ai",
        "translation": "a child, child"
    },
    {
        "word": "아이고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aigo",
        "translation": "oh no, oh my, oops"
    },
    {
        "word": "아이돌",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aidol",
        "translation": "idol"
    },
    {
        "word": "아이스크림",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aiseukeurim",
        "translation": "ice cream"
    },
    {
        "word": "아일랜드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ailraendeu",
        "translation": "ireland"
    },
    {
        "word": "아저씨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ajeossi",
        "translation": "mister"
    },
    {
        "word": "아주",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aju",
        "translation": "very, very much, so"
    },
    {
        "word": "아주머니",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ajumeoni",
        "translation": "ma'am"
    },
    {
        "word": "아직",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ajig",
        "translation": "still, yet"
    },
    {
        "word": "아침",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "acim",
        "translation": "breakfast, morning"
    },
    {
        "word": "아파트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "apateu",
        "translation": "apartment building, apartment"
    },
    {
        "word": "아프리카",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "apeurika",
        "translation": "africa"
    },
    {
        "word": "아픈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "apeun",
        "translation": "sore, ill, sick"
    },
    {
        "word": "아픔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "apeum",
        "translation": "pain"
    },
    {
        "word": "아홉",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ahob",
        "translation": "nine"
    },
    {
        "word": "아흐레",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aheure",
        "translation": "nine days, ninth (of a month), ninth"
    },
    {
        "word": "아흔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aheun",
        "translation": "ninety"
    },
    {
        "word": "안",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "an",
        "translation": "doesn't, do not, it is not, I don't, is not, don't, wasn't, didn't, does not, the inside, cannot, has not, weren't, he doesn't, she doesn't, not, won't, aren't, isn't"
    },
    {
        "word": "안개",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "angae",
        "translation": "fog"
    },
    {
        "word": "안겼다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "angyeossda",
        "translation": "was embraced"
    },
    {
        "word": "안경",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "angyeong",
        "translation": "glasses"
    },
    {
        "word": "안내",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "annae",
        "translation": "information"
    },
    {
        "word": "안내서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "annaeseo",
        "translation": ""
    },
    {
        "word": "안녕",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "annyeong",
        "translation": "hi, bye, hello, goodbye"
    },
    {
        "word": "안녕하세요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "annyeonghaseyo",
        "translation": "hello"
    },
    {
        "word": "안녕하십니까",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "annyeonghasibnigga",
        "translation": "hello"
    },
    {
        "word": "안녕히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "annyeonghi",
        "translation": "good, well"
    },
    {
        "word": "안무",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anmu",
        "translation": "Choreography"
    },
    {
        "word": "안아요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anayo",
        "translation": "hugs"
    },
    {
        "word": "안전",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anjeon",
        "translation": "safety"
    },
    {
        "word": "안주",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anju",
        "translation": "bar snacks"
    },
    {
        "word": "앉는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anjneun",
        "translation": "seated, sitting"
    },
    {
        "word": "앉습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anjseubnida",
        "translation": "sits, sit"
    },
    {
        "word": "않는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "anhneun",
        "translation": "not doing"
    },
    {
        "word": "알려",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "alryeo",
        "translation": "get"
    },
    {
        "word": "앞",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ap",
        "translation": "front"
    },
    {
        "word": "애인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "aein",
        "translation": "girlfriend, partner, lover, significant other, boyfriend"
    },
    {
        "word": "야",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ya",
        "translation": "s, hey, ya (sound)"
    },
    {
        "word": "야구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yagu",
        "translation": "baseball"
    },
    {
        "word": "야채",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yacae",
        "translation": "vegetable, vegetables"
    },
    {
        "word": "약",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yag",
        "translation": "medicine, about"
    },
    {
        "word": "약국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yaggug",
        "translation": "pharmacy"
    },
    {
        "word": "약사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yagsa",
        "translation": "pharmacist"
    },
    {
        "word": "양",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yang",
        "translation": "sheep, amount"
    },
    {
        "word": "양궁",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yanggung",
        "translation": "archery"
    },
    {
        "word": "양념",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yangnyeom",
        "translation": "seasoned, yangnyeom"
    },
    {
        "word": "양말",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yangmal",
        "translation": "sock, socks"
    },
    {
        "word": "양보",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yangbo",
        "translation": ""
    },
    {
        "word": "양복",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yangbog",
        "translation": "tuxedo, suit, suits"
    },
    {
        "word": "양식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yangsig",
        "translation": "food, Western food, style, language"
    },
    {
        "word": "어",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eo",
        "translation": "eo (sound), oh"
    },
    {
        "word": "어깨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eoggae",
        "translation": "shoulder"
    },
    {
        "word": "어느",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eoneu",
        "translation": "which"
    },
    {
        "word": "어디",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eodi",
        "translation": "where"
    },
    {
        "word": "어떤",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eoddeon",
        "translation": "some, any, which, what kind of"
    },
    {
        "word": "어떻게",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eoddeohge",
        "translation": "how"
    },
    {
        "word": "어려운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eoryeoun",
        "translation": "difficult"
    },
    {
        "word": "어제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eoje",
        "translation": "yesterday"
    },
    {
        "word": "어젯밤",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eojesbam",
        "translation": "last evening, yesterday night, last night, yesterday evening"
    },
    {
        "word": "억",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eog",
        "translation": "hundred million"
    },
    {
        "word": "언덕",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eondeog",
        "translation": "hill"
    },
    {
        "word": "언론",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eonron",
        "translation": "press, speech"
    },
    {
        "word": "언어",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eoneo",
        "translation": "languages, language, a language"
    },
    {
        "word": "언제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eonje",
        "translation": "when"
    },
    {
        "word": "얼굴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eolgul",
        "translation": "face"
    },
    {
        "word": "얼음",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eoleum",
        "translation": "ice"
    },
    {
        "word": "엄마",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eomma",
        "translation": "mom"
    },
    {
        "word": "엄청",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eomceong",
        "translation": "very, overly, too"
    },
    {
        "word": "없는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eobsneun",
        "translation": "without"
    },
    {
        "word": "없습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eobsseubnida",
        "translation": "do not, never, is not, there are no, do not exist, does not exist, am not, does not, are not, not, does not have, there is no, do not have"
    },
    {
        "word": "없을",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eobseul",
        "translation": "will not have"
    },
    {
        "word": "없이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eobsi",
        "translation": "without"
    },
    {
        "word": "에",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "e",
        "translation": "to, on, at, e (sound), in"
    },
    {
        "word": "에게",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ege",
        "translation": "to"
    },
    {
        "word": "에게서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "egeseo",
        "translation": "from"
    },
    {
        "word": "에서",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eseo",
        "translation": "from, at"
    },
    {
        "word": "에어컨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eeokeon",
        "translation": "air conditioner"
    },
    {
        "word": "엔지니어",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "enjinieo",
        "translation": "engineer"
    },
    {
        "word": "엘리베이터",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "elribeiteo",
        "translation": "lift, elevator"
    },
    {
        "word": "여권",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeogweon",
        "translation": "passport"
    },
    {
        "word": "여기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeogi",
        "translation": "here"
    },
    {
        "word": "여덟",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeodeolb",
        "translation": "eight"
    },
    {
        "word": "여동생",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeodongsaeng",
        "translation": "younger sister"
    },
    {
        "word": "여드레",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeodeure",
        "translation": "eight days"
    },
    {
        "word": "여든",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeodeun",
        "translation": "eighty"
    },
    {
        "word": "여름",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeoreum",
        "translation": "summer"
    },
    {
        "word": "여보",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeobo",
        "translation": "honey"
    },
    {
        "word": "여섯",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeoseos",
        "translation": "six"
    },
    {
        "word": "여우",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeou",
        "translation": "fox"
    },
    {
        "word": "여자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeoja",
        "translation": "the woman, woman, a woman"
    },
    {
        "word": "여자아이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeojaai",
        "translation": "girl"
    },
    {
        "word": "여행",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeohaeng",
        "translation": "trip"
    },
    {
        "word": "역",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeog",
        "translation": "station"
    },
    {
        "word": "역사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeogsa",
        "translation": "history"
    },
    {
        "word": "연구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeongu",
        "translation": "study, investigation, studies, research"
    },
    {
        "word": "연못",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeonmos",
        "translation": "pond"
    },
    {
        "word": "연세",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeonse",
        "translation": ""
    },
    {
        "word": "연습",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeonseub",
        "translation": "practice"
    },
    {
        "word": "연장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeonjang",
        "translation": ""
    },
    {
        "word": "연필",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeonpil",
        "translation": "a pencil, pencil"
    },
    {
        "word": "열",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeol",
        "translation": "heat, ten"
    },
    {
        "word": "열기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeolgi",
        "translation": "heat"
    },
    {
        "word": "열려",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeolryeo",
        "translation": "open"
    },
    {
        "word": "열쇠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeolsoe",
        "translation": "keys, key"
    },
    {
        "word": "열심히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeolsimhi",
        "translation": "diligently, hard"
    },
    {
        "word": "열흘",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeolheul",
        "translation": "ten days"
    },
    {
        "word": "엿새",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeossae",
        "translation": "six days"
    },
    {
        "word": "영국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeonggug",
        "translation": "U.K., British"
    },
    {
        "word": "영수증",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeongsujeung",
        "translation": "receipt"
    },
    {
        "word": "영어",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeongeo",
        "translation": "English, english"
    },
    {
        "word": "영화",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeonghwa",
        "translation": "picture, screen, film, movie"
    },
    {
        "word": "옆",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yeop",
        "translation": "side, next"
    },
    {
        "word": "예",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ye",
        "translation": "ye (sound), yes, example"
    },
    {
        "word": "예매",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yemae",
        "translation": "reservation, booking"
    },
    {
        "word": "예보",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yebo",
        "translation": ""
    },
    {
        "word": "예보관",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yebogwan",
        "translation": "forecaster"
    },
    {
        "word": "예쁜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yebbeun",
        "translation": "beautiful, pretty"
    },
    {
        "word": "예순",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yesun",
        "translation": "sixty"
    },
    {
        "word": "예정",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yejeong",
        "translation": "be scheduled to, be planned to, be supposed to, be expected to, Schedule"
    },
    {
        "word": "옛날",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yesnal",
        "translation": "the old times, the past, the old days"
    },
    {
        "word": "오",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "o",
        "translation": "five, 5, o (sound), o"
    },
    {
        "word": "오는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oneun",
        "translation": "coming"
    },
    {
        "word": "오늘",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oneul",
        "translation": "today"
    },
    {
        "word": "오랜만",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oraenman",
        "translation": ""
    },
    {
        "word": "오렌지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "orenji",
        "translation": "orange, an orange"
    },
    {
        "word": "오륜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oryun",
        "translation": "Olympic rings"
    },
    {
        "word": "오른쪽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oreunjjog",
        "translation": "right"
    },
    {
        "word": "오리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ori",
        "translation": "duck"
    },
    {
        "word": "오븐",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "obeun",
        "translation": "the oven"
    },
    {
        "word": "오빠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "obba",
        "translation": "brother"
    },
    {
        "word": "오이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oi",
        "translation": "cucumber"
    },
    {
        "word": "오전",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ojeon",
        "translation": "in the morning, a.m."
    },
    {
        "word": "오토바이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "otobai",
        "translation": "motorcycle"
    },
    {
        "word": "오후",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ohu",
        "translation": "afternoon"
    },
    {
        "word": "옥수수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ogsusu",
        "translation": "corn"
    },
    {
        "word": "온",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "on",
        "translation": "when ... came, who came, whole, having come, that came"
    },
    {
        "word": "온도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ondo",
        "translation": "temperature"
    },
    {
        "word": "온도계",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ondogye",
        "translation": "thermometer"
    },
    {
        "word": "올",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ol",
        "translation": "come, will come"
    },
    {
        "word": "올림픽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "olrimpig",
        "translation": "Olympics"
    },
    {
        "word": "올해",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "olhae",
        "translation": "this year"
    },
    {
        "word": "옷",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "os",
        "translation": "clothes"
    },
    {
        "word": "옷장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "osjang",
        "translation": "wardrobe"
    },
    {
        "word": "와",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "wa",
        "translation": "and, wa (sound), wow, with, come"
    },
    {
        "word": "와인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "wain",
        "translation": "wine"
    },
    {
        "word": "완전히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "wanjeonhi",
        "translation": "all, perfectly, completely, just"
    },
    {
        "word": "왜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "wae",
        "translation": "wae (sound), why"
    },
    {
        "word": "외과",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oegwa",
        "translation": "department of surgery, surgical"
    },
    {
        "word": "외국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oegug",
        "translation": "a foreign country, foreign"
    },
    {
        "word": "왼쪽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "oenjjog",
        "translation": "left"
    },
    {
        "word": "요리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yori",
        "translation": "dish, plate, cooking"
    },
    {
        "word": "요리법",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yoribeob",
        "translation": ""
    },
    {
        "word": "요리사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yorisa",
        "translation": "cook"
    },
    {
        "word": "요리책",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yoricaeg",
        "translation": "cookbooks"
    },
    {
        "word": "요일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yoil",
        "translation": "day"
    },
    {
        "word": "욕실",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yogsil",
        "translation": "Bathroom"
    },
    {
        "word": "욕조",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yogjo",
        "translation": "bathtub"
    },
    {
        "word": "용",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yong",
        "translation": "dragon, dragons"
    },
    {
        "word": "용품",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yongpum",
        "translation": "goods"
    },
    {
        "word": "우는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "uneun",
        "translation": "crying"
    },
    {
        "word": "우대석",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "udaeseog",
        "translation": "priority seating"
    },
    {
        "word": "우리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "uri",
        "translation": "our, we, my, us"
    },
    {
        "word": "우박",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ubag",
        "translation": "hail"
    },
    {
        "word": "우아",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ua",
        "translation": "wow, whoa"
    },
    {
        "word": "우연히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "uyeonhi",
        "translation": "by chance, by accident"
    },
    {
        "word": "우유",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "uyu",
        "translation": "milk"
    },
    {
        "word": "우의",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "uyi",
        "translation": "friendship, raincoat"
    },
    {
        "word": "우체부",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ucebu",
        "translation": "mail carrier"
    },
    {
        "word": "우회전",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "uhoejeon",
        "translation": ""
    },
    {
        "word": "운동",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "undong",
        "translation": "exercises, sport, exercise, sports"
    },
    {
        "word": "운동장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "undongjang",
        "translation": "Sports field, track field"
    },
    {
        "word": "운동화",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "undonghwa",
        "translation": "sneaker, sneakers"
    },
    {
        "word": "운전",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "unjeon",
        "translation": "driving"
    },
    {
        "word": "운전자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "unjeonja",
        "translation": "driver"
    },
    {
        "word": "울타리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ultari",
        "translation": "fence"
    },
    {
        "word": "웃는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "usneun",
        "translation": "smiling, who laugh, laughing"
    },
    {
        "word": "웃습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "usseubnida",
        "translation": "smile, laughs, laugh"
    },
    {
        "word": "워싱턴",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "weosingteon",
        "translation": "Washington"
    },
    {
        "word": "원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "weon",
        "translation": "circle, won"
    },
    {
        "word": "원피스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "weonpiseu",
        "translation": "dress"
    },
    {
        "word": "월",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "weol",
        "translation": "Monday, month"
    },
    {
        "word": "월요일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "weolyoil",
        "translation": "mondays, monday"
    },
    {
        "word": "위",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "wi",
        "translation": "on, top, stomach, above"
    },
    {
        "word": "위하여",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "wihayeo",
        "translation": "here's to that, for, cheers to that"
    },
    {
        "word": "위험",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "wiheom",
        "translation": "danger, risk, risks"
    },
    {
        "word": "유",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yu",
        "translation": "yu (sound)"
    },
    {
        "word": "유나이티드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yunaitideu",
        "translation": "United"
    },
    {
        "word": "유럽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yureob",
        "translation": "europe, european"
    },
    {
        "word": "유로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yuro",
        "translation": "Euro"
    },
    {
        "word": "유명한",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yumyeonghan",
        "translation": "famous"
    },
    {
        "word": "유명합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yumyeonghabnida",
        "translation": "be famous, is famous for, is famous"
    },
    {
        "word": "유치원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yuciweon",
        "translation": "kindergarten"
    },
    {
        "word": "유튜브",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yutyubeu",
        "translation": "YouTube"
    },
    {
        "word": "으로",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "euro",
        "translation": "to, toward, with"
    },
    {
        "word": "은",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eun",
        "translation": "is, silver"
    },
    {
        "word": "은행",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eunhaeng",
        "translation": "banks, bank"
    },
    {
        "word": "을",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eul",
        "translation": ""
    },
    {
        "word": "음반",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eumban",
        "translation": "album"
    },
    {
        "word": "음식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eumsig",
        "translation": "food, cook"
    },
    {
        "word": "음악",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eumag",
        "translation": "music"
    },
    {
        "word": "응",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eung",
        "translation": "yes, okay, yeah"
    },
    {
        "word": "응원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "eungweon",
        "translation": ""
    },
    {
        "word": "의",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yi",
        "translation": "of, 's"
    },
    {
        "word": "의미",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yimi",
        "translation": "meaning"
    },
    {
        "word": "의사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yisa",
        "translation": "doctor"
    },
    {
        "word": "의자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "yija",
        "translation": "chairs, chair"
    },
    {
        "word": "이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "i",
        "translation": "this, these, i (sound), two, tooth"
    },
    {
        "word": "이것",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "igeos",
        "translation": "this, it"
    },
    {
        "word": "이따",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "idda",
        "translation": ""
    },
    {
        "word": "이레",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ire",
        "translation": "seven days"
    },
    {
        "word": "이론",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "iron",
        "translation": "theory, theories"
    },
    {
        "word": "이름",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ireum",
        "translation": "first name, name, the name, names"
    },
    {
        "word": "이만큼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "imankeum",
        "translation": "this, this much"
    },
    {
        "word": "이모",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "imo",
        "translation": ""
    },
    {
        "word": "이미",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "imi",
        "translation": "already"
    },
    {
        "word": "이번",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ibeon",
        "translation": "this, these, this time"
    },
    {
        "word": "이스라엘",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "iseurael",
        "translation": "israel"
    },
    {
        "word": "이야기합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "iyagihabnida",
        "translation": "speaks, tell, talk, speak"
    },
    {
        "word": "이제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ije",
        "translation": "now"
    },
    {
        "word": "이집트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ijibteu",
        "translation": "Egypt, Egyptian"
    },
    {
        "word": "이쪽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ijjog",
        "translation": "this way"
    },
    {
        "word": "이틀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "iteul",
        "translation": "two days"
    },
    {
        "word": "이편",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ipyeon",
        "translation": "This side"
    },
    {
        "word": "이해",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ihae",
        "translation": ""
    },
    {
        "word": "인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "in",
        "translation": ""
    },
    {
        "word": "인기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ingi",
        "translation": "popularity"
    },
    {
        "word": "인도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "indo",
        "translation": "sidewalk, Indian, India"
    },
    {
        "word": "인분",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "inbun",
        "translation": "portions"
    },
    {
        "word": "인사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "insa",
        "translation": "greeting"
    },
    {
        "word": "인터넷",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "inteones",
        "translation": "internet"
    },
    {
        "word": "인터뷰",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "inteobyu",
        "translation": "interview"
    },
    {
        "word": "일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "il",
        "translation": "task, work, days, one, job, day"
    },
    {
        "word": "일곱",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilgob",
        "translation": "seven, 7"
    },
    {
        "word": "일기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilgi",
        "translation": ""
    },
    {
        "word": "일반",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilban",
        "translation": "homeroom 1"
    },
    {
        "word": "일본",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilbon",
        "translation": "Japan"
    },
    {
        "word": "일식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilsig",
        "translation": "Japanese Food, Japanese-style"
    },
    {
        "word": "일어서라",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ileoseora",
        "translation": "stand up"
    },
    {
        "word": "일요일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilyoil",
        "translation": "sundays, sunday"
    },
    {
        "word": "일찍",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "iljjig",
        "translation": "early"
    },
    {
        "word": "일흔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilheun",
        "translation": "seventy"
    },
    {
        "word": "읽는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilgneun",
        "translation": "reading"
    },
    {
        "word": "읽습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilgseubnida",
        "translation": "read, reads, are reading, is reading"
    },
    {
        "word": "읽습니다.",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ilgseubnida.",
        "translation": ""
    },
    {
        "word": "입",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ib",
        "translation": "mouth"
    },
    {
        "word": "입국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ibgug",
        "translation": "entry, entrance, an entrance to a country, entering a country"
    },
    {
        "word": "입는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ibneun",
        "translation": "wearing"
    },
    {
        "word": "입니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ibnida",
        "translation": "it's, is, am, are"
    },
    {
        "word": "있는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "issneun",
        "translation": "being, existing, with, having, who has"
    },
    {
        "word": "있습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "issseubnida",
        "translation": "there are, is, stay, are, have, in, has, there is"
    },
    {
        "word": "있을",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "isseul",
        "translation": "am"
    },
    {
        "word": "자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ja",
        "translation": "sleep, here, ja (sound)"
    },
    {
        "word": "자고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jago",
        "translation": "sleeps and, sleep and, sleeping"
    },
    {
        "word": "자기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jagi",
        "translation": "honey, self, his, darling"
    },
    {
        "word": "자는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "janeun",
        "translation": "ruler, sleeping"
    },
    {
        "word": "자동차",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jadongca",
        "translation": "car"
    },
    {
        "word": "자랑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jarang",
        "translation": "show-off"
    },
    {
        "word": "자루",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jaru",
        "translation": "(counter for long things)"
    },
    {
        "word": "자리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jari",
        "translation": "place"
    },
    {
        "word": "자매",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jamae",
        "translation": "sisters"
    },
    {
        "word": "자물쇠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jamulsoe",
        "translation": "lock"
    },
    {
        "word": "자식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jasig",
        "translation": "children, child"
    },
    {
        "word": "자신",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jasin",
        "translation": "himself, herself"
    },
    {
        "word": "자전거",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jajeongeo",
        "translation": "bicycle"
    },
    {
        "word": "자주",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jaju",
        "translation": "frequently, often, frequent, common"
    },
    {
        "word": "작가",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jagga",
        "translation": "writer, writers, author"
    },
    {
        "word": "작년",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jagnyeon",
        "translation": "Last year"
    },
    {
        "word": "작은",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jageun",
        "translation": "small, little"
    },
    {
        "word": "잔치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "janci",
        "translation": "party"
    },
    {
        "word": "잘",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jal",
        "translation": "well"
    },
    {
        "word": "잘려요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jalryeoyo",
        "translation": "is cut"
    },
    {
        "word": "잠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jam",
        "translation": "slumber, sleep"
    },
    {
        "word": "잠겼다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jamgyeossda",
        "translation": "locked"
    },
    {
        "word": "잡지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jabji",
        "translation": "magazine"
    },
    {
        "word": "잡혔습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jabhyeossseubnida",
        "translation": "was caught"
    },
    {
        "word": "장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jang",
        "translation": "newspapers, counter, page"
    },
    {
        "word": "장갑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "janggab",
        "translation": "gloves, glove"
    },
    {
        "word": "장난감",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jangnangam",
        "translation": "toy"
    },
    {
        "word": "장바구니",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jangbaguni",
        "translation": "shopping basket"
    },
    {
        "word": "장소",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jangso",
        "translation": "the place, place"
    },
    {
        "word": "재료",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jaeryo",
        "translation": "material"
    },
    {
        "word": "재미없는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jaemieobsneun",
        "translation": "boring"
    },
    {
        "word": "재미없습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jaemieobsseubnida",
        "translation": "is not fun, uninteresting, is boring, are boring"
    },
    {
        "word": "재미있는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jaemiissneun",
        "translation": "interesting"
    },
    {
        "word": "재미있다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jaemiissda",
        "translation": "funny, is interesting, to be interesting"
    },
    {
        "word": "재미있습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jaemiissseubnida",
        "translation": "are interesting, is interesting, is fun"
    },
    {
        "word": "저",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeo",
        "translation": "I, that (over there), those (over there), me"
    },
    {
        "word": "저것",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeogeos",
        "translation": "that (over there), that"
    },
    {
        "word": "저기",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeogi",
        "translation": "over there, there"
    },
    {
        "word": "저기요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeogiyo",
        "translation": "excuse me, hey"
    },
    {
        "word": "저녁",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeonyeog",
        "translation": "dinner, evening"
    },
    {
        "word": "저만큼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeomankeum",
        "translation": "to that extent, that much, that"
    },
    {
        "word": "저쪽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeojjog",
        "translation": "that way over there, that way"
    },
    {
        "word": "저편",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeopyeon",
        "translation": "That side over there"
    },
    {
        "word": "적",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeog",
        "translation": "enemy, red"
    },
    {
        "word": "적습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeogseubnida",
        "translation": "write, writes, few/small, there are few"
    },
    {
        "word": "전",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeon",
        "translation": "I, ago"
    },
    {
        "word": "전자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeonja",
        "translation": ""
    },
    {
        "word": "전통",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeontong",
        "translation": "traditional, tradition"
    },
    {
        "word": "전혀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeonhyeo",
        "translation": "at all, anything"
    },
    {
        "word": "전화",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeonhwa",
        "translation": "call, telephone"
    },
    {
        "word": "점",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeom",
        "translation": "respects, points, dot"
    },
    {
        "word": "점수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeomsu",
        "translation": "grade, points"
    },
    {
        "word": "점심",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeomsim",
        "translation": "lunch"
    },
    {
        "word": "접시",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeobsi",
        "translation": "dish, plate, the plate, dishes"
    },
    {
        "word": "젓가락",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeosgarag",
        "translation": "chopsticks"
    },
    {
        "word": "정",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeong",
        "translation": "affection"
    },
    {
        "word": "정류장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeongryujang",
        "translation": "station, stop"
    },
    {
        "word": "정말",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeongmal",
        "translation": "really"
    },
    {
        "word": "정원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeongweon",
        "translation": "garden"
    },
    {
        "word": "정육",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeongyug",
        "translation": ""
    },
    {
        "word": "제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "je",
        "translation": "my"
    },
    {
        "word": "제공",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jegong",
        "translation": ""
    },
    {
        "word": "제발",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jebal",
        "translation": "please"
    },
    {
        "word": "제빵사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jebbangsa",
        "translation": "baker"
    },
    {
        "word": "제일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeil",
        "translation": "most, the most"
    },
    {
        "word": "조",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jo",
        "translation": "trillion"
    },
    {
        "word": "조금",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jogeum",
        "translation": "little, a bit of, a bit, some, a little bit of, a little, bit"
    },
    {
        "word": "조련",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "joryeon",
        "translation": "training"
    },
    {
        "word": "조리대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "joridae",
        "translation": "(kitchen) counter"
    },
    {
        "word": "조사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "josa",
        "translation": "studies"
    },
    {
        "word": "조용히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "joyonghi",
        "translation": "quietly"
    },
    {
        "word": "조종",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jojong",
        "translation": ""
    },
    {
        "word": "조종사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jojongsa",
        "translation": "pilot"
    },
    {
        "word": "졸업",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "joleob",
        "translation": "Commencement, Graduation"
    },
    {
        "word": "좀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jom",
        "translation": "a bit, little, some"
    },
    {
        "word": "좁습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jobseubnida",
        "translation": "is narrow, is small"
    },
    {
        "word": "종업원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jongeobweon",
        "translation": "waiter, [Waiter/Waitress]"
    },
    {
        "word": "종이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jongi",
        "translation": "paper"
    },
    {
        "word": "좋은",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "joheun",
        "translation": "good, nice"
    },
    {
        "word": "좌회전",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jwahoejeon",
        "translation": "turn left"
    },
    {
        "word": "죄송합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "joesonghabnida",
        "translation": "sorry, I am sorry"
    },
    {
        "word": "주",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ju",
        "translation": "week, ju (sound)"
    },
    {
        "word": "주는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "juneun",
        "translation": "giving"
    },
    {
        "word": "주말",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jumal",
        "translation": "the weekend"
    },
    {
        "word": "주방",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jubang",
        "translation": "kitchen"
    },
    {
        "word": "주스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "juseu",
        "translation": "juice"
    },
    {
        "word": "주의",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "juyi",
        "translation": "attention"
    },
    {
        "word": "주인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "juin",
        "translation": ""
    },
    {
        "word": "주황",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "juhwang",
        "translation": ""
    },
    {
        "word": "죽음",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jugeum",
        "translation": "death"
    },
    {
        "word": "준",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jun",
        "translation": "which ... gave, on which ... gave"
    },
    {
        "word": "준비",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "junbi",
        "translation": "ready, preparation"
    },
    {
        "word": "줄",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jul",
        "translation": "roll, how to, string, row"
    },
    {
        "word": "중",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jung",
        "translation": "during, under"
    },
    {
        "word": "중국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "junggug",
        "translation": "China, Chinese"
    },
    {
        "word": "중학교",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "junghaggyo",
        "translation": "middle school"
    },
    {
        "word": "중학생",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "junghagsaeng",
        "translation": "middle school student"
    },
    {
        "word": "쥐",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jwi",
        "translation": "mouse"
    },
    {
        "word": "증",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jeung",
        "translation": "card"
    },
    {
        "word": "지갑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jigab",
        "translation": "wallet"
    },
    {
        "word": "지구본",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jigubon",
        "translation": "globe"
    },
    {
        "word": "지금",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jigeum",
        "translation": "currently, right now, just now, at [the/this] moment, now, present"
    },
    {
        "word": "지난",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jinan",
        "translation": "past, last"
    },
    {
        "word": "지도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jido",
        "translation": "map"
    },
    {
        "word": "지만",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jiman",
        "translation": "but"
    },
    {
        "word": "지불",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jibul",
        "translation": "pay"
    },
    {
        "word": "지붕",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jibung",
        "translation": "roof"
    },
    {
        "word": "지역",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jiyeog",
        "translation": "neighborhood, district, zone, area, country, region, field, local"
    },
    {
        "word": "지우개",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jiugae",
        "translation": "eraser"
    },
    {
        "word": "지하철",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jihaceol",
        "translation": "underground, tube, subway"
    },
    {
        "word": "지훈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jihun",
        "translation": "Jihun"
    },
    {
        "word": "직업",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jigeob",
        "translation": "job, profession"
    },
    {
        "word": "직장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jigjang",
        "translation": "job, jobs"
    },
    {
        "word": "직진",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jigjin",
        "translation": ""
    },
    {
        "word": "진단",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jindan",
        "translation": "diagnosis"
    },
    {
        "word": "진압하",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jinabha",
        "translation": ""
    },
    {
        "word": "진지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jinji",
        "translation": "rice, meal"
    },
    {
        "word": "진짜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jinjja",
        "translation": "real, truly, so, really"
    },
    {
        "word": "질문",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jilmun",
        "translation": "question"
    },
    {
        "word": "질투",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jiltu",
        "translation": "Jealousy"
    },
    {
        "word": "짐",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jim",
        "translation": "load, baggage, baggages, luggage"
    },
    {
        "word": "집",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jib",
        "translation": "homes, a house, house, place, a home, home"
    },
    {
        "word": "짜증",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jjajeung",
        "translation": ""
    },
    {
        "word": "짝",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jjag",
        "translation": "(one half of a pair)"
    },
    {
        "word": "짠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jjan",
        "translation": "salty"
    },
    {
        "word": "짧은",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jjalbeun",
        "translation": "short"
    },
    {
        "word": "쪽",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jjog",
        "translation": "page"
    },
    {
        "word": "찌개",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jjigae",
        "translation": "soup, stew"
    },
    {
        "word": "찜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "jjim",
        "translation": ""
    },
    {
        "word": "차",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ca",
        "translation": "tea, cha (sound), car"
    },
    {
        "word": "차가운",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cagaun",
        "translation": "cold"
    },
    {
        "word": "차고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cago",
        "translation": "garage"
    },
    {
        "word": "차는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "caneun",
        "translation": "wearing, tea, kicking, car, who kicks, put on"
    },
    {
        "word": "찬장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "canjang",
        "translation": "cupboard"
    },
    {
        "word": "찰",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cal",
        "translation": "kick"
    },
    {
        "word": "참",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cam",
        "translation": "truly, really"
    },
    {
        "word": "창문",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cangmun",
        "translation": "window"
    },
    {
        "word": "채",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cae",
        "translation": "racket, while, rackets"
    },
    {
        "word": "채식주의자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "caesigjuyija",
        "translation": "vegetarians, vegetarian"
    },
    {
        "word": "책",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "caeg",
        "translation": "a book, books, book"
    },
    {
        "word": "책상",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "caegsang",
        "translation": "desk"
    },
    {
        "word": "처음 뵙겠습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceoeum boebgessseubnida",
        "translation": "nice to meet you"
    },
    {
        "word": "천",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceon",
        "translation": "thousand"
    },
    {
        "word": "천둥",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceondung",
        "translation": "thunder"
    },
    {
        "word": "천만에요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceonmaneyo",
        "translation": "you are welcome, no problem"
    },
    {
        "word": "천천히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceonceonhi",
        "translation": "slowly, take time to"
    },
    {
        "word": "첫",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceos",
        "translation": "first"
    },
    {
        "word": "청각",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceonggag",
        "translation": "(sense of) hearing"
    },
    {
        "word": "청소",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceongso",
        "translation": "cleaning"
    },
    {
        "word": "체육관",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceyuggwan",
        "translation": "gym"
    },
    {
        "word": "체코",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceko",
        "translation": "Czech Republic"
    },
    {
        "word": "초",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "co",
        "translation": "seconds, second"
    },
    {
        "word": "초등학교",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "codeunghaggyo",
        "translation": "elementary school"
    },
    {
        "word": "초등학생",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "codeunghagsaeng",
        "translation": "elementary school student"
    },
    {
        "word": "초록",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "corog",
        "translation": "green"
    },
    {
        "word": "초원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "coweon",
        "translation": "grassland"
    },
    {
        "word": "초콜릿",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cokolris",
        "translation": "chocolate"
    },
    {
        "word": "촉각",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "coggag",
        "translation": "somatosensation, tactition, (sense of) touch"
    },
    {
        "word": "추는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cuneun",
        "translation": "dancing"
    },
    {
        "word": "추천",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cuceon",
        "translation": "recommend"
    },
    {
        "word": "추측",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cuceug",
        "translation": "assumed, estimate, assumption, guess"
    },
    {
        "word": "축구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cuggu",
        "translation": "soccer, football"
    },
    {
        "word": "출국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "culgug",
        "translation": "exit"
    },
    {
        "word": "출발",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "culbal",
        "translation": "departure"
    },
    {
        "word": "출신",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "culsin",
        "translation": "origin"
    },
    {
        "word": "출장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "culjang",
        "translation": "business trip"
    },
    {
        "word": "춤",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cum",
        "translation": "dance"
    },
    {
        "word": "춥습니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cubseubnida",
        "translation": "is cold, it is cold"
    },
    {
        "word": "취미",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cwimi",
        "translation": "interests, hobby"
    },
    {
        "word": "층",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ceung",
        "translation": "levels, floor"
    },
    {
        "word": "치과",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cigwa",
        "translation": "dentist"
    },
    {
        "word": "치료",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ciryo",
        "translation": "treatment"
    },
    {
        "word": "치마",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cima",
        "translation": "skirt, skirts"
    },
    {
        "word": "치맥",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cimaeg",
        "translation": ""
    },
    {
        "word": "치약",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ciyag",
        "translation": "toothpaste, toothbrush and"
    },
    {
        "word": "치즈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cijeu",
        "translation": "cheese"
    },
    {
        "word": "치킨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cikin",
        "translation": "chicken"
    },
    {
        "word": "친구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cingu",
        "translation": "friend"
    },
    {
        "word": "친한",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cinhan",
        "translation": "close"
    },
    {
        "word": "칠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cil",
        "translation": "seven"
    },
    {
        "word": "칠판",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cilpan",
        "translation": "blackboard, board"
    },
    {
        "word": "침대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cimdae",
        "translation": "bed, the bed"
    },
    {
        "word": "침실",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cimsil",
        "translation": "bedroom"
    },
    {
        "word": "칫솔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "cissol",
        "translation": "toothbrush"
    },
    {
        "word": "카드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "kadeu",
        "translation": "cards, card"
    },
    {
        "word": "카메라",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "kamera",
        "translation": "camera"
    },
    {
        "word": "카트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "kateu",
        "translation": "carts, cart"
    },
    {
        "word": "카페",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "kape",
        "translation": "a cafe, cafe, the cafe"
    },
    {
        "word": "칼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "kal",
        "translation": "knives, a knife, knife"
    },
    {
        "word": "캐나다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "kaenada",
        "translation": "Canada, Canadian"
    },
    {
        "word": "커피",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "keopi",
        "translation": "coffee"
    },
    {
        "word": "컴퓨터",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "keompyuteo",
        "translation": "computers, computer"
    },
    {
        "word": "케이크",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "keikeu",
        "translation": "cake, cakes"
    },
    {
        "word": "케이팝",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "keipab",
        "translation": "K-pop"
    },
    {
        "word": "코",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ko",
        "translation": "ko (sound), nose, noses"
    },
    {
        "word": "코끼리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "koggiri",
        "translation": "elephant"
    },
    {
        "word": "코치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "koci",
        "translation": "coach"
    },
    {
        "word": "코트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "koteu",
        "translation": "coat, court"
    },
    {
        "word": "콘서트",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "konseoteu",
        "translation": "concert"
    },
    {
        "word": "콜라",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "kolra",
        "translation": "cola"
    },
    {
        "word": "콧수염",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "kossuyeom",
        "translation": ""
    },
    {
        "word": "큰",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "keun",
        "translation": "large, big"
    },
    {
        "word": "키",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ki",
        "translation": "keys, a key, key"
    },
    {
        "word": "타는",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "taneun",
        "translation": "to ride, with"
    },
    {
        "word": "탁구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "taggu",
        "translation": "table tennis, ping pong"
    },
    {
        "word": "탈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "tal",
        "translation": "ride, get into"
    },
    {
        "word": "탈의",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "talyi",
        "translation": ""
    },
    {
        "word": "탐험하자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "tamheomhaja",
        "translation": "let's explore"
    },
    {
        "word": "탑승",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "tabseung",
        "translation": "boarding, The boarding"
    },
    {
        "word": "탕",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "tang",
        "translation": "soup"
    },
    {
        "word": "태국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "taegug",
        "translation": "Thailand, Thai"
    },
    {
        "word": "태권도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "taegweondo",
        "translation": "Taekwondo"
    },
    {
        "word": "태풍",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "taepung",
        "translation": "typhoon"
    },
    {
        "word": "택시",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "taegsi",
        "translation": "taxi"
    },
    {
        "word": "터널",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "teoneol",
        "translation": ""
    },
    {
        "word": "터미널",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "teomineol",
        "translation": "terminal"
    },
    {
        "word": "터키",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "teoki",
        "translation": "turkey"
    },
    {
        "word": "테니스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "teniseu",
        "translation": "tennis"
    },
    {
        "word": "텔레비전",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "telrebijeon",
        "translation": "tv, television"
    },
    {
        "word": "토끼",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "toggi",
        "translation": "a rabbit, rabbit"
    },
    {
        "word": "토마토",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "tomato",
        "translation": "tomato, a tomato"
    },
    {
        "word": "토요일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "toyoil",
        "translation": "saturdays, saturday"
    },
    {
        "word": "톱",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "tob",
        "translation": ""
    },
    {
        "word": "퇴직",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "toejig",
        "translation": "Retirement"
    },
    {
        "word": "특히",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "teughi",
        "translation": "in particular, especially"
    },
    {
        "word": "틀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "teul",
        "translation": "turn on"
    },
    {
        "word": "틀었어요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "teuleosseoyo",
        "translation": "did turn on"
    },
    {
        "word": "팀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "tim",
        "translation": "team, teams"
    },
    {
        "word": "파",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pa",
        "translation": "pa (sound), green onion, scallion"
    },
    {
        "word": "파란",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "paran",
        "translation": "blue"
    },
    {
        "word": "파랑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "parang",
        "translation": "blue"
    },
    {
        "word": "파리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pari",
        "translation": "Paris"
    },
    {
        "word": "파스타",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "paseuta",
        "translation": "pasta"
    },
    {
        "word": "파이팅",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "paiting",
        "translation": "go, good luck"
    },
    {
        "word": "파티",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pati",
        "translation": "party"
    },
    {
        "word": "판매",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "panmae",
        "translation": "sales"
    },
    {
        "word": "팔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pal",
        "translation": "sell, eight, arm"
    },
    {
        "word": "팔꿈치",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "palggumci",
        "translation": "elbow"
    },
    {
        "word": "패스트푸드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "paeseuteupudeu",
        "translation": "Fast food"
    },
    {
        "word": "팬",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "paen",
        "translation": "pans, fans, pan"
    },
    {
        "word": "퍼즐",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "peojeul",
        "translation": ""
    },
    {
        "word": "페루",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "peru",
        "translation": "Peru"
    },
    {
        "word": "펜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pen",
        "translation": "pen, pens"
    },
    {
        "word": "편의점",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pyeonyijeom",
        "translation": "convenience store"
    },
    {
        "word": "편지",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pyeonji",
        "translation": "letter"
    },
    {
        "word": "평일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pyeongil",
        "translation": "weekday"
    },
    {
        "word": "평창",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pyeongcang",
        "translation": "Pyeongchang"
    },
    {
        "word": "폐막식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pyemagsig",
        "translation": "closing ceremony"
    },
    {
        "word": "포르투갈",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "poreutugal",
        "translation": "portugal"
    },
    {
        "word": "포크",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pokeu",
        "translation": "fork, forks"
    },
    {
        "word": "포함",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "poham",
        "translation": ""
    },
    {
        "word": "폭풍",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pogpung",
        "translation": "storm, the storm"
    },
    {
        "word": "폴란드",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "polrandeu",
        "translation": "Poland"
    },
    {
        "word": "표",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pyo",
        "translation": "vote, pyo (sound), ticket"
    },
    {
        "word": "푸른",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pureun",
        "translation": "blue, green"
    },
    {
        "word": "풀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pul",
        "translation": "grass"
    },
    {
        "word": "품",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pum",
        "translation": ""
    },
    {
        "word": "풍경",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "punggyeong",
        "translation": "landscape"
    },
    {
        "word": "프랑스",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "peurangseu",
        "translation": "French, France"
    },
    {
        "word": "플라스틱",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "peulraseutig",
        "translation": "plastic"
    },
    {
        "word": "피",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pi",
        "translation": "blood"
    },
    {
        "word": "피겨",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pigyeo",
        "translation": "figure"
    },
    {
        "word": "피부",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pibu",
        "translation": "skin"
    },
    {
        "word": "피자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pija",
        "translation": "the pizza, pizza"
    },
    {
        "word": "필요",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "pilyo",
        "translation": "need, necessary"
    },
    {
        "word": "하고",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hago",
        "translation": "and, do"
    },
    {
        "word": "하늘",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haneul",
        "translation": "the sky, sky, air"
    },
    {
        "word": "하루",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haru",
        "translation": "one day"
    },
    {
        "word": "하얀",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hayan",
        "translation": "white"
    },
    {
        "word": "하양",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hayang",
        "translation": "white"
    },
    {
        "word": "하지만",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hajiman",
        "translation": "however, but"
    },
    {
        "word": "하키",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haki",
        "translation": "hockey"
    },
    {
        "word": "학",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hag",
        "translation": ""
    },
    {
        "word": "학교",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haggyo",
        "translation": "school, a school"
    },
    {
        "word": "학급",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haggeub",
        "translation": "class"
    },
    {
        "word": "학년",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hagnyeon",
        "translation": "grade"
    },
    {
        "word": "학생",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hagsaeng",
        "translation": "pupil, student"
    },
    {
        "word": "한",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "han",
        "translation": "a, one, done, an"
    },
    {
        "word": "한국",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hangug",
        "translation": "Korean, Korea"
    },
    {
        "word": "한국어",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hangugeo",
        "translation": "Korean"
    },
    {
        "word": "한글",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hangeul",
        "translation": "Korean alphabet, Hangeul"
    },
    {
        "word": "한류",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hanryu",
        "translation": "Korean wave"
    },
    {
        "word": "한복",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hanbog",
        "translation": "hanbok"
    },
    {
        "word": "한식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hansig",
        "translation": "Korean food"
    },
    {
        "word": "한파",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hanpa",
        "translation": "cold snap"
    },
    {
        "word": "할",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hal",
        "translation": "do, to do, speak"
    },
    {
        "word": "할인",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "halin",
        "translation": "discount, sale, offer"
    },
    {
        "word": "함께",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hamgge",
        "translation": "with, together"
    },
    {
        "word": "합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "habnida",
        "translation": "we should, do, plays, has to, should, need to, say, does, have, eats, eat, speak"
    },
    {
        "word": "항공사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hanggongsa",
        "translation": "airline"
    },
    {
        "word": "항구",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hanggu",
        "translation": "port"
    },
    {
        "word": "항상",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hangsang",
        "translation": "always"
    },
    {
        "word": "항해",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hanghae",
        "translation": "voyage, sail, sailing"
    },
    {
        "word": "해",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hae",
        "translation": "sun, hae (sound), do"
    },
    {
        "word": "해변",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haebyeon",
        "translation": "beach, beaches, coast"
    },
    {
        "word": "해수욕장",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haesuyogjang",
        "translation": ""
    },
    {
        "word": "해안",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haean",
        "translation": "coast"
    },
    {
        "word": "햄버거",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haembeogeo",
        "translation": "hamburger"
    },
    {
        "word": "햄스터",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haemseuteo",
        "translation": "hamsters"
    },
    {
        "word": "햇빛",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haesbic",
        "translation": "sunlight"
    },
    {
        "word": "행",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "haeng",
        "translation": ""
    },
    {
        "word": "허리",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "heori",
        "translation": "waist"
    },
    {
        "word": "허리띠",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "heoriddi",
        "translation": ""
    },
    {
        "word": "현금",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyeongeum",
        "translation": "cash"
    },
    {
        "word": "현대",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyeondae",
        "translation": "Hyundai"
    },
    {
        "word": "협곡",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyeobgog",
        "translation": "gorge"
    },
    {
        "word": "형",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyeong",
        "translation": "(older) brother"
    },
    {
        "word": "형광펜",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyeonggwangpen",
        "translation": "highlighter"
    },
    {
        "word": "형사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyeongsa",
        "translation": "detective"
    },
    {
        "word": "형제",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyeongje",
        "translation": "brothers"
    },
    {
        "word": "호",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "ho",
        "translation": ""
    },
    {
        "word": "호랑이",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "horangi",
        "translation": "tiger, tigers"
    },
    {
        "word": "호박",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hobag",
        "translation": "pumpkin"
    },
    {
        "word": "호수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hosu",
        "translation": "lake, the lake"
    },
    {
        "word": "호주",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hoju",
        "translation": "Australia"
    },
    {
        "word": "호텔",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hotel",
        "translation": "hotel"
    },
    {
        "word": "혼자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "honja",
        "translation": "alone, by myself, by oneself"
    },
    {
        "word": "홍수",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hongsu",
        "translation": "flood, floods"
    },
    {
        "word": "화",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwa",
        "translation": "anger"
    },
    {
        "word": "화가",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwaga",
        "translation": "anger, painter"
    },
    {
        "word": "화살",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwasal",
        "translation": "arrows, arrow"
    },
    {
        "word": "화요일",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwayoil",
        "translation": "Tuesday"
    },
    {
        "word": "화장실",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwajangsil",
        "translation": "the bathroom, bathrooms"
    },
    {
        "word": "화재",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwajae",
        "translation": "fire, blaze"
    },
    {
        "word": "확실",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwagsil",
        "translation": ""
    },
    {
        "word": "환갑",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwangab",
        "translation": "Sixtieth birthday, Sixtieth"
    },
    {
        "word": "환영합니다",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwanyeonghabnida",
        "translation": "welcome, You are welcome."
    },
    {
        "word": "환자",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwanja",
        "translation": "patient"
    },
    {
        "word": "활",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwal",
        "translation": "bow"
    },
    {
        "word": "황사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hwangsa",
        "translation": "yellow dust"
    },
    {
        "word": "회",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hoe",
        "translation": ""
    },
    {
        "word": "회사",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hoesa",
        "translation": "firm, company, office"
    },
    {
        "word": "회사원",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hoesaweon",
        "translation": "employee, businessman"
    },
    {
        "word": "횡단보도",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hoengdanbodo",
        "translation": ""
    },
    {
        "word": "후",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hu",
        "translation": "after"
    },
    {
        "word": "후각",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hugag",
        "translation": "(sense of) smell"
    },
    {
        "word": "후식",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "husig",
        "translation": ""
    },
    {
        "word": "후추",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hucu",
        "translation": "pepper"
    },
    {
        "word": "훈련",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hunryeon",
        "translation": "training"
    },
    {
        "word": "훨씬",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hweolssin",
        "translation": "far, even, by far, much, a lot"
    },
    {
        "word": "휴대폰",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyudaepon",
        "translation": "cell phone, a cell phone"
    },
    {
        "word": "휴지통",
        "partOfSpeech": "",
        "gender": "",
        "transcription": "hyujitong",
        "translation": "trashcan"
    }
]

export default wordList;