import React from "react";
import {
  Card,
  CardGroup,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import linkchimpImage from "../language-learning-immersion-extension.png";

import AppMetaDataContext from "../context/AppMetaDataContext";

function LearnDuolingoVocabularyMainCTA() {
  const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
        <Row style={{ margin: "0% 3%", marginBottom: "5%" }}>
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <h4 className="display-5 mb-5">
              Learn{" "}
              <span style={{ color: "#58cc02", fontWeight: "bold" }}>
                duolingo
              </span>{" "}
              vocabulary while browsing the web
            </h4>
            <h4>
              Translate text on webpages based on your Duolingo vocabulary using
              our Chrome extension
            </h4>
            <br />
            {/* <a
              href="https://chrome.google.com/webstore/detail/duolingo-champion/cdiecbgkdbkloiniekbfblbdbjgehpgb"
              target="_blank"
              rel="noreferrer"
            > */}
              <LinkContainer to="/register">
                <Button size="lg" className="me-3">
                  Try for free
                </Button>
              </LinkContainer>
            {/* </a> */}
            <LinkContainer to="/register">
              <Button size="lg" variant="success">
                Sign up
              </Button>
            </LinkContainer>
            <br />
            <br />
            <p>No credit card required.</p>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 2 }}
            style={{ paddingBottom: "5%" }}
          >
            <img src={linkchimpImage} alt="Logo" className="img-fluid" />
          </Col>
        </Row>
        <br />
        <br />
        <hr />
      </div>
    );
}

export default LearnDuolingoVocabularyMainCTA;
