import React, { useState, useEffect } from "react";
import { Button, Card, Alert, Badge } from "react-bootstrap";
import ReactGA from "react-ga4";

function registerAdClick() {
        ReactGA.event({
          category: "navigation",
          action: "click_ad",
          label: "ad_2", // optional
          // value: 99, // optional, must be a number
          // nonInteraction: false, // optional, true/false
          // transport: "xhr", // optional, beacon/xhr/image
        });
}

function BilingualNewsInSpanishAd() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-V2T4J6Y9YR");
      window.GA_INITIALIZED = true;
    }
  }, []);

  if (show) {
    return (
      <center>
        <Alert
          variant="light"
          className="mt-4 mb-4"
          style={{ border: "1px gray solid", maxWidth: "700px" }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Card className="border-0">
            {/* <Card.Img variant="top" src={logo192} /> */}
            {/* <Card.Img variant="top" src={logo192} /> */}
            <Card.Body className="mx-1">
              <Card.Title
                style={{
                  fontWeight: "normal",
                  fontSize: "2.4em",
                  textWrap: "balance",
                }}
                className="my-4"
              >
                Bilingual News in Spanish podcast{" "}
                <sup>
                  <Badge bg="success" pill className="mt-2" style={{fontSize: "0.5em"}}>
                    New!
                  </Badge>
                </sup>
              </Card.Title>
              <Card.Text style={{ textWrap: "balance" }}>
                Improve your Spanish. Weekly news podcasts in
                Spanish with English narration and transcripts. Real content, real fluency.
              </Card.Text>
              <Button
                variant="success"
                className="my-2"
                href="https://bilingualnews.net"
              >
                Check it out
              </Button>
            </Card.Body>
          </Card>
        </Alert>
      </center>
    );
  }
  return (
    <Button onClick={() => setShow(true)} variant="light">
      Show ad
    </Button>
  );
}

export default BilingualNewsInSpanishAd;
