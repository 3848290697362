import React from "react";
import {
  Container,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

import socialImage from "../duolingo-ninja-social-sharing.png";

import LearnDuolingoVocabularyMainCTA from "../Components/LearnDuolingoVocabularyMainCTA";
import HowItWorks from "../Components/HowItWorks";
import Benefits from "../Components/Benefits";
import Customize from "../Components/Customize";
import FounderQuote from "../Components/FounderQuote";
import DownloadExtension from "../Components/DownloadExtension";
import WhyDuolingoIsNotEnough from "../Components/WhyDuolingoIsNotEnough";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>{metaData.metaTitle}</title>
        <meta
          name="description"
          key="description"
          content={metaData.metaDesc}
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <WhyDuolingoIsNotEnough />
        <Benefits />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
