import React from "react";
import {
  Container,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../../context/AppMetaDataContext";


import socialImage from "../../duolingo-ninja-social-sharing.png";

import DuolingoNinjaAd from "../../Components/DuolingoNinjaAd";

import LearnDuolingoVocabularyMainCTA from "../../Components/LearnDuolingoVocabularyMainCTA";
import HowItWorks from "../../Components/HowItWorks";
import Benefits from "../../Components/Benefits";
import Customize from "../../Components/Customize";
import FounderQuote from "../../Components/FounderQuote";
import DownloadExtension from "../../Components/DownloadExtension";
import DownloadVocabularyWithExtension from "../../Components/DownloadVocabularyWithExtension";
import WordListTable from "./WordListTable";
import VocabularyHeading from "./VocabularyHeading";
import WhyDuolingoIsNotEnough from "../../Components/WhyDuolingoIsNotEnough";


function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  const language = "Japanese";
  const helmetMetaContent = `The full list of ${language} vocabulary from Duolingo. Plus the list of words you've learned with Duolingo. Including word gender and part of speech.`;
  return (
    <>
      <Helmet>
        <title>
          Duolingo {language} Vocabulary List - {metaData.appName}
        </title>
        <meta
          name="description"
          key="description"
          content={helmetMetaContent}
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <DuolingoNinjaAd />

        <VocabularyHeading language={language} />

        <WordListTable language={language} />

        <DownloadVocabularyWithExtension />
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <WhyDuolingoIsNotEnough />
        <Benefits />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
