const wordList = [
  {
    "word": "ああ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aa",
    "translation": "ah, oh"
  },
  {
    "word": "あい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ai",
    "translation": "love"
  },
  {
    "word": "あいつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aitsu",
    "translation": "that guy"
  },
  {
    "word": "あいて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aite",
    "translation": "open"
  },
  {
    "word": "あいにく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ainiku",
    "translation": "unfortunately"
  },
  {
    "word": "あお",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ao",
    "translation": "blue"
  },
  {
    "word": "あか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aka",
    "translation": "red"
  },
  {
    "word": "あき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aki",
    "translation": "autumn, open, fall"
  },
  {
    "word": "あきはばら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "akihabara",
    "translation": "Akihabara"
  },
  {
    "word": "あけ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ake",
    "translation": "open, opened (and)"
  },
  {
    "word": "あけて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "akete",
    "translation": "open, opened, opened (and)"
  },
  {
    "word": "あげ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "age",
    "translation": "let"
  },
  {
    "word": "あさ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asa",
    "translation": "mornings, morning"
  },
  {
    "word": "あさごはん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asagohan",
    "translation": "breakfast"
  },
  {
    "word": "あさって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asatte",
    "translation": "the day after tomorrow, in two days"
  },
  {
    "word": "あし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ashi",
    "translation": "foot, feet, legs, leg"
  },
  {
    "word": "あした",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ashita",
    "translation": "tomorrow"
  },
  {
    "word": "あそこ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asoko",
    "translation": "(over) there"
  },
  {
    "word": "あそび",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asobi",
    "translation": "play, playing"
  },
  {
    "word": "あたし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atashi",
    "translation": "I (female), me (female)"
  },
  {
    "word": "あたたかい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atatakai",
    "translation": "warm"
  },
  {
    "word": "あたたかく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atatakaku",
    "translation": "warm (and)"
  },
  {
    "word": "あたま",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atama",
    "translation": "head"
  },
  {
    "word": "あたらしい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atarashii",
    "translation": "new"
  },
  {
    "word": "あたらしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atarashiku",
    "translation": "new"
  },
  {
    "word": "あちら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "achira",
    "translation": "(over) there, in that direction, that"
  },
  {
    "word": "あった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atsuta",
    "translation": "had, transpired, encountered, there was, there were, took place, was, happened"
  },
  {
    "word": "あっち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "acchi",
    "translation": "(over) there, that way"
  },
  {
    "word": "あつい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atsui",
    "translation": "thick, hot"
  },
  {
    "word": "あつく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atsuku",
    "translation": "thick, hot"
  },
  {
    "word": "あなた",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "anata",
    "translation": "you"
  },
  {
    "word": "あに",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ani",
    "translation": "(my) older brother, (my) big brother"
  },
  {
    "word": "あね",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ane",
    "translation": "(my) older sisters, (my) older sister"
  },
  {
    "word": "あの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ano",
    "translation": "um, ah, those (over there), that (over there), that"
  },
  {
    "word": "あび",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "abi",
    "translation": "bathe"
  },
  {
    "word": "あびて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "abite",
    "translation": "bathe, took (a shower) (and), bathed (and), taking (a shower), take (a shower), took (and)"
  },
  {
    "word": "あぶない",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "abunai",
    "translation": "dangerous"
  },
  {
    "word": "あぶなかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "abunakatta",
    "translation": "was dangerous"
  },
  {
    "word": "あまい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "amai",
    "translation": "sweet"
  },
  {
    "word": "あまり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "amari",
    "translation": "(not) very well, (not) very often, (not) really, that, (not) a lot of, (not) much, (not) so, (not) too, (not) very, (not) many, (not) that, (not) often, really"
  },
  {
    "word": "あめ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ame",
    "translation": "candies, sweets, candy"
  },
  {
    "word": "あらい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "arai",
    "translation": "wash"
  },
  {
    "word": "あり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ari",
    "translation": "is/existing (now)"
  },
  {
    "word": "ありがとう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "arigatou",
    "translation": "thank you, thanks"
  },
  {
    "word": "ありません",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "arimasen",
    "translation": "is missing, (it) is not, is not, missing, has nothing, are not, has no, have no, has neither, does not have, there are no, is not available, there is not a, am not, there is no, do not have"
  },
  {
    "word": "ある",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aru",
    "translation": "a, there are, certain, is, some, an, lies, are, have, has, there is"
  },
  {
    "word": "あれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "are",
    "translation": "that (one) (over there), what, hey, that"
  },
  {
    "word": "あれば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "areba",
    "translation": "if there is, if ... had"
  },
  {
    "word": "あんな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "anna",
    "translation": "that kind of, like that (over there)"
  },
  {
    "word": "い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "i",
    "translation": "(adjective ending), be"
  },
  {
    "word": "いい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ii",
    "translation": "good, okay, prefer, well, may I, hope, alright, do you want, fine, best, nice"
  },
  {
    "word": "いいえ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iie",
    "translation": "no"
  },
  {
    "word": "いえ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ie",
    "translation": "house, houses, home, homes"
  },
  {
    "word": "いかが",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikaga",
    "translation": "how"
  },
  {
    "word": "いくつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikutsu",
    "translation": "how many ..."
  },
  {
    "word": "いくつか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikutsuka",
    "translation": "some"
  },
  {
    "word": "いくら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikura",
    "translation": "how (much)"
  },
  {
    "word": "いけ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ike",
    "translation": "pond"
  },
  {
    "word": "いけぶくろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikebukuro",
    "translation": "Ikebukuro"
  },
  {
    "word": "いしゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isha",
    "translation": "doctor"
  },
  {
    "word": "いす",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isu",
    "translation": "chairs, chair"
  },
  {
    "word": "いそがしい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isogashii",
    "translation": "busy, occupied"
  },
  {
    "word": "いそがしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isogashiku",
    "translation": ""
  },
  {
    "word": "いたい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itai",
    "translation": "aches, want to be, want to stay, hurt, (want to) be, hurts, ache, painful"
  },
  {
    "word": "いたく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itaku",
    "translation": "sore"
  },
  {
    "word": "いただきます",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itadakimasu",
    "translation": "get (polite), take (polite), let's eat, have (polite)"
  },
  {
    "word": "いち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ichi",
    "translation": "markets, market, one"
  },
  {
    "word": "いちばん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ichiban",
    "translation": "number one, the most"
  },
  {
    "word": "いっしょ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isshiyo",
    "translation": "together"
  },
  {
    "word": "いっぱい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itsupai",
    "translation": "all over, full, filled, a lot (of)"
  },
  {
    "word": "いつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itsu",
    "translation": "when, what time"
  },
  {
    "word": "いつか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itsuka",
    "translation": "someday, sometime, one day"
  },
  {
    "word": "いつも",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itsumo",
    "translation": "always, never, all the time"
  },
  {
    "word": "いない",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "inai",
    "translation": "do not, is not, away, am not, has not, are not, does not, have not"
  },
  {
    "word": "いぬ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "inu",
    "translation": "dogs, dog"
  },
  {
    "word": "います",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "imasu",
    "translation": "is (being), (there) is, has been, am, (there) are, are, have, have been, has"
  },
  {
    "word": "いません",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "imasen",
    "translation": "does not have, do not have, is, have not, is (not), there are no, has not, are, are not, not, (is) not (there), am not, have (no)"
  },
  {
    "word": "いみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "imi",
    "translation": "meaning, meanings"
  },
  {
    "word": "いもうと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "imouto",
    "translation": "younger sisters, younger sister"
  },
  {
    "word": "いや",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iya",
    "translation": "no"
  },
  {
    "word": "いり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iri",
    "translation": "need"
  },
  {
    "word": "いる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iru",
    "translation": "be, is (being), have (been), (there) is, am, has (been), stay, (there) are, are (being), has, was"
  },
  {
    "word": "いれば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ireba",
    "translation": ""
  },
  {
    "word": "いろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iro",
    "translation": "color, colored"
  },
  {
    "word": "いろいろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iroiro",
    "translation": "various (things), all sorts of (things), for everything"
  },
  {
    "word": "いろえんぴつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iro enpitsu",
    "translation": "coloured pencil"
  },
  {
    "word": "ううん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uun",
    "translation": "nah, no"
  },
  {
    "word": "うすい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "usui",
    "translation": "bland, mild, weak, thin"
  },
  {
    "word": "うすく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "usuku",
    "translation": "thin"
  },
  {
    "word": "うそ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uso",
    "translation": "lies, no way, lie"
  },
  {
    "word": "うた",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uta",
    "translation": "song, songs"
  },
  {
    "word": "うたい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "utai",
    "translation": "sing"
  },
  {
    "word": "うたう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "utau",
    "translation": "sing"
  },
  {
    "word": "うち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uchi",
    "translation": "home, we, (my) house"
  },
  {
    "word": "うっかり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "utsukari",
    "translation": "carelessly"
  },
  {
    "word": "うでどけい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "udedokei",
    "translation": "watch, watches"
  },
  {
    "word": "うどん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "udon",
    "translation": "udon, wheat noodles"
  },
  {
    "word": "うまみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "umami",
    "translation": "savoriness"
  },
  {
    "word": "うみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "umi",
    "translation": "sea, ocean"
  },
  {
    "word": "うり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uri",
    "translation": "sell"
  },
  {
    "word": "うるさい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "urusai",
    "translation": "annoying, loud, noisy"
  },
  {
    "word": "うわぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uwagi",
    "translation": "jacket, coat"
  },
  {
    "word": "うん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "un",
    "translation": "yes, yeah"
  },
  {
    "word": "うんてんしゅ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "untenshu",
    "translation": "driver"
  },
  {
    "word": "うんどう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "undou",
    "translation": "exercise"
  },
  {
    "word": "え",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "e",
    "translation": "picture, pictures"
  },
  {
    "word": "えいが",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eiga",
    "translation": "films, movie, film, movies"
  },
  {
    "word": "えいがかん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eigakan",
    "translation": "movie theater, cinema"
  },
  {
    "word": "ええ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ee",
    "translation": "yes"
  },
  {
    "word": "えき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eki",
    "translation": "(train) stations, (train) station"
  },
  {
    "word": "えもじ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "emozi",
    "translation": "pictograph, emoji"
  },
  {
    "word": "えんぴつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "enpitsu",
    "translation": "pencils, pencil"
  },
  {
    "word": "えー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "e",
    "translation": "wow, whoa, oh"
  },
  {
    "word": "お",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "o",
    "translation": "(polite)"
  },
  {
    "word": "おいしい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oishii",
    "translation": "good, tasty, delicious"
  },
  {
    "word": "おいしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oishiku",
    "translation": "taste good, delicious"
  },
  {
    "word": "おいて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oite",
    "translation": "put, placed, ahead of time, in advance, keep"
  },
  {
    "word": "おおい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ooi",
    "translation": "a lot of"
  },
  {
    "word": "おおく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ooku",
    "translation": "too much, a lot of"
  },
  {
    "word": "おおさか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oosaka",
    "translation": "Osaka"
  },
  {
    "word": "おおぜい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oozei",
    "translation": "a lot of"
  },
  {
    "word": "おかえりなさい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okaerinasai",
    "translation": ""
  },
  {
    "word": "おかげ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okage",
    "translation": "because of, due to"
  },
  {
    "word": "おかし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okashi",
    "translation": "snack, treats, treat"
  },
  {
    "word": "おかず",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okazu",
    "translation": "side dish, side dishes"
  },
  {
    "word": "おき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oki",
    "translation": "every"
  },
  {
    "word": "おさけ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "osake",
    "translation": "alcohol"
  },
  {
    "word": "おさら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "osara",
    "translation": "plate, plates"
  },
  {
    "word": "おし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oshi",
    "translation": "push, pressed"
  },
  {
    "word": "おしえ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oshie",
    "translation": "teach"
  },
  {
    "word": "おして",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oshite",
    "translation": "press, stamp"
  },
  {
    "word": "おじいさん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oziisan",
    "translation": "old man, grandfather"
  },
  {
    "word": "おじさん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ozisan",
    "translation": "uncle"
  },
  {
    "word": "おそい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "osoi",
    "translation": "late, slow"
  },
  {
    "word": "おそく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "osoku",
    "translation": "late, slow"
  },
  {
    "word": "おちゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oCha",
    "translation": "(green) tea"
  },
  {
    "word": "おてあらい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otearai",
    "translation": "restroom"
  },
  {
    "word": "おとうと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otouto",
    "translation": "little brother, younger brother"
  },
  {
    "word": "おととい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ototoi",
    "translation": "the day before yesterday, two days ago"
  },
  {
    "word": "おととし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ototoshi",
    "translation": "the year before last"
  },
  {
    "word": "おなか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onaka",
    "translation": "stomach, belly"
  },
  {
    "word": "おなじ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onaji",
    "translation": "same"
  },
  {
    "word": "おにいさん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oniisan",
    "translation": "older brothers, older brother, big brother"
  },
  {
    "word": "おにぎり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onigiri",
    "translation": "rice balls, onigiri, rice ball"
  },
  {
    "word": "おねえさん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oneesan",
    "translation": "older sisters, older sister, big sister, big sisters"
  },
  {
    "word": "おねがいします",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onegaishimasu",
    "translation": "please"
  },
  {
    "word": "おはし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ohashi",
    "translation": "chopsticks"
  },
  {
    "word": "おはよう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ohayou",
    "translation": "good morning"
  },
  {
    "word": "おばあさん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "obaasan",
    "translation": "grandmother, (your) grandmother, old woman, old lady"
  },
  {
    "word": "おばさん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "obasan",
    "translation": "auntie, aunt"
  },
  {
    "word": "おべんとう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "obentou",
    "translation": "bento, lunchbox"
  },
  {
    "word": "おぼえ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oboe",
    "translation": "remember, memorize, memorized"
  },
  {
    "word": "おぼえて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oboete",
    "translation": "remember, memorize"
  },
  {
    "word": "おめでとう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omedetou",
    "translation": "congratulations, happy, congratulations (on)"
  },
  {
    "word": "おもい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omoi",
    "translation": "heavy"
  },
  {
    "word": "おもく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omoku",
    "translation": "heavy"
  },
  {
    "word": "おもしろい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omoshiroi",
    "translation": "fun, funny, interesting"
  },
  {
    "word": "おもしろかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omoshirokatsuta",
    "translation": "(was) interesting"
  },
  {
    "word": "おもしろく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omoshiroku",
    "translation": "interesting/funnily"
  },
  {
    "word": "おもちゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omocha",
    "translation": "toy, toys"
  },
  {
    "word": "おやすみなさい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oyasuminasai",
    "translation": "good night"
  },
  {
    "word": "およいで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oyoide",
    "translation": "swim, swimming"
  },
  {
    "word": "およぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oyogi",
    "translation": "swim"
  },
  {
    "word": "およぐ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oyogu",
    "translation": "swim"
  },
  {
    "word": "おり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ori",
    "translation": "go down, to be, get off"
  },
  {
    "word": "おわって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "owatte",
    "translation": "finished (and), ended, finish, end, ended (and)"
  },
  {
    "word": "おわり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "owari",
    "translation": "ended, end"
  },
  {
    "word": "おんがく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ongaku",
    "translation": "music"
  },
  {
    "word": "お兄さん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onisan",
    "translation": "older brothers, older brother, big brother"
  },
  {
    "word": "お前",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omae",
    "translation": "you "
  },
  {
    "word": "お化け",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "obake",
    "translation": "shapeshifter, apparitions, shapeshifters, monstrous, apparition"
  },
  {
    "word": "お好み焼き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okonomiyaki",
    "translation": "savory Japanese pancakes"
  },
  {
    "word": "お姉さん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oneesan",
    "translation": "(older) sister, big sister"
  },
  {
    "word": "お孫さん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omagosan",
    "translation": "(your) grandchildren, (your) grandchild, grandchild"
  },
  {
    "word": "お宅",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otaku",
    "translation": "(your) house, (your) home"
  },
  {
    "word": "お母さん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okaasan",
    "translation": "mom, mother"
  },
  {
    "word": "お父さん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otousan",
    "translation": "(your) father, (your) dad"
  },
  {
    "word": "お疲れ様",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otsukaresama",
    "translation": "well done"
  },
  {
    "word": "お腹",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onaka",
    "translation": "stomach, belly"
  },
  {
    "word": "お茶",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ocha",
    "translation": "tea, (green) tea"
  },
  {
    "word": "お金",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okane",
    "translation": "money"
  },
  {
    "word": "お風呂",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ofuro",
    "translation": "(a) bathtub, bathroom, (the) bathtub, (a) bath, (the) bath"
  },
  {
    "word": "か",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ka",
    "translation": "what, or, that"
  },
  {
    "word": "かい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kai",
    "translation": "are you, will you"
  },
  {
    "word": "かいしゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaisha",
    "translation": "company, work, companies, office"
  },
  {
    "word": "かいじょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaijou",
    "translation": "venue, stadium"
  },
  {
    "word": "かいだん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaidan",
    "translation": "stairs, stairwell, staircase"
  },
  {
    "word": "かいもの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaimono",
    "translation": "shopping"
  },
  {
    "word": "かえさないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaesanaide",
    "translation": "do not return (it)"
  },
  {
    "word": "かえし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaeshi",
    "translation": "return"
  },
  {
    "word": "かえして",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaeshite",
    "translation": "return"
  },
  {
    "word": "かえす",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaesu",
    "translation": "return"
  },
  {
    "word": "かえって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaette",
    "translation": "go home, go back, return"
  },
  {
    "word": "かえり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaeri",
    "translation": "go home"
  },
  {
    "word": "かお",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kao",
    "translation": "face"
  },
  {
    "word": "かかり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kakari",
    "translation": "takes (eg time)"
  },
  {
    "word": "かき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaki",
    "translation": "draw"
  },
  {
    "word": "かけ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kake",
    "translation": "put"
  },
  {
    "word": "かさ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kasa",
    "translation": "umbrella, umbrellas"
  },
  {
    "word": "かさないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kasanaide",
    "translation": "do not lend"
  },
  {
    "word": "かし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kashi",
    "translation": ""
  },
  {
    "word": "かして",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kashite",
    "translation": "lend"
  },
  {
    "word": "かす",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kasu",
    "translation": "lend"
  },
  {
    "word": "かぜ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaze",
    "translation": "wind, a cold"
  },
  {
    "word": "かぞく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kazoku",
    "translation": "family"
  },
  {
    "word": "かった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "katta",
    "translation": "won/past-tense"
  },
  {
    "word": "かっぱ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "katsupa",
    "translation": "water demon, river goblins, kappa, river goblin, water demons"
  },
  {
    "word": "かつて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "katsute",
    "translation": "used to, ever, use to, once"
  },
  {
    "word": "かてい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "katei",
    "translation": "household, family"
  },
  {
    "word": "かど",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kado",
    "translation": "corner, corners"
  },
  {
    "word": "かどうか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kadouka",
    "translation": "whether (or not)"
  },
  {
    "word": "かな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kana",
    "translation": "perhaps, maybe, I wonder, I wonder (if)"
  },
  {
    "word": "かなり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanari",
    "translation": "fairly, rather, quite, very, so, pretty"
  },
  {
    "word": "かのじょ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanojo",
    "translation": "girlfriend, she, her"
  },
  {
    "word": "かばん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaban",
    "translation": "bags, bag"
  },
  {
    "word": "かぶり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaburi",
    "translation": "wear"
  },
  {
    "word": "かみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kami",
    "translation": "hair, god, paper"
  },
  {
    "word": "から",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kara",
    "translation": "because, off, since, from, (out) of, of, after, down, to, as, so, at, in, and then, through, beginning, starting, by"
  },
  {
    "word": "からい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karai",
    "translation": "spicy"
  },
  {
    "word": "からだ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karada",
    "translation": "body"
  },
  {
    "word": "かり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kari",
    "translation": "borrow"
  },
  {
    "word": "かりて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karite",
    "translation": "borrow, borrowed (and)"
  },
  {
    "word": "かりないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karinaide",
    "translation": ""
  },
  {
    "word": "かりる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kariru",
    "translation": "borrow"
  },
  {
    "word": "かるい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karui",
    "translation": "light"
  },
  {
    "word": "かるく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karuku",
    "translation": "light"
  },
  {
    "word": "かれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kare",
    "translation": "him, he"
  },
  {
    "word": "かれし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kareshi",
    "translation": "boyfriend"
  },
  {
    "word": "かれら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karera",
    "translation": "they (male), they (mixed male/female), them (male), them (mixed male/female)"
  },
  {
    "word": "かわいい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kawaii",
    "translation": "cuter, cute, pretty"
  },
  {
    "word": "かわいく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kawaiku",
    "translation": "cute"
  },
  {
    "word": "かんじ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanzi",
    "translation": "kanji, Chinese character, Chinese characters"
  },
  {
    "word": "かんたん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kantan",
    "translation": "simple, easy"
  },
  {
    "word": "かんぱい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanpai",
    "translation": "cheers"
  },
  {
    "word": "かん国",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kankoku",
    "translation": "(South) Korean, (South) Korea"
  },
  {
    "word": "が",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ga",
    "translation": "(softens the tone), but, (indicates the subject)"
  },
  {
    "word": "がち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gachi",
    "translation": "serious/apt to do/artistry/merit/win/goose/footsoldier"
  },
  {
    "word": "がっかり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gatsukari",
    "translation": "disappointing"
  },
  {
    "word": "がっこう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gakkou",
    "translation": "school"
  },
  {
    "word": "がり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gari",
    "translation": "pickled ginger"
  },
  {
    "word": "き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ki",
    "translation": "tree"
  },
  {
    "word": "きいろい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kiiroi",
    "translation": "yellow"
  },
  {
    "word": "きたない",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kitanai",
    "translation": "dirty, foul"
  },
  {
    "word": "きたなく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kitanaku",
    "translation": ""
  },
  {
    "word": "きちんと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kichinto",
    "translation": "properly, neatly"
  },
  {
    "word": "きって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kitte",
    "translation": "(postage) stamps, cut, (postage) stamp"
  },
  {
    "word": "きっと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kitto",
    "translation": "(I) am sure, certainly, for sure, must be, definitely, surely"
  },
  {
    "word": "きっぷ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kippu",
    "translation": "tickets, ticket"
  },
  {
    "word": "きて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kite",
    "translation": "wearing, becoming, getting, wear, wore (and)"
  },
  {
    "word": "きのう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kinou",
    "translation": "yesterday"
  },
  {
    "word": "きゅう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyuu",
    "translation": "nine"
  },
  {
    "word": "きょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyou",
    "translation": "today"
  },
  {
    "word": "きょうかしょ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoukasho",
    "translation": "textbooks, textbook"
  },
  {
    "word": "きょうしつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoushitsu",
    "translation": "classroom"
  },
  {
    "word": "きょうだい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoudai",
    "translation": "siblings, brothers"
  },
  {
    "word": "きょうと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyouto",
    "translation": "Kyoto"
  },
  {
    "word": "去年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyonen",
    "translation": "last year"
  },
  {
    "word": "きらい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kirai",
    "translation": "do not like"
  },
  {
    "word": "きり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kiri",
    "translation": "cut"
  },
  {
    "word": "きる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kiru",
    "translation": "wear"
  },
  {
    "word": "きれい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kirei",
    "translation": "beautiful, clean, pretty, pure"
  },
  {
    "word": "ぎゅうにく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gyuuniku",
    "translation": "beef"
  },
  {
    "word": "ぎゅうにゅう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gyuunyuu",
    "translation": "milk"
  },
  {
    "word": "ぎんこう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ginkou",
    "translation": "banks, bank"
  },
  {
    "word": "くうこう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuukou",
    "translation": "airport, airports"
  },
  {
    "word": "くしゃみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kushiyami",
    "translation": "sneezes, sneeze"
  },
  {
    "word": "くすり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kusuri",
    "translation": "medicine, medication, drug"
  },
  {
    "word": "ください",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kudasai",
    "translation": "can I get ..., may I ..., please, give me ... please"
  },
  {
    "word": "くだもの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kudamono",
    "translation": "fruit, fruits"
  },
  {
    "word": "くつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kutsu",
    "translation": "shoes, shoe"
  },
  {
    "word": "くもって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kumotte",
    "translation": "foggy, fogging up, cloudy"
  },
  {
    "word": "くもり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kumori",
    "translation": "cloudy"
  },
  {
    "word": "くらい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kurai",
    "translation": "dark, about, around"
  },
  {
    "word": "くらく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuraku",
    "translation": "dark"
  },
  {
    "word": "くれて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kurete",
    "translation": "(for) me"
  },
  {
    "word": "くろい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuroi",
    "translation": "black"
  },
  {
    "word": "けいたい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "keitai",
    "translation": "cell phones, cell phone"
  },
  {
    "word": "けさ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kesa",
    "translation": "this morning"
  },
  {
    "word": "けっこう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ketsukou",
    "translation": "no thank you, pretty, quite"
  },
  {
    "word": "けっこん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ketsukon",
    "translation": "marry, marriage"
  },
  {
    "word": "けど",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kedo",
    "translation": "even though, but"
  },
  {
    "word": "げんかん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "genkan",
    "translation": "entrance (of a house), entranceway, genkan"
  },
  {
    "word": "げんき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "genki",
    "translation": "how are ..., healthy, well, lively, how is ..., fine, energetic"
  },
  {
    "word": "こいつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koitsu",
    "translation": "this guy"
  },
  {
    "word": "こうえん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kouen",
    "translation": "park, parks"
  },
  {
    "word": "こうさてん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kousaten",
    "translation": "intersection"
  },
  {
    "word": "こうちゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koucha",
    "translation": "(black) tea"
  },
  {
    "word": "こうばん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kouban",
    "translation": "police boxes, police box"
  },
  {
    "word": "こえ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koe",
    "translation": "singing (of a bird), sound (of an animal), voice"
  },
  {
    "word": "ここ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koko",
    "translation": "this (place), (over) here, here"
  },
  {
    "word": "こたえ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotae",
    "translation": "answer"
  },
  {
    "word": "こたえて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotaete",
    "translation": "answer, tell me (as an answer)"
  },
  {
    "word": "こちら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kochira",
    "translation": "this, this (way), (over) here"
  },
  {
    "word": "こちらこそ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kochirakoso",
    "translation": "to you as well, likewise, the pleasure is ours, same here, the pleasure is mine"
  },
  {
    "word": "こっち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kocchi",
    "translation": "this (way), this (one), (over) here"
  },
  {
    "word": "こと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koto",
    "translation": "what, anything, matter, things, that, to, something, thing"
  },
  {
    "word": "ことに",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotoni",
    "translation": "especially"
  },
  {
    "word": "ことば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotoba",
    "translation": "word, language"
  },
  {
    "word": "この",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kono",
    "translation": "this, these"
  },
  {
    "word": "この間",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konoaida",
    "translation": "the other day, just a while ago"
  },
  {
    "word": "こまって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "komatte",
    "translation": "in trouble, troubled"
  },
  {
    "word": "こまり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "komari",
    "translation": "be in trouble"
  },
  {
    "word": "これ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kore",
    "translation": "this (one)"
  },
  {
    "word": "こわい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kowai",
    "translation": "scary, scared of"
  },
  {
    "word": "こわかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kowakatsuta",
    "translation": "was scary, were scary"
  },
  {
    "word": "こわく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kowaku",
    "translation": "scary"
  },
  {
    "word": "こんな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konna",
    "translation": "these, such, this kind of, like this"
  },
  {
    "word": "こんにちは",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konnichiha",
    "translation": "good day, hello, good afternoon"
  },
  {
    "word": "こんばん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konban",
    "translation": "tonight, this evening"
  },
  {
    "word": "こんばんは",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konbanha",
    "translation": "tonight, good evening, this evening"
  },
  {
    "word": "ご",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "go",
    "translation": "(polite)"
  },
  {
    "word": "ございます",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gozaimasu",
    "translation": "very much, we have, there is"
  },
  {
    "word": "ごちそう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gochisou",
    "translation": ""
  },
  {
    "word": "ごちそうさまでした",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gochisousamadeshita",
    "translation": "thank you for the meal"
  },
  {
    "word": "ごはん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gohan",
    "translation": "rice, meal"
  },
  {
    "word": "ごめんなさい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gomennasai",
    "translation": "(I am) sorry, sorry"
  },
  {
    "word": "ごろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "goro",
    "translation": "about, around"
  },
  {
    "word": "ご飯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gohan",
    "translation": "food, rice, meals, meal"
  },
  {
    "word": "さ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sa",
    "translation": "open"
  },
  {
    "word": "さあ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saa",
    "translation": "well, I am not sure, now, come on, well (then)"
  },
  {
    "word": "さい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sai",
    "translation": "years old, the age of, year old"
  },
  {
    "word": "さいて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saite",
    "translation": "in bloom, blooming"
  },
  {
    "word": "さいふ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saifu",
    "translation": "wallet, wallets"
  },
  {
    "word": "さかな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakana",
    "translation": "fishes, fish"
  },
  {
    "word": "さき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saki",
    "translation": "bloom"
  },
  {
    "word": "さくぶん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakubun",
    "translation": "essay, essays, compositions, composition"
  },
  {
    "word": "さくら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakura",
    "translation": "Sakura (female name)"
  },
  {
    "word": "さし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sashi",
    "translation": "put up"
  },
  {
    "word": "さして",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sashite",
    "translation": "putting up, use (an umbrella), put up, holding up"
  },
  {
    "word": "さっき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "satsuki",
    "translation": "a short while ago, just now, a few minutes ago"
  },
  {
    "word": "さつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "satsu",
    "translation": "(for counting books)"
  },
  {
    "word": "さとう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "satou",
    "translation": "sugar"
  },
  {
    "word": "さむい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "samui",
    "translation": "cold"
  },
  {
    "word": "さむく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "samuku",
    "translation": "cold"
  },
  {
    "word": "さようなら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sayounara",
    "translation": "bye, farewell, goodbye"
  },
  {
    "word": "さん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "san",
    "translation": "Ms., Mr., Miss, three, Mrs."
  },
  {
    "word": "さんぽ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sanpo",
    "translation": "go on walks, going on walks, going for walks, take walks"
  },
  {
    "word": "さ来年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sarainen",
    "translation": "the year after next"
  },
  {
    "word": "さ来週",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saraishuu",
    "translation": "the week after next"
  },
  {
    "word": "ざっし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zasshi",
    "translation": "magazine, magazines"
  },
  {
    "word": "し",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shi",
    "translation": "and"
  },
  {
    "word": "しお",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shio",
    "translation": "salt"
  },
  {
    "word": "しか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shika",
    "translation": "only, deer"
  },
  {
    "word": "しかし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shikashi",
    "translation": "however, but"
  },
  {
    "word": "しごと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shigoto",
    "translation": "job, work"
  },
  {
    "word": "しずか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shizuka",
    "translation": "quiet"
  },
  {
    "word": "した",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shita",
    "translation": "made, played, did, had, been, conducted, decided, have, put, gave"
  },
  {
    "word": "しって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shitte",
    "translation": "know"
  },
  {
    "word": "しつもん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shitsumon",
    "translation": "question, questions"
  },
  {
    "word": "して",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shite",
    "translation": "wearing, be, play, having, do, doing, took out (and), keep, did, playing, (let's) do (and)"
  },
  {
    "word": "しないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinaide",
    "translation": "do not (make), do not (use), do not (do)"
  },
  {
    "word": "しに",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shini",
    "translation": "go to do"
  },
  {
    "word": "しばらく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shibaraku",
    "translation": "(for) a moment, (in) a while, (for) a while, (for) some time"
  },
  {
    "word": "しぶや",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shibuya",
    "translation": "Shibuya"
  },
  {
    "word": "しまい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shimai",
    "translation": "sisters"
  },
  {
    "word": "しまって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shimatte",
    "translation": "finish,end,stop"
  },
  {
    "word": "しまり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shimari",
    "translation": "close"
  },
  {
    "word": "しめ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shime",
    "translation": "close, wear"
  },
  {
    "word": "しめて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shimete",
    "translation": "close, shut, put on, wear, closed (and)"
  },
  {
    "word": "しゃしん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shashin",
    "translation": "picture, pictures, photos, photo"
  },
  {
    "word": "しゅくだい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shukudai",
    "translation": "homework"
  },
  {
    "word": "しゅっしん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shusshin",
    "translation": "origin, hometown, from, home country"
  },
  {
    "word": "しゅもく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shumoku",
    "translation": "event, events"
  },
  {
    "word": "しょうゆ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shouyu",
    "translation": "soy sauce"
  },
  {
    "word": "しょくどう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shokudou",
    "translation": "cafeteria, dining room, dining hall"
  },
  {
    "word": "しよう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiyou",
    "translation": "should I do, I will do, let's do"
  },
  {
    "word": "しり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiri",
    "translation": "find out, know"
  },
  {
    "word": "しろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiro",
    "translation": "castles, be, do, do it, white, castle"
  },
  {
    "word": "しんかんせん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinkansen",
    "translation": "bullet train, Shinkansen"
  },
  {
    "word": "じしょ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jisho",
    "translation": "dictionaries, dictionary"
  },
  {
    "word": "じてんしゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jitensha",
    "translation": "bikes, bicycles, bike"
  },
  {
    "word": "じどうしゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jidousha",
    "translation": "automobile, car"
  },
  {
    "word": "じゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ja",
    "translation": "so, well (then), so (then)"
  },
  {
    "word": "じゃあ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jaa",
    "translation": "well then, bye, so, well, see you"
  },
  {
    "word": "じゃない",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "janai",
    "translation": "does not, do not, is not, are not, am not"
  },
  {
    "word": "じゃん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jan",
    "translation": "you know, didn't I"
  },
  {
    "word": "じゅうたん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juutan",
    "translation": "carpet"
  },
  {
    "word": "じゅうどう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juudou",
    "translation": "judo"
  },
  {
    "word": "じゅぎょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jugyou",
    "translation": "lesson, class"
  },
  {
    "word": "じょうぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jougi",
    "translation": "ruler, rulers"
  },
  {
    "word": "じょうず",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jouzu",
    "translation": "good at"
  },
  {
    "word": "じょうぶ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joubu",
    "translation": "durable, sturdy"
  },
  {
    "word": "すい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sui",
    "translation": "smoke"
  },
  {
    "word": "すいえい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suiei",
    "translation": "swimming"
  },
  {
    "word": "すき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suki",
    "translation": "likes, like"
  },
  {
    "word": "すぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sugi",
    "translation": "too (much), too much"
  },
  {
    "word": "すくない",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sukunai",
    "translation": "small in quantity, few, not much, little, a small amount"
  },
  {
    "word": "すくなく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sukunaku",
    "translation": "not much"
  },
  {
    "word": "すぐに",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suguni",
    "translation": "soon, immediate, quickly, in a moment, straight away, right away, at once, immediately"
  },
  {
    "word": "すこし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sukoshi",
    "translation": "a bit, a little, some, few"
  },
  {
    "word": "すし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sushi",
    "translation": "sushi"
  },
  {
    "word": "すすみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "susumi",
    "translation": "go, progress"
  },
  {
    "word": "すずしい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suzushii",
    "translation": "cool"
  },
  {
    "word": "すずしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suzushiku",
    "translation": "cool (and)"
  },
  {
    "word": "すって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sutte",
    "translation": "smoking, smoke"
  },
  {
    "word": "すてき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suteki",
    "translation": "lovely, nice"
  },
  {
    "word": "すみません",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sumimasen",
    "translation": "excuse me, pardon me, sorry"
  },
  {
    "word": "する",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suru",
    "translation": "do, play, plays, takes, give, would you do, to, have, makes"
  },
  {
    "word": "すれば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sureba",
    "translation": ""
  },
  {
    "word": "すわって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suwatte",
    "translation": "sitting (down), sit (down)"
  },
  {
    "word": "すわり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suwari",
    "translation": "sit"
  },
  {
    "word": "すんで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sunde",
    "translation": "live"
  },
  {
    "word": "ずっと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zutto",
    "translation": "far, always, constantly, much, the whole time, continuously, more"
  },
  {
    "word": "せ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "se",
    "translation": "stature, height"
  },
  {
    "word": "せい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sei",
    "translation": "fault,consequence"
  },
  {
    "word": "せっけん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sekken",
    "translation": "soap"
  },
  {
    "word": "せなか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senaka",
    "translation": "back"
  },
  {
    "word": "せびろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sebiro",
    "translation": "suits, suit"
  },
  {
    "word": "せまい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "semai",
    "translation": "small, confined, narrow"
  },
  {
    "word": "せんしゅ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senshu",
    "translation": "players, athlete, athletes, player"
  },
  {
    "word": "せんたく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sentaku",
    "translation": "laundry"
  },
  {
    "word": "ぜひ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zehi",
    "translation": "do, certainly, please,definitely"
  },
  {
    "word": "ぜんぜん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zenzen",
    "translation": "(not) one bit, (not) at all"
  },
  {
    "word": "ぜんぶ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zenbu",
    "translation": "all (of them)"
  },
  {
    "word": "そう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sou",
    "translation": "true, right, so, correct, that"
  },
  {
    "word": "そうじ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "souji",
    "translation": "cleaning"
  },
  {
    "word": "そこ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "soko",
    "translation": "there, that (place), that"
  },
  {
    "word": "そちら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sochira",
    "translation": "there, that way, that"
  },
  {
    "word": "そっち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "socchi",
    "translation": "that (one), that direction, there, that way"
  },
  {
    "word": "そと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "soto",
    "translation": "outside"
  },
  {
    "word": "その",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sono",
    "translation": "the, its, those, that"
  },
  {
    "word": "そば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "soba",
    "translation": "buckwheat noodles, soba"
  },
  {
    "word": "そふ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sohu",
    "translation": "(my) grandfather, (our) grandfathers"
  },
  {
    "word": "そぼ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sobo",
    "translation": "(my) grandmother, (my) grandmothers"
  },
  {
    "word": "そら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sora",
    "translation": "the sky"
  },
  {
    "word": "それ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sore",
    "translation": "that (one), it"
  },
  {
    "word": "それから",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sorekara",
    "translation": "and then, after that"
  },
  {
    "word": "それで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sorede",
    "translation": "and, so"
  },
  {
    "word": "それとも",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "soretomo",
    "translation": "or"
  },
  {
    "word": "そろそろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sorosoro",
    "translation": "shortly, soon"
  },
  {
    "word": "そんな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sonna",
    "translation": "like that, such (a)"
  },
  {
    "word": "ぞ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zo",
    "translation": "... you know (male)"
  },
  {
    "word": "た",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ta",
    "translation": "did"
  },
  {
    "word": "たい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tai",
    "translation": "want to"
  },
  {
    "word": "たいせつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taisetsu",
    "translation": "important"
  },
  {
    "word": "たいそう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taisou",
    "translation": "a warm-up, gymnastics, exercise"
  },
  {
    "word": "たいてい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taitei",
    "translation": "usually, typically, in general, generally, often"
  },
  {
    "word": "たいへん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taihen",
    "translation": "very, hard, tough"
  },
  {
    "word": "たかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "takatta",
    "translation": "gathered around"
  },
  {
    "word": "-たく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "-taku",
    "translation": "verb – want to"
  },
  {
    "word": "たくさん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "takusan",
    "translation": "lots of, many, a lot (of)"
  },
  {
    "word": "たこ焼き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "takoyaki",
    "translation": "fried octopus balls, takoyaki"
  },
  {
    "word": "ただ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tada",
    "translation": "free, just"
  },
  {
    "word": "ただいま",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tadaima",
    "translation": "I'm back, I'm home"
  },
  {
    "word": "たち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tachi",
    "translation": "(plural ending)"
  },
  {
    "word": "たった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tatsuta",
    "translation": "only, just"
  },
  {
    "word": "たって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tatte",
    "translation": "standing, stood up (and), stand up"
  },
  {
    "word": "たっぷり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tappuri",
    "translation": "plenty/ample"
  },
  {
    "word": "たてもの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tatemono",
    "translation": "building, buildings"
  },
  {
    "word": "たのしい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanoshii",
    "translation": "fun"
  },
  {
    "word": "たのしかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanoshikatsuta",
    "translation": "was fun"
  },
  {
    "word": "たのしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanoshiku",
    "translation": "fun"
  },
  {
    "word": "たのみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanomi",
    "translation": "ask"
  },
  {
    "word": "たぶん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tabun",
    "translation": "maybe, probably"
  },
  {
    "word": "たまご",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tamago",
    "translation": "eggs, egg"
  },
  {
    "word": "たまねぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tamanegi",
    "translation": "onions, onion"
  },
  {
    "word": "ため",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tame",
    "translation": "(in order) to"
  },
  {
    "word": "誕生日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanjoubi",
    "translation": "birthday"
  },
  {
    "word": "だ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "da",
    "translation": "will be, you're, 's, it's, is, am, are, have, were, I'm, has, was, that's"
  },
  {
    "word": "だいどころ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daidokoro",
    "translation": "kitchen"
  },
  {
    "word": "だけ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dake",
    "translation": "alone, only, just"
  },
  {
    "word": "だった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "datta",
    "translation": "had, was, were"
  },
  {
    "word": "だらけ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "darake",
    "translation": "full of, covered in"
  },
  {
    "word": "だれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dare",
    "translation": "who"
  },
  {
    "word": "だろう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "darou",
    "translation": "I wonder, would, is, will, must be, likely, probably, is going to, are going to"
  },
  {
    "word": "だんだん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dandan",
    "translation": "gradually, more and more"
  },
  {
    "word": "ちかい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chikai",
    "translation": "near"
  },
  {
    "word": "ちかく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chikaku",
    "translation": "near, close to, nearby"
  },
  {
    "word": "ちかてつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chikatetsu",
    "translation": "subway, subways"
  },
  {
    "word": "ちがい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chigai",
    "translation": "wrong"
  },
  {
    "word": "ちがう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chigau",
    "translation": "incorrect, wrong, different"
  },
  {
    "word": "ちず",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chizu",
    "translation": "map, maps"
  },
  {
    "word": "ちゃわん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chawan",
    "translation": "(rice) bowls, (rice) bowl"
  },
  {
    "word": "ちゃんと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chanto",
    "translation": "proper, fully, properly, full, correctly"
  },
  {
    "word": "ちょうど",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "choudo",
    "translation": "precisely, sharp, exactly, just"
  },
  {
    "word": "ちょっと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chotto",
    "translation": "briefly, a moment, somewhat, a little, (a) bit, (for) a second, slightly"
  },
  {
    "word": "つい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsui",
    "translation": "accidentally, unintentionally, in spite of myself"
  },
  {
    "word": "ついに",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuini",
    "translation": "in the end, at last, finally, after all, eventually"
  },
  {
    "word": "つかい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukai",
    "translation": "errand"
  },
  {
    "word": "つかって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukatte",
    "translation": "using, use"
  },
  {
    "word": "つかれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukare",
    "translation": "are tired, get tired"
  },
  {
    "word": "つかれて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukarete",
    "translation": "tired"
  },
  {
    "word": "つぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsugi",
    "translation": "next"
  },
  {
    "word": "つくえ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukue",
    "translation": "desks, desk"
  },
  {
    "word": "つくり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukuri",
    "translation": "make"
  },
  {
    "word": "つけ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuke",
    "translation": "turn on"
  },
  {
    "word": "つけて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukete",
    "translation": "turning on, turn on"
  },
  {
    "word": "つとめ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsutome",
    "translation": "worked"
  },
  {
    "word": "つとめて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsutomete",
    "translation": "employed, to work, working"
  },
  {
    "word": "つまらない",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsumaranai",
    "translation": "boring"
  },
  {
    "word": "つまらなかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsumaranakatta",
    "translation": "was boring"
  },
  {
    "word": "つめたい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsumetai",
    "translation": "cold"
  },
  {
    "word": "つもり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsumori",
    "translation": "going to, intend to, intention, intends to, plan to"
  },
  {
    "word": "つよい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuyoi",
    "translation": "strong"
  },
  {
    "word": "つよく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuyoku",
    "translation": "strongly"
  },
  {
    "word": "て",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "te",
    "translation": "and, so"
  },
  {
    "word": "てがみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tegami",
    "translation": "letters, letter"
  },
  {
    "word": "ても",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "temo",
    "translation": "even if"
  },
  {
    "word": "てりやき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "teriyaki",
    "translation": "teriyaki"
  },
  {
    "word": "てんぷら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tenpura",
    "translation": "tempura"
  },
  {
    "word": "で",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "de",
    "translation": "and, on, via, off, for, from, (because) of, over, due to, so, out of, in, through, at, during, under, using, with, into, by (means of), as a"
  },
  {
    "word": "であれば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "deareba",
    "translation": "if I am, I were"
  },
  {
    "word": "できて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dekite",
    "translation": "able to"
  },
  {
    "word": "でした",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "deshita",
    "translation": "had, was, were"
  },
  {
    "word": "でしょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "deshyou",
    "translation": "will, right, would be, would, is, you'll, will likely ... be, will probably, isn't it, am going to, is it not, probably, would probably, are going to, will probably ... be"
  },
  {
    "word": "です",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "desu",
    "translation": "he's, it's, is, am, are, have, she's, I'm, has"
  },
  {
    "word": "では",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "deha",
    "translation": "well (then), during, at, by, in"
  },
  {
    "word": "ではありません",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Dewa arimasen",
    "translation": "(we) are not, is not, (they) are not, (he) is not, are not, is ... not, (she) is not, (it) is ... not, (I) am not"
  },
  {
    "word": "でも",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "demo",
    "translation": "even if ... were, even if ... is, is also, even (on), but, however, neither, nor"
  },
  {
    "word": "でんわ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "denwa",
    "translation": "telephone, telephones"
  },
  {
    "word": "と",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "to",
    "translation": "and, door, for, that, whenever, when, to, as, doors, from, with, or, nor, if"
  },
  {
    "word": "といい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": " To ii",
    "translation": "it is best to, I hope (that)"
  },
  {
    "word": "といいます",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "To iimasu",
    "translation": "it is called, my name is, it is said that"
  },
  {
    "word": "という",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "To iu",
    "translation": "named, is the name of, like, that, of, by the name of, such as, called"
  },
  {
    "word": "とうきょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toukyou",
    "translation": "Tokyo"
  },
  {
    "word": "とおい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tooi",
    "translation": "far"
  },
  {
    "word": "とおく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tooku",
    "translation": "far, the distance, far away"
  },
  {
    "word": "とおり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toori",
    "translation": "go by way of/past"
  },
  {
    "word": "とか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toka",
    "translation": "things like"
  },
  {
    "word": "ときどき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tokidoki",
    "translation": "sometimes"
  },
  {
    "word": "とけい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tokei",
    "translation": "clocks, clock"
  },
  {
    "word": "ところ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tokoro",
    "translation": "right, about to, places, somewhere, just, place"
  },
  {
    "word": "として",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toshite",
    "translation": "as"
  },
  {
    "word": "としょかん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toshokan",
    "translation": "libraries, library"
  },
  {
    "word": "とても",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "totemo",
    "translation": "very, deeply, a lot, so, really"
  },
  {
    "word": "となり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tonari",
    "translation": "next door, neighboring, next to"
  },
  {
    "word": "とび",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tobi",
    "translation": "fly"
  },
  {
    "word": "とまって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tomatte",
    "translation": "stop"
  },
  {
    "word": "とまり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tomari",
    "translation": "stop"
  },
  {
    "word": "とらないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toranaide",
    "translation": "do not take"
  },
  {
    "word": "とり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tori",
    "translation": "catch, capture, take, bird, birds"
  },
  {
    "word": "とりあえず",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toriaezu",
    "translation": "first (of all), for the time being, first"
  },
  {
    "word": "とりにく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toriniku",
    "translation": "chicken"
  },
  {
    "word": "とる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toru",
    "translation": "take"
  },
  {
    "word": "とんで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tonde",
    "translation": "fly, flying"
  },
  {
    "word": "と言います",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "to iimasu",
    "translation": "says, is named, tell, say, my name is, is called, tells"
  },
  {
    "word": "どう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dou",
    "translation": "what, how (about)"
  },
  {
    "word": "どういたしまして",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "douitashimashite",
    "translation": "my pleasure, you're welcome, you are welcome"
  },
  {
    "word": "どうして",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doushite",
    "translation": "how, why"
  },
  {
    "word": "どうぞ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "douzo",
    "translation": "here you are, please, go ahead"
  },
  {
    "word": "どうぶつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doubutsu",
    "translation": "animals, animal"
  },
  {
    "word": "どうも",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doumo",
    "translation": "very (much), please, thanks, quite"
  },
  {
    "word": "どうやって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "douyatte",
    "translation": "how"
  },
  {
    "word": "どこ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doko",
    "translation": "where"
  },
  {
    "word": "どちら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dochira",
    "translation": "which one, where, which, which way"
  },
  {
    "word": "どっち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "docchi",
    "translation": "which (one), which (way), where"
  },
  {
    "word": "どなた",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "donata",
    "translation": "who (formal)"
  },
  {
    "word": "どの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dono",
    "translation": "what, every, any, which, each"
  },
  {
    "word": "どれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dore",
    "translation": "which (one)"
  },
  {
    "word": "どんな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "donna",
    "translation": "what (type of), how, what (kind of), any, what (sort of)"
  },
  {
    "word": "な",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "na",
    "translation": "(adjective ending), do not, without, (added emotion), don't"
  },
  {
    "word": "ない",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nai",
    "translation": "do not, do not have, no, never, hasn't, am not, nothing, without, isn't, are not, not, is gone, aren't, (have) not, haven't, will not"
  },
  {
    "word": "ないて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "naite",
    "translation": "sing, singing, barking, crying, chirp"
  },
  {
    "word": "なかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nakatta",
    "translation": "there have not been, could not, there was no, there were no, (I) did not, did not, had no, there has not been, had never, was not"
  },
  {
    "word": "ながら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nagara",
    "translation": "as, while"
  },
  {
    "word": "なき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "naki",
    "translation": "chirp, cry"
  },
  {
    "word": "なくし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nakushi",
    "translation": "lose something"
  },
  {
    "word": "なくなり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nakunari",
    "translation": "pass away"
  },
  {
    "word": "なさい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nasai",
    "translation": "please/sorry"
  },
  {
    "word": "なし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nashi",
    "translation": "without"
  },
  {
    "word": "なぜ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "naze",
    "translation": "why, how come"
  },
  {
    "word": "なった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "natta",
    "translation": "have ... gone, got, have gotten, became, turned"
  },
  {
    "word": "なっとう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nattou",
    "translation": "natto, fermented soybeans"
  },
  {
    "word": "なつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "natsu",
    "translation": "summer"
  },
  {
    "word": "なな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nana",
    "translation": "seven"
  },
  {
    "word": "なの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nano",
    "translation": "is (she), are (you), do you, is (that), is, is (it), does, is (he), to be"
  },
  {
    "word": "ならい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "narai",
    "translation": "learn"
  },
  {
    "word": "ならばないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "narabanaide",
    "translation": "don’t stand in line"
  },
  {
    "word": "ならび",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "narabi",
    "translation": "form a line, wait in line"
  },
  {
    "word": "なり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nari",
    "translation": "become"
  },
  {
    "word": "なるほど",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "naruhodo",
    "translation": "indeed, I see"
  },
  {
    "word": "なんか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nanka",
    "translation": "sort of, anything, somewhere (like), kind of, something, a little, (negative judgement)"
  },
  {
    "word": "なんて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nante",
    "translation": "that, what ..., how ..."
  },
  {
    "word": "に",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ni",
    "translation": "among, (out) of, into, within, as (an), as (a), at, in, from, for, two, per, to, over, atop, upon, let, during, with, by, on, made, of, inside, against, through, onto"
  },
  {
    "word": "におい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nioi",
    "translation": "smell"
  },
  {
    "word": "にぎやか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nigiyaka",
    "translation": "busy, lively, bustling"
  },
  {
    "word": "にく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "niku",
    "translation": "meat"
  },
  {
    "word": "について",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nitsuite",
    "translation": "about, regarding"
  },
  {
    "word": "にとって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nitotte",
    "translation": "to, for"
  },
  {
    "word": "にもつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nimotsu",
    "translation": "baggage, luggage"
  },
  {
    "word": "によると",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "niyoruto",
    "translation": "according to"
  },
  {
    "word": "にわ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "niwa",
    "translation": "yard, garden"
  },
  {
    "word": "にんじゃ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ninja",
    "translation": "ninjas, ninja"
  },
  {
    "word": "にんじん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ninjin",
    "translation": "carrots, carrot"
  },
  {
    "word": "にんにく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ninniku",
    "translation": "garlic"
  },
  {
    "word": "ぬいぐるみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nuigurumi",
    "translation": "plush toy, stuffed animal, plushie"
  },
  {
    "word": "ぬぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nugi",
    "translation": "remove clothes"
  },
  {
    "word": "ね",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ne",
    "translation": "wouldn't you agree, isn't there, haven't you, okay, would you, didn't we, don't they, aren't you, correct, won't you, isn't it, right, isn't she, aren't they, don't you, alright, is it not, doesn't she, isn't he, you know, doesn't he"
  },
  {
    "word": "ねこ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "neko",
    "translation": "cats, cat"
  },
  {
    "word": "の",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "no",
    "translation": "do, (belong) to, from, around, for, person, does, of, in, about, one, (belongs) to, to, ones, at, who is, between, 's, on, which is, (that)"
  },
  {
    "word": "ので",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "node",
    "translation": "because, for, that, since, as, so"
  },
  {
    "word": "のに",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "noni",
    "translation": "(in order) for, (in order) to, even though, although"
  },
  {
    "word": "のぼって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nobotte",
    "translation": "go up, climbing, climb, climbed (and)"
  },
  {
    "word": "のぼり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nobori",
    "translation": "climb, climbed"
  },
  {
    "word": "のみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nomi",
    "translation": "only"
  },
  {
    "word": "のみもの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nomimono",
    "translation": "beverage, (a) drink, beverages"
  },
  {
    "word": "のむ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nomu",
    "translation": "drink, drinks"
  },
  {
    "word": "のり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nori",
    "translation": "get on, ride, board, roasted seaweed"
  },
  {
    "word": "のんで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nonde",
    "translation": "drinking, drink, take (a medicine)"
  },
  {
    "word": "は",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ha",
    "translation": "on, regarding, for, of, when (it comes to), in, about, tooth, as for, (indicates the topic), at, teeth, during, with, by"
  },
  {
    "word": "はい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hai",
    "translation": "yes"
  },
  {
    "word": "はき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haki",
    "translation": "put on (lower)"
  },
  {
    "word": "はこ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hako",
    "translation": "box, boxes"
  },
  {
    "word": "はし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hashi",
    "translation": "bridge, chopsticks, bridges"
  },
  {
    "word": "はしり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hashiri",
    "translation": "run"
  },
  {
    "word": "はしる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hashiru",
    "translation": "run"
  },
  {
    "word": "はじまって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hajimatte",
    "translation": "start"
  },
  {
    "word": "はじまり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hajimari",
    "translation": "the start, beginning"
  },
  {
    "word": "はじめに",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hajime ni",
    "translation": "firstly, first"
  },
  {
    "word": "はじめまして",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hazimemashite",
    "translation": "hello, nice to meet you"
  },
  {
    "word": "はず",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hazu",
    "translation": "ought to, supposed to, should"
  },
  {
    "word": "はたらき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hataraki",
    "translation": "work"
  },
  {
    "word": "はって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hatte",
    "translation": "put, paste, stick"
  },
  {
    "word": "はな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hana",
    "translation": "Hana, flower"
  },
  {
    "word": "はなし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanashi",
    "translation": "story, talking, conversation, stories, chat, talk"
  },
  {
    "word": "はなせ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanase",
    "translation": "can speak"
  },
  {
    "word": "はやい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hayai",
    "translation": "early, quick, fast"
  },
  {
    "word": "はやく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hayaku",
    "translation": "early, quick, fast, quickly"
  },
  {
    "word": "はらい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "harai",
    "translation": "pay, will pay"
  },
  {
    "word": "はらじゅく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "harajuku",
    "translation": "Harajuku"
  },
  {
    "word": "はらって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haratte",
    "translation": "pay"
  },
  {
    "word": "はり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hari",
    "translation": "put, paste, stick"
  },
  {
    "word": "はる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haru",
    "translation": "spring, paste"
  },
  {
    "word": "はれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hare",
    "translation": "sunny"
  },
  {
    "word": "はれて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "harete",
    "translation": "clear, sunny"
  },
  {
    "word": "はんこ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanko",
    "translation": "personal seal"
  },
  {
    "word": "ばかり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bakari",
    "translation": "continuously, only, just"
  },
  {
    "word": "ばんごう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bangou",
    "translation": "number"
  },
  {
    "word": "ばんごはん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bangohan",
    "translation": "dinner"
  },
  {
    "word": "ひいて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiite",
    "translation": "use (a dictionary), look up (a word), have (a cold), consult (a dictionary), catch (a cold)"
  },
  {
    "word": "ひき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiki",
    "translation": "to catch (a cold), play, look up, (counter for animals)"
  },
  {
    "word": "ひく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiku",
    "translation": "play"
  },
  {
    "word": "ひくい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hikui",
    "translation": "lower, low"
  },
  {
    "word": "ひげ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hige",
    "translation": "mustaches, beards, facial hair, mustache, beard"
  },
  {
    "word": "ひこうき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hikouki",
    "translation": "airplane, plane, airplanes, planes"
  },
  {
    "word": "ひま",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hima",
    "translation": "available, bored, free"
  },
  {
    "word": "ひる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiru",
    "translation": "midday, daytime"
  },
  {
    "word": "ひるごはん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hirugohan",
    "translation": "lunch"
  },
  {
    "word": "ひるま",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiruma",
    "translation": "afternoon, daytime"
  },
  {
    "word": "ひろい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiroi",
    "translation": "big, spacious, wide"
  },
  {
    "word": "びき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "biki",
    "translation": "(counter for animals)"
  },
  {
    "word": "びっくり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bitsukuri",
    "translation": "surprise"
  },
  {
    "word": "びょういん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "byouin",
    "translation": "hospital, hospitals"
  },
  {
    "word": "びょう気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "byouki",
    "translation": "ill, disease, sick"
  },
  {
    "word": "ぴき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "piki",
    "translation": "(counter for animals)"
  },
  {
    "word": "ふうとう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuutou",
    "translation": "envelope"
  },
  {
    "word": "ふき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuki",
    "translation": "blow"
  },
  {
    "word": "ふく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuku",
    "translation": "outfit, clothing, clothes"
  },
  {
    "word": "ふた",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futa",
    "translation": "lid, lids"
  },
  {
    "word": "ふとい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futoi",
    "translation": "thick"
  },
  {
    "word": "ふとって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futotte",
    "translation": "heavy, overweight, gaining ... weight, fat"
  },
  {
    "word": "ふゆ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuyu",
    "translation": "winter"
  },
  {
    "word": "ふり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furi",
    "translation": "fall (snow/rain)"
  },
  {
    "word": "ふるい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furui",
    "translation": "old"
  },
  {
    "word": "ふるく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furuku",
    "translation": "old"
  },
  {
    "word": "ふろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furo",
    "translation": "bathroom, bathtub, bath"
  },
  {
    "word": "ぶたにく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "butaniku",
    "translation": "pork"
  },
  {
    "word": "ぶどう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "budou",
    "translation": "grape, grapes"
  },
  {
    "word": "ぶんしょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bunshou",
    "translation": "text, texts"
  },
  {
    "word": "へ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "e",
    "translation": "to"
  },
  {
    "word": "へいじつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "heijitsu",
    "translation": "weekdays"
  },
  {
    "word": "へた",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "heta",
    "translation": "bad at, not good at"
  },
  {
    "word": "へや",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "heya",
    "translation": "room, rooms"
  },
  {
    "word": "べんきょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "benkyou",
    "translation": "studying, study"
  },
  {
    "word": "べんとう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bentou",
    "translation": "bento, lunchbox"
  },
  {
    "word": "ほう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hou",
    "translation": "(comparison)"
  },
  {
    "word": "ほか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hoka",
    "translation": "other, another"
  },
  {
    "word": "ほこり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hokori",
    "translation": "dust"
  },
  {
    "word": "ほしい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hoshii",
    "translation": "wants, want"
  },
  {
    "word": "ほしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hoshiku",
    "translation": "want"
  },
  {
    "word": "ほそい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hosoi",
    "translation": "fine, narrow, slim, thin"
  },
  {
    "word": "ほとんど",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hotondo",
    "translation": "little, practically, almost, (not) much, few, hardly (any)"
  },
  {
    "word": "ほど",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hodo",
    "translation": "sufficient, as ... as, about, extent, enough"
  },
  {
    "word": "ほら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hora",
    "translation": "hey, listen"
  },
  {
    "word": "ほんとう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hontou",
    "translation": "true, really"
  },
  {
    "word": "ほんの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "honno",
    "translation": "only, mere, just"
  },
  {
    "word": "ぼうし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "boushi",
    "translation": "hats, hat"
  },
  {
    "word": "まあまあ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maamaa",
    "translation": "come on, decent, now, now, so-so, there, there, not bad, alright, passable, tolerable"
  },
  {
    "word": "まい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mai",
    "translation": "(counting thin objects)"
  },
  {
    "word": "まがり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "magari",
    "translation": "turn"
  },
  {
    "word": "ました",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mashita",
    "translation": "did, has (done)"
  },
  {
    "word": "-ましょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "-mashiyou",
    "translation": "Let's …"
  },
  {
    "word": "-ましょうか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "-mashiyouka",
    "translation": "should I, shall we, may I, should we, would you like to …'"
  },
  {
    "word": "ます",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "masu",
    "translation": "will..., (polite form)"
  },
  {
    "word": "まずい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mazui",
    "translation": "taste bad, bad, tastes bad"
  },
  {
    "word": "ません",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "masen",
    "translation": "does not, do not, (I) do not, will not"
  },
  {
    "word": "ませんか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "masenka",
    "translation": "do you want to, (a suggestion), would you like to"
  },
  {
    "word": "また",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mata",
    "translation": "again, back, see you ..."
  },
  {
    "word": "またね",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "matane",
    "translation": "bye, see you later"
  },
  {
    "word": "まだ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mada",
    "translation": "still, yet, too"
  },
  {
    "word": "まち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "machi",
    "translation": "town, city, district, wait"
  },
  {
    "word": "まっすぐ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "massugu",
    "translation": "straight"
  },
  {
    "word": "まったく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "matsutaku",
    "translation": "(not) at all, absolutely, not much, quite, completely, whatsoever, perfectly, totally"
  },
  {
    "word": "まって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "matte",
    "translation": "wait"
  },
  {
    "word": "まで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "made",
    "translation": "even, to, through, until, before"
  },
  {
    "word": "まど",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mado",
    "translation": "windows, window"
  },
  {
    "word": "まま",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mama",
    "translation": "still"
  },
  {
    "word": "まるい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "marui",
    "translation": "round"
  },
  {
    "word": "まんが",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "manga",
    "translation": "comics, comic books, manga"
  },
  {
    "word": "みがいて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "migaite",
    "translation": "brushing, polish, polished, brush"
  },
  {
    "word": "みがかないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "migakanaide",
    "translation": ""
  },
  {
    "word": "みがき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "migaki",
    "translation": "polish, brush"
  },
  {
    "word": "みじかい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mijikai",
    "translation": "short"
  },
  {
    "word": "みじかく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mijikaku",
    "translation": "short"
  },
  {
    "word": "みせ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mise",
    "translation": "shop, stores, store"
  },
  {
    "word": "みたい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mitai",
    "translation": "seems like, looks"
  },
  {
    "word": "みち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "michi",
    "translation": "paths, streets, street, way, roads, path, road"
  },
  {
    "word": "みどりいろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Midori iro",
    "translation": "green"
  },
  {
    "word": "みなさん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "minasan",
    "translation": "everybody, all, everyone"
  },
  {
    "word": "みみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mimi",
    "translation": "ear, ears"
  },
  {
    "word": "みんな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "minna",
    "translation": "everybody, the whole, all, everyone, you guys"
  },
  {
    "word": "むこう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mukou",
    "translation": "over there, the other side"
  },
  {
    "word": "むずかしい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muzukashii",
    "translation": "difficult"
  },
  {
    "word": "むずかしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muzukashiku",
    "translation": "hard, difficult"
  },
  {
    "word": "むら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mura",
    "translation": "village"
  },
  {
    "word": "むらさきいろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Murasaki iro",
    "translation": "purple"
  },
  {
    "word": "め",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "me",
    "translation": "buds, eyes, eye, sprout, sprouts, bud"
  },
  {
    "word": "も",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mo",
    "translation": "even, again, and, too, some, neither ... nor, or, also, as many as, both ... and, still, whole, any, as well"
  },
  {
    "word": "もう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mou",
    "translation": "again, already, anymore, oh, more, another, now, yet, any longer"
  },
  {
    "word": "もうし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "moushi",
    "translation": "my name is..."
  },
  {
    "word": "もし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "moshi",
    "translation": "if, supposing"
  },
  {
    "word": "もしもし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "moshimoshi",
    "translation": "hello (on the telephone)"
  },
  {
    "word": "もち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mochi",
    "translation": "carry, hold"
  },
  {
    "word": "もちろん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mochiron",
    "translation": "of course"
  },
  {
    "word": "もって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "motte",
    "translation": "carry, have"
  },
  {
    "word": "もっと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "motto",
    "translation": "more"
  },
  {
    "word": "もの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mono",
    "translation": "what, piece, anything, things, thing, stuff, something, property"
  },
  {
    "word": "もらい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "morai",
    "translation": "to receive"
  },
  {
    "word": "もん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mon",
    "translation": "gate"
  },
  {
    "word": "もんだい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mondai",
    "translation": "question, problem, problems, questions"
  },
  {
    "word": "や",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ya",
    "translation": "and, or"
  },
  {
    "word": "やかん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yakan",
    "translation": "kettles, kettle"
  },
  {
    "word": "やきゅう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yakyuu",
    "translation": "baseball"
  },
  {
    "word": "やさい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasai",
    "translation": "vegetables"
  },
  {
    "word": "やさしい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasashii",
    "translation": "kind, easy, nice"
  },
  {
    "word": "やさしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasashiku",
    "translation": "easy"
  },
  {
    "word": "やすい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasui",
    "translation": "cheap"
  },
  {
    "word": "やすく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasuku",
    "translation": "cheap"
  },
  {
    "word": "やすんだ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasunda",
    "translation": "took a day off from, rest"
  },
  {
    "word": "やせて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasete",
    "translation": "slim down, thin, lose weight"
  },
  {
    "word": "やって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yatte",
    "translation": "do, play, did, did (and), conducting, playing, doing, running, giving, having"
  },
  {
    "word": "やっと",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yatsuto",
    "translation": "at last, barely, finally"
  },
  {
    "word": "やめ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yame",
    "translation": "stop"
  },
  {
    "word": "やめて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yamete",
    "translation": "left (and), quit (and), stop, stopped (and), (please) no"
  },
  {
    "word": "やめないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yamenaide",
    "translation": "do not leave, do not stop"
  },
  {
    "word": "やめる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yameru",
    "translation": "abstain from, quit, abstain, leave"
  },
  {
    "word": "やらないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yaranaide",
    "translation": "do not"
  },
  {
    "word": "やり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yari",
    "translation": "do, play"
  },
  {
    "word": "やる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yaru",
    "translation": "do, play, does, plays"
  },
  {
    "word": "ゆうがた",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuugata",
    "translation": "evening"
  },
  {
    "word": "ゆうはん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuuhan",
    "translation": "dinner"
  },
  {
    "word": "ゆうびんきょく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuubinkyoku",
    "translation": "post office"
  },
  {
    "word": "ゆうべ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuube",
    "translation": "last night"
  },
  {
    "word": "ゆうめい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuumei",
    "translation": "famous"
  },
  {
    "word": "ゆき",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuki",
    "translation": "(headed) for, snow, (bound) for"
  },
  {
    "word": "ゆっくり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yukkuri",
    "translation": "slow, slowly"
  },
  {
    "word": "よ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yo",
    "translation": "... you know, ... won't you"
  },
  {
    "word": "よう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "you",
    "translation": "so that"
  },
  {
    "word": "ようこそ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youkoso",
    "translation": "welcome"
  },
  {
    "word": "ような",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youna",
    "translation": "like/similar to"
  },
  {
    "word": "ように",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youni",
    "translation": "that, remember to, (not) to, as, (so) that, like"
  },
  {
    "word": "ようふく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youhuku",
    "translation": "(Western) clothes, (Western) clothing"
  },
  {
    "word": "曜日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youbi",
    "translation": "day of the week"
  },
  {
    "word": "よかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yokatta",
    "translation": "I am glad, better, was good, I am relieved, were good"
  },
  {
    "word": "よく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yoku",
    "translation": "good, often, nicely, hard, well, better, a lot, closely, carefully, clearly, frequent"
  },
  {
    "word": "よこ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yoko",
    "translation": "beside/lying down"
  },
  {
    "word": "よし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yoshi",
    "translation": "alright, okay"
  },
  {
    "word": "よび",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yobi",
    "translation": "call"
  },
  {
    "word": "よむ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yomu",
    "translation": "read"
  },
  {
    "word": "より",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yori",
    "translation": "over, since, from, than, more"
  },
  {
    "word": "よる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yoru",
    "translation": "evening, night"
  },
  {
    "word": "よろしく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yoroshiku",
    "translation": "(his) regards, (my) regards, nice to meet you, treat me kindly, (her) regards"
  },
  {
    "word": "よわい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yowai",
    "translation": "weak"
  },
  {
    "word": "よわく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yowaku",
    "translation": "weakly, weak"
  },
  {
    "word": "よん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yon",
    "translation": "four"
  },
  {
    "word": "よんで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yonde",
    "translation": "call"
  },
  {
    "word": "ら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ra",
    "translation": "when, if"
  },
  {
    "word": "り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ri",
    "translation": "things like"
  },
  {
    "word": "りくじょう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rikujou",
    "translation": "track and field"
  },
  {
    "word": "りっぱ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ritsupa",
    "translation": "admirable, magnificent, splendid"
  },
  {
    "word": "留学生",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryuugakusei",
    "translation": "foreign exchange student, foreign exchange students"
  },
  {
    "word": "りょうしん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryoushin",
    "translation": "parents"
  },
  {
    "word": "りょうり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryouri",
    "translation": "cuisine, dishes, food, cooking, cook, dish, meal"
  },
  {
    "word": "りょこう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryokou",
    "translation": "travel, trip"
  },
  {
    "word": "ある",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aru",
    "translation": "is (she), are (you), is (it), am (I), are (they), have, is (that), is (he), has"
  },
  {
    "word": "れい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rei",
    "translation": "zero"
  },
  {
    "word": "れいぞうこ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "reizouko",
    "translation": "refrigerators, fridges, refrigerator, fridge"
  },
  {
    "word": "れんしゅう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "renshuu",
    "translation": "practice"
  },
  {
    "word": "ろうか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rouka",
    "translation": "hallway, corridor, hallways"
  },
  {
    "word": "ろく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "roku",
    "translation": "satisfactory, six"
  },
  {
    "word": "わ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wa",
    "translation": "... you know (female), (for counting birds), (for counting rabbits)"
  },
  {
    "word": "わかい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wakai",
    "translation": "young"
  },
  {
    "word": "わかって",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wakatte",
    "translation": "understand, understanding"
  },
  {
    "word": "わかり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wakari",
    "translation": "understand"
  },
  {
    "word": "わかれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wakare",
    "translation": "break up"
  },
  {
    "word": "わがまま",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wagamama",
    "translation": "selfishness"
  },
  {
    "word": "わすれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wasure",
    "translation": "forget"
  },
  {
    "word": "わたし",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "watashi",
    "translation": "I, me, give, pass"
  },
  {
    "word": "わるい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "warui",
    "translation": "poor, bad"
  },
  {
    "word": "わるく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "waruku",
    "translation": "bad"
  },
  {
    "word": "を",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wo",
    "translation": "on, about, from, for, of, in, upon, to, through, at, (object marker), along, with"
  },
  {
    "word": "ん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "n",
    "translation": "(explanation/emphasis)"
  },
  {
    "word": "んです",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ndesu",
    "translation": ""
  },
  {
    "word": "アイヌ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ainu",
    "translation": "Ainu"
  },
  {
    "word": "アジア",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "azia",
    "translation": "Asian, Asia"
  },
  {
    "word": "アニメ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "anime",
    "translation": "anime"
  },
  {
    "word": "アパート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "apato",
    "translation": "apartment"
  },
  {
    "word": "アメリカ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "amerika",
    "translation": "the United States, the US, American, America, the USA"
  },
  {
    "word": "アレルギー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "arerugi",
    "translation": "allergies, allergy, allergic"
  },
  {
    "word": "アンケート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "anketo",
    "translation": "questionnaires, surveys, survey, questionnaire"
  },
  {
    "word": "イギリス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "igirisu",
    "translation": "the UK"
  },
  {
    "word": "イケメン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikemen",
    "translation": "good-looking man, hunk, good-looking men, stud, handsome"
  },
  {
    "word": "エアコン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eakon",
    "translation": "air conditioner, air conditioners"
  },
  {
    "word": "エネルギー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "enerugi",
    "translation": "energy"
  },
  {
    "word": "エレベーター",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "erebeta",
    "translation": "elevator"
  },
  {
    "word": "エンジン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "enzin",
    "translation": "engine"
  },
  {
    "word": "オタク",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otaku",
    "translation": "nerds, geeks, otakus, otaku, geek, nerd"
  },
  {
    "word": "オリンピック",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "orinpikku",
    "translation": "the Olympics, Olympic (Games)"
  },
  {
    "word": "オレンジいろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Orenzi iro",
    "translation": "orange"
  },
  {
    "word": "オレンジ色",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "orenzi iro",
    "translation": "orange"
  },
  {
    "word": "オーストラリア",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "osutoraria",
    "translation": "Australia"
  },
  {
    "word": "オーブン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "obun",
    "translation": "ovens, oven"
  },
  {
    "word": "カギ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kagi",
    "translation": "keys, key"
  },
  {
    "word": "カップ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kappu",
    "translation": "cups, cup"
  },
  {
    "word": "カナダ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanada",
    "translation": "Canada"
  },
  {
    "word": "カバン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaban",
    "translation": "bags, backpack, (the) bag, bag"
  },
  {
    "word": "カフェ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kafue",
    "translation": "cafes, cafe"
  },
  {
    "word": "カメラ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kamera",
    "translation": "camera, cameras"
  },
  {
    "word": "カラオケ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karaoke",
    "translation": "karaoke"
  },
  {
    "word": "カレンダー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karenda",
    "translation": "calendar"
  },
  {
    "word": "カレー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kare",
    "translation": "curry"
  },
  {
    "word": "ガス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gasu",
    "translation": ""
  },
  {
    "word": "ガラス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "garasu",
    "translation": "glass"
  },
  {
    "word": "キロ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kiro",
    "translation": "kilograms, kilometer, kilogram, kilometers"
  },
  {
    "word": "ギター",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gita",
    "translation": "guitar"
  },
  {
    "word": "クラス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kurasu",
    "translation": "section, classes, class"
  },
  {
    "word": "クリック",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kurikku",
    "translation": "click"
  },
  {
    "word": "グラス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gurasu",
    "translation": "glass, glasses"
  },
  {
    "word": "グラム",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "guramu",
    "translation": "gram, grams"
  },
  {
    "word": "ケーキ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "keki",
    "translation": "cake, cakes"
  },
  {
    "word": "ゲーム",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gemu",
    "translation": "(video) game, (video) games"
  },
  {
    "word": "コスプレ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kosupure",
    "translation": "cosplay, dress up"
  },
  {
    "word": "コップ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koppu",
    "translation": "cups, glass, glasses, cup"
  },
  {
    "word": "コピー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kopi",
    "translation": "copy"
  },
  {
    "word": "コンサート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konsato",
    "translation": "concerts, concert"
  },
  {
    "word": "コンセント",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konsento",
    "translation": "outlet"
  },
  {
    "word": "コンビニ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konbini",
    "translation": "convenience stores, convenience store"
  },
  {
    "word": "コート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koto",
    "translation": "coat"
  },
  {
    "word": "コーヒー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kohi",
    "translation": "coffee"
  },
  {
    "word": "ゴルフ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gorufu",
    "translation": "golf"
  },
  {
    "word": "サッカー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakka",
    "translation": "soccer, football"
  },
  {
    "word": "サラダ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sarada",
    "translation": "salads, salad"
  },
  {
    "word": "サラリーマン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sarariman",
    "translation": "office workers, office worker, white-collar workers, salarymen, white-collar worker, salaryman"
  },
  {
    "word": "サンドイッチ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sandoicchi",
    "translation": "sandwich, sandwiches"
  },
  {
    "word": "サービス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sabisu",
    "translation": "services, (free) service, (free) services"
  },
  {
    "word": "シャツ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shyatsu",
    "translation": "shirt, shirts"
  },
  {
    "word": "シャワー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shyawa",
    "translation": "shower, showers"
  },
  {
    "word": "ショック",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shyokku",
    "translation": "shocking, shock"
  },
  {
    "word": "ジャケット",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jyaketto",
    "translation": "jacket, jackets"
  },
  {
    "word": "ジュース",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jyusu",
    "translation": "juice"
  },
  {
    "word": "ジョン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jyon",
    "translation": "John, Jon"
  },
  {
    "word": "スカート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sukato",
    "translation": "skirt, skirts"
  },
  {
    "word": "スキー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suki",
    "translation": "skiing"
  },
  {
    "word": "ステーキ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suteki",
    "translation": "steak"
  },
  {
    "word": "ストレス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sutoresu",
    "translation": "stress"
  },
  {
    "word": "スプーン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "supun",
    "translation": "spoons, spoon"
  },
  {
    "word": "スペイン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "supein",
    "translation": "Spain"
  },
  {
    "word": "スポーツ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "supotsu",
    "translation": "sport, sports"
  },
  {
    "word": "スマホ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sumaho",
    "translation": "(my) smartphone"
  },
  {
    "word": "スープ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "supu",
    "translation": "soup, soups"
  },
  {
    "word": "ズボン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zubon",
    "translation": "trousers, pants"
  },
  {
    "word": "セーター",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seta",
    "translation": "sweater, sweaters"
  },
  {
    "word": "ゼルダ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zeruda",
    "translation": "Zelda (female name)"
  },
  {
    "word": "ゼロ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zero",
    "translation": "zero"
  },
  {
    "word": "ソース",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sosu",
    "translation": "sauce"
  },
  {
    "word": "タクシー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "takushi",
    "translation": "taxi, taxis"
  },
  {
    "word": "タバコ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tabako",
    "translation": "cigarettes, cigarette, tobacco, smoking"
  },
  {
    "word": "ダイヤモンド",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daiyamondo",
    "translation": "diamond, diamonds"
  },
  {
    "word": "チェックアウト",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chiekkuauto",
    "translation": "check out"
  },
  {
    "word": "チェックイン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chiekkuin",
    "translation": "check-in, check in"
  },
  {
    "word": "チケット",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chiketsuto",
    "translation": "tickets, ticket"
  },
  {
    "word": "チーム",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chimu",
    "translation": "team, teams"
  },
  {
    "word": "テスト",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tesuto",
    "translation": "test, tests, exams, exam"
  },
  {
    "word": "テニス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tenisu",
    "translation": "tennis"
  },
  {
    "word": "テレビ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "terebi",
    "translation": "TV, televisions, television, TV show, TVs"
  },
  {
    "word": "テーブル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "teburu",
    "translation": "(the) table, table, tables"
  },
  {
    "word": "テーマ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tema",
    "translation": "topic, subject"
  },
  {
    "word": "デパート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "depato",
    "translation": "department stores, department store"
  },
  {
    "word": "デート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "deto",
    "translation": "date"
  },
  {
    "word": "トイレ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toire",
    "translation": "toilets, bathroom, bathrooms, toilet, restrooms, the bathroom, restroom"
  },
  {
    "word": "ドア",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doa",
    "translation": "door, doors"
  },
  {
    "word": "ドイツ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doitsu",
    "translation": "Germany"
  },
  {
    "word": "ドル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doru",
    "translation": "dollars, dollar"
  },
  {
    "word": "ドレス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doresu",
    "translation": "dress, dresses"
  },
  {
    "word": "ナイフ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "naifu",
    "translation": "knives, knife"
  },
  {
    "word": "ニュース",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nyusu",
    "translation": "news, the news"
  },
  {
    "word": "ネクタイ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nekutai",
    "translation": "tie, necktie"
  },
  {
    "word": "ノート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "noto",
    "translation": "note, notebooks, notes, notebook"
  },
  {
    "word": "ハガキ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hagaki",
    "translation": "postcard"
  },
  {
    "word": "ハワイ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hawai",
    "translation": "Hawaii"
  },
  {
    "word": "ハンカチ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hankachi",
    "translation": "handkerchiefs, handkerchief"
  },
  {
    "word": "ハンドバッグ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "handobagu",
    "translation": "handbags, pocketbook, purse, purses, handbag, pocketbooks"
  },
  {
    "word": "バス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "basu",
    "translation": "bus, buses"
  },
  {
    "word": "バスケットボール",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "basukettoboru",
    "translation": "basketball"
  },
  {
    "word": "バター",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bata",
    "translation": "butter"
  },
  {
    "word": "バナナ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "banana",
    "translation": "bananas, banana"
  },
  {
    "word": "パイロット",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pairotto",
    "translation": "pilot"
  },
  {
    "word": "パスタ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pasuta",
    "translation": "pasta"
  },
  {
    "word": "パスポート",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pasupoto",
    "translation": "passport"
  },
  {
    "word": "パソコン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pasokon",
    "translation": "PC, computer, (personal) computer, computers"
  },
  {
    "word": "パン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pan",
    "translation": "bread"
  },
  {
    "word": "パンダ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "panda",
    "translation": "panda"
  },
  {
    "word": "パンツ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pantsu",
    "translation": "underwear"
  },
  {
    "word": "パーセント",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pasento",
    "translation": "percent"
  },
  {
    "word": "パーティー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "patei",
    "translation": "party, parties"
  },
  {
    "word": "ビザ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "biza",
    "translation": "visa"
  },
  {
    "word": "ビデオ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bideo",
    "translation": "video, videos"
  },
  {
    "word": "ビール",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "biru",
    "translation": "beer"
  },
  {
    "word": "ピアノ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "piano",
    "translation": "piano"
  },
  {
    "word": "ピンクいろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Pinku iro",
    "translation": "pink"
  },
  {
    "word": "ピンク色",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Pinku iro",
    "translation": "pink"
  },
  {
    "word": "ファイル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuairu",
    "translation": "files, file"
  },
  {
    "word": "ファックス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuakkusu",
    "translation": "fax, fax (machine)"
  },
  {
    "word": "フォーク",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuoku",
    "translation": "fork, forks"
  },
  {
    "word": "フランス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furansu",
    "translation": "French, France"
  },
  {
    "word": "フロント",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furonto",
    "translation": "front desk, reception"
  },
  {
    "word": "ブラウス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "burausu",
    "translation": "blouse"
  },
  {
    "word": "プラスチック",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "purasuchikku",
    "translation": "plastic"
  },
  {
    "word": "プレゼント",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "purezento",
    "translation": "gifts, presents, present, gift"
  },
  {
    "word": "プール",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "puru",
    "translation": "(swimming) pools, (swimming) pool"
  },
  {
    "word": "ベジタリアン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bejitarian",
    "translation": "vegetarian"
  },
  {
    "word": "ベッド",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "beddo",
    "translation": "beds, bed"
  },
  {
    "word": "ベルト",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "beruto",
    "translation": "belt, belts"
  },
  {
    "word": "ペット",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "petto",
    "translation": "pet, pets"
  },
  {
    "word": "ペットボトル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pettobotoru",
    "translation": "PET bottle, plastic bottles, plastic bottle, PET bottles"
  },
  {
    "word": "ペン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "pen",
    "translation": "pen, pens"
  },
  {
    "word": "ページ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "peji",
    "translation": "page, pages"
  },
  {
    "word": "ホテル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hoteru",
    "translation": "hotel, hotels"
  },
  {
    "word": "ボス",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bosu",
    "translation": "mob, boss"
  },
  {
    "word": "ボタン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "botan",
    "translation": "button"
  },
  {
    "word": "ボール",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "boru",
    "translation": "ball, balls"
  },
  {
    "word": "ポケット",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "poketto",
    "translation": "pocket, pockets"
  },
  {
    "word": "ポスト",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "posuto",
    "translation": "postbox, mailbox"
  },
  {
    "word": "ママチャリ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mamachari",
    "translation": "granny bikes, granny bike"
  },
  {
    "word": "マリア",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maria",
    "translation": "Maria"
  },
  {
    "word": "マンガ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "manga",
    "translation": "comics, comic books, comic book, manga"
  },
  {
    "word": "マンション",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "manshiyon",
    "translation": "condominium"
  },
  {
    "word": "ミント",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "minto",
    "translation": "mint"
  },
  {
    "word": "メイド",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "meido",
    "translation": "maids, maid"
  },
  {
    "word": "メガネ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "megane",
    "translation": "glasses"
  },
  {
    "word": "メダル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "medaru",
    "translation": "medal"
  },
  {
    "word": "メニュー",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "meniyu",
    "translation": "menu, menus"
  },
  {
    "word": "メモ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "memo",
    "translation": "note, notes"
  },
  {
    "word": "メートル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "metoru",
    "translation": "meters, meter"
  },
  {
    "word": "メール",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "meru",
    "translation": "email"
  },
  {
    "word": "ラジオ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rajio",
    "translation": "(the) radio, radio, radios"
  },
  {
    "word": "ラーメン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ramen",
    "translation": "ramen"
  },
  {
    "word": "リサイクル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "risaikuru",
    "translation": "recycle, recycling"
  },
  {
    "word": "レストラン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "resutoran",
    "translation": "restaurant, restaurants"
  },
  {
    "word": "レベル",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "reberu",
    "translation": "levels, level"
  },
  {
    "word": "レモン",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "remon",
    "translation": "lemon, lemons"
  },
  {
    "word": "ロシア",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "roshia",
    "translation": "Russian, Russia"
  },
  {
    "word": "ロボット",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "robotto",
    "translation": "robots, robot"
  },
  {
    "word": "ローマ字",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "romaji",
    "translation": "Latin script, Roman letters"
  },
  {
    "word": "ワイシャツ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "waishatsu",
    "translation": "dress shirt"
  },
  {
    "word": "ヶ月",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kagetsu",
    "translation": "months, month counter"
  },
  {
    "word": "一",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ichi",
    "translation": "one, first"
  },
  {
    "word": "一つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hitotsu",
    "translation": "one (thing)"
  },
  {
    "word": "一人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hitori",
    "translation": "one (person)"
  },
  {
    "word": "一個",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikko",
    "translation": "one (fragment)"
  },
  {
    "word": "一切",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "issai",
    "translation": "it all, everything, at all, any, whatsoever"
  },
  {
    "word": "一日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ichinichi",
    "translation": "one day, a day, first"
  },
  {
    "word": "一昨年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ototoshi",
    "translation": "the year before last"
  },
  {
    "word": "一生懸命",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isshoukenmei",
    "translation": " as hard as she can, for dear life, as hard as she could, as hard as he can, as hard as I can, as fast as I could"
  },
  {
    "word": "一種",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isshu",
    "translation": "one kind, sort, species"
  },
  {
    "word": "七",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shichi/nana",
    "translation": "seven, seventh, 7"
  },
  {
    "word": "七つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nanatsu",
    "translation": "seven (things)"
  },
  {
    "word": "七日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nanoka",
    "translation": "seven days, seventh"
  },
  {
    "word": "万",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "man",
    "translation": "ten thousand"
  },
  {
    "word": "丈夫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joubu",
    "translation": "durable, sturdy"
  },
  {
    "word": "三",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "San",
    "translation": "three"
  },
  {
    "word": "三つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mittsu",
    "translation": "three"
  },
  {
    "word": "三日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mikka",
    "translation": "three days, third"
  },
  {
    "word": "上",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ue",
    "translation": "upper, on, atop, up, on top of, above"
  },
  {
    "word": "上司",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joushi",
    "translation": "bosses, boss"
  },
  {
    "word": "上手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jouzu",
    "translation": "skilled at, good at"
  },
  {
    "word": "上着",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uwagi",
    "translation": "jacket, coat"
  },
  {
    "word": "上野",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ueno",
    "translation": "Ueno"
  },
  {
    "word": "下",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shita",
    "translation": "down, lower, below, bottom"
  },
  {
    "word": "下手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "heta",
    "translation": "bad at, not good at"
  },
  {
    "word": "不便",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuben",
    "translation": "inconvenient, not convenient"
  },
  {
    "word": "不可能",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fukanou",
    "translation": "impossible"
  },
  {
    "word": "不安",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuan",
    "translation": "anxiety, uneasy, angst, anxious, anxieties, uneasiness"
  },
  {
    "word": "不思議",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fushigi",
    "translation": "mysterious, curious, wonderful, strange"
  },
  {
    "word": "不明",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fumei",
    "translation": "unknown, unclear, uncertain"
  },
  {
    "word": "不気味",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bukimi",
    "translation": "eerie, spooky, creepy, uncanny, bizarre, ominous"
  },
  {
    "word": "不足",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fusoku",
    "translation": "deprivation, lack, shortage"
  },
  {
    "word": "世紀",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seiki",
    "translation": "centuries, century"
  },
  {
    "word": "世論",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yoron",
    "translation": "public opinion"
  },
  {
    "word": "丘",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oka",
    "translation": "hill, hills"
  },
  {
    "word": "両方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryouhou",
    "translation": "both, neither"
  },
  {
    "word": "両親",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryoushin",
    "translation": "(my) parents"
  },
  {
    "word": "中",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "naka/chuu",
    "translation": "during, while, in the process of, inside, in the middle"
  },
  {
    "word": "中国",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chuugoku",
    "translation": "China, Chinese"
  },
  {
    "word": "中学",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chuugaku",
    "translation": "junior high school, middle school"
  },
  {
    "word": "中止",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chuushi",
    "translation": "Interruption/cancellation"
  },
  {
    "word": "中身",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nakami",
    "translation": "content, contents"
  },
  {
    "word": "主",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omo",
    "translation": "chief/main"
  },
  {
    "word": "乗り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nori",
    "translation": "ride"
  },
  {
    "word": "乗り物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "norimono",
    "translation": "vehicle, vehicles, vehicle"
  },
  {
    "word": "乗客",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joukyaku",
    "translation": "passengers"
  },
  {
    "word": "九",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyuu",
    "translation": "9, nine"
  },
  {
    "word": "九つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kokonotsu",
    "translation": "nine (things)"
  },
  {
    "word": "九日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kokonoka",
    "translation": "nine days, ninth"
  },
  {
    "word": "乾燥",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kansou",
    "translation": "dryness/aridity"
  },
  {
    "word": "予報",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yohou",
    "translation": "forecast"
  },
  {
    "word": "予習",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yoshuu",
    "translation": "preparation for lesson"
  },
  {
    "word": "事件",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jiken",
    "translation": "case, matters, incident, incidents, matter"
  },
  {
    "word": "事務所",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jimusho",
    "translation": "office"
  },
  {
    "word": "事故",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jiko",
    "translation": "accident, accidents"
  },
  {
    "word": "二",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ni",
    "translation": "two"
  },
  {
    "word": "二つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futatsu",
    "translation": "two (things)"
  },
  {
    "word": "二人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futari",
    "translation": "two people, they"
  },
  {
    "word": "二個",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "niko",
    "translation": "two (fragments)"
  },
  {
    "word": "二十日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hatsuka",
    "translation": "twenty days, twentieth"
  },
  {
    "word": "二日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futsuka",
    "translation": "second, two days"
  },
  {
    "word": "二日酔い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futsukayoi",
    "translation": "hungover, hangover"
  },
  {
    "word": "五",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "go",
    "translation": "five, 5"
  },
  {
    "word": "五つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itsutsu",
    "translation": "five (things)"
  },
  {
    "word": "五日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itsuka",
    "translation": "five days, fifth"
  },
  {
    "word": "亡くなり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nakunari/u",
    "translation": "die"
  },
  {
    "word": "交差点",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kousaten",
    "translation": "crossing, intersection"
  },
  {
    "word": "交通",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koutsuu",
    "translation": "traffic, transportation"
  },
  {
    "word": "京都",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoto",
    "translation": "Kyoto"
  },
  {
    "word": "人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hito (jin)",
    "translation": "someone, somebody, people, anyone, person, human, no one"
  },
  {
    "word": "人々",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hitobito",
    "translation": "people"
  },
  {
    "word": "人口",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jinkou",
    "translation": "(the) population, population"
  },
  {
    "word": "人権",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jinken",
    "translation": "human rights"
  },
  {
    "word": "人気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ninki",
    "translation": "popularity"
  },
  {
    "word": "人生",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jinsei",
    "translation": "One’s life"
  },
  {
    "word": "人間",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ningen",
    "translation": "human"
  },
  {
    "word": "今",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ima (kon)",
    "translation": "presently, just, this, currently, current, these days, now"
  },
  {
    "word": "今回",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konkai",
    "translation": "this time"
  },
  {
    "word": "今夜",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konya",
    "translation": "tonight"
  },
  {
    "word": "今年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotoshi",
    "translation": "this year"
  },
  {
    "word": "今日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyou",
    "translation": "today"
  },
  {
    "word": "今晩",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konban",
    "translation": "tonight, this evening"
  },
  {
    "word": "今月",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kongetsu",
    "translation": "this month"
  },
  {
    "word": "今朝",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kesa",
    "translation": "this morning"
  },
  {
    "word": "今週",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "konshuu",
    "translation": "this week"
  },
  {
    "word": "仏教",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bukkyou",
    "translation": "Buddhism"
  },
  {
    "word": "仕事",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shigoto",
    "translation": "workload, jobs, working, business, work, task, job, tasks, position"
  },
  {
    "word": "仕方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shikata",
    "translation": "way to do it, method"
  },
  {
    "word": "他人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanin/hitosama",
    "translation": "other people, others"
  },
  {
    "word": "令和",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "reiwa",
    "translation": "Reiwa era (2019-present)"
  },
  {
    "word": "以上",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ijou",
    "translation": "over, more than"
  },
  {
    "word": "以下",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ika",
    "translation": "below, less than"
  },
  {
    "word": "以外",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "igai",
    "translation": "except for, aside from"
  },
  {
    "word": "休み",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasumi",
    "translation": "break, on holiday, vacation, rest, a day off"
  },
  {
    "word": "休んだ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasunda",
    "translation": "took a day off from, got some rest, rested, was absent from, were absent from"
  },
  {
    "word": "休暇",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyuuka",
    "translation": "vacation"
  },
  {
    "word": "会い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ai",
    "translation": "meet, see"
  },
  {
    "word": "会場",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaijou",
    "translation": "venue, site, stadium"
  },
  {
    "word": "会社",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaisha",
    "translation": "firm, company, agency, companies, office"
  },
  {
    "word": "会社員",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaishain",
    "translation": "office worker"
  },
  {
    "word": "会話",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaiwa",
    "translation": "conversations, conversation"
  },
  {
    "word": "会議",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaigi",
    "translation": "conference, meetings, meeting, conferences"
  },
  {
    "word": "伝説",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "densetsu",
    "translation": "folklore, legend"
  },
  {
    "word": "似て",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nite  (niru)",
    "translation": "to resemble"
  },
  {
    "word": "住んで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sunde",
    "translation": "live, lived"
  },
  {
    "word": "体",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karada",
    "translation": "(my) body, (your) body, (her) body, (his) body"
  },
  {
    "word": "体操",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taisou",
    "translation": "warm-up, gymnastics, exercise"
  },
  {
    "word": "体重",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taijuu",
    "translation": "weight"
  },
  {
    "word": "何",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nan/nani",
    "translation": "what, which, anything"
  },
  {
    "word": "何と",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nanto",
    "translation": "how, what"
  },
  {
    "word": "余分",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yobun",
    "translation": "superfluous, unnecessary"
  },
  {
    "word": "余地",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yochi",
    "translation": "room (for)"
  },
  {
    "word": "作り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukuri",
    "translation": "produce"
  },
  {
    "word": "作り方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukurikata",
    "translation": "how to make"
  },
  {
    "word": "作品",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakuhin",
    "translation": "pieces, work, art, piece, works"
  },
  {
    "word": "作家",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakka",
    "translation": "writer, authors, writers, author"
  },
  {
    "word": "作成",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakusei",
    "translation": "making"
  },
  {
    "word": "作文",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakubun",
    "translation": "essay, essays, composition, compositions"
  },
  {
    "word": "作物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakumotsu",
    "translation": "crops, crop"
  },
  {
    "word": "使い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukai (tsukau)",
    "translation": "use"
  },
  {
    "word": "使い方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukaikata",
    "translation": "how to use"
  },
  {
    "word": "使って",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukatte",
    "translation": "using, use, through"
  },
  {
    "word": "便利",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "benri",
    "translation": "convenient"
  },
  {
    "word": "保存",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hozon",
    "translation": "preservation/maintenance"
  },
  {
    "word": "信仰",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinkou",
    "translation": "faith, belief"
  },
  {
    "word": "俳優",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haiyuu",
    "translation": "actors, (an) actor"
  },
  {
    "word": "俺",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ore",
    "translation": "me (male), I (male)"
  },
  {
    "word": "個",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ko",
    "translation": "(counter for objects)"
  },
  {
    "word": "倍",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bai",
    "translation": "Counter n times"
  },
  {
    "word": "借りないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karinaide",
    "translation": "do not borrow"
  },
  {
    "word": "借りる",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kariru",
    "translation": "borrow, rent"
  },
  {
    "word": "借金",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shakkin",
    "translation": "loan, debt"
  },
  {
    "word": "値引き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nebiki",
    "translation": "discount, price reduction"
  },
  {
    "word": "値段",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nedan",
    "translation": "prices, price, value"
  },
  {
    "word": "偉大",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "idai",
    "translation": "great"
  },
  {
    "word": "健康",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kenkou",
    "translation": "health, fitness"
  },
  {
    "word": "側",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gawa (or soba)",
    "translation": "side (or near)"
  },
  {
    "word": "偶然",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "guuzen",
    "translation": "accident, happened to, happen to, coincidence"
  },
  {
    "word": "傘",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kasa",
    "translation": "umbrella, umbrellas"
  },
  {
    "word": "働き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hataraki",
    "translation": "function, functions, working, work, role, workings, works"
  },
  {
    "word": "僕",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "boku",
    "translation": "me, I"
  },
  {
    "word": "元気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "genki",
    "translation": "how are ..., energy, well, how is ..., fine, energetic"
  },
  {
    "word": "兄",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ani",
    "translation": "(elder) brothers, (older) brothers, brother, (big) brothers, (older) brother, (elder) brother, (big) brother"
  },
  {
    "word": "兄弟",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoudai",
    "translation": "siblings, brother, brothers"
  },
  {
    "word": "充電",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juuden",
    "translation": "charge"
  },
  {
    "word": "先月",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sengetsu",
    "translation": "last month"
  },
  {
    "word": "先生",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sensei",
    "translation": "doctor, Mister, Professor, Mrs., Ms., Mr., (a) ... teacher, Miss, teacher, teachers"
  },
  {
    "word": "先輩",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senpai",
    "translation": "superiors, your seniors, senior colleague, senpai (title), mentor, upperclassmen, above me, my senior"
  },
  {
    "word": "先週",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senshuu",
    "translation": "last week"
  },
  {
    "word": "光",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hikari",
    "translation": "light"
  },
  {
    "word": "入り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hairi",
    "translation": "enter"
  },
  {
    "word": "入り口",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iriguchi",
    "translation": "entry, entrance"
  },
  {
    "word": "入れ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ire-",
    "translation": "pour, put in"
  },
  {
    "word": "入学",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nyuugaku",
    "translation": "school entrance, matriculation"
  },
  {
    "word": "入院",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nyuuin",
    "translation": "hospitalisation"
  },
  {
    "word": "全員",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zenin",
    "translation": "everybody, everyone, all (of)"
  },
  {
    "word": "全然",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zenzen",
    "translation": "no ... at all, not ... at all"
  },
  {
    "word": "全部",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zenbu",
    "translation": "all (of them), everything, all (of it), all (of this)"
  },
  {
    "word": "八",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hachi",
    "translation": "eight"
  },
  {
    "word": "八つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yattsu",
    "translation": "eight (things)"
  },
  {
    "word": "八日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youka",
    "translation": "eight days, eighth"
  },
  {
    "word": "八百屋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yaoya",
    "translation": "vegetable store, produce market, greengrocer"
  },
  {
    "word": "公務員",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koumuin",
    "translation": "government employee, public servants, public servant, government employees"
  },
  {
    "word": "公園",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kouen",
    "translation": "park, garden"
  },
  {
    "word": "六",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "roku",
    "translation": "six, 6"
  },
  {
    "word": "六つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muttsu",
    "translation": "six (things)"
  },
  {
    "word": "六日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muika",
    "translation": "sixth, six days"
  },
  {
    "word": "兵士",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "heishi",
    "translation": "soldier, soldiers"
  },
  {
    "word": "具体的",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gutaiteki",
    "translation": "concrete/solid"
  },
  {
    "word": "具合",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "guai",
    "translation": "health/state"
  },
  {
    "word": "円",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "en/maru",
    "translation": "circle, makes a circle, yen"
  },
  {
    "word": "冊",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "satsu",
    "translation": "(counter for books)"
  },
  {
    "word": "再び",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futatabi",
    "translation": "again, once more"
  },
  {
    "word": "再来年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sarainen",
    "translation": "the year after next"
  },
  {
    "word": "再来週",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saraishuu",
    "translation": "the week after next"
  },
  {
    "word": "冗談",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joudan",
    "translation": "joke"
  },
  {
    "word": "写真",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shashin",
    "translation": "photographs, picture, photograph, pictures, image, photos, photo, images, photography"
  },
  {
    "word": "冬",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuyu",
    "translation": "(the) winter"
  },
  {
    "word": "冷凍",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "reitou",
    "translation": "frozen"
  },
  {
    "word": "冷凍庫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "reitouko",
    "translation": "freezers, freezer"
  },
  {
    "word": "冷蔵庫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "reizouko",
    "translation": "refrigerators, fridges, refrigerator, fridge"
  },
  {
    "word": "出",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shutsu",
    "translation": "appear, participate"
  },
  {
    "word": "出かけ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dekake",
    "translation": "to depart (excursion)"
  },
  {
    "word": "出し",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dashi- (dasu)",
    "translation": "take out, serve, start to"
  },
  {
    "word": "出口",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "deguchi",
    "translation": "exit"
  },
  {
    "word": "出来事",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dekigoto",
    "translation": "affair, incident, event, occurrence"
  },
  {
    "word": "出発",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuppatsu",
    "translation": "departure, to depart, left"
  },
  {
    "word": "出身",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shusshin",
    "translation": "origin, come from, from, am from, home country, hometown"
  },
  {
    "word": "分",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fun",
    "translation": "minutes, minute"
  },
  {
    "word": "分野",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bunya",
    "translation": "field, areas, fields, area"
  },
  {
    "word": "切れ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kire",
    "translation": "slice, slices"
  },
  {
    "word": "切手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kitte",
    "translation": "(postage) stamps, (postage) stamp"
  },
  {
    "word": "切符",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kippu",
    "translation": "tickets, ticket"
  },
  {
    "word": "刑務所",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "keimusho",
    "translation": "prison, prisons"
  },
  {
    "word": "列島",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rettou",
    "translation": "island chain, archipelago, island chains, archipelagos"
  },
  {
    "word": "列車",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ressha",
    "translation": "train, trains"
  },
  {
    "word": "初心者",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shoshinsha",
    "translation": "novice, beginner"
  },
  {
    "word": "判断",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "handan",
    "translation": "judgement, decision, decisions"
  },
  {
    "word": "判決",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanketsu",
    "translation": "verdict"
  },
  {
    "word": "別",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "betsu",
    "translation": "separate"
  },
  {
    "word": "別々",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "betsubetsu",
    "translation": "individual"
  },
  {
    "word": "別れ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wakare",
    "translation": "farewell, parting, goodbye"
  },
  {
    "word": "利益",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rieki",
    "translation": "profit, profits"
  },
  {
    "word": "到着",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "touchaku",
    "translation": "arrival"
  },
  {
    "word": "制度",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seido",
    "translation": "system"
  },
  {
    "word": "制服",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seifuku",
    "translation": "uniforms, uniform"
  },
  {
    "word": "削除",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakujo",
    "translation": "deletion/erasure"
  },
  {
    "word": "前",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mae",
    "translation": "front, ago, before"
  },
  {
    "word": "前回",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zenkai",
    "translation": "last time"
  },
  {
    "word": "剣",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ken",
    "translation": "swords, sword"
  },
  {
    "word": "劇",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "geki",
    "translation": "drama, play, plays, dramas"
  },
  {
    "word": "劇場",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gekijou",
    "translation": "theaters, theater"
  },
  {
    "word": "力",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chikara",
    "translation": "strength, force, ability, power, forces, capacity"
  },
  {
    "word": "勇気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuuki",
    "translation": "courage"
  },
  {
    "word": "勇者",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuusha",
    "translation": "heroes, hero"
  },
  {
    "word": "勉強",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "benkyou",
    "translation": "studying, study, your studies, learning"
  },
  {
    "word": "動物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doubutsu",
    "translation": "animals, (an) animal, animal"
  },
  {
    "word": "動物園",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "doubutsuen",
    "translation": "zoos, zoo"
  },
  {
    "word": "北",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kita (hoku)",
    "translation": "(in the) north, northern"
  },
  {
    "word": "北海道",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Hokkaido",
    "translation": "Hokkaido"
  },
  {
    "word": "匹",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiki",
    "translation": "(counter for small anim.)"
  },
  {
    "word": "医学",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "igaku",
    "translation": "medicine, medical (science)"
  },
  {
    "word": "医者",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isha",
    "translation": "physician, doctor, physicians, doctors"
  },
  {
    "word": "十",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juu",
    "translation": "ten"
  },
  {
    "word": "十分",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juubun",
    "translation": "adequate, sufficient, enough"
  },
  {
    "word": "十日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tooka",
    "translation": "tenth, ten days"
  },
  {
    "word": "千",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sen",
    "translation": "thousand"
  },
  {
    "word": "千葉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Chiba",
    "translation": "Chiba"
  },
  {
    "word": "午前",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gozen",
    "translation": "in the morning, A.M."
  },
  {
    "word": "午後",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gogo",
    "translation": "P.M., (in the) afternoon"
  },
  {
    "word": "半",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "han",
    "translation": "half"
  },
  {
    "word": "半分",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanbun",
    "translation": "half (minute)"
  },
  {
    "word": "半袖",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hansode",
    "translation": "short sleeves, short-sleeve"
  },
  {
    "word": "卓球",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "takkyuu",
    "translation": "table tennis, ping pong"
  },
  {
    "word": "南",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Minami (nan)",
    "translation": "(the) south"
  },
  {
    "word": "単語",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tango",
    "translation": "word, words"
  },
  {
    "word": "博物館",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hakubutsukan",
    "translation": "museums, museum, (the) museum (of)"
  },
  {
    "word": "印刷",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "insatsu",
    "translation": "print"
  },
  {
    "word": "危険",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kiken",
    "translation": "dangerous, risk, danger"
  },
  {
    "word": "卵",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tamago",
    "translation": "(the) egg, (its) eggs, (the) eggs, (an) egg"
  },
  {
    "word": "原因",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "genin",
    "translation": "cause, causes"
  },
  {
    "word": "原宿",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Harajuku",
    "translation": "Harajuku"
  },
  {
    "word": "去年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyonen",
    "translation": "last year"
  },
  {
    "word": "友だち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tomodachi",
    "translation": "friends, friend"
  },
  {
    "word": "友人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuujin",
    "translation": "friend"
  },
  {
    "word": "友達",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tomodachi",
    "translation": "friends, friend"
  },
  {
    "word": "双子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futago",
    "translation": "twins"
  },
  {
    "word": "反応",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hannou",
    "translation": "response"
  },
  {
    "word": "取っ手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "totte/hanshu",
    "translation": "handle"
  },
  {
    "word": "取らないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toranaide",
    "translation": "do not take"
  },
  {
    "word": "受付",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uketsuke",
    "translation": "reception (desk)"
  },
  {
    "word": "口",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuchi",
    "translation": "taste, tastes, mouth, mouths"
  },
  {
    "word": "口座",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kouza",
    "translation": "account, accounts"
  },
  {
    "word": "古く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furuku",
    "translation": "old/formerly"
  },
  {
    "word": "可決",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaketsu",
    "translation": "approval/adoption"
  },
  {
    "word": "可能",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanou",
    "translation": "possible"
  },
  {
    "word": "可能性",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanousei",
    "translation": "possibilities, chances, chance, possibility"
  },
  {
    "word": "台",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Dai",
    "translation": "(counter for machines), (counter for furniture)"
  },
  {
    "word": "台所",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daidokoro",
    "translation": "(the) kitchen, kitchens, kitchen"
  },
  {
    "word": "台湾",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Taiwan",
    "translation": "Taiwan"
  },
  {
    "word": "台風",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taifuu",
    "translation": "typhoons, typhoon"
  },
  {
    "word": "右",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "migi",
    "translation": "right"
  },
  {
    "word": "合格",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "goukaku",
    "translation": "test results, test result, passing, pass"
  },
  {
    "word": "合計",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "goukei",
    "translation": "amount, total, sum"
  },
  {
    "word": "同じ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onaji",
    "translation": "(the) same"
  },
  {
    "word": "同僚",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "douryo",
    "translation": "colleague, colleagues, co-worker, co-workers"
  },
  {
    "word": "名刺",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "meishi",
    "translation": "business cards, business card"
  },
  {
    "word": "名前",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "namae",
    "translation": "names, name"
  },
  {
    "word": "名古屋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Nagoya",
    "translation": "Nagoya"
  },
  {
    "word": "向こう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mukou",
    "translation": "over there, the other side"
  },
  {
    "word": "君",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kimi",
    "translation": "you"
  },
  {
    "word": "周り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mawari",
    "translation": "around/circumference"
  },
  {
    "word": "味",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aji",
    "translation": "flavors, taste, flavor, tastes"
  },
  {
    "word": "味噌",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "miso",
    "translation": "miso"
  },
  {
    "word": "命",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "inochi",
    "translation": "life, lives"
  },
  {
    "word": "和室",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "washitsu",
    "translation": "Japanese-style rooms, Japanese-style room"
  },
  {
    "word": "和菓子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wagashi",
    "translation": "Japanese sweets, Japanese confections"
  },
  {
    "word": "和食",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "washoku",
    "translation": "Japanese food"
  },
  {
    "word": "咳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seki",
    "translation": "coughing, cough"
  },
  {
    "word": "唯一",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuiitsu",
    "translation": "the only one"
  },
  {
    "word": "問題",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mondai",
    "translation": "matters, question, problems, matter, difficulties, (an) issue, questions, (the) issue, problem, issues"
  },
  {
    "word": "喉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nodo",
    "translation": "throat"
  },
  {
    "word": "噴火",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "funka",
    "translation": "erupting, eruption"
  },
  {
    "word": "囚人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuujin",
    "translation": "prisoner, prisoners"
  },
  {
    "word": "四",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yon/shi",
    "translation": "four, fourth"
  },
  {
    "word": "四つ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yottsu",
    "translation": "four (things)"
  },
  {
    "word": "四日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yokka",
    "translation": "four days, fourth"
  },
  {
    "word": "回",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kai",
    "translation": "time(s) (counter)"
  },
  {
    "word": "回転",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaiten",
    "translation": "rotation/spin"
  },
  {
    "word": "図書館",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toshokan",
    "translation": "libraries, library"
  },
  {
    "word": "固体",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotai",
    "translation": "solid"
  },
  {
    "word": "国",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuni  (koku)",
    "translation": "country, national, (a) country, countries"
  },
  {
    "word": "国境",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kokkyo",
    "translation": "borders, border"
  },
  {
    "word": "国旗",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kokki",
    "translation": "(national) flag"
  },
  {
    "word": "土",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuchi (do)",
    "translation": "soil, dirt"
  },
  {
    "word": "土地",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tochi",
    "translation": "property, properties, land"
  },
  {
    "word": "地下鉄",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chikatetsu",
    "translation": "subways, subway, (a) subway"
  },
  {
    "word": "地位",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chii",
    "translation": "status, position, rank"
  },
  {
    "word": "地元",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jimoto",
    "translation": "local"
  },
  {
    "word": "地図",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chizu",
    "translation": "map, maps"
  },
  {
    "word": "地域",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chiiki",
    "translation": "neighborhood, district, area, region, community, regions, part"
  },
  {
    "word": "地方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chihou",
    "translation": "countryside, region, regional, district, area"
  },
  {
    "word": "地球",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chikyuu",
    "translation": "world, global, earth"
  },
  {
    "word": "地面",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jimen",
    "translation": "the ground"
  },
  {
    "word": "城",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiro",
    "translation": "castles, castle"
  },
  {
    "word": "基地",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kichi",
    "translation": "bases, base"
  },
  {
    "word": "報道",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "houdou",
    "translation": "report (of news), press"
  },
  {
    "word": "場合",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "baai",
    "translation": "case, instance, situations, situation"
  },
  {
    "word": "場所",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "basho",
    "translation": "places, area, space, site, place, location"
  },
  {
    "word": "場面",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bamen",
    "translation": "situation, scene"
  },
  {
    "word": "塔",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tou",
    "translation": "towers, pagoda, tower, pagodas"
  },
  {
    "word": "塩",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shio",
    "translation": "salt"
  },
  {
    "word": "壁",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kabe",
    "translation": "barriers, wall, walls, barrier"
  },
  {
    "word": "声",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koe",
    "translation": "singing (of a bird), sound (of an animal), voice, voices"
  },
  {
    "word": "売り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uri",
    "translation": "sale"
  },
  {
    "word": "変",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hen ",
    "translation": "weird, strange"
  },
  {
    "word": "変更",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "henkou",
    "translation": "change"
  },
  {
    "word": "夏",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "natsu",
    "translation": "(the) summer"
  },
  {
    "word": "夕べ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuube",
    "translation": "last night"
  },
  {
    "word": "夕方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuugata",
    "translation": "evening, dusk"
  },
  {
    "word": "夕飯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuuhan",
    "translation": "dinner"
  },
  {
    "word": "外",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "soto (gai)",
    "translation": "outside, out"
  },
  {
    "word": "外国",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gaikoku",
    "translation": "foreign (countries), outside the country, overseas, abroad"
  },
  {
    "word": "外国人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gaikokujin",
    "translation": "foreigner, foreigners"
  },
  {
    "word": "外食",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gaishoku",
    "translation": "eating out"
  },
  {
    "word": "多く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ooku",
    "translation": "a lot, much, many"
  },
  {
    "word": "多分",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tabun",
    "translation": "probably"
  },
  {
    "word": "夜",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yoru",
    "translation": "evening, nights, night"
  },
  {
    "word": "夢",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yume",
    "translation": "dream, dreams"
  },
  {
    "word": "夢中",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muchuu",
    "translation": "absorbed"
  },
  {
    "word": "大きい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ookii",
    "translation": "large, big, great, loud, considerable"
  },
  {
    "word": "大きく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ookiku",
    "translation": "a great deal, big, large, widespread, greatly"
  },
  {
    "word": "大きさ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ookisa",
    "translation": "size"
  },
  {
    "word": "大きな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ookina",
    "translation": "major, big, great, large, severe, loudly, a lot of"
  },
  {
    "word": "大じょうぶ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daijoubu",
    "translation": "okay"
  },
  {
    "word": "大丈夫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daijoubu",
    "translation": "alright, okay, fine"
  },
  {
    "word": "大人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otona/dainin",
    "translation": "grown-up, adult, adults"
  },
  {
    "word": "大切",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taisetsu",
    "translation": "important"
  },
  {
    "word": "大勢",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oozei",
    "translation": "large groups, crowd, a lot of"
  },
  {
    "word": "大変",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taihen",
    "translation": "tough, very, hard, terrible, so, pretty, in trouble, difficult, extremely, really"
  },
  {
    "word": "大好き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daisuki",
    "translation": "love, really like"
  },
  {
    "word": "大嫌い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daikirai",
    "translation": "hate"
  },
  {
    "word": "大学",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daigaku",
    "translation": "universities, university, college"
  },
  {
    "word": "大統領",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daitouryou",
    "translation": "president"
  },
  {
    "word": "大豆",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daizu",
    "translation": "soybeans, soybean"
  },
  {
    "word": "大部分",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daibubun",
    "translation": "most/majority"
  },
  {
    "word": "大金",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "daikin",
    "translation": "a lot of money"
  },
  {
    "word": "大阪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Osaka",
    "translation": "Osaka"
  },
  {
    "word": "大陸",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dairiku",
    "translation": "continents, continent"
  },
  {
    "word": "天井",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tenjou",
    "translation": "ceiling"
  },
  {
    "word": "天国",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tengoku",
    "translation": "heaven"
  },
  {
    "word": "天気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tenki",
    "translation": "weather"
  },
  {
    "word": "天然",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tennen",
    "translation": "natural"
  },
  {
    "word": "天皇",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tennou",
    "translation": "emperor"
  },
  {
    "word": "太って",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futte ",
    "translation": "heavy, overweight, gaining ... weight, fat"
  },
  {
    "word": "太陽",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taiyou",
    "translation": "the sun"
  },
  {
    "word": "夫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otto",
    "translation": "(his) husband, (her) husband, (my) husband, husband"
  },
  {
    "word": "失敗",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shippai",
    "translation": "failure, mistakes, mistake"
  },
  {
    "word": "失礼",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shitsurei",
    "translation": "rudeness/impoliteness"
  },
  {
    "word": "奥さん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okusan",
    "translation": "(your) wife"
  },
  {
    "word": "女",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onna",
    "translation": "woman, lady"
  },
  {
    "word": "女の人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onna no hito",
    "translation": "woman, women"
  },
  {
    "word": "女の子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onna no ko",
    "translation": "girls, girl, (a) girl"
  },
  {
    "word": "女優",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joyuu",
    "translation": "actresses, (an) actress"
  },
  {
    "word": "女性",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "josei",
    "translation": "ladies, woman, (a) woman, female, lady, women"
  },
  {
    "word": "女王",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joou",
    "translation": "queen, queens"
  },
  {
    "word": "奴",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yatsu",
    "translation": "guy, fellow"
  },
  {
    "word": "好き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suki",
    "translation": "likes, like"
  },
  {
    "word": "好物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koubutsu",
    "translation": "favorite food"
  },
  {
    "word": "妖怪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youkai",
    "translation": "yokai, monster, supernatural beings, monsters, supernatural being"
  },
  {
    "word": "妹",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "imouto",
    "translation": "(my) (little) sister, (my) (younger) sisters, (my) (younger) sister, (my) (little) sisters"
  },
  {
    "word": "妻",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuma",
    "translation": "(his) wife, (my) wife, (her) wife"
  },
  {
    "word": "姉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ane",
    "translation": "(my) (big) sister, sister, (my) (older) sister, (my) (elder) sister, (my) (older) sisters"
  },
  {
    "word": "姉妹",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shimai",
    "translation": "sisters"
  },
  {
    "word": "娘",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "musume",
    "translation": "(her) daughter, (my) daughter, daughter, (his) daughter, daughters, (our) daughter"
  },
  {
    "word": "嫌",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iya",
    "translation": "disliked"
  },
  {
    "word": "嫌い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kirai",
    "translation": "do not like"
  },
  {
    "word": "子ども",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kodomo",
    "translation": "children, child"
  },
  {
    "word": "子犬",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koinu",
    "translation": "puppies, puppy"
  },
  {
    "word": "子猫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koneko",
    "translation": "(the) kittens, (the) kitten, (a) kitten"
  },
  {
    "word": "字",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ji",
    "translation": "handwriting, writing neatly"
  },
  {
    "word": "季節",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kisetsu",
    "translation": "seasons, season"
  },
  {
    "word": "学位",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gakui",
    "translation": "degree"
  },
  {
    "word": "学校",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gakkou",
    "translation": "schools, school"
  },
  {
    "word": "学生",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gakusei",
    "translation": "students, student"
  },
  {
    "word": "孫息子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "magomusuko",
    "translation": "grandsons, (my) grandson"
  },
  {
    "word": "宇宙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uchuu",
    "translation": "(the) universe, space"
  },
  {
    "word": "安く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasuku",
    "translation": "cheap(ly)"
  },
  {
    "word": "安全",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "anzen",
    "translation": "safety, security, safely"
  },
  {
    "word": "安心",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "anshin",
    "translation": "relieved, a relief"
  },
  {
    "word": "宗教",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuukyou",
    "translation": "religion, religions"
  },
  {
    "word": "実",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jitsu",
    "translation": "fact/truth"
  },
  {
    "word": "宣告",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senkoku",
    "translation": "sentence/verdict"
  },
  {
    "word": "家",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ie/uchi",
    "translation": "house, houses, home, homes"
  },
  {
    "word": "家族",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kazoku",
    "translation": "families, family"
  },
  {
    "word": "家賃",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yachin",
    "translation": "rental, rent"
  },
  {
    "word": "宿",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yado",
    "translation": "lodge, resort, inn"
  },
  {
    "word": "宿題",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shukudai",
    "translation": "(her) homework, (his) homework, (my) homework, (your) homework"
  },
  {
    "word": "富士山",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Fuji san",
    "translation": "Mount Fuji"
  },
  {
    "word": "寝",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ne",
    "translation": "go to sleep, go to bed"
  },
  {
    "word": "寝室",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinshitsu",
    "translation": "bedroom"
  },
  {
    "word": "封筒",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuutou",
    "translation": "envelope, envelopes"
  },
  {
    "word": "専攻",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senkou",
    "translation": "(my) major subject"
  },
  {
    "word": "専用",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senyou",
    "translation": "for ... only, reserved for"
  },
  {
    "word": "将来",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shourai",
    "translation": "the future, my future"
  },
  {
    "word": "尊重",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sonchou",
    "translation": "respect (for)"
  },
  {
    "word": "小さい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chiisai",
    "translation": "small, little"
  },
  {
    "word": "小さく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chiisaku",
    "translation": "small"
  },
  {
    "word": "小さな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chiisana",
    "translation": "small, little"
  },
  {
    "word": "小切手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kogitte",
    "translation": "checks, (a) check, check, cheque, cheques"
  },
  {
    "word": "小学",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shougaku",
    "translation": "elementary school, primary school"
  },
  {
    "word": "小浜",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "obama",
    "translation": "Obama (family name)"
  },
  {
    "word": "小説",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shousetsu",
    "translation": "novel, novels"
  },
  {
    "word": "少々",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shoushou",
    "translation": "briefly, a moment, some, a bit (of)"
  },
  {
    "word": "少し",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sukoshi",
    "translation": "a bit, some, a bit (of), few, somewhat, a little, slightly, a slight"
  },
  {
    "word": "少なくとも",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sukunakutomo",
    "translation": "at least, no fewer than"
  },
  {
    "word": "居酒屋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "izakaya",
    "translation": "pub, izakaya"
  },
  {
    "word": "居間",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ima",
    "translation": "living room"
  },
  {
    "word": "屋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ya",
    "translation": "shop, store"
  },
  {
    "word": "屋根",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yane",
    "translation": "roofs, rooves, roof"
  },
  {
    "word": "履き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haki-",
    "translation": "put on (lower)"
  },
  {
    "word": "山",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yama",
    "translation": "mountain, mountains"
  },
  {
    "word": "山口",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Yamaguchi",
    "translation": "Yamaguchi"
  },
  {
    "word": "島",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shima",
    "translation": "island, islands"
  },
  {
    "word": "嵐",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "arashi",
    "translation": "storms, stormy, storm"
  },
  {
    "word": "川",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kawa",
    "translation": "river, rivers"
  },
  {
    "word": "巣",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "su",
    "translation": "web, nest, nests, webs"
  },
  {
    "word": "工場",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koujou",
    "translation": "factories, factory"
  },
  {
    "word": "左",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hidari",
    "translation": "left"
  },
  {
    "word": "巨大",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoudai",
    "translation": "huge, enormous, giant"
  },
  {
    "word": "市長",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shichou",
    "translation": "mayor, mayors"
  },
  {
    "word": "布団",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futon",
    "translation": "futons, futon"
  },
  {
    "word": "席",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seki",
    "translation": "seat, place, places, seats"
  },
  {
    "word": "帯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "obi",
    "translation": "sash"
  },
  {
    "word": "帰った",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaetta (kaeru)",
    "translation": "leave, went home, gone home, returned"
  },
  {
    "word": "帰ろう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaerou",
    "translation": "let's go back"
  },
  {
    "word": "帽子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "boushi",
    "translation": "hats, cap, hat, caps"
  },
  {
    "word": "平ら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taira",
    "translation": "flat"
  },
  {
    "word": "平成",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Heisei",
    "translation": "Heisei era (1989-2019)"
  },
  {
    "word": "平日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "heijitsu",
    "translation": "weekday, weekdays"
  },
  {
    "word": "平等",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "byoudou",
    "translation": "equality, equal"
  },
  {
    "word": "年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nen (toshi)",
    "translation": "age, year, years"
  },
  {
    "word": "年々",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nennen/toshidoshi",
    "translation": "year by year, year after year"
  },
  {
    "word": "年生",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nensei",
    "translation": "grade"
  },
  {
    "word": "年金",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nenkin",
    "translation": "pension, pensions"
  },
  {
    "word": "幸せ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiawase",
    "translation": "happiness, happy"
  },
  {
    "word": "幽霊",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuurei",
    "translation": "ghost, ghosts"
  },
  {
    "word": "広島",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Hiroshima",
    "translation": "Hiroshima"
  },
  {
    "word": "床",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuka",
    "translation": "the floor"
  },
  {
    "word": "店",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mise",
    "translation": "shop, stores, restaurant, shops, store, restaurants"
  },
  {
    "word": "店員",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tenin",
    "translation": "shop assistant, clerk, salespeople, shop assistants, salesperson, clerks"
  },
  {
    "word": "庭",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "niwa",
    "translation": "yard, garden, yards, gardens"
  },
  {
    "word": "廊下",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rouka",
    "translation": "corridors, hallway, corridor, hallways"
  },
  {
    "word": "延長",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "enchou",
    "translation": "lengthening/extension"
  },
  {
    "word": "建物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tatemono",
    "translation": "building, buildings"
  },
  {
    "word": "弁護士",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bengoshi",
    "translation": "attorneys, attorney, lawyers, lawyer"
  },
  {
    "word": "式",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiki",
    "translation": "ceremony/formula"
  },
  {
    "word": "弓",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yumi",
    "translation": "bow"
  },
  {
    "word": "弟",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otouto",
    "translation": "(my) (younger) brother, (my) (younger) brothers, (my) (little) brother, (my) (little) brothers"
  },
  {
    "word": "弾き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiki",
    "translation": "play (an instrument)"
  },
  {
    "word": "形",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "katachi",
    "translation": "form/shape"
  },
  {
    "word": "彼",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kare",
    "translation": "him, he"
  },
  {
    "word": "彼ら",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karera",
    "translation": "them, they"
  },
  {
    "word": "彼女",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanojo",
    "translation": "(my) girlfriend, she, her"
  },
  {
    "word": "彼氏",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kareshi",
    "translation": "boyfriend"
  },
  {
    "word": "後",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ato",
    "translation": "the rest, later, after"
  },
  {
    "word": "後ろ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ushiro",
    "translation": "behind, back"
  },
  {
    "word": "後悔",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koukai",
    "translation": "regret/remorse"
  },
  {
    "word": "後輩",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kouhai",
    "translation": "junior colleagues, inferiors, mentee, my junior, junior colleague"
  },
  {
    "word": "微妙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bimyou",
    "translation": "questionable, lackluster, delicate, subtle, mediocre, not very good"
  },
  {
    "word": "心",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kokoro",
    "translation": "heart, head, mind, spirit"
  },
  {
    "word": "心臓",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinzou",
    "translation": "hearts, heart"
  },
  {
    "word": "心配",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinpai",
    "translation": "worries, anxieties, concerns, concern"
  },
  {
    "word": "必ず",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanarazu",
    "translation": "certainly, necessarily, needs to, always, has to, for sure, be sure to, must"
  },
  {
    "word": "必死",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hisshi",
    "translation": "frantic/desperate"
  },
  {
    "word": "必要",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hitsuyou",
    "translation": "need, necessary, necessity"
  },
  {
    "word": "忍者",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ninja",
    "translation": "ninjas, ninja"
  },
  {
    "word": "忙しい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isogashii",
    "translation": "busy"
  },
  {
    "word": "忙しく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isogashiku",
    "translation": "busily"
  },
  {
    "word": "忙しそう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isogashisou",
    "translation": "looks busy"
  },
  {
    "word": "念",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nen",
    "translation": "sense/thought/feeling"
  },
  {
    "word": "思い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omoi",
    "translation": "thought"
  },
  {
    "word": "急",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyuu",
    "translation": "steep/sudden"
  },
  {
    "word": "急行",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyuuko",
    "translation": "hurry+suru, express (train)"
  },
  {
    "word": "恋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koi",
    "translation": "romantic love"
  },
  {
    "word": "息子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "musuko",
    "translation": "sons, (my) son, (our) son, son"
  },
  {
    "word": "患者",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanja",
    "translation": "patients, patient"
  },
  {
    "word": "悲しみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanashimi",
    "translation": "sadness, sorrow"
  },
  {
    "word": "情報",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jouhou",
    "translation": "information"
  },
  {
    "word": "惑星",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wakusei",
    "translation": "planet, planets"
  },
  {
    "word": "意味",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "imi",
    "translation": "meaning, point, meanings, (the) meaning, significance"
  },
  {
    "word": "愛",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ai",
    "translation": "love, affection"
  },
  {
    "word": "愛情",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aijou",
    "translation": "love, affection"
  },
  {
    "word": "態度",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taido",
    "translation": "attitude, disposition, behavior"
  },
  {
    "word": "成功",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seikou",
    "translation": "successes, success"
  },
  {
    "word": "成績",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seiseki",
    "translation": "grade, grades, results, marks"
  },
  {
    "word": "我々",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wareware",
    "translation": "us"
  },
  {
    "word": "我慢",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gaman",
    "translation": "(my) patience"
  },
  {
    "word": "戦争",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sensou",
    "translation": "war, wars"
  },
  {
    "word": "戸",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "to",
    "translation": "(sliding) doors, (sliding) door"
  },
  {
    "word": "所有",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shoyuu",
    "translation": "property"
  },
  {
    "word": "手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "te",
    "translation": "hand, hands"
  },
  {
    "word": "手段",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shudan",
    "translation": "medium, means, measures, way, measure, method"
  },
  {
    "word": "手紙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tegami",
    "translation": "(a) letter, (the) letters, letters, (the) letter"
  },
  {
    "word": "手袋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tebukuro",
    "translation": "gloves"
  },
  {
    "word": "手首",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tekubi",
    "translation": "wrist"
  },
  {
    "word": "才能",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sainou",
    "translation": "talents, talent, gift"
  },
  {
    "word": "折り紙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "origami",
    "translation": "origami, paper folding"
  },
  {
    "word": "抹茶",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maccha",
    "translation": "Matcha, powdered green tea"
  },
  {
    "word": "拍手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hakushu",
    "translation": "(a round of) applause"
  },
  {
    "word": "招待",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shoutai",
    "translation": "invite, invitation"
  },
  {
    "word": "持ち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mochi",
    "translation": "carry, hold"
  },
  {
    "word": "持ち主",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mochinushi",
    "translation": "owner, possessor"
  },
  {
    "word": "持って",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "motte",
    "translation": "carry, carried (and), have, take (and)"
  },
  {
    "word": "指",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yubi",
    "translation": "fingers, finger"
  },
  {
    "word": "挨拶",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aisatsu",
    "translation": "greeting"
  },
  {
    "word": "掃除",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "souji",
    "translation": "cleaning, clean"
  },
  {
    "word": "掃除機",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "soujiki",
    "translation": "vacuum cleaner"
  },
  {
    "word": "授業",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jugyou",
    "translation": "lessons, section, classes, lesson, class"
  },
  {
    "word": "描き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eki- (egaku)",
    "translation": "draw"
  },
  {
    "word": "握り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nigiri",
    "translation": "hand-pressed sushi, nigiri"
  },
  {
    "word": "握手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "akushu",
    "translation": "shake hands"
  },
  {
    "word": "携帯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "keitai(denwa)",
    "translation": "portable (cell phone)"
  },
  {
    "word": "撮り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tori",
    "translation": "take"
  },
  {
    "word": "攻撃",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kougeki",
    "translation": "attacks, attack"
  },
  {
    "word": "政党",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seitou",
    "translation": "partisan, (political) parties, (political) party"
  },
  {
    "word": "政府",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seifu",
    "translation": "administration, government"
  },
  {
    "word": "政治家",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seijika",
    "translation": "politicians, politician"
  },
  {
    "word": "故郷",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kokyou",
    "translation": "hometown, home"
  },
  {
    "word": "救急車",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyuukyuusha",
    "translation": "ambulances, ambulance"
  },
  {
    "word": "教え",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oshie",
    "translation": "teach"
  },
  {
    "word": "教会",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyokai",
    "translation": "churches, church"
  },
  {
    "word": "教室",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoushitsu",
    "translation": "classroom, classrooms, class"
  },
  {
    "word": "教師",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoushi",
    "translation": "teachers, teacher"
  },
  {
    "word": "教科書",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoukasho",
    "translation": "textbooks, textbook"
  },
  {
    "word": "教育",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyouiku",
    "translation": "educational, education"
  },
  {
    "word": "散歩",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sanpo",
    "translation": "go on walks, going on walks, walk"
  },
  {
    "word": "数",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kazu",
    "translation": "the numbers, numbers, number"
  },
  {
    "word": "数学",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suugaku",
    "translation": "mathematics, math"
  },
  {
    "word": "敵",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "teki",
    "translation": "enemy, (an) enemy, enemies"
  },
  {
    "word": "文化",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bunka",
    "translation": "culture, cultures"
  },
  {
    "word": "文学",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bungaku",
    "translation": "literary, literature"
  },
  {
    "word": "文法",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bunpou",
    "translation": "grammar"
  },
  {
    "word": "料理",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryouri",
    "translation": "cuisine, dishes, food, cooking, cook, dish, meal"
  },
  {
    "word": "料理人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryourinin",
    "translation": "chef, cook, cooks, chefs"
  },
  {
    "word": "新しく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atarashiku",
    "translation": "new"
  },
  {
    "word": "新幹線",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinkansen",
    "translation": "bullet train, Shinkansen"
  },
  {
    "word": "新聞",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinbun",
    "translation": "press, newspaper, newspapers, paper, (the) newspaper"
  },
  {
    "word": "新鮮",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinsen",
    "translation": "fresh"
  },
  {
    "word": "方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hou, kata",
    "translation": "person, side, way"
  },
  {
    "word": "旅行",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryokou",
    "translation": "voyage,trip, travel, journey, traveling, trip"
  },
  {
    "word": "旅館",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryokan",
    "translation": "traditional Japanese inn"
  },
  {
    "word": "日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hi (nichi)",
    "translation": "the sun, days, day"
  },
  {
    "word": "日常",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nichijou",
    "translation": "everyday,  daily"
  },
  {
    "word": "日本",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nihon",
    "translation": "Japan, Japanese"
  },
  {
    "word": "日本海",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nihonkai",
    "translation": "the Sea of Japan"
  },
  {
    "word": "日本語",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nihongo",
    "translation": "Japanese"
  },
  {
    "word": "日本酒",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nihonshu",
    "translation": "sake, Japanese rice wine"
  },
  {
    "word": "日記",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nikki",
    "translation": "diary, diaries"
  },
  {
    "word": "明らか",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "akiraka",
    "translation": "clear, obvious, manifest"
  },
  {
    "word": "明るい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "akarui",
    "translation": "cheerful, brighter, bright"
  },
  {
    "word": "明るく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "akaraku",
    "translation": "cheerful, bright, joyful, joyous"
  },
  {
    "word": "明後日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asatte",
    "translation": "The day after tomorrow, the day after tomorrow"
  },
  {
    "word": "明日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ashita",
    "translation": "tomorrow"
  },
  {
    "word": "易しそう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasashi sou",
    "translation": "looks easy"
  },
  {
    "word": "星",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hoshi",
    "translation": "star, stars"
  },
  {
    "word": "映画",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eiga",
    "translation": "films, movie, film, movies"
  },
  {
    "word": "映画館",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eigakan",
    "translation": "movie theater, cinema"
  },
  {
    "word": "春",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haru",
    "translation": "(the) spring, springtime"
  },
  {
    "word": "昨日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kinou",
    "translation": "yesterday"
  },
  {
    "word": "昭和",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shouwa",
    "translation": "Showa era (1926-1989)"
  },
  {
    "word": "昼ご飯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hirugohan",
    "translation": "lunch"
  },
  {
    "word": "昼間",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiruma",
    "translation": "daytime, (during) the day"
  },
  {
    "word": "時",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ji",
    "translation": "o'clock, when, time"
  },
  {
    "word": "時代",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jidai",
    "translation": "period, era, time, age, days, times "
  },
  {
    "word": "時計",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tokei",
    "translation": "clocks, watch, watches, clock"
  },
  {
    "word": "時間",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jikan",
    "translation": "hours, hour, time"
  },
  {
    "word": "晩ご飯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bangohan",
    "translation": "dinner"
  },
  {
    "word": "普通",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futsuu",
    "translation": "normal, typically, average, ordinarily, typical, usually, ordinary, in general, normally, usual"
  },
  {
    "word": "景色",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "keshiki",
    "translation": "scenery, sight, scene, landscape, view"
  },
  {
    "word": "晴れ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hare",
    "translation": "clear, sunny"
  },
  {
    "word": "晴れて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "harete",
    "translation": "openly,publicly"
  },
  {
    "word": "暇",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hima",
    "translation": "available, bored, spare, free"
  },
  {
    "word": "暗記",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "anki",
    "translation": "memorisation, rote learning"
  },
  {
    "word": "暗証番号",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "anshoubangou",
    "translation": "PIN"
  },
  {
    "word": "曜日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youbi",
    "translation": "(a) day (of the week), day (of the week)"
  },
  {
    "word": "曲がり",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "magari",
    "translation": "curvature,bend"
  },
  {
    "word": "更新",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koushin",
    "translation": "renewal"
  },
  {
    "word": "書いて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaite",
    "translation": "write up, written, writing"
  },
  {
    "word": "書き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaki",
    "translation": "write, (to) write"
  },
  {
    "word": "書き方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kakikata",
    "translation": "how to write"
  },
  {
    "word": "書く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaku",
    "translation": "write, writes"
  },
  {
    "word": "書道",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shodou",
    "translation": "calligraphy"
  },
  {
    "word": "書類",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shorui",
    "translation": "documents, document"
  },
  {
    "word": "最初",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saisho",
    "translation": "the beginning, (the) first"
  },
  {
    "word": "最大",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saidai",
    "translation": "biggest, largest"
  },
  {
    "word": "最後",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saigo",
    "translation": "the end, the last"
  },
  {
    "word": "最悪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saiaku",
    "translation": "the worst"
  },
  {
    "word": "最新",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saishin",
    "translation": "newest, latest"
  },
  {
    "word": "最近",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saikin",
    "translation": "these days, lately, recently, as of late, recent"
  },
  {
    "word": "最高",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saikou",
    "translation": "the best, awesome, the greatest, superb"
  },
  {
    "word": "月",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuki (gatsu)",
    "translation": "month, moons, months, moon"
  },
  {
    "word": "有名",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuumei",
    "translation": "well-known, famous"
  },
  {
    "word": "有料",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuuryou",
    "translation": "charging a fee, not free, paid"
  },
  {
    "word": "有罪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuuzai",
    "translation": "guilt, guilty"
  },
  {
    "word": "服",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fuku",
    "translation": "clothing, clothes"
  },
  {
    "word": "朝",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asa",
    "translation": "(in the) morning, morning"
  },
  {
    "word": "朝ご飯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asagohan",
    "translation": "breakfast"
  },
  {
    "word": "木",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ki",
    "translation": "wood, tree, trees, Thursday"
  },
  {
    "word": "未来",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mirai",
    "translation": "future"
  },
  {
    "word": "本",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hon",
    "translation": "(for count. long objects), books, book"
  },
  {
    "word": "本だな",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hondana",
    "translation": "bookshelf, bookcase"
  },
  {
    "word": "本日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "honjitsu",
    "translation": "today (formal)"
  },
  {
    "word": "本田",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "honda",
    "translation": "Honda (family name)"
  },
  {
    "word": "机",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsukue",
    "translation": "desks, (the) desk, desk"
  },
  {
    "word": "材料",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zairyou",
    "translation": "material, ingredient, materials, ingredients"
  },
  {
    "word": "村",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mura",
    "translation": "villages, village"
  },
  {
    "word": "杖",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsue",
    "translation": "wands, wand"
  },
  {
    "word": "来",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rai",
    "translation": "coming (year etc)"
  },
  {
    "word": "来い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ki (v=kuru)",
    "translation": "come"
  },
  {
    "word": "来て",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kite",
    "translation": "come, arrived, came (and), coming"
  },
  {
    "word": "来る",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuru",
    "translation": "is coming, come, comes, would"
  },
  {
    "word": "来年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rainen",
    "translation": "next year"
  },
  {
    "word": "来月",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "raigetsu",
    "translation": "next month"
  },
  {
    "word": "来週",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "raishuu",
    "translation": "next week"
  },
  {
    "word": "東",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "higashi",
    "translation": "east"
  },
  {
    "word": "東京",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toukyou",
    "translation": "Tokyo"
  },
  {
    "word": "林",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hayashi",
    "translation": "woods, the woods"
  },
  {
    "word": "枚",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mai",
    "translation": "sheet, (counting flat objects), sheets"
  },
  {
    "word": "果物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kudamono",
    "translation": "(a) fruit, fruit, fruits"
  },
  {
    "word": "枝",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eda",
    "translation": "branches, branch"
  },
  {
    "word": "柔道",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juudo",
    "translation": "judo"
  },
  {
    "word": "校長",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kouchou",
    "translation": "principals, principal"
  },
  {
    "word": "森",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mori",
    "translation": "woods, Mori (family name), forest, forests"
  },
  {
    "word": "椅子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "isu",
    "translation": "chairs, chair"
  },
  {
    "word": "植物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shokubutsu",
    "translation": "plants, plant"
  },
  {
    "word": "楽しかった",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanoshikatta",
    "translation": "was fun"
  },
  {
    "word": "楽しみ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanoshimi",
    "translation": "fun, pleasure"
  },
  {
    "word": "様",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sama",
    "translation": "Ms., Mr., Miss, Mrs."
  },
  {
    "word": "様子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yousu",
    "translation": "doing, how it goes, appearance, scene, state, situation, condition"
  },
  {
    "word": "標識",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hyoushiki",
    "translation": "sign, signs"
  },
  {
    "word": "権利",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kenri",
    "translation": "right, rights"
  },
  {
    "word": "横断",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oudan",
    "translation": "road/river crossing"
  },
  {
    "word": "横浜",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yokohama",
    "translation": "Yokohama"
  },
  {
    "word": "橋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hashi",
    "translation": "bridge, (the) bridge, bridges"
  },
  {
    "word": "機会",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kikai",
    "translation": "occasion, chance, opportunity, opportunities, occasions"
  },
  {
    "word": "機械",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kikai",
    "translation": "machine, (a) machine, machines"
  },
  {
    "word": "機能",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kinou",
    "translation": "function, functions, work, feature, features"
  },
  {
    "word": "欠席",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kessiki",
    "translation": "absence"
  },
  {
    "word": "次",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsugi",
    "translation": "next"
  },
  {
    "word": "次回",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jikai",
    "translation": "next time"
  },
  {
    "word": "欲しい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hoshii",
    "translation": "wants, you want, wanted, want"
  },
  {
    "word": "欲しく",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hoshiku",
    "translation": "want"
  },
  {
    "word": "歌",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "uta",
    "translation": "song, songs"
  },
  {
    "word": "歌い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "utai-",
    "translation": "to sing"
  },
  {
    "word": "歌う",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "utau",
    "translation": "to sing"
  },
  {
    "word": "歌手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kashu",
    "translation": "singers, singer"
  },
  {
    "word": "止まれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tomare",
    "translation": "stop"
  },
  {
    "word": "武器",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "buki",
    "translation": "weapons, weapon"
  },
  {
    "word": "歩き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aruki",
    "translation": "walks, walk"
  },
  {
    "word": "歩く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aruku",
    "translation": "to walk"
  },
  {
    "word": "歩こう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "arukou",
    "translation": "I will walk, let's walk"
  },
  {
    "word": "歩行者",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hokousha",
    "translation": "pedestrians, pedestrian"
  },
  {
    "word": "歩道",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hodou",
    "translation": "the sidewalk, the pavement"
  },
  {
    "word": "歯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ha",
    "translation": "tooth, teeth"
  },
  {
    "word": "歯ブラシ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haburashi",
    "translation": "toothbrush, toothbrushes"
  },
  {
    "word": "歯医者",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haisha",
    "translation": "dentist, dentists"
  },
  {
    "word": "歯磨き粉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hamigakiko",
    "translation": "toothpaste"
  },
  {
    "word": "歳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sai",
    "translation": "years old, the age of, years of age"
  },
  {
    "word": "死",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shi",
    "translation": "die, passing, death, deaths"
  },
  {
    "word": "死に",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shini",
    "translation": "dying, death"
  },
  {
    "word": "残念",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zannen",
    "translation": "disappointing, pity, sorry, shame"
  },
  {
    "word": "残業",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zangyou",
    "translation": "working overtime"
  },
  {
    "word": "残高",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zandaka",
    "translation": "balance"
  },
  {
    "word": "母",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "haha",
    "translation": "(my) mom, (my) mother, mother"
  },
  {
    "word": "毎あさ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maiasa",
    "translation": "every morning"
  },
  {
    "word": "毎ばん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maiban",
    "translation": "every evening"
  },
  {
    "word": "毎年",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maitoshi",
    "translation": "yearly, annually, every year, each year"
  },
  {
    "word": "毎日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mainichi",
    "translation": "every day, daily"
  },
  {
    "word": "毎晩",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maiban",
    "translation": "every night "
  },
  {
    "word": "毎月",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maitsuki",
    "translation": "monthly, each month"
  },
  {
    "word": "毎朝",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maiasa",
    "translation": "each morning, every morning"
  },
  {
    "word": "毎週",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "maishuu",
    "translation": "each week"
  },
  {
    "word": "毛布",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "moufu",
    "translation": "blanket, blankets"
  },
  {
    "word": "気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ki",
    "translation": "temper, consciousness"
  },
  {
    "word": "気体",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kitai",
    "translation": "gas, gases"
  },
  {
    "word": "気候",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kikou",
    "translation": "climate, weather"
  },
  {
    "word": "気分",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kibun",
    "translation": "feel, attitude, mood"
  },
  {
    "word": "気持ち",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kimochi",
    "translation": "feel, feeling, feelings"
  },
  {
    "word": "気温",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kion",
    "translation": "temperature, air temperature"
  },
  {
    "word": "水",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mizu (sui)",
    "translation": "water, (the) water"
  },
  {
    "word": "水泳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suiei",
    "translation": "swimming"
  },
  {
    "word": "氷",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koori",
    "translation": "ice"
  },
  {
    "word": "汁",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiru",
    "translation": "juice"
  },
  {
    "word": "汗",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ase",
    "translation": "sweating, sweat"
  },
  {
    "word": "汚染",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "osen",
    "translation": "pollution, contamination"
  },
  {
    "word": "池",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ike",
    "translation": "pond, ponds"
  },
  {
    "word": "池袋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Ikebukuro",
    "translation": "Ikebukuro"
  },
  {
    "word": "決して",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kesshite",
    "translation": "in any way, by any means, by no means, in no way, never"
  },
  {
    "word": "沖縄",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Okinawa",
    "translation": "Okinawa"
  },
  {
    "word": "油",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "abura",
    "translation": "oil, Oil"
  },
  {
    "word": "法案",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "houan",
    "translation": "bill, bills"
  },
  {
    "word": "波",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nami",
    "translation": "waves, wave"
  },
  {
    "word": "泥棒",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dorobou",
    "translation": "thief"
  },
  {
    "word": "注射",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chuusha",
    "translation": "injections, injection, shot, shots"
  },
  {
    "word": "泳いで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oyoide",
    "translation": "swim, swimming"
  },
  {
    "word": "泳ぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oyogi",
    "translation": "swim"
  },
  {
    "word": "泳ぎ方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oyogikata",
    "translation": "how to swim"
  },
  {
    "word": "泳ぐ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oyogu",
    "translation": "To swim, swims"
  },
  {
    "word": "洋服",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youfuku",
    "translation": "(Western) clothes, (Western) clothing"
  },
  {
    "word": "洗い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "arai",
    "translation": "washing"
  },
  {
    "word": "洗濯",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sentaku",
    "translation": "laundry"
  },
  {
    "word": "洗濯機",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sentakuki",
    "translation": "washing machines, washing machine"
  },
  {
    "word": "津波",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsunami",
    "translation": "tsunami, tsunamis"
  },
  {
    "word": "洪水",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kouzui",
    "translation": "flood"
  },
  {
    "word": "流し",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nagashi",
    "translation": "(kitchen) sink"
  },
  {
    "word": "浴衣",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yukata",
    "translation": "yukata, lightweight robe, lightweight robes"
  },
  {
    "word": "海",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "umi",
    "translation": "beach, sea, ocean"
  },
  {
    "word": "海外",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaigai",
    "translation": "abroad"
  },
  {
    "word": "涙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "namida",
    "translation": "tear, tears"
  },
  {
    "word": "液体",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ekitai",
    "translation": "liquids, liquid"
  },
  {
    "word": "渋滞",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juutai",
    "translation": "traffic (jam)"
  },
  {
    "word": "渋谷",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Shibuya",
    "translation": "Shibuya"
  },
  {
    "word": "温度",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ondo",
    "translation": "temperature"
  },
  {
    "word": "温度計",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ondokei",
    "translation": "thermometer"
  },
  {
    "word": "温暖",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ondan",
    "translation": "temperate"
  },
  {
    "word": "温泉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onsen",
    "translation": "hot springs, hot spring"
  },
  {
    "word": "港",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "minato",
    "translation": "ports, harbor, (the) port, harbors, port"
  },
  {
    "word": "湖",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mizuumi",
    "translation": "lakes, lake"
  },
  {
    "word": "準備",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "junbi",
    "translation": "preparation, preparing, preparations"
  },
  {
    "word": "漢字",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kanji",
    "translation": "kanji, Chinese character, Chinese characters"
  },
  {
    "word": "火",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hi",
    "translation": "fire, light, lighter"
  },
  {
    "word": "火星",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kasei",
    "translation": "Mars"
  },
  {
    "word": "災害",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saigai",
    "translation": "disasters"
  },
  {
    "word": "炊飯器",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suihanki",
    "translation": "rice cooker"
  },
  {
    "word": "無事",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "buji",
    "translation": "safely"
  },
  {
    "word": "無料",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muryou",
    "translation": "free"
  },
  {
    "word": "無理",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muri",
    "translation": "impossible, unreasonable (things), no rationality"
  },
  {
    "word": "無罪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muzai",
    "translation": "innocent, innocence"
  },
  {
    "word": "無色",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mushoku",
    "translation": "colorless"
  },
  {
    "word": "無視",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mushi",
    "translation": "disregarding/ignoring"
  },
  {
    "word": "無限",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mugen",
    "translation": "infinite, boundless, limitless, unlimited"
  },
  {
    "word": "煙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kemuri",
    "translation": "smoke"
  },
  {
    "word": "煙突",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "entotsu",
    "translation": "chimneys, chimney"
  },
  {
    "word": "熱",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "netsu",
    "translation": "heat, fever"
  },
  {
    "word": "爆弾",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bakudan",
    "translation": "bomb, bombs"
  },
  {
    "word": "爪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsume",
    "translation": "nail (finger,toe)"
  },
  {
    "word": "父",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chichi",
    "translation": "(my) dad, (my) father"
  },
  {
    "word": "牛乳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gyuunyuu",
    "translation": "milk"
  },
  {
    "word": "牛肉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gyuuniku",
    "translation": "beef"
  },
  {
    "word": "物価",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bukka",
    "translation": "the cost of living, prices"
  },
  {
    "word": "特に",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tokuni",
    "translation": "in particular, particularly, especially"
  },
  {
    "word": "特別",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tokubetsu",
    "translation": "special"
  },
  {
    "word": "犬",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "inu",
    "translation": "dogs, dog"
  },
  {
    "word": "犯人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hannin",
    "translation": "criminals, culprit, perpetrator, culprits, perpetrators, criminal"
  },
  {
    "word": "状態",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joutai",
    "translation": "shape, conditions, state, condition"
  },
  {
    "word": "状況",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "joukyou",
    "translation": "things, state, situations, situation, conditions, condition"
  },
  {
    "word": "独立",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dokoritsu",
    "translation": "independence"
  },
  {
    "word": "独身",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dokushin",
    "translation": "single,batchelorhood"
  },
  {
    "word": "狭い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "semai",
    "translation": "small, confined, narrow"
  },
  {
    "word": "猛烈",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mouretsu",
    "translation": "intense"
  },
  {
    "word": "猫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "neko",
    "translation": "cats, cat"
  },
  {
    "word": "玄関",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "genkan",
    "translation": "entranceway, entrance, door, (front) door"
  },
  {
    "word": "王女",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oujo",
    "translation": "princess, princesses"
  },
  {
    "word": "王子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ouji",
    "translation": "princes, prince"
  },
  {
    "word": "現在",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "genzai",
    "translation": "now, currently, at the moment"
  },
  {
    "word": "現象",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "genshou",
    "translation": "fads, phenomena, phenomenon"
  },
  {
    "word": "現金",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "genkin",
    "translation": "cash"
  },
  {
    "word": "理由",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "riyuu",
    "translation": "reasons, reason, why"
  },
  {
    "word": "理解",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rikai",
    "translation": "understanding, understand, comprehension, comprehend"
  },
  {
    "word": "環境",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kankyou",
    "translation": "environment, surroundings, environments, environmental"
  },
  {
    "word": "甘み",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "amami",
    "translation": "sweetness"
  },
  {
    "word": "生",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nama/ki/sei",
    "translation": "raw, uncooked/ pure/life, live, student"
  },
  {
    "word": "生きがい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikigai",
    "translation": "purpose in life, reason for living"
  },
  {
    "word": "生き物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikimono",
    "translation": "living thing, life, living things, creatures, creature"
  },
  {
    "word": "生け花",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikebana",
    "translation": "arranging flowers, flower arrangement"
  },
  {
    "word": "生まれ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "umare",
    "translation": "born"
  },
  {
    "word": "生徒",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seito",
    "translation": "pupils, students, learner, pupil, student, learners"
  },
  {
    "word": "生活",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seikatsu",
    "translation": "living, life, lifestyle, lives, way of life, lifestyles"
  },
  {
    "word": "生物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "seibutsu",
    "translation": "organisms, creatures"
  },
  {
    "word": "産業",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sangyo",
    "translation": "industries, industry"
  },
  {
    "word": "用事",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youji",
    "translation": "something to do, errand, errands, things to do, business"
  },
  {
    "word": "用紙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "youshi",
    "translation": "forms, sheet, form"
  },
  {
    "word": "田中",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Tanaka",
    "translation": "Tanaka (family name)"
  },
  {
    "word": "田舎",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "inaka",
    "translation": "rural (area), the countryside"
  },
  {
    "word": "申し",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "moushi",
    "translation": "excuse me, pardon me, sorry"
  },
  {
    "word": "申し訳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "moushiwake",
    "translation": "excuse"
  },
  {
    "word": "男",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otoko",
    "translation": "male, man"
  },
  {
    "word": "男の人",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otoko no hito",
    "translation": "men, man"
  },
  {
    "word": "男の子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "otoko no ko",
    "translation": "boys, a boy"
  },
  {
    "word": "男性",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dansei",
    "translation": "men, (a) man, man"
  },
  {
    "word": "画像",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gazou",
    "translation": "images, image"
  },
  {
    "word": "画面",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gamen",
    "translation": "(TV) screen"
  },
  {
    "word": "留学生",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryuugakusei",
    "translation": "international student, international students, foreign exchange students, foreign exchange student"
  },
  {
    "word": "留守",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rusu",
    "translation": "absence, away (from home)"
  },
  {
    "word": "番号",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bangou",
    "translation": "number"
  },
  {
    "word": "番組",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bangumi",
    "translation": "show, programs, program, programme, programmes, shows"
  },
  {
    "word": "畳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tatami",
    "translation": "straw mats, straw mat"
  },
  {
    "word": "病気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "byouki",
    "translation": "ill, illness, disease, sick, sickness"
  },
  {
    "word": "病院",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "byouin",
    "translation": "hospital, hospitals"
  },
  {
    "word": "痛み",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itami",
    "translation": "pain"
  },
  {
    "word": "発明",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hatsumei",
    "translation": "invention, inventions"
  },
  {
    "word": "発表",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "happyou",
    "translation": "announcement, presentation"
  },
  {
    "word": "発見",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hakken",
    "translation": "discovery"
  },
  {
    "word": "発音",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hatsuon",
    "translation": "pronunciation"
  },
  {
    "word": "白い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiroi",
    "translation": "white"
  },
  {
    "word": "白米",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hakumai",
    "translation": "polished rice"
  },
  {
    "word": "白線",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hakusen",
    "translation": "white line"
  },
  {
    "word": "百",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hyaku",
    "translation": "100, (one) hundred, (a) hundred"
  },
  {
    "word": "的",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "teki",
    "translation": "object, target"
  },
  {
    "word": "監督",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kantoku",
    "translation": "director, supervision, coach"
  },
  {
    "word": "目",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "me",
    "translation": "eyes, eye"
  },
  {
    "word": "目的",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mokuteki",
    "translation": "goal, objectives, aims, aim, goals, objective, purpose"
  },
  {
    "word": "相談",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "soudan",
    "translation": "consult"
  },
  {
    "word": "盾",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tate",
    "translation": "shields, shield"
  },
  {
    "word": "看護師",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kangoshi",
    "translation": "nurses, nurse"
  },
  {
    "word": "県",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ken",
    "translation": "prefectures, prefecture"
  },
  {
    "word": "着",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chaku",
    "translation": "(counter for garments)"
  },
  {
    "word": "着て",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kite",
    "translation": "wearing, dressed in, wore (and), wear (and)"
  },
  {
    "word": "着物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kimono",
    "translation": "kimono, traditional Japanese robe"
  },
  {
    "word": "矢",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ya",
    "translation": "arrows"
  },
  {
    "word": "知って",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shitte",
    "translation": "know"
  },
  {
    "word": "知り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiri",
    "translation": "find out, know"
  },
  {
    "word": "石",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ishii",
    "translation": "stones, stone"
  },
  {
    "word": "石油",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sekiyu",
    "translation": "petroleum, oil"
  },
  {
    "word": "石鹸",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sekken",
    "translation": "soap"
  },
  {
    "word": "砂",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "suna",
    "translation": "sand"
  },
  {
    "word": "研究",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kenkyuu",
    "translation": "study, studies, research"
  },
  {
    "word": "硬貨",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kouka",
    "translation": "coins, coin"
  },
  {
    "word": "確か",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tashika",
    "translation": "certain, good, sure, positive"
  },
  {
    "word": "確認",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kakunin",
    "translation": "check, confirmation"
  },
  {
    "word": "社長",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shachou",
    "translation": "CEO, company president, executive directors, executive director"
  },
  {
    "word": "祖母",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sobo",
    "translation": "(my) grandmother, (my) grandmothers"
  },
  {
    "word": "祖父",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sofu",
    "translation": "(my) grandfather, (our) grandfathers, grandfather"
  },
  {
    "word": "祝日",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shukujitsu",
    "translation": "holiday"
  },
  {
    "word": "神社",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jinja",
    "translation": "Shinto shrine, Shinto shrines"
  },
  {
    "word": "神道",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinto",
    "translation": "Shintoism"
  },
  {
    "word": "禁止",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kinshi",
    "translation": "prohibited"
  },
  {
    "word": "禁煙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kinen",
    "translation": "non-smoking"
  },
  {
    "word": "私",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "watashi",
    "translation": "I, me"
  },
  {
    "word": "秋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aki",
    "translation": "autumn, the fall, fall"
  },
  {
    "word": "秋葉原",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Akihabara",
    "translation": "Akihabara"
  },
  {
    "word": "科学",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kagaku",
    "translation": "science, scientific"
  },
  {
    "word": "科目",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kamoku",
    "translation": "course, subjects, subject, courses"
  },
  {
    "word": "秘密",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "himitsu",
    "translation": "secrets, secret"
  },
  {
    "word": "種目",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shumoku",
    "translation": "sport, events, event"
  },
  {
    "word": "穴",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ana",
    "translation": "hole"
  },
  {
    "word": "空",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sora",
    "translation": "the sky, the skies"
  },
  {
    "word": "空手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "karate",
    "translation": "karate"
  },
  {
    "word": "空気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuuki",
    "translation": "atmosphere, air"
  },
  {
    "word": "空港",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuukou",
    "translation": "airport"
  },
  {
    "word": "突然",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "totsuzen",
    "translation": "suddenly, all of a sudden, abruptly"
  },
  {
    "word": "窓",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mado",
    "translation": "windows, window, (the) window"
  },
  {
    "word": "立場",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tachiba",
    "translation": "standpoint, position, place, stance, viewpoint"
  },
  {
    "word": "立派",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rippa",
    "translation": "admirable, amazing, magnificent, splendid"
  },
  {
    "word": "竜",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryuu",
    "translation": "dragon, dragons"
  },
  {
    "word": "筆",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fude",
    "translation": "brushes, brush"
  },
  {
    "word": "答え",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotae",
    "translation": "respond, reply, (an) answer, response, answers"
  },
  {
    "word": "答えて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotaete",
    "translation": "answer, tell me (as an answer)"
  },
  {
    "word": "箱",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hako",
    "translation": "box, boxes"
  },
  {
    "word": "簡単",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kantan",
    "translation": "simple, easy"
  },
  {
    "word": "約",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yaku",
    "translation": "about, around, approximately, roughly"
  },
  {
    "word": "約束",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yakusoku",
    "translation": "promises, word, deal, deals, appointments, promise, appointment"
  },
  {
    "word": "紅茶",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kou cha",
    "translation": "(black) tea"
  },
  {
    "word": "納豆",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nattou",
    "translation": "natto, fermented soybeans"
  },
  {
    "word": "紙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kami",
    "translation": "paper"
  },
  {
    "word": "紹介",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shoukai",
    "translation": "introduction, profile"
  },
  {
    "word": "終点",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuuten",
    "translation": "the last stop, the final stop"
  },
  {
    "word": "組",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kumi",
    "translation": "pairs of"
  },
  {
    "word": "結婚",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kekkon",
    "translation": "marriage"
  },
  {
    "word": "結婚式",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kekkonshiki",
    "translation": "weddings, wedding"
  },
  {
    "word": "結構",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kekkou",
    "translation": "fine, pretty, quite"
  },
  {
    "word": "給料",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyuuryou",
    "translation": "salary, pay, salaries"
  },
  {
    "word": "絵",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "e",
    "translation": "drawings, picture, painting, drawing, pictures"
  },
  {
    "word": "絵画",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaiga",
    "translation": "painting, paintings"
  },
  {
    "word": "網",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ami",
    "translation": "grill, net, nets, wire mesh"
  },
  {
    "word": "緊張",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kinchou",
    "translation": "tension"
  },
  {
    "word": "線",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sen",
    "translation": "track, line, lines"
  },
  {
    "word": "締め切り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shimekiri",
    "translation": "deadline, due date"
  },
  {
    "word": "練習",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "renshuu",
    "translation": "training, practice"
  },
  {
    "word": "罠",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wana",
    "translation": "traps, trap"
  },
  {
    "word": "罪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zai",
    "translation": "offenses, crimes, sins, crime, guilt, offense, sin"
  },
  {
    "word": "美術館",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "bijutsukan",
    "translation": "art gallery, art museum"
  },
  {
    "word": "義務",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gimu",
    "translation": "duty, obligation, duties"
  },
  {
    "word": "羽",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "wa",
    "translation": "(counter for rabbits), (counter for birds)"
  },
  {
    "word": "習い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "narai",
    "translation": "life normally is"
  },
  {
    "word": "翻訳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "honyaku",
    "translation": "translation, translations"
  },
  {
    "word": "考え",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kangae",
    "translation": "concept, consider, idea, ideas, thought, concepts, notion, thoughts"
  },
  {
    "word": "耳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mimi",
    "translation": "ears, ear, sight"
  },
  {
    "word": "聞き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kiki",
    "translation": "hearing"
  },
  {
    "word": "肉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "niku",
    "translation": "meat"
  },
  {
    "word": "肌",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hada",
    "translation": "skin"
  },
  {
    "word": "肘",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiji",
    "translation": "elbow, elbows"
  },
  {
    "word": "肩",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kata",
    "translation": "shoulder, shoulders"
  },
  {
    "word": "肺",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hai",
    "translation": "lung, lungs"
  },
  {
    "word": "背",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "se",
    "translation": "stature, back, height"
  },
  {
    "word": "背中",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senaka",
    "translation": "behind, back"
  },
  {
    "word": "背広",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sebiro",
    "translation": "business suit"
  },
  {
    "word": "胸",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mune",
    "translation": "chest"
  },
  {
    "word": "脱ぎ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nugi",
    "translation": "take off (clothes)"
  },
  {
    "word": "脳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nou",
    "translation": "brains, brain"
  },
  {
    "word": "腕",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ude",
    "translation": "arm, arms"
  },
  {
    "word": "腕時計",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "udedokei",
    "translation": "watch, wristwatch"
  },
  {
    "word": "腹",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hara",
    "translation": "(my) stomach"
  },
  {
    "word": "膝",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hiza",
    "translation": "knee, knees"
  },
  {
    "word": "自信",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jishin",
    "translation": "faith, confidence"
  },
  {
    "word": "自分",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jibun",
    "translation": "myself, himself, herself, self, yourself"
  },
  {
    "word": "自動車",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jidousha",
    "translation": "automobiles, car, automobile"
  },
  {
    "word": "自己",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jiko",
    "translation": "self, my (your, his, her) identity, identity"
  },
  {
    "word": "自然",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shizen",
    "translation": "natural, nature"
  },
  {
    "word": "自転車",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jitensha",
    "translation": "bikes, bicycles, bike, bicycle"
  },
  {
    "word": "興味",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kyoumi",
    "translation": "interests, interest"
  },
  {
    "word": "舌",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shita",
    "translation": "tongues, tongue"
  },
  {
    "word": "舞台",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "butai",
    "translation": "setting, backdrop, stage"
  },
  {
    "word": "船",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fune",
    "translation": "boats, ship, ships, boat"
  },
  {
    "word": "船便",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "funabin",
    "translation": "surface mail"
  },
  {
    "word": "色",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iro",
    "translation": "colors, colored, (a) color"
  },
  {
    "word": "花",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hana",
    "translation": "flowers, flower"
  },
  {
    "word": "花びん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanabin",
    "translation": "vase"
  },
  {
    "word": "花火",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanabi",
    "translation": "fireworks"
  },
  {
    "word": "花瓶",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kabin",
    "translation": "vase"
  },
  {
    "word": "芸術家",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "geijutsuka",
    "translation": "artists, artist"
  },
  {
    "word": "英語",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eigo",
    "translation": "English"
  },
  {
    "word": "茂",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Shigeru",
    "translation": "Shigeru (male name)"
  },
  {
    "word": "茶碗",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chawan",
    "translation": "rice bowl, rice bowls"
  },
  {
    "word": "草",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kusa",
    "translation": "LOL (laughing out loud), grass"
  },
  {
    "word": "荷物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nimotsu",
    "translation": "load, baggage, luggage, package"
  },
  {
    "word": "葉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ha",
    "translation": "leaves, leaf"
  },
  {
    "word": "葬式",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "soushiki",
    "translation": "funeral"
  },
  {
    "word": "薬",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kusuri",
    "translation": "drugs, medicine, medication, drug"
  },
  {
    "word": "虫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mushi",
    "translation": "insects, insect, bugs, bug"
  },
  {
    "word": "血",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chi",
    "translation": "blood"
  },
  {
    "word": "行かないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikanaide",
    "translation": "do not go"
  },
  {
    "word": "行かなくて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikanakute",
    "translation": "have to go"
  },
  {
    "word": "行き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iki",
    "translation": "went (and), (will) go"
  },
  {
    "word": "行き方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikikata",
    "translation": "how to go, the way, how to get"
  },
  {
    "word": "行く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iku",
    "translation": "is going, am going, will go, goes, go, come"
  },
  {
    "word": "行けば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikeba",
    "translation": "if ... go"
  },
  {
    "word": "行こう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ikou",
    "translation": "let's go, should go"
  },
  {
    "word": "行った",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itta",
    "translation": "went, been"
  },
  {
    "word": "行ってきます",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Ittekimasu",
    "translation": "am going, I'm off, I'm leaving"
  },
  {
    "word": "行ってらっしゃい",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "Itte rasshai",
    "translation": "have a good day, have fun, take care, have a nice day, have a great day, see you later"
  },
  {
    "word": "行事",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gyouji",
    "translation": "events, event"
  },
  {
    "word": "表現",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hyougen",
    "translation": "expression, aspects, phrase"
  },
  {
    "word": "袋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fukuro",
    "translation": "bags, bag"
  },
  {
    "word": "裁判官",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saibankan",
    "translation": "judge, judges"
  },
  {
    "word": "裏",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ura",
    "translation": "opposite/other side"
  },
  {
    "word": "複雑",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fukuzatsu",
    "translation": "intricate, complex, complicated"
  },
  {
    "word": "西",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nishi",
    "translation": "west"
  },
  {
    "word": "要り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "iri ",
    "translation": "expenses"
  },
  {
    "word": "見",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mi",
    "translation": "seen, watch"
  },
  {
    "word": "見せ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mise",
    "translation": "show"
  },
  {
    "word": "見せて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "misete",
    "translation": "show (me)"
  },
  {
    "word": "見た",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mita",
    "translation": "watched, had, saw, looked (at), seen"
  },
  {
    "word": "見て",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mite",
    "translation": "look, watched (and), watching, watch, looking, saw (and), looked (and)"
  },
  {
    "word": "見ないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "minaide",
    "translation": "do not look (at)"
  },
  {
    "word": "見る",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "miru",
    "translation": "sees, look, watch, watches, looked, see"
  },
  {
    "word": "見物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kenbutsu",
    "translation": "sightseeing"
  },
  {
    "word": "見舞い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mimai",
    "translation": "visit ill people... eg at hospital"
  },
  {
    "word": "視線",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shisen",
    "translation": "glances, glance, gaze, gazes"
  },
  {
    "word": "親戚",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinseki",
    "translation": "relative, relatives"
  },
  {
    "word": "観客",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kankyaku",
    "translation": "(the) audience"
  },
  {
    "word": "角",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kado",
    "translation": "corner, (the) corner"
  },
  {
    "word": "解凍",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaitou",
    "translation": "defrosting"
  },
  {
    "word": "言い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ii",
    "translation": "say, tell"
  },
  {
    "word": "言って",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "itte",
    "translation": "saying, said (and), tell, say, telling, stating"
  },
  {
    "word": "言葉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kotoba",
    "translation": "words, tongue, word, expressions, language"
  },
  {
    "word": "言語",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gengo",
    "translation": "languages, language"
  },
  {
    "word": "計画",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "keikaku",
    "translation": "project, plans, plan"
  },
  {
    "word": "記事",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kiji",
    "translation": "articles, article"
  },
  {
    "word": "記者",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kisha",
    "translation": "reporters, journalist, journalists, reporter"
  },
  {
    "word": "試験",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shiken",
    "translation": "test, examinations, exams, tests, exam"
  },
  {
    "word": "話",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanashi",
    "translation": "story, talking, conversation, stories, chat, talk"
  },
  {
    "word": "話し",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanashi",
    "translation": "talks, speaks, talk"
  },
  {
    "word": "話せ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hanase",
    "translation": "able to speak"
  },
  {
    "word": "語",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "go",
    "translation": "word, language"
  },
  {
    "word": "読まなくて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yomanakute",
    "translation": "don’t have to read"
  },
  {
    "word": "読み",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yomi",
    "translation": "read, reading"
  },
  {
    "word": "読み方",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yomikata",
    "translation": "how to read"
  },
  {
    "word": "読む",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yomu",
    "translation": "read, will read, reads"
  },
  {
    "word": "読んで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yonde",
    "translation": "read, (by) reading"
  },
  {
    "word": "誰",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dare",
    "translation": "who"
  },
  {
    "word": "調子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "choushi",
    "translation": "doing, feel, shape, going, condition"
  },
  {
    "word": "調整",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chousei",
    "translation": "adjusting"
  },
  {
    "word": "調節",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chousetsu",
    "translation": "control"
  },
  {
    "word": "論文",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ronbun",
    "translation": "dissertation, essays, theses, thesis, dissertations"
  },
  {
    "word": "講義",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kougi",
    "translation": "lecture"
  },
  {
    "word": "警察",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "keisatsu",
    "translation": "the police"
  },
  {
    "word": "議会",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gikai",
    "translation": "parliament, Congress"
  },
  {
    "word": "議員",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "giin",
    "translation": "lawmaker, senator, member, legislator, member of ..."
  },
  {
    "word": "議論",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "giron",
    "translation": "arguments, discussion, debate, argument"
  },
  {
    "word": "豆乳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tounyuu",
    "translation": "soy milk"
  },
  {
    "word": "豆腐",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toufu",
    "translation": "bean curd, tofu"
  },
  {
    "word": "豊か",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yutaka",
    "translation": "rich (in), abundant (in)"
  },
  {
    "word": "豊富",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "houfu",
    "translation": "rich (in), extensive, abundant"
  },
  {
    "word": "豚肉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "butaniku",
    "translation": "pork"
  },
  {
    "word": "象徴",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shouchou",
    "translation": "symbols, symbol"
  },
  {
    "word": "財布",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "saifu",
    "translation": "wallet, wallets"
  },
  {
    "word": "責任",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sekinin",
    "translation": "blame, responsibilities, responsibility"
  },
  {
    "word": "買い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kai",
    "translation": "buy"
  },
  {
    "word": "買い物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaimono",
    "translation": "shopping"
  },
  {
    "word": "買う",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kau",
    "translation": "to buy, will buy, buys"
  },
  {
    "word": "買って",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "katte",
    "translation": "bought (and), buy, buying"
  },
  {
    "word": "買わなくて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kawanakute",
    "translation": "do not have to buy"
  },
  {
    "word": "貸さないで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kasanaide",
    "translation": "do not lend"
  },
  {
    "word": "貸す",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kasu",
    "translation": "lend"
  },
  {
    "word": "貿易",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "boueki",
    "translation": "trade"
  },
  {
    "word": "資源",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shigen",
    "translation": "resource, resources"
  },
  {
    "word": "質",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shitsu",
    "translation": "quality"
  },
  {
    "word": "質問",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shitsumon",
    "translation": "ask, ask (questions), question, questions"
  },
  {
    "word": "贈り物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "okurimono",
    "translation": "gifts, present, gift"
  },
  {
    "word": "赤い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "akai",
    "translation": "red"
  },
  {
    "word": "赤ちゃん",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "akachan",
    "translation": "baby, babies"
  },
  {
    "word": "走り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hashiri",
    "translation": "running, run"
  },
  {
    "word": "走る",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hashiru",
    "translation": "run"
  },
  {
    "word": "起き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oki（ru）",
    "translation": "happen, get up"
  },
  {
    "word": "超",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chou",
    "translation": "super, really"
  },
  {
    "word": "趣味",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shumi",
    "translation": "interests, hobby, pastime, hobbies"
  },
  {
    "word": "足",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ashi",
    "translation": "leg, feet, pair of, foot, legs, pairs of"
  },
  {
    "word": "身",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mi",
    "translation": "body, himself, yourself, itself, you, herself, themselves"
  },
  {
    "word": "身長",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shinchou",
    "translation": "height"
  },
  {
    "word": "車",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kuruma",
    "translation": "cars, car"
  },
  {
    "word": "車両",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sharyou",
    "translation": "train car, carriage, vehicles, car, vehicle"
  },
  {
    "word": "車庫",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shako",
    "translation": "garage"
  },
  {
    "word": "車椅子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kurumaisu",
    "translation": "wheelchairs, wheelchair"
  },
  {
    "word": "軍艦",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gunkan",
    "translation": "battleship, warship"
  },
  {
    "word": "転勤",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tenkin",
    "translation": "job transfer "
  },
  {
    "word": "輸入",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yunyuu",
    "translation": "imports"
  },
  {
    "word": "輸出",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yushutsu",
    "translation": "exports, export"
  },
  {
    "word": "辞書",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "jisho",
    "translation": "dictionaries, dictionary"
  },
  {
    "word": "農家",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nouka",
    "translation": "farmers, farmer"
  },
  {
    "word": "辺",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hen",
    "translation": "area"
  },
  {
    "word": "辺り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "atari",
    "translation": "area"
  },
  {
    "word": "近く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chikaku",
    "translation": "near, neighborhood"
  },
  {
    "word": "近所",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kinjo",
    "translation": "(the) neighborhood, neighbors, neighborhood"
  },
  {
    "word": "退屈",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taikutsu",
    "translation": "boring, bored"
  },
  {
    "word": "退院",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taiin",
    "translation": "discharge from hospital"
  },
  {
    "word": "途中",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tochuu",
    "translation": "on the way"
  },
  {
    "word": "通り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toori",
    "translation": "as, street, streets, like, road"
  },
  {
    "word": "通知",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuuchi",
    "translation": "notice"
  },
  {
    "word": "通訳",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuuyaku",
    "translation": "interpreter"
  },
  {
    "word": "通過",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsuuka",
    "translation": "passing through or by"
  },
  {
    "word": "速く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hayaku",
    "translation": "fast, quickly"
  },
  {
    "word": "連れて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tsurete",
    "translation": "take (someone)"
  },
  {
    "word": "連絡",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "renraku",
    "translation": "contact (you), contact (me)"
  },
  {
    "word": "逮捕",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "taiho",
    "translation": "(under) arrest"
  },
  {
    "word": "週まつ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuumatsu",
    "translation": "(on the) weekend, (on) weekends"
  },
  {
    "word": "週末",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuumatsu",
    "translation": "weekends, weekend"
  },
  {
    "word": "週間",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuukan",
    "translation": "weeks"
  },
  {
    "word": "進み",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "susumi",
    "translation": "progress/advance"
  },
  {
    "word": "遅く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "osoku",
    "translation": "late, slow, belated"
  },
  {
    "word": "遅刻",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chikoku",
    "translation": "late"
  },
  {
    "word": "遊び",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asobi",
    "translation": "play"
  },
  {
    "word": "遊ぼう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "asobou",
    "translation": "let's play"
  },
  {
    "word": "運動",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "undou",
    "translation": "campaign, working out, drive, exercises, cause, exercise, movement"
  },
  {
    "word": "運転",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "unten",
    "translation": "drive/operate machine"
  },
  {
    "word": "運転手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "untenshu",
    "translation": "drivers, driver"
  },
  {
    "word": "過去",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kako",
    "translation": "past, last, history"
  },
  {
    "word": "道",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "michi",
    "translation": "route, field, street, way, roads, path, road, way to go"
  },
  {
    "word": "道具",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dougu",
    "translation": "equipment, tool, tools, instruments"
  },
  {
    "word": "道路",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "douro",
    "translation": "main roads, streets, street, road"
  },
  {
    "word": "違い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chigai",
    "translation": "difference"
  },
  {
    "word": "違う",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chigau",
    "translation": "incorrect, differ, (is) different, (are) different, different, wrong, differs"
  },
  {
    "word": "遠く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tooku",
    "translation": "far, the distance"
  },
  {
    "word": "遠足",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ensoku",
    "translation": "excursion"
  },
  {
    "word": "選手",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senshu",
    "translation": "player, athlete, athletes, players"
  },
  {
    "word": "選挙",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "senkyo",
    "translation": "electoral, election, campaign, elections"
  },
  {
    "word": "郊外",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kogai",
    "translation": "suburbs, suburb"
  },
  {
    "word": "部屋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "heya",
    "translation": "room, rooms"
  },
  {
    "word": "郵便",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuubin",
    "translation": "mail, post"
  },
  {
    "word": "郵便局",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuubinkyoku",
    "translation": "post offices, post office"
  },
  {
    "word": "重さ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "omosa",
    "translation": "weight"
  },
  {
    "word": "重大",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juudai",
    "translation": "serious, great"
  },
  {
    "word": "重要",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juuyou",
    "translation": "big, vital, important"
  },
  {
    "word": "野球",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yakyuu",
    "translation": "baseball"
  },
  {
    "word": "野生",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasei",
    "translation": "wild"
  },
  {
    "word": "野菜",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yasai",
    "translation": "vegetable, vegetables"
  },
  {
    "word": "量",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryou",
    "translation": "how much, portion, portions, amount, quantity"
  },
  {
    "word": "金",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kane (kin)",
    "translation": "gold"
  },
  {
    "word": "金属",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kinzoku",
    "translation": "metal, metals"
  },
  {
    "word": "鉛筆",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "empitsu",
    "translation": "pencils, pencil"
  },
  {
    "word": "銀",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "gin",
    "translation": "silver"
  },
  {
    "word": "銀行",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ginkou",
    "translation": "banks, bank"
  },
  {
    "word": "銃",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "juu",
    "translation": "gun"
  },
  {
    "word": "銅",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dou",
    "translation": "copper, bronze"
  },
  {
    "word": "鍋",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nabe",
    "translation": "pot, pots"
  },
  {
    "word": "鏡",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kagami",
    "translation": "mirror, mirrors"
  },
  {
    "word": "長い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nagai",
    "translation": "lengthy, long"
  },
  {
    "word": "長く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nagaku",
    "translation": "for a long time, longer, long"
  },
  {
    "word": "長さ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nagasa",
    "translation": "length"
  },
  {
    "word": "長袖",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nagasode",
    "translation": "long-sleeve, long sleeves"
  },
  {
    "word": "門",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mon",
    "translation": "gate, gates"
  },
  {
    "word": "開発",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaihatsu",
    "translation": "(commercial) development"
  },
  {
    "word": "間",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aida",
    "translation": "a period of, for, when, while, between, during"
  },
  {
    "word": "降って",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "futte",
    "translation": "fall (snow/rain)"
  },
  {
    "word": "降り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furi",
    "translation": "falling (snow/rain)"
  },
  {
    "word": "降る",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "furu",
    "translation": "to fall"
  },
  {
    "word": "降れば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "fureba",
    "translation": "if it rains"
  },
  {
    "word": "陸上",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rikujou",
    "translation": "track and field"
  },
  {
    "word": "階",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kai",
    "translation": "floor/storey"
  },
  {
    "word": "階段",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaidan",
    "translation": "stairs, steps, stairwell, staircase"
  },
  {
    "word": "隣",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tonari",
    "translation": "next door, neighboring, next to"
  },
  {
    "word": "雄",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "osu",
    "translation": "male"
  },
  {
    "word": "集中",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuuchuu",
    "translation": "concentrate"
  },
  {
    "word": "雌",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mesu",
    "translation": "female"
  },
  {
    "word": "雑誌",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "zasshi",
    "translation": "magazine, magazines"
  },
  {
    "word": "離婚",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rikon",
    "translation": "divorce"
  },
  {
    "word": "難しそう",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "muzukashi sou",
    "translation": "seems difficult, seem difficult"
  },
  {
    "word": "雨",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ame",
    "translation": "rainy, (it) rains, rain"
  },
  {
    "word": "雪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "yuki",
    "translation": "snow"
  },
  {
    "word": "雲",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kumo",
    "translation": "clouds, cloud"
  },
  {
    "word": "零",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "rei",
    "translation": "zero"
  },
  {
    "word": "雷",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaminari",
    "translation": "thunder, lightning"
  },
  {
    "word": "電子",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "denshi",
    "translation": "electronic"
  },
  {
    "word": "電気",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "denki",
    "translation": "electricity, light, electric, (an) electric, lights"
  },
  {
    "word": "電池",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "denchi",
    "translation": "battery"
  },
  {
    "word": "電波",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "denpa",
    "translation": "radio waves, signal, (radio) signals, radio wave, reception"
  },
  {
    "word": "電源",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "dengen",
    "translation": "power (supply)"
  },
  {
    "word": "電球",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "denkyuu",
    "translation": "lightbulbs, (a) light bulb, (the) light bulbs, (the) lightbulb, (the) light bulb, (a) lightbulb"
  },
  {
    "word": "電話",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "denwa",
    "translation": "phones, telephone, phone, call, telephones, (the) telephone"
  },
  {
    "word": "電車",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "densha",
    "translation": "train, trains"
  },
  {
    "word": "青い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "aoi",
    "translation": "blue, pale, (a) blue"
  },
  {
    "word": "静か",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shizuka",
    "translation": "quiet"
  },
  {
    "word": "非難",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hinan",
    "translation": "criticism"
  },
  {
    "word": "革",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kawa",
    "translation": "leather"
  },
  {
    "word": "靴",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kutsu",
    "translation": "shoes, shoe"
  },
  {
    "word": "靴下",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kutsushita",
    "translation": "sock, socks"
  },
  {
    "word": "韓国",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kankoku",
    "translation": "(South) Korean, (South) Korea"
  },
  {
    "word": "音",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oto",
    "translation": "sound, sounds, noise, noises"
  },
  {
    "word": "音楽",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ongaku",
    "translation": "music"
  },
  {
    "word": "音量",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "onryou",
    "translation": "volume"
  },
  {
    "word": "頂上",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "choujou",
    "translation": "top, summit, peak"
  },
  {
    "word": "頑張れ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ganbare",
    "translation": "do your best, work (hard)"
  },
  {
    "word": "領収書",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "ryoushousho",
    "translation": "receipt, receipts"
  },
  {
    "word": "頭",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tou/atama",
    "translation": "(counter for big animals)/ mind, head, heads, minds"
  },
  {
    "word": "頼み",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tanomi",
    "translation": "request"
  },
  {
    "word": "顔",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kao",
    "translation": "face, faces"
  },
  {
    "word": "風",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaze",
    "translation": "breeze, wind"
  },
  {
    "word": "風邪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaze",
    "translation": "(common) cold"
  },
  {
    "word": "飛び",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tobi",
    "translation": "fly"
  },
  {
    "word": "飛んで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tonde",
    "translation": "flying"
  },
  {
    "word": "飛行機",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hikouki",
    "translation": "flight, airplanes, (the) plane, plane, planes, airplane"
  },
  {
    "word": "食べ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tabe",
    "translation": "will eat, eat"
  },
  {
    "word": "食べて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tabete",
    "translation": "ate (and), eating, eat, eaten"
  },
  {
    "word": "食べもの",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tabemono",
    "translation": "food"
  },
  {
    "word": "食べ物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tabemono",
    "translation": "food"
  },
  {
    "word": "食事",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shokuji",
    "translation": "meal, (the) meal, diet, meals"
  },
  {
    "word": "食堂",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shokudou",
    "translation": "cafeteria, dining room, dining hall"
  },
  {
    "word": "食欲",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shokuyoku",
    "translation": "(my) appetite, (your) appetite"
  },
  {
    "word": "飲み",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nomi",
    "translation": "drink, drinks"
  },
  {
    "word": "飲み会",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nomikai",
    "translation": "drinking parties, drinking party"
  },
  {
    "word": "飲み物",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nomimono",
    "translation": "drink, beverage, beverages, drinks"
  },
  {
    "word": "飲めば",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nomeba",
    "translation": "if ... drink, if ... take"
  },
  {
    "word": "飲んで",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "nonde",
    "translation": "drinks (and), taking, drink, drink (and), (after) drinking, take, drank (and), took (and)"
  },
  {
    "word": "首",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kubi",
    "translation": "fired, sacked/ necks, neck"
  },
  {
    "word": "首相",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shushou",
    "translation": "chancellor, prime minister"
  },
  {
    "word": "首都",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "shuto",
    "translation": "capital"
  },
  {
    "word": "香り",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kaori",
    "translation": "smell, fragrance"
  },
  {
    "word": "駅",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "eki",
    "translation": "(train) stations, station, (train) station"
  },
  {
    "word": "駐車",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chuusha",
    "translation": "parking"
  },
  {
    "word": "駐車場",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "chuushajou",
    "translation": "parking lots, car parks, car park, parking lot"
  },
  {
    "word": "骨",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hone",
    "translation": "bones, bone"
  },
  {
    "word": "高い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "takai",
    "translation": "high, tall, expensive"
  },
  {
    "word": "高く",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "takaku",
    "translation": "high up, expensive, higher"
  },
  {
    "word": "高さ",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "takasa",
    "translation": "level, height"
  },
  {
    "word": "高校",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "koukou",
    "translation": "high school"
  },
  {
    "word": "髪",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "kami",
    "translation": "hair"
  },
  {
    "word": "鬼",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "oni",
    "translation": "demon, ogres, demons, ogre"
  },
  {
    "word": "魔女",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "majo",
    "translation": "witch, witches"
  },
  {
    "word": "魔法",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mahou",
    "translation": "magic"
  },
  {
    "word": "魔法使い",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "mahoutsukai",
    "translation": "wizard, wizards"
  },
  {
    "word": "魚",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "sakana",
    "translation": "fish"
  },
  {
    "word": "鳥",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "tori",
    "translation": "bird, birds"
  },
  {
    "word": "鳴いて",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "naite",
    "translation": "hooting, chirping, barking, singing"
  },
  {
    "word": "鳴き",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "naku",
    "translation": "to make animal sound"
  },
  {
    "word": "鶏肉",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "toriniku",
    "translation": "chicken meat"
  },
  {
    "word": "鼻",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "hana",
    "translation": "nose, noses"
  },
  {
    "word": "鶏",
    "partOfSpeech": "",
    "gender": "",
    "transcription": "niwatori",
    "translation": "chicken"
  }
];

export default wordList;