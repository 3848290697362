import React from "react";
import { Table } from "react-bootstrap";

function WordListTable(props) {
  const { language } = props;

  const wordList = require(`./${language}-words.js`).default;

  let words = wordList;

  switch (language) {
    case "Japanese":
      return (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Word</th>
              <th>Transcription</th>
              <th>Translation</th>
            </tr>
          </thead>
          <tbody>
            {words.map((word, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{word.word}</td>
                <td>{word.transcription}</td>
                <td>{word.translation}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
      break;
      case "Korean":
      return (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Word</th>
              <th>Transcription</th>
              <th>Translation</th>
            </tr>
          </thead>
          <tbody>
            {words.map((word, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{word.word}</td>
                <td>{word.transcription}</td>
                <td>{word.translation}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
      break;
    default:

      return (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Word</th>
              <th>Gender</th>
              <th>Part of Speech</th>
            </tr>
          </thead>
          <tbody>
            {words.map((word, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{word.word}</td>
                <td>{word.gender}</td>
                <td>{word.partOfSpeech}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
      break;
  }
}

export default WordListTable;
