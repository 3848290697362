import React from "react";
import {
  Container,
  Card,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../../context/AppMetaDataContext";

import socialImage from "../../duolingo-ninja-social-sharing.png";

import LearnDuolingoVocabularyMainCTA from "../../Components/LearnDuolingoVocabularyMainCTA";
import HowItWorks from "../../Components/HowItWorks";
import Benefits from "../../Components/Benefits";
import Customize from "../../Components/Customize";
import FounderQuote from "../../Components/FounderQuote";
import DownloadExtension from "../../Components/DownloadExtension";
import WhyDuolingoIsNotEnough from "../../Components/WhyDuolingoIsNotEnough";

import HowToUseDuolingoFlashcards from "../../Components/HowToUseDuolingoFlashcards";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  const metaDesc = "Memorize your Duolingo vocabulary with flashcards using Duolingo Ninja. Download your Duolingo vocabulary list for any language.";
  const metaTitle = "Duolingo Flashcards - practice your Duolingo vocabulary - " + metaData.appName;
  return (
    <>
      <Helmet>
        <title>
          {metaTitle}
        </title>
        <meta
          name="description"
          key="description"
          content={metaDesc}
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaTitle}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaDesc}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <Card className="border-0 mb-5">
          <Card.Body>
            <Card.Text>
              <h1
                className="text-center"
                style={{ marginBottom: "4%", marginTop: "5%" }}
              >
                Does Duolingo Offer Flashcards?
              </h1>
              <p>
                Not anymore. Duolingo used to have a separate app for flashcards
                called{" "}
                <a
                  href="https://duolingo.fandom.com/wiki/Tinycards"
                  target="_blank"
                >
                  TinyCards
                </a>
                . However, this app was discontinued in 2020. In the meantime,
                Duolingo has made no promises to bring it back.
              </p>

              <HowToUseDuolingoFlashcards />

              {/* <Row
                style={{ marginBottom: "4%", marginTop: "5%" }}
                className="mb-3"
              >
                <Col xs={{ span: 12 }} lg={{ span: 4 }}></Col>
                <Col xs={{ span: 12 }} lg={{ span: 8 }}></Col>
              </Row> */}
            </Card.Text>
          </Card.Body>
        </Card>

        <hr className="mb-5" />
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <WhyDuolingoIsNotEnough />
        <Benefits />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
