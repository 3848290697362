import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Image,
  CardGroup,
  Badge,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { LinkContainer } from "react-router-bootstrap";
import AppMetaDataContext from "../../context/AppMetaDataContext";

import linkchimpImage from "../../language-learning-immersion-extension.png";
import meelisImage from "../../meelis.jpg";
import socialImage from "../../duolingo-ninja-social-sharing.png";
import duolingoMobileAppImage from "../../duolingo-mobile-app-vocabulary-list.png";
import duolingoNinjaVocabularyList from "../../duolingo-ninja-vocabulary-list.png";

import LearnDuolingoVocabularyMainCTA from "../../Components/LearnDuolingoVocabularyMainCTA";
import HowItWorks from "../../Components/HowItWorks";
import Benefits from "../../Components/Benefits";
import Customize from "../../Components/Customize";
import FounderQuote from "../../Components/FounderQuote";
import DownloadExtension from "../../Components/DownloadExtension";
import WhyDuolingoIsNotEnough from "../../Components/WhyDuolingoIsNotEnough";

import DownloadVocabularyWithExtension from "../../Components/DownloadVocabularyWithExtension";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>Can Duolingo Make You Fluent? - {metaData.appName}</title>
        <meta
          name="description"
          key="description"
          content="A compilation of latest research into whether Duolingo can make you fluent in a language."
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <Card className="border-0 mb-5">
          <Card.Body>
            <Card.Text>
              <h1
                className="text-center"
                style={{ marginBottom: "4%", marginTop: "5%" }}
              >
                Can Duolingo Make You Fluent?
              </h1>
              <p>
                The short answer is - no. Duolingo alone can’t make you fluent
                in a language. But it can help you learn a language. And it can
                be a great tool for learning a language. But it’s not enough to
                make you fluent. Read on to find out why.
              </p>
              <h2>What is Duolingo?</h2>
              <p>
                Duolingo is one of the most popular language learning apps in
                the world. It’s a gamified language learning app that helps you
                learn a language by playing games. It’s free to use and it’s
                available on the web and on mobile devices.
              </p>
              <h2>How is fluency measured?</h2>
              <p>
                Fluency is measured by how well you can communicate in a
                language. There are different tests that measure fluency. But in
                general, here's what they measure:
                <ol className="mt-3">
                  <li>
                    <strong>Passive skills (input)</strong>
                    <ol>
                      <li>Listening</li>
                      <li>Reading</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Active skills (output)</strong>
                    <ol>
                      <li>Speaking</li>
                      <li>Writing</li>
                    </ol>
                  </li>
                </ol>
              </p>
              <p>
                Additionally, some tests also measure cultural knowledge, for
                example, or some other nuances.
              </p>

              <h2>What does Duolingo claim?</h2>
              <p>
                Duolingo has a separate{" "}
                <a href="https://www.duolingo.com/efficacy" target="_blank">
                  research section
                </a>{" "}
                on their website that lists the studies they've done (or that
                have been done about them). However, as of 2023 there wasn't a
                single "study" there that wasn't biased or misleading.
              </p>
              <p>
                Quite often, they claim that Duolingo is comparable to studying
                a language in a university. But that's not true (at least it
                hasn't been proven yet).
              </p>
              <p>
                For example,{" "}
                <a
                  href="https://duolingo-papers.s3.amazonaws.com/reports/duolingo-efficacy-cross-section-whitepaper.pdf"
                  target="_blank"
                >
                  “Developing reading and listening proficiency with Duolingo: A
                  cross-sectional analysis”
                </a>{" "}
                (2022) claims that:
                <div className="mx-5 my-3">
                  <i>
                    “Across most measures, the difference between Duolingo
                    Spanish and French learners completing Unit 5 versus Unit 7
                    was comparable to those of US university students between
                    the 4th and 5th semester”.
                  </i>
                </div>
                However, they only measured passive skills (listening and
                reading). They did not measure active skills (speaking and
                writing). And they did not measure cultural knowledge (which is
                typically included in university courses).
              </p>
              <p>
                Duolingo makes many other dubious claims. You can check out a
                more comprehensive review of their claims in this{" "}
                <a
                  href="https://medium.com/@meelis-ojasild/no-you-cant-master-a-language-with-duolingo-nor-trust-their-research-cfe6655d0e8c"
                  target="_blank"
                >
                  Medium article
                </a>
                .
              </p>
              <h2>What does Duolingo get fundamentally wrong?</h2>
              <h3>Meaningless content</h3>
              <p>
                Every single sentence in Duolingo is meaningless. It's
                meaningless because it's not connected to anything. It's not
                connected to a context or actual situation you're in. It's all
                made up content about what might happen in the future.
              </p>
              <p>
                For example, take this sentence from Duolingo: "¿Dónde está el
                museo?" ("Where is the museum?" in Spanish). It's a sentence
                that's theoretically useful but practically meaningless. It's
                meaningless because while you're learning this you're actually
                not in a situation where you're looking for a museum. So, it has
                no purpose or immediate use. It's like trying to learn to swim
                on dry land.
              </p>
              <p>
                Another problem with meaningless content is that you will just
                forget about it more quickly.
              </p>
              <h3>You don't get enough input</h3>
              <p>
                A common misconception is that you need to speak and write (put
                the language into use) to become fluent. In reality, what matter
                way more is input - reading and listening. Especially{" "}
                <a
                  href="https://lingochampion.com/comprehensible-input"
                  target="_blank"
                >
                  comprehensible input
                </a>
                . If you don't get enough input, you will not become fluent.
              </p>
              <p>
                A baby hears on average ~15,000 words per day. That's how much
                is needed to acquire a language. It depends how much time you
                spend on Duolingo, but maybe you'll hear just 500-1,000 words
                per day at best. That's a difference of up to 30x. Meaning, the efficiency of Duolingo is very very low.
              </p>
              <h3>You can't choose the content you're interested in</h3>
              <p>
                Duolingo forces you into a learning track. They force every user
                to go through exactly the same content. However, people have
                different interests. If you're interested, for example, in agriculture or programming, then that's the kind of content you should be learning from.
              </p>
              <h2>What is Duolingo good for?</h2>
              <p>
                Duolingo is great for getting some basic understanding about a
                language. Learning your first words and seeing how the basic
                grammar works.
              </p>
              <p>
                Because it's gamified, it's also great for getting into the
                habit of learning a language every day. You can compete with
                friends (and strangers), earn badges etc.
              </p>
              <p>However, the efficiency will wear off fast and you will find yourself stuck quite quickly. Theoretically you should be fluent but in practice you won't be able to read any proper native speaker content.</p>
              <h2>What to do besides Duolingo?</h2>
              <p>
                In general, you have to find native speaker content that you're
                interested in. This can be in the form of podcasts, YouTube
                videos, TV shows, movies, books, articles, blogs, social media
                etc. But it has to be meaningful and interesting <i>to you</i>.
              </p>
              <p>
                There are some apps out there as well that help with that. For
                example,{" "}
                <a href="https://lingochampion.com" target="_blank">
                  Lingo Champion
                </a>{" "}
                is a language learning app that helps you learn a language by
                reading and listening to native speaker content. It's available
                on the web and on mobile devices. You can easily look up words
                next to the content and see your progress.
              </p>
              <p>
                Another way is to first choose a route between Duolingo and native
                speaker content and use something like{" "}
                <a href="/">Duolingo Ninja</a>. It's a browser extension that
                works based on your habits - it translates part of the web pages
                that you visit anyway into the language you're learning on Duolingo based on the
                Duolingo vocabulary. That way you can keep learning the
                vocabulary daily without any extra effort. Plus the content is
                meaningful to you.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>

        <hr className="mb-5" />
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <WhyDuolingoIsNotEnough />
        <Benefits />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
