import React from "react";
import {
  Card,
  Row,
  Col,
  Image,
} from "react-bootstrap";

import meelisImage from "../meelis.jpg";

import AppMetaDataContext from "../context/AppMetaDataContext";

function FounderQuote() {
  const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
                <hr />
        <Row style={{ padding: "10% 5% 7% 5%" }}>
          <Col
            xs={{ span: 12, order: 2 }}
            lg={{ span: 3, order: 1 }}
            style={{ marginBottom: "5%" }}
          >
            <Image
              src={meelisImage}
              alt="Meelis Ojasild"
              roundedCircle={true}
              style={{ maxWidth: "150px" }}
            />
          </Col>
          <Col xs={{ span: 12, order: 1 }} lg={{ span: 9, order: 2 }}>
            <Card className="border-0">
              <blockquote className="blockquote">
                <em>
                  “I created {metaData.appName} to immerse myself in Spanish,
                  not just do daily dry repetitions on Duolingo. Translating
                  parts of web pages to Spanish keeps my vocabulary fresh
                  without having to change my habits.”
                </em>
              </blockquote>
              <p>- Meelis Ojasild, Founder of {metaData.appName}</p>
            </Card>
          </Col>
        </Row>

        <hr />
      </div>
    );
}

export default FounderQuote;
