import React from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import AppMetaDataContext from "../context/AppMetaDataContext";

function DownloadExtension() {
  const metaData = React.useContext(AppMetaDataContext);

  return (
    <div>
      <div className="text-center" style={{ margin: "0% 3%" }}>
        <h1 className="text-center" style={{ paddingTop: "15%" }}>
          Download the Chrome Extension for free
        </h1>
        <h4
          className="text-center"
          style={{ padding: "2% 0%", fontWeight: "normal" }}
        >
          Start learning in under 30 seconds
        </h4>
        {/* <a
            href="https://chrome.google.com/webstore/detail/duolingo-champion/cdiecbgkdbkloiniekbfblbdbjgehpgb"
            target="_blank"
            rel="noreferrer"
          > */}
        <LinkContainer to="/register">
          <Button size="lg" className="me-3">
            Try for free
          </Button>
        </LinkContainer>
        {/* </a> */}
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default DownloadExtension;
