import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Container } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

export default function Register() {
  const metaData = React.useContext(AppMetaDataContext);

  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);

  const handleSubmit = async (data) => {
    data.preventDefault();
    // if passwords don't match, set an error message
    if (passwordRef.current.value != passwordConfirmRef.current.value) {
      setErrorMessage("Passwords don't match.");
      console.log("Passwords don't match.")
      return;
    }
      console.log("Password change form submitted");

    const body = {
      password: passwordRef.current.value,
      password_confirm: passwordConfirmRef.current.value,
    };
    // send API request to register back-end
    axios
      .post("/api/user/password-change", body)
      .then((res) => {
        console.log("Password change API response: ", res.data);
        // TODO: somehow need to change the user ID either in a cookie or message body
        setSuccessMessage(res.data.message);
        setErrorMessage(undefined);
      })
      .catch((err) => {
        console.log("Password change failed with message: ", err.response.data);
        setErrorMessage(err.response.data);
        setSuccessMessage(undefined);
      });
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minheight: "100vh" }}
    >
      <Helmet>
        <title>Register - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Change your password</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" required ref={passwordRef} />
              </Form.Group>
              <Form.Group id="password-confirm">
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control
                  type="password"
                  required
                  ref={passwordConfirmRef}
                />
              </Form.Group>
              <Button type="submit" className="w-100 mt-4">
                Change password
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
        </div>
      </div>
    </Container>
  );
}
