import React from "react";
import {
  Card,
  CardGroup,
} from "react-bootstrap";

import AppMetaDataContext from "../context/AppMetaDataContext";

function Benefits() {
  const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
        <h1
          className="text-center"
          style={{ marginBottom: "7%", marginTop: "10%" }}
        >
          Benefits
        </h1>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>
                Works with your habits not against your habits
              </Card.Title>
              <Card.Text>
                There's no extra app or website you have to open. Just keep
                doing what you always do - browsing the web. And you'll keep
                learning every day.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>Learn the lingo you need</Card.Title>
              <Card.Text>
                As you browse the pages you are interested in, you'll champion
                the vocabulary you actually need to use.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        <CardGroup style={{ marginBottom: "13%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>No nonsense sentences</Card.Title>
              <Card.Text>
                Duolingo sometimes teaches you sentences that don't make sense.
                "The cow opened its favorite book" and "The cat wrote a letter
                in Japanese" are actual sentences you'll "learn" in Duolingo.
                Ever seen a cat write a letter in Japanese?
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>
                Give meaning back to your language learning
              </Card.Title>
              <Card.Text>
                Most sentences in Duolingo do make sense. But there's no
                immediate use for them. I'm not trying to order wine in a
                restaurant while I'm on my couch looking at my phone. With{" "}
                {metaData.appName} you'll learn the vocabulary you actually
                need.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        </div>
    );
}

export default Benefits;
