const wordList = [
    {
        "word": "à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "a",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "à bientôt",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à boire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à café",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à cause",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à cause de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à ce moment-là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à cette époque",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à clé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à côté",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à côté de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à demain",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à droite",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à emporter",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à gauche",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a honte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à jour",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à l'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à l'envers",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à l'étage",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à l'étranger",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à l'extérieur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à l'heure",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à l'intérieur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à la campagne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à la mode",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à manger",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à moi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à mon avis",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à peu près",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à pied",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à plus tard",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à point",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à soupe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à suspense",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à temps partiel",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à temps plein",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "à travers",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a-t-elle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "a-t-il",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "abandonner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abeille",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "abeilles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "abîme",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "abîmée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "abîmes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "absence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "absent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "absente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "absolument",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "accélère",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accélérer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accepté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "accepter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accepterais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accepterait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accès",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "accident",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "accompagner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accompagnez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accord",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "accueille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accueillent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accueilles",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accueillez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accueillie",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "accueillir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "accueillons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "achats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "acheté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "achète",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "achètent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acheter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "achèteras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "achèteront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "achètes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "achetez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "achetons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "acides",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "acier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "acte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "actes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "acteur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "action",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "actrices",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "actuel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "actuelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "actuellement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "actuels",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "adaptateur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "adapté",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "adaptée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "addition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "adolescent",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "adolescents",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "adopter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "adorable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "adore",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "adoré",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "adorent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "adorer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "adores",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "adorez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "adorons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ados",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "adresse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "adulte",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "adultes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "aéroport",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aéroports",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "affaire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "affaires",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "affirme",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "afin de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "africain",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "africaine",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "africaines",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "africains",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "afrique",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "âge",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "âgée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "âgées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "agenda",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "âgés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "agir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agissant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "agneau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "agréable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "agréables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "agricultrice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aidé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "aide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aider",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aideras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aiderez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aides",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aidez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aidions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aidons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ail",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aile",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ailes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ailleurs",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "aimables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "aimais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aime",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aimé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "aime bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aiment",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aimer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aimerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aimerais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aimeront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aimes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aimes-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aimez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aimons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aîné",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ainsi",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "air",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aire de jeux",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ajoute",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ajouter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ajoutez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "album",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "albums",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "alcool",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "aléatoire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "algérien",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "algérienne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "aliment",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "allaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "allemagne",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "allemand",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "allemande",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "aller",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aller simple",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aller-retour",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "allergie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "allergies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "allergique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "allers-retours",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "allez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "allô",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "allongée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "allongeras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allongerons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allons-y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "allume",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allumer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "allumez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "alors",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "alors qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "alors que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "alpes",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "ambitieuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ambitieux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ambition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "âme",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "améliorations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "améliore",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amélioré",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "améliorer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amélioreras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amène",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amener",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amèneras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "amère",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "amères",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "américain",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "américaine",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "américaines",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "américains",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "amérique",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "ami",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "amie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "amitié",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "amour",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "amoureux",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ample",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "amusant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "amusante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "amusantes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "amusants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "an",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "analyse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ananas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ancien",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ancienne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ânes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ange",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anglais",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "anglaise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "anglaises",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "angleterre",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "angoissé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "angoissés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "animal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "animal de compagnie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "animaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "animé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "année",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "années",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "anniversaire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "annoncé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "annonce",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "annuel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "annuelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "annulation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "annulé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "annuler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ans",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "antipathique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "août",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "apercevoir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aperçois",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aperçoit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apéritif",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "apparaître",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apparaîtront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appareil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "appareil photo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "apparemment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "appartement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "appartements",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "appartenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appartenu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "appartiendra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appartiennent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appartient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "appelé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "appeler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appelez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "appelle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appellent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appelles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "applaudi",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "applaudir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "applaudis",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "applaudisses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "applaudissez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "applaudissions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apporté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "apporte",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apporter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apporteras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apportes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apportez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apportons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apprend",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "apprendra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apprendras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apprendre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "apprendrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apprends",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "apprenez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "apprennent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "apprenons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "appris",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "apprise",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "appuie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "appuyer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "après",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "après avoir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après être",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après leur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après lui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après m'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après nous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après s'être",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après t'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après-demain",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après-midi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "après-midis",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "arabe",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "araignée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "araignées",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arbre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arbres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arc",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "architecture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arêtes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "argent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "argument",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arguments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "arme",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "armée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "armes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "armoire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arobase",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arrêtais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrête",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrêter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrêtez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "arrivée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "arrivent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arriver",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "arrivera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrivons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "arrondissement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "art",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "article",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "articles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "artiste",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "artistes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "arts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "as",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "as besoin",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "as l'air",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "as-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ascenseur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "asiatiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "asie",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "aspirateur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "assemblée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "asseoir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asseyait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asseyent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asseyez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "asseyiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "asseyions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "assez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "assez de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "assied",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "assieds",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "assieds-toi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "assiette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "assiettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "assis",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "assistant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "assistante",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "assisté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "assister",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "association",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "associations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "atlantique",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "atmosphère",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "attaché",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "attache",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attacher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "atteindre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attend",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attendront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attends",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "attendu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "attention",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "atterrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "atterrisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attraction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "attractions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "attrape",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "attraper",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "au",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au bout de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au chômage",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au coin de la rue",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au courant",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au début",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au fait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au foyer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au lieu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au lieu de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au milieu de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au moins",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au petit déjeuner",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au revoir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au secours",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au téléphone",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au-dessus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "au-dessus de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "auberge de jeunesse",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aucun",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "aucune",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "augmentation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aujourd'hui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "auprès de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "auquel",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "aura",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aurai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "auraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aurais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aurait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "auras",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "aurions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aurons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "auront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "aussi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "australie",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "autant",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "autant de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "autant que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "auteur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "automne",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "autoroute",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "autour",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "autour de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "autre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "autre chose",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "autrement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "autres",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "aux",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "aux toilettes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "auxquelles",
        "partOfSpeech": "",
        "gender": "Feminine"
    },
    {
        "word": "auxquels",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "avaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avaient l'habitude",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avait l'habitude",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avancé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "avancer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avancez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avant",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "avant de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avant qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avant que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avant-hier",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avantages",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avec",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avec enthousiasme",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avec qui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "avenir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "aviez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avion",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avions",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avocat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "avocate",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "avoir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "avril",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ayant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ayez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "baby-sitter",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "baby-sitting",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bac",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bagage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bagages",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bague",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bagues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "baguette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "baguettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "baignoire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "baignoires",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bain",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bains",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "balais",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "balcon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "balcons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "baleine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "baleines",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "balle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ballon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "banane",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bananes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bancaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "bancaires",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "banlieue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "banque",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "barbe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "barbecue",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bas",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "basket",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "baskets",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "basses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bateau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bateaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bâtiment",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bâtiments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bâton",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bâtons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "battaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "battant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "battent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "batterie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "battions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "battre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "battu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "bavard",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bavardes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bazar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "beau",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "beau-père",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "beaucoup",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "beaucoup de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "beaucoup de monde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "beaucoup trop",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "beauté",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "beaux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "beaux-parents",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bébé",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bébés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "belge",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "belges",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "belgique",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "belle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "belle-mère",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "belles",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bénéfice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bénéfices",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bénin",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "berceau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "berceaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "besoin",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "besoin de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "besoins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bête",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "beurre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bibliothèque",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bibliothèques",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bien qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bien que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bien réussi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bien s'entendre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bien sûr",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bientôt",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "bienvenue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bières",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bijou",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bijoux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "billet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "billet d'avion",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "billets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "biographie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "biographies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "biologie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "biologique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "biologiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "biscuits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bistrot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bizarre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "bizarres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "blagues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "blanc",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "blanche",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "blanches",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "blancs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "blessé",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "blessée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "blesser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "blessure",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bleu",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bleu clair",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bleu foncé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bleue",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bleues",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bleus",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "blog",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "blogueur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "blogueuse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "blonde",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "blondes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "blonds",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "boirai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "boire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bois",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "boisson",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boissons",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boissons fraîches",
        "partOfSpeech": "",
        "gender": "Feminine"
    },
    {
        "word": "boit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "boîte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boîte aux lettres",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "boîte de nuit",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boîtes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boîtes de conserve",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "boivent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bols",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bon",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bon marché",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bonbon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bonbons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bondé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bondée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bonheur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bonjour",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "bonne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bonne journée",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bonne nuit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bonne soirée",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bonnes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bonnet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bons",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bord",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bordeaux",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "botte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bottes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bouche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boucher",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "boucherie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bouclé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bouclés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "boucles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bouger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bougie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bougies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bouillir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "boulanger",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "boulangère",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boulangerie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boulangeries",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boulangers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "boulot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bourse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bouteille",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bouteilles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boutique",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "boutons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "boxe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "bracelet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bracelets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "branche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "branches",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bras",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bravo",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "bref",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "brésil",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "brésilien",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brillant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brillante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "brioche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "britanniques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "brochure",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "brochures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "brocolis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bronzé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bronze",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bronzera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bronzeras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bronzerons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bronzés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brosse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "brosse à dents",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "brosser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "brosses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "brouillard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bruit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bruits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "brûle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "brûlé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "brume",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "brun",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "brune",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bruyant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "bruyante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bruyantes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "bu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "budget",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bureau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bureau de change",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bureau de poste",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bureaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "bureaux de tabac",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "bus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "but",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "buvant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buvez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "buvons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "bœuf",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "c'",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "c'est",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "c'est le",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "c'est ma",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "c'est mon",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "c'est un",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "c'est une",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "c'était",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ça",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "ça a l'air",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ça fait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ça te dit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ça va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ça va bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ça vous dit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cabine d'essayage",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cacahuètes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cache",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cachent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cacher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cachet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cadeau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cadeaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cadre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cadres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "café",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "café au lait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cafés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cafétéria",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cafetière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cages",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cahier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cahier d'activités",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cahiers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caisse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "caisses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "calcul",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calculatrice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "calculs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calendrier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "calme",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "calmes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "caméra",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cameroun",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "camion",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "camionnette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camions",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campagne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "camping",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "campings",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canada",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "canadien",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "canadienne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "canadiennes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "canadiens",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "canapé",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canapés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "candidat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "candidate",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "candidats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "canicule",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "capable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "capital",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "capitales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "car",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "caractère",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "caractères",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carotte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carré",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "carrefour",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "carrés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "carrière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carrières",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "carte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "carte bancaire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "carte d'embarquement",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "carte d'identité",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "carte de crédit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "carte postale",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cartes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cartes d'embarquement",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cascade",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "casque",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "casquette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "casquettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "casse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cassé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "cassée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "cassées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "casser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "casseroles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cassés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "casses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "catastrophe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "catégorie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "catégories",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cathédrales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cauchemars",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cave",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ce",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "ce dont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ce jour-là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ce matin-là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ce qu'",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "ce que",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "ce qui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ce soir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ce sont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ce sont des",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ce sont les",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ce sont mes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ceci",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "ceinture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ceintures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cela",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "célèbre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "célèbres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "célébrité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "célibataire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "celle",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "celle dont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "celle-ci",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "celle-là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "celles",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "celles dont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "celles-ci",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "celles-là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "celui",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "celui dont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "celui-ci",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "celui-là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cent",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cent mille",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "centaine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "centaines",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "centime",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centimes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centimètre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centimètres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "centre-ville",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cents",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cependant",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "cercle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "céréales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cerf-volant",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cerise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cerises",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "certain",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "certaine",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "certainement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "certains",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "cerveau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ces",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "cet",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "cette",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "cette année-là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ceux",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "ceux dont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ceux-ci",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ceux-là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "chacun",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "chaise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chaises",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chaleur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chaleureuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "chambre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chambres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chameau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chameaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chamelle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "champagne",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "champignons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "champion",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "champs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "champs-élysées",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "chance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "change",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "changement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "changements",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "changeons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "changer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "changes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "changez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chanson",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chansons",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chante",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chanté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "chantent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chanter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chantes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chanteur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chanteuse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chantez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chapeau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chapeaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chapitre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chaque",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "charge",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chargeons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chargeur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chargeurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chariot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chariots",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "charmante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "chat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "château",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "châteaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chatons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chatte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chaud",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "chaude",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "chaudes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "chauffage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chauffeur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chaussette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chaussettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chaussure",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chaussures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chauve",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "chef",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chemin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chemise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chemises",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cher",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cherchaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cherchant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cherche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cherchent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chercher",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "cherches",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cherchez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cherchions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cherchons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "chère",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "chères",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "chérie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chers",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cheval",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chevaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cheveux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cheville",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chèvre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chèvres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chez",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "chien",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chienne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chiennes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chiens",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chimie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chimiste",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "chine",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "chinois",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "chinoise",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "choc",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chocolat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chocolats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "choisi",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "choisir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "choisirai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "choisis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "choisisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "choisissent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "choisissez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "choisissons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "choisit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "choix",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chômage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "choqué",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "choquées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "choqués",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "chose",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "choses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chou",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "chouette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "chouettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ci",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ciel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cigale",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cigales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cigarettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cinéma",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cinq",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cinquantaine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cinquante",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "cinquième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "circulation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cirque",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ciseaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "citer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "citoyen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "citoyenne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "citron",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "citrons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "clair",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "claire",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "clairement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "classe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "classique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "clavier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "claviers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "clé",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clé usb",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "clés",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clés usb",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "client",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "clientes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clients",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "climat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "climatisation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "clinique",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cliquer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cliquez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "clown",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "clowns",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "club",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cochon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cochons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "code",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coiffe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coiffé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "coiffeur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coiffeurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coiffeuse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coiffeuses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coiffez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coiffures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "col",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "colère",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "collants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "collègue",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "collègues",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "colles",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "colliers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "collines",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "colonne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coloré",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "combien",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "combien de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comédiens",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "commande",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "commander",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comme",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "comme ça",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comme d'habitude",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "commence",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "commencé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "commencé à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "commencer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "commencer à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "commençons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "comment ça va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comment est-ce que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comment est-ce que je fais pour",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comment tu t'appelles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "commentaire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "commentaires",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "commode",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "communication",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "compagnie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "compagnie aérienne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comparé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "compare",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compétition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "complet",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "complètement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "complètent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compléter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compliquées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "comportera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comportes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "composes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprend",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comprendrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comprendrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendrions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprendront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprends",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comprenez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comprennent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "comprenons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compris",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comprise",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "comprises",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "comptable",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "comptait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "compte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "compte de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "compter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "comptes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concentrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "concept",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concepts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concerne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "concert",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conclusion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conclusions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "concombre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concombres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concours",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "concurrence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "condition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conditions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conduire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduirez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduirions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduisaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduisent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduisez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduisons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conduit",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "conduite",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "conférence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "confiance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "confirmerez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "confiture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conflit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conflits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "confortable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "confortables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "congélateur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "congèle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "congeler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connaissais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connaissent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connaissez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connaissions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connaissons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connaît",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connaître",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connecter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "connexion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "connu",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "conseil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "conseille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conseiller",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conseillerais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conseils",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "consommateur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "consommateurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "consommation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "consommer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "constater",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "construction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "construire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "consulter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contact",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contacter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "content",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "contente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "contentes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "contents",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "contient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "continent",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "continents",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "continue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "continué",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "continuer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "continuera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "continuez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "contraire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "contrat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contrats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contre",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "contrôle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "contrôler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "conversation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "conversations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "copain",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "copains",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "copie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "copié sur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "copine",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "copines",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "coq",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "corps",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "correctement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "costaud",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "costume",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "costumes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "côté",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "côte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "côte d'ivoire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "côtés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coton",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cou",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "couché",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "couche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couchent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coucher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couchera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couches",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couchez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coucou",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "coud",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coudes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coudra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coudrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coudrais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coudre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coule",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coulé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "couler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couleur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "couleurs",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "couloir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "couloirs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coup",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coup d'œil",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "coup de pied",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "coupe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coupe de cheveux",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "coupée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "couper",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couple",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "courage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "courageux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "courais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "courant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "courent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "courez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "courir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "courons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "courriel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cours",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "course",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "course à pied",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "courses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "court",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "courtes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "courts",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "couru",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "cousais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cousait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cousin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cousine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cousins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coussins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cousu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "coûte",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couteau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "couteaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "coûtent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "coûter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couverts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "couverture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "couvertures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "couvrent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couvres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couvrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couvrir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "couvrirais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couvrirait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "couvrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crachin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "crayon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "crayons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "créais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "créait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "créatifs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "création",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "créatrice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "crédit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "crédits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "créer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "créiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "créions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crème",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "crème fraîche",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "crème solaire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "crêpe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "crêpes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "crevettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "criait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crié",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "crier",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crime",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cris",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "crise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "critique",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "croient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "croiraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "croire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "crois",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "croisière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "croissance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "croissant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "croissants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "croit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "croix",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "croyait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "croyiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "croyions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cru",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "crues",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "crus",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cubes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cuillère",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "cuir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuisine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuisiné",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "cuisiner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuisines",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuisinez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuisinier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cuisinons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "cuits",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "cuivre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "culture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "culturel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "culturelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "curieuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "curieux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "curry",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cv",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "cœur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "d'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "d'à côté",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "d'abord",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "d'accord",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "d'ailleurs",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "d'après",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "d'habitude",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "d'occasion",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "d'origine",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dame",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dames",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "danger",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dangereuses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "dangereux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "dans",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "dans deux jours",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dans la vie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dans le nord",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dans le sud",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dans un mois",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dansé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "danse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "danse classique",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "danser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "danserais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "danseront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "danses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "danseur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "danseuse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dansez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dansons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "date",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dates",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dauphin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de cette manière",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de classe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de l'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de l'après-midi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de l'est",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de l'exercice",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de la brioche",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de la confiture",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de la famille",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de la musique",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de pire en pire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de rien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de sport",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de toute façon",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de travail",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "de valeur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "débarrasse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "débarrasser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "débarrasses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "débat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "debout",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "débrouille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "début",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "débutant",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "débutante",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "débutants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "décembre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "déchets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "déchiré",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "déchirer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déchireras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décider",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déciderais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décision",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "décisions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "déclarer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décoller",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décontracté",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "décontractés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "découvert",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "découverte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "découvrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "découvriras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décrirait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décrire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décrive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décrives",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décroché",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "décroche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "décrocher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déçu",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "déçus",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dedans",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "défaire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "défendre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "défense",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "définir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "définition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "définitivement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "dégoûtant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "dégoûtantes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "degré",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "degrés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dehors",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "déjà",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "déjeuné",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "déjeuner",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "déjeunes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déjeunez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "délicieuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "délicieux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "demain",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "demandé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "demande",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "demande-lui",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "demander",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "demandes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "demandez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "demandons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "démarrer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déménager",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "demi",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "demi-heure",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "demie",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "démocratie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "démontre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dent",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dentiste",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "dents",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "départ",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dépêche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dépêche-toi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dépêchent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dépêcher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dépêches",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dépêchés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "dépêchez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dépêchons-nous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dépend",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dépenser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dépenses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déplacer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déplacera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "déplaçons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "depuis",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "depuis longtemps",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "depuis qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "déranger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dérangera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dérangerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dérangeront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dernière",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "dernière fois que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dernièrement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "dernières",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "derrière",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "des",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "dès",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "dès qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dès que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "désagréables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "descend",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descendant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descendent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descendrais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descendre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descends",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "descendu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "descendues",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "descendus",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "description",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "désert",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "désir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "désiré",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "désirez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "désolé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "désolée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "désolées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "désolés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "désordre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dessert",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dessin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dessin animé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dessiné",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "dessine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dessiner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dessines",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dessinez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dessins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dessous",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "dessus",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "destin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "destination",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "détail",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "détails",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "déteste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "détesté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "détestées",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "détestes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deux",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "deux fois",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "deux mille",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "deuxième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "devant",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "développement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "devenez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devenons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devenu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "devenue",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "devenues",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "devenus",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "devez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devez pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "deviendra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deviendrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deviennent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deviens",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "deviner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devineras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devoir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devoirs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "devons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devrais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devrait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "devront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dictionnaire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dieu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dieux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "différence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "différent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "différente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "différentes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "différents",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "difficile",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "difficiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "difficulté",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "difficultés",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dimanche",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "diminuer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diminution",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dinde",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dîné",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "dîner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dînerez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dînes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dînez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dinosaure",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "diplômée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "dirait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "diras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "direct",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "directement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "directeur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "direction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dirige",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dirigent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "diriger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dirigerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dis-lui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dis-moi",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "discothèque",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "discothèques",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "discours",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "discussion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "discuter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disparaissent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disparaître",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disponibles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dispute",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "disque",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dissertation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dissertations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "distance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "distribue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "distribuées",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "distribuer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "distributeur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "divertissement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "divorcé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "divorcée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "divorcés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "dix",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dix-huit",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "dix-neuf",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dix-sept",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "dixième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "dizaine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "docteur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "docteurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "document",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "documents",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "doigt",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "doigts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dois",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dois pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "doit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "doivent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dollar",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dollars",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dommage",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "donc",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "donne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "donnent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "donner",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "donneras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "donnes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "donnez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dormais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dorment",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormirai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormirez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormirons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormiront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dormons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dors",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dort",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "dos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dossier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "dossiers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "douane",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "douanier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "douanière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "double",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "doubles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "douce",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "doucement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "douche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "douches",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "douleur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "doute",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "doutons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "doux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "douzaine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "douze",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "drame",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "drames",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "drap",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "drapeau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "draps",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "droit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "droite",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "droits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "drôle",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "du",
        "partOfSpeech": "Preposition",
        "gender": "Masculine"
    },
    {
        "word": "du café",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du café au lait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du lait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du mal à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du mal à comprendre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du matin",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du miel",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du monde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du pain",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du soir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du sucre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du thé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "du vélo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "dur",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "durant",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "dure",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "durée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "dures",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "durs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "e-mail",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "e-mails",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "eau",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "échange",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "échanger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "échangeras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "échappaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écharpe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "écharpes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "échecs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "échelle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "éclaircie",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "éclaircies",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "éclairé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "éclaire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "éclairée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "éclairs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "école",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "écoles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "économie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "économiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "économisé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "économiser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "économisez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écosse",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "écoute",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écouté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "écoutent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écouter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écoutera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écouteront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écoutes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écoutons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écran",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "écrire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "écris",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écrit",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "écrite",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "écrivain",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "écrivait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écrive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écrivent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écrives",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écrivez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écrivions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "écrivons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "éducation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "effectivement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "effet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "effets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "efficace",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "efficaces",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "effort",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "efforts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "effrayant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "effrayante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "effrayé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "effrayés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "égal",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "égale",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "égaux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "église",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "églises",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "égoïste",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "élection",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "élections",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "électricité",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "électrique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "électriques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "électronique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "électroniques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "élégant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "élégants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "élément",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "éléments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "éléphant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "éléphants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "élève",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "élèves",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "elle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "elles",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "elles sont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "embarqué",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "embarquerons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "embauche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "embouteillage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "embouteillages",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "embrasser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "embrasserai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "émission",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "emmène",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "emmener",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "emmèneras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "emmenez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "émotions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "empêcher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "emploi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "emploie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "employé",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "employée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "employés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "employeur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "employeurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "employeuse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "emprunté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "emprunter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "en",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "en a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en achète",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en ai",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en arrière",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en as",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en attente",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en avance",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en bas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en bas de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en boîte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en ce moment",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en changer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en colère",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en dehors de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en dernier",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en dessert",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en direct",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en effet",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en entrée",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en espèces",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en est",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en face",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en faisant",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en fait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en famille",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en feu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en forme",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en général",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en haut",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en haut de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en jouant",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en libre-service",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en ligne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en mange",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en même temps",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en occupe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en panne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en parle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en pause",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en plein air",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en premier",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en prépare",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en promotion",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en retard",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en secret",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en tout",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en train de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en utilisant",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en vacances",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en vente",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en veux",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en ville",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en-dessous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "en-dessous de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "enchanté",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "enchantée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "enchantés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "encore",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "encore plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "énergie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "énervants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "énervé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "enfance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "enfant",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "enfants",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "enfer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "enfin",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "enfuis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enfuit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enfuyons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ennemi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ennemie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ennemis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ennuie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ennuies",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ennuyer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ennuyeuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ennuyeux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "énorme",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "énormes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "enseignant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "enseignante",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "enseigne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enseignement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ensemble",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ensoleillée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ensuite",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "entend",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "entende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entendent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "entendez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "entendons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "entendrait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entendre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entendriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entends",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entendu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "entendu parler",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "entier",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "entière",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "entièrement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "entraînait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entraîner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entraîneur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "entrant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entre",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "entrée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "entrées",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "entreprise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "entreprises",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "entrer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrerais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "entres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entretien",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "entrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "entrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "enveloppe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "enveloppes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "envie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "environ",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "environnement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "envoie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "envoient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envoies",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envoyaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envoyé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "envoyée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "envoyer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "envoyez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "envoyons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "épais",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "épaisse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "épaisses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "épaule",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "épice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "épicerie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "épices",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "épinards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "épisode",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "épisodes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "éponge",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "éponges",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "époque",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "équipe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "équipes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "erreur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "erreurs",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "es",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "es-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "escalade",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "escalier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "escaliers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "escargot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "escargots",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espace",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espaces",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "espagne",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "espagnol",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "espagnole",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "espagnols",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "espèce",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "espérer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "espérons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "espoir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "esprit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "essai",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "essaie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "essaies",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "essayé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "essayer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "essayions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "essence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "est",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "est-ce qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "est-ce que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "est-elle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "est-il",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "estomac",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "et",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "et demi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "et demie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "et en plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "et onze",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "et un",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "étage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "étagère",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "étaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "était",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étape",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "état",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "états",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "états-unis",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "été",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "éteignent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "éteindre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "éternue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "êtes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "êtes-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "étiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étiquette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "étiquettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "étoile",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "étoiles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "étrange",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "étranger",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "étrangère",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "étrangères",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "étrangers",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "être",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étroit",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "étroite",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "étroits",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "étude",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "études",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "étudiais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étudiant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "étudiante",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "étudiantes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "étudiants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "étudie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étudié",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "étudient",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "étudier",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étudies",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "étudiez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "étudiez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "étudions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "eu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "euro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "europe",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "européen",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "européenne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "européens",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "euros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "eux",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "événement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "évidemment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "évident",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "évier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "éviter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "éviterions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ex",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "exact",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "exactement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "examen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "examens",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "excellent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "excellente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "excellentes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "excursion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "excuse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "excuse-moi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "excuser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "excuses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "excusés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "excusez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "excusez-moi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "exemple",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "exemples",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "exercice",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "existe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "existera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "expédition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "expérience",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "expériences",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "experte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "experts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "explication",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "explications",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "explique",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "expliquer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "expliquons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "exploré",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "explorer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "exprimer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "extérieur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "extraordinaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "extraordinaires",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "extrême",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "extrêmement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "facile",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "facilement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "façon",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "facture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "faible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "faim",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "faire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faire de la plongée",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faire du ski",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faire mal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faire plaisir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faire plaisir à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faire un voyage",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fais-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faisaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faisais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faisait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faisant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faisiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faisions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faisons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fait",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "fait maison",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fait mal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fait tomber",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fait-il",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faites maison",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faites-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fallait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "falloir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "famille",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "familles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fantôme",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fantômes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "farine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fasse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fassiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fatigant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fatigue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fatigué",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fatiguée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fatiguées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fatigués",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "faudra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faudrait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fausse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fausses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "faut",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "faute",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fauteuil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fauteuil roulant",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "faux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "félicitations",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "femme",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "femme de ménage",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "femmes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fenêtre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fenêtres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ferai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ferait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "feras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fermé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ferme",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fermée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ferment",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fermer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fermes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fermons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "feront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fête",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fêtent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fêter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fêtes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "feu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "feu d'artifice",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "feuille",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "feuilles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "feuilleton",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "feux d'artifice",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "février",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fichiers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fiction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fier",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fiers",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fièvre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "filer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fille",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "filles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "film",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "films",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fils",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fin",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "finale",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "finalement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "fine",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fini",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "finies",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "finir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finirai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finirons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finiront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finissent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finissez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finissions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finissons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "finit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fixe",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fleur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fleurs",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fleuve",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fleuves",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "flotte",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "flotteras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "flûtes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "foi",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fois",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "folle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fonction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fond",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fonds",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "font",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fontaine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "foot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "football",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "football américain",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "force",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "forêt",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "formation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "forme",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "formidable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "formidables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "formulaire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "formulaires",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "formule",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fort",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "forte",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fortement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "forts",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fou",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "foule",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "four",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fourchette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fourchettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fourmi",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fourmis",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fourrure",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fours",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fous",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fragile",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fragiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "fraîche",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "fraîches",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "frais",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fraise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fraises",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "franc",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "français",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "française",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "françaises",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "france",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "franchement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "francophone",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "frappé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "frappe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "frapper",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "freiner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "frère",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "frères",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "frites",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "froid",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "froide",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "froids",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "fromage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "front",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "frontière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fruits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "fruits de mer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "fume",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "fumée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "fumer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "furieuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "futur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gâche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gâcher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gagne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gagné",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "gagner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gagnons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "garage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "garant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "garçon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "garçons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "garde",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "garder",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gardien de but",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "gardienne de but",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "gare",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gare-toi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "garée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "garer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "gares",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "garés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "garez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "garons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "garons-nous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "gaspille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gaspiller",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gaspillons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gâteau",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "gâteaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gauche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gauches",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "gazeuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gelée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "geler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gênant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gênante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gêne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gêné",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gênée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "général",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "généralement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "génération",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "généreuses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "généreux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gênés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "genève",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "génial",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "géniales",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "géniaux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "génie",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "genoux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "genre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "genres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gens",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gentil",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gentille",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gentilles",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "gentils",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "géographie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gérante",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gérer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gères",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gestion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "girafes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "glace",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "glaçons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "glisser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "golf",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gommes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gorge",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "goût",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "goûtait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "goûté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "goûter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "goûtera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "goûterai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "goûteras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "goûtiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "goûtions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "goûts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "goutte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gouvernement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gouvernements",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grâce",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "grâce à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "graines",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "grammaire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "grammes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "grand",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grand frère",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "grand-mère",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "grand-père",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "grande",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "grande sœur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "grandes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grandi",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "grandir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grandiront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grandisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grandissent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grandit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grands",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grands-mères",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "grands-parents",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "grands-pères",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "gras",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gratuit",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "gratuitement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "gratuits",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grave",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "gravement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "grèce",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "grenouilles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "grève",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "grèves",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "grignoter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grignotons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grillée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "griller",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grippe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "gris",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grise",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grogne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "gros",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "grosse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grosses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "grossi",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "grossir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grossiras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grossis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grossissait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grossit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "grotte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "groupe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "groupes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "guadeloupe",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "guerre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "guide",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guides",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "guidions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "guitare",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "guitariste",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "gymnase",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "gymnastique",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "habillé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "habitais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habitant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "habitants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "habité",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "habite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habitent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habiter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habiteront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habites-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "habitez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habitez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "habitiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habitons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "habitude",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "habitudes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "habituelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "habituent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "haïr",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hall",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hamburgers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "handball",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "handicapée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "handicapées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "haricots",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hasard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hâte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "haut",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "haute",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "hauteur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hebdomadaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "hélicoptère",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hélicoptères",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "herbe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "héros",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hésite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "hésitez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "heure",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "heures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "heureuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "heureusement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "heureux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "hibou",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hiboux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hier",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "hip-hop",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "histoire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "histoires",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "historique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "historiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "hiver",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hockey",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "homme",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "homme d'affaires",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "homme de ménage",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hommes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "homosexuel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "homosexuelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "homosexuelles",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "homosexuels",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "honnête",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "honnêtement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "honneur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "honte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "hôpital",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hôpitaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "horloge",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "horloges",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "horreur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "horrible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "horribles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "hors de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hôtel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "hôtel de ville",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "hôtels",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "huile",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "huit",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "huitième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "humeur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "humide",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "humoriste",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "humour",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ici",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "idéal",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "idéale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "idée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "idées",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "identité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "il",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "il est",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il faut",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il n'y a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il n'y a plus de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il n'y avait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il ne faut pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il va y avoir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il y a",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il y a eu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il y a trois",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "il y avait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "île",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ils",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "ils sont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ils y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "image",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "imagination",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "imagine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "imaginer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "imagines",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "immédiatement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "immense",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "immeuble",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "immigration",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "imperméable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "impoli",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "impolie",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "importance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "important",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "importante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "importants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "impossible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "impôts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "impression",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "imprimante",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "imprimer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "imprimez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inacceptable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "inacceptables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "incapable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "incapables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "incendie",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inconnu",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "inconnue",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "inconnus",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "inconvénient",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inconvénients",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "incorrect",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "incroyable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "indépendant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "indépendante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "indépendants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "indien",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "indiennes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "indiens",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "indispensable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "individuelles",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "industrie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "industriel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "industrielle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "infirmier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "infirmière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "influent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "information",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "informations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "informatique",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ingénieur",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "ingénieurs",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "ingrédients",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inondation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "inquiet",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "inquiétant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "inquiète",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "inquiètes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inquiets",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "inscrite",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "inscrivent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inscriviez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "insecte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "instructions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "instrument",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "instruments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "insupportables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "intellectuel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "intelligence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "intelligent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "intelligente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "intelligentes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "intelligents",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "intention",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "intéressant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "intéressante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "intéresse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intéressé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "intéressée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "intéressent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intéressera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intéresseriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "intérêt",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "intérieur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "intermédiaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "international",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "internationales",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "internet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "inutile",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "inutiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "invention",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "inventions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "inverse",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "investissement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "invitation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "invite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invité",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "invitées",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "invitent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inviter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "inviteront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invités",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "invites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "invitons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "irai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "iraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "irais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "irait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "iras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "iriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "irions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "irlandais",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "irlande",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "iront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "italie",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "italien",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "italienne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "italiennes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "j'",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "j'achète",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "j'ai",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "j'ai envie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "j'y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jalouse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "jamaïcain",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "jamais",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "jambe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jambes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jambon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "janvier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "japon",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "japonais",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "japonaise",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "jardin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jardins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jaune",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "jaunes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "jazz",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "je",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "je me perds",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "je ne sais pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jean",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jeans",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jeter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jetez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jettes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jeu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jeu de société",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jeu vidéo",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jeudi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jeune",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "jeunes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "jeunesse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jeux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "joie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "joli",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "jolie",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "joue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jouent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jouer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "joueras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jouerons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "joues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jouet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "joueurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jouez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jouiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jouons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "jour",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jour de congé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jour férié",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "journal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "journaliste",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "journalistes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "journaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "journée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jours",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jours de congé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "juge",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "juger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "juges",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "juillet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "juin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jumeau",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "jumelle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jumelles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jungle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jupe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jupes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "jus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "jus d'orange",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jusqu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jusqu'à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "jusque",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "juste",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "justice",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "kilo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "kilomètre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "kilomètres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "kilos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "l'",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "l'ai",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "l'air",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "l'année où",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "l'as",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "l'aspirateur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "l'avez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "l'eau",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "l'habitude",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "l'y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "là",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "la leur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la même",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la mienne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la nôtre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la saison où",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la sienne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la tienne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la vaisselle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "la vôtre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "là-bas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lac",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "laid",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "laide",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "laine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "laisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "laissent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "laisser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "laissez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lait",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "laitue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "laitues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lampe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lance",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lancer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lances",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "langue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "langues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lapin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lapins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "laquelle",
        "partOfSpeech": "",
        "gender": "Feminine"
    },
    {
        "word": "large",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "largeur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "larmes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lavabo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lave",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "laver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "laves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lavons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "le",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "le balai",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le droit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le jour où",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le leur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le long",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le lundi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le matin",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le même",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le mien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le mieux",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le nôtre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le quatorze juillet",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le sien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le soir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le tien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le vendredi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le vôtre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "le week-end",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "leçon",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "leçons",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lecture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "léger",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "légère",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "légume",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "légumes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "lentement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "lequel",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "les",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "les courses",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les leur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les leurs",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les magasins",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les miennes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les miens",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les nôtres",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les siennes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les siens",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les tiennes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les tiens",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les vôtres",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "les y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lesquelles",
        "partOfSpeech": "",
        "gender": "Feminine"
    },
    {
        "word": "lesquels",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "lettre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lettres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "leur",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "leur en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "leurs",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "lève",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "levé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "lève-toi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lèvent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lever",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lèvera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lèves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lèvres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "libanais",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "libanaises",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "libérer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "libérera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "liberté",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "librairie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "libre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "libres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "licence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "licences",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lieu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lieux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ligne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lignes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "limites",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "limonade",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "linge",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lion",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lions",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "liquide",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "liront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lisent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lisez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lisions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "lisons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "liste",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "litre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "littérature",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "livré",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "livre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "livres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "local",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "locale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "logement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "logiciel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "logique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "loi",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "loin",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "loin de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lois",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "loisir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "loisirs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "londres",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "long",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "longtemps",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "longue",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "longues",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "longueur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lorsque",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "louer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "loueras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "loueront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "louons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "loup",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lourd",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "louvre",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "loyer",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "lui",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "lui écrire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lui en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lumière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lundi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lune",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lune de miel",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lunettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "lunettes de soleil",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "lutter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "luxe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lycée",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "lycées",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "m'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "m'appelle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "m'en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "m'en aller",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "m'en fiche",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "m'en vais",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "m'endors",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "m'y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ma",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "ma place",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "machine à café",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "machine à laver",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "madame",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mademoiselle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "magasin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "magasins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "magazine",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "magazines",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "magique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "magiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "magnifique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mai",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maigri",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "maigrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "maigrira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "maigrisses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "maigrit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "maillot de bain",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "main",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mains",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maintenant",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "maire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mairie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mais",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "maïs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maison",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "maison de vacances",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "maisons",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "majeurs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "majorité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mal à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mal à l'aise",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mal au",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "malade",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "malades",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "maladie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "malgré",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "malheureusement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "malheureux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "malienne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "maman",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mamie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "manche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mange",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "mangent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangeons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangeras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangerez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangeront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manges",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mangue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "manifestation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "manifester",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manifesterions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mannequin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mannequins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "manque",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manquer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manques",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manquez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "manteau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "manteaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "manuels",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "maquillage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marchant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "marche",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "marché",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marchent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "marcher",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "marcheras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "marches",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "marchés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mardi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mari",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mariage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marié",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "mariée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mariées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "marierons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "marieront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mariés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maris",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "marocain",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "marron",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mars",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "masque",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "match",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "matériel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "maternelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mathématiques",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "matière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "matin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mauvais",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mauvaise",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "maximum",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mayonnaise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "me",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "me déshabiller",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "me maquille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "me peignerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "me suis",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mécanicien",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "méchant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "méchante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "méchantes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "méchants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "médecin",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "médecine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "médecins",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "médias",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "médical",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "médicament",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "médicaments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "médicaux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "méditerranée",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "méfient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "meilleur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meilleure",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "meilleures",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "meilleurs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mélange",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mélangé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "mélanger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mélanges",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mélangez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "melon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "melons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "membres",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "même",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "même pas",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "mêmes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "mémoire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ménage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mensonge",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ment",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "menton",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mentons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "menu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mer",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "merci",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "merci beaucoup",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "merci de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mercredi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mère",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mères",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mérite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "merveilleuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "merveilleuses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mes",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "mesdames",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "message",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "messages",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "messieurs",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "met",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "métal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "météo",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "méthode",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "méthodes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "métier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mètre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mètres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "métro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mets",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mettront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "meuble",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meubles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "meurs",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mexicain",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mexicaine",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mexicaines",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mexicains",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mexique",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "micro-ondes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "midi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "miel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mien",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "mienne",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "miens",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "mieux",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "mignon",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "milieu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mille",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "mille neuf cent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "milliard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "milliards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "millimètre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "millimètres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "million",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "millions",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mince",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "minérale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "minimum",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ministère",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ministres",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "minuit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "minuscule",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "minute",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "minutes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "miroir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mission",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "missions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mode d'emploi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "modèle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "modèles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moderne",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "modernes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "modes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "modestes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "moi",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "moi-même",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "moins",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "moins bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "moins de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "moins le quart",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "mois",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moitié",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "molles",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "moment",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mon",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "monde",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mondial",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mondiale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "monnaie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "monsieur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monstre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monstres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "montagne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "montant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "monté",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "montées",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "monter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "montre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montré",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "montrer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "montres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "montrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "monument",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "morceau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "morceaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mord",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mordant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mordent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mordre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mords",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mort",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "morte",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "morts",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "morue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mosquée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mosquées",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mot de passe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "moteur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "motif",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "motive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "moto",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mots",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mouche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mouchoir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mouillé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mouillée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mouillés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "mourir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "mous",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "moustache",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "moustique",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moustiques",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moutarde",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "moutons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mouvement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mouvements",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "moyen",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "municipal",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "municipale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "mur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "murs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "muscle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "musclé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "musée",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "musées",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "musicales",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "musicien",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "musicienne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "musiciens",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "musique",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "mystère",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "mystères",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "n'",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "n'ai pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'aime pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'allez pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'as pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'importe où",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'importe quand",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'importe quel",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'importe quelle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'importe quoi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'y a plus de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'y avait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'y en a pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "n'y en a plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nage",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nagé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "nagent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nageons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nager",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nagerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nageras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nageront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nagez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "naissance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "naissances",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "naître",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "namibie",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "nappe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "national",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nationalité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nature",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "naturel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "navette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "naviguant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "naviguer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "navigues",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "né",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "ne",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ne faisait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne la",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne le",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne me",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne me suis",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne nous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne nous asseyons pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne nous garons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne nous perdons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne peut plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne peux plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne porte pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne s'est",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne sais plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne se",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne se sont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne t'es",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne te",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne te gare pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne te lève pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne te perds",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne veux plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne vous asseyez pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne vous garez pas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ne vous perdez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nécessaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "née",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "nées",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "négatif",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "négatifs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "négative",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "neige",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "neigé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "neigeait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "neiger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nerveuses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nerveux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "nettoie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nettoient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nettoieras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nettoierez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nettoyé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "nettoyer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "nettoyons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "neuf",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "neuve",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "neuves",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "neuvième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "neveu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "neveux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nez",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ni",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "nièce",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nièces",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "niveau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "noël",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "noir",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "noire",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "noires",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "noix",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "noix de coco",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nom",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nom d'utilisateur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nombre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nombres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nombreux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "noms",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "non",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "non plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "non-fumeur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nord",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nord-est",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nord-ouest",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "normal",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "normale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "normalement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "nos",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "notamment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "noté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "note",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "noteras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "notes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "notre",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "nôtre",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "notre-dame",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nôtres",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "nourriture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nous",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "nous appelons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nous connaissons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nous détestons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nous en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nous enverrons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nous nous sommes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "nouveau",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nouveaux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nouvel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nouvelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nouvelles",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "novembre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nu",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nuage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nuages",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "nuageux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nue",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nues",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nuit",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nuits",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "nul",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "nulle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "nulle part",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "numérique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "numéro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "objet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "objets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "obligatoire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "obligé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "observe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "observera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "obtenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "obtiendra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "obtiendras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "obtiennent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "obtiens",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "occasion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "occupé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "occupée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "occupées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "occuper",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "occupés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "occupons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "océan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "océanie",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "octobre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "odeur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "offert",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "office de tourisme",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "offre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "offrent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "offriraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrirais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offririez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offririons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "offrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oh là là",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "oignon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oiseau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oiseaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "olives",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "olympiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ombre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ombres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "omelettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "on",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "on apporte",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "on devrait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "on mange",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "on se voit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "on sort",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "on va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "on y va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "oncle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oncles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ongles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ont",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ont-ils",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "onze",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "onzième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "opéra",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "opéras",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "opération",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "opinion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "opposition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "option",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "options",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "or",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "orageuses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "orange",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "oranges",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "orchestre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ordinaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ordinateur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ordonnance",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ordonnances",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oreille",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "oreiller",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oreillers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oreilles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "organe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "organisation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "organiser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "original",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "originale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "originaux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "origine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "os",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "où",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ou",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "où est-ce que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "oublie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oublié",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "oublier",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "oubliiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ouest",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "oui",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "ouragan",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ouragans",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ours",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "outil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "outils",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ouvert",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ouverte",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ouvrant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ouvre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ouvrent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ouvres",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ouvrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ouvrières",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ouvriers",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "ouvrir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ouvrirais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ouvrirait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ouvririez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ouvrons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "page",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pages",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pain",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pain grillé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "paire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "paires",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "paix",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "palais",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pâle",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "panne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "panneau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "panneaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pantalon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pantalons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pantoufle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pantoufles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "papa",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "papier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "papiers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "papillon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "papillons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pâques",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "par",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "par contre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "par cœur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "par exemple",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "par hasard",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "par qui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "par terre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "paradis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paragraphe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paramètres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parapluie",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parapluies",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parc",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parce qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parce que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parcs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pardon",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "pardonner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pardonneras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pareil",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pareille",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pareilles",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pareils",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "parent",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parents",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paresseuses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "parfait",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "parfaite",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "parfaitement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "parfois",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "parfum",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parfums",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paris",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "parisienne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "parking",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parkings",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parlais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parle de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parlement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "parlent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlent-ils",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parleras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlerons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parles",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parles-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parlez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parlions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parlons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "parmi",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "paroles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pars",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "part",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "partage",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partageons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partager",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "parti",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "participe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "participer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "participions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "particulièrement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "partie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "parties",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "partir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partira",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partiraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partirais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partirait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partiras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "partout",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "parts",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pas assez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas d'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas de poisson",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas de viande",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas du tout",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas encore",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas envie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas ici",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas maintenant",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas mal",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas moi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pas que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "passager",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "passagère",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "passagères",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "passagers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "passais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "passé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "passe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passe par",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "passent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passeport",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "passeports",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "passer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passeras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passerez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "passion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "passons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pastèque",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pastèques",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pâtes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "patience",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "patient",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "patienté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "patiente",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "patienter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "patienterai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "patients",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pâtisserie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "patron",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "patronne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pause",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pauvre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "pauvres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "payais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "payant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "payante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "payantes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "paye",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "payé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "payer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "payes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "payez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pays",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paysage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "paysages",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "peau",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pêchait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pêché",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "pêche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pêcher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pêches",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "peignent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peigner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peindras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peindre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peins",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peint",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "peintres",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "peinture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pelouse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pendant",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "pendant que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pensaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pense",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pensé à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pense à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pense que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pensée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pensées",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pensent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penser à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "penseras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "penserez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perd",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perde",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perds",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "perdu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "perdue",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "père",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "père noël",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "période",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "permettraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permettrais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permettre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "permis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "perroquet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "perroquets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "personnalité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "personne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "personne n'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "personne ne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "personnel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "personnelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "personnellement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "personnels",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "personnes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pèse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pèsent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "petit",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "petit ami",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "petit déjeuner",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "petit frère",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "petit-fils",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "petite",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "petite amie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "petite sœur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "petite-fille",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "petites",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "petits",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "petits pois",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "petits-enfants",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pétrole",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "peu",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "peu importe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "peuple",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "peur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "peur de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "peut",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peut-être",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "peut-être qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "peut-être que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "peuvent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peux",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "peux plus",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pharmacie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pharmacien",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pharmacienne",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pharmacies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "phénomène",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "philosophie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "photo",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "photographes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "photographie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "photographies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "photos",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "phrase",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "phrases",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "physique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "physiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "piano",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pièce",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pièce de théâtre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pièces",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pied",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pieds",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pierre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piéton",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piétons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "piles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pilote",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "piments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pique-nique",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "piscine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "piscines",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pizza",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pizzas",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "placard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "placards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "place",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "places",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plafond",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plage",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plairait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plaire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plairont",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plaisantais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plaisanterais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plaisent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plaisez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plaisir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plaît",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plan",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "planche à voile",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "planète",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plans",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plante",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plantes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plastique",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plat",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "plate",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "plateau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plates",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "plein",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "plein de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pleine",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pleurais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pleuré",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "pleure",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pleurer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pleures",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pleut",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pleuvoir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plongée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plonger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plongerions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "plongeur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "plongeuse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plongeuses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "pluie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "plus",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "plus de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "plus jamais",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "plus maintenant",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "plusieurs",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "plutôt",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "pneu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pneus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poches",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poêles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poème",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poèmes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poésie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poids",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poils",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "point",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "points",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pois",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poisson",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poissons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poitrine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poivre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poivrons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poli",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "police",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "policier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "policiers",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "polis",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "politique",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "politiques",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "pollution",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pomme",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pommes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pommes de terre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pompiers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pont",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ponts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pop",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "populaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "populaires",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "population",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "porc",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "port",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "portables",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "portail",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "portais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "porte-monnaie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "portefeuille",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "portefeuilles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "porter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "porterai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "portugais",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "portugaises",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "portugal",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "pose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "posez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "positif",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "position",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "positions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "possède",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "possédons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "possibilité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "possibilités",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "possible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "poste",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "posté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "poster",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "postuler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "postulerais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "postulerait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poubelle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poubelles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pouce",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pouces",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poudre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poule",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poulet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "poupée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "poupées",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pour",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pour cent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pour la santé",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pour le moment",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pour qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pour que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pourboire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pourquoi",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "pourra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourrais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourrait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourrions",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pourront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pourtant",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "poussé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "pousser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "poussière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pouvaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pouvais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pouvait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pouvez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pouvez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pouvez-vous me dire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pouviez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pouvions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pouvoir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pouvoirs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pouvons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "pratique",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pratiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "précédent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "précieux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "précis",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "préfère",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préféré",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "préférée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "préfèrent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préférer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préférerait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préfères",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préférez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préférons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "premier",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "première",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "première fois que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "prenait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prend",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendrais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendrait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prendront",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "prends",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prends-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "prenez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "prenez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "prennent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "prénom",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prenons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "préparation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prépare",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "préparé",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préparée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "préparent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préparer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préparerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préparerons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prépares",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préparez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "préparons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "près",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "près de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "près du",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "présent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "présente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "présenter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "présentez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "présents",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "président",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "présidente",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "presque",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "pressé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "presse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pressée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "prêt",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "prête",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prêter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prêtez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prêts",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "preuve",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "preuves",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prévenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prévisions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prévoir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prévoirait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prévois",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prévoyez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prévoyons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prévu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "priait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pries",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "priions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prince",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "princesse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "principal",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "principale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "principalement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "principes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "printemps",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prison",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prisonnière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prisonniers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "privée",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "privées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "prix",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "probablement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "problème",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "procès",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prochain",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "prochaine",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "production",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "produire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "produit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "produits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "prof",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "professeur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "professeurs",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "professionnel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "professionnelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "professionnels",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "profit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "profond",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "progrès",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "projet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "projets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "promenade",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "promenades",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "promène",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "promené",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "promènent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "promener",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "promenez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "promenions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "promenons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "promesse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "promesses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "promotion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "prononce",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prononcer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prononces",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prononciation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proposerait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proposez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "proposition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "propriétaire",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "propriété",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "propriétés",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "protection",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "protéger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "prudent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "prudente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "pu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "public",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "publicité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "publicités",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "publique",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "puis",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "puissant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "puisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "puissions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "pulls",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pur",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "pure",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "purée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "pyjamas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "pyrénées",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qu'est-ce qu'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "qu'est-ce que",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quai",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "qualité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "quand",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quand même",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quantité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "quarante",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quart",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "quartier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "quartier latin",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quasiment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "quatorze",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quatre",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quatre-vingt",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quatre-vingt-dix",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quatre-vingt-neuf",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quatre-vingt-un",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quatre-vingts",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quatrième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "que",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "québécois",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "québécoise",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "québécoises",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "quel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "quel âge",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quel temps",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "quelles",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "quelqu'un",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "quelque chose",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quelque chose de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quelque part",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quelques",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "quelques uns",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "question",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "questions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "queue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "qui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "quinze",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "quitte",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quitté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "quitter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "quoi",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "raconte",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "raconter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "radio",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "raides",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "raisin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "raison",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "raisons",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ramasse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ramasser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ramassés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "randonnée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "range",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rangées",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "ranger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rangera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rangeras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rangeront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rap",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rapide",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "rapidement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "rapides",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "rappelle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rappellerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rappelons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rapport",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rapports",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "raquettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rare",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "rarement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "rasant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rase",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rasent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rases",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "raté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "rate",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ratent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rater",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ratera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rateraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ravie",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rayon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rayures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "réaction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "réagisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réagisses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réalisateurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "réaliser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réalité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "récemment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "récente",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "réception",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "réceptionnistes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "recette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "recettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "recevoir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recevons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recherche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "recherches",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reçois",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reçoit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reçoivent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recommande",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "recommandes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reconnais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reconnaît",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reconnaîtra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reconnu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "reconnue",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "reconnus",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "reçu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "recycle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rédaction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "réduire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réduise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réduises",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réduisions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réduit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "réellement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "réfléchi",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réfléchir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réfléchirez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réfléchissait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réfléchisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réfléchisses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réfléchissions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réfrigérateur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "refuse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "refuser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regardais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regardait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regardant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regarde",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regardent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regarder",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regarderons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regardes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regardez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "regardons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "régime",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "régimes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "région",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "règle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "règles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "relation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "relations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "relativement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "relever",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "religieux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "religion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "remarque",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remboursez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remercie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remplacer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remplacerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rempli",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "remplir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remplis",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "remplissiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remplissions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remplissons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remplit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remue",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "remuer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "renard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "renards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rencontre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rencontrer",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rencontrés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "rend",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rendent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rendez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rendra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rendre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rendre visite",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rendu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "renseignement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "renseignements",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rentrais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rentre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rentrées",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "rentrent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rentrer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rentreront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rentres",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rentrés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "rentrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rentriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rentrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "renversé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "réparé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "répare",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réparer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "repas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "répond",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "réponde",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "répondent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "répondes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "répondez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "répondions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "répondons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "répondra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "répondras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "répondre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "réponds",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "répondu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "réponse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "réponses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "reportages",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reporter",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "reporters",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "repos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "repose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reposée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "reprendre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "représente",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "république",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "requin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "requins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "réseau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "réseaux sociaux",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "réservation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "réservations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "réserve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réservé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "réservent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réserver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réserves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réservez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réservons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "résoudre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respect",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "respecte",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respectent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respecter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respectons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "respirer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "responsabilité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "responsabilités",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "responsable",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "responsables",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ressemble",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ressemblent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ressembles",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ressemblez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ressemblons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ressources",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "restaurant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "restaurants",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reste",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "resté",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "restée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "restées",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "restent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rester",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "resterai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "resteraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "restes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "restés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "restez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "resto",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "restons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "résultat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "résultats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "retard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "retarder",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retards",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "retire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retirer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retires",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retirez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retour",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "retourne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retournées",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "retourner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retournerais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retournés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "retraite",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "retrouve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retrouver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "retrouves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réunies",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "réunion",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "réunirions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réunissent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réunissiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réunissons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réussir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réussiras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réussisse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réussisses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réussissiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réussissions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réussite",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rêvait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rêve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réveillant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réveille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réveillée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "réveillent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "réveiller",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réveillons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "revenez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "revenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "revenons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rêvent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "revenues",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "rêver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reverras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rêves",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "reviendra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reviennent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "reviens",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "revient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rêvions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "révise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réviser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "réviseras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "revoir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "révolution",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rez-de-chaussée",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rhume",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ri",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "riche",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "riches",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "rideau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rideaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ridicule",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "rien",
        "partOfSpeech": "Pronoun",
        "gender": "Neuter"
    },
    {
        "word": "rien du tout",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rien n'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rien ne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rigolé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "rigoler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "risque",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rivière",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rivières",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "riz",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "robe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "robes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "robinet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "robot",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "robots",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "roi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rois",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rôle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "roman",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "romantique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ronde",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rondes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "rose",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "roses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rôti",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "rôtie",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "roue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "roues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rouge",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "rouges",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "roule",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "rouler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "roulerait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rousse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "route",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "routes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "royaume-uni",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rue",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "rues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "rugby",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "russe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "rythme",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "s'",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "s'adorent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'appelle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'appellent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'écrit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'écrivaient",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'écrivent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'en fiche",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'en va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'en vont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'envoyaient",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'est",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'il",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'il te plaît",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'il vous plaît",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "s'y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sa",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "sable",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sac",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sac à dos",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sac à main",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sachant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sacré-cœur",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sacs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sage",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sages",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "saignant",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "saignes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saint",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saisis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saisissent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saison",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "saisons",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "salade",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "salaire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sale",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "salé",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "salle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "salle d'attente",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "salle de bain",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "salle de classe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "salle de sport",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "salles",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "salon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "salut",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "samedi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "samu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sandales",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sandwich",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sandwichs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sang",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sans",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "sans arrêt",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sans contact",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "santé",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "satisfait",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "satisfaite",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "saucisses",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sauf",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "saumon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "saurai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saurais",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sauras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saurez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sauront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sauter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sauvage",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sauvegarde",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sauvegardez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sauver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "savais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "savait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "savent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "saveur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "saveurs",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "savez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "savoir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "savon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "savons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scannais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "scène",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "science",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sciences",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "scientifique",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "scientifiques",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "scolaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "scooter",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sculpteur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sculpture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sculptures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "se",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "se lever",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se maquille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "se parlent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se passe",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se peigne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "se perd",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se ressemblent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se sont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se taire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se trouve",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "se voyaient",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sec",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sèche",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sèche-cheveux",
        "partOfSpeech": "",
        "gender": "Masculine"
    },
    {
        "word": "sèchent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sécher avec",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "second",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "seconde",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "secondes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "secret",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "secrétaire",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "secrétaires",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "secrets",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "secteur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sécurité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "seine",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "seize",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "sel",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sélectionné",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "selon",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "semaine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "semaines",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "semble",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "semblent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "semblez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "semestre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sénateur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sénateurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sénégalais",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sens",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sensible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentie",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "sentiment",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sentiments",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sentir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sentons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "séparés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sept",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "septembre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "septième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serait-il possible",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "seras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "série",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "séries",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sérieusement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "sérieuses",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sérieux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "seriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "seront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serpent",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "serpents",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "serre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sers",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sert",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sert à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "servent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "servent à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "serves",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serveur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "serveuse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "servez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "service",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "services",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "serviette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "serviettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "serviez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "servir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "serviraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "ses",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "seul",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "seule",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "seulement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "seules",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "seuls",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sexe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "shampoing",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "shampooing",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "short",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "si",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "siècle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "siècles",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "signatures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "signer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "signerait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "signes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "signification",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "signifie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "signifient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "silence",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "silencieuse",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "silencieux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "similaire",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "simple",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "simplement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "simples",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sincère",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "singe",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "singes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sinon",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "site",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "situation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "situations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "situe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "situera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "six",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "sixième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "ski",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "skiait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "skier",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "skiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "snowboard",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sociaux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "société",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sociétés",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "soda",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sodas",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "soient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soif",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "soin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soirée",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sois",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soixante",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "soixante-dix",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "soixante-et-onze",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soldats",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "soleil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "solide",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "solution",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "solutions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sombre",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sombres",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "somme",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sommeil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sommes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sommet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "son",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "sonné",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "sont",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sont à",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sont-elles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sont-ils",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sors",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sort",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sortaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sortant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sortent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sortes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sortez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sortie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sortir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sortons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "souffle",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soufflé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "souffler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "souffrir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "souhaite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "souhaitent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "souhaiter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "souhaiteraient",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "souhaits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "soupe",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "source",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sources",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sourd",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "souriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sourions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sourire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "souris",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sous",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "sous la pluie",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "soutenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soutiendrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soutiendras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soutiendrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soutiens",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "souvenez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "souvenir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "souvenirs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "souvenons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "souvent",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "souviendrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "souviennent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "souviens",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "soyez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "soyons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "spécial",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "spéciale",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "spécialement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "spéciales",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "spécialiste",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "spécialistes",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "spéciaux",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "spectacle",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sport",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sportifs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "stades",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stars",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "station balnéaire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "stations",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "statue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stratégie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "stupide",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "stylo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "stylos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "subir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "succès",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sucre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sucré",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sucrerie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sucrés",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sud",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sud-est",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sud-ouest",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "suffisamment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "suffisant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "suffisants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "suffit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suisses",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "suit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suite",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "suivant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "suivante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "suivantes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "suivants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "suivent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suiviez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suivre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sujet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sujets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "super",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "supérieur",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "supérieurs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "supermarché",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "supermarchés",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "supplémentaires",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "supporte",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supporter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "suppose",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supposons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "supprime",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sûr",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "sur",
        "partOfSpeech": "Preposition",
        "gender": ""
    },
    {
        "word": "sur l'avenue",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sur le point d'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sur pause",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sûre",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "sûrement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "surfant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "surfer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "surnom",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "surnoms",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "surprenant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "surprenante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "surprise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "surprises",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sûrs",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "surtout",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "surveille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "surveiller",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "surveillons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "sushis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sweat-shirt",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "sympa",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "sympathique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "synagogue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "synagogues",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "syndicat",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "syndicats",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "système",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "sœur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "sœurs",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "t",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "t'",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "t'ai",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "t'appelle",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "t'appelles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "t'en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "t-shirt",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "t-shirts",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "ta",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "ta place",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "table",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tableau",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tableaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tables",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tablette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "taille",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "taille unique",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "taise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "taisez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "taisiez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "talon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "talons",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tant",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tante",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tantes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tape",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "taper",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tapis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tard",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tarifs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tarte",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tasse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tasses",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "taux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "taxi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "taxis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "te",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "te connecteras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "te maquilles",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "technique",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "techniques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "technologie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "télé",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "télécharge",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "téléchargements",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "télécharger",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "téléphone",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "téléphoner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "téléphones",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "télévision",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tellement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tels",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "température",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tempête",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "temple",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "temps",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tenez",
        "partOfSpeech": "Interjection",
        "gender": ""
    },
    {
        "word": "tenir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tennis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tenons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tente",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tentes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tenue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "termine",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terminer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "terrasse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "terre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "terrible",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "territoire",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tes",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "têtes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "texte",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "textes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "thaïlande",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "thé",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "théâtre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "thème",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "théorie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "thon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ticket",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tickets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tien",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "tiendras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tienne",
        "partOfSpeech": "Pronoun",
        "gender": "Feminine"
    },
    {
        "word": "tiens",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tiers",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tigre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tigres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "timbre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "timide",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "timides",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tirer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tiroir",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tiroirs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tisane",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tissu",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tissus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "titre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "toi",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "toi-même",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "toilettes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "toit",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "toits",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tomate",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tombe",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tombent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tomber",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tombés",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "ton",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "tonnes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tort",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tortue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tôt",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "total",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "totalement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "touchant",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "touche",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "touché",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "touchée",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "touchent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "toucher",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "touches",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "touchez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "toujours",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tour",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tour eiffel",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tourisme",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "touriste",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "touristes",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "touristiques",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tourne",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tourné",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "tournent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tourner",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tournes",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tournez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tournons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tours",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tous",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "tous les jours",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tousse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tousser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tousseras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tousses",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tout",
        "partOfSpeech": "Determiner",
        "gender": "Masculine"
    },
    {
        "word": "tout à coup",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout à fait",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout à l'heure",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout ce",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout ce dont",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout ce qui",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout de suite",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout droit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout le monde",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout neuf",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout petit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout seul",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tout va bien",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "toute",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "toute la journée",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "toute neuve",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "toute seule",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "toutes",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "toutes les deux",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tradition",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "traditionnelle",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "traditions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "traduction",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "traductions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "traduire",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traduise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traduisent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "train",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "train de nuit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "trains",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trains de nuit",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "traitement",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trajet",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trajets",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tram",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tranches",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tranquille",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "tranquilles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "transfère",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "transport",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "transports",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "travail",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "travaillais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "travaillait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "travaille",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "travaillé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "travaillent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "travaillent-elles",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "travailler",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "travaillera",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "travailleras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "travailles",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "travailles-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "travaillez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "travaillez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "travaillons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "travaux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "traversant",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "traverser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "treize",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "trente",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "très",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "tribunal",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tricoterai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tries",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "triste",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "trois",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "troisième",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "trompette",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "tronc",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trop",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "trop de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "trottoirs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trou",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trousse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "trouvais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trouve",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trouvé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "trouvent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trouver",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "trouvons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "truc",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "trucs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tu",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "tu marches",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tu t'es",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tu vas",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tu y",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "tube",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "tuer",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "tunisien",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "tunisiens",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "type",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "typiquement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "un",
        "partOfSpeech": "Numeral",
        "gender": "Masculine"
    },
    {
        "word": "un autre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "un de ces jours",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "un petit peu de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "un peu de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "un tour",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "un verre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "une",
        "partOfSpeech": "Determiner",
        "gender": "Feminine"
    },
    {
        "word": "une autre",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "une fête",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "une fois",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "uni",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "uniforme",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "uniquement",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "universel",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "université",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "universités",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "urgence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "urgences",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "urgent",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "usine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "utile",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "utiles",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "utilisation",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "utilise",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utilisent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utiliser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utilises",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utilisez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "utilisons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "va",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vacances",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vache",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vaches",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vague",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vaisselle",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "valeur",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "valise",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "valises",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vanille",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vas",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vas-tu",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vaut",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veau",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vegan",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "veille",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vélo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vélos",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "venaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vend",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vende",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendras",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vendredi",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vendredis",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vends",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "veniez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "venir",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "venons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "venons de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vent",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vente",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "ventilateur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ventre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "venu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "venue",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "venues",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "venus",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "vérifiait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vérifie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vérifiées",
        "partOfSpeech": "Verb",
        "gender": "Feminine"
    },
    {
        "word": "vérifier",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vérifions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vérité",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "verra",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verrai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "verres",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "verrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vers",
        "partOfSpeech": "Conjunction",
        "gender": ""
    },
    {
        "word": "versé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "verse",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "verser",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "versions",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vert",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "verte",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vertes",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "veste",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vêtements",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vétérinaire",
        "partOfSpeech": "Noun",
        "gender": ""
    },
    {
        "word": "veuillez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veulent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veut",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "veut dire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "veux",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viande",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "victoire",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vide",
        "partOfSpeech": "Adjective",
        "gender": ""
    },
    {
        "word": "vidéos",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vider",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vie",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vie nocturne",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vieil",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vieille",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "viendrez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viendrons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viennent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viens",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "viens de",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vient",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vies",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vietnam",
        "partOfSpeech": "Proper noun",
        "gender": ""
    },
    {
        "word": "vietnamienne",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vieux",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "village",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "villages",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "ville",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "villes",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vinaigre",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vingt",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "vingt et unième",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vingtaine",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vingtième",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "violence",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "violet",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "violets",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "violon",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "virus",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vis",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "visite",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visité",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "visitent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visiter",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "visiterai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visiteraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visiterais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visiterons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visiteront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visites",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visiteurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "visitez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "visitons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vite",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "vitesse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vivant",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vivante",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vivants",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vive",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vivre",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vocabulaire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voici",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "voie",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voie ferrée",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voies ferrées",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voilà",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "voile",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "voilier",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "voir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vois",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voisin",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "voisinage",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "voisins",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "voit",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voiture",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "voitures",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "voix",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "vol",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volant",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "vole",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "volent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voler",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voleur",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "voleurs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volleyball",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volume",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "volumes",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "vont",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vos",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "vote",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "voter",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "votre",
        "partOfSpeech": "Determiner",
        "gender": ""
    },
    {
        "word": "vôtre",
        "partOfSpeech": "Pronoun",
        "gender": "Masculine"
    },
    {
        "word": "votre place",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vôtres",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "voudraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voudrais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voudrait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voudriez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voudriez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voudrions",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voudront",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voulaient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voulais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voulait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voulez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voulez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vouloir",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voulons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voulu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "vous",
        "partOfSpeech": "Pronoun",
        "gender": ""
    },
    {
        "word": "vous appelez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vous en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vous taire",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "vous vous êtes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voyage",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyagé",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "voyagent",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyageons",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voyager",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voyagerai",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyageraient",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyagerez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyagerons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyages",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyageuse",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "voyagez",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voyagez-vous",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "voyais",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyait",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyez",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "voyons",
        "partOfSpeech": "Verb",
        "gender": ""
    },
    {
        "word": "vrai",
        "partOfSpeech": "Adjective",
        "gender": "Masculine"
    },
    {
        "word": "vraie",
        "partOfSpeech": "Adjective",
        "gender": "Feminine"
    },
    {
        "word": "vraiment",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "vu",
        "partOfSpeech": "Verb",
        "gender": "Masculine"
    },
    {
        "word": "vue",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "web",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "week-end",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "week-ends",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "wi-fi",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "y",
        "partOfSpeech": "Adverb",
        "gender": ""
    },
    {
        "word": "y en",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "y sommes",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "y suis",
        "partOfSpeech": "",
        "gender": ""
    },
    {
        "word": "yaourts",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "yeux",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "yoga",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "zéro",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "zéros",
        "partOfSpeech": "Numeral",
        "gender": ""
    },
    {
        "word": "zone",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "zones",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "zoo",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "œil",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "œuf",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "œufs",
        "partOfSpeech": "Noun",
        "gender": "Masculine"
    },
    {
        "word": "œuvre",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    },
    {
        "word": "œuvres",
        "partOfSpeech": "Noun",
        "gender": "Feminine"
    }
]

export default wordList;